import React from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "right",
  },
  link: {
    textDecoration: "none",
    color: "#0E253F",
  },
  deals: {
    fontSize: 10,
    lineHeight: "16px",
  },
  assetPoolDeals: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "0.25px",
    margin: "0 0 0 4px",
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: " [..]",
    overflow: "hidden",
  },
}));

const PoolDeals = ({ assetPool }) => {
  const classes = useStyles();
  const { assetPool_Deals } = assetPool;

  return (
    <Box className={classes.root}>
      <Typography
        variant='overline'
        color='secondary'
        noWrap={true}
        className={classes.deals}>
        Deals
      </Typography>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          overflow: "auto",
        }}>
        {assetPool_Deals
          ? assetPool_Deals.map((deal, idx) => (
              // <Link
              //   key={idx}
              //   className={classes.link}
              //   to={{
              //     pathname: `/assetPool/portfolio/${deal.dealId}`,
              //     state: { deal: deal, dealId: deal.dealId },
              //   }}>
              <Tooltip key={idx} title={deal.dealName} placement='bottom' arrow>
                <Typography
                  variant='h1'
                  color='primary'
                  className={{
                    noWrap: classes.noWrap,
                  }}
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    letterSpacing: "0.25px",
                    margin: "0 0 0 4px",
                  }}
                  noWrap={true}>
                  {deal.dealName}
                  {idx + 1 !== assetPool_Deals.length ? "," : null}
                </Typography>
              </Tooltip>
              // </Link>
            ))
          : null}
      </Box>
    </Box>
  );
};

export default PoolDeals;
