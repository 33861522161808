import _ from "lodash";
import React, { useState } from "react";
import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import ThreeDotButton from "../../ThreeDotButton";
import * as constants from "../../../../constants";
import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 1,
    backgroundColor: "inherit",
  },
  inlineFlex: {
    display: "inline-flex",
  },
  overline: {
    color: "#263238",
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: "2px",
    lineHeight: "normal",
  },
  subtitle1: {
    color: "#023059",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.15px",
  },
  caption: {
    color: "#546E7A",
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: "0.4px",
  },
}));

const DataTableCell = ({
  overlines,
  body,
  captions,
  content,
  modalProps,
  showCellOptions,
  cellOptions,
  onOptionOpen,
}) => {
  const classes = useStyles();
  const captionSpan = captions && captions.length <= 6 ? 12 : 6;
  const [showThreeDotButton, setShowThreeDotButton] = useState(false);
  const [open, setOpen] = useState(false);

  cellOptions = cellOptions || [];
  const maxThreeDotBtnOptionId = _.maxBy(cellOptions, (o) => o.id);

  const threeDotButtonOptions = [
    {
      // Make sure the ids are all unique for all options
      id: maxThreeDotBtnOptionId ? maxThreeDotBtnOptionId.id + 1 : 0,
      label: constants.EDIT,
      icon: Edit,
      // For data table, each cell can have a three-dot button,
      // Please refer to reserve account caps table.
      handleClick: showCellOptions ? () => setOpen(!open) : onOptionOpen,
    },
    ...cellOptions,
  ];

  return (
    <Paper
      elevation={0}
      className={classes.root}
      onMouseEnter={showCellOptions ? () => setShowThreeDotButton(true) : null}
      onMouseLeave={
        showCellOptions ? () => setShowThreeDotButton(false) : null
      }>
      <Divider />
      <span className={classes.inlineFlex}>
        <Grid container>
          {overlines &&
            overlines.map((overline, idx) => (
              <Grid key={`${overline}_${idx}}`} item sm={12}>
                <div>
                  <Typography
                    variant='overline'
                    display='block'
                    className={classes.overline}>
                    {overline}
                  </Typography>
                </div>
              </Grid>
            ))}
        </Grid>
      </span>
      {showThreeDotButton && showCellOptions ? (
        <span className={classes.inlineFlex} style={{ float: "right" }}>
          <ThreeDotButton
            padding={"0"}
            color={"rgba(0, 0, 0, 0.5)"}
            fontSize={"small"}
            options={threeDotButtonOptions}
          />
        </span>
      ) : null}
      <Typography variant='subtitle1' className={classes.subtitle1}>
        {content ? content(body) : body || constants.___}
      </Typography>
      <Grid container>
        {captions &&
          captions.map((caption, index) => (
            <Grid key={index} item sm={captionSpan} lg={captionSpan}>
              <Typography
                variant='caption'
                display='block'
                className={classes.caption}>
                {caption}
              </Typography>
            </Grid>
          ))}
      </Grid>
      {modalProps && (
        <MultiFieldsFadeInEditingPanel
          open={open && showCellOptions}
          columns={modalProps.columns}
          data={modalProps.data}
          tableName={modalProps.tableName}
          errorsOnSubmit={modalProps.errorsOnSubmit}
          props={modalProps.props}
          onClose={() => setOpen(false)}
        />
      )}
    </Paper>
  );
};

export default DataTableCell;
