import _ from "lodash";
import * as constants from "../../../../constants";

const updateReserveAccountId = (lease, newData) => {
  const { components } = newData;
  const componentLeaseInfo = lease.componentLeaseInfo;
  for (let componentName of components) {
    let componentIdx = _.findIndex(
      componentLeaseInfo,
      (c) => c.componentName === componentName
    );
    if (componentIdx !== -1) {
      componentLeaseInfo[componentIdx] = {
        ...componentLeaseInfo[componentIdx],
      };
      componentLeaseInfo[componentIdx].reserveAccountId = newData.leaseId;
    }
  }
};

const removeAccountIdsInReserveCaps = (newData, scenario, sectionIdx) => {
  let reserveAccountCaps = [...scenario.leases[sectionIdx].reserveAccountCaps];
  for (let i = 0; i < reserveAccountCaps.length; i++) {
    reserveAccountCaps[i] = { ...reserveAccountCaps[i] };
    reserveAccountCaps[i].reserveIds = [...reserveAccountCaps[i].reserveIds];
    reserveAccountCaps[i].reserveIds = reserveAccountCaps[i].reserveIds.filter(
      (id) => id !== newData.id
    );
  }

  scenario.leases[sectionIdx].reserveAccountCaps = reserveAccountCaps;
};

export const updateData = (actionType, sectionIdx, newData, scenario) => {
  const template = {};

  scenario.leases = [...scenario.leases];

  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };

  let reserveAccounts = [...scenario.leases[sectionIdx].reserveAccounts];
  if(actionType === "Retrieve"){
    scenario.leases[sectionIdx].reserveAccounts = newData;
  }

  else if (actionType === constants.DELETE_ROW) {
    reserveAccounts = _.filter(reserveAccounts, (o) => o.id !== newData.id);

    if (reserveAccounts.length === 0)
      scenario.leases[sectionIdx].reserveAccountCaps = [];
    else removeAccountIdsInReserveCaps(newData, scenario, sectionIdx);

    // newData.id = -1;
  } else {
    // let accountIdx = _.findIndex(reserveAccounts, (e) => e.id === newData.id);
    // reserveAccounts[accountIdx] = { ...reserveAccounts[accountIdx] };

    // template.accountCaps = newData.accountCaps || [];
    // template.cashBalance =
    //   newData.cashBalance === null || newData.cashBalance === undefined
    //     ? 0
    //     : parseFloat(newData.cashBalance);
    template.components = newData.components;

    // template.date =
    //   newData.date ||
    //   (reserveAccounts[0] && reserveAccounts[0].date) ||
    //   moment.utc().format(constants.ISOFormat);

    if (actionType === constants.UPDATE_ROW) {
      updateReserveAccountId(scenario.leases[sectionIdx], newData, actionType);
    } else {
      if (reserveAccounts.length === 0) {
        template.id = 1
      } else {
        template.id = reserveAccounts[reserveAccounts.length - 1].id + 1;
      }
      reserveAccounts.push(template);
    }
  }
  scenario.leases[sectionIdx].reserveAccounts = reserveAccounts;

  return scenario;
};
