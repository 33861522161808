import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableFooter,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import CustomPagination from "../../CustomPagination";
import Pool from "./Pool/Pool";
import { makeStyles } from "@material-ui/core/styles";
import LoadingPanel from "../../Common/LoadingPanel";
import apiRoutes from "../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../utils/ApiRequest";
import * as constants from "../../../constants";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles({
  tablePaginationFooter: {
    border: "none",
    color: "#355A79",
    paddingRight: 11,
  },
});

const Pools = ({
  currPage,
  rowsPerPage,
  assetPoolData,
  handleChangePage,
  handleChangeRowsPerPage,
  liabilityData,
  airlineLogos,
  totalRows,
  userName,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const [allAssets, setAllAssets] = useState(null);

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    const fetchAssets = async () => {
      try {
        const assetList = await apiRequest(
          {
            url: apiRoutes.VectorDeal_GetAssets,
            method: "GET",
            cancelToken: cancelToken.token,
          },
          msalContext
        );
        if (assetList.status === response.OK) {
          setAllAssets(assetList.data);
        }
      } catch (ex) {
        console.log(ex);
      }
    };
    fetchAssets();

    return () => {
      if (cancelToken) {
        cancelToken.cancel(constants.API_REQUEST_CANCEL_MSG);
      }
      setAllAssets(null);
    };
  }, []);

  let assetPoolAirlineLogos = [];
  if (!_.isEmpty(airlineLogos)) {
    assetPoolData.map((assetPool) => {
      let assetPoolLogos = [];
      airlineLogos.forEach((logo) => {
        if (
          _.find(assetPool.assetPool_Assets, function (o) {
            return o.operator === logo.operator;
          })
        )
          assetPoolLogos.push(logo);
      });
      assetPoolAirlineLogos.push(assetPoolLogos);
    });
  }

  return (
    <Box>
      {!_.isEmpty(airlineLogos) ? (
        <TableContainer style={{ maxWidth: "100%", overflowX: "hidden" }}>
          <Table aria-label='simple table'>
            <TableBody m={2}>
              {assetPoolData.map((assetPool, index) => (
                <TableRow key={index}>
                  <TableCell
                    scope='row'
                    component={() => (
                      <td>
                        <Pool
                          key={index}
                          allAssets={allAssets}
                          assetPoolId={assetPool.assetPoolId}
                          assetPool={assetPool}
                          liabilityData={
                            liabilityData
                              ? liabilityData[assetPool.assetPoolId]
                              : null
                          }
                          airlineLogos={
                            _.isEmpty(airlineLogos)
                              ? airlineLogos
                              : assetPoolAirlineLogos[index]
                          }
                          userName={userName}
                        />
                      </td>
                    )}></TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.tablePaginationFooter}
                  rowsPerPageOptions={[10, 25, 50, 75, 100, 125, 150]}
                  count={
                    assetPoolData.length > 0 ? totalRows : assetPoolData.length
                  }
                  color='primary'
                  rowsPerPage={rowsPerPage}
                  page={currPage}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                    color: "primary",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={CustomPagination}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <>
          <LoadingPanel />
        </>
      )}
    </Box>
  );
};

export default Pools;
