import React , { useState } from "react";
import { FormControl, InputLabel, Input  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: `${theme.spacing(1)}px 0`,
    backgroundColor: "#FFFFFF",
  },
  inputLabel: {
    letterSpacing: "1px",
    paddingTop: 10,
    paddingLeft: 17,
  },
  textField: {
    color: "#263238",
    // Delete later
    marginTop: 2,
    padding: "6px 0 7px 0 !important",
    "&:focus": { backgroundColor: "transparent" },
  },
}));

const VfTextInput = ({
  items,
  type,
  placeholder,
  onChange,
  id,
  fullWidth,
  inputType,
  startAdornment,
  variant = "standard",
  label = null,
  minWidth = 1,
  disableUnderline = false,
  fontSize = null,
  padding = null,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({inputvalue: ""})
  const handleInputChange = e => {
        const { value } = e.target
        setValues({...values, inputvalue: value})
    }

  const keyPress = (event) => {
    if(event.keyCode === 13){
        let { value, name } = event.target;
        if (inputType === "number") {
          value = parseInt(value)
        }
        onChange(type, value, name);
        setValues({...values, inputvalue: ""})
        }
    }

  return (
    <FormControl
      className={classes.formControl}
      style={{
        minWidth,
        width: fullWidth ? fullWidth : "100%",
        padding: padding,
      }}
    >
      {label ? (
        <InputLabel id={label[0]} className={classes.inputLabel} shrink={true}>
          {label}
        </InputLabel>
      ) : null}
      {items && (
        <Input 
          label={label && label[0]}
          name={`${id === null ? label : id}`}
          value={values.inputvalue} 
          placeholder={placeholder}
          onKeyDown={keyPress} 
          onChange={handleInputChange} 
          startAdornment={startAdornment}
          disableUnderline={disableUnderline}
          variant={variant}
          style={{
              fontSize,
          }}
          type={inputType}
          classes={{
              TextField: classes.TextField,
          }}
        />
      )}
    </FormControl>
  );
};

export default VfTextInput;
