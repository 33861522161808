import * as constants from "../../../../constants";
import { updateData as updateCashReserveAccounts } from "./cashReserveAccounts";
import { updateData as updateCashReserveCaps } from "./cashReserveCaps";
import { updateData as updateConditionsAtDevlivery } from "./conditionsAtDelivery";
import { updateData as updateConditionsAtRedelivery } from "./conditionsAtRedelivery";
import { updateData as updateCreditLineReserve } from "./creditLineReserve";
import { updateData as updateLeaseExtension } from "./leaseExtension";
import { updateData as updateExtensionRent } from "./extensionRent";
import { updateData as updateLeaseRent } from "./leaseRent";
import { updateData as updateMaintenanceFunding } from "./maintenanceFunding";
import { updateData as updateMaintenanceReserveRates } from "./maintenanceReserveRates";
import { updateData as updateRedeliveryAdjustments } from "./redeliveryAdjustments";
import { updateData as updateLLPReplCostClaimable } from "./llpReplCostClaimable";
import { updateData as updateLeaseList } from "./leaseList";
import { updateData as updateLeaseSummary } from "./leaseSummary";
import { updateData as updateDeposits } from "./leaseDeposits";

function findEvent(assetDetails, mxEvent) {
  const { airframeChecks, engines, apUs, landingGears } = assetDetails;
  let events = null;

  if (airframeChecks) events = [airframeChecks, engines, apUs, landingGears];
  else events = [engines];

  let componentName = null;
  for (let i = 0; i < events.length; i++) {
    for (let event of events[i]) {
      if (event.checkName === mxEvent) {
        componentName = event.checkName;
      } else if (event.componentType.toUpperCase() === mxEvent.toUpperCase())
        componentName = constants.APU.toUpperCase();
      else if (event.position === mxEvent && !mxEvent.includes("-")) {
        componentName = event.position;
      } else if (mxEvent.includes("LLP") && !mxEvent.includes("-")) {
        componentName = mxEvent;
      } else if (mxEvent.includes("LG") && !mxEvent.includes("-")) {
        componentName = mxEvent.replace("LG ", "");
      } else if (mxEvent.includes("-")) {
        if (
          mxEvent.includes("LG") &&
          event.position === mxEvent.split("-")[1].trim()
        ) {
          componentName = event.position;
        }
        //else if ((mxEvent.match(/-/g)||[]).length > 1){
        //   if (mxEvent.includes(constants.ENGINE_PR) && event. === "EnginePR") {
        //     componentName = event.position;
        //   } else if (mxEvent.includes(constants.ENGINE_LLP) && event.componentType === "EnginePR") {
        //     componentName = "LLP Stack " + event.position;
        //   }
        // } 
        else if (event.serialNumber === mxEvent.replace("EPR - ","") || event.serialNumber === mxEvent.replace("ELLP - ","") ) {
          if (mxEvent.includes(constants.ENGINE_PR)) {
            componentName = event.position;
          } else if (mxEvent.includes(constants.ENGINE_LLP)) {
            componentName = "LLP Stack " + event.position;
          }
        }
      } else if (mxEvent.includes("Position")) {
        componentName = mxEvent;
      }
      if (componentName) break;
    }
  }

  return componentName;
}

export function updateLeaseInfo(
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario
) {
  // console.log(tableName,
  //   actionType,
  //   assetDetails,
  //   sectionIdx,
  //   newData,
  //   scenario)
  let componentName = null;
  if (tableName !== constants.cashReserveCap) {
    componentName = newData.mxEvent && findEvent(assetDetails, newData.mxEvent);
  }

  const params1 = [actionType, sectionIdx, newData, scenario, componentName];
  const params2 = [scenario, sectionIdx, newData, actionType];
  const params3 = [tableName, actionType, sectionIdx, newData, scenario];
  switch (tableName) {
    case constants.cashReserveAccounts:
      return updateCashReserveAccounts(...params1);
    case constants.cashReserveCap:
    case "Accounts":
      return updateCashReserveCaps(...params3);
    case constants.deliveryConditions:
      return updateConditionsAtDevlivery(...params1);
    case constants.redeliveryConditions:
      return updateConditionsAtRedelivery(...params1);
    case constants.creditLineReserve:
      return updateCreditLineReserve(...params1);
    case constants.leaseSummary:
      return updateLeaseSummary(...params2);
    case constants.leaseExtension:
      return updateLeaseExtension(...params1);
    case constants.leaseRent:
      return updateLeaseRent(...params2);
    case constants.llpReplacementCostClaimable:
      return updateLLPReplCostClaimable(...params2);
    case constants.maintenanceFunding:
      return updateMaintenanceFunding(...params1);
    case constants.maintenanceReserveRates:
      return updateMaintenanceReserveRates(...params1);
    case constants.redeliveryAdjustments:
      return updateRedeliveryAdjustments(...params1);
    case constants.leaseList:
      return updateLeaseList(assetDetails, actionType, newData, scenario);
    case "extensionRent":
      return updateExtensionRent(...params2);
    case "depositcash":
      return updateDeposits(...params3);
    case "depositlc":
      return updateDeposits(...params3);
    default:
      return;
  }
}
