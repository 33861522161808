import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  overlay: {
    minHeight: 48,
    // padding: "0 16px",
    borderRadius: 0,
  },
  subtitle1: {
    fontSize: 16,
    letterSpacing: "0.15px",
    textAlign: "left",
    // color: "#263238",
    color: "#023059",
    fontWeight: 500,
  },
  caption: {
    letterSpacing: "0.4px",
    textAlign: "right",
    color: "#023059",
    fontWeight: 500,
  },
  buttons: {
    textAlign: "right",
    float: "right",
    display: "flex",
  },
}));

const TableNamePanel = ({ label, caption, buttons = null, data = null }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.overlay} alignItems="center">
      <Grid xs={12} sm={6} item>
        <Typography variant="subtitle1" className={classes.subtitle1}>
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={
          !buttons || (buttons && buttons.length === 0)
            ? 6
            : buttons.length === 1
            ? 5
            : 3
        }
      >
        <Typography
          variant="caption"
          display="block"
          className={classes.caption}
        >
          {typeof caption === "function" ? caption(data) : caption}
        </Typography>
      </Grid>
      {buttons && buttons.length ? (
        <Grid xs={12} sm={buttons.length === 1 ? 1 : 3} item>
          <Box className={classes.buttons}>
            {buttons.map((button, idx) => (
              <span key={idx} style={button.props.styles}>
                {button}
              </span>
            ))}
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default TableNamePanel;
