import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import { DeleteOutline, Visibility } from "@material-ui/icons";
import SingleFieldFadeInEditingPanel from "../../../../../../Common/Editing/EditingPanels/SingleFieldFadeInEditingPanel";
import XLSX from "xlsx";
import ExportIconSvg from "../../../../../../Common/SvgComponents/ExportIconSvg";
import ImportIconSvg from "../../../../../../Common/SvgComponents/ImportIconSvg";
import CalendarSvg from "../../../../../../Common/SvgComponents/CalendarSvg";
import DataTable from "../../../../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../../../../Common/Tables/EditableTable/EditableTable";
import VfSnackerBar from "../../../../../../Common/UtilComponents/VfSnackerBar";
import VfDropdown from "../../../../../../Common/UtilComponents/VfDropdown";
import * as AssetDetailViewModel from "../../../../../mapToViewModels";
import * as constants from "../../../../../../../constants";
import { mapToViewModelHeader } from "../../../../../mapToViewModels";
import { apiRequest, response } from "../../../../../../../utils/ApiRequest";
import apiRoutes from "../../../../../../../utils/ApiRoutes";
import { useMsal } from "@azure/msal-react";
import { StaticDataContext } from "../../../../../../../context/StaticDataContext";
import VfAlertDialogBox from "../../../../../../../Components/Common/UtilComponents/VfAlertDialogBox";

const formatData = (
  engine,
  engineIdx,
  componentType,
  generateEditableProps,
  scenario,
  statusDate,
  onSelectedStatusDateChange,
  airlines,
  subSeries,
  partOut,
  setsnackOpen,
  setSeverity,
  setMessage,
  confirmRemove,
  assetDetails
) => {
  let props = generateEditableProps(engineIdx, componentType);

  const {
    sectionIdx,
    section,
    funcs: { handleValuesUpdate },
  } = props;
  props.isAircraft = assetDetails.aircraftSubSeries ? true : false;


  props.confirmRemove = confirmRemove;

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      if (
        !file.name.includes(
          `${engine.engineSubSeries.replace("/", "_")}-${
            engine.serialNumber
          }-${moment.utc(statusDate).format(constants.DATE_FORMAT)}`
        )
      ) {
        setMessage(
          `Wrong file provided, please export current llp status for use.`
        );
        setsnackOpen(true);
        setSeverity("warning");
        reject("Wrong file provided");
      }
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const updateLLPStatusData = data.map((llp, idx) => {
        return {
          id: idx,
          statusDate: statusDate,
          isReplacement: llp["Is Replacement"],
          cyclesBefore: llp["Cycles"],
          cyclesAfter: llp["Cycles After"],
          serialNumber: engine.serialNumber,
          position: engine.position,
          engineSubSeries: engine.engineSubSeries,
        };
      });
      handleValuesUpdate(
        section,
        sectionIdx,
        "engineLLPStatus",
        updateLLPStatusData
      );
    });
  };

  const exportFile = () => {
    const filename = `${engine.engineSubSeries}-${engine.serialNumber}-${moment
      .utc(statusDate)
      .format(constants.DATE_FORMAT)}-LLPStatus.csv`;
    let data = [];

    engine.llpStack.llPs.forEach((llp) => {
      let replacedLLPStatus = _.find(llp.status, function (o) {
        return moment.utc(o.statusDate).diff(statusDate, "days") === 0;
      });
      replacedLLPStatus = replacedLLPStatus || llp.status[0];

      data.push({
        Module: llp.module,
        "Part Name": llp.partName,
        "List Price": llp.price[0].price,
        "Price Year": llp.price[0].priceYear,
        "Cycle Limit": llp.price[0].cycleLifeLimit,
        Cycles: replacedLLPStatus.cyclesBefore,
        "Is Replacement": replacedLLPStatus.isReplacementDate,
        "Cycles After": replacedLLPStatus.cyclesAfter,
      });
    });

    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, filename);
  };

  // options for the three dot button in the Engine LLP Status table panel header.
  let threeDotBtnOptions = [
    {
      id: 0,
      icon: Visibility,
      label: constants.VIEW,
    },
    {
      id: 1,
      icon: DeleteOutline,
      label: `${constants.DELETE} Status`,
      handleClick: () => {
        onSelectedStatusDateChange(null);
        props.funcs.handleRowDelete(
          constants.ENGINES,
          -1,
          constants.engineLLPStatus,
          {
            serialNumber: engine.serialNumber,
            engineSubSeries: engine.engineSubSeries,
            position: engine.position,
            statusDate,
          }
        );
      },
    },
    {
      id: 2,
      icon: ExportIconSvg,
      label: `Export Status`,
      handleClick: () => {
        exportFile();
      },
    },
    {
      id: 3,
      icon: ImportIconSvg,
      label: `Import Status`,
      handleClick: (file) => {
        readExcel(file);
      },
    },
  ];

  return [
    {
      header: mapToViewModelHeader(engine, componentType),
      data: AssetDetailViewModel.mapToViewModel(
        engine,
        componentType,
        props,
        scenario,
        [statusDate, threeDotBtnOptions],
        airlines,
        subSeries,
        partOut
      ),
      componentType,
    },
    props,
  ];
};

const Engines = ({
  engine,
  engineIdx,
  generateEditableProps,
  llpsStatusDates,
  open,
  onEditComponentClose,
  onSelectedStatusDateChange,
  scenario,
  serialNumber,
  selectedStatusDate,
  assetDetails
}) => {
  const [snackOpen, setsnackOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [subSeries, setSubSeries] = useState(null);
  const [partOut, setPartOut] = useState(null);

  const msalContext = useMsal();
  const { airlinesData } = useContext(StaticDataContext);

  const confirmRemove = () => {
      setAlertOpen(true);
  }
  const removeFromWing = async () =>{
    let EngId = engine.id;
    let user = msalContext.accounts[0].name;
    let apiEndPoint = apiRoutes.Engine_RemoveFromWing({EngId: EngId, user: user});
    let resp = await apiRequest(
      {
        url: apiEndPoint,
        method: "Post",
      },
      msalContext
    );
    if (resp.status === response.OK){
        setSeverity("info");
        setMessage("Engine Removed From Wing");
        setsnackOpen(true);
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
        return resp;
    }

  }

  useEffect(() => {
    const getPartOut = async () => {
      let apiEndPoint = apiRoutes.Engine_PartOutSpecs;
      let genericId = engine.genericID;
      apiEndPoint = apiEndPoint({
        genericId
      });
      let polist = await apiRequest(
        {
          url: apiEndPoint,
          method: "GET",
        },
        msalContext
      );
      if (polist.status === response.OK) setPartOut(polist.data);
    };
    const getSubSeries = async () => {
      let subSeries = await apiRequest(
        {
          url: apiRoutes.Engine_GetSeries,
          params: {
            sub: engine.engineSubSeries,
            acsub: assetDetails.aircraftSubSeries
              ? assetDetails.aircraftSubSeries
              : null,
          },
          method: "GET",
        },
        msalContext
      );

      if (subSeries.status === response.OK) setSubSeries(subSeries.data);
    };

   getPartOut();
   getSubSeries();

  }, []);

  const [engineViewModel, props] = formatData(
    engine,
    engineIdx,
    constants.ENGINES,
    generateEditableProps,
    scenario,
    selectedStatusDate,
    onSelectedStatusDateChange,
    airlinesData,
    subSeries,
    partOut,
    setsnackOpen,
    setSeverity,
    setMessage,
    confirmRemove,
    assetDetails
  );

  return (
    <Box>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        message={message}
        duration={2000}
      />
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DataTable tableData={engineViewModel.data.engineInfo} />
        </Grid>
        <Grid item sm={12}>
          <EditableTable table={engineViewModel.data.engineStatus} />
        </Grid>
        <Grid item sm={12}>
          <EditableTable table={engineViewModel.data.shopVisitHistory} />
        </Grid>
        <Grid item sm={12}>
          <EditableTable table={engineViewModel.data.scheduledShopVisit} />
        </Grid>
        <Grid item sm={12}>
          <EditableTable
            tableNamePanelButtons={[
              <VfDropdown
                styles={{ margin: "auto 0", minWidth: 120 }}
                itemProps={{
                  icon: (
                    <CalendarSvg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      style={{ verticalAlign: "middle" }}
                    />
                  ),
                }}
                // When all engine llps' status got deleted,
                // selectedStatusDate will be "No LLPs Status"
                // if we don't check selectedStatusDate is a valid date,
                // moment.utc(selectedStatusDate) will be rendered as today's date
                // Be advised:
                // If you want to usemoment.utc().isValid(), it throws a depracation warning.
                selectedItem={
                  selectedStatusDate.match(constants.RAW_DATE_REG) ||
                  selectedStatusDate.match(constants.RAW_DATE_REG2) ||
                  selectedStatusDate.match(constants.RAW_DATE_REG3)
                    ? moment
                        .utc(selectedStatusDate)
                        .format(constants.DATE_FORMAT)
                    : selectedStatusDate
                }
                items={llpsStatusDates}
              />,
            ]}
            table={engineViewModel.data.engineLLPStatus}
            bulkEdit={true}
          />
        </Grid>
      </Grid>
      <VfAlertDialogBox
        isOpen={alertOpen}
        handleAlertDialogVisible={setAlertOpen}
        dialogTitle={"Confirm Remove From Wing"}
        dialogBody={"Are you sure you would like to remove this engine from the aircraft?"}
        onAccept={() => {
          removeFromWing();
          setAlertOpen(false);
        }}
      />
      <SingleFieldFadeInEditingPanel
        open={open}
        id={-1}
        overlines={["New Status Date"]}
        body={new Date()}
        captions={null}
        onClose={onEditComponentClose}
        tableName={constants.engineLLPStatus}
        required={true}
        type={constants.DATE}
        props={{
          section: props.section,
          sectionIdx: props.sectionIdx,
          errorMessage: "Status Date exists.",
          funcs: {
            validateCell: (...props) => {
              const llpStatus = engine.llpStack.llPs[0].status;
              const value = props[4];
              const isValid =
                _.findIndex(
                  llpStatus,
                  (o) => moment.utc(o.statusDate).diff(value, "days") === 0
                ) === -1;

              return isValid;
            },
            handleCellUpdate: (section, sectionIdx, tableName, _, value) => {
              onSelectedStatusDateChange(value);
              props.funcs.handleRowAdd(section, sectionIdx, tableName, {
                statusDate: value,
                engineSubSeries: engine.engineSubSeries,
                position: engine.position,
                serialNumber,
              });
            },
          },
        }}
      />
    </Box>
  );
};

export default Engines;
