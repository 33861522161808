import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";

export const updateData = (actionType, newData, scenario) => {
  let buildStandardOverrides = [...scenario.buildStandardOverrides];
  let idx = _.findIndex(
    buildStandardOverrides,
    (o) => o.component === newData.component
  );

  if (idx === -1) {
    scenario.buildStandardOverrides.push({
      component: newData.component,
      llps: [
        {
          overrides: [],
        },
      ],
    });
    idx = buildStandardOverrides.length;
    buildStandardOverrides = [...scenario.buildStandardOverrides];
  }
  buildStandardOverrides[idx] = { ...buildStandardOverrides[idx] };

  let llps = [...buildStandardOverrides[idx].llps];

  const { cutOff, replaceCycles, cutOffComment, replaceCyclesComment } =
    newData;

  for (var i = 0; i < llps.length; i++) {
    llps[i] = { ...llps[i] };
    let override = [...llps[i].overrides];
    const template = {
      asOfDate: null,
      cutOff: null,
      replaceCycles: null,
      scrapSaleCutoff: null,
      cutOffComment: null,
      replaceCyclesComment: null,
    };

    if (actionType === constants.ADD_ROW) {
      let newOverride = {
        asOfDate: moment
          .utc()
          .add(parseInt(newData.daysDown), "days")
          .toISOString()
          .split(".")[0],
        shopVisitNumber: override.length + 1,
        overrideDescription: null,
        newBuildStandard: null,
      };

      const maxShopVisitNumber =
        override.length &&
        _.maxBy(override, (o) => o.shopVisitNumber).shopVisitNumber + 1;

      const shopVisitNumber =
        newData.runNumber ||
        (override.length === 0 || maxShopVisitNumber <= 5
          ? 6
          : maxShopVisitNumber + 1);

      newOverride.shopVisitNumber = shopVisitNumber;

      template.asOfDate = override.length
        ? override[0].newBuildStandard.asOfDate
        : moment
            .utc()
            .add(parseInt(newData.daysDown), "days")
            .toISOString()
            .split(".")[0];
      template.scrapSaleCutoff = override.length
        ? parseInt(override[0].newBuildStandard.scrapSaleCutoff)
        : parseInt(llps[i].buildStandard.scrapSaleCutoff);
      template.cutOff = parseInt(cutOff);
      template.replaceCycles = parseInt(replaceCycles);
      template.cutOffComment = cutOffComment;
      template.replaceCyclesComment = replaceCyclesComment;

      newOverride.newBuildStandard = template;

      override.push(newOverride);
    } else if (actionType === constants.UPDATE_ROW) {
      const idx = _.findIndex(
        override,
        (o) => o.shopVisitNumber === newData.runNumber
      );
      if (idx === -1) {
        let newOverride = {
          asOfDate: moment
            .utc()
            .add(parseInt(newData.daysDown), "days")
            .toISOString()
            .split(".")[0],
          shopVisitNumber: newData.runNumber,
          overrideDescription: null,
          newBuildStandard: null,
        };

        template.asOfDate = newOverride.asOfDate;
        template.cutOff = _.isObjectLike(cutOff)
          ? parseInt(cutOff.override) || cutOff.override === 0
            ? parseInt(cutOff.override)
            : cutOff.generic
          : parseInt(cutOff);
        template.replaceCycles = _.isObjectLike(replaceCycles)
          ? parseInt(replaceCycles.override) || replaceCycles.override === 0
            ? parseInt(replaceCycles.override)
            : replaceCycles.generic
          : parseInt(replaceCycles);
        template.cutOffComment = cutOffComment;
        template.replaceCyclesComment = replaceCyclesComment;

        newOverride.newBuildStandard = template;
        override.push(newOverride);
      } else {
        override[idx] = { ...override[idx] };
        let newBuildStandard = { ...override[idx].newBuildStandard };

        newBuildStandard.cutOff = _.isObjectLike(cutOff)
          ? parseInt(cutOff.override) || cutOff.override === 0
            ? parseInt(cutOff.override)
            : cutOff.generic
          : parseInt(cutOff);
        newBuildStandard.replaceCycles = _.isObjectLike(replaceCycles)
          ? parseInt(replaceCycles.override) || replaceCycles.override === 0
            ? parseInt(replaceCycles.override)
            : replaceCycles.generic
          : parseInt(replaceCycles);
        newBuildStandard.cutOffComment = cutOffComment;
        newBuildStandard.replaceCyclesComment = replaceCyclesComment;

        override[idx].newBuildStandard = newBuildStandard;
      }
    } else {
      const idx = _.findIndex(
        override,
        (o) => o.shopVisitNumber === newData.runNumber
      );
      // override[idx] = { ...override[idx] };
      override.splice(idx, 1);
    }
    llps[i].overrides = override;
  }

  buildStandardOverrides[idx].llps = llps;
  scenario.buildStandardOverrides = buildStandardOverrides;

  return scenario;
};
