import React from "react";
import {
  Grid,
  Box
} from "@material-ui/core";
import { apiRequest, response } from "../../utils/ApiRequest";
import apiRoutes from "../../utils/ApiRoutes";
import { MsalContext } from "@azure/msal-react";
import * as utils from "../../utils/utils";
import axios from "axios";
import _ from "lodash";
import * as constants from "../../constants";
import VfSnackerBar from "../../Components/Common/UtilComponents/VfSnackerBar";
import VfBreadcrumbs from "../../Components/Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../../Components/Common/UtilComponents/VfBreadcrumbContainer";
import Deals from "./Components/DealList";
import DealFilter from "./Components/DealHeading";
import ErrorBoundary from "../GeneralErrorPage";
import STRVandCOLR from "../../Components/EditStrValueColRating/EditStrValueColRating";

class DealsPage extends React.Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.tokenSourceDeals = null;
    this.state = {
      allDealsData: [],
      filteredDealsData: [],
      dealsData: [],
      airlineLogos: [],
      dealsSearchQuery: "",
      isloading: true,
      rowsPerPage: 10,
      currPage: 0,
      skip: 0,
      take: 10,
      searchDealName: "",
      filteringSponsorsList: [],
      checkedDealStage: ["Closed"],
      checkedSponsor: [],
      searchSponsor: "",
      showFilterOptions: false,
      snackOpen: false,
      severity: "info",
      message: "",
      liabilityData: null,
      expectedLossRateData: null,
      editSTR_COL: false,
    };
  }

  componentDidMount = async () => {
    // getting sponsors for filtering list of sponsors in deals,
    // used when there was pagination for getting deal data,
    // may bring back again if too many deals and we need pagination on api level again
    // let resp2 = await this.getSponsorsList();
    // if (resp2.status === response.OK) {
    //   this.setState({ filteringSponsorsList: resp2.data, isloading: false });
    // }

    const urlValues = utils.parseUrlObject(new URL(window.location.href));

    let {
      skip,
      take,
      currPage,
      rowsPerPage,
      checkedDealStage,
      checkedSponsor,
      searchDealName,
    } = this.state;

    const sessionCheckedDealStage =
      utils.sessionGetDataHelper("checked-dealstages");

    skip = urlValues.skip ? parseInt(urlValues.skip) : skip;
    take = urlValues.take ? parseInt(urlValues.take) : take;
    currPage = urlValues.page ? parseInt(urlValues.page) - 1 : currPage;
    rowsPerPage = urlValues.rows ? parseInt(urlValues.rows) : rowsPerPage;
    checkedDealStage = urlValues.status
      ? urlValues.status.split(",")
      : sessionCheckedDealStage
      ? sessionCheckedDealStage
      : checkedDealStage;
    checkedSponsor = urlValues.sponsors
      ? urlValues.sponsors
          .split(",")
          .map((sponsor) => decodeURI(sponsor).trim())
      : checkedSponsor;
    searchDealName = urlValues.search
      ? decodeURI(urlValues.search).trim()
      : searchDealName;

    let allDealsData = await this.getDealsData(0, 1000, null, null, null);
    if (allDealsData.status === response.OK) {
      let allDealAssets = [];
      let filteringSponsorsList = [];
      const pipelineStages = [
        "Approval Process",
        "Initial Review",
        "Due Diligence",
        "Closing Process"
      ];
      const closedStages = ["Amending", "Delayed Draw"];

      allDealsData.data.forEach((deal) => {
        if (pipelineStages.includes(deal.stage)) {
          deal.stage = "Pipeline";
        }
        if (closedStages.includes(deal.stage)) {
          deal.stage = "Closed";
        }
        allDealAssets = allDealAssets.concat(deal.assets);
        filteringSponsorsList.push(deal.sponsor);
      });

      const airlineLogos = await this.getAllAirlineLogos(allDealAssets);
      const filteredData = _(allDealsData.data)
        .filter((deal) => {
          return (
            (_.isEmpty(checkedDealStage)
              ? true
              : checkedDealStage.includes(deal.stage)) &&
            (_.isEmpty(checkedSponsor)
              ? true
              : checkedSponsor.includes(deal.sponsor))
          );
        })
        .sortBy(["stage", "sponsor", "dealName"])
        .value();

      const dealsData = filteredData
        ? filteredData.slice(skip, skip + take)
        : null;

      this.setState({
        allDealsData: allDealsData.data,
        airlineLogos: airlineLogos,
        filteredDealsData: filteredData,
        searchDealName: searchDealName,
        dealsData: dealsData,
        filteringSponsorsList: filteringSponsorsList.sort(),
        isloading: false,
        currPage: currPage,
        rowsPerPage: rowsPerPage,
        skip: skip,
        take: take,
        checkedDealStage: checkedDealStage,
        checkedSponsor: checkedSponsor,
      });

      if (searchDealName !== "") {
        this.searchDeal(searchDealName);
      }
    }

    let resp5 = await this.getAllLiabilityData();
    if (resp5.status === response.OK) {
      this.setState({ liabilityData: resp5.data });
    }

    let resp6 = await this.getExpectedLossRateData();
    if (resp6.status === response.OK) {
      this.setState({ expectedLossRateData: resp6.data });
    }
  };

  getAllAirlineLogos = async (assetList) => {
    let logos = [];
    const uniqOperators = _.compact(
      _.uniqBy(assetList, "operator").map((o) => o.operator)
    );

    const fetchLogo = async (operators) => {
      return apiRequest(
        {
          url: apiRoutes.Blob_GetAirlineLogos(),
          method: "POST",
          data: operators,
          options: { headers: { "Content-Type": "text/json" } },
        },
        this.context
      );
    };

    try {
      const result = await fetchLogo(uniqOperators);
      if (result.status === response.OK) {
        Object.entries(result.data).forEach(([key, value]) => {
          logos.push({
            operator: key,
            path: value,
          });
        });
      }

      return logos;
    } catch (ex) {}
  };

  getDealsData = async (
    skip,
    take,
    searchDealName = "",
    searchDealStage = ["Pipeline", "Closed"],
    searchSponsorName = ""
  ) => {
    this.tokenSourceDeals = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiRoutes.VectorDeal_GetDealsWithAssets({
          skip: skip,
          take: take,
          searchDealName: searchDealName,
          searchDealStage:
            searchDealStage != null ? searchDealStage.toString() : "",
          searchSponsorName:
            searchSponsorName != null ? searchSponsorName.toString() : "",
        }),
        method: "GET",
        cancelToken: this.tokenSourceDeals.token,
      },
      this.context
    );
  };

  getAllLiabilityData = async () => {
    return apiRequest(
      {
        //url: apiRoutes.LiabilityModel_GetAllDealsLiabilityModelOutputs,
        url: apiRoutes.LiabilityModel_GetDealsPageView,
        method: "POST",
        // data: dealIds,
        options: { headers: { "Content-Type": "text/json" } },
      },
      this.context
    );
  };

  getExpectedLossRateData = async () => {
    return apiRequest(
      {
        //url: apiRoutes.LiabilityModel_GetAllDealsLiabilityModelOutputs,
        url: apiRoutes.ExpectedLoss_GetDealsPageView,
        method: "POST",
        // data: dealIds,
        options: { headers: { "Content-Type": "text/json" } },
      },
      this.context
    );
  };

  postNewDealtoDB = async (newDealData, userName) => {
    newDealData["id"] = 0;
    return apiRequest(
      {
        url: apiRoutes.VectorDeal_Deals({ user: userName }),
        data: newDealData,
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      this.context
    );
  };

  postPipelineDealtoDB = async (data, userName) => {
    return apiRequest(
      {
        url: apiRoutes.VectorDeal_CreateExistingDeal({
          user: userName,
          globalId: data.globalId,
        }),
        params: {
          Recourse: data.recourse,
          facilityTrancheName: data.facilityTrancheName,
          trancheSeniority: data.trancheSeniority,
        },
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      this.context
    );
  };

  searchDeal = (searchString) => {
    //console.log(searchString);
    let searchDeals = _.chain(this.state.allDealsData)
      .filter((o) => {
        const { dealName, sponsor, stage, assets } = o;
        const newlist = assets.map(
          (a) => a.serialNumber + a.operator + a.subseries
        );
        const str = dealName + sponsor + stage + newlist;
        return str.toUpperCase().includes(searchString.toUpperCase());
      })
      .sortBy(["stage", "sponsor", "dealName"])
      .value();
    const { skip, take, filteredDealsData, allDealsData, checkedDealStage,checkedSponsor } = this.state;
    if (_.isEmpty(searchString)) {

      window.history.pushState({}, null, "?");
      const filteredData = _(allDealsData)
      .filter((deal) => {
        return (
          (_.isEmpty(checkedDealStage)
            ? true
            : checkedDealStage.includes(deal.stage)) &&
          (_.isEmpty(checkedSponsor)
            ? true
            : checkedSponsor.includes(deal.sponsor))
        );
      })
      .sortBy(["stage", "sponsor", "dealName"])
      .value();

      let dealsData = filteredData.slice(skip, skip + take);
      this.setState({
        dealsData: dealsData,
        filteredDealsData: filteredData,
        isloading: false,
      });
    } else {
      let newUrl = `?search=${searchString}`;
      window.history.pushState({}, null, newUrl);
      let newfilter = searchDeals;
      let resize = newfilter.slice(0,take);
      this.setState({
        currPage: 0,
        skip: 0,
        dealsData: resize,
        filteredDealsData: newfilter,
        isloading: false,
      });
    }
  };

  handleEditSTCOL = (bool) => {
    this.setState({
      editSTR_COL: bool,
    });
  };

  handleChangeRowsPerPage = async (event) => {
    this.setState({ isloading: true });
    let rowsPerPage = parseInt(event.target.value, 10);

    let dealsData = this.state.filteredDealsData.slice(0, 0 + rowsPerPage);

    this.setState({
      currPage: 0,
      skip: 0,
      take: rowsPerPage,
      rowsPerPage: rowsPerPage,
      dealsData: dealsData,
      isloading: false,
    });

    this.handleUrlChange();
  };

  handleChangePage = async (event, newPage) => {
    this.setState({ isloading: true });
    const { rowsPerPage, filteredDealsData } = this.state;

    let newSkip = rowsPerPage * (newPage - 1);
    let newTake = rowsPerPage;

    let dealsData = filteredDealsData.slice(newSkip, newSkip + newTake);

    this.setState({
      currPage: newPage - 1,
      skip: newSkip,
      take: newTake,
      dealsData: dealsData,
      isloading: false,
    });

    this.handleUrlChange();
  };

  handleStageChange = async (status, singleStageSelect) => {
    this.setState({ isloading: true });
    let checkedDealStage = this.state.checkedDealStage;
    if (singleStageSelect) {
      checkedDealStage = [status];
    } else {
      let isChecked = this.state.checkedDealStage.some(
        (item) => status === item
      );

      if (isChecked)
        checkedDealStage = checkedDealStage.filter((item) => item !== status);
      else checkedDealStage.push(status);
    }

    if (status === "All") checkedDealStage = [];

    const { checkedSponsor, allDealsData, take } = this.state;

    let filteredData = _(allDealsData)
      .filter((deal) => {
        return (
          (_.isEmpty(checkedDealStage)
            ? true
            : checkedDealStage.includes(deal.stage)) &&
          (_.isEmpty(checkedSponsor)
            ? true
            : checkedSponsor.includes(deal.sponsor))
        );
      })
      .sortBy(["stage", "sponsor", "dealName"])
      .value();

    let dealsData = filteredData ? filteredData.slice(0, take) : null;

    utils.sessionStoreHelper("checked-dealstages", checkedDealStage);

    if (dealsData) {
      this.setState({
        currPage: 0,
        skip: 0,
        checkedDealStage: checkedDealStage,
        dealsData: dealsData,
        filteredDealsData: filteredData,
        isloading: false,
      });
    }

    this.handleUrlChange();
  };

  handleDealAdd = async (_, __, tableName, values, ___) => {
    const { accounts } = this.context;
    var userName = accounts[0].name;

    try {
      const result =
        tableName === "Add Deal"
          ? await this.postNewDealtoDB(values, userName)
          : await this.postPipelineDealtoDB(values, userName);

      if (result.status === response.POST_OK) {
        this.handleSnackerBarMessage(true, "info", "Successfully added Deal.");
        setTimeout(() => {
          window.location.reload(true);
        }, 1500);
      } else {
        this.handleSnackerBarMessage(true, "warning", "Failed to add Deal.");
      }
    } catch (ex) {}
  };

  handleSponsorChange = async (sponsors) => {
    const { checkedDealStage, allDealsData, take } = this.state;

    let filteredData = _(allDealsData)
      .filter((deal) => {
        return (
          (_.isEmpty(checkedDealStage)
            ? true
            : checkedDealStage.includes(deal.stage)) &&
          (_.isEmpty(sponsors) ? true : sponsors.includes(deal.sponsor))
        );
      })
      .sortBy(["stage", "sponsor", "dealName"])
      .value();

    let dealsData = filteredData ? filteredData.slice(0, take) : null;

    if (dealsData) {
      this.setState({
        currPage: 0,
        skip: 0,
        checkedSponsor: sponsors,
        dealsData: dealsData,
        filteredDealsData: filteredData,
        isloading: false,
      });
    }

    this.handleUrlChange();
  };

  handleUrlChange = () => {
    const changeUrl = () => {
      const {
        currPage,
        skip,
        take,
        rowsPerPage,
        checkedDealStage,
        checkedSponsor,
      } = this.state;

      let pageInfo = `?page=${
        currPage + 1
      }&skip=${skip}&take=${take}&rows=${rowsPerPage}`;
      let filterQuery = "";
      if (!_.isEmpty(checkedDealStage))
        filterQuery = filterQuery + `&status=${checkedDealStage}`;
      if (!_.isEmpty(checkedSponsor))
        filterQuery = filterQuery + `&sponsors=${checkedSponsor}`;
      let newUrl = pageInfo + filterQuery;
      window.history.pushState({}, null, newUrl);
    };

    const change = _.debounce(changeUrl, 100);
    change();
  };

  handleSnackerBarOpen = (open) => {
    this.setState({ snackOpen: open });
  };

  handleSnackerBarMessage = (snackOpen, severity, message) => {
    this.setState({
      snackOpen: snackOpen,
      severity: severity,
      message: message,
    });
  };

  generateBreadCrumbs() {
    return [
      {
        label: "Deals",
        type: constants.TEXT,
      },
    ];
  }
  render() {
    const {
      allDealsData,
      filteredDealsData,
      dealsData,
      isloading,
      rowsPerPage,
      currPage,
      searchDealName,
      filteringSponsorsList,
      searchSponsor,
      airlineLogos,
      showFilterOptions,
      snackOpen,
      severity,
      message,
      liabilityData,
      expectedLossRateData,
      checkedSponsor,
      checkedDealStage,
      editSTR_COL,
    } = this.state;
    const userName = this.props.userName
      ? this.props.userName
      : this.props.accountObj.name;

    const sponsorsList = _.sortedUniq(filteringSponsorsList);

    const dealStages = [
      { label: "All", value: "All" },
      { label: "Pipeline", value: "Pipeline" },
      { label: "Closed", value: "Closed" },
      { label: "Managed", value: "Managed" },
      { label: "Expired", value: "Expired" },
      { label: "Lost", value: "Lost" },
      { label: "Info Only", value: "Info Only" },
      { label: "Archive", value: "Archive"}
    ];

    let totalRows = filteredDealsData.length;

    if (!_.isEmpty(allDealsData)) {
      const dealStageCounts = _.countBy(allDealsData, "stage");
      dealStages[0].count = allDealsData.length;
      dealStages[1].count = dealStageCounts.Pipeline;
      dealStages[2].count = dealStageCounts.Closed;
      dealStages[3].count = dealStageCounts.Managed;
      dealStages[4].count = dealStageCounts.Expired;
      dealStages[5].count = dealStageCounts.Lost;
      dealStages[6].count = dealStageCounts["Info Only"];
      dealStages[7].count = dealStageCounts.Archive;
    }
    

    return (
      <ErrorBoundary>
        <Box m={1} flexGrow={1}>
          <VfSnackerBar
            snackOpen={snackOpen}
            setsnackOpen={this.handleSnackerBarOpen}
            severity={severity}
            message={message}
          />

          <VfBreadcrumbContainer
            breadcrumb={
              <VfBreadcrumbs breadcrumbs={this.generateBreadCrumbs()} />
            }
          />

          <Grid container direction='row'>
            <Box style={{ width: "100%" }}>
              <DealFilter
                allDealsData={allDealsData}
                dealStages={dealStages}
                handleChange={this.handleStageChange}
                checkedDealStage={checkedDealStage}
                searchDealName={searchDealName}
                searchDeal={this.searchDeal}
                handleDealAdd={this.handleDealAdd}
                sponsorsList={sponsorsList}
                searchSponsor={searchSponsor}
                // searchSponsorChange={this.searchSponsorChange}
                handleSponsorChange={this.handleSponsorChange}
                checkedSponsor={checkedSponsor}
                handleEditSTCOL={this.handleEditSTCOL}
                editSTR_COL={editSTR_COL}
              />
            </Box>
            <Box
              flexGrow={1}
              mb={1}
              style={{
                display: "grid",
                gridTemplateColumns: showFilterOptions ? "2fr 10fr" : null,
              }}>
              <Box style={{ maxWidth: "100%" }}>
                {editSTR_COL ? (
                  <STRVandCOLR
                    dealsData={dealsData}
                    // handleEditSTCOL={this.handleEditSTCOL}
                    handleSnackerBarMessage={this.handleSnackerBarMessage}
                  />
                ) : (
                  <Deals
                    liabilityData={liabilityData}
                    expectedLossRateData={expectedLossRateData}
                    dealStages={dealStages}
                    currPage={currPage}
                    dealsData={dealsData}
                    isLoading={isloading}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    airlineLogos={airlineLogos}
                    totalRows={totalRows}
                    userName={userName}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
      </ErrorBoundary>
    );
  }
}

export default DealsPage;
