import * as constants from "../../../../constants";

export const updateData = (actionType, sectionIdx, newData, scenario) => {
  scenario.leases = [...scenario.leases];

  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };

  let creditAccounts = [...scenario.leases[sectionIdx].creditAccounts];

  newData.amount = newData.amount && parseFloat(newData.amount);
  switch (actionType) {
    case constants.ADD_ROW:
      creditAccounts.push(newData);
      break;
    case constants.DELETE_ROW:
      creditAccounts = creditAccounts.filter((e, idx) => idx !== newData.id);
      break;
    case constants.UPDATE_ROW:
      creditAccounts[newData.id] = newData;
      break;
    default:
      break;
  }

  scenario.leases[sectionIdx].creditAccounts = creditAccounts;

  return scenario;
};
