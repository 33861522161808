import * as constants from "../../../../constants";
import { updateData as updateUtilization } from "./utilizationByDate";

export function updateUtilizationInfo(
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario
) {
  switch (tableName) {
    case constants.utilizationSelectedComponents:
    case constants.utilizationByDate:
      return updateUtilization(
        tableName,
        actionType,
        assetDetails,
        sectionIdx,
        newData,
        scenario
      );
    default:
      return;
  }
}
