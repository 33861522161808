import { Updater } from "./collateral"
import { aircraftsDetails } from "./aircraftsDetails";

export function MXReserve_CollateralUpdater(
  fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  loanSummary) {

  switch (fieldName) {
    case "Asset Details":
      return aircraftsDetails(actionType, sectionName, sectionIdx, newData, loanSummary);
    default:
      return Updater(fieldName, actionType, sectionName, sectionIdx, newData, loanSummary);
  }
}