import _ from "lodash";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core/";
import DealInfoNavBar from "./DealInfoNavBar";
import SideNavBarListSection from "../../../Common/NavBar/SideNavBarListSection";
// import apiRoutes from "../../../../utils/ApiRoutes";
// import { apiRequest, response } from "../../../../utils/ApiRequest";
import * as constants from "../../../../constants";
// import AircraftSvg from "../../../Common/SvgComponents/AircraftSvg";
// import EngineSvg from "../../../Common/SvgComponents/EngineSvg";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 80,
    height: 80,
    top: -20,
    right: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `#023059`,
  },
  root: {
    display: "flex",
    paddingTop: 24,
    position: "sticky",
    top: 50,
  },
  infoCard: {
    minWidth: 200,
    maxWidth: 240,
    maxHeight: 190,
    backgroundColor: "#FFFFFF",
    borderRadius: "152.73px 12px 12px 12px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    width: "100%",
    padding: "unset",
    minWidth: 200,
    maxWidth: 240,
    margin: "10px 0",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4,
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
  sectionLabel: {
    color: "#023059",
    fontWeight: 600,
    fontSize: 18,
  },
  section: {
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: "white",
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
}));

const SideNavBar = ({
  deal,
  selected,
  navBarSectionItems,
  onSelectedChange,
}) => {
  const classes = useStyles();
  // const [airlineLogo, setAirlineLogo] = useState(null);

  // useEffect(() => {
  //   const fetchLogo = async () => {
  //     // try {
  //     //   const result = await apiRequest({
  //     //     url: apiRoutes.Blob_GetAirlineLogo({
  //     //       operator: assetDetails.operator,
  //     //     }),
  //     //     method: "GET",
  //     //   });
  //     //   if (result.status === response.OK)
  //     //     setAirlineLogo("data:image/png;base64," + result.data);
  //     // } catch (ex) {}
  //   };

  //   fetchLogo();
  // }, [deal.assets]);

  const handleSelectedChange = (value) => {
    onSelectedChange(value);
    const selectElement = document.getElementById(value.label);
    if (selectElement) {
      selectElement.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  return (
    <Box mt={2} className={classes.root}>
      <Box className={classes.drawerContainer}>
        <Paper className={classes.infoCard}>
          <DealInfoNavBar
            generalInfo={[
              {
                icon: null,
                label: deal.dealName,
                caption: deal.sponsor,
                style: {
                  label: {
                    right: -20,
                    textAlign: "right",
                    color: "#023059",
                    fontSize: 20,
                    fontWeight: 500,
                  },
                  caption: {
                    color: "#023059",
                    fontSize: 14,
                    fontWeight: 450,
                    opacity: 0.9,
                    margin: 8,
                  },
                },
              },
            ]}
            details={
              deal
                ? [
                    { label: "Asset Count", value: "Assets:" },
                    {
                      label: "Asset Count",
                      value: deal.assets
                        ? `${deal.assets.length}`
                        : constants.___,
                    },
                  ]
                : null
            }
          />
        </Paper>
        <SideNavBarListSection
          navBarSectionItems={navBarSectionItems}
          selected={selected}
          onSelectedChange={handleSelectedChange}
        />
      </Box>
    </Box>
  );
};

export default SideNavBar;
