import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as d3 from "d3";
import { Grid, Box, Typography } from "@material-ui/core";
import * as Constants from "../../../../constants";

const styles = {
  root: {
    margin: 2,
    paddingTop: 10,
    paddingBottom: 10,
    height: 100,
    width: 250,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    textAlign: "center",
  },
  radialCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  radialProgressChart: {
    marginLeft: 10,
  },
  radialProgressChartText: {
    paddingTop: 20,
    marginLeft: 40,
  },
  liabilityPreview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainTextColor: {
    color: "#023059",
    fontWeight: 600,
  },
  otherText: {
    color: "#023059",
    fontWeight: 600,
    fontSize: 30,
  },
  marginLIBOR: {
    paddingTop: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
};

class LiabilityPreviews extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      DealID: null,
      DealName: null,
      LiborFloorPct: null,
      MarginPct: null,
      RateType: null,
      UpfrontFeePct: null,
      WAL: null,
      Data: [],
      collateral: null,
    };
  }

  componentDidMount = () => {
    const { liabilityData } = this.props;
    if (liabilityData) {
      const collateral =
        liabilityData.liabilityModelData.modelCashflow[0].assetValue +
        liabilityData.liabilityModelData.modelCashflow[0].lcReserve_Gurantee +
        liabilityData.liabilityModelData.modelCashflow[0].cashReserve;

      this.setState({
        DealID: liabilityData.dealId,
        DealName: liabilityData.dealName,
        LiborFloorPct:
          liabilityData.liabilityModelData.modelInfo.referenceRateFloor,
        MarginPct:
          liabilityData.liabilityModelData.modelInfo.rateType === "Fixed"
            ? liabilityData.liabilityModelData.modelInfo.fixedRate
            : liabilityData.liabilityModelData.modelInfo.margin,
        RateType: liabilityData.liabilityModelData.modelInfo.rateType,
        UpfrontFeePct: liabilityData.liabilityModelData.modelInfo.upfrontFee,
        WAL: liabilityData.liabilityModelData.modelInfo.wal,
        Data: liabilityData.liabilityModelData.modelCashflow[0],
        collateral: collateral,
      });

      this.radialProgressChart(
        liabilityData.liabilityModelData.modelCashflow[0].loanOutstanding,
        collateral
      );
      this.walProgressBar(liabilityData);
    }
  };

  setAreaChartGradient = (svg, fillpct) => {
    const defs = svg.append("defs");
    const bgGradient = defs
      .append("linearGradient")
      .attr("id", `bg-fill-partial`)
      .attr("x1", 0)
      .attr("x2", 1)
      .attr("y1", 0)
      .attr("y2", 0);
    bgGradient
      .append("stop")
      .attr("stop-color", `#023059`)
      .attr("offset", "0%");
    bgGradient
      .append("stop")
      .attr("stop-color", `#023059`)
      .attr("offset", fillpct);
    bgGradient
      .append("stop")
      .attr("stop-color", `#d0cfd1`)
      .attr("offset", fillpct);
  };

  walProgressBar = (liabilityData) => {
    var width = 200;
    var height = 35;
    var barSpace = 8;
    var WALValue = liabilityData.liabilityModelData.modelInfo.wal;

    const numOfBars = [];
    for (var i = 0; i < 10; i++) {
      if (WALValue - i > 0 && WALValue - i < 1) {
        numOfBars.push(0.5);
      } else if (i <= WALValue) {
        numOfBars.push(1);
      } else {
        numOfBars.push(0);
      }
    }
    var parent = d3.select("#walBars");

    var svg = parent.append("svg").attr("width", width).attr("height", height);

    this.setAreaChartGradient(svg, `${_.round((WALValue % 1) * 100, 2)}%`);

    svg
      .selectAll("rect")
      .data(numOfBars)
      .enter()
      .append("rect")
      .attr("x", (d, i) => i * (barSpace + 12))
      .attr("y", (d, i) => 0)
      .attr("width", 12)
      .attr("height", 40)
      .attr("fill", (d) => {
        if (d === 1) return "#023059";
        else if (d === 0.5) return "url(#bg-fill-partial)";
        else return "#d0cfd1";
      });
  };

  radialProgressChart = (loanOutstanding, collateral) => {
    var radius = 40;
    var border = 5;
    var padding = 0;
    var startPercent = 0;
    var endPercent =
      collateral && collateral !== 0
        ? (loanOutstanding / collateral).toFixed(2)
        : 0;
    let color = null;

    if (endPercent > 0.85) {
      color = "#fa4b00";
    } else if (endPercent > 0.6) {
      color = "#F7B500";
    } else {
      color = "#63AB17";
    }

    var twoPi = Math.PI * 2;
    var formatPercent = d3.format(".0%");
    var boxSize = (radius + padding) * 2;

    var count = Math.abs((endPercent - startPercent) / 0.01);
    var step = endPercent < startPercent ? -0.01 : 0.01;

    var arc = d3
      .arc()
      .startAngle(0)
      .innerRadius(radius)
      .outerRadius(radius - border)
      .cornerRadius(50);

    var parent = d3.select("#radialProgressChart");

    var svg = parent
      .append("svg")
      .attr("width", boxSize)
      .attr("height", boxSize);

    var gradient = svg
      .append("svg:defs")
      .append("svg:linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%")
      .attr("y1", "50%")
      .attr("x2", "50%")
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    gradient
      .append("svg:stop")
      .attr("offset", "0%")
      .attr("stop-color", color)
      .attr("stop-opacity", 1);

    gradient
      .append("svg:stop")
      .attr("offset", "100%")
      .attr("stop-color", color)
      .attr("stop-opacity", 1);

    var field = svg
      .append("g")
      .attr("transform", "translate(" + boxSize / 2 + "," + boxSize / 2 + ")");

    var meter = field.append("g").attr("class", "progress-meter");

    meter
      .append("path")
      .attr("class", "background")
      .attr("fill", "#D8D8D8")
      .attr("fill-opacity", 1)
      .attr("d", arc.endAngle(twoPi));

    var front = meter
      .append("path")
      .attr("class", "foreground")
      .attr("fill", "url(#gradient)")
      .attr("fill-opacity", 1);

    var numberText = d3
      .select("#radialProgressChartText")
      .append("text")
      .attr("fill", "black")
      .attr("text-anchor", "middle")
      .style("text-align", "center");

    function updateProgress(progress) {
      // foreground.attr('d', arc.endAngle(twoPi * progress));
      front.attr("d", arc.endAngle(twoPi * progress));
      numberText.text(formatPercent(progress));
    }

    var progress = startPercent;

    (function loops() {
      updateProgress(progress);

      if (count > 0) {
        count--;
        progress += step;
        setTimeout(loops, 10);
      }
    })();
  };

  render() {
    const { classes } = this.props;
    const { Data, MarginPct, RateType, WAL, collateral } = this.state;

    return (
      <Grid container spacing={2} style={{ minWidth: 1250 }}>
        <Grid item sm={12} className={classes.liabilityPreview}>
          <Box className={classes.root}>
            <Typography color='textPrimary' align='center' variant='body2'>
              Loan Balance
            </Typography>
            <Typography
              className={classes.mainTextColor}
              align='center'
              variant='h4'>
              {`$${_.round(Data.loanOutstanding / 1000000, 1).toFixed(1)}M`}
            </Typography>
            <Typography color='textSecondary' align='center' variant='caption'>
              {`${moment.utc(Data.paymentDate).format(Constants.DATE_FORMAT)}`}
            </Typography>
          </Box>
          <Box className={classes.root}>
            <Typography color='textPrimary' align='center' variant='body2'>
              Collateral
            </Typography>
            <Typography
              className={classes.mainTextColor}
              align='center'
              variant='h4'>
              {`$${_.round(collateral / 1000000, 1).toFixed(1)}M`}
            </Typography>
            <Typography color='textSecondary' align='center' variant='caption'>
              {`${moment.utc(Data.paymentDate).format(Constants.DATE_FORMAT)}`}
            </Typography>
          </Box>
          <Box className={`${classes.root} ${classes.marginLIBOR}`}>
            <Box>
              <Typography
                className={classes.otherText}
                align='center'
                variant='h4'>
                {MarginPct > 0.1
                  ? `${_.round(MarginPct * 100, 2).toFixed(2)}%`
                  : `${_.round(MarginPct * 100, 3).toFixed(3)}%`}
              </Typography>
              <Typography
                color='textSecondary'
                align='center'
                variant='caption'>
                {RateType === "Fixed" ? "FixedRate" : "Margin"}
              </Typography>
            </Box>
            {/* <Box>
              <Typography
                className={classes.otherText}
                align='center'
                variant='h4'>
                {LiborFloorPct > 0.1
                  ? `${_.round(LiborFloorPct * 100, 2).toFixed(2)}%`
                  : `${_.round(LiborFloorPct * 100, 3).toFixed(3)}%`}
              </Typography>
              <Typography
                color='textSecondary'
                align='center'
                variant='caption'>
                {"LIBOR Floor"}
              </Typography>
            </Box> */}
          </Box>
          <Box
            className={classes.root}
            style={{ padding: 12, textAlign: "unset" }}>
            <Typography
              className={classes.mainTextColor}
              align='left'
              variant='body2'>
              Weighted Average Life
            </Typography>
            <Typography color='textSecondary' align='left' variant='caption'>
              {`${_.round(WAL, 2).toFixed(2)} Years`}
            </Typography>
            <Box id={"walBars"}></Box>
          </Box>
          <Box className={`${classes.root} ${classes.radialCard}`}>
            <Box
              id={"radialProgressChart"}
              className={classes.radialProgressChart}></Box>
            <Box className={classes.radialProgressChartText}>
              <Typography
                className={classes.mainTextColor}
                align='center'
                variant='h4'
                id={"radialProgressChartText"}></Typography>
              <Typography color='textSecondary' align='left' variant='caption'>
                LTV
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(LiabilityPreviews);
