import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Table, TableContainer, TablePagination } from "@material-ui/core";

import MultiFunctionTableHead from "./MultiFunctionTableHead";
import MultiFunctionTableBody from "./MultiFunctionTableBody";
import MultiFunctionTableFooter from "./MultiFunctionTableFooter";
import TableNamePanel from "../../TableNamePanel";

import _ from "lodash";

const styles = {
  rectangle13: {
    boxShadow:
      "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "16px 16px",
    overflowX: "auto",
    borderRadius: 12,
  },
  noShadow: {
    padding: "16px 16px",
    overflowX: "auto",
  },
  noPadding: {
    overflowX: "auto",
  },
  container: {},
  table: {},
  gap: {
    marginBottom: 15,
    // padding: "0 16px"
  },
};

class MultiFunctionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      order: "asc",
      orderBy: "",
    };
  }

  comparator = (valueA, valueB, order, orderBy) => {
    let a = null,
      b = null;

    if (valueA && _.isObjectLike(valueA[orderBy])) {
      a = valueA[orderBy][orderBy];
      b = valueB[orderBy][orderBy];
    } else if (valueA) {
      a = valueA[orderBy];
      b = valueB[orderBy];
    } else console.log(valueA, valueB);

    // equal items sort equally
    if (a === b) {
      return 0;
    }
    // nulls sort after anything else
    else if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    }

    // if (_.isString(a)) a = a.toLowerCase()
    // if (_.isString(b)) b = b.toLowerCase()

    // otherwise, if we're ascending, lowest sorts first
    else if (order === "asc") {
      return a < b ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
      return a < b ? 1 : -1;
    }
  };

  getComparator = (order, orderBy) => {
    return (a, b) => this.comparator(a, b, order, orderBy);
    // return order === 'desc'
    //   ? (a, b) => this.descendingComparator(a, b, orderBy)
    //   : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setOrder(isAsc ? "desc" : "asc");
    this.setOrderBy(property);
  };

  setOrder = (value) => {
    this.setState({ order: value });
  };

  setOrderBy = (value) => {
    this.setState({ orderBy: value });
  };

  componentDidMount = () => {
    const { tableData } = this.props;

    if (tableData)
      this.setState({
        orderBy:
          tableData.data[0] && "id" in tableData.data[0]
            ? "id"
            : tableData.columns[0].field,
      });
  };

  componentDidUpdate(prevProps) {
    const { optionsItems } = prevProps.tableData;

    if (optionsItems && optionsItems.filter) {
      const { filterList } = optionsItems.filter;

      for (let key in filterList) {
        if (filterList[key]) {
          if (filterList[key].length === 0) {
            this.setState((prevState) => {
              if (prevState.page !== 0) {
                return { page: 0 };
              }
            });
            return;
          }
        }
      }
    }
  }

  filterData = () => {
    const { data, optionsItems } = this.props.tableData;

    const { filterList } = optionsItems.filter;

    let query = {};
    for (let key in filterList) {
      if (filterList[key]) {
        if (filterList[key].length === 0) return false;
        query[key] = _.without(filterList[key], "All");
      }
    }

    let displayData = data;
    Object.keys(query).map((key) => {
      displayData = displayData.filter((o) => {
        if (query[key] && !query[key].length) return false;

        if (o[key] === undefined || !query[key].includes(o[key])) {
          return false;
        }
        return true;
      });
      return null;
    });

    return displayData;
  };

  generateButtons = () => {
    const { tableData } = this.props;
    const { columns, options, optionsItems } = tableData;

    let buttons = [];

    if (options) {
      if (options.filter)
        buttons.push(optionsItems.filter.content(columns, optionsItems.filter));

      if (options.refresh)
        buttons.push(optionsItems.refresh.content(optionsItems.refresh));

      if (options.reset)
        buttons.push(optionsItems.reset.content(optionsItems.reset));

      if (options.threeDotButton)
        buttons.push(
          optionsItems.threeDotButton.content(optionsItems.threeDotButton.items)
        );
    }
    return buttons;
  };

  render() {
    const {
      classes,
      tableData,
      bgcolor,
      containerMaxHeight,
      noShadow = false,
      noPadding = false,
    } = this.props;
    const { options, optionsItems, data } = tableData;
    const { order, orderBy } = this.state;
    const displayData = options && options.filter ? this.filterData() : data;

    let filteredTotal = _.cloneDeep(tableData.total);
    if (options && options.filter && !_.isEmpty(displayData)) {
      Object.keys(displayData[0]).forEach((element) => {
        const checkValue = tableData.total[element];
        if (typeof checkValue === "number") {
          filteredTotal[element] = _.sumBy(displayData, (d) => d[element]);
        }
      });
    }

    const pagination = tableData.options && tableData.options.pagination;
    const sorting = tableData.options && tableData.options.sorting;
    const buttons = tableData.options && this.generateButtons();

    const rowsPerPageOptions = displayData
      ? displayData.length < 15
        ? [5, 10, displayData.length]
        : [5, 10, 15, displayData.length]
      : null;

    return (
      <Box
        id='multifunctionaltable'
        className={
          tableData.hideHeaders
            ? null
            : noShadow
            ? classes.noShadow
            : noPadding
            ? classes.noPadding
            : classes.rectangle13
        }
        style={{ width: "100%", marginTop: tableData.options?.margtop ? tableData.options.margtop : "" }}
        bgcolor={bgcolor || "#FFFFFF"}>
        <Box className={tableData.hideHeaders ? null : classes.gap}>
          {tableData.label && (
            <TableNamePanel
              label={tableData.label}
              caption={tableData.caption}
              buttons={buttons}
            />
          )}
        </Box>
        <TableContainer
          component={Box}
          style={{ maxHeight: containerMaxHeight || 700 }}>
          <Table
            stickyHeader
            aria-label='simple table'
            className={classes.tableContainer}>
            {!tableData.hideHeaders ? (
              <MultiFunctionTableHead
                columnHeaders={tableData.columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
            ) : (
              <></>
            )}

            {displayData ? (
              <MultiFunctionTableBody
                columnHeaders={tableData.columns}
                rows={
                  pagination && sorting
                    ? this.stableSort(
                        displayData,
                        this.getComparator(order, orderBy)
                      ).slice(
                        optionsItems.pagination.page *
                          optionsItems.pagination.rowsPerPage,
                        optionsItems.pagination.page *
                          optionsItems.pagination.rowsPerPage +
                          optionsItems.pagination.rowsPerPage
                      )
                    : pagination && !sorting
                    ? displayData.slice(
                        optionsItems.pagination.page *
                          optionsItems.pagination.rowsPerPage,
                        optionsItems.pagination.page *
                          optionsItems.pagination.rowsPerPage +
                          optionsItems.pagination.rowsPerPage
                      )
                    : sorting && !pagination
                    ? this.stableSort(
                        displayData,
                        this.getComparator(order, orderBy)
                      )
                    : displayData
                }
              />
            ) : null}

            {filteredTotal ? (
              <MultiFunctionTableFooter
                columnHeaders={tableData.columns}
                data={filteredTotal}
              />
            ) : null}
          </Table>
        </TableContainer>

        {displayData && pagination ? (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={displayData.length}
            rowsPerPage={optionsItems.pagination.rowsPerPage}
            page={optionsItems.pagination.page}
            onChangePage={(_, newPage) =>
              optionsItems.pagination.handleChangePage(
                optionsItems.pagination.tableName,
                newPage
              )()
            }
            onChangeRowsPerPage={(event) =>
              optionsItems.pagination.handleChangePage(
                optionsItems.pagination.tableName,
                0
              )(parseInt(event.target.value, 10))
            }
          />
        ) : null}
      </Box>
    );
  }
}

export default withStyles(styles)(MultiFunctionTable);
