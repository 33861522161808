import React, { Component } from "react";
import { Button, Box, Typography, Card, CardContent } from "@material-ui/core";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

class SessionData extends Component {
  render() {
    let { accountObj } = this.props;
    return (
      <>
        <Card>
          <CardContent style={{ padding: "10px" }}>
            <Typography variant="h5">Session Data</Typography>
            <Typography color="textSecondary">session data /</Typography>
          </CardContent>
        </Card>
        <Box m={1}>
          {!accountObj.account ? (
            <Box
              display="flex"
              width={1}
              component="span"
              style={{ marginTop: "30px" }}
            >
              <Button
                variant="contained"
                style={{ width: "100%" }}
                color="primary"
                size={"large"}
                onClick={accountObj.handleSignIn}
              >
                Sign In
              </Button>
            </Box>
          ) : (
            <>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                color="secondary"
                size={"large"}
                onClick={accountObj.onSignOut}
              >
                Sign Out
              </Button>
              {/* <button onClick={this.props.onSignOut}>Sign Out</button>
                                <button onClick={this.props.onRequestEmailToken}>Request Email Permission</button> */}
            </>
          )}
          <section className="data">
            {accountObj.account && (
              <div className="data-account">
                <h2>Session Account Data</h2>
                <Json data={accountObj.account} />
              </div>
            )}
            {accountObj.accessToken && (
              <div className="data-accessToken">
                <h2>Access Token</h2>
                <p style={{ wordBreak: "break-all" }}>
                  {accountObj.accessToken}
                </p>
              </div>
            )}
            {accountObj.graphProfile && (
              <div className="data-graph">
                <h2>Graph Profile Data</h2>
                <Json data={accountObj.graphProfile} />
              </div>
            )}
            {accountObj.emailMessages && (
              <div className="data-graph">
                <h2>Messages Data</h2>
                <Json data={accountObj.emailMessages} />
              </div>
            )}
          </section>
          <section>
            {accountObj.account && (
              <button onClick={this.handleClick}>Activate Lasers</button>
            )}
          </section>
        </Box>
      </>
    );
  }
}

export default SessionData;
