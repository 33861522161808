import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  ClickAwayListener,
  Divider,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Grow,
} from "@material-ui/core";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dropDown: {
    transformOrigin: "center top",
  },
  divider: { opacity: 0.3, backgroundColor: "#023059" },
  icon: {
    fontSize: 14,
    verticalAlign: "text-top",
  },
  menuItemRoot: { paddingBottom: 3, paddingTop: 0 },
  text: {
    color: "#023059",
    lineHeight: 1.8,
    fontSize: 14,
    zIndex: 20,
    "&:hover": {
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  textSelected: {
    fontWeight: 600,
  },
  textNoHoverEffect: {
    color: "#023059",
    lineHeight: 1.8,
    fontSize: 14,
  },
});

const VfDropdown = ({ selectedItem, itemProps, items }) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box className={items.length ? classes.dropDown : null}>
      <Typography
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={items.length ? classes.text : classes.textNoHoverEffect}
        onClick={handleToggle}
      >
        <span style={{ float: "left" }} mr={1}>
          {itemProps && itemProps.icon} {selectedItem}
        </span>
        <span>
          {items.length ? (
            open ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )
          ) : null}
        </span>
      </Typography>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{
          zIndex: 50,
          maxHeight: 1,
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} className={classes.drowDown}>
            <Paper square={true}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  elevation={1}
                  autoFocusItem={open}
                  id="menu-list-grow"
                >
                  {items.map((item, idx) => (
                    <MenuItem
                      classes={{ root: classes.menuItemRoot }}
                      key={idx}
                      onClick={(event) => {
                        item.props.handleClick(item.id);
                        handleClose(event);
                      }}
                    >
                      <span
                        className={
                          selectedItem === item.label
                            ? `${classes.text} ${classes.textSelected}`
                            : classes.text
                        }
                      >
                        {item.id === -1 ? (
                          <Divider className={classes.divider} />
                        ) : null}
                        {item.label}
                      </span>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default VfDropdown;
