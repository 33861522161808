import _ from "lodash";
import React from "react";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import VfSelect from "../../../Common/UtilComponents/VfSelect";

export default function mapToViewModel_airframeDetails(
  airframeData,
  handleSelectChange,
  props,
  selectedSubseries,
) {
  const items = airframeData.map( e => {
    return {
      label: e.subseries,
      value: e.subseries,
    }
  })
  let selectedAirframeData = _.find(airframeData, { 'subseries': selectedSubseries });
  return {
    label: "Airframe Details",
    tableName: "Airframe Details",
    caption: null,
    showOptions: true,
    props,
    tableType: constants.DATA_TABLE,
    columns: [
      {
        label: ["Aircraft Subseries"],
        field: "aircraftSubseries",
        captions: null,
        span: [4, 4],
        content: (_) => {
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedSubseries? selectedSubseries : ""}
              type={"aircraftSubseries"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={200}
            />
          );
        },
      },
      {
        label: ["Aircraft Series"],
        field: "aircraftSeries",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["Aircraft Masterseries"],
        field: "aircraftMasterseries",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["Manufacturer"],
        field: "manufacturer",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["Model Classic"],
        field: "modelClassic",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["Usage"],
        field: "usage",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["Number of Engines"],
        field: "numOfEngines",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["Daily Flight Hours"],
        field: "dailyFlightHours",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, null, 2),
      },
      {
        label: ["Daily Flight Cycles"],
        field: "dailyFlightCycles",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, null, 2),
      },
      {
        label: ["Landing Gear Run-Out Value"],
        field: "lgRunoutValue",
        editable: true,
        required: false,
        type: constants.NUMBER,
        inputFieldType: constants.TEXT_FIELD,
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, null, 2),
      },
    ],
    data: {
      genericId: selectedAirframeData.id,
      aircraftSeries: selectedAirframeData.series,
      aircraftMasterseries: selectedAirframeData.masterseries,
      manufacturer: selectedAirframeData.manufacturer,
      modelClassic: selectedAirframeData.modelClass,
      usage: selectedAirframeData.usage,
      numOfEngines: selectedAirframeData.numberOfEngines,
      dailyActiveHours: selectedAirframeData.activeHour_Daily,
      dailyFlightHours: selectedAirframeData.flightHour_Daily,
      dailyFlightCycles: selectedAirframeData.flightCycle_Daily,
      lgRunoutValue: selectedAirframeData.landingGear_Runout
    },
  };
}
