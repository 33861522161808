import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { margin: "0 15px", paddingTop: 10, paddingBottom: 10 },
}));

const AssetInfoNavBar = ({ generalInfo, details }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box mt={1} mr={-1}>
        {generalInfo.map((o, idx) => (
          <Grid key={idx} container spacing={1}>
            <Grid item xs={2}>
              <Typography variant="subtitle1">{o.icon}</Typography>
            </Grid>
            <Grid item xs={10} >
              <Typography variant="subtitle1" style={o.style.label}>
                {o.label}
                {o.caption && (
                  <Typography
                    display="block"
                    style={o.style.caption}
                  >
                    {o.caption}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
      {details ? (
        <Box style={{ display: "flex", justifyContent: "space-between"}}>
          {details.map((detail, idx) => (
            <Box key={idx}>
              <Tooltip title={detail.label} aria-label={detail.label}>
                <Typography
                  style={{ fontSize: 14, fontWeight: 500, color: "#023059", textAlign: "center" }}
                  variant="subtitle1"
                >
                  {detail.value}
                </Typography>
              </Tooltip>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default AssetInfoNavBar;
