import _ from "lodash";
import React, { useState, useRef } from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  Popper,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import * as constants from "../../constants";
import { makeStyles } from "@material-ui/core/styles";
import VfMenuList from "./UtilComponents/VfMenuList";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex:"auto",
  },
  fontSmall: { fontSize: "1.2rem" },
  popper: { zIndex: 151 },
  grow: {
    transformOrigin: "center bottom",
    backgroundColor: "#FFFFFF",
    marginRight: theme.spacing(2),
  },
}));

const ThreeDotButton = ({ fontSize, options, padding, color = "#263238" }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // 1. index is for locating the row the user is clicking from
  //    Table: Portfoloio -> Fleet Summary, three dot button
  // 2. id is used for uniquely identifying locating which action has been clicked
  // 3. Action param is used for distinguishing
  //    the special case (described in 1)
  //    from other cases (described in 2)
  const handleItemClick = (e, index, id, action) => {
    const obj = _.find(options, (o) => o.id === id);

    switch (action) {
      case constants.CHANGE_COMMON_SCENARIO:
        obj.handleClick(obj.subMenuItems[index].label);
        break;
      default:
        obj.handleClick();
        setOpen(false);
        break;
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const renderDropDownButton = (button) => {
    return (
      <Tooltip title='Open' arrow>
        <IconButton
          disableRipple={true}
          ref={anchorRef}
          aria-label='more'
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          style={{ padding }}>
          <SvgIcon
            classes={{ root: fontSize === "small" ? classes.fontSmall : null }}
            style={{ color }}
            children={button}
          />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Box className={classes.root} id='threedotbutton'>
      {renderDropDownButton(<MoreVert />)}

      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} className={classes.grow}>
              <VfMenuList
                open={open}
                options={options}
                onItemClick={handleItemClick}
              />
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

export default ThreeDotButton;
