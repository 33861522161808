import React, { useState, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import DataTable from "../../../../../../Common/Tables/DataTable/DataTable";
import * as AssetDetailViewModel from "../../../../../mapToViewModels";
import * as AssetInfoViewModel from "../../../../../mapToViewModels/assetSummary/aircraft/assetDetails";
import VfSnackerBar from "../../../../../../Common/UtilComponents/VfSnackerBar";
import CashReserveAccounts from "./CashReserveAccounts/CashReserveAccounts";
import Appraisals from "./Appraisals";
import ScenarioSummaries from "./ScenarioSummaries";
import { StaticDataContext } from "../../../../../../../context/StaticDataContext";
import FleetDataDialog from "../../../../../../AssetCreation/Components/AssetComponent/FleetDataDialog";
// From new MsalProvider
import { Edit } from "@material-ui/icons";
import { useMsal } from "@azure/msal-react";
import * as constants from "../../../../../../../constants";
import {apiRequest, response} from "../../../../../../../utils/ApiRequest";
import apiRoutes from "../../../../../../../utils/ApiRoutes";

const AssetSummaries = ({ assetDetails, generateEditableProps }) => {
  const msalContext = useMsal();
  const { sponsorsData } = useContext(StaticDataContext);

  const [snackOpen, setsnackOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const formatData = (data, componentType, additionalProps, additionalData) => {
    let props = generateEditableProps(null, componentType);

    return {
      data: AssetDetailViewModel.mapToViewModel(
        data,
        componentType,
        props,
        null,
        additionalProps,
        null,
        additionalData
      ),
      props,
      componentType,
    };
  };
  const changefgid = async (fgid) => {
      var meprops = generateEditableProps(null,"Asset Details");
      let acid = assetDetails.id;
      let user = msalContext.accounts[0].name;
      try {
         var reqdata = await apiRequest(
          {
            url: apiRoutes.FleetData_UpdateFGID({acid,fgid,user}),
            method: "POST",
          },
          msalContext
        );

        if (reqdata.status === response.OK){
          meprops.funcs.handleValueChange("FGID")("Fleet Data",0,"Asset Details",reqdata.data)
        };
      } catch (ex) {}


  };
  const purchasePrice = formatData(
    assetDetails,
    "Purchase Price",
    null,
    sponsorsData
  );
  const detailsTable = assetDetails.landingGears ?  AssetInfoViewModel.mapToViewModel_assetDetails(
    assetDetails,
    generateEditableProps(null, "Asset Details")
  ) : null;
  if(detailsTable){
    detailsTable.tableThreeDotBtnOptions = [
      {
        id: 0,
        icon: Edit,
        label: constants.EDIT,
      },
      {
        id: 1,
        icon: Edit,
        label: `Change FGID`,
        handleClick: () => {
          setDialogOpen(true);
        },
      }
    ];
  }

  return (
    <Box>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        message={message}
        duration={2000}
      />
      <FleetDataDialog
          msn={assetDetails.msn}
          manufacturer={assetDetails.manufacturer}
          openme={dialogOpen}
          setopenme={setDialogOpen}
          updatefcn={changefgid
            //(x) => detailsTable.props.funcs.handleValueChange("FGID")("Fleet Data",0,"Asset Details",x)
          }
      /> 

      <Grid container spacing={2}>
        {assetDetails.landingGears ? (
          <Grid item sm={12}>
            <DataTable
              tableData={detailsTable}
            />
          </Grid>
        ) : null}

        <Grid item sm={12}>
          <DataTable tableData={purchasePrice.data} />
        </Grid>

        <Appraisals
          assetDetails={assetDetails}
          generateEditableProps={generateEditableProps}
          setsnackOpen={setsnackOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          msalContext={msalContext}
        />

        <ScenarioSummaries
          scenarioSummaries={assetDetails.scenarioSummaries}
          formatData={formatData}
        />

        <Grid item sm={12} >
          <CashReserveAccounts
            assetDetails={assetDetails}
            formatData={formatData}
            setsnackOpen={setsnackOpen}
            setSeverity={setSeverity}
            setMessage={setMessage}
            msalContext={msalContext}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetSummaries;
