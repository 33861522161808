import _ from "lodash";
import moment from "moment";
import React from "react";
import * as utils from "../../../../../../../utils/utils";
import VfChart from "../../../../../../Common/UtilComponents/VfChart";

const getResidualValue = (report) => {
  const evaluateDate = new Date(report.leases[report.leases.length-1].endDate);
  const currValuation = _.find(report.appraisal.futureValuations, (d) => {
    return new Date(d.date) > evaluateDate;
  });

  const nextEvaluationDate = new Date(moment.utc(evaluateDate).add(1, "years"));
  const nextValuation = _.find(report.appraisal.futureValuations, (d) => {
    return new Date(d.date) > nextEvaluationDate;
  });

  const currValuationDate = new Date(currValuation.date);
  const nextValuationtDate = new Date(nextValuation.date);

  return _.round(
    utils.linearInterpolation(
      currValuationDate,
      evaluateDate,
      nextValuationtDate,
      currValuation[
        report.scenario.simParameters
          ? `halfLife${report.scenario.simParameters.hlValueType}_inflated`
          : "halfLifeBase_inflated"
      ],
      nextValuation[
        report.scenario.simParameters
          ? `halfLife${report.scenario.simParameters.hlValueType}_inflated`
          : "halfLifeBase_inflated"
      ],
      "days"
    ) * Math.pow(10, 6)
  );
};

const getChartData = (report) => {
  if (_.isEmpty(report.leases)) {
    return {
      evaluateDates: [],
      cumuTotalCashFlow: [],
      cumuleaseRents: [],
      cumusupplementalRents: [],
      MXEvents: [],
      mxCostLessorContributions: [],
      redeliveryComps: [],
      rrrCosts: [],
      otherCFs: [],
    };
  }

  const chartCutOffDate = moment
    .utc(report.leases[report.leases.length - 1].endDate)
    .add(6, "M")
    .endOf("month");
  const evaluatingDatesData = Object.entries(report.cashFlows).filter(
    ([key, value]) => moment.utc(key).isBefore(chartCutOffDate, "month")
  );
  const evaluateDates = evaluatingDatesData.map((data) =>
    moment.utc(data[0]).format("MM-YYYY")
  );

  const totalCashFlowSeries = evaluatingDatesData.map((data) =>
    _.round(
      data[1].rent +
        data[1].mxReserveToLessor +
        data[1].mxExpenseFromLessor +
        data[1].redeliveryComp +
        data[1].rrrCost +
        data[1].otherCF
    )
  );
  const leaseRents = evaluatingDatesData.map((data) => data[1].rent);
  const supplementalRents = evaluatingDatesData.map((data) =>
    _.round(data[1].mxReserveToLessor)
  );
  const mxCostLessorContributions = evaluatingDatesData.map(
    (data) => data[1].mxExpenseFromLessor
  );
  const redeliveryComps = evaluatingDatesData.map(
    (data) => data[1].redeliveryComp
  );
  const rrrCosts = evaluatingDatesData.map((data) => data[1].rrrCost);
  const otherCFs = evaluatingDatesData.map((data) => data[1].otherCF);
  const residualValue = getResidualValue(report);
  const getIndexforRV = evaluateDates.indexOf(
    moment.utc(report.leases[report.leases.length-1].endDate).endOf("month").format("MM-YYYY")
  );
  const cumuTotalCashFlow = _.reduce(
    totalCashFlowSeries,
    function (acc, n) {
      acc.push((acc.length > 0 ? acc[acc.length - 1] : 0) + n);
      return acc;
    },
    []
  );
  const cumuTotalCashFlowwithRV = cumuTotalCashFlow.map((value, index) => {
    if (index >= getIndexforRV) {
      return value + residualValue;
    } else {
      return value;
    }
  });

  const cumuleaseRents = _.reduce(
    leaseRents,
    function (acc, n) {
      acc.push((acc.length > 0 ? acc[acc.length - 1] : 0) + n);
      return acc;
    },
    []
  );
  const cumusupplementalRents = _.reduce(
    supplementalRents,
    function (acc, n) {
      acc.push((acc.length > 0 ? acc[acc.length - 1] : 0) + n);
      return acc;
    },
    []
  );
  const saleValue = _.round(
    residualValue + evaluatingDatesData[getIndexforRV][1].valueAboveHalfLife
  );
  const totalEOLCompNet = _.round(
    _.sumBy(report.eolEvents, function (data) {
      return (
        data.lesseeToLessor + data.lessorToLessee + data.cashReserveToLessee
      );
    })
  );
  const getMXEvents = [];
  getMXEvents.push({
    x: getIndexforRV,
    y: 2000000,
    toolTipHtml:
      `<table><tr><th colspan="2"><h3>EOL</h3></th></tr>` +
      `<tr><th><small>(U.S. $)</small>:</th><td>${totalEOLCompNet.toLocaleString()}</td></tr>` +
      `<tr><th>Event Date:</th><td>${moment
        .utc(report.leases[0].endDate)
        .format("MM-YYYY")}</td></tr></table>`,
    eventType: "R.E.",
    date: moment.utc(report.leases[0].endDate).format("MM-YYYY"),
    // cashReserve: residualValue,
  });
  getMXEvents.push({
    x: getIndexforRV,
    y: 4000000,
    toolTipHtml:
      `<table><tr><th colspan="2"><h3>Sale</h3></th></tr>` +
      `<tr><th><small>(U.S. $)</small>:</th><td>${saleValue.toLocaleString()}</td></tr>` +
      `<tr><th>Event Date:</th><td>${moment
        .utc(report.leases[0].endDate)
        .format("MM-YYYY")}</td></tr></table>`,
    eventType: "S.V.",
    date: moment.utc(report.leases[0].endDate).format("MM-YYYY"),
    // cashReserve: residualValue,
  });

  report.mxEvents.forEach((data) => {
    let eventDate = moment.utc(data.eventStartDate).format("MM-YYYY");
    let cashReserve = _.round(data.fromCashReserve);
    let creditReserve = _.round(data.fromCreditReserve);
    let lesseeContribution = _.round(data.fromLesseeContribution);
    let lessorContribution = _.round(data.fromLessorContribution);
    let toolTipHtml = "";
    let itemindex = null;
    let type = null;
    if (data.type.includes("LLP")) {
      type = "ELLP";
    } else if (data.type.includes("PR")) {
      type = "EPR";
    } else if (data.type.includes("Check")) {
      type = "HMV";
    } else if (data.type.includes("Gear")) {
      type = "LG";
    } else if (data.type.includes("Swap")) {
      type = "ES";
    } else if (data.type.includes("EngineOther")) {
      type = "EP_O";
    } else {
      type = data.type;
    }

    if (evaluateDates.includes(eventDate)) {
      itemindex = evaluateDates.indexOf(eventDate);
      toolTipHtml = `<table><tr><th colspan="2"><h3>${type} Payment, Funded By</h3></th></tr>`;
      if (
        cashReserve ||
        creditReserve ||
        lesseeContribution ||
        lessorContribution
      ) {
        if (cashReserve !== 0) {
          toolTipHtml =
            toolTipHtml +
            `<tr><th>C.R. <small>(U.S. $)</small>:</th><td>${cashReserve.toLocaleString()}</td></tr>`;
        }
        if (creditReserve !== 0) {
          toolTipHtml =
            toolTipHtml +
            `<tr><th>LSR. CTB. <small>(U.S. $)</small>:</th><td>${creditReserve.toLocaleString()}</td></tr>`;
        }
        if (lesseeContribution !== 0) {
          toolTipHtml =
            toolTipHtml +
            `<tr><th>L.C. <small>(U.S. $)</small>:</th><td>${lesseeContribution.toLocaleString()}</td></tr>`;
        }
        if (lessorContribution !== 0) {
          toolTipHtml =
            toolTipHtml +
            `<tr><th>LSE. CTB. <small>(U.S. $)</small>:</th><td>${lessorContribution.toLocaleString()}</td></tr>`;
        }
      } else {
        toolTipHtml =
          toolTipHtml + "<tr><td><h5>No Funding for this Event</h5></td></tr>";
      }
      toolTipHtml =
        toolTipHtml +
        `<tr><th>Event Date:</th><td>${eventDate}</td></tr></table>`;
      let repeatDates = _.filter(getMXEvents, ["x", itemindex]);
      // handle multiple events on the same date
      if (repeatDates.length >= 1) {
        getMXEvents.push({
          x: itemindex,
          y: 2000000 * (repeatDates.length + 1),
          eventType: type,
          toolTipHtml: toolTipHtml,
        });
      } else {
        getMXEvents.push({
          x: itemindex,
          y: 2000000,
          eventType: type,
          toolTipHtml: toolTipHtml,
        });
      }
    }
  });

  return {
    evaluateDates: evaluateDates,
    cumuTotalCashFlow: cumuTotalCashFlowwithRV,
    cumuleaseRents: cumuleaseRents,
    cumusupplementalRents: cumusupplementalRents,
    MXEvents: getMXEvents,
    mxCostLessorContributions: mxCostLessorContributions,
    redeliveryComps: redeliveryComps,
    rrrCosts: rrrCosts,
    otherCFs: otherCFs,
  };
};

const assetCashFlowTimeSeriesChart = ({ report }) => {
  const chartData = getChartData(report);

  const options = {
    title: {
      text: "Asset Cash Flow Time Series",
    },
    credits: { enabled: false },
    xAxis: {
      categories: chartData.evaluateDates,
    },
    yAxis: {
      min: 0,
    },
    tooltip: {
      useHTML: true,
      split: true,
      distance: 30,
      padding: 5,
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#bababa",
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
      spline: {
        marker: {
          enabled: false,
        },
        dashStyle: "Dash",
      },
      bubble: {
        maxSize: 20,
        dataLabels: {
          enabled: true,
          format: "{point.eventType}",
          allowOverlap: true,
          color: "#000",
        },
      },
    },
    series: [
      {
        type: "area",
        name: "Lease Rent",
        data: chartData.cumuleaseRents,
        color: "rgba(2, 48, 89, 0.2)",
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "rgba(2, 48, 89, 0.3)"],
            [0.5, "rgba(2, 48, 89, 0.3)"],
            [1, "rgba(2, 48, 89, 0.1)"],
          ],
        },
      },
      {
        type: "area",
        name: "Supplemental Rent",
        data: chartData.cumusupplementalRents,
        color: "rgba(2, 48, 89, 0.6)",
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "rgba(2, 48, 89, 0.6)"],
            [0.5, "rgba(2, 48, 89, 0.6)"],
            [1, "rgba(2, 48, 89, 0.1)"],
          ],
        },
      },
      {
        type: "spline",
        name: "Total Cash Flows",
        lineWidth: 2,
        color: "#B5292A",
        data: chartData.cumuTotalCashFlow,
      },
      {
        type: "bubble",
        name: "Payment Events",
        data: chartData.MXEvents,
        tooltip: {
          pointFormat: "{point.toolTipHtml}",
          followPointer: true,
        },
        color: "#023059",
        visible: false,
      },
    ],
  };

  return <VfChart options={options} />;
};

export default assetCashFlowTimeSeriesChart;
