import * as constants from "../../../../constants";

export const updateParameters = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario
) => {
  const parameters = scenario.parameters;
  if (actionType === constants.UPDATE_ROW) {
    parameters.simDate = newData.simDate;
    parameters.appraiser = newData.appraiser;
    parameters.appraisalDate = newData.appraisalDate;
    parameters.modelBehavior = newData.modelBehavior;
    parameters.prLifeDenominator = newData.prLifeDenominator;
    parameters.valuationMethod = newData.valuationMethod;
    parameters.mxAdjMethod = newData.mxAdjMethod;
    parameters.hlValueType = newData.hlValueType;
    parameters.ptValueType = newData.ptValueType;
    parameters.afCheck_HMV_PPD = parseInt(newData.afCheck_HMV_PPD);
    parameters.afCheck_HMV_RemainingDay = parseInt(
      newData.afCheck_HMV_RemainingDay
    );
    parameters.afCheck_Minor_PPD = parseInt(newData.afCheck_Minor_PPD);
    parameters.afCheck_Minor_RemainingDay = parseInt(
      newData.afCheck_Minor_RemainingDay
    );
    parameters.apU_PPD = parseInt(newData.apU_PPD);
    parameters.apU_RemainingDay = parseInt(newData.apU_RemainingDay);
    parameters.engineLLP_PPD = parseInt(newData.engineLLP_PPD);
    parameters.engineLLP_RemainingDay = parseInt(
      newData.engineLLP_RemainingDay
    );
    parameters.enginePRMinLifeOutOfShop = parseFloat(
      newData.enginePRMinLifeOutOfShop
    );
    parameters.enginePR_PPD = parseInt(newData.enginePR_PPD);
    parameters.enginePR_RemainingDay = parseInt(newData.enginePR_RemainingDay);
    parameters.landingGear_PPD = parseInt(newData.landingGear_PPD);
    parameters.landingGear_RemainingDay = parseInt(
      newData.landingGear_RemainingDay
    );
    parameters.llpPriceFactor = parseFloat(newData.llpPriceFactor);
    parameters.saleGap = parseInt(newData.saleGap);
    parameters.eolCompGap = parseInt(newData.eolCompGap);
    parameters.valuationFloor = parseInt(newData.valuationFloor);
    parameters.utilizationScaleFactor = parseFloat(
      newData.utilizationScaleFactor
    );
    parameters.eprIntervalScaleFactor = parseFloat(
      newData.eprIntervalScaleFactor
    );
  }

  return scenario;
};
