import _ from "lodash";
import * as constants from "../../../../../constants";
import { mapToViewModel_LeaseSummary } from "./leaseSummaryViewModel";
import { mapToViewModel_CreditLineReserve } from "./creditLineReserveViewModel";
import { mapToViewModel_RentPayments } from "./rentPaymentsViewModel";
import { mapToViewModel_maintenanceReserveRate } from "./maintenanceReserveRateViewModel";
import { mapToViewModel_CashReserveAccounts } from "./cashReserveViewModel";
import { mapToViewModel_CashReserveCap } from "./cashReserveCapsViewModel";
import { mapToViewModel_MxFundingAndLLPReplCostClaimable } from "./mxFundingAndLLPReplCostClaimableViewModel";
import { mapToViewModel_DeliveryAndRedeliveryConditions } from "./DeliveryAndRedeliveryConditionsViewModel";
import { mapToViewModel_RedeliveryAdjustment } from "./redeliveryAdjustmentsViewModel";
import { mapToViewModel_LeaseExtension } from "./leaseExtensionViewModel";
import { mapToViewModel_ExtensionRent } from "./extensionRent";
import { mapToViewModel_LeaseDeposit } from "./leaseDepositViewModel";

function extractComponentsLeaseInfo(data, lease) {
  const componentLeaseInfo = lease.componentLeaseInfo;
  var events = [];
  data.engines.forEach((x) => {
    x.supplementalEvents.forEach((e) => {
      events.push({
        checkName: e.eventName + ` - ${x.serialNumber}`,
        componentName: e.eventName,
        mxStatus: e.maintenanceStatus,
        componentLeaseInfo: _.find(
          componentLeaseInfo,
          (o) => o.componentName === e.eventName + " " + x.position
        ),
      });
    });
  });
  let leaseInfo = {
    engines: data.engines.map((e) => ({
      checkName: `EPR - ${e.serialNumber}`,
      componentName: e.position,
      mxStatus: e.maintenanceStatus,
      componentLeaseInfo: _.find(
        componentLeaseInfo,
        (o) => o.componentName === e.position
      ),
    })),
    llps: data.engines.map((e) => ({
      checkName: `ELLP - ${e.serialNumber}`,
      componentName: "LLP Stack " + e.position,
      mxStatus: e.maintenanceStatus,
      componentLeaseInfo: _.find(
        componentLeaseInfo,
        (o) => o.componentName === "LLP Stack " + e.position
      ),
    })),
    events: events,
    lease,
  };
  if (!_.isEmpty(data.airframeChecks)) {
    leaseInfo.airframeChecks = data.airframeChecks.map((a) => ({
      checkName: a.checkName,
      mxStatus: a.maintenanceStatus,
      componentLeaseInfo: _.find(
        componentLeaseInfo,
        (o) => o.componentName === a.checkName
      ),
    }));
    leaseInfo.landingGears = data.landingGears.map((l) => ({
      checkName: `LG - ${l.position}`,
      componentName: `LG ${l.position}`,
      mxStatus: l.maintenanceStatus,
      componentLeaseInfo: _.find(
        componentLeaseInfo,
        (o) => o.componentName === l.position
      ),
    }));
    leaseInfo.apus = data.apUs.map((a) => ({
      checkName: constants.APU.toUpperCase(),
      mxStatus: a.maintenanceStatus,
      componentLeaseInfo: _.find(
        componentLeaseInfo,
        (o) => o.componentName === constants.APU.toUpperCase()
      ),
    }));
  }
  return leaseInfo;
}

export default function mapToViewModel_Lease(
  assetDetails,
  props,
  lease,
  sponsors,
  scenario,
  threeDotBtnOptions,
  lessees
) {
  let componentsLeaseInfo = extractComponentsLeaseInfo(assetDetails, lease);
  let leaseInfo = componentsLeaseInfo.lease;
  const components = _.omit(componentsLeaseInfo, constants.LEASE);
  const data = {
    eoLcompCap: lease.eoLcompCap,
    eoLcompFloor: lease.eoLcompFloor,
  };
  const [mxFunding, llpReplCostType, eolCompBounds] =
    mapToViewModel_MxFundingAndLLPReplCostClaimable(components, props, data);

  const [deliveryConditions, redeliveryConditions] =
    mapToViewModel_DeliveryAndRedeliveryConditions(components, props);

  return {
    leaseSummary: mapToViewModel_LeaseSummary(
      leaseInfo,
      props,
      sponsors,
      lessees
    ),
    leaseExtension: mapToViewModel_LeaseExtension(leaseInfo, props),
    extensionRent: mapToViewModel_ExtensionRent(leaseInfo.extensions, props),
    leaseRent: mapToViewModel_RentPayments(leaseInfo.rentPayments, props),
    LeaseDepositCash: mapToViewModel_LeaseDeposit(
      "cash",
      leaseInfo.securityDeposit_Cash,
      props,
      leaseInfo
    ),
    LeaseDepositLC: mapToViewModel_LeaseDeposit(
      "lc",
      leaseInfo.securityDeposit_LC,
      props,
      leaseInfo
    ),
    maintenanceReserveRates: mapToViewModel_maintenanceReserveRate(
      components,
      props
    ),
    cashReserveAccounts: mapToViewModel_CashReserveAccounts(
      leaseInfo,
      props,
      scenario,
      threeDotBtnOptions
    ),
    cashReserveCap: leaseInfo.reserveAccountCaps
      ? leaseInfo.reserveAccountCaps.map((caps) =>
          mapToViewModel_CashReserveCap(caps, props, leaseInfo)
        )
      : [],
    creditLineReserve: mapToViewModel_CreditLineReserve(leaseInfo, props),
    maintenanceFunding: mxFunding,
    llpReplacementCostClaimable: llpReplCostType,
    eolCompBounds: eolCompBounds,
    redeliveryAdjustments: mapToViewModel_RedeliveryAdjustment(
      components,
      props
    ),
    deliveryConditions,
    redeliveryConditions,
  };
}
