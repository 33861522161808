import * as constants from "../../../../constants";
import { updateData as updateStatusSinceNew } from "./statusSinceNew";
import { updateData as updateCheckHistory } from "./checkHistory";
import { updateData as updateCheckStatus } from "./checkStatus";
import { updateData as updateScheduledShopVisit } from "./scheduledShopVisit";
import { updateData as updateShopVisitAssumptions } from "../common/shopVisitAssumptions";

export function updateAirframeInfo(
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario,
  msalContext
) {
  switch (tableName) {
    case constants.checkStatus:
      return updateCheckStatus(actionType, assetDetails, newData, msalContext);
    case constants.statusSinceNew:
      return updateStatusSinceNew(assetDetails, newData);
    case constants.scheduledShopVisit:
      return updateScheduledShopVisit(
        actionType,
        assetDetails,
        newData,
        msalContext
      );
    case constants.shopVisitHistory:
      return updateCheckHistory(actionType, assetDetails, newData, msalContext);
    case constants.shopVisitAssumptions:
      return updateShopVisitAssumptions(
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        scenario,
        msalContext
      );
    default:
      return;
  }
}
