import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

const getDataforTable = (report, selectedAppraisal) => {
  selectedAppraisal.dispositionAssumption =
    selectedAppraisal.dispositionAssumption
      ? selectedAppraisal.dispositionAssumption
      : "Contractual";

  let test = null;
  const initialSimDate = Object.keys(report.cashFlows)[0];
  if (_.isEmpty(report.leases)) {
    test = moment
      .utc(initialSimDate)
      .add(selectedAppraisal.downtimeBeforeDisp, "month");
    //.endOf("month");
  } else {
    var lastLeaseEndDate = report.leases.filter(
      (x) => x.leaseStatus === "Contractual"
    );
    lastLeaseEndDate = lastLeaseEndDate[lastLeaseEndDate.length - 1].endDate;
    if (selectedAppraisal.dispositionAssumption === "Assumed")
      lastLeaseEndDate = report.leases[report.leases.length - 1].endDate;

    let sum = moment
      .utc(lastLeaseEndDate)
      .add(selectedAppraisal.downtimeBeforeDisp, "month");
    let partOutList = Object.entries(report.partOutValues);
    let polength = partOutList.length - 1;

    if (moment(initialSimDate).isAfter(lastLeaseEndDate)) {
      test = moment
        .utc(initialSimDate)
        .add(selectedAppraisal.downtimeBeforeDisp, "month");
    } else if (sum.isAfter(partOutList[polength][0]))
      test = moment.utc(lastLeaseEndDate);
    //.endOf("month");
    else
      test = moment
        .utc(lastLeaseEndDate)
        .add(selectedAppraisal.downtimeBeforeDisp, "month");
    //.endOf("month");
  }

  let dispDate = new Date(test.year(), test.month() + 1, 0); //moment.utc(test).endOf("month");
  //let testDate = dispDate.setToLastDateInMonth();

  const futureValuations = report.appraisal.futureValuations;
  const evaluateDate1 = new Date(dispDate);
  //.toDate();
  const nextValuation = _.find(futureValuations, (d) => {
    return new Date(d.date) > evaluateDate1;
  });
  //leap year correction
  const previousEvaluationDate = new Date(
    evaluateDate1.getFullYear() - 1,
    evaluateDate1.getMonth(),
    0
  );
  // previousEvaluationDate.setMonth(previousEvaluationDate.getMonth() +1);
  // previousEvaluationDate.setDate(previousEvaluationDate.getDate() -1);

  const currValuation = _.find(futureValuations, (d) => {
    return new Date(d.date) > previousEvaluationDate;
  });

  // const currValuationDate = new Date(currValuation.date);
  // const nextValuationtDate = new Date(nextValuation.date);
  // const evaluateDate = moment(dispDate).format("YYYY-MM-DDTHH:mm:ss");
  //console.log(report);
  // let halfLife = _.round(
  //   utils.linearInterpolation(
  //     currValuationDate,
  //     evaluateDate,
  //     nextValuationtDate,
  //     currValuation[`halfLife${selectedAppraisal.valueType}_inflated`],
  //     nextValuation[`halfLife${selectedAppraisal.valueType}_inflated`],
  //     "days"
  //   ) * Math.pow(10, 6)
  // );

  const ptvalueType = selectedAppraisal.partOutValueType.toLowerCase();
  let getpartOutValue = Object.entries(report.partOutValues).filter((data) => {
    let date1 = moment.utc(data[0]).format(constants.DATE_FORMAT);
    let date2 = moment(dispDate).format(constants.DATE_FORMAT);

    return date1 === date2;
  });

  let partOutValue =
    getpartOutValue[0][1].afpoValues[ptvalueType] +
    _.sumBy(getpartOutValue[0][1].enginePOValues, (o) => o[ptvalueType]);

  let getvalueAboveHL = Object.entries(report.cashFlows).filter((data) => {
    let date = moment.utc(data[0]).format(constants.DATE_FORMAT);
    let date2 = moment(dispDate).format(constants.DATE_FORMAT);
    return date === date2;
  });
  let hlcf = getvalueAboveHL[0][1];
  let halfLife =  _.round(hlcf[`halfLifeValue_${selectedAppraisal.valueType}`]);
  let valueAboveHalfLife = _.round(getvalueAboveHL[0][1].valueAboveHalfLife);
  let mxAdjHLValue = halfLife + valueAboveHalfLife;
  let dispValue = null;
  if (selectedAppraisal.dispValueMethod === "Mx. Adj. H.L") {
    dispValue = mxAdjHLValue * (1 - selectedAppraisal.dispValueHairCut);
  } else if (selectedAppraisal.dispValueMethod === "Part-Out Value") {
    dispValue = partOutValue * (1 - selectedAppraisal.dispValueHairCut);
  } else {
    dispValue = selectedAppraisal.dispValueOverride;
  }

  return {
    dispDate: dispDate,
    halfLife: halfLife,
    valueAboveHL: valueAboveHalfLife,
    mxAdjHL: mxAdjHLValue,
    partOutValue: partOutValue,
    dispValue: dispValue,
  };
};

export default function mapToViewModel_DispositionValues(
  report,
  partOutValues,
  runDate,
  selectedAppraisal
) {
  let data = getDataforTable(report, selectedAppraisal, partOutValues);

  let columns = [
    {
      label: ["HALF_LIFE"],
      field: "halfLife",
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: ["VALUE ABOVE HL"],
      field: "valueAboveHL",
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: ["MX ADJ HL"],
      field: "mxAdjHL",
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: ["PART-OUT VALUE"],
      field: "partOutValue",
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: ["DISP VALUE"],
      field: "dispValue",
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
  ];

  return {
    label: "Disposition Values",
    caption: `As of ${moment(data.dispDate).format(constants.DATE_FORMAT)}`,
    tableType: constants.DATA_TABLE,
    columns,
    data,
  };
}
