import _ from "lodash";
import React, { useContext } from "react";
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableFooter,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import CustomPagination from "./../CustomPagination";
import Deal from "./Components/Deal/Deal";
import { makeStyles } from "@material-ui/core/styles";
import LoadingPanel from "../../Components/Common/LoadingPanel";
import { DealRelatedDataContext } from "../../context/DealRelatedDataContext";

const useStyles = makeStyles({
  tablePaginationFooter: {
    border: "none",
    color: "#355A79",
    paddingRight: 11,
  },
});

const Deals = ({
  currPage,
  rowsPerPage,
  dealsData,
  dealStages,
  handleChangePage,
  handleChangeRowsPerPage,
  liabilityData,
  expectedLossRateData,
  airlineLogos,
  totalRows,
  userName,
}) => {
  const classes = useStyles();
  const { pipelineDealsData } = useContext(DealRelatedDataContext);

  let dealAirlineLogos = [];
  if (!_.isEmpty(airlineLogos)) {
    dealsData.map((deal) => {
      let dealLogos = [];
      airlineLogos.forEach((logo) => {
        if (
          _.find(deal.assets, function (o) {
            return o.operator === logo.operator;
          })
        )
          dealLogos.push(logo);
      });
      dealAirlineLogos.push(dealLogos);

      // find deal in pipeline deals, if not in there then you can't edit
      let findAssetModelOnlyDeal = _.find(
        pipelineDealsData,
        (pipedeal) => pipedeal.id === deal.universalDealId
      );
      if (findAssetModelOnlyDeal) deal["editableDeal"] = true;
      else deal["editableDeal"] = false;
    });
  }

  return (
    <Box>
      {!_.isEmpty(airlineLogos) ? (
        <TableContainer style={{ maxWidth: "100%", overflowX: "hidden" }}>
          <Table aria-label='simple table'>
            <TableBody m={2}>
              {dealsData.map((deal, index) => (
                <TableRow key={index}>
                  <TableCell
                    scope='row'
                    component={() => (
                      <td>
                        <Deal
                          key={index}
                          dealId={deal.dealId}
                          deal={deal}
                          dealStages={dealStages}
                          liabilityData={
                            liabilityData ? liabilityData[deal.dealId] : null
                          }
                          expectedLossRateData={
                            expectedLossRateData
                              ? expectedLossRateData[deal.dealId]
                              : null
                          }
                          airlineLogos={
                            _.isEmpty(airlineLogos)
                              ? airlineLogos
                              : dealAirlineLogos[index]
                          }
                          userName={userName}
                          pipelineData={pipelineDealsData}
                        />
                      </td>
                    )}></TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.tablePaginationFooter}
                  rowsPerPageOptions={[10, 25, 50, 75, 100, 125, 150]}
                  count={dealsData.length > 0 ? totalRows : dealsData.length}
                  color='primary'
                  rowsPerPage={rowsPerPage}
                  page={currPage}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                    color: "primary",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={CustomPagination}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <>
          <LoadingPanel />
        </>
      )}
    </Box>
  );
};

export default Deals;
