import moment from "moment";
import _ from "lodash";
import * as constants from "../../constants";

export const generateLSTemplate = (deal, userName) => {
  return {
    // id: -1,
    dealId: deal.dealId,
    dealName: deal.dealName,
    dealSponsor: deal.sponsor,
    dealStage: deal.stage,
    author: userName,
    loanSummaryDate: new Date(),
    published: false,
    newLoanSummary: false,
    lastUpdatedBy: userName,
    lastUpdatedDate: new Date(),
    seniorLoanDetails: {
      additionalRows: [],
      borrower: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      agent: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      securityTrustee: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lender_s: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      sponsor: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      borrowerParent: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      otherObligors_Role: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      loanExecutionDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      totalCommitment: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      fixed_floating: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      interestRate_preConvDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      interestRate_postConvDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      interestPeriod: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      interestOnlyPeriod: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      repaymentDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      repaymentProfiles: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      interestLatePayments: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      servicerFees: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      recourse: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      volofinCollectionsAccount: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      aircraftsDetails: {
        footNotes: null,
        dataTable: [],
      },
    },
    collateral: {
      additionalRows: [],
      aircraftsDetails: {
        footNotes: null,
        dataTable: [],
      },
      otherSecurityDocuments: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      otherfeatures: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    taxAndIndemnities: {
      additionalRows: [],
      indemnitees: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      taxIndemnity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      withholdingGrossUp: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      genTaxIndemnity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      taxIndemnityExclusions: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      operatingIndemnity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      operatingIndemnityExclusions: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      otherIndemnities: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    fees: {
      additionalRows: [],
      arrangementFee: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      dateforPayment: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      commitmentFee: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      datesforPaymentCF: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      facilityAgentFee: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      datesforPaymentFAF: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      securityTrusteeFee: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      datesforPaymentSTF: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    financialCovenants: {
      additionalRows: [],
      ltvTest: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      frequency: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      firstLTVTestDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      ltvRatio: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      consequence: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    prepayments: {
      additionalRows: [],
      mandatoryPrepayments: [],
      voluntaryPrepayments: [],
      prepaymentFees: [],
    },
    information_ReportingCovenants: {
      additionalRows: [],
      briefDescription_ClauseReference: [],
    },
    restrictiveCovenants: {
      additionalRows: [],
      briefDescription_ClauseReference: [],
    },
    eventsOfDefault: {
      additionalRows: [],
      briefDescription_ClauseReference: [],
    },
    other_Legal: {
      additionalRows: [],
      governingLaw: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      jurisdiction: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      exclusiveJurisdiction: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      volofinCounsel: [],
      borrowerCounsel: [],
      jointLocalcounsel: [],
    },
  };
};
