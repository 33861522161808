import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";

export const mapToViewModel_pool = (data) => {
  const recourses = ["Non-Recourse", "Recourse"];
  return {
    columns: [
      {
        label: ["Asset Pool Name"],
        field: "assetPoolName",
        editable: true,
        required: true,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      // {
      //   label: ["Recourse"],
      //   field: "recourse",
      //   editable: true,
      //   required: true,
      //   items: recourses.map((x) => {
      //     return { label: x, value: x };
      //   }),
      //   // initialEditValue: sponsors,
      //   type: constants.AUTO_COMPLETE,
      //   inputFieldType: constants.AUTO_COMPLETE,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
    ],
    data,
  };
};
