import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";

export const updateData = (
  tableName,
  actionType,
  sectionIdx,
  newData,
  scenario
) => {
  scenario.leases = [...scenario.leases];
  scenario.leases[sectionIdx] = { ...scenario.leases[sectionIdx] };

  let deposits =
    tableName === "depositcash"
      ? scenario.leases[sectionIdx].securityDeposit_Cash
      : scenario.leases[sectionIdx].securityDeposit_LC;

  if (Array.isArray(deposits)) {
    let editDeposit = [...deposits];
    if (actionType === constants.ADD_ROW) {
      editDeposit.push(newData);
    } else if (actionType === constants.UPDATE_ROW) {
      editDeposit[newData.id] = newData;
    } else if (actionType === constants.DELETE_ROW) {
      editDeposit.splice(newData.id, 1);
    }

    deposits = editDeposit;
  } else {
    deposits = [];
    if (
      actionType === constants.ADD_ROW ||
      actionType === constants.UPDATE_ROW
    ) {
      deposits.push(newData);
    }
  }

  if (tableName === "depositcash") {
    scenario.leases[sectionIdx].securityDeposit_Cash = deposits;
  } else {
    scenario.leases[sectionIdx].securityDeposit_LC = deposits;
  }
};
