import { updateData as updateApuDetail } from "./apuDetail";
import { updateData as updateAirframeDetail } from "./airframeDetail";
import { updateData as updateEngineDetail } from "./engineDetail";
import { updateData as updateLandingGearDetail } from "./landingGearDetail";
import { updateData as updateDomDetail } from "./assetDetail";

export function updateAssetDetail(
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
  msalContext
) {
  // console.log(tableName);
  // console.log(assetDetails);
  // console.log(newData);
  switch (tableName) {
    case "Asset Details":
      return updateDomDetail(assetDetails, newData, msalContext);
    case "Airframe Details":
      return updateAirframeDetail(assetDetails, newData, msalContext);
    case "Engine Details":
      return updateEngineDetail(assetDetails, sectionIdx, newData, msalContext);
    case "Landing Gear Details":
      return updateLandingGearDetail(
        assetDetails,
        sectionIdx,
        newData,
        msalContext
      );
    case "APU Details":
      return updateApuDetail(assetDetails, sectionIdx, newData, msalContext);
    default:
      return;
  }
}
