import React from "react";
import { List, Paper } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import SideNavBarListSectionItem from "./SideNavBarListSectionItem";

const useStyles = makeStyles((theme) => ({
  root: { borderRadius: 12 },
  list: {
    width: "100%",
    padding: "unset",
    maxWidth: 210,
    margin: "10px 0",
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,
  },
}));

const SideNavBarSectionList = ({
  navBarSectionItems,
  selected,
  onSelectedChange,
}) => {
  const classes = useStyles();
  return navBarSectionItems.map((item, idx) => (
    <Paper key={idx} className={classes.root}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.list}
      >
        <SideNavBarListSectionItem
          label={item.label}
          labelIcon={item.labelIcon}
          listItems={item.listItems}
          defaultOpen={true}
          selected={selected}
          onSelectedChange={onSelectedChange}
        />
      </List>
    </Paper>
  ));
};

export default SideNavBarSectionList;
