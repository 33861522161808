import { constant } from "lodash";
import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";

export function mapToViewModel_LeaseDeposit(type, deposits, props, leaseInfo) {
  // const paymentFrequency = moment.utc(data[1]).diff(moment.utc(data[0]), "days");

  let data = [];
  if (Array.isArray(deposits)) {
    data = deposits.map((p, idx) => ({
      id: idx,
      fromDate: p.fromDate,
      toDate: p.toDate,
      amount: p.amount ? p.amount : null,
      isCollateral:
        p.isCollateral && p.isCollateral === constants.YES
          ? constants.YES
          : constants.NO,
    }));
  } else if (!Array.isArray(deposits) && deposits !== null) {
    data = [
      {
        id: 0,
        fromDate: leaseInfo.leaseStartDate,
        toDate: leaseInfo.leaseEndDate,
        amount: deposits,
        isCollateral: constants.NO,
      },
    ];
  }

  return {
    label: `DEPOSIT ${type.toUpperCase()}`,
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: `deposit${type}`,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns: [
      {
        title: "From Date",
        field: "fromDate",
        required: true,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.fromDate).format(constants.DATE_FORMAT),
      },
      {
        title: "To Date",
        field: "toDate",
        required: true,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.toDate).format(constants.DATE_FORMAT),
      },
      // {
      //   title: "Payment Date",
      //   field: "paymentDate",
      //   required: true,
      //   type: constants.DATE,
      //   inputFieldType: constants.DATE,
      //   editable: constants.ALWAYS,
      //   cellStyle: styles.cellStyle,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      //   render: (rowData) =>
      //     moment.utc(rowData.paymentDate).format(constants.DATE_FORMAT),
      // },
      {
        title: "Amount",
        field: "amount",
        required: true,
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.amount,
            null,
            constants.NUMERIC_CELL,
            "$"
          ),
      },
      {
        title: "IsCollateral",
        field: "isCollateral",
        required: true,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items: [
          { label: constants.YES, value: constants.YES },
          { label: constants.NO, value: constants.NO },
        ],
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      // {
      //   title: "Payment Frequency",
      //   field: "paymentFrequency",
      //   required: true,
      //   type: constants.STRING,
      //   inputFieldType: constants.SELECT,
      //   editable: false,
      //   items: items,
      //   cellStyle: styles.cellStyle,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
    ],
    data: data,
  };
}
