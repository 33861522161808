import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { renderCell } from "../../common";

export function mapToViewModel_EngineLLPScrapValue(data, props, scenario) {
  const enginePosition = data.position;
  let scrapValue = _.find(
    scenario.llpStackScrapOverrides,
    (o) => o.enginePosition === data.position
  );
  scrapValue = scrapValue ? scrapValue.scrapSaleCutoff : data.llpStack.llPs[0].buildStandard[0].scrapSaleCutoff;
  let escalationRate = _.find(
    scenario.llpStackPriceEscOverrides,
    (o) => o.enginePosition === data.position
  );
  escalationRate = escalationRate ? escalationRate.escalationRate : data.llpStack.llPs[0].price[0].escalationRate;

  const label = _.find(
    props.allShopVisitEventNames.engines,
    (o) => o.checkName.includes(data.position) && o.checkName.includes("LLP")
  ).value;

  let rowData = {
    scrapValue: {
      override: scrapValue != null ? scrapValue : null,
      generic: data.llpStack.llPs[0].buildStandard[0].scrapSaleCutoff,
    },
    escalationRate: {
      override: escalationRate != null ? escalationRate : null,
      generic: data.llpStack.llPs[0].price[0].escalationRate,
    }
  }

  return {
    label: label,
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: constants.scrapSaleCutoff,
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    columns: [
      {
        label: ["Min Cycles For Resale"],
        field: "scrapValue",
        captions: null,
        span: [6, 6],
        editable: true,
        required: true,
        type: constants.NUMBER,
        initialEditValue: scrapValue,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          renderCell(rowData)("scrapValue", constants.NUMBER),
      },
      {
        label: ["Price Esc Rate"],
        field: "escalationRate",
        captions: null,
        span: [6, 6],
        editable: true,
        hidden: false,
        required: true,
        initialEditValue: escalationRate,
        type: constants.PERCENTAGE,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(constants.PERCENTAGE, value, required),
        content: (value) =>
          renderCell(rowData)("escalationRate", constants.NUMBER), 
      },
    ],
    data: {
      enginePosition,
      scrapValue,
      escalationRate,
    },
    // data: data,
  };
}
