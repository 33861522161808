import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    root: {
        width: 400,
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        boxShadow:
          "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    },
    cancelText: {
        height: 20,
        width: 80,
        color: "rgba(0,0,0,0.38)",
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: "2px",
        lineHeight: "20px",
        textAlign: "left",
    },
    submitText: {
        height: 20,
        width: 80,
        color: "#0D47A1",
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: "1.25px",
        lineHeight: "20px",
    },
  }));

const VfAlertDialogBox = ({ isOpen, handleAlertDialogVisible, dialogTitle, dialogBody, onAccept, customButton }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    if(isOpen != open){
        setOpen(isOpen);
    }

    const handleClose = () => {
        handleAlertDialogVisible(false);
    };

    const handleCustomCancel = () => {
        if (customButton) {
            const { handleClick } = customButton 
            handleClick()
        }
        handleAlertDialogVisible(false);
    }

    return (
        <Box className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle id="alert-dialog-title" 
                    style={{marginLeft: 10, color: "#023059"}}>
                        {dialogTitle}
                    {customButton ? <IconButton onClick={handleClose} style={{cursor:'pointer', float:'right', marginTop: '-15px', width: '20px'}}>
                        <CloseIcon />
                    </IconButton>
                    : <></>}
                </DialogTitle>
                <DialogContent>
                    {dialogBody}
                </DialogContent>
                <DialogActions style={{marginRight: 25, marginBottom: 20}}>
                    {customButton ? <Button onClick={handleCustomCancel} variant="outlined">
                        <span className={classes.submitText}>{customButton.label}</span>
                    </Button>
                    : <Button onClick={handleClose}>
                        <span className={classes.cancelText}>Cancel</span>
                    </Button>}
                    {onAccept === null ? <></> : <Button onClick={onAccept} variant="outlined">
                        <span className={classes.submitText}>Agree</span>
                    </Button>}
                </DialogActions>
            </Dialog>
        </Box>
    );
}
export default VfAlertDialogBox;