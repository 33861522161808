import _ from "lodash";
import * as constants from "../../../../constants";

export const updateData = (
  actionType,
  sectionIdx,
  newData,
  scenario,
  componentName
) => {
  const template = {
    compensationIfWaived: null,
    isWaived: false,
    maxUsed: {
      activeHour: null,
      day: null,
      flightCycle: null,
      flightHour: null,
    },
    minLeft: {
      activeHour: 0,
      day: 0,
      flightCycle: 0,
      flightHour: 0,
    },
  };

  scenario.leases = [...scenario.leases];

  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };

  scenario.leases[sectionIdx].componentLeaseInfo = [
    ...scenario.leases[sectionIdx].componentLeaseInfo,
  ];

  let targetLeaseInfoIdx = _.findIndex(
    scenario.leases[sectionIdx].componentLeaseInfo,
    (o) => o.componentName === componentName
  );

  scenario.leases[sectionIdx].componentLeaseInfo[targetLeaseInfoIdx] = {
    ...scenario.leases[sectionIdx].componentLeaseInfo[targetLeaseInfoIdx],
  };

  let redeliveryCondition = null;
  if (actionType === constants.DELETE_ROW) {
    redeliveryCondition = template;
  } else {
    template.id = newData.id;
    template.maxUsed.activeHour =
      newData.maxUsedAH && parseInt(newData.maxUsedAH);
    template.maxUsed.day = newData.maxUsedDays && parseInt(newData.maxUsedDays);
    template.maxUsed.flightCycle =
      newData.maxUsedFC && parseInt(newData.maxUsedFC);
    template.maxUsed.flightHour =
      newData.maxUsedFH && parseInt(newData.maxUsedFH);
    template.minLeft.activeHour =
      newData.minLeftAH && parseInt(newData.minLeftAH);
    template.minLeft.day = newData.minLeftDays && parseInt(newData.minLeftDays);
    template.minLeft.flightCycle =
      newData.minLeftFC && parseInt(newData.minLeftFC);
    template.minLeft.flightHour =
      newData.minLeftFH && parseInt(newData.minLeftFH);
    template.compensationIfWaived = 
      newData.compensationIfWaived && parseInt(newData.compensationIfWaived);
    template.isWaived = newData.isWaived
    redeliveryCondition = template;
  }

  scenario.leases[sectionIdx].componentLeaseInfo[
    targetLeaseInfoIdx
  ].redeliveryCondition = redeliveryCondition;


  return scenario;
};
