import React, { useRef, useState, useEffect } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Button, Box } from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";

const richTextTheme = createTheme();

const RichTextInputComponent = ({ value, onChange, heightLimit }) => {
  const richTextRef = useRef();
  const [maxHeightValue, setMaxHeightValue] = useState(null);
  const [shouldExpand, setShouldExpand] = useState(false);
  const [checkCollapsed, setCheckCollapsed] = useState(false);
  const [controls, setControls] = useState([]);

  useEffect(() => {
    const isCollapsed =
      richTextRef.current && richTextRef.current.offsetHeight > heightLimit;

    setCheckCollapsed(isCollapsed);
    setShouldExpand(isCollapsed);
    isCollapsed ? setMaxHeightValue(heightLimit) : setMaxHeightValue("auto");
  }, [value]);

  const toggleShouldExpand = () => {
    if (maxHeightValue === "auto") {
      setMaxHeightValue(heightLimit);
    }

    if (maxHeightValue === heightLimit) {
      setMaxHeightValue("auto");
    }
  };

  const displayControls = () => {
    setControls([
      "title",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "highlight",
      "undo",
      "redo",
      "link",
      "media",
      "numberList",
      "bulletList",
      "quote",
      "code",
      "clear",
      "save",
    ]);
  };

  Object.assign(richTextTheme, {
    overrides: {
      MUIRichTextEditor: {
        // root: {
        //   // marginTop: 20,
        //   // width: "100%",
        //   maxHeight: "100px",
        // },
        editor: {
          maxHeight: maxHeightValue,
          overflow: "hidden",
          whiteSpace: "pre-wrap",
          //   margin: shouldExpand ? "0 0 20px 0" : 0,
        },
      },
    },
  });

  return (
    <Box key={maxHeightValue}>
      <MuiThemeProvider theme={richTextTheme}>
        <Box ref={richTextRef}>
          <MUIRichTextEditor
            defaultValue={value}
            label='Start typing...'
            inlineToolbar={true}
            controls={controls}
            onSave={(event) => {
              onChange(event);
            }}
            onFocus={() => {
              displayControls();
              if (checkCollapsed) {
                setMaxHeightValue("auto");
                setShouldExpand(false);
              }
            }}
          />
        </Box>
      </MuiThemeProvider>
      {shouldExpand && (
        <Button
          variant='text'
          size='small'
          style={{ width: "7rem", margin: "0 0 -10px 0" }}
          onClick={toggleShouldExpand}>
          {maxHeightValue === heightLimit ? "See more" : "See less"}
        </Button>
      )}
    </Box>
  );
};

export default RichTextInputComponent;
