import moment from "moment";
import React, { useState, useEffect } from "react";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import * as constants from "../../../../../../constants";
import * as mapToViewModels from "../../../../mapToViewModels/index";
import mapToViewModel_partoutEvents from "../../../../mapToViewModels/leaseReport/partoutEventsViewModel";
import DataTable from "../../../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../../../Common/Tables/EditableTable/EditableTable";
import SingleFieldFadeInEditingPanel from "../../../../../Common/Editing/EditingPanels/SingleFieldFadeInEditingPanel";
import VfDropdown from "../../../../../Common/UtilComponents/VfDropdown";
import CalendarSvg from "../../../../../Common/SvgComponents/CalendarSvg";

const formatData = (scenario, componentType, generateEditableProps, assetDetails = null) => {
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModels.simulateParameter(scenario, props, assetDetails),
    componentType,
    props,
  }
};
const formatDatatwo = (scenario, componentType, generateEditableProps) => {
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModels.conversionEvent(scenario, props),
    componentType,
    props,
  }
};
const formatDataRunout = (scenario, componentType, generateEditableProps) => {
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModels.runoutOverrides(scenario, props),
    componentType,
    props,
  }
};
const formatDataPartout = (scenario, componentType, generateEditableProps) => {
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModel_partoutEvents(scenario, props),
    componentType,
    props,
  }
};

const SimulationParameter = ({ scenario, generateEditableProps, assetDetails }) => {
  let props = generateEditableProps(null, "simulate");
  const simulationData = formatData(scenario, "simulate", generateEditableProps, assetDetails);
  const conversionData = formatDatatwo(scenario, "simulate" ,generateEditableProps);
  const runoutOverrideData = formatDataRunout(scenario, "simulate", generateEditableProps);
  const partoutData = formatDataPartout(scenario, "simulate", generateEditableProps);
  var selectedPartoutDate = scenario.parameters?.partoutEvent?.partoutDate;
  const [open, setOpen] = useState(false);
  var dateOptions = [];
  //console.log(assetDetails);
  //console.log(scenario);

  const onEditComponentClose = () => {
    setOpen(false);
  }
  const selectedDateChange = (value) => {
    if(moment.utc(selectedPartoutDate).diff(moment.utc(value), "days") === 0 ){
      scenario.parameters.partoutEvent.partoutDate = value;
    }
  }

  if(selectedPartoutDate != null){
    selectedPartoutDate = moment.utc(selectedPartoutDate).format(constants.DATE_FORMAT);
    dateOptions.push({
      id: -1,
      label: moment.utc(selectedPartoutDate).format(constants.DATE_FORMAT),
      value: selectedPartoutDate,
      props: { handleClick: () => {}},
    })
  }
  else{
    selectedPartoutDate = "No Partout Event Dates";
    dateOptions.push({
        id: -1,
        label: "+ New Date",
        value: "+ New Date",
        props: { handleClick: () => setOpen(true)},
      });
    }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
          <DataTable tableData={simulationData.data} />
      </Grid>
      <Grid item sm={12}>
          <DataTable tableData={runoutOverrideData.data} />
      </Grid>
      {scenario.aircraftId != null ?
      <Grid item sm={12}>
          <DataTable tableData={conversionData.data} />
      </Grid>
      : null}
      {scenario.aircraftId != null ?
      <Grid item sm={12}>
          <EditableTable
            tableNamePanelButtons={[
              <VfDropdown
                styles={{ margin: "auto 0", minWidth: 120 }}
                itemProps={{
                  icon: (
                    <CalendarSvg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      style={{ verticalAlign: "middle" }}
                    />
                  ),
                }}
                selectedItem={
                  selectedPartoutDate.match(constants.RAW_DATE_REG) ||
                  selectedPartoutDate.match(constants.RAW_DATE_REG2) ||
                  selectedPartoutDate.match(constants.RAW_DATE_REG3)
                    ? moment
                        .utc(selectedPartoutDate)
                        .format(constants.DATE_FORMAT)
                    : selectedPartoutDate
                }
                items={dateOptions}
              />,
            ]}
            table={partoutData.data} 
            bulkEdit={partoutData.data.data != null && partoutData.data.data.length === 0 ? false : true}
          />
      </Grid>
      : null}
      <SingleFieldFadeInEditingPanel
        open={open}
        id={-1}
        overlines={["New Partout Event"]}
        body={new Date()}
        captions={null}
        onClose={onEditComponentClose}
        tableName={"PartoutEventDate"}
        required={true}
        type={constants.DATE}
        props={{
          section: 0,
          sectionIdx: 0,
          errorMessage: "Partout Date Exists",
          funcs: {
            validateCell: (...props) => {
              return true;
            },
            handleCellUpdate: (section, sectionIdx, tableName, _, value) => {
              selectedDateChange(value);
              //onSelectedStatusDateChange(value);
              props.funcs.handleRowAdd(section, sectionIdx, tableName, {
                partoutEventDate: value,
              });
            },
          },
        }}
      />
    </Grid>
  );
};

export default SimulationParameter;
