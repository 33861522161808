import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Engine from "./Engine";
import { Box, Tab, Tabs } from "@material-ui/core";
import * as constants from "../../../../../../../constants";
import LoadingPanel from "../../../../../../Common/LoadingPanel";

const Engines = ({
  assetDetails,
  engines,
  generateEditableProps,
  scenario,
  // selectedEngineInfo,
}) => {
  const [open, setOpen] = useState({});
  const [selectedStatusDate, setSelectedStatusDate] = useState({});
  const [llpsStatusDates, setLLPsStatusDates] = useState({});
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const initllpsStatusDates = {};
    const initOpen = {};
    for (let engine of engines) {
      const uniqStatusDates = _.chain(engine.llpStack.llPs[0].status)
        .uniqBy("statusDate")
        .map("statusDate")
        .value();

      let llpsStatusDates = uniqStatusDates.map((e, idx) => ({
        id: idx,
        label: moment.utc(e).format(constants.DATE_FORMAT),
        value: e,
        props: {
          handleClick: () =>
            setSelectedStatusDate({
              [engine.position]: e,
            }),
        },
      }));

      llpsStatusDates = _.isEmpty(llpsStatusDates)
        ? [
            {
              label: "No LLPs Status",
              value: "No LLPs Status",
              props: { handleClick: () => null },
            },
          ]
        : _.orderBy(llpsStatusDates, ["value"], ["desc"]);

      llpsStatusDates.push({
        id: -1,
        label: "+ New Status",
        value: "+ New Status",
        props: { handleClick: () => setOpen({ [engine.position]: true }) },
      });

      initllpsStatusDates[engine.position] = llpsStatusDates;
      initOpen[engine.position] = false;
    }

    setLLPsStatusDates(initllpsStatusDates);
    setOpen(initOpen);
  }, [engines]);

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        justify='end'
        style={{ marginBottom: 16, marginTop: -16 }}>
        {engines.map((engine, idx) => (
          <Tab key={idx} label={engine.serialNumber} />
        ))}
      </Tabs>
      {engines.map((engine, idx) =>
        idx === value ? (
          llpsStatusDates[engine.position] &&
          !_.isNil(open[engine.position]) ? (
            <Engine
              key={idx}
              engine={engine}
              engineIdx={idx}
              generateEditableProps={generateEditableProps}
              llpsStatusDates={llpsStatusDates[engine.position]}
              open={open[engine.position]}
              onSelectedStatusDateChange={(value) =>
                setSelectedStatusDate({
                  [engine.position]: value,
                })
              }
              assetDetails={assetDetails}
              scenario={scenario}
              serialNumber={engine.serialNumber}
              selectedStatusDate={
                selectedStatusDate[engine.position] ||
                llpsStatusDates[engine.position][0].value
              }
              onEditComponentClose={() => setOpen({ [engine.position]: false })}
            />
          ) : (
            <LoadingPanel key={idx} />
          )
        ) : null
      )}
    </Box>
  );
};

export default Engines;
