// import * as constants from "../../../constants";
import { tableUpdater } from "./tableUpdater";
import { MXReserve_SeniorLoanDetailsUpdater } from "./SeniorLoanDetails/index";
import { MXReserve_CollateralUpdater } from "./Collateral/index";
import { prepaymentsUpdater } from "./prepayments";
import { briefDescriptionUpdater } from "./briefDescription_ClauseReference";
import { otherLegalUpdater } from "./otherLegal";

export function updateTable(
  fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  loanSummary,
  columns
) {
  // console.log(
  //   fieldName,
  //   actionType,
  //   sectionName,
  //   sectionIdx,
  //   newData,
  //   loanSummary,
  //   columns
  // );
  const params1 = [
    fieldName,
    actionType,
    sectionName,
    sectionIdx,
    newData,
    loanSummary,
    columns,
  ];

  switch (sectionName) {
    case "seniorLoanDetails":
      return MXReserve_SeniorLoanDetailsUpdater(...params1);
    case "collateral":
      return MXReserve_CollateralUpdater(...params1);
    case "prepayments":
      return prepaymentsUpdater(...params1);
    case "information_ReportingCovenants":
      return briefDescriptionUpdater(...params1);
    case "restrictiveCovenants":
      return briefDescriptionUpdater(...params1);
    case "eventsOfDefault":
      return briefDescriptionUpdater(...params1);
    case "other_Legal":
      return otherLegalUpdater(...params1);
    default:
      return tableUpdater(...params1);
  }
}
