import _ from "lodash";
import React, { Component } from "react";
import { Box, Paper, IconButton, List, ListItem, Typography, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import SectionHeader from "../../../../../../Common/Section/SectionHeader";
import InputFieldComponent from "../../../../../../Common/Editing/EditingComponents/InputFieldComponent";
import * as constants from "../../../../../../../constants";

const styles = (theme) => ({
  root: {
    height: "100%",
    width: 400,
    position: "fixed",
    top: 56,
    right: 0,
    // float: "right",
    zIndex: 50,
    backgroundColor: "#FBFBFB",
    overflow: 'auto',
  },
  closeIcon: { 
    position: "relative", 
    float: "right" 
  },
  checkLabelArea: {
    paddingTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
  
});

class SlideInErrorPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      height: window.innerHeight,
      filteredErrorMessages: {},
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({ filteredErrorMessages: this.props.errorMessages })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = (height, event) => {
    this.setState({ height: window.innerHeight });
  };

  handleFilterValueChange = (filterValue) => {
    const { errorMessages } = this.props;
    if (filterValue.value === "all") {
      this.setState({ filteredErrorMessages: errorMessages })
    } else {
      const filteredValues = _.filter(errorMessages, error => error.checkName === filterValue.value || error.checkType === filterValue.value )
      if (filteredValues) this.setState({ filteredErrorMessages: filteredValues })
    }
  }

  renderCheckTypeChip = (type) => {
    let checkTypeLabel = null;
    let fillColor = null;
    let chipColor = null;

    switch (type) {
      case "Utilizations":
        checkTypeLabel = "Utilizations";
        fillColor = "rgba(0,82,250, 0.2)";
        chipColor = "#0052FA";
        break;
      case "Interval Check":
        checkTypeLabel = "Interval Checks";
        fillColor = "rgba(97, 4, 177, 0.2)";
        chipColor = "#6104B1";
        break;
      case "Reserve Accounts":
        checkTypeLabel = "Reserve Accounts";
        fillColor = "rgba(246, 189, 96, 0.2)";
        chipColor = "#F6BD60";
        break;
      case "MX Status":
        checkTypeLabel = "Maintenance Status";
        fillColor = "rgba(96, 169, 246, 0.2)";
        chipColor = "#60a9f6";
        break;
      default:
        checkTypeLabel = "Others";
        fillColor = "rgba(96, 121, 246, 0.2)";
        chipColor = "#6079f6";
        break;
    }

    return (
      <Box style={{ fontSize: 11, borderStyle: "solid", borderRadius: 6, backgroundColor: fillColor, color: chipColor, padding: 5, borderColor: chipColor }} >
        {checkTypeLabel}
      </Box>
    )
  }

  render() {
    const { errorMessages, classes, onSlidePanelClose } = this.props;
    const { filteredErrorMessages } = this.state;

    const checkTypes = errorMessages.map(e => { return {
        label: e.checkType,
        value: e.checkType,
      }
    })
    const checkNames = errorMessages.map(e => { return {
        label: e.checkName,
        value: e.checkName,
      }
    })
    const filterItems = _.unionBy( _.uniqBy(checkTypes, "value"), _.uniqBy(checkNames, "value"), "value" )
    filterItems.push({
      label: "Others",
      value: "others",
    })
    filterItems.unshift({
      label: "View All Errors",
      value: "all",
    })


    return (
      <Paper
        className={classes.root}
        style={{
          height: this.state.height - 58,
        }}
      >
        <Box>
          <IconButton
            className={classes.closeIcon}
            onClick={onSlidePanelClose}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box style={{ paddingLeft: 20 }}>
          <SectionHeader label={"Simulation Errors"} fontSize={18} />
        </Box>
        <Box style={{ display: "flex",
              justifyContent: "flex-end",
              paddingRight: 20,
              marginBottom: 10,
              }}> 
          <Autocomplete
            filterSelectedOptions
            id="autoselect"
            onChange={(e,value) => this.handleFilterValueChange(value)}
            options={filterItems}
            getOptionLabel={(filterItems) =>filterItems.label}
            style={{
              color: "#263238",
              width: 300,
              height: 30,
              padding: "27px 12px 10px !important",
              "&:focus": { backgroundColor: "transparent" }        
            }}
            size="small"
            disablePortal={false}
            defaultValue={filterItems[0]}
            disableClearable={true}
            // limitTags={2}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                // label="Filter Simulation Checks"
                // placeholder={filterItems[0].label}
                rowsMax={1}
              />
            )} />
        </Box>
        <Box style={{ padding: 5 }}>
        {!_.isEmpty(filteredErrorMessages) ? 
          <List dense={true}>
          {filteredErrorMessages.map((error, idx) => 
            <ListItem key={idx}>
              <Box style={{ width: "100%" }} >
                <Typography align="left" variant="body2" style={{ color: "#1D2D5F" }}>
                  {error.errorMessage}
                </Typography>
                <Box className={classes.checkLabelArea}>
                  <Typography align="left" variant="caption">
                    {this.renderCheckTypeChip(error.checkType)}
                  </Typography>
                  <Typography align="right" color="textSecondary" variant="overline" >
                    {error.checkName}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          )}
          </List>
        : <></>}
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(SlideInErrorPanel);
