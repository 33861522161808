import React from "react";
import Pagination from "@material-ui/lab/Pagination";

const CustomPagination = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props;
  return (
    <Pagination
      p={1}
      count={Math.ceil(count / rowsPerPage)}
      page={page + 1}
      onChange={onChangePage}
      defaultPage={1}
      color="secondary"
      showFirstButton
      showLastButton
    />
  );
};

export default CustomPagination;
