import React from "react";
import moment from "moment";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";
import { Box, Tooltip, IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const copyToClipboard = (url) => {
  navigator.clipboard.writeText(url);
};

export default function mapToViewModel_cashflowHistory(data) {
  return {
    label: "Export History",
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    span: [12, 12, 12],
    columns: [
      {
        label: "Filename",
        caption: null,
        field: "fileName",
        filter: true,
        align: constants.LEFT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Date Created",
        caption: null,
        field: "asOfDateUtc",
        align: "right",
        indent: 16,
        content: (value, indent) => (
          <Box style={{ paddingRight: indent }}>
            {moment.utc(value).local().format(constants.LOCAL_DATETIME_FORMAT)}
          </Box>
        ),
      },
      {
        label: "File Link",
        caption: null,
        field: "spwebUrl",
        filter: true,
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) => (
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              style={{
                margin: "12px 0 0 20px",
                fontSize: 14,
                color: "#023059",
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}>
              <a href={value} target='_blank'>
                Download Json
              </a>
            </Box>

            <Box>
              <Tooltip
                title='Copy Sharepoint Link for File'
                aria-label='Copy Sharepoint Link for File'>
                <IconButton
                  aria-label='copy'
                  onClick={() => copyToClipboard(value)}>
                  <FileCopyIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ),
      },
    ],
    data: data.map((x, idx) => ({
      id: idx,
      asOfDateUtc: x.asOfDateUtc,
      dealId: x.dealId,
      fileName: x.fileName,
      spwebUrl: x.spwebUrl,
      scenarioIdList: x.scenarioIdList,
    })),
  };
}
