import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const updateData = async (assetId, data, msalContext) => {
     let apiendpoint = apiRoutes.AircraftPurchasePriceUpdateInfo({ Id: assetId,user: msalContext.accounts[0].name });
     if(data.componentType === "EnginePR")
        apiendpoint = apiRoutes.EnginePurchasePriceUpdateInfo({ Id: assetId, user: msalContext.accounts[0].name});

      var ppupdate = {
        owner : data.owner,
        purchasePrice : data.purchasePrice,
        purchaseDate : data.purchaseDate,
        financeDate : data.financeDate
      }
        
  // apiendpoint = apiendpoint({ Id: assetId });
  
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: ppupdate,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    data = resp.data;
  }
};

export const updatePurchasePrice = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
  msalContext
) => {
  assetDetails.owner = newData.owner;
  assetDetails.purchasePrice = newData.purchasePrice;
  assetDetails.purchaseDate = newData.purchaseDate;
  assetDetails.financeDate = newData.financeDate;

  updateData(assetDetails.id, assetDetails, msalContext);

  return assetDetails;
};
