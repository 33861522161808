import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";

const StatusToFullLife = "StatusToFullLife";
const StatusToDelivery_TwoWay = "StatusToDelivery_TwoWay";
const StatusToDelivery_OneWay = "StatusToDelivery_OneWay";
const StatusToDeliveryOrLastEvent_TwoWay = "StatusToDeliveryOrLastEvent_TwoWay";
const StatusToDeliveryOrLastEvent_OneWay = "StatusToDeliveryOrLastEvent_OneWay";
const StatusToHalfLife_TwoWay = "StatusToHalfLife_TwoWay";
const StatusToHalfLife_OneWay = "StatusToHalfLife_OneWay";
const StatusToRef_TwoWay = "StatusToRef_TwoWay";
const StatusToRef_OneWay = "StatusToRef_OneWay";
const StatusToDeliveryOrNew = "StatusToDeliveryOrNew";
const CumSinceDelivery = "CumSinceDelivery";

const lessor = "Lessor";
const lessee = "Lessee";

const FromMarket = "FromMarket";
const FromLease = "FromLease";
const Override = "Override";

const SinceEvent = "SinceEvent";
const ToEvent = "ToEvent";

export function mapToViewModel_RedeliveryAdjustment(components, props) {
  const mxEvents = _.flattenDeep(Object.values(props.allShopVisitEventNames));

  const financialCompType = [
    { label: constants.NONE, value: constants.NONE },
    {
      label: CumSinceDelivery,
      value: CumSinceDelivery,
    },
    { label: StatusToFullLife, value: StatusToFullLife },
    {
      label: StatusToDelivery_TwoWay,
      value: StatusToDelivery_TwoWay,
    },
    {
      label: StatusToDelivery_OneWay,
      value: StatusToDelivery_OneWay,
    },
    {
      label: StatusToDeliveryOrLastEvent_TwoWay,
      value: StatusToDeliveryOrLastEvent_TwoWay,
    },
    {
      label: StatusToDeliveryOrLastEvent_OneWay,
      value: StatusToDeliveryOrLastEvent_OneWay,
    },
    {
      label: StatusToHalfLife_TwoWay,
      value: StatusToHalfLife_TwoWay,
    },
    {
      label: StatusToHalfLife_OneWay,
      value: StatusToHalfLife_OneWay,
    },
    {
      label: StatusToRef_TwoWay,
      value: StatusToRef_TwoWay,
    },
    {
      label: StatusToRef_OneWay,
      value: StatusToRef_OneWay,
    },
    {
      label: StatusToDeliveryOrNew,
      value: StatusToDeliveryOrNew,
    },
  ];
  const maintenanceReserveBalanceReceiver = [
    {
      label: lessor,
      value: lessor,
    },
    {
      label: lessee,
      value: lessee,
    },
  ];

  const unitRate = [
    // { label: constants.NONE, value: constants.NONE },
    // {
    //   label: StatusToDeliveryOrLastEvent_OneWay,
    //   value: StatusToDeliveryOrLastEvent_OneWay,
    // },
    {
      label: FromMarket,
      value: FromMarket,
    },
    {
      label: FromLease,
      value: FromLease,
    },
    // {
    //   label: Override,
    //   value: Override,
    // },
  ];

  const comparisionUsageType = [
    // { label: constants.NONE, value: constants.NONE },
    {
      label: SinceEvent,
      value: SinceEvent,
    },
    {
      label: ToEvent,
      value: ToEvent,
    },
  ];

  const referenceRateAt = [
    {
      label: FromMarket,
      value: FromMarket,
    },
    {
      label: FromLease,
      value: FromLease,
    },
  ];

  const columns = [
    {
      title: "Mx Event",
      field: "mxEvent",
      initialEditValue: mxEvents[0].value,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      items: mxEvents,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => rowData.mxEventDisplay,
    },
    {
      title: "Financial Comp Type",
      field: "financialCompType",
      initialEditValue: financialCompType[0].value,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      items: (rowData) => {
        if (rowData.mxEvent.includes("LLP")) return financialCompType;
        else return financialCompType.slice(0, -1);
      },
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.financialCompType,
          {
            width: 120,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
          constants.STRING
        ),
    },
    {
      title: "Reference Percentage",
      field: "refPct",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMERIC,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(constants.PERCENTAGE, value, required),
      render: (rowData) =>
        rowData.refPct ? `${_.round(rowData.refPct * 100, 3)}%` : constants.___,
    },
    {
      title: "Rate Source",
      field: "unitRate",
      initialEditValue: unitRate[0].value,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      items: unitRate,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Comparision Usage Type",
      field: "comparisionUsageType",
      initialEditValue: comparisionUsageType[0].value,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      items: comparisionUsageType,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Reference Rate Source",
      field: "referenceRateAt",
      initialEditValue: referenceRateAt[0].value,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      items: referenceRateAt,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Cash Balance Receiver",
      field: "maintenanceReserveBalanceReceiver",
      initialEditValue: maintenanceReserveBalanceReceiver[0].value,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      items: maintenanceReserveBalanceReceiver,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Comp Scale Factor",
      field: "compScaleFactor",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.compScaleFactor,
          null,
          constants.NUMERIC,
          null,
          2
        ),
    },
    {
      title: "Comp Cap",
      field: "compCap",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.compCap,
          null,
          constants.NUMERIC,
          null,
          2
        ),
    },
    {
      title: "Comp Floor",
      field: "compFloor",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.compFloor,
          null,
          constants.NUMERIC,
          null,
          2
        ),
    },
    {
      title: "Extra Comp",
      field: "extraComp",
      required: false,
      type: constants.NEG_NUMERIC,
      inputFieldType: constants.NEG_NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.extraComp,
          null,
          constants.NUMERIC,
          null,
          2
        ),
    },
  ];
  const redilveryAdjustments = [];
  let id = 0;
  Object.keys(components).map((key) => {
    components[key].map((mxEvent) => {
      const checkName = mxEvent.checkName;
      const compPolicy = mxEvent.componentLeaseInfo.compPolicy;

      redilveryAdjustments.push({
        id: id,
        leaseID: mxEvent.componentLeaseInfo.leaseID,
        mxEventDisplay:
          checkName === constants.APU ? checkName.toUpperCase() : checkName,
        mxEvent: mxEvent.componentName ? mxEvent.componentName : checkName,
        financialCompType: compPolicy.redeliveryCompType,
        unitRate: compPolicy.redeliveryRateType,
        comparisionUsageType: compPolicy.usageType,
        referenceRateAt: compPolicy.referenceRateType,
        maintenanceReserveBalanceReceiver:
          compPolicy.maintenanceReserveBalanceReceiver,
        compScaleFactor: compPolicy.scaleFactor,
        compCap:
          typeof compPolicy.compCap === constants.STRING
            ? null
            : compPolicy.compCap,
        compFloor:
          typeof compPolicy.compFloor === constants.STRING
            ? null
            : compPolicy.compFloor,
        extraComp: compPolicy.extraComp,
        refPct: compPolicy.refPct,
      });
      id++;
      return null;
    });
    return null;
  });

  return {
    label: "Redelivery Adjustments",
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.redeliveryAdjustments,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns,
    data: redilveryAdjustments,
  };
}
