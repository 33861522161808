export const generateAssetDTOTemplate = () => {
  return {
    id: 0,
    msn: null,
    operator: null,
    owner: null,
    FGID: null,
    purchasePrice: null,
    purchaseDate: null,
    registration: null,
    manufacturer: null,
    manufactureDate: null,
    aircraftSeries: null,
    aircraftSubSeries: null,
    scenarioSummaries: [],
    fleetData: {},
    airframeChecks: [],
    landingGears: [],
    apUs: [],
    engines: [],
    valuation: {},
    vfScores: {},
    fleetDistribution: {},
  };
};
