import React, { useState } from "react";
import { Box, Grid, Fade, Modal, Backdrop, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import * as Constants from "../../../constants";
import DataTable from "../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../Common/Tables/EditableTable/EditableTable";
import MultiFunctionTable from "../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import LiabilityPreviews from "./LiabilityComponents/LiabilityPreviews";
import StackedAreaChart from "./LiabilityComponents/StackedAreaChart";
import Errors from "../../Common/Errors";
import * as mapToViewModels from "../mapToViewModels";
import apiRoutes from "../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles({
  root: { maxWidth: "100%", margin: "auto" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const HandleLiabilityModelExtrasUpdate = async (
  newData,
  liabilityData,
  deal,
  handleUpdateLiabilityData,
  handleOpenLoansTableEditing,
  msalContext
) => {
  const liabData = _.cloneDeep(liabilityData);
  newData.forEach((el) => {
    liabData.liabilityModelData.loanDetails[el.id].assetRating = parseFloat(
      el.collateralRating
    );
    liabData.liabilityModelData.loanDetails[el.id].strategicValue = parseFloat(
      el.strategicValue
    );
    el.collateralRating = parseFloat(el.collateralRating);
    el.strategicValue = parseFloat(el.strategicValue);
    el.id = null;
  });

  const result = await apiRequest(
    {
      url: apiRoutes.LiabilityModel_PostLiabilityModelExtras,
      method: "POST",
      data: newData,
      params: {
        dealId: deal.dealId,
      },
    },
    msalContext
  );

  if (result.status === response.OK) {
    handleUpdateLiabilityData(liabData);
    handleOpenLoansTableEditing(false);
  }
};

const PortfolioLiabilitySection = ({
  liabilityData,
  report,
  liabilityTranches,
  liabilityCashflow,
  editLoans,
  deal,
  handleOpenLoansTableEditing,
  handleUpdateLiabilityData,
  assetList,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();

  const liabilityModelExtrasData = mapToViewModels.editLiabilityLoans(
    liabilityData,
    deal,
    {
      sectionIdx: 0,
      section: "editLiabilityLoans",
      funcs: {
        handleValuesUpdate: (_, __, ___, newData) =>
          HandleLiabilityModelExtrasUpdate(
            newData,
            liabilityData,

            deal,
            handleUpdateLiabilityData,
            handleOpenLoansTableEditing,
            msalContext
          ),
        validateData: () => true,
      },
    },
    assetList
  );

  const renderTable = (
    data,
    span = [],
    setDisplayStlye = true,
    showPagination = false
  ) => {
    const display = { display: "grid" };

    let table = null;
    if (data.tableType === Constants.DATA_TABLE) {
      table = <DataTable tableData={data} />;
    } else if (data.tableType === Constants.EDITABLE_TABLE) {
      table = <EditableTable table={data} />;
    } else if (data.tableType === Constants.MULTIFUNCTION_TABLE) {
      table = (
        <MultiFunctionTable tableData={data} showPagination={showPagination} />
      );
    }

    return (
      <Grid
        style={setDisplayStlye ? display : null}
        item
        sm={span[0]}
        lg={span[1]}>
        {table}
      </Grid>
    );
  };

  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        {!liabilityData.liabilityModelData.modelCashflow ? (
          <Grid item sm={12}>
            <Errors
              msg={
                "There is no model output cashflows for this deal. This is mostly likely because the deal has expired."
              }
            />
          </Grid>
        ) : report ? (
          <>
            <Grid item sm={12}>
              <LiabilityPreviews liabilityData={liabilityData} />
            </Grid>
            <Grid item sm={12}>
              <StackedAreaChart
                key='withReport'
                report={report}
                liabilityData={liabilityData}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={12}>
              <LiabilityPreviews liabilityData={liabilityData} />
            </Grid>
            <Grid item sm={12}>
              <StackedAreaChart
                key='withOutReport'
                liabilityData={liabilityData}
              />
            </Grid>
          </>
        )}
        {_.isEmpty(liabilityTranches) ? (
          <> </>
        ) : (
          renderTable(
            liabilityTranches[0].data.liabilityTranches,
            [12, 12],
            false,
            true
          )
        )}
        {_.isEmpty(liabilityCashflow) ? (
          <> </>
        ) : (
          renderTable(
            liabilityCashflow[0].data.liabilityCashflows,
            [12, 12],
            false,
            true
          )
        )}
      </Grid>
      <Modal
        open={editLoans}
        className={classes.modal}
        onClose={() => handleOpenLoansTableEditing(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}>
        <Fade in={editLoans}>
          <Box
            style={{
              width: 1000,
              margin: "0 auto",
              backgroundColor: "#fff",
              // padding: 20,
              borderRadius: "5px",
            }}>
            <Box
              style={{
                padding: 20,
              }}>
              <EditableTable table={liabilityModelExtrasData} bulkEdit={true} />
            </Box>

            <Button
              style={{ float: "right", margin: "0 16px 10px 0" }}
              variant='outlined'
              onClick={() => handleOpenLoansTableEditing(false)}>
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default PortfolioLiabilitySection;
