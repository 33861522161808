import * as constants from "../../../../constants";

export const updateUtilization = (
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario
) => {
  const template = { components: [], utilizationByDate: [] };

  scenario.expectedUtilizations = [...scenario.expectedUtilizations];
  if (actionType === constants.ADD_ROW)
    scenario.expectedUtilizations.push(template);
  else
    scenario.expectedUtilizations = scenario.expectedUtilizations.filter(
      (o, idx) => idx !== sectionIdx
    );

  // assetDetails[sectionName] = scenario;

  return scenario;
};
