import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const updateAsset = async (assetId, data, msalContext) => {
  let user = msalContext.accounts[0].name;
  let compType = "Aircraft";
  let apiendpoint = apiRoutes.PostAircraftUpdateInfo;
  apiendpoint = apiendpoint({user, compType, assetId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: data,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    data = resp.data;
    window.location.reload();
  }
};

export const updateData = (assetDetails, newData, msalContext) => {
  assetDetails.manufactureDate = newData.aircraftDOM;
  let postData = {
    DOM: newData.aircraftDOM,
  };

  updateAsset(assetDetails.id, postData, msalContext);
  return assetDetails;
};
