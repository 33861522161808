import { faTruckMonster } from "@fortawesome/free-solid-svg-icons";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";


export const mapToViewModel_pipelineAssets = (plAssets) => {

  
  let columns = [
    {
        label: ["Use Asset?"],
        field: "selectedAssets",
        editable: false,
        required: false,
        items: plAssets.map((o) => ({
          label: "",
          value: `${o.id}`,
        })),
        type: constants.CHECK_BOX,
        inputFieldType: constants.CHECK_BOX,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Serial Number"],
        field: "serialNumber",
        editable: false,
        required: false,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Asset Subseries"],
        field: "assetSubseries",
        editable: false,
        required: false,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Asset Type"],
        field: "assetType",
        editable: false,
        required: false,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["EOL Adj"],
        field: "eolAdj",
        editable: false,
        required: false,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Mx Reserve"],
        field: "mxReserve",
        editable: false,
        required: false,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },



  ];
  //todo: check if asset already exists and unselect / grey out if it does
  let data = plAssets.map((o) => {
    return{
        selected: true,
        serialNumber: o.serialNumber,
        assetSubseries: o.assetSubSeries,
        assetType: o.assetType,
        eolAdj: o.eoL_Adj,
        mxReserve: o.mxReserve,
    };   
    });


  return {
    tableName: "Pipeline Assets",
    tableType: "EDITABLE_TABLE",
    showOptions:true,
    lable:"Pipeline Assets",
    columns: columns,
    data: data
  };
};
