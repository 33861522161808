import _ from "lodash";
import React from "react";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import VfSelect from "../../../Common/UtilComponents/VfSelect";

export default function mapToViewModel_engineDetails(
  engineData,
  handleSelectChange,
  props,
  selectedSubseries,
) {
  const items = engineData.map( e => {
    return {
      label: e.subseries,
      value: e.subseries,
    }
  })

  let selectedEngineData = _.find(engineData, { 'subseries': selectedSubseries });

  return {
    label: "Engine Details",
    caption: null,
    props,
    tableType: constants.DATA_TABLE,
    columns: [
      {
        label: ["Engine Subseries"],
        field: "engineSubseries",
        captions: null,
        span: [4, 4],
        content: (_) => {
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedSubseries? selectedSubseries : ""}
              type={"engineSubseries"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={200}
            />
          );
        },
      },
      {
        label: ["Engine Series"],
        field: "engineSeries",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["Manufacturer"],
        field: "manufacturer",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["LLP Stack Name"],
        field: "llpStackName",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["Daily Flight Hours"],
        field: "dailyFlightHours",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, null, 2),
      },
      {
        label: ["Daily Flight Cycles"],
        field: "dailyFlightCycles",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, null, 2),
      },
    ],
    data: {
      engineSeries: selectedEngineData.series,
      manufacturer: selectedEngineData.manufacturer,
      llpStackName: selectedEngineData.stackseries,
      dailyFlightHours: selectedEngineData.flightHour_Daily,
      dailyFlightCycles: selectedEngineData.flightCycle_Daily,
    },
  };
}
