import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const updateAsset = async (assetId, data, msalContext) => {
  let compType = "APU";
  let apiendpoint = apiRoutes.PostAircraftUpdateInfo;
  let user = msalContext.accounts[0].name;
  apiendpoint = apiendpoint({user, compType, assetId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: data,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    data = resp.data;
  }
};

export const updateData = (assetDetails, sectionIdx, newData, msalContext) => {
  assetDetails.apUs[sectionIdx].model = newData.apuModel
  assetDetails.apUs[sectionIdx].serialNumber = newData.serialNumber;
  // assetDetails.apUs[sectionIdx].manufacturer = newData.manufacturer

  let postData = {
    serialNumber: newData.serialNumber,
    subSeries: newData.apuModel
  };

  updateAsset(assetDetails.apUs[sectionIdx].localId, postData, msalContext);
  return assetDetails;
};
