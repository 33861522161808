import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import React from "react";
import { Box } from "@material-ui/core";
import { styles } from "../common";

export function mapToViewModel_EngineLLP(
  data,
  props,
  engineSubSeries,
  serialNumber,
  position,
  selectedStatusDate,
  threeDotBtnOptions
) {
  return {
    label: "Engine LLP Status",
    caption: null,
    props,
    showOptions: true,
    tableName: constants.engineLLPStatus,
    threeDotBtnOptions,
    isDeletable: () => false,
    tableType: constants.EDITABLE_TABLE,
    errorsOnSubmit: {},
    columns: [
      {
        title: "Module",
        field: "module",
        cellStyle: styles.cellStyle,
        editable: constants.NEVER,
        required: false,
      },
      {
        title: "Part Name",
        field: "partName",
        cellStyle: styles.cellStyle,
        editable: constants.NEVER,
        required: false,
      },
      // {
      //   title: "Part Number",
      //   field: "partNumber",
      //   cellStyle: styles.cellStyle,
      //   editable: constants.NEVER,
      //   required: false,
      // },
      {
        title: "List Price",
        field: "listPrice",
        required: false,
        editable: constants.NEVER,
        cellStyle: styles.cellStyle,
        render: (rowData) =>
          utils.renderCellValue(
            rowData.listPrice,
            null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Price Year",
        field: "priceYear",
        required: false,
        editable: constants.NEVER,
        cellStyle: styles.cellStyle,
      },

      {
        title: "Cycle Limit",
        field: "cycleLifeLimit",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.NEVER,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.cycleLifeLimit,
            null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Cycles Remaining",
        field: "cyclesBefore",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.cyclesBefore,
            rowData.isReplacement ? styles.boldCellStyle : null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Is Replacement",
        field: "isReplacement",
        initialEditValue: true,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: false,
        items: [
          { label: constants.YES, value: true },
          { label: constants.NO, value: false },
        ],
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          rowData.isReplacement ? (
            <Box style={styles.boldCellStyle}> {constants.YES} </Box>
          ) : (
            constants.NO
          ),
      },
      {
        title: "Cycles Remaining \n Replacement",
        field: "cyclesAfter",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => {
          return rowData.isReplacement ? (
            utils.renderCellValue(
              rowData.cyclesAfter,
              styles.boldCellStyle,
              constants.NUMERIC_CELL
            )
          ) : (
            <Box>{constants.___}</Box>
          );
        },
      },
    ],
    data: _.isEmpty(data)
      ? []
      : _.compact(
          data.map(function (llp, idx) {
            let replacedLLPStatus = _.find(llp.status, function (o) {
              return (
                moment.utc(o.statusDate).diff(selectedStatusDate, "days") === 0
              );
            });

            replacedLLPStatus = replacedLLPStatus || llp.status[0];
            return (
              replacedLLPStatus && {
                id: idx,
                serialNumber,
                engineSubSeries,
                position,
                module: llp.module,
                partName: llp.partName,
                partNumber: llp.partNumber,
                listPrice: llp.price[0].price,
                priceYear: llp.price[0].priceYear,
                priceEscRate: llp.price[0].escalationRate,
                statusDate: replacedLLPStatus.statusDate,
                cyclesBefore: replacedLLPStatus.cyclesBefore,
                cyclesAfter: replacedLLPStatus.cyclesAfter,
                cycleLifeLimit: llp.price[0].cycleLifeLimit,
                isReplacement: replacedLLPStatus.isReplacementDate,
                // cyclesLeft: llp.price[0].cycleLifeLimit - llp.status[0].cyclesAfter
              }
            );
          })
        ),
  };
}
