import React, { useState, useEffect } from "react";

import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import AssetInput from "../AssetInput";
import LoadingPanel from "../../../Common/LoadingPanel";
import moment from "moment";
import _ from "lodash";
import { styles } from "../../../../Components/AssetModel/mapToViewModels/common";

const mapToViewModel = () => {
  return [
    {
      label: ["Status As Of Date"],
      field: "asOfDate",
      type: constants.DATE,
      inputFieldType: constants.DATE,
      editable: constants.ALWAYS,
      initialEditValue: moment(),
      value: moment(),
      required: false,
      defaultSort: "desc",
      sorting: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(rowData.asOfDate, null, constants.DATE_CELL),
    },
    {
      label: ["Life Percentage"],
      field: "lifePercent",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMERIC,
      editable: constants.ALWAYS,
      value: 0.5,
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(constants.PERCENTAGE, value, required),
    },
    {
      label: ["Create Default Scenario?"],
      field: "createScenario",
      editable: true,
      required: false,
      value: "true",
      items: [
        {
          label: "Yes",
          value: "true",
        },
        { label: "No", value: "false" },
      ],
      type: constants.RADIO,
      inputFieldType: constants.RADIO,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
  ];
};

const LifePercent = ({ props }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(mapToViewModel());
  }, []);

  return data ? (
    <AssetInput columns={data} subTitle='Life Percentage' props={props} />
  ) : (
    <LoadingPanel />
  );
};

export default LifePercent;
