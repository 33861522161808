import _, { remove } from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import editableTables, { styles } from "../common";
import { mapToViewModel_EngineLLP } from "./engineLLPsViewModel";
import { Edit,ArrowDownwardOutlined  } from "@material-ui/icons";

export default function mapToViewModel_EnginePR(
  data,
  props,
  additionalLLPStatusProps,
  airlines,
  additionalData,
  partOut
) {
  var statusChoices = [{ label: "EnginePR", value: "EnginePR" }];
  const tableThreeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
    }
  ];
  if(props.isAircraft){
    tableThreeDotBtnOptions.push(
      {
        id: 1,
        icon: ArrowDownwardOutlined,
        label: `Take Off Wing`,
        handleClick: () => {
          props.confirmRemove();
        },
      });
  }
  var mxlist = data.maintenanceStatus.map((o, idx) => ({
    idx,
    serialNumber: data.serialNumber,
    eventName: "EnginePR",
    isFirstRun: o.isFirstRun ? constants.YES : constants.NO,
    asOfDate: o.statusAsOf || new Date(),
    flightCycles_sinceNew:
      o.sinceNew.flightCycle && _.round(o.sinceNew.flightCycle),
    flightHours_sinceNew:
      o.sinceNew.flightHour && _.round(o.sinceNew.flightHour),
    flightCycles_sinceEvent:
      o.sinceEvent.flightCycle && _.round(o.sinceEvent.flightCycle),
    flightHours_sinceEvent:
      o.sinceEvent.flightHour && _.round(o.sinceEvent.flightHour),
  }));
  var eventstats = [];
  data.supplementalEvents.forEach(
    (Element) =>
      (eventstats = eventstats.concat(
        Element.maintenanceStatus.map((o, idx) => ({
          idx,
          serialNumber: data.serialNumber,
          eventName: Element.eventName,
          isFirstRun: o.isFirstRun ? constants.YES : constants.NO,
          asOfDate: o.statusAsOf || new Date(),
          flightCycles_sinceNew:
            o.sinceNew.flightCycle && _.round(o.sinceNew.flightCycle),
          flightHours_sinceNew:
            o.sinceNew.flightHour && _.round(o.sinceNew.flightHour),
          flightCycles_sinceEvent:
            o.sinceEvent.flightCycle && _.round(o.sinceEvent.flightCycle),
          flightHours_sinceEvent:
            o.sinceEvent.flightHour && _.round(o.sinceEvent.flightHour),
        }))
      ))
  );
  data.supplementalEvents.forEach((x) =>
    statusChoices.push({ label: x.eventName, value: x.eventName })
  );
  var newlist = mxlist.concat(eventstats);
  return {
    engineInfo: {
      label: "Engine Details",
      // style: { paddingTop: 30 },
      caption: null,
      tableType: constants.DATA_TABLE,
      tableName: "Engine Details",
      props: props,
      errorsOnSubmit: {},
      showOptions: true,
      tableThreeDotBtnOptions: tableThreeDotBtnOptions,
      columns: [
        {
          label: ["Engine Operator"],
          field: "EPROperator",
          span: [3, 3],
          editable: true,
          required: false,
          hidden: false,
          items: airlines.map((airline) => {
            return {
              label: airline.name,
              value: airline.name,
            };
          }),
          initialEditValue: { label: data.operator, value: data.operator },
          type: constants.AUTO_COMPLETE,
          inputFieldType: constants.AUTO_COMPLETE,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          label: ["MODEL"],
          field: "engineSubSeries",
          captions: [],
          span: [3, 3],
          editable: true,
          required: true,
          hidden: false,
          items: additionalData
            ? additionalData.map((subSeries) => {
                return {
                  label: subSeries,
                  value: subSeries,
                };
              })
            : [],
          initialEditValue: {
            label: data.engineSubSeries,
            value: data.engineSubSeries,
          },
          type: constants.AUTO_COMPLETE,
          inputFieldType: constants.AUTO_COMPLETE,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          label: ["Serial Number"],
          field: "serialNumber",
          captions: [],
          span: [3, 3],
          editable: true,
          required: false,
          type: constants.TEXT,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
        {
          label: ["Date of Manufacture"],
          field: "engineDOM",
          captions: [],
          span: [3, 3],
          editable: true,
          required: false,
          type: constants.DATE,
          inputFieldType: constants.DATE,
          initialEditValue: moment.utc().format(constants.ISOFormat),
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) => value ? moment.utc(value).format(constants.DATE_FORMAT) : "---",
        },
        {
          label: ["POSITION"],
          field: "position",
          captions: [],
          span: [3, 3],
          editable: false,
          required: false,
          type: constants.STRING,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
        {
          label: ["Engine Specification Adjusters"],
          field: "qeC_Note",
          captions: [],
          span: [3, 3],
          editable: true,
          required: false,
          items: partOut
            ? partOut.map((qec) => {
                return {
                  label: qec,
                  value: qec,
                };
              })
            : [],

          //value:  data.engineSpecificationAdjusterDescriptions,
          initialEditValue: data.engineSpecificationAdjusterDescriptions
            ? data.engineSpecificationAdjusterDescriptions.map((qec) => {
                return {
                  label: qec,
                  value: qec,
                };
              })
            : [],
          type: constants.MULTI_AUTO,
          inputFieldType: constants.MULTI_AUTO,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
      ],
      data: {
        engineSubSeries: data.engineSubSeries,
        serialNumber: data.serialNumber,
        engineDOM: data.manufactureDate,
        position: data.position ? _.last(data.position.split(" ")) : null,
        EPROperator: data.operator,
        qeC_Note: data.engineSpecificationAdjusterDescriptions.join(", "),
      },
    },
    engineStatus: {
      label: "Maintenance Status",
      caption:
      "\"Since Event\" values will be auto-populated if there is overhaul shop visit history",
      props,
      showOptions: true,
      sorting: true,
      tableType: constants.EDITABLE_TABLE,
      tableName: constants.engineStatus,
      // isDeletable: (rowData) => {
      //   return false;
      // },
      errorsOnSubmit: {},
      columns: [
        {
          title: "As Of Date",
          field: "asOfDate",
          type: constants.DATE,
          inputFieldType: constants.DATE,
          editable: constants.ALWAYS,
          initialEditValue: moment.utc().format(constants.ISOFormat),
          required: true,
          defaultSort: "desc",
          sorting: true,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(rowData.asOfDate, null, constants.DATE_CELL),
        },
        {
          title: "Serial Number",
          field: "serialNumber",
          cellStyle: styles.cellStyle,
          type: constants.STRING,
          inputFieldType: constants.TEXT,
          editable: constants.NEVER,
          initialEditValue: data.serialNumber,
          required: false,
          sorting: false,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },

        statusChoices.length > 1
          ? {
              title: "Event Name",
              field: "eventName",
              type: constants.STRING,
              inputFieldType: constants.SELECT,
              initialEditValue: constants.NO,
              items: statusChoices,
              required: false,
              sorting: false,
              editable: constants.ALWAYS,
              cellStyle: styles.cellStyle,
            }
          : {
              title: "Event Name",
              field: "eventName",
              cellStyle: styles.cellStyle,
              type: constants.STRING,
              inputFieldType: constants.TEXT,
              editable: constants.NEVER,
              initialEditValue: "EnginePR",
              required: true,
              sorting: false,
              validate: (inputType, value, required) =>
                utils.validate(inputType, value, required),
            },
        {
          title: "Hours Since New",
          field: "flightHours_sinceNew",
          type: constants.NUMERIC,
          inputFieldType: constants.NUMBER,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.flightHours_sinceNew,
              null,
              constants.NUMERIC_CELL
            ),
        },
        {
          title: "Cycles Since New",
          field: "flightCycles_sinceNew",
          type: constants.NUMERIC,
          inputFieldType: constants.NUMBER,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.flightCycles_sinceNew,
              null,
              constants.NUMERIC_CELL
            ),
        },
        {
          title: "Hours Since Event",
          field: "flightHours_sinceEvent",
          type: constants.NUMERIC,
          inputFieldType: constants.STATUSMXCH,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.flightHours_sinceEvent,
              null,
              constants.NUMERIC_CELL
            ),
        },
        {
          title: "Cycles Since Event",
          field: "flightCycles_sinceEvent",
          type: constants.NUMERIC,
          inputFieldType: constants.STATUSMXCH,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.flightCycles_sinceEvent,
              null,
              constants.NUMERIC_CELL
            ),
        },
        {
          title: "First Run",
          field: "isFirstRun",
          type: constants.STRING,
          inputFieldType: constants.SELECT,
          initialEditValue: constants.NO,
          items: [
            { label: constants.YES, value: constants.YES },
            { label: constants.NO, value: constants.NO },
          ],
          required: false,
          sorting: false,
          editable: constants.ALWAYS,
          cellStyle: styles.cellStyle,
        },
      ],
      // data: data.maintenanceStatus.map((o, idx) => ({
      //   idx,
      //   serialNumber: data.serialNumber,
      //   isFirstRun: o.isFirstRun ? constants.YES : constants.NO,
      //   asOfDate: o.statusAsOf || new Date(),
      //   flightCycles_sinceNew:
      //     o.sinceNew.flightCycle && _.round(o.sinceNew.flightCycle),
      //   flightHours_sinceNew:
      //     o.sinceNew.flightHour && _.round(o.sinceNew.flightHour),
      //   flightCycles_sinceEvent:
      //     o.sinceEvent.flightCycle && _.round(o.sinceEvent.flightCycle),
      //   flightHours_sinceEvent:
      //     o.sinceEvent.flightHour && _.round(o.sinceEvent.flightHour),
      // })),
      data: newlist,
    },
    shopVisitHistory: editableTables.shopVisitHistory(
      data.shopVisitHistory,
      props
    ),
    scheduledShopVisit: editableTables.scheduledShopVisits(data, props),
    engineLLPStatus: mapToViewModel_EngineLLP(
      data.llpStack.llPs,
      props,
      data.engineSubSeries,
      data.serialNumber,
      data.position,
      ...additionalLLPStatusProps
    ),
  };
}
