// import * as constants from "../../../constants";
import { mapToViewModel_aircraftDetails } from "./aircraftDetails";
import { mapToViewModel_leaseDetails } from "./leaseDetails";
import { MXReserve_LessorContrib } from "./MXReserve_LessorContrib/index";
import { mapToViewModel_leaseOptions } from "./leaseOptions";
import { RedeliveryConditions } from "./RedeliveryConditions/index";
import { mapToViewModel_engineSubstitution } from "./engineSubstitution";
import { mapToViewModel_airworthinessDirectives } from "./airworthinessDirectives";
import { mapToViewModel_insurance } from "./insurance";
import { mapToViewModel_taxAndIndemnities } from "./taxIndemnities";
import { mapToViewModel_other_Legal } from "./otherLegal";

export const styles = {
  cellStyle: {
    color: "#023059",
    textAlign: "center",
    fontSize: 11,
    height: 40,
    padding: 0,
    borderBottom: "unset",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  boldCellStyle: {
    fontWeight: "bold",
    fontSize: 12,
  },
};

export function mapToViewModel(
  assetType,
  leaseSection,
  props,
  leaseSummary,
  threeDotBtnOptions,
) {
  switch (leaseSection) {
    case "aircraftDetails":
      return mapToViewModel_aircraftDetails(assetType, leaseSummary, props);
    case "leaseDetails":
      return mapToViewModel_leaseDetails(leaseSummary, props);
    case "mxReserve_LessorContrib":
      return MXReserve_LessorContrib(leaseSummary, props, threeDotBtnOptions);
    case "leaseOptions":
      return mapToViewModel_leaseOptions(leaseSummary, props);
    case "redeliveryConditions":
      return RedeliveryConditions(leaseSummary, props, threeDotBtnOptions);
    case "engineSubstitution":
      return mapToViewModel_engineSubstitution(leaseSummary, props);
    case "airworthinessDirectives":
      return mapToViewModel_airworthinessDirectives(leaseSummary, props);
    case "insurance":
      return mapToViewModel_insurance(leaseSummary, props);
    case "taxAndIndemnities":
      return mapToViewModel_taxAndIndemnities(leaseSummary, props);
    case "other_Legal":
      return mapToViewModel_other_Legal(leaseSummary, props);
    default:
      break;
  }
}