import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Box, Slide } from "@material-ui/core";

import MultiFunctionTable from "../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import FilteringTable from "../../Common/Tables/FilteringTable/FilteringTable";
import AssetsKPI from "./AssetsKPI";
import TopFilters from "./TopFilters";
import SlideInPanel from "./AssetDetailSlideIn/SlideInPanel";
import { mapToViewModel_Assets } from "../mapToViewModels/assetsViewModel";

const MainContent = ({
  assets,
  allassets,
  selectedSortBy,
  unassignedSelected,
  onAssetAddClick,
  onAssetSearch,
  onDisplayAssetTypeChange,
  onSortByChange,
  onUnassignedAssetsOptionChange,
  onColumnCheck,
  onProfileSwap,
  filterProfiles,
  selectedProfile,
  cols
}) => {
  const [open, setOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [updateRenderKey, setUpdateRenderKey] = useState(0);

  const props = {
    funcs: {
      handleClick: (assetId, assetType, serialNumber) => {
        setOpen(!open);
        setSelectedAsset(
          _.find(
            allassets,
            (o) =>
              o.id === assetId &&
              o.assetType === assetType &&
              o.serialNumber === serialNumber
          )
        );
      },
      addAsset: onAssetAddClick,
    },
  };

  const tableData = mapToViewModel_Assets(assets,cols, props);

  const handleSlidePanelClose = () => {
    setOpen(false);
  };
  
  return (
    <Box>
      <TopFilters
        assets={assets}
        selectedSortBy={selectedSortBy}
        unassignedSelected={unassignedSelected}
        onAssetSearch={onAssetSearch}
        onUnassignedAssetsOptionChange={onUnassignedAssetsOptionChange}
        onDisplayAssetTypeChange={onDisplayAssetTypeChange}
        onSortByChange={onSortByChange}
        onAssetAddClick={onAssetAddClick}
        setUpdateRenderKey={setUpdateRenderKey}
        updateRenderKey={updateRenderKey}
        columns={cols}
        onSwitchChange={onColumnCheck}
        onProfileSwap={onProfileSwap}
        profiles={filterProfiles}
        selectedProfile={selectedProfile}
      />
      <AssetsKPI assets={assets} />
      <Box style={{ margin: "auto", width: "100%" }}>
        {/* <MultiFunctionTable
          key={updateRenderKey}
          tableData={tableData}
          containerMaxHeight={window.innerHeight - 250}
        /> */}

        <FilteringTable
          table={tableData}
          maxHeight={window.innerHeight - 250}
        />
      </Box>
      <Box>
        <Slide direction='left' in={open} mountOnEnter unmountOnExit>
          <SlideInPanel
            asset={selectedAsset}
            onSlidePanelClose={handleSlidePanelClose}
          />
        </Slide>
      </Box>
    </Box>
  );
};

export default MainContent;
