import React from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  link: {
    textDecoration: "none",
    color: "#0E253F",
  },
  deals: {
    fontSize: 10,
    lineHeight: "16px",
  },
  assetPoolName: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "0.25px",
  },
}));

const PoolName = ({ assetPool }) => {
  const classes = useStyles();
  const { assetPoolName, assetPoolId } = assetPool;
  return (
    <Box className={classes.root}>
      <Typography
        variant='caption'
        color='secondary'
        noWrap={true}
        className={classes.deals}>
        Pool {assetPoolId}
      </Typography>
      <Typography
        variant='h1'
        color='primary'
        className={classes.assetPoolName}
        noWrap={true}>
        {assetPoolName}
      </Typography>
    </Box>
  );
};

export default PoolName;
