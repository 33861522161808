import _ from "lodash";
import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import InputFieldComponent from "../EditingComponents/InputFieldComponent";
import * as constants from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  multiFields: {
    // width: 400,
    maxHeight: 800,
    overflow: "auto",
    padding: 30,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  buttons: {
    padding: "30px 0px 0px 69px",
    textAlign: "right",
  },
  cancelText: {
    height: 16,
    width: 80,
    color: "rgba(0,0,0,0.38)",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "2px",
    lineHeight: "16px",
    textAlign: "left",
  },
  submitText: {
    height: 16,
    width: 80,
    color: "#0D47A1",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
  submitTextDisabled: {
    color: "rgba(0,0,0,0.38)",
  },
  submitButton: {
    width: 104,
    height: 36,
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: 4,
    backgroundColor: "rgba(98,2,238,0)",
  },
  rowStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconButton: {
    height: 50,
    width: 50,
    padding: 4,
  },
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(227, 242, 253)",
    },
  },
}));

const ShopVisitAssumptionEditingPanel = (
  { section, sectionIdx, tableName, onClose, onValuesUpdate, data, table },
  ref
) => {
  const columns = table.columns;
  const detailPanel =
    table.detailPanel && table.detailPanel.shouldRender
      ? table.detailPanel
      : false;
  const detailPanelData = detailPanel ? detailPanel.detailPanelData(data) : {};

  const classes = useStyles();
  const [error, setError] = useState(null);
  const [values, setValues] = useState({ ...data, ...detailPanelData });

  // This override check is for the reset to override button and also checking the initialization of the required columns
  const isOverride = (field) => {
    if (_.isObjectLike(data[field])) {
      return data[field].generic === data[field].override ||
        !data[field].override
        ? true
        : false;
    } else if (field === "swap" || field.includes("Comment")) return true;
  };

  // this override check if for when new values are entered in the form and we need to check if the comments field still need required label on it
  const isNewValueOverride = (field, newValues) => {
    if (_.isObjectLike(newValues[field])) {
      return `${data[field].generic}` === `${newValues[field].override}`
        ? false
        : true;
    } else if (
      field.includes("swap") ||
      field.includes("Comment") ||
      field.includes("greenTime")
    )
      return true;
    else {
      return `${data[field].generic}` === `${newValues[field]}` ? false : true;
    }
  };

  // column override and required setting are changes on the fly based off of the logic for sv assumptions
  const [overrides, setOverrides] = useState(
    Object.assign(
      {},
      ...columns.map((col) => ({ [col.field]: isOverride(col.field) }))
    )
  );

  const [requiredCol, setRequiredCol] = useState(
    Object.assign(
      {},
      ...columns.map((col) => {
        if (
          col.field.includes("Comment") &&
          !isOverride(col.field.replace("Comment", ""))
        )
          return { [col.field]: true };
        else return { [col.field]: col.required };
      })
    )
  );

  const handleChange = (col, value, resetOverride = false) => {
    let newValues = { ...values };
    if (resetOverride) {
      newValues[col.field] = data[col.field].generic;
      newValues[`${col.field}Comment`] = null;

      var newOverrides = _.cloneDeep(overrides);
      newOverrides[col.field] = true;
      setOverrides(newOverrides);
    } else {
      newValues[col.field] = value ? value : null;
    }

    var newRequired = _.cloneDeep(requiredCol);

    if (
      !col.field.includes("Comment") &&
      isNewValueOverride(col.field, newValues) &&
      col.field !== "swap"
    )
      newRequired[`${col.field}Comment`] = true;
    else newRequired[`${col.field}Comment`] = false;

    setRequiredCol(newRequired);
    setValues(newValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var checkCommentRequirement = false;
    for (const [colName, req] of Object.entries(requiredCol)) {
      if (colName.includes("Comment") && req && !values[colName]) {
        if (!colName.includes("swap") && !colName.includes("greenTime"))
          checkCommentRequirement = true;
      }
    }

    if (checkCommentRequirement)
      setError("Not all required comment fields have been filled");
    else {
      onValuesUpdate(constants.UPDATE_ROW)(
        section,
        sectionIdx,
        tableName,
        values
      );
      setError(null);
      onClose();
    }
  };

  const generateFields = () => {
    return (
      <Grid container alignItems={"flex-end"} spacing={2}>
        {columns.map((col, idx) => (
          <Grid item key={idx} sm={12}>
            {col.editable === "always" ||
            (col.field.includes("Comment") && requiredCol[col.field]) ? (
              <>
                <Box className={classes.rowStyle}>
                  <Box width={"100%"}>
                    <InputFieldComponent
                      value={
                        _.isObjectLike(values[col.field])
                          ? values[col.field].override === 0 ||
                            values[col.field].override
                            ? values[col.field].override
                            : values[col.field].generic
                          : values[col.field]
                      }
                      type={col.inputFieldType}
                      multiline={col.multiline}
                      rows={col.field.includes("Comment") ? 2 : 1}
                      onChange={(value) => handleChange(col, value)}
                      items={col.items ? col.items : null}
                      label={[col.title]}
                      fullWidth={true}
                      variant={"filled"}
                      datePickervariant={"filled"}
                      required={requiredCol[col.field]}
                      keyboardButtonProps={{
                        style: { padding: "12px 0 0 0" },
                      }}
                      className={
                        col.field.includes("Comment") && requiredCol[col.field]
                          ? classes.root
                          : null
                      }
                      disabled={
                        detailPanel &&
                        detailPanelData &&
                        col.title.includes("Cost")
                          ? true
                          : false
                      }
                    />
                  </Box>

                  <IconButton
                    style={{ margin: "4px -10px 0 8px" }}
                    className={classes.iconButton}
                    disabled={overrides[col.field]}
                    onClick={() => {
                      handleChange(col, null, true);
                    }}>
                    <Tooltip
                      title='Reset Override to Generic'
                      aria-label='Reset Override to Generic'>
                      <DeleteForever
                        color={overrides[col.field] ? "disabled" : "secondary"}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
                {col.field.includes("Comment") && requiredCol[col.field] ? (
                  <Divider
                    variant='middle'
                    style={{ margin: "20px 44px 12px 0" }}
                  />
                ) : null}
              </>
            ) : null}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Box
        className={classes.multiFields}
        style={{ width: detailPanel && detailPanelData ? 770 : 400 }}>
        <Grid container alignItems={"flex-end"} spacing={2}>
          <Grid item md={12}>
            <Box style={{ color: "red" }}>{error && `* ${error}`}</Box>
          </Grid>
        </Grid>

        {detailPanel && detailPanelData ? (
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box width={348}>{generateFields()}</Box>
            <Divider orientation='vertical' flexItem />
            <Box width={300} style={{ margin: "16px 0 0 10px" }}>
              <Grid container alignItems={"flex-end"} spacing={2}>
                <Grid item sm={12}>
                  <Box
                    style={{
                      height: 56,
                      margin: "0 auto",
                      textAlign: "center",
                      fontSize: "50",
                      fontWeight: "bold",
                      color: "#023059",
                      textDecoration: "underline",
                      textUnderlineOffset: "4px",
                      padding: "20px 0 0 0",
                    }}>
                    Engine Swaps
                  </Box>
                </Grid>
                {detailPanel.columns.map((col, idx) => (
                  <Grid item key={idx} sm={12}>
                    <Box className={classes.rowStyle}>
                      <Box width={"100%"}>
                        <InputFieldComponent
                          value={values[col.field]}
                          type={col.inputFieldType}
                          onChange={(value) => handleChange(col, value)}
                          items={col.items ? col.items : null}
                          label={col.label}
                          fullWidth={true}
                          variant={"filled"}
                          datePickervariant={"filled"}
                          required={col.required}
                          keyboardButtonProps={{
                            style: { padding: "12px 0 0 0" },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ) : (
          generateFields()
        )}

        <Box className={classes.buttons}>
          <Button onClick={onClose}>
            <span className={classes.cancelText}>CANCEL</span>
          </Button>
          <Button
            className={classes.submitButton}
            variant='outlined'
            type='submit'>
            <span className={classes.submitText}>SAVE</span>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

const forwardEditableTable = React.forwardRef(ShopVisitAssumptionEditingPanel);
export default forwardEditableTable;
