import { generateAirframeCheck } from "./airframeCheck";
import { generateAPU } from "./apu";
import { generateEngine } from "./engine";
import { generateLandingGear } from "./landingGear";

import * as constants from "../../../../constants";

export const transformData = async (
  component,
  data,
  addtionalInfo = null,
  msalContext
) => {
  switch (component) {
    case constants.APU:
      return generateAPU(data, addtionalInfo);
    case constants.Engines:
      return await generateEngine(data, addtionalInfo, msalContext);
    case constants.NOSE:
    case constants.LEFT_MAIN:
    case constants.RIGHT_MAIN:
    case constants.CENTER:
    case constants.RIGHT_WING:
    case constants.RIGHT_BODY:
    case constants.LEFT_BODY:
    case constants.LEFT_WING:
    case constants.LEFT_MAIN:
      return generateLandingGear(component, data);
    default:
      return generateAirframeCheck(component, data);
  }
};
