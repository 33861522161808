import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  formControlLabel: { fontSize: 14, fontWeight: 500, color: "#023059" },
});

const VfRadioGroup = ({ label, items, selected, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState(selected);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        aria-label="radioGroup"
        name="radioGroup"
        value={value}
        onChange={handleChange}
      >
        {items.map((item, idx) => (
          <FormControlLabel
            key={idx}
            control={<Radio color="primary" />}
            value={item.value}
            label={
              <span className={classes.formControlLabel}>{item.label}</span>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default VfRadioGroup;
