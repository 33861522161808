import _ from "lodash";
import axios from "axios";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { apiRequest, response } from "../../utils/ApiRequest";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import apiRoutes from "../../utils/ApiRoutes";
import * as constants from "../../constants";
import * as stateUpdater from "./stateUpdate";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import MultiFieldsFadeInEditingPanel from "../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import LoadingPanel from "../Common/LoadingPanel";
import Errors from "../Common/Errors";
import VfAlertDialogBox from "../Common/UtilComponents/VfAlertDialogBox";
import { Box, Button, Tooltip } from "@material-ui/core";
import SideNavBar from "./Components/SideNavBar/SideNavBar";
import LoanContent from "./Components/LoanContent/LoanContent";
import { generateLSTemplate } from "./loanSummaryTemplate";
import ReactToPrint from "react-to-print";
import SectionHeader from "../Common/Section/SectionHeader";
import { Prompt } from "react-router";
import { MsalContext } from "@azure/msal-react";
import VfPrint from "../Common/UtilComponents/VfPrint";

const styles = {
  root: {
    paddingTop: 50,
    paddingBottom: 10,
  },
};

class LoanSummaryComponents extends Component {
  static contextType = MsalContext;

  assetSummaryTokenSource = null;
  loanSummaryTokenSource = null;
  constructor(props) {
    super(props);
    this.loanContentRef = React.createRef();
    this.state = {
      deal: null,
      loanSummary: null,
      selected: { label: "Aircraft Details", caption: null },
      editing: false,
      editAll: false,
      isAlertDialogOpen: false,
      openCopy: false,
      copyProps: null,
      uniqueSummaries: null,
    };
  }

  generateEditableProps = (idx, section) => ({
    sectionIdx: idx,
    section,
    funcs: {
      handleValuesUpdate: this.handleValueChange(constants.UPDATE_ROW),
      handleRowAdd: this.handleValueChange(constants.ADD_ROW),
      handleColumnAdd: this.handleValueChange(constants.ADD_COLUMN),
      handleRowDelete: this.handleValueChange(constants.DELETE_ROW),
      validateData: this.validateData,
      // allow for custom props in child components
      handleValueChange: this.handleValueChange,
    },
  });

  getLoanSummary = async (id) => {
    let apiEndPoint = apiRoutes.LoanSummary_GetDTO({
      id,
    });

    this.loanSummaryTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.loanSummaryTokenSource.token,
      },
      this.context
    );
  };

  getAllLoanSummaries = async () => {
    this.loanSummaryTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiRoutes.LoanSummary_GetAllLoanSummaries,
        method: "GET",
        cancelToken: this.loanSummaryTokenSource.token,
      },
      this.context
    );
  };

  getDealsData = async (dealId) => {
    this.loanSummaryTokenSource = axios.CancelToken.source();
    let apiEndPoint = apiRoutes.VectorDeal_GetDealsByDealId({
      dealId,
    });
    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.loanSummaryTokenSource.token,
      },
      this.context
    );
  };

  componentDidMount = async () => {
    const url = new URL(window.location.href);
    const pathValues = url.pathname.split("/");
    const dealId = pathValues[pathValues.length - 1];
    let deal = await this.getDealsData(dealId);
    if (deal.status === response.OK) {
      this.setState({ deal: deal.data });
    }
    let loanSummary = await this.getLoanSummary(dealId);
    if (loanSummary.status === response.OK) {
      this.setState({
        loanSummary: loanSummary.data,
      });
    }
    // window.addEventListener('scroll', this.handleScroll);
  };

  componentDidUpdate = () => {
    if (this.state.editing) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  // componentWillUnmount = () => {
  //     window.removeEventListener('scroll', this.handleScroll);
  // };

  validateData = (newData, columns, section, tableName, actionType) => {
    let allValid = true;
    let errorsOnSubmit = {};
    return allValid;
  };

  // handleScroll = (event) => {
  //   const loanSectionItems = [
  //     { label: "Aircraft Details", caption: null },
  //     { label: "Loan Details", caption: null },
  //     { label: "Maintenance Reserves / Lessor Contributions", caption: null },
  //     { label: "Loan Options", caption: null },
  //     { label: "Redelivery Conditions", caption: null },
  //     { label: "Engine Substitution", caption: null },
  //     { label: "Airworthiness Directives", caption: null },
  //     { label: "Insurance", caption: null },
  //     { label: "Tax and Indemnities", caption: null },
  //     { label: "Other/Legal", caption: null },
  //   ];
  //   const selectElement = document.getElementById("Loan Details")
  //   if (selectElement) {
  //     if (window.scrollY >= selectElement.getBoundingClientRect().top) {
  //     }
  //   }
  // };

  handleSelectedChange = (selected) => {
    this.setState({
      selected: selected,
    });
  };

  handleValueChange =
    (action) => (section, sectionIdx, fieldName, newData, columns) => {
      const loanSummary = { ...this.state.loanSummary };
      stateUpdater.updateTable(
        fieldName,
        action,
        section,
        sectionIdx,
        newData,
        loanSummary,
        columns
      );
      loanSummary.published = false;
      this.setState({ loanSummary, editing: true });
    };

  handleNewLoanSummary = () => {
    const { deal } = this.state;
    const userName = this.context.accounts[0].name;
    const loanSummary = _.cloneDeep(generateLSTemplate(deal, userName));
    loanSummary.newLoanSummary = true;
    this.setState({
      loanSummary: loanSummary,
      editing: true,
    });
    this.handleAlertDialogVisible(false);
  };

  handleCopyLoanSummary = async (section, sectionIdx, fieldName, newData) => {
    const { deal } = this.state;
    const userName = this.context.accounts[0].name;
    const loanSummary = _.cloneDeep(generateLSTemplate(deal, userName));
    let copiedloanSummary = await this.getLoanSummary(parseInt(newData.dealId));
    if (copiedloanSummary.status === response.OK) {
      let copiedloanSummaryData = copiedloanSummary.data;
      loanSummary.taxAndIndemnities = copiedloanSummaryData.taxAndIndemnities;
      loanSummary.fees = copiedloanSummaryData.fees;
      loanSummary.financialCovenants = copiedloanSummaryData.financialCovenants;
      loanSummary.prepayments = copiedloanSummaryData.prepayments;
      loanSummary.information_ReportingCovenants =
        copiedloanSummaryData.information_ReportingCovenants;
      loanSummary.restrictiveCovenants =
        copiedloanSummaryData.restrictiveCovenants;
      loanSummary.eventsOfDefault = copiedloanSummaryData.eventsOfDefault;
      loanSummary.other_Legal = copiedloanSummaryData.other_Legal;

      this.setState({
        loanSummary: loanSummary,
        editing: true,
      });
    }
  };

  handleGetLoanSummaries = async () => {
    let loanSummaries = await this.getAllLoanSummaries();
    if (loanSummaries.status === response.OK) {
      let uniqueSummaries = _.unionBy(loanSummaries.data, "dealId");
      let props = {
        section: "copyloan",
        sectionIdx: 1,
        funcs: {
          handleValuesUpdate: (section, sectionIdx, fieldName, newData) => {
            this.handleCopyLoanSummary(section, sectionIdx, fieldName, newData);
          },
          validateData: this.validateData,
        },
      };

      this.setState({
        copyProps: props,
        uniqueSummaries: uniqueSummaries,
        openCopy: true,
      });
    }
  };

  handleAlertDialogVisible = (open) => {
    this.setState({ isAlertDialogOpen: open });
  };

  handlePublished = () => {
    this.setState({ editing: false });
  };

  renderNewLoanSummaryBody = () => {
    return this.state.loanSummary ? (
      <div>
        <p>
          Are you sure you would like to <b>Override</b> the current loan
          summary?
        </p>
        <p>
          You may copy from an existing loan summary by clicking <b>COPY</b> or{" "}
          <b>AGREE</b> to create completely new loan summary
        </p>
        <p>
          Note: The current loan summary will not be replaced until you publish.
        </p>
      </div>
    ) : (
      <div>
        <p>
          You may copy from an existing loan summary by clicking <b>COPY</b> or{" "}
          <b>AGREE</b> to create completely new loan summary
        </p>
      </div>
    );
  };

  renderActionButtons = () => {
    const pageStyle = `
      @media all {
        .pagebreak {
          overflow: hidden; height: 0;
        }
      }

      @media print {
        @page { 
          size: landscape; 
          margin: 0mm;
        }

        #threedotbutton {
          visibility: hidden;
        }
        #loanSummarySideBar {
          overflow: hidden; 
          height: 0;
        }
        #loanSummaryTitle {
          overflow: hidden; 
          height: 0;
        }
        #loanSummaryContent {
          width: 100%;
          margin-left: -200px;
        }
        .scrollMargin {
          padding: 0px;
          margin: 0 auto;
        }
      }
    `;
    return (
      <Box style={{ marginTop: 12 }}>
        {this.state.loanSummary ? (
          <>
            <Tooltip title='Edit All' aria-label='edit all'>
              <Button
                style={{ maxHeight: 28, marginLeft: 16 }}
                variant='outlined'
                color='primary'
                onClick={() => this.setState({ editAll: true })}>
                <span style={{ margin: "0px 8px 0px 8px" }}>Edit All</span>
              </Button>
            </Tooltip>
            <Tooltip title='Print / to PDF' aria-label='print/pdf'>
              {/* <ReactToPrint
                trigger={() => (
                  <Button
                    style={{ maxHeight: 28, marginLeft: 16 }}
                    variant='outlined'
                    color='primary'
                    disabled={this.state.loanSummary.published ? false : true}>
                    <span style={{ margin: "0px 8px 0px 8px" }}>Print</span>
                  </Button>
                )}
                content={() => this.componentRef}
                pageStyle={pageStyle}
                documentTitle={`MSN ${this.state.deal.dealName} Loan Summary`}
              /> */}
              <VfPrint
                styleId={"leaseSummaryPrint"}
                margin={20}
                orientation={"landscape"}
                additionalCss={`html {
                  zoom: 80%;
                  margin: 0 auto;
                  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
                  color-adjust: exact !important; /*Firefox*/
                  width: 100%;
                }`}
              />
            </Tooltip>
          </>
        ) : (
          <></>
        )}
        <Tooltip title='New Loan Summary' aria-label='new loan summary'>
          <Button
            style={{ maxHeight: 28, marginLeft: 16 }}
            variant='outlined'
            color='primary'
            onClick={() => this.handleAlertDialogVisible(true)}>
            <span style={{ margin: "0px 8px 0px 8px" }}>New</span>
          </Button>
        </Tooltip>
      </Box>
    );
  };

  renderBreadCrumbs = (deal) => {
    const { sponsor, dealName } = deal;
    const dealInfo = _.template(`Portfolio - <%= sponsor %>/<%= dealName %>`);
    return [
      {
        label: "Deals",
        type: constants.LINK,
        tooltip: "Back to Deals",
        props: { to: "/deals", handleClick: null },
      },
      {
        label: dealInfo({ sponsor, dealName }),
        type: constants.LINK,
        tooltip: "Go to Portfolio",
        props: {
          to: {
            pathname: `/deal/${deal.dealName}/portfolio`,
            state: { deal: deal, dealId: deal.dealId },
          },
          handleClick: null,
        },
      },
      {
        label: "Loan Summary",
        type: constants.TEXT,
        tooltip: "Loan Summary",
      },
    ];
  };

  render() {
    const {
      deal,
      loanSummary,
      selected,
      editing,
      editAll,
      isAlertDialogOpen,
      copyProps,
      openCopy,
      uniqueSummaries,
    } = this.state;

    const loanSectionItems = [
      { label: "Senior Loan Details", caption: null },
      { label: "Collateral", caption: null },
      { label: "Tax and Indemnities", caption: null },
      { label: "Fees", caption: null },
      { label: "Financial Covenants", caption: null },
      { label: "Prepayments", caption: null },
      { label: "Information/Reporting Covenants", caption: null },
      { label: "Restrictive Covenants", caption: null },
      { label: "Events of Default", caption: null },
      { label: "Other/Legal", caption: null },
    ];

    const navBarSectionItems = [
      {
        label: "Loan Sections",
        listItems: loanSectionItems,
      },
    ];

    return deal ? (
      <ErrorBoundary>
        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={this.handleAlertDialogVisible}
          dialogTitle={"New Loan Summary"}
          dialogBody={this.renderNewLoanSummaryBody()}
          customButton={{
            label: "Copy",
            handleClick: () => {
              this.handleGetLoanSummaries();
              this.setState({ openCopy: true });
            },
          }}
          onAccept={this.handleNewLoanSummary}
        />

        <VfBreadcrumbContainer
          breadcrumb={
            <VfBreadcrumbs breadcrumbs={this.renderBreadCrumbs(deal)} />
          }
        />

        <Prompt
          when={editing}
          message='You have unsaved changes, are you sure you want to leave?
            "OK" to continue and discard changes.
            Or "Cancel" to stay on page.'
        />

        <Box
          spacing={10}
          ref={(el) => (this.componentRef = el)}
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 10fr",
            marginTop: 20,
          }}>
          <Box style={{ width: 220, paddingRight: 10 }} id='loanSummarySideBar'>
            <SideNavBar
              deal={deal}
              navBarSectionItems={navBarSectionItems}
              selected={selected}
              onSelectedChange={this.handleSelectedChange}
            />
          </Box>
          <Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "28px 0 28px 0",
              }}
              id='loanSummaryTitle'>
              <SectionHeader label={"Loan Summary"} />
              {this.renderActionButtons()}
            </Box>
            {loanSummary ? (
              <Box style={{ maxWidth: "100%" }} id='loanSummaryContent'>
                <LoanContent
                  loanSummary={loanSummary}
                  generateEditableProps={this.generateEditableProps}
                  editing={editing}
                  editAll={editAll}
                  handlePublished={this.handlePublished}
                />
              </Box>
            ) : (
              <Box style={{ paddingTop: 40 }}>
                <Errors
                  msg={
                    "There is currently no loan summary for this asset, ploan click on new loan summary button."
                  }
                />
              </Box>
            )}
          </Box>

          <Box style={{ height: 75 }}></Box>

          {uniqueSummaries ? (
            <MultiFieldsFadeInEditingPanel
              open={openCopy}
              columns={[
                {
                  label: ["Deal Name"],
                  field: "dealId",
                  editable: true,
                  required: true,
                  items: uniqueSummaries.map((o) => {
                    return {
                      label: `${o.dealSponsor}-${o.dealName}`,
                      value: o.dealId,
                    };
                  }),
                  type: constants.AUTO_COMPLETE,
                  inputFieldType: constants.AUTO_COMPLETE,
                },
              ]}
              data={null}
              tableName={""}
              props={copyProps}
              onClose={() => this.setState({ openCopy: false })}
            />
          ) : (
            <></>
          )}
        </Box>
      </ErrorBoundary>
    ) : (
      <LoadingPanel />
    );
  }
}

export default withStyles(styles)(LoanSummaryComponents);
