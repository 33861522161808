import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Box, Chip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as constants from "../../../../../../../constants";

const useStyles = makeStyles({
  chip: {
    height: 22,
    minWidth: 86,
    borderRadius: 6,
    fontSize: 13,
    fontWeight: 500,
    border: " 1px solid #023059",
    backgroundColor: "#BDC7D1",
    color: "#023059",
    marginRight: 8,
    "&:hover": {
      backgroundColor: "#023059",
      cursor: "pointer",
      color: "#ffffff",
    },
  },
  chipSelected: {
    opacity: 0.8,
    color: "#ffffff",
    backgroundColor: "#023059 !important",
  },
  // content: { paddingLeft: 14 },
  chipContent: {
    paddingLeft: 2,
    paddingRight: 2,
  },
});

const renderSection = (
  events,
  eventType,
  classes,
  key,
  selectedEvent,
  onEventChange,
  setSelectedEvent
) => {
  let eventName = "";
  switch (eventType) {
    case constants.AIRFRAME_CHECKS:
      eventName = constants.Airframe_Checks;
      break;
    case constants.ENGINES:
      eventName = constants.Engines;
      break;
    case constants.LANDING_GEARS:
      eventName = constants.Landing_Gears;
      break;
    case constants.apUs:
      eventName = constants.APUs;
      break;
    case "llps":
      eventName = "Engine LLPs";
      break;
    default:
      break;
  }

  return (
    <Box
      key={key}
      className={classes.content}
      style={{ minWidth: eventType === constants.LANDING_GEARS ? 300 : 225 }}>
      <Typography
        variant='caption'
        color='primary'
        style={{ marginBottom: -5 }}>
        {`${eventName}:`}
      </Typography>
      <Box>
        {events.map((e, j) => (
          <Chip
            key={j}
            style={{ marginTop: 5 }}
            classes={{ label: classes.chipContent }}
            className={
              selectedEvent === e.label
                ? `${classes.chip} ${classes.chipSelected}`
                : classes.chip
            }
            label={e.label}
            onClick={() => {
              setSelectedEvent(e.label);
              onEventChange(e.label);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

const Events = ({ events, onEventChange, selected }) => {
  const classes = useStyles();
  const [selectedEvent, setSelectedEvent] = useState(selected);
  const groupedEvents = _.groupBy(events, "componentType");

  useEffect(() => {
    setSelectedEvent(selected);
  }, [selected]);

  return Object.keys(groupedEvents).map((name, i) =>
    renderSection(
      groupedEvents[name],
      name,
      classes,
      i,
      selectedEvent,
      onEventChange,
      setSelectedEvent
    )
  );
};

export default Events;
