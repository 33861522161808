import _ from "lodash";
import React from "react";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";
import { Box } from "@material-ui/core";

export default function mapToViewModel_expectedLossTranches(
  data,
  deal,
  options
) {
  return {
    expectedLossTranches: {
      label: "Expected Loss Tranches",
      caption: null,
      tableType: constants.MULTIFUNCTION_TABLE,
      options: { pagination: false },
      optionsItems: {
        // pagination: {
        //   page: 0,
        //   rowsPerPage: 10,
        //   tableName: "expectedLossTranches",
        //   handleChangePage: options.pagination.handleChangePage,
        //   handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
        // },
      },
      span: [12, 12, 12],
      columns: [
        {
          label: "Tranche",
          caption: null,
          field: "tranche",
          filter: true,
          align: constants.LEFT,
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingLeft: indent },
              constants.TEXT_CELL
            ),
        },
        {
          label: "Expected Loss Rate",
          caption: null,
          field: "lossRatio",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value || value === 0 ? value.toFixed(3) : constants.___}
            </Box>
          ),
        },
        {
          label: "Standard Error",
          caption: null,
          field: "stdErr",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value || value === 0 ? value.toFixed(3) : constants.___}
            </Box>
          ),
        },
        {
          label: "Random Samples",
          caption: null,
          field: "numSamples",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Obligor",
          caption: null,
          field: "obligor",
          filter: true,
          align: constants.LEFT,
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingLeft: indent },
              constants.TEXT_CELL
            ),
        },
        {
          label: "Spread",
          caption: null,
          field: "creditSpread",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value || value === 0 ? value.toFixed(3) : constants.___}
            </Box>
          ),
        },
        {
          label: "Collateral Rating",
          caption: null,
          field: "collateralRating",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value ? value.toFixed(2) : constants.___}
            </Box>
          ),
        },
        {
          label: "Strategic Value",
          caption: null,
          field: "strategicValue",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value ? value.toFixed(2) : constants.___}
            </Box>
          ),
        },
        {
          label: "Asset Vol",
          caption: null,
          field: "assetValueVol",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value || value === 0 ? value.toFixed(3) : constants.___}
            </Box>
          ),
        },
        {
          label: "Is Full Recourse",
          caption: null,
          field: "isFullRecourse",
          filter: true,
          align: constants.LEFT,
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingLeft: indent }}>{`${value}`}</Box>
          ),
        },
        {
          label: "Unsecured Recovery",
          caption: null,
          field: "unsecuredRevoveryRate",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value || value === 0 ? value.toFixed(3) : constants.___}
            </Box>
          ),
        },

        // {
        //   label: "LTV",
        //   caption: "%",
        //   field: "ltvPct",
        //   filter: false,
        //   align: "right",
        //   indent: 16,
        //   content: (value, indent) => (
        //     <Box style={{ paddingRight: indent }}>
        //       {_.round(value * 100, 3).toFixed(2)}
        //     </Box>
        //   ),
        // },
      ],
      data: data.tranches.map((expLoss, id) => ({
        tranche: expLoss.name,
        lossRatio:
          (expLoss.trancheLoss.lossRatio &&
            expLoss.trancheLoss.lossRatio !== "NaN") ||
          expLoss.trancheLoss.lossRatio === 0
            ? expLoss.trancheLoss.lossRatio
            : null,
        stdErr:
          expLoss.trancheLoss.stdErr && expLoss.trancheLoss.stdErr !== "NaN"
            ? expLoss.trancheLoss.stdErr
            : null,
        numSamples: expLoss.trancheLoss.numSamples,
        obligor: expLoss.counterparty.name,
        creditSpread: expLoss.counterparty.creditSpread,
        assetValueVol: expLoss.assetValueVol,
        collateralRating: expLoss.collateralRating,
        strategicValue: expLoss.strategicValue,
        isFullRecourse: expLoss.isFullRecourse,
        unsecuredRevoveryRate: expLoss.counterparty.unsecuredRevoveryRate,
      })),
    },
  };
}
