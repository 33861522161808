import _ from "lodash";
import React from "react";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import VfSelect from "../../../Common/UtilComponents/VfSelect";

export default function mapToViewModel_apuDetails(
  apuData,
  handleSelectChange,
  props,
  selectedModel,
) {
  const items = apuData.map( e => {
    return {
      label: e.model,
      value: e.model,
    }
  })

  let selectedAPUData = _.find(apuData, { 'model': selectedModel });

  return {
    label: "APU Details",
    caption: null,
    props,
    showOptions: true,
    tableType: constants.DATA_TABLE,
    columns: [
      {
        label: ["Model"],
        field: "model",
        captions: null,
        span: [4, 4],
        content: (_) => {
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedModel? selectedModel : ""}
              type={"engineSubseries"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={200}
            />
          );
        },
      },
      {
        label: ["Manufacturer"],
        field: "manufacturer",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.TEXT_CELL),
      },
      {
        label: ["APU Run-Out Value"],
        field: "apuRunoutValue",
        editable: true,
        required: false,
        type: constants.NUMBER,
        inputFieldType: constants.TEXT_FIELD,
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, null, 2),
      },
    ],
    data: {
      Id: selectedAPUData.id,
      manufacturer: selectedAPUData.manufacturer,
      apuRunoutValue: selectedAPUData.runoutValue
    },
  };
}
