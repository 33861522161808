import _ from "lodash";
import React, { useState, useEffect } from "react";

import apiRoutes from "../../../../utils/ApiRoutes";
import { apiRequest } from "../../../../utils/ApiRequest";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import AssetInput from "../AssetInput";
import LoadingPanel from "../../../Common/LoadingPanel";
import { useMsal } from "@azure/msal-react";
import moment from "moment";

const mapToViewModel = (data, engineNo) => {
  return {
    columns: [
      {
        label: ["Engine Model"],
        field: "engineModel",
        editable: true,
        required: true,
        items: data.map((o) => {
          o.position = engineNo;
          return {
            label: o.engineSubSeries,
            value: o,
          };
        }),
        type: constants.AUTO_COMPLETE,
        inputFieldType: constants.AUTO_COMPLETE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Manufacture Date"],
        field: "manufactureDate",
        span: [6, 4],
        editable: true,
        initialEditValue: moment(),
        value: moment(),
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.manufactureDate, null, constants.DATE_CELL),
      },
      {
        label: ["Serial Number"],
        field: "serialNumber",
        span: [6, 4],
        editable: true,
        required: true,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
    ],
  };
};
const mapToViewModel_EngineEvents = (data) => {
  let columns = [];
  let selected = [];
  columns.push({
    label: ["Engine Supplemental Events"],
    field: `EngineSupplementalEvents`,
    editable: true,
    required: false,
    items: data.map((o) => ({
      label: o.eventName,
      value: JSON.stringify(o),
    })),
    type: constants.CHECK_BOX,
    inputFieldType: constants.CHECK_BOX,
    validate: (inputType, value, required) =>
      utils.validate(inputType, value, required),
  });
  return {
    columns,
    data: selected,
  };
};

const groupFanBladesAndFillersIfExist = (groupedLLPsByModule) => {
  for (let partName of ["Fan Blade", "Annulus Fillers"]) {
    let moduleName = null;
    const parts = _.flatten(
      Object.keys(groupedLLPsByModule).map((module) =>
        groupedLLPsByModule[module].filter((o) => {
          if (o.partName.includes(partName)) {
            moduleName = module;
            return true;
          }
          return false;
        })
      )
    );

    if (parts.length) {
      const ids = parts.map((o) => o.id);
      const partNames = parts.map((o) => o.partName);
      groupedLLPsByModule[moduleName] = groupedLLPsByModule[moduleName].filter(
        (o) => !o.partName.includes(partName)
      );
      groupedLLPsByModule[moduleName].push({
        ids,
        partNames,
        buildStandard: null,
        isCore: parts[0].isCore,
        isDefault: parts[0].isDefault,
        module: moduleName,
        partName: `${partName} (${parts.length})`,
        partNumber: parts[0].partNumber,
        price: null,
        vfLLPStackSeries: parts[0].vfLLPStackSeries,
      });
    }
  }
};

const mapToViewModel_EngineLLP = (data) => {
  const groupedLLPsByModule = _.groupBy(data, "module");
  groupFanBladesAndFillersIfExist(groupedLLPsByModule);
  let columns = [];
  let selected = [];
  Object.keys(groupedLLPsByModule).map((module) => {
    let req =  module === "Static" ? false : true;
    columns.push({
      label: [module],
      field: `llps_${module}`,
      editable: true,
      required: req,
      items: groupedLLPsByModule[module].map((o) => ({
        label: o.partName,
        value: JSON.stringify(o),
      })),
      type: constants.CHECK_BOX,
      inputFieldType: constants.CHECK_BOX,
      validate: (inputType, value, req) =>
        utils.validate(inputType, value, req),
    });
    selected.push({
      [`llps_${module}`]: groupedLLPsByModule[module].map((o) => {
        if (o.isDefault && req) return JSON.stringify(o);
        else return null;
      }),
    });
    return null;
  });

  return {
    columns,
    data: _.compact(selected),
  };
};

const Engine = ({ ACId, props, engineNo, totalEngines, selectedType }) => {
  const [data, setData] = useState(null);
  const msalContext = useMsal();

  props.funcs.handleSelectChange = async (value) => {
    const { genericLLPStackId } = value;
    const result = await apiRequest(
      {
        url: apiRoutes.GenericEngineLLP_GetGenericEngineLLPByStackSeries({
          genericLLPStackId,
        }),
        method: "GET",
      },
      msalContext
    );
    const { id } = value;
    const eventresult = await apiRequest(
      {
        url: apiRoutes.GenericEngine_GetGenericEngineEvents({
          id,
        }),
        method: "GET",
      },
      msalContext
    );

    let columns = [{ ...data.columns[0] }, { ...data.columns[1] }, {...data.columns[2]}];
    const llps = mapToViewModel_EngineLLP(result.data);
    const ees = mapToViewModel_EngineEvents(eventresult.data);
    const newData = {
      columns: [...columns, ...ees.columns, ...llps.columns],
      data: llps.data,
    };

    setData(newData);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await apiRequest(
        {
          url:
            selectedType === constants.AIRCRAFT
              ? apiRoutes.GenericEngine_GetACEngines({ ACId })
              : apiRoutes.GenericEngine_GetList,
          method: "GET",
        },
        msalContext
      );
      data.manufactureDate = moment();
      setData(mapToViewModel(data, engineNo));
    };
    fetchData();
    return () => {
      setData(null);
    };
  }, [ACId, selectedType, engineNo]);

  return data ? (
    <AssetInput
      columns={data.columns}
      selected={data.data}
      subTitle={
        selectedType === constants.AIRCRAFT
          ? `Engines - ${engineNo} of ${totalEngines}`
          : "Engine"
      }
      props={props}
    />
  ) : (
    <LoadingPanel />
  );
};

export default Engine;
