import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DataTable from "../../../../../Common/Tables/DataTable/DataTable";
import MultiFunctionTable from "../../../../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import MultiFieldsFadeInEditingPanel from "../../../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import * as constants from "../../../../../../constants";
import * as utils from "../../../../../../utils/utils";
import * as mapToViewModels from "../../../../mapToViewModels/index";
import VfSnackerBar from "../../../../../Common/UtilComponents/VfSnackerBar";
import XLSX from "xlsx";
import apiRoutes from "../../../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../../../utils/ApiRequest";
import { MsalContext } from "@azure/msal-react";

const styles = (theme) => ({
  button: {
    height: 40,
    width: 160,
    margin: 10,
    padding: 8,
    textAlign: "center",
  },
  deleteAssetButton: {
    backgroundColor: "#dcdcdc",
    "&:hover": { opacity: 0.8, backgroundColor: "#dcdcdc" },
  },
  moreDetailButton: {
    backgroundColor: "#023059",
    color: "#FFFFFF",
    "&:hover": { opacity: 0.8, backgroundColor: "#023059" },
  },
  disabledButtonFix: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
});

class Valuation extends Component {
  static contextType = MsalContext;
  report = null;
  appraisals = {};
  groupedComponentStatus = {};
  partOutValues = {};
  dtDispDates = null;
  runDate = null;
  compStatusDate = null;
  endOfLastMonth = moment
    .utc(new Date())
    .subtract(2, "months")
    .endOf("month")
    .format(constants.DATE_FORMAT);
  constructor(props) {
    super(props);

    this.state = {
      selectedAppraisalInfo: {
        appraiser: constants.ASCEND,
        valueType: constants.MARKET,
        partOutValueType: constants.MARKET,
        LSdiscountRate: 0.06,
        DispdiscountRate: 0.08,
        downtimeBeforeDisp: 0,
        dispValueMethod: "Part-Out Value",
        dispValueHairCut: 0.0,
        dispValueOverride: "optional",
        dispositionAssumption: "Contractual",
      },
      valuationParamsChange: false,
      assetValuation: null,
      assetCompStatus: null,
      dispositionValue: null,
      encumberedValues: null,
      compStatusValues: null,
      openStatusDate: false,
      compStatusDateChange: {},
      error: false,
      snackOpen: false,
      severity: "info",
      message: "",
    };
  }

  handleSelectChange = (type, value) => {
    const selectedAppraisalInfo = {
      ...this.state.selectedAppraisalInfo,
    };
    const report = this.report;

    switch (type) {
      case constants.APPRAISER:
      case constants.VALUE_TYPE:
      case constants.PART_OUT_VALUE_TYPE:
      case "Lease CF Discount Rate":
      case "Disp Discount Rate":
      case "DOWNTIME BEFORE DISP":
      case "DISP VALUE METHOD":
      case "DISP VALUE HAIRCUT":
      case "DISP VALUE OVERRIDE":
      case "Disposition Assumption":
      case "Reset":
        const assetValuation = [...this.state.assetValuation];
        let dispositionValue = this.state.dispositionValue;
        let encumberedValues = this.state.encumberedValues;
        assetValuation[0] = { ...assetValuation[0] };
        const innerData = { ...assetValuation[0].data };

        innerData.valuationAssumptions =
          mapToViewModels.mapToValuationAssumptionsViewModel(
            selectedAppraisalInfo,
            this.handleSelectChange,
            this.dtDispDates,
            this.appraisals
          );
        if (type === constants.APPRAISER) {
          selectedAppraisalInfo.appraiser = value;
        } else if (type === constants.VALUE_TYPE) {
          selectedAppraisalInfo.valueType = value;
        } else if (type === constants.PART_OUT_VALUE_TYPE) {
          selectedAppraisalInfo.partOutValueType = value;
        } else if (type === "DOWNTIME BEFORE DISP") {
          selectedAppraisalInfo.downtimeBeforeDisp = value;
        } else if (type === "Lease CF Discount Rate") {
          selectedAppraisalInfo.LSdiscountRate = value;
        } else if (type === "Disp Discount Rate") {
          selectedAppraisalInfo.DispdiscountRate = value;
        } else if (type === "DISP VALUE METHOD") {
          if (value === "override") {
            selectedAppraisalInfo.dispValueMethod = value;
          } else {
            selectedAppraisalInfo.dispValueMethod = value;
            selectedAppraisalInfo.dispValueOverride = "optional";
          }
        } else if (type === "DISP VALUE HAIRCUT") {
          selectedAppraisalInfo.dispValueHairCut = value;
        } else if (type === "DISP VALUE OVERRIDE") {
          if (value !== "optional") {
            selectedAppraisalInfo.dispValueOverride = value;
            selectedAppraisalInfo.dispValueMethod = "override";
          }
        } else if (type === "Disposition Assumption") {
          selectedAppraisalInfo.dispositionAssumption = value;
        } else if (type === "Reset") {
          if (
            report.valuationParameters &&
            !_.isEmpty(report.valuationParameters)
          ) {
            selectedAppraisalInfo.appraiser =
              report.valuationParameters.appraiser;
            selectedAppraisalInfo.valueType =
              report.valuationParameters.valueType;
            selectedAppraisalInfo.partOutValueType =
              report.valuationParameters.partOutValueType;
            selectedAppraisalInfo.LSdiscountRate =
              report.valuationParameters.cashflowDispositionRate;
            selectedAppraisalInfo.DispdiscountRate =
              report.valuationParameters.dispositionDiscountRate;
            selectedAppraisalInfo.downtimeBeforeDisp = parseInt(
              report.valuationParameters.dispositionDowntime.split(" ")[0]
            );
            selectedAppraisalInfo.dispValueMethod =
              report.valuationParameters.dispositionValueMethod;
            selectedAppraisalInfo.dispValueHairCut =
              report.valuationParameters.dispositionHaircut;
            selectedAppraisalInfo.dispValueOverride = report.valuationParameters
              .dispositionValueOverride
              ? report.valuationParameters.dispositionValueOverride
              : "optional";

            selectedAppraisalInfo.dispositionAssumption = report
              .valuationParameters.dispositionAssumption
              ? report.valuationParameters.dispositionAssumption
              : "Contractual";
          } else {
            selectedAppraisalInfo.appraiser = constants.ASCEND;
            selectedAppraisalInfo.valueType = constants.MARKET;
            selectedAppraisalInfo.partOutValueType = constants.MARKET;
            selectedAppraisalInfo.LSdiscountRate = 0.06;
            selectedAppraisalInfo.DispdiscountRate = 0.08;
            selectedAppraisalInfo.downtimeBeforeDisp = 3;
            selectedAppraisalInfo.dispValueMethod = "Part-Out Value";
            selectedAppraisalInfo.dispValueHairCut = 0.0;
            selectedAppraisalInfo.dispValueOverride = "optional";
            selectedAppraisalInfo.dispositionAssumption = "Contractual";
          }
        }

        innerData.appraisal = mapToViewModels.mapToApprasialViewModel(
          report,
          this.appraisals,
          selectedAppraisalInfo,
          this.groupedComponentStatus[this.runDate],
          this.runDate,
          this.partOutValues[this.runDate],
          this.handleSelectChange
        );

        innerData.partOutValues = mapToViewModels.mapToPartOutValuesViewModel(
          this.partOutValues,
          this.runDate,
          selectedAppraisalInfo
        );

        innerData.valuationAssumptions =
          mapToViewModels.mapToValuationAssumptionsViewModel(
            selectedAppraisalInfo,
            this.handleSelectChange,
            this.dtDispDates,
            this.appraisals
          );

        if (report.appraisal) {
          dispositionValue = mapToViewModels.mapToViewModel_DispositionValues(
            report,
            innerData.partOutValues,
            this.endOfLastMonth,
            selectedAppraisalInfo
          );

          encumberedValues = mapToViewModels.mapToViewModel_EncumberedValues(
            report,
            dispositionValue,
            this.endOfLastMonth,
            selectedAppraisalInfo
          );
        }

        assetValuation[0].data = innerData;

        this.setState({
          selectedAppraisalInfo,
          assetValuation,
          dispositionValue,
          encumberedValues,
          valuationParamsChange: type === "Reset" ? false : true,
        });
        break;
      default:
        break;
    }
  };

  getEscalateValue(startDate, toDate, startValue, escRate) {
    let a = moment(startDate);
    let b = moment(toDate);
    // escRate is annual rate
    return startValue * Math.pow(1 + escRate, a.diff(b, "days") / 365.0);
  }

  getLLPComponentStatusByDate = (statusDate) => {
    // const { report } = this.state;
    const report = this.report;
    let compStatus = report.componentStatus;
    let llpRefIndex = {};

    for (let i = 0; i < compStatus.length; i++) {
      if (
        moment
          .utc(compStatus[i].asOfDate)
          .isAfter(moment.utc(statusDate), "month")
      ) {
        break;
      }
      if (
        compStatus[i].llpStatus !== null &&
        !_.isEmpty(compStatus[i].llpStatus)
      ) {
        if (compStatus[i].serialNumber in llpRefIndex) {
          if (llpRefIndex[compStatus[i].serialNumber] < i)
            llpRefIndex[compStatus[i].serialNumber] = i;
        } else llpRefIndex[compStatus[i].serialNumber] = i;
      }

      if (
        moment.utc(compStatus[i].asOfDate).format(constants.DATE_FORMAT) ===
        moment.utc(statusDate).format(constants.DATE_FORMAT)
      ) {
        if (
          compStatus[i].componentType == "EngineLLP" &&
          _.isEmpty(compStatus[i].llpStatus)
        ) {
          let refStatus = compStatus[llpRefIndex[compStatus[i].serialNumber]];
          let cyclesDelta = refStatus.toEvent_FC - compStatus[i].toEvent_FC;
          let priceMultiple = this.getEscalateValue(
            refStatus.asOfDate,
            statusDate,
            1,
            refStatus.llpStatus[0].expectedPriceEscalation
          );
          refStatus.llpStatus.forEach((llp) => {
            let newllp = _.cloneDeep(llp);
            newllp.cyclesLeft = llp.cyclesLeft - cyclesDelta;
            newllp.listPrice = llp.listPrice * priceMultiple;
            compStatus[i].llpStatus.push(newllp);
          });
        }
      }
    }
    return compStatus;
  };

  setPartOutValuesByDate = (partOutValues) => {
    Object.keys(partOutValues).map((key) => {
      this.partOutValues[moment.utc(key).format(constants.DATE_FORMAT)] =
        partOutValues[key];
      return null;
    });
  };

  setGroupedComponentStatusByDate = (compStatus) => {
    const asOfDate = "asOfDate";

    compStatus.map((status) => {
      status[asOfDate] = moment
        .utc(status[asOfDate])
        .format(constants.DATE_FORMAT);
      return null;
    });

    const groupedComponentStatus = _.groupBy(compStatus, asOfDate);
    const runDates = Object.keys(groupedComponentStatus).sort((a, b) =>
      moment.utc(Date.parse(a)).diff(moment.utc(Date.parse(b)), "days")
    );

    if (runDates.includes(this.endOfLastMonth))
      this.runDate = this.endOfLastMonth;
    else {
      this.runDate = runDates[0];
    }

    this.compStatusDate = this.runDate;
    let nextDatetoCheck = null;
    try {
      let checkCompStatus = groupedComponentStatus[this.runDate];
      let checkNum = 1;
      while (
        checkCompStatus[0].lifePct === null &&
        checkCompStatus[0].valueAboveHalfLife === null &&
        checkNum < 11
      ) {
        nextDatetoCheck = moment
          .utc(this.runDate)
          .add(checkNum, "months")
          .endOf("month")
          .format(constants.DATE_FORMAT);
        checkCompStatus = this.groupedComponentStatus[nextDatetoCheck];
        checkNum++;
      }
    } catch (e) {
      console.log(e);
    }
    if (nextDatetoCheck) this.compStatusDate = nextDatetoCheck;

    this.groupedComponentStatus = groupedComponentStatus;
  };

  setAppraisals = (appraisals) => {
    // not final
    const appraisalsObj = {
      ASCEND: appraisals[0],
    };
    this.appraisals = appraisalsObj;
  };

  setdtDispDates = (report) => {
    let monthDifference = null;
    const initialSimDate = Object.keys(report.cashFlows)[0];
    if (
      !_.isEmpty(report.leases) &&
      moment(initialSimDate).isBefore(report.leases[0].endDate)
    ) {
      const leaseEndDate = moment.utc(report.leases[0].endDate);
      const maxPartoutDate = moment.utc(
        _.last(Object.keys(report.partOutValues))
      );
      monthDifference = maxPartoutDate.diff(leaseEndDate, "months");
    } else {
      monthDifference = Object.keys(report.partOutValues).length;
    }

    const dtrange = Array.from(
      Array(monthDifference >= 12 ? 13 : monthDifference + 1).keys()
    );

    this.dtDispDates = dtrange.map((x) => ({ label: `${x} months`, value: x }));
  };

  formatData = (data, componentType, misc) => {
    const { selectedAppraisalInfo } = this.state;
    let simDate = moment.utc(Object.keys(data[0].cashFlows)[0]).format(constants.DATE_FORMAT);

    return data.map((item) => ({
      header: null,
      data:
        componentType === constants.ASSET_STATUS
          ? mapToViewModels.mapToComponentStatusViewModel(
              misc,
              this.groupedComponentStatus[misc],
              {
                openChangeStatusDate: this.openChangeStatusDate,
                handleCompStatusExport: this.handleCompStatusExport,
              }
            )
          : mapToViewModels.assetValuationViewModel(
              this.report,
              this.appraisals,
              misc ? misc : selectedAppraisalInfo,
              this.groupedComponentStatus[simDate],
              this.partOutValues,
              this.handleSelectChange,
              simDate,
              this.dtDispDates
            ),
      componentType,
    }));
  };

  handleSnackerBarOpen = (open) => {
    this.setState({ snackOpen: open });
  };

  handleChangeStatusDate = (section, sectionIdx, tableName, newData) => {
    // console.log(section, sectionIdx, tableName, newData)
    const report = this.report;
    const checkDate = moment
      .utc(`${newData.month}-01-${newData.year}`)
      .endOf("month")
      .format(constants.DATE_FORMAT);
    let componentStatus = this.getLLPComponentStatusByDate(checkDate);
    this.setGroupedComponentStatusByDate(componentStatus);
    const assetCompStatus = this.formatData(
      [report],
      constants.ASSET_STATUS,
      checkDate
    );
    this.setState({
      assetCompStatus,
    });
    this.compStatusDate = checkDate;
  };

  handlevalidateStatusDateData = (
    newData,
    columns,
    section,
    tableName,
    actionType
  ) => {
    let isValid = false;
    const { startDate, endDate } = this.state.compStatusDateChange;
    const checkDate = moment
      .utc(`${newData.month}-01-${newData.year}`)
      .endOf("month");
    if (moment.utc(checkDate).isBetween(startDate, endDate)) isValid = true;
    else {
      this.setState({
        snackOpen: true,
        severity: "warning",
        message:
          "The date provided is out of range, please try a different date.",
      });
    }
    // console.log(newData, checkDate, isValid)
    return isValid;
  };

  handleCompStatusExport = () => {
    const report = this.report;
    const { assetInfo } = report;
    const filename = `${assetInfo.assetModel}-${assetInfo.serialNumber}-Comp Status.xlsx`;
    const compStatus = this.state.assetCompStatus[0].data;
    const currency = "$#,##0.00";

    let data = [];

    const wb = XLSX.utils.book_new();

    compStatus.data.forEach((comp) => {
      let rowData = {
        Name: comp.componentName.toString().replace(",", " "),
        "Mx Event Previous": comp.lastMajorMxEventDate
          ? moment(comp.lastMajorMxEventDate).format(constants.FULL_YEAR_DATE)
          : null,
        "Mx Event Next": comp.nextMajorMxEventDate
          ? moment(comp.nextMajorMxEventDate).format(constants.FULL_YEAR_DATE)
          : null,
        "Mx Cost Next Event": comp.nextMajorMxEventCost,
        "Life Left %": comp.lifePctBar ? comp.lifePctBar / 100 : null,
        "Value to H.L.": comp.valueAboveHalfLife,
      };

      comp.sinceLastEvent.forEach((x) => {
        rowData["Since Last Event " + x.label] = x.value
          ? x.value.toFixed(0)
          : null;
      });

      comp.toNextEvent.forEach((x) => {
        rowData["To Next Event " + x.label] = x.value
          ? x.value.toFixed(0)
          : null;
      });

      if (comp.componentName.includes("EngineLLP")) {
        let llpData = [];
        comp.hidden.data.forEach((llp) => {
          llpData.push({
            Module: llp.module,
            "Part Name": llp.partName,
            "Cycle Limit": llp.cycleLimit,
            "Cycles Left": llp.cyclesLeft ? llp.cyclesLeft.toFixed(0) : null,
            "List Price": llp.listPrice,
            "Escalation Rate": llp.expectedPriceEscalation,
            "Is Core": llp.isCore,
          });
        });

        let llpws = XLSX.utils.json_to_sheet(llpData);

        llpws["!cols"] = utils.fitToColumn(llpData.map(Object.values));
        utils.formatColumn(llpws, 4, currency);

        XLSX.utils.book_append_sheet(
          wb,
          llpws,
          `${comp.componentName.toString()}`
        );
      }

      data.push(rowData);
    });

    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(data);

    // format currency columns
    for (let col of [3, 5]) {
      utils.formatColumn(ws, col, currency);
    }
    utils.formatColumn(ws, 4, "0%");

    ws["!cols"] = utils.fitToColumn(data.map(Object.values));

    XLSX.utils.book_append_sheet(wb, ws, "Comp Status");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, filename);
  };

  handleSaveValuationParams = async () => {
    const { selectedAppraisalInfo } = this.state;

    let valuationParameters = {
      scenarioName: this.report.scenario.name,
      AssetType: this.report.assetInfo.assetType,
      AssetId: this.report.assetInfo.id,
      appraiser: selectedAppraisalInfo.appraiser,
      valueType: selectedAppraisalInfo.valueType,
      partOutValueType: selectedAppraisalInfo.partOutValueType,
      dispositionDowntime: selectedAppraisalInfo.downtimeBeforeDisp + " months",
      dispositionValueMethod: selectedAppraisalInfo.dispValueMethod,
      dispositionHaircut: selectedAppraisalInfo.dispValueHairCut,
      cashflowDispositionRate: selectedAppraisalInfo.LSdiscountRate,
      dispositionDiscountRate: selectedAppraisalInfo.DispdiscountRate,
      dispositionValueOverride:
        selectedAppraisalInfo.dispValueOverride === "optional"
          ? null
          : selectedAppraisalInfo.dispValueOverride,
    };

    let apiEndPoint = apiRoutes.VectorDeal_PostValuationParams();

    let resp = await apiRequest(
      {
        url: apiEndPoint,
        method: "POST",
        data: valuationParameters,
      },
      this.context
    );

    if (resp.status === response.OK) {
      this.report.valuationParameters = { ...resp.data };

      this.setState({
        valuationParamsChange: false,
        snackOpen: true,
        severity: "success",
        message: "The valuation parameters have been save to the scenario.",
      });
    }
  };

  openChangeStatusDate = () => {
    const report = this.report;
    const compStatus = report.componentStatus;

    const startDate = compStatus[0].asOfDate;
    const endDate = compStatus[compStatus.length - 1].asOfDate;
    let years = [];
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    months = months.map((month) => {
      return {
        label: month,
        value: month,
      };
    });

    for (
      let i = parseInt(moment(startDate).format("YYYY"));
      i < parseInt(moment(endDate).format("YYYY")) + 1;
      i++
    ) {
      years.push({
        label: String(i),
        value: String(i),
      });
    }

    let handleValuesUpdate = this.handleChangeStatusDate;
    let validateData = this.handlevalidateStatusDateData;
    let props = {
      section: "Status Date",
      sectionIdx: 1,
      funcs: { handleValuesUpdate, validateData },
    };

    const columns = [
      {
        label: ["Month"],
        field: "month",
        editable: true,
        required: true,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: months,
      },
      {
        label: ["Year"],
        field: "year",
        editable: true,
        required: true,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: years,
      },
    ];

    const columnData = {
      month: moment(this.compStatusDate).format("MMM"),
      year: moment(this.compStatusDate).format("YYYY"),
    };

    this.setState({
      openStatusDate: true,
      compStatusDateChange: {
        columns,
        columnData,
        props,
        startDate,
        endDate,
      },
    });
  };

  componentDidMount = () => {
    const { report } = this.props;
    this.report = report;

    if (report) {
      if (!this.groupedComponentStatus.length) {
        let componentStatus = this.getLLPComponentStatusByDate(
          this.endOfLastMonth
        );
        this.setGroupedComponentStatusByDate(componentStatus);
      }

      if (this.endOfLastMonth !== this.compStatusDate) {
        let componentStatus = this.getLLPComponentStatusByDate(
          this.compStatusDate
        );
        this.setGroupedComponentStatusByDate(componentStatus);
      }

      if (!this.partOutValues.length) {
        this.setPartOutValuesByDate(report.partOutValues);
        this.setdtDispDates(report);
      }

      if (!this.appraisals.length) {
        this.setAppraisals([report.appraisal]);
      }

      const assetCompStatus = this.formatData(
        [report],
        constants.ASSET_STATUS,
        this.compStatusDate
      );
      let assetValuation,
        dispositionValue,
        encumberedValues = null;

      let valuationParameters = null;
      if (
        report.valuationParameters &&
        !_.isEmpty(report.valuationParameters)
      ) {
        valuationParameters = {
          appraiser: report.valuationParameters.appraiser,
          valueType: report.valuationParameters.valueType,
          partOutValueType: report.valuationParameters.partOutValueType,
          LSdiscountRate: report.valuationParameters.cashflowDispositionRate,
          DispdiscountRate: report.valuationParameters.dispositionDiscountRate,
          downtimeBeforeDisp: parseInt(
            report.valuationParameters.dispositionDowntime.split(" ")[0]
          ),
          dispValueMethod: report.valuationParameters.dispositionValueMethod,
          dispValueHairCut: report.valuationParameters.dispositionHaircut,
          dispValueOverride: report.valuationParameters.dispositionValueOverride
            ? report.valuationParameters.dispositionValueOverride
            : "optional",
        };
      }

      if (report.appraisal) {
        let firstAppraisalDate = report.appraisal.futureValuations[0].date;
        if (
          moment.utc(this.endOfLastMonth).isBefore(firstAppraisalDate, "day")
        ) {
          this.runDate = moment
            .utc(firstAppraisalDate)
            .endOf("month")
            .format(constants.DATE_FORMAT);
        }

        assetValuation = this.formatData(
          [report],
          constants.ASSET_VALUATION,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );

        dispositionValue = mapToViewModels.mapToViewModel_DispositionValues(
          report,
          assetValuation[0].data.partOutValues,
          this.endOfLastMonth,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );

        encumberedValues = mapToViewModels.mapToViewModel_EncumberedValues(
          report,
          dispositionValue,
          this.endOfLastMonth,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );
      } else {
        assetValuation = this.formatData(
          [report],
          constants.ASSET_VALUATION,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );
        this.setState({
          error: true,
        });
      }

      this.setState({
        assetValuation,
        assetCompStatus,
        dispositionValue,
        encumberedValues,
        selectedAppraisalInfo: valuationParameters
          ? valuationParameters
          : { ...this.state.selectedAppraisalInfo },
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { report } = nextProps;
    this.report = nextProps.report;

    if (report) {
      if (!this.groupedComponentStatus.length) {
        let componentStatus = this.getLLPComponentStatusByDate(
          this.endOfLastMonth
        );
        this.setGroupedComponentStatusByDate(componentStatus);
      }

      if (this.endOfLastMonth !== this.compStatusDate) {
        let componentStatus = this.getLLPComponentStatusByDate(
          this.compStatusDate
        );
        this.setGroupedComponentStatusByDate(componentStatus);
      }

      if (!this.partOutValues.length) {
        this.setPartOutValuesByDate(report.partOutValues);
        this.setdtDispDates(report);
      }

      if (!this.appraisals.length) {
        this.setAppraisals([report.appraisal]);
      }

      const assetCompStatus = this.formatData(
        [report],
        constants.ASSET_STATUS,
        this.compStatusDate
      );
      let assetValuation,
        dispositionValue,
        encumberedValues = null;

      let valuationParameters = null;
      if (
        report.valuationParameters &&
        !_.isEmpty(report.valuationParameters)
      ) {
        valuationParameters = {
          appraiser: report.valuationParameters.appraiser,
          valueType: report.valuationParameters.valueType,
          partOutValueType: report.valuationParameters.partOutValueType,
          LSdiscountRate: report.valuationParameters.cashflowDispositionRate,
          DispdiscountRate: report.valuationParameters.dispositionDiscountRate,
          downtimeBeforeDisp: parseInt(
            report.valuationParameters.dispositionDowntime.split(" ")[0]
          ),
          dispValueMethod: report.valuationParameters.dispositionValueMethod,
          dispValueHairCut: report.valuationParameters.dispositionHaircut,
          dispValueOverride: report.valuationParameters.dispositionValueOverride
            ? report.valuationParameters.dispositionValueOverride
            : "optional",
        };
      }

      if (report.appraisal) {
        let firstAppraisalDate = report.appraisal.futureValuations[0].date;
        if (
          moment.utc(this.endOfLastMonth).isBefore(firstAppraisalDate, "day")
        ) {
          this.runDate = moment
            .utc(firstAppraisalDate)
            .endOf("month")
            .format(constants.DATE_FORMAT);
        }

        assetValuation = this.formatData(
          [report],
          constants.ASSET_VALUATION,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );

        dispositionValue = mapToViewModels.mapToViewModel_DispositionValues(
          report,
          assetValuation[0].data.partOutValues,
          this.endOfLastMonth,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );

        encumberedValues = mapToViewModels.mapToViewModel_EncumberedValues(
          report,
          dispositionValue,
          this.endOfLastMonth,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );
      } else {
        assetValuation = this.formatData(
          [report],
          constants.ASSET_VALUATION,
          valuationParameters
            ? valuationParameters
            : this.state.selectedAppraisalInfo
        );
        this.setState({
          error: true,
        });
      }

      this.setState({
        assetValuation,
        assetCompStatus,
        dispositionValue,
        encumberedValues,
        selectedAppraisalInfo: valuationParameters
          ? valuationParameters
          : { ...this.state.selectedAppraisalInfo },
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      assetValuation,
      assetCompStatus,
      dispositionValue,
      encumberedValues,
      error,
      compStatusDateChange,
      snackOpen,
      severity,
      message,
      valuationParamsChange,
    } = this.state;

    return (
      assetCompStatus && (
        <Box>
          <VfSnackerBar
            snackOpen={snackOpen}
            setsnackOpen={this.handleSnackerBarOpen}
            severity={severity}
            message={message}
          />
          <Grid container spacing={2}>
            {!error ? (
              <>
                <Grid item sm={4} className="valuation_grid1">
                  <Box
                    style={{
                      position: "relative",
                      borderRadius: 12,
                      backgroundColor: "#FFFFFF",
                      padding: 20,
                      height: "100%",
                      boxShadow:
                        "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <DataTable
                      tableData={assetValuation[0].data.valuationAssumptions}
                    />
                    <Box
                      style={{
                        position: "absolute",
                        bottom: 20,
                        marginLeft: -20,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        disabled={!valuationParamsChange}
                        onClick={() => this.handleSaveValuationParams()}
                        className={`${classes.button} ${
                          classes.moreDetailButton
                        } ${
                          !valuationParamsChange
                            ? classes.disabledButtonFix
                            : ""
                        }`}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => this.handleSelectChange("Reset", null)}
                        className={`${classes.button} ${classes.deleteAssetButton}`}
                      >
                        Reset
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={4} className="valuation_grid2">
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <DataTable tableData={assetValuation[0].data.appraisal} />
                    </Grid>
                    <Grid item sm={12}>
                      <DataTable tableData={dispositionValue} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={4} className="valuation_grid3">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      sm={12}
                      style={{
                        height:
                          assetValuation[0].data.partOutValues.columns.length >
                          4
                            ? 340
                            : 290,
                      }}
                    >
                      <DataTable
                        tableData={assetValuation[0].data.partOutValues}
                      />
                    </Grid>

                    <Grid item sm={12}>
                      <MultiFunctionTable
                        tableData={encumberedValues}
                        containerMaxHeight={
                          assetValuation[0].data.partOutValues.columns.length >
                          4
                            ? 285
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={6}>
                  <DataTable
                    tableData={assetValuation[0].data.valuationAssumptions}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} style={{ height: 285 }}>
                      <DataTable
                        tableData={assetValuation[0].data.partOutValues}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item sm={12}>
              <MultiFunctionTable tableData={assetCompStatus[0].data} />
            </Grid>
          </Grid>
          <MultiFieldsFadeInEditingPanel
            open={this.state.openStatusDate}
            columns={compStatusDateChange.columns}
            data={compStatusDateChange.columnData}
            tableName={"Status Date Change"}
            props={compStatusDateChange.props}
            onClose={() => this.setState({ openStatusDate: false })}
          />
        </Box>
      )
    );
  }
}

export default withStyles(styles)(Valuation);
