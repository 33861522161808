import _, { forEach } from "lodash";
import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Switch,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Select,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Slide,
    TextField,
    Typography,
    Tooltip,
    Modal,
    Dialog,
  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    submitText: {
      height: 16,
      width: 80,
      color: "#0D47A1",
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: "1.25px",
      lineHeight: "16px",
    },
    submitTextDisabled: {
      color: "rgba(0,0,0,0.38)",
    },
    submitButton: {
      width: 104,
      height: 36,
      border: "1px solid rgba(0,0,0,0.12)",
      borderRadius: 4,
      backgroundColor: "rgba(98,2,238,0)",
    },
  }));
const ColumnFilter = ({
    columns,
    profiles,
    selectedProfile,
    onProfileSwap,
    onSwitchChange
}) => 
{
    
    const classes = useStyles();
    const [profName, setProfName] = useState("");
    const [openAdd, setOpenAdd] = useState(false);
    const [availableProfiles, setAvailableProfiles] = useState(profiles)
    //const [selectedProfile, setSelectedProfile] = useState("Asset Details");
    const disableList = ["Asset Type","Asset Model","Serial Number","Details"];
    let allcols = selectedProfile.columns;
    let colkeys = Object.keys(allcols);
    colkeys.forEach(e=> {
        if(columns.includes(e)){
            allcols[e] = true;
        }
        else{
            allcols[e] = false;
        }
    })
    const handleSelectChange = (event) => {
        if(event.target.value == "add"){
            setOpenAdd(true);
        }
        else{
            onProfileSwap(event.target.value);
        }
    }
    const handleClose = () =>{
        setOpenAdd(false);
    }
    const handleSubmit = () => {
        availableProfiles.splice(availableProfiles.length-1,0,{label:profName, value:profName})
        setAvailableProfiles(availableProfiles);
        //setSelectedProfile(profName);
        handleClose();
    }

    return ( 
        <Box >
            <Dialog
                open={openAdd}
                onClose={handleClose}
            >
                <DialogTitle>Create Filter Profile</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Profile Name"
                    type="text"
                    value={profName}
                    fullWidth
                    variant="standard"
                    onChange={e => {setProfName(e.target.value)}}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Add</Button>
                </DialogActions>
            </Dialog>
            <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Filter Columns</FormLabel>
            <FormGroup style={{maxHeight:200}} >
                {Object.entries(allcols).map(([k,v]) => {
                    return (
                    <FormControlLabel
                    key={k}
                    control={
                        <Switch disabled={disableList.includes(k)} checked={v} onChange={(event)=>{onSwitchChange(event.target.name)}} name={k} />
                    }
                    label={k}
                    />);
                })}
            </FormGroup>
            </FormControl>
            <FormControl style={{marginLeft:50}} component="fieldset" variant="standard">
            <FormLabel component="legend">Filter Profiles</FormLabel>
            <FormGroup style={{maxHeight:180}} >
                <FormControlLabel style={{marginTop:10, marginBottom:20}}
                control={
                    <Select
                    id="ProfSelect"
                    fontSize={14}
                    value={selectedProfile.name}
                    onChange={handleSelectChange}
                    style={{minWidth:150}}
                    >
                    {availableProfiles.map((o,index)=>{
                        return <MenuItem key={index} value={o.name}>{o.name}</MenuItem>
                    })}
                    {/* <MenuItem
                     key={"Add"}
                     value={"add"}
                    >
                    + Create New 
                    </MenuItem> */}
                    </Select>
                }
                />
                {/* <FormControlLabel
                control={
                    <Button
                    className={classes.submitButton}
                    variant='outlined'
                    type='submit'
                    disabled={true}
                    >
                    
                    <span className={classes.submitText}>
                    {"SAVE"}
                    </span>
                </Button>
                }
                /> */}
            </FormGroup>
            </FormControl>
        </Box>
    );
};
  export default ColumnFilter;