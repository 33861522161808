import _, { constant } from "lodash";
import moment from "moment";

import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

import { renderCell, styles } from "./";

function generateScheduledShopVisitRows(data, props, engineEvent = false) {
  if (data && data.length !== 0) {
    data.sort(function compare(a,b){
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    })
    return _.compact(
      data.map(function (e, idx) {
        return {
          id: idx,
          event: engineEvent
            ? engineEvent
            : props.isScenario ? props.section : props.allShopVisitEventNames[props.section][props.sectionIdx]
                .value,
          startDate: e.startDate,
          daysDown: parseFloat(moment.utc(e.endDate).diff(e.startDate, "days")),
          cost: e.cost,
          costDate: e.costDate,
          escalationRate: e.escalationRate,
          isOverhaul: e.isOverhaul,
          isTriggeredByOtherEvents: e.isTriggeredByOtherEvents,
          paymentProcessDays: e.paymentProcessDays,
        };
      })
    );
  }
  return [];
}
function generateSupplementalScheduledShopVisitRows(
  data,
  props,
  engineEvent = false
) {
  if (data && data.length >= 1) {
    return _.compact(
      data.map(function (e, idx) {
        return {
          id: idx,
          event: engineEvent
            ? engineEvent
            : props.allShopVisitEventNames[props.section][props.sectionIdx]
                .value,
          startDate: e.startDate,
          daysDown: moment.utc(e.endDate).diff(e.startDate, "days"),
          cost: e.cost,
          isOverhaul: e.isOverhaul,
          isTriggeredByOtherEvents: e.isTriggeredByOtherEvents,
          paymentProcessDays: e.paymentProcessDays,
        };
      })
    );
  }
  return [];
}
function generateScenarioShopvisitRows(data,props){
  if (data && data.length >= 1) {
    data.sort(function compare(a,b){
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    })
    return _.compact(
      data.map(function (e, idx) {
        if(e.swapInfo){
          props.shouldRenderDetailPanel = true;
          return {
            id: idx,
            event: props.section,
            startDate: e.startDate,
            daysDown:parseInt(moment.utc(e.endDate).diff(e.startDate, "days")),
            cost: e.cost,
            costDate: e.costDate,
            escalationRate: e.escalationRate,
            isOverhaul: e.isOverhaul,
            isTriggeredByOtherEvents: e.isTriggeredByOtherEvents,
            paymentProcessDays: parseInt(e.paymentProcessDays),
            swap: true,
            swapInfo:{
              cost: e.swapInfo.cost,
              costDate: e.costDate,
              escalationRate: e.escalationRate,
              greenTimeFlightCycles: e.swapInfo.greenTimeFlightCycles,
              greenTimeFlightHours: e.swapInfo.greenTimeFlightHours,
              swapSinceEventFlightHours: e.swapInfo.swapSinceEventFlightHours,
              swapSinceEventFlightCycles: e.swapInfo.swapSinceEventFlightCycles,
              id: idx
            }
          };
        }
        else 
          return {
            id: idx,
            event: props.section,
            startDate: e.startDate,
            daysDown: moment.utc(e.endDate).diff(e.startDate, "days"),
            cost: e.cost,
            costDate: e.costDate,
            escalationRate: e.escalationRate,
            isOverhaul: e.isOverhaul,
            isTriggeredByOtherEvents: e.isTriggeredByOtherEvents,
            paymentProcessDays: e.paymentProcessDays,
          };
      })
    );
  }
  return [];
}
const detailPanelData = (rowData) => {
    if (rowData.swapInfo) {
      let newRowData = {
        cost: rowData.swapInfo.cost,
        costDate: rowData.costDate ? rowData.costDate : rowData.swapInfo.costDate,
        escalationRate: rowData.escalationRate ? rowData.escalationRate : rowData.swapInfo.escalationRate,
        greenTimeFlightCycles: rowData.swapInfo.greenTimeFlightCycles,
        greenTimeFlightHours: rowData.swapInfo.greenTimeFlightHours,
        swapSinceEventFlightHours: rowData.swapInfo.swapSinceEventFlightHours,
        swapSinceEventFlightCycles: rowData.swapInfo.swapSinceEventFlightCycles,
        id: rowData.id,
      };
      return newRowData;
    } else return null;
};
const swapItems = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];


export function mapToViewModel_ScheduledShopVisits(data, props) {
  let shopVisits = null;

  // console.log(data);
  // console.log(props);
  //populate different for scenario
  if(props.isScenario){
    shopVisits = generateScenarioShopvisitRows(data,props);
  }
  else {
    if (data.position) {
      let supplemental = null;
      data.supplementalEvents.forEach((x) => {
        let returned = generateSupplementalScheduledShopVisitRows(
          x.maintenanceEvents,
          _,
          x.eventName + " P" + data.position.slice(-1)
        );
        if (returned.length > 0) {
          if (supplemental === null) supplemental = _.concat(returned);
          else supplemental = supplemental.concat(returned);
        }
      });
      if (supplemental != null)
        shopVisits = _.concat(
          generateScheduledShopVisitRows(
            data.maintenanceEvents,
            _,
            "EnginePR P" + data.position.slice(-1)
          ),
          generateScheduledShopVisitRows(
            data.llpStack.maintenanceEvents,
            _,
            "ELLP Stack P" + data.position.slice(-1)
          ),
          supplemental
        );
      else
        shopVisits = _.concat(
          generateScheduledShopVisitRows(
            data.maintenanceEvents,
            _,
            "EnginePR P" + data.position.slice(-1)
          ),
          generateScheduledShopVisitRows(
            data.llpStack.maintenanceEvents,
            _,
            "ELLP Stack P" + data.position.slice(-1)
          )
        );
    } else {
      shopVisits = generateScheduledShopVisitRows(data, props);
    }
  }
  let columns = [
    {
      title: "Start Date",
      field: "startDate",
      type: constants.DATE,
      inputFieldType: constants.DATE,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        moment.utc(rowData.startDate).format(constants.DATE_FORMAT),
    },
    {
      title: "Days Down",
      field: "daysDown",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(rowData.daysDown, null, constants.NUMBER),
    },
    {
      title: "Cost",
      field: "cost",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(rowData.cost, null, constants.NUMERIC_CELL, "$"),
    },
    {
      title: "Cost Date",
      field: "costDate",
      type: constants.DATE,
      inputFieldType: constants.DATE,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.costDate).format(constants.DATE_FORMAT),
    },
    {
      title: "Esc Rate",
      field: "escalationRate",
      type: constants.PERCENTAGE,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        rowData.escalationRate ? 
        `${_.round(rowData.escalationRate * 100, 3).toFixed(2)}%` : "---",
    },
    {
      title: "Payment Process (Days)",
      field: "paymentProcessDays",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.paymentProcessDays,
          null,
          constants.NUMBER,
          null,
          0,
          true
        ),
    },
    {
      title: "Is Overhaul",
      field: "isOverhaul",
      initialEditValue: true,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: false,
      items: [
        { label: constants.YES, value: true },
        { label: constants.NO, value: false },
      ],
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => (rowData.isOverhaul ? constants.YES : constants.NO),
    },
    {
      title: "Triggered by Other Events",
      field: "isTriggeredByOtherEvents",
      initialEditValue: false,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      items: [
        { label: constants.NO, value: false },
        { label: constants.YES, value: true },
      ],
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        rowData.isTriggeredByOtherEvents ? constants.YES : constants.NO,
    },
  ];
  if(props.isScenario){
    columns.unshift({
      title: "Event",
      field: "event",
      type: constants.STRING,
      inputFieldType: constants.STRING,
      editable: constants.NEVER,
      initialEditValue: props.section,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    });
  }
  else if (props.section === "engines") {
    let items = props.allShopVisitEventNames[props.section]
      .slice(props.sectionIdx * 2, props.sectionIdx * 2 + 2)
      .concat(
        props.allShopVisitEventNames["events"]
          ? props.allShopVisitEventNames["events"].slice(
              props.sectionIdx * 2,
              props.sectionIdx * 2 + 3
            )
          : []
      );

    columns.unshift({
      title: "Event",
      field: "event",
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      items: items,
      initialEditValue: items[0].value,
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    });
  } else {
    columns.unshift({
      title: "Event",
      field: "event",
      type: constants.STRING,
      inputFieldType: constants.STRING,
      editable: constants.NEVER,
      initialEditValue: props.allShopVisitEventNames
        ? props.allShopVisitEventNames[props.section][props.sectionIdx].value
        : null,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    });
  }
  if(props.section === "engines" || props.section.includes("EPR")){
    columns.push({
      title: "Swap",
      field: "swap",
      required: false,
      cellStyle: styles.cellStyle,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      items: swapItems,
      initialEditValue: false,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => (rowData.swap ? constants.YES : constants.NO),
    });
  }
  
  let panelColumns = [
    // {
    //   label: ["Cost"],
    //   field: "cost",
    //   type: constants.NUMBER,
    //   inputFieldType: constants.TEXT_FIELD,
    //   cellStyle: styles.cellStyle,
    //   editable: true,
    //   required: false,
    //   width: 14,
    //   validate: (inputType, value, required) =>
    //     utils.validate(inputType, value, required),
    //   content: (value) =>
    //     utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
    // },
    // {
    //   label: ["Cost Date"],
    //   field: "costDate",
    //   type: constants.DATE,
    //   inputFieldType: constants.DATE,
    //   cellStyle: styles.cellStyle,
    //   editable: true,
    //   required: false,
    //   width: 14,
    //   validate: (inputType, value, required) =>
    //     utils.validate(inputType, value, required),
    //   content: (value) =>
    //     utils.renderCellValue(value, null, constants.DATE_CELL),
    // },
    // {
    //   label: ["Esc Rate"],
    //   field: "escalationRate",
    //   type: constants.PERCENTAGE,
    //   inputFieldType: constants.TEXT_FIELD,
    //   cellStyle: styles.cellStyle,
    //   editable: true,
    //   required: false,
    //   width: 10,
    //   validate: (inputType, value, required) =>
    //     utils.validate(inputType, value, required),
    //   content: (value) => `${_.round(value * 100, 3).toFixed(2)}%`,
    // },
    {
      label: ["GreenTime FH"],
      field: "greenTimeFlightHours",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: ["GreenTime FC"],
      field: "greenTimeFlightCycles",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: ["SinceEvent FH"],
      field: "swapSinceEventFlightHours",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: ["SinceEvent FC"],
      field: "swapSinceEventFlightCycles",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
  ];
  const detailPanel = {
    label: null,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: false, pagination: false },
    shouldRender: props.shouldRenderDetailPanel,
    detailPanelData: detailPanelData,
    inPanelEditing: true,
    tooltip: "Show Swap",
    columns: panelColumns,
    panelLetter: "S",
  };

  return {
    label: "Scheduled Shop Visit",
    caption: null,
    props,
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: props.isScenario ? constants.scenarioScheduledShopVisit : constants.scheduledShopVisit,
    errorsOnSubmit: {},
    detailPanel: detailPanel,
    columns: columns,
    data: shopVisits,
  };
}
