import * as constants from "../../../../constants";

export const updateData = (actionType, sectionIdx, newData, scenario) => {
  scenario.leases = [...scenario.leases];
  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };
  let extensions = [...scenario.leases[sectionIdx].extensions];
  switch (actionType) {
    case constants.ADD_ROW:
      extensions.push(newData);
      break;
    case constants.DELETE_ROW:
      extensions = extensions.filter((e, idx) => idx !== newData.id);
      break;
    case constants.UPDATE_ROW:
      extensions[newData.id] = newData;
      break;
    default:
      break;
  }

  scenario.leases[sectionIdx].extensions = extensions;

  return scenario;
};
