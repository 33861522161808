import * as constants from "../../../../constants";
import _ from "lodash";
import * as utils from "../../../../utils/utils";
import { styles } from "../common";
import { DeleteOutline, Visibility } from "@material-ui/icons";

const formatData = (scenario) => {
    var data = [];
    if(scenario.parameters.partoutEvent != null){
        data = scenario.parameters.partoutEvent.components;
    }
    //console.log(data);
    return data; 
}
export default function mapToViewModel_partoutEvents(
    scenario,
    props
) 
{
    const mxEvents = _.flattenDeep(Object.values(props.allShopVisitEventNames));
    // console.log(props);
    // console.log(mxEvents);
    // console.log(scenario);
    var threeDotBtnOptions = [{
        id: 0,
        icon: Visibility,
        label: constants.VIEW,
      }];
    if(scenario.parameters.partoutEvent != null)
    {
        threeDotBtnOptions.push({
            id: 1,
            icon: DeleteOutline,
            label: `${constants.DELETE} Date`,
            handleClick: () => {
              props.funcs.handleRowDelete(
                "partoutEventDate",
                -1,
                "partoutEventDate",
                {
                  partoutEventDate: null 
                }
              );
            },
          });
    }
    var tabData = formatData(scenario);

    const columns = [
        {
            title: "Component",
            field: "component",
            initialEditValue: mxEvents[0].value,
            type: constants.STRING,
            inputFieldType: constants.SELECT,
            editable: false,
            required: true,
            cellStyle: styles.cellStyle,
            items: mxEvents,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
        },
        {
            title: "SerialNumber",
            field: "componentSN",
            initialEditValue: mxEvents[0].value,
            type: constants.STRING,
            inputFieldType: constants.SELECT,
            editable: false,
            required: true,
            cellStyle: styles.cellStyle,
            items: mxEvents,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
        },
        {
            title: "Is Sold?",
            field: "isSold",
            initialEditValue: false,
            cellStyle: styles.cellStyle,
            type: constants.STRING,
            inputFieldType: constants.SELECT,
            editable: constants.ALWAYS,
            required: false,
            items: [
                { label: constants.YES, value: true },
                { label: constants.NO, value: false },
            ],
            validate: (inputType, value, required) =>
                utils.validate(inputType, value, required),
            render: (rowData) => (rowData.isSold ? constants.YES : constants.NO),
        },
        {
            title: "Sale Amount",
            field: "saleAmount",
            required: false,
            type: constants.NUMERIC,
            inputFieldType: constants.NUMBER,
            editable: constants.ALWAYS,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            render: (rowData) => utils.renderCellValue(rowData.saleAmount, null, constants.NUMERIC, null, 2),
        },
        {
            title: "Sale Date",
            field: "saleDate",
            required: false,
            type: constants.DATE,
            inputFieldType: constants.DATE,
            editable: constants.ALWAYS,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            render: (rowData) => utils.renderCellValue(rowData.saleDate,null, constants.DATE_CELL),
        },
    ]
    return {
        label: "Partout Events",
        caption: null,
        props,
        showOptions: true,
        tableName: "Partout Events",
        threeDotBtnOptions,
        isDeletable: () => false,
        tableType: constants.EDITABLE_TABLE,
        errorsOnSubmit: {},
        columns: columns,
        data: tabData,
    };
}