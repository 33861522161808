import React, { useState } from "react";
import moment from "moment";

import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DataTableCell from "../../Tables/DataTable/DataTableCell";
import InputFieldComponent from "./InputFieldComponent";
import * as constants from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  singleField: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  singleFieldUpper: {},
  singleFieldDataCell: {
    padding: "32px 113px 19px 32px",
  },
  singleFieldButtons: {
    padding: "19px 32px 19px 69px",
    textAlign: "right",
  },
  cancelText: {
    height: 16,
    width: 80,
    color: "rgba(0,0,0,0.38)",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "2px",
    lineHeight: "16px",
    textAlign: "left",
  },
  submitText: {
    height: 16,
    width: 80,
    color: "#0D47A1",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
  submitTextDisabled: {
    color: "rgba(0,0,0,0.38)",
  },
  submitButton: {
    width: 104,
    height: 36,
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: 4,
    backgroundColor: "rgba(98,2,238,0)",
  },
}));

const SingleFieldEditingCard = (
  { id, overlines, body, captions, onClose, required, type, tableName, props },
  ref
) => {
  const classes = useStyles();
  const [value, setValue] = useState(body);
  const [valid, setValid] = useState(
    props.funcs.validateCell(
      props.section,
      props.sectionIdx,
      tableName,
      id,
      body
    )
  );

  const styles = {
    datePicker: {
      color: "#64B5F6",
    },
  };

  const handleChange = (value) => {
    setValid(
      props.funcs.validateCell(
        props.section,
        props.sectionIdx,
        tableName,
        id,
        value
      )
    );

    setValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (valid) {
      props.funcs.handleCellUpdate(
        props.section,
        props.sectionIdx,
        tableName,
        id,
        type === constants.DATE
          ? moment.utc(value).format(constants.ISOFormat)
          : value
      );
      onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.singleField}>
        <Box className={classes.singleFieldUpper}>
          <Box className={classes.singleFieldDataCell}>
            <DataTableCell
              overlines={overlines}
              body={
                <InputFieldComponent
                  value={value}
                  type={type}
                  onChange={handleChange}
                  inputProps={
                    type !== constants.DATE ? { styles: styles.datePicker } : {}
                  }
                  required={required}
                />
              }
              captions={captions}
            />
            {valid ? null : (
              <span style={{ color: "red" }}>{props.errorMessage}</span>
            )}
          </Box>
        </Box>
        <Box className={classes.singleFieldLower}>
          <Box className={classes.singleFieldButtons}>
            <Button onClick={onClose}>
              <span className={classes.cancelText}>CANCEL</span>
            </Button>
            <Button
              className={classes.submitButton}
              variant="outlined"
              type="submit"
              disabled={!valid}
            >
              <span
                className={
                  !valid ? classes.submitTextDisabled : classes.submitText
                }
              >
                SAVE
              </span>
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

const forwardSingleField = React.forwardRef(SingleFieldEditingCard);
export default forwardSingleField;
