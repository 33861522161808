import React from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tooltip,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import VfDropdown from "./VfDropdown";
import * as constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
  dropDown: {
    transformOrigin: "center bottom",
    // "&:hover": {
    //   backgroundColor: "rgba(0,0,0,.06)",
    //   cursor: "pointer",
    // },
  },
  sticky: {
    // position: "sticky",
    top: 72,
    zIndex: 20,
    // background: "rgba(14,37,63,0.2)",
  },
  text: {
    color: "#023059",
    lineHeight: 1.8,
    fontSize: 14,
    zIndex: 20,
    "&:hover": {
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  textNoHoverEffect: {
    color: "#023059",
    lineHeight: 1.8,
    fontSize: 14,
  },
  icon: {
    fontSize: 14,
    verticalAlign: "text-top",
  },
  muiBreadcrumbsol: { padding: "5px 25px", alignItems: "unset", marginTop: 5 },
  muiBreadcrumbsli: { zIndex: 20 },
}));

const VfBreadcrumbs = ({ breadcrumbs }) => {
  const classes = useStyles();

  const renderLink = (label, props, tooltip, key) => {
    return (
      <Tooltip key={key} title={tooltip} arrow>
        <Box>
          <Link className={classes.text} to={props.to}>
            {label}
          </Link>
        </Box>
      </Tooltip>
    );
  };

  const renderText = (label, key) => {
    return (
      <Typography key={key} className={classes.textNoHoverEffect}>
        {label}
      </Typography>
    );
  };

  const renderButton = (label, key, props) => {
    return (
      <Button color='primary' key={key} onClick={props.handleClick}>
        {label}
      </Button>
    );
  };

  return (
    <Breadcrumbs
      style={{ marginLeft: -20 }}
      separator='›'
      aria-label='breadcrumb'
      classes={{ ol: classes.muiBreadcrumbsol, li: classes.muiBreadcrumbsli }}>
      <Tooltip title={"Back to Homepage"} arrow>
        <Box>
          <Link className={classes.text} to={"/"}>
            <HomeIcon fontSize='medium' />
          </Link>
        </Box>
      </Tooltip>
      {breadcrumbs.map((o, idx) => {
        switch (o.type) {
          case constants.LINK:
            return renderLink(o.label, o.props, o.tooltip, idx);
          case constants.DROPDOWN:
            return (
              <VfDropdown key={idx} selectedItem={o.label} items={o.items} />
            );
          case constants.BUTTON:
            return renderButton(o.label, idx, o.props);
          default:
            return renderText(o.label, idx);
        }
      })}
    </Breadcrumbs>
  );
};

export default VfBreadcrumbs;
