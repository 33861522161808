import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import { Backdrop, Box, Button, Fade, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, Visibility, AddBox, DeleteForever } from "@material-ui/icons";
import getComponentCashReserveAccData from "./getComponentCashReserveAccData";
import XLSX from "xlsx";
import DataTable from "../../../../../../../Common/Tables/DataTable/DataTable";
import MultiFunctionTable from "../../../../../../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import VfAlertDialogBox from "../../../../../../../Common/UtilComponents/VfAlertDialogBox";
import * as constants from "../../../../../../../../constants";
import MultiFieldsFadeInEditingPanel from "../../../../../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import DownloadIconSvg from "../../../../../../../Common/SvgComponents/DownloadIconSvg";
import UploadIconSvg from "../../../../../../../Common/SvgComponents/UploadIconSvg";

const useStyles = makeStyles({
  root: { maxWidth: "100%", margin: "auto" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  threeDotButton: { display: "inline-flex", float: "right" },
});

const uploadFile = async (
  file,
  assetDetails,
  columns,
  section,
  sectionIdx,
  handleValueChange,
  setsnackOpen,
  setSeverity,
  setMessage
) => {
  const reserveAccTemplate = columns
    .filter((col) => col.field !== "total")
    .map((col) => col.field);

  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    if (
      !file.name.includes(
        `MSN_${assetDetails.msn}-${moment
          .utc(Date.now())
          .format(constants.FULL_YEAR_DATE)}`
      )
    ) {
      setMessage(
        `Wrong file provided, please export current cash reserve accounts for use.`
      );
      setsnackOpen(true);
      setSeverity("warning");
      reject("Wrong file provided");
    }

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, {
        type: "binary",
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { raw: false });
      resolve(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  promise.then(async (data) => {
    const reserveAccountData = [];
    data.forEach((acc, idx) => {
      if (acc["dates"]) {
        let accObject = { id: idx };
        reserveAccTemplate.forEach((col) => {
          accObject[col] = acc[col]
            ? col === "dates"
              ? moment.utc(acc[col]).format(constants.FULL_YEAR_DATE)
              : acc[col]
            : null;
        });
        reserveAccountData.push(accObject);
      }
    });

    if (!_.isEmpty(reserveAccountData))
      handleValueChange("Import")(
        section,
        sectionIdx,
        constants.Cash_Reserve_Accounts,
        reserveAccountData
      );
  });
};

const exportFile = (alldata, assetDetails) => {
  const filename = `${constants.Cash_Reserve_Accounts}-MSN_${
    assetDetails.msn
  }-${moment.utc(Date.now()).format(constants.FULL_YEAR_DATE)}.csv`;
  let data = [];

  alldata.forEach((acc) => {
    // delete acc.total;
    delete acc.id;
    data.push(acc);
  });

  /* convert state to workbook */
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "sheet1");
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, filename);
};

const CashReserveAccounts = ({
  assetDetails,
  formatData,
  setsnackOpen,
  setSeverity,
  setMessage,
  msalContext,
}) => {
  const crComponentData = useMemo(
    () => getComponentCashReserveAccData(assetDetails),
    [assetDetails]
  );

  const cashReserveAccDates = useMemo(
    () => (_.isEmpty(crComponentData.dates) ? [] : crComponentData.dates),
    [crComponentData]
  );

  const classes = useStyles();
  const [viewAll, setViewAll] = useState(false);
  const [addCRAcc, setAddCRAcc] = useState(false);
  const [selectedCRAccDate, setSelectedCRAccDate] = useState(
    !_.isEmpty(cashReserveAccDates)
      ? cashReserveAccDates[cashReserveAccDates.length - 1]
      : ""
  );
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  useEffect(() => {
    setSelectedCRAccDate(
      cashReserveAccDates
        ? cashReserveAccDates[cashReserveAccDates.length - 1]
        : ""
    );
  }, [cashReserveAccDates]);

  const tableThreeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
    },
    {
      id: 1,
      icon: AddBox,
      label: `New Acc Date`,
      handleClick: () => {
        setAddCRAcc(true);
      },
    },
    {
      id: 2,
      icon: DeleteForever,
      label: `Delete`,
      handleClick: () => {
        setIsAlertDialogOpen(true);
      },
    },
  ];

  tableThreeDotBtnOptions.push({
    id: 5,
    icon: Visibility,
    label: `View All`,
    handleClick: () => {
      setViewAll(true);
    },
  });

  let cashReserveAccDatesOptions = !_.isEmpty(cashReserveAccDates)
    ? cashReserveAccDates.map((e, idx) => ({
        id: idx,
        label: moment(e).format(constants.DATE_FORMAT),
        value: e,
        props: {
          handleClick: (e) => setSelectedCRAccDate(cashReserveAccDates[e]),
        },
      }))
    : [
        {
          label: "No Cash Reserve Accounts",
          value: "No Cash Reserve Accounts",
          props: { handleClick: () => null },
        },
      ];

  const crAccountData = formatData(
    { selectedCRAccDate: selectedCRAccDate, crComponentData: crComponentData },
    constants.Cash_Reserve_Accounts,
    {
      tableThreeDotBtnOptions: _.isEmpty(cashReserveAccDates)
        ? [tableThreeDotBtnOptions[1]]
        : tableThreeDotBtnOptions,
      cashReserveAccDatesOptions: cashReserveAccDatesOptions,
    }
  );

  const {
    section,
    sectionIdx,
    funcs: { handleRowAdd, validateData, handleRowDelete, handleValueChange },
  } = crAccountData.props;

  const { accounts } = msalContext;
  const checkNames = ["Jordan Peart", "Jason Lin"];
  if (checkNames.some((checkName) => accounts[0].name.includes(checkName)))
    crAccountData.data.tableThreeDotBtnOptions.splice(3, 0, {
      id: 3,
      icon: UploadIconSvg,
      label: `Upload Acc`,
      handleClick: (file) => {
        uploadFile(
          file,
          assetDetails,
          crAccountData.data.columns,
          section,
          sectionIdx,
          handleValueChange,
          setsnackOpen,
          setSeverity,
          setMessage
        );
      },
    });

  // if (!_.isEmpty(cashReserveAccDates))
  crAccountData.data.tableThreeDotBtnOptions.splice(4, 0, {
    id: 4,
    icon: DownloadIconSvg,
    label: `Download Acc`,
    handleClick: () => {
      exportFile(crAccountData.data.alldata, assetDetails);
    },
  });

  const allcashreserveaccountdata = { ...crAccountData.data };
  allcashreserveaccountdata.data = crAccountData.data.alldata;
  allcashreserveaccountdata.caption = null;

  return (
    <Box>
      <VfAlertDialogBox
        isOpen={isAlertDialogOpen}
        handleAlertDialogVisible={setIsAlertDialogOpen}
        dialogTitle={"Delete Reserse Account"}
        dialogBody={
          "Are you sure you want to Delete this Cash Reserse Account?"
        }
        onAccept={() => {
          handleRowDelete(
            section,
            sectionIdx,
            crAccountData.componentType,
            crAccountData.data.data
          );
          setIsAlertDialogOpen(false);
        }}
      />

      <DataTable tableData={crAccountData.data} />

      <Modal
        open={viewAll}
        className={classes.modal}
        onClose={() => setViewAll(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}>
        <Fade in={viewAll}>
          <Box
            style={{
              maxWidth: 1550,
              margin: "0 auto",
              backgroundColor: "#fff",
              padding: 10,
              borderRadius: "5px",
            }}>
            <MultiFunctionTable
              tableData={allcashreserveaccountdata}
              noShadow={true}
              noPadding={true}
            />
            <Button
              style={{ float: "right", margin: "0 16px 10px 0" }}
              variant='outlined'
              onClick={() => setViewAll(false)}>
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>

      <MultiFieldsFadeInEditingPanel
        key={"addNewAcc"}
        open={addCRAcc}
        columns={crAccountData.data.columns}
        data={{}}
        props={{
          section: constants.Cash_Reserve_Accounts,
          funcs: { handleValuesUpdate: handleRowAdd, validateData },
        }}
        onClose={() => setAddCRAcc(false)}
        tableName={constants.Cash_Reserve_Accounts}
      />
    </Box>
  );
};

export default CashReserveAccounts;
