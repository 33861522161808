import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const uploadReserveAccounts = async (assetDetails, msalContext) => {
  let user = msalContext.accounts[0].name;
  let apiendpoint = apiRoutes.PostReserveAccount;
  let resp = await apiRequest(
    {
      url: apiendpoint({user: user}),
      method: "POST",
      data: assetDetails,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    assetDetails = resp.data;
  }
};

const updateCashBalance = (
  assetDetails,
  changeComponent,
  check,
  component,
  updateValue,
  date,
  idx,
  LLP = false
) => {
  let getindex = _.findIndex(assetDetails[changeComponent], {
    [check]: component,
  });
  let cashBalanceValues = null;
  if (LLP) {
    cashBalanceValues =
      assetDetails[changeComponent][getindex].llpStack.reserveAccount
        .cashBalanceByDate;
  } else if (component.includes("SupplementalEvent")) {
    //get eng position from supplemental event name
    var engpos = component.substring(
      0,
      component.indexOf("SupplementalEvent") - 1
    );
    getindex = _.findIndex(assetDetails[changeComponent], { [check]: engpos });
    let eventname = component.substring(
      component.indexOf("SupplementalEvent") + 18
    );

    let findevent = _.findIndex(
      assetDetails[changeComponent][getindex].supplementalEvents,
      { ["eventName"]: eventname }
    );
    cashBalanceValues =
      assetDetails[changeComponent][getindex].supplementalEvents[findevent]
        .reserveAccount.cashBalanceByDate;
  } else {
    cashBalanceValues =
      assetDetails[changeComponent][getindex].reserveAccount.cashBalanceByDate;
  }
  if (!_.isEmpty(cashBalanceValues)) {
    cashBalanceValues[idx].balance = parseFloat(updateValue);
    cashBalanceValues[idx].date = date;
  }
};

const addToCashBalance = (
  assetDetails,
  changeComponent,
  check,
  component,
  updateValue,
  date,
  LLP = false
) => {
  let getindex = _.findIndex(assetDetails[changeComponent], {
    [check]: component,
  });
  let cashBalanceValues = null;
  if (LLP) {
    cashBalanceValues = assetDetails[changeComponent][getindex].llpStack;
  } else if (component.includes("SupplementalEvent")) {
    var engpos = component.substring(
      0,
      component.indexOf("SupplementalEvent") - 1
    );
    getindex = _.findIndex(assetDetails[changeComponent], { [check]: engpos });
    let eventname = component.substring(
      component.indexOf("SupplementalEvent") + 18
    );
    let findevent = _.findIndex(
      assetDetails[changeComponent][getindex].supplementalEvents,
      { ["eventName"]: eventname }
    );
    cashBalanceValues =
      assetDetails[changeComponent][getindex].supplementalEvents[findevent];
  } else {
    cashBalanceValues = assetDetails[changeComponent][getindex];
  }
  if (
    cashBalanceValues.reserveAccount ||
    !_.isEmpty(cashBalanceValues.reserveAccount)
  ) {
    cashBalanceValues.reserveAccount.cashBalanceByDate.push({
      id: 0,
      date: date,
      balance: parseFloat(updateValue),
    });
  } else {
    cashBalanceValues.reserveAccount = {
      cashBalanceByDate: [
        {
          id: 0,
          date: date,
          balance: parseFloat(updateValue),
        },
      ],
    };
  }
};

const removeReserveAccDate = (
  assetDetails,
  changeComponent,
  value,
  LLP = false
) => {
  assetDetails[changeComponent].forEach((comp) => {
    let cashBalanceValues = null;
    if (LLP) {
      cashBalanceValues = comp.llpStack.reserveAccount.cashBalanceByDate;
    } else {
      cashBalanceValues = comp.reserveAccount.cashBalanceByDate;
    }
    if (cashBalanceValues.length === 1) {
      if (LLP) comp.llpStack.reserveAccount = null;
      else comp.reserveAccount = null;
    } else {
      let getindex = _.findIndex(
        cashBalanceValues,
        (o) => moment.utc(value.dates).diff(moment.utc(o.date), "days") === 0
      );
      cashBalanceValues = cashBalanceValues.splice(getindex, 1);
    }
    if (LLP && comp.supplementalEvents && comp.supplementalEvents.length >= 1) {
      comp.supplementalEvents.forEach((e) => {
        cashBalanceValues = e.reserveAccount.cashBalanceByDate;
        if (cashBalanceValues.length === 1) {
          e.reserveAccount = null;
        } else {
          let getindex = _.findIndex(
            cashBalanceValues,
            (o) =>
              moment.utc(value.dates).diff(moment.utc(o.date), "days") === 0
          );
          cashBalanceValues = cashBalanceValues.splice(getindex, 1);
        }
      });
    }
  });
};

const removeAllReserveAccDate = (assetDetails, changeComponent) => {
  assetDetails[changeComponent].forEach((comp) => {
    if (comp.reserveAccount) comp.reserveAccount.cashBalanceByDate = [];
  });
};

export const cashReserveAccounts = (
  actionType,
  assetDetails,
  newData,
  msalContext
) => {
  let date = null;
  let ignoreKeys = ["dates", "cashReservetotal", "id"];
  if (actionType === constants.UPDATE_ROW) {
    date = newData.dates;
    Object.entries(newData).forEach(([key, value]) => {
      let checkValue = key;

      if (checkValue.includes("LG")) {
        checkValue = checkValue.replace("LG ", "");
        updateCashBalance(
          assetDetails,
          "landingGears",
          "position",
          checkValue,
          value,
          date,
          newData.id
        );
      } else if (checkValue.includes("APU")) {
        updateCashBalance(
          assetDetails,
          "apUs",
          "componentType",
          checkValue,
          value,
          date,
          newData.id
        );
      } else if (checkValue.includes("Engine") && !checkValue.includes("LLP")) {
        updateCashBalance(
          assetDetails,
          "engines",
          "position",
          checkValue,
          value,
          date,
          newData.id
        );
      } else if (checkValue.includes("LLP")) {
        checkValue = checkValue.replace("LLP Stack ", "");
        updateCashBalance(
          assetDetails,
          "engines",
          "position",
          checkValue,
          value,
          date,
          newData.id,
          true
        );
      } else if (!ignoreKeys.includes(checkValue)) {
        updateCashBalance(
          assetDetails,
          "airframeChecks",
          "checkName",
          checkValue,
          value,
          date,
          newData.id
        );
      }
    });
  } else if (actionType === constants.ADD_ROW) {
    date = newData.dates;
    Object.entries(newData).forEach(([key, value]) => {
      let checkValue = key;
      if (checkValue.includes("LG")) {
        checkValue = checkValue.replace("LG ", "");
        addToCashBalance(
          assetDetails,
          "landingGears",
          "position",
          checkValue,
          value,
          date
        );
      } else if (checkValue.includes("APU")) {
        addToCashBalance(
          assetDetails,
          "apUs",
          "componentType",
          checkValue,
          value,
          date
        );
      } else if (checkValue.includes("Engine") && !checkValue.includes("LLP")) {
        addToCashBalance(
          assetDetails,
          "engines",
          "position",
          checkValue,
          value,
          date
        );
      } else if (checkValue.includes("LLP")) {
        checkValue = checkValue.replace("LLP Stack ", "");
        addToCashBalance(
          assetDetails,
          "engines",
          "position",
          checkValue,
          value,
          date,
          true
        );
      } else if (!ignoreKeys.includes(checkValue)) {
        addToCashBalance(
          assetDetails,
          "airframeChecks",
          "checkName",
          checkValue,
          value,
          date
        );
      }
    });
  } else if (actionType === constants.DELETE_ROW) {
    let comps = Object.keys(newData);
    let checkValues = comps.toString();
    if (checkValues.includes("LG"))
      removeReserveAccDate(assetDetails, "landingGears", newData);
    if (checkValues.includes("APU"))
      removeReserveAccDate(assetDetails, "apUs", newData);
    if (checkValues.includes("Engine")) {
      removeReserveAccDate(assetDetails, "engines", newData);
      removeReserveAccDate(assetDetails, "engines", newData, true);
    }
    if (comps.length > 6)
      removeReserveAccDate(assetDetails, "airframeChecks", newData);
  } else if (actionType === "Import") {
    //first remove all reserve account dates
    let comps = Object.keys(newData[0]);
    let checkValues = comps.toString();
    if (checkValues.includes("LG"))
      removeAllReserveAccDate(assetDetails, "landingGears");
    if (checkValues.includes("APU"))
      removeAllReserveAccDate(assetDetails, "apUs");
    if (checkValues.includes("Engine")) {
      removeAllReserveAccDate(assetDetails, "engines");
    }
    //TODO: Not feasible with engine supplemental events
    if (comps.length > 6)
      removeAllReserveAccDate(assetDetails, "airframeChecks");

    //now add them
    newData.forEach((e) => {
      date = e.dates;
      Object.entries(e).forEach(([key, value]) => {
        let checkValue = key;
        if (checkValue.includes("LG")) {
          checkValue = checkValue.replace("LG ", "");
          addToCashBalance(
            assetDetails,
            "landingGears",
            "position",
            checkValue,
            value,
            date
          );
        } else if (checkValue.includes("APU")) {
          addToCashBalance(
            assetDetails,
            "apUs",
            "componentType",
            checkValue,
            value,
            date
          );
        } else if (
          checkValue.includes("Engine") &&
          !checkValue.includes("LLP")
        ) {
          addToCashBalance(
            assetDetails,
            "engines",
            "position",
            checkValue,
            value,
            date
          );
        } else if (checkValue.includes("LLP")) {
          checkValue = checkValue.replace("LLP Stack ", "");
          addToCashBalance(
            assetDetails,
            "engines",
            "position",
            checkValue,
            value,
            date,
            true
          );
        } else if (!ignoreKeys.includes(checkValue)) {
          addToCashBalance(
            assetDetails,
            "airframeChecks",
            "checkName",
            checkValue,
            value,
            date
          );
        }
      });
    });
  }

  uploadReserveAccounts(assetDetails, msalContext);

  return assetDetails;
};
