import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Button,
  Grow,
  MenuItem,
  Divider,
  Paper,
  ClickAwayListener,
  MenuList,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import * as utils from "../../../../../../../utils/utils";
import * as constants from "../../../../../../../constants";
import Popper from "@material-ui/core/Popper";
import SingleFieldFadeInEditingPanel from "../../../../../../Common/Editing/EditingPanels/SingleFieldFadeInEditingPanel";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LoadingPanel from "../../../../../../Common/LoadingPanel";

const ScenarioDropdown = ({
  classes,
  openCreateScenario,
  setOpenCreateScenario,
  setSelectedScenario,
  assetDetails,
  scenario,
  handleScenarioChangeEvt,
  currentState,
  setShowErrorIcon,
}) => {
  const [openDD, setOpenDD] = useState(false);
  const anchorRef = useRef(null);
  const [showScenarios, setShowScenarios] = useState({});

  const scenarioSummaries = _.orderBy(
    assetDetails.scenarioSummaries,
    (scenario) => scenario.date,
    "desc"
  );

  const props = {
    funcs: {
      validateCell: (...props) => {
        const scenarioName = props[4];
        if (!scenarioName) return false;
        return true;
      },
      handleCellUpdate: (...props) => {
        const index = props[3];
        const scenarioName = utils.capitalizeFirstLetter(props[4].trim());
        handleScenarioChangeEvt(index, scenarioName);
        setShowErrorIcon(false);
        setOpenCreateScenario(false);
      },
    },
  };

  let selectedScenario =
    scenario.id === -1 || scenario.id === -2
      ? scenario
      : _.find(
          scenarioSummaries,
          (o) =>
            o.name === scenario.name &&
            moment.utc(o.date).format("DD-MM-YYYY") ===
              moment.utc(scenario.scenarioDate).format("DD-MM-YYYY")
        );

  if (_.isEmpty(selectedScenario))
    selectedScenario = {
      id: -1,
      name: null,
      date: null,
      author: null,
      upToDate: true,
    };

  const {
    id: selectedScenarioId,
    name: selectedScenarioName,
    date: selectedScenarioDate,
    author: selectedScenarioUser,
    upToDate: selectedScenarioUpToDate,
    outdatedReason: selectedScenarioOutdatedReason,
  } = selectedScenario;

  useEffect(() => {
    setSelectedScenario(selectedScenario);
  }, [selectedScenario]);

  const scenarioInfo = _.template(
    `<%= scenarioName %> (<%= scenarioDate %>) - <%= scenarioUser %>`
  );

  let items = [];
  let remainingScenarios = {};
  if (!_.isEmpty(scenarioSummaries)) {
    let groupedScenarios = _.groupBy(
      scenarioSummaries,
      (scenarios) => scenarios.name
    );

    let filteredScenarios = [];

    for (const [key, value] of Object.entries(groupedScenarios)) {
      if (key === "Base") filteredScenarios.unshift(value[0]);
      else filteredScenarios.push(value[0]);
      remainingScenarios[key] = _.chain(value.slice(1))
        .filter((o) => o.id !== selectedScenarioId)
        .map((o) => ({
          id: o.id,
          uptodate: o.upToDate,
          published: o.published,
          name: o.name,
          label: scenarioInfo({
            scenarioName: o.name,
            scenarioDate: moment.utc(o.date).format(constants.DATE_FORMAT),
            scenarioUser: o.author,
          }),
          props: {
            handleClick: handleScenarioChangeEvt,
          },
          outdatedReason: o.outdatedReason,
        }))
        .value();
    }
    items = _.chain(filteredScenarios)
      .filter(
        (o) =>
          o.id !== selectedScenarioId ||
          (o.id === selectedScenarioId && remainingScenarios[o.name].length > 0)
      )
      .map((o) => ({
        id: o.id,
        uptodate: o.upToDate,
        published: o.published,
        name: o.name,
        label: scenarioInfo({
          scenarioName: o.name,
          scenarioDate: moment.utc(o.date).format(constants.DATE_FORMAT),
          scenarioUser: o.author,
        }),
        props: {
          handleClick: handleScenarioChangeEvt,
        },
        outdatedReason: o.outdatedReason,
      }))
      .value();

    items.push({
      id: -2,
      label: "+ Duplicate Current Scenario",
      name: "duplicate",
      props: {
        handleClick: () => {
          currentState.duplicateScenario = true;
          setOpenCreateScenario(true);
        },
      },
    });
  }

  items.push({
    id: -1,
    label: "+ New Scenario",
    name: "new",
    props: {
      handleClick: () => setOpenCreateScenario(true),
    },
  });

  const handleExpandScenario = (scename) => {
    setShowScenarios({
      [scename]: showScenarios[scename] ? !showScenarios[scename] : true,
    });
  };

  const handleToggle = () => {
    setOpenDD((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDD(false);
  };

  let selectedItem = scenarioInfo({
    scenarioName: selectedScenarioName,
    scenarioDate: moment.utc(selectedScenarioDate).format("MMM-DD-YY"),
    scenarioUser: selectedScenarioUser,
  });

  return (
    <Box>
      {selectedScenarioName == null ? (
        <LoadingPanel />
      ) : (
        <Button
          variant='contained'
          ref={anchorRef}
          className={classes.scenarioButton}
          aria-controls={openDD ? "split-button-menu" : undefined}
          aria-expanded={openDD ? "true" : undefined}
          aria-label='select merge strategy'
          title={
            !selectedScenarioUpToDate && selectedScenarioId > 0
              ? selectedScenarioOutdatedReason
              : null
          }
          aria-haspopup='menu'
          disableElevation={true}
          onClick={handleToggle}>
          {selectedItem}
          {!selectedScenarioUpToDate && selectedScenarioId > 0 ? (
            <Tooltip
              title={selectedScenarioOutdatedReason}
              aria-label='scenario outofdate'>
              <ErrorOutlineIcon
                color='error'
                style={{ marginLeft: 20, marginRight: -30, marginBottom: 5 }}
              />
            </Tooltip>
          ) : (
            <></>
          )}
          <span className={classes.arrowSpan}>
            {items.length ? (
              openDD ? (
                <ExpandLess className={classes.icon} />
              ) : (
                <ExpandMore className={classes.icon} />
              )
            ) : null}
          </span>
        </Button>
      )}

      <Popper
        open={openDD}
        anchorEl={anchorRef.current}
        placement='bottom-start'
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 100,
          width: anchorRef.current ? anchorRef.current.offsetWidth - 8 : "100%",
          margin: "8px 0 0 4px",
          maxHeight: 500,
          overflow: "auto",
        }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id='split-button-menu'
                  style={{ display: "flex", flexDirection: "column" }}>
                  {items.map((item, idx) => (
                    <MenuItem key={idx} disableRipple>
                      <Box style={{ width: "100%" }}>
                        <Box className={classes.menuItemRoot}>
                          <Button
                            className={classes.menuItemButton}
                            style={{
                              all: "unset",
                              flexGrow: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: item.id < 0 ? "100%" : 260,
                              font: "Roboto Regular",
                              fontSize: 14,
                            }}
                            disableRipple
                            onClick={(event) => {
                              item.props.handleClick(item.id);
                              if (item.id !== -3) {
                                handleClose(event);
                                if (item.id >= 0) {
                                  setShowErrorIcon(false);
                                }
                              }
                            }}>
                            {item.id === -1 || item.id === -2 ? (
                              <span>
                                <Divider className={classes.divider} />
                                {item.label}
                              </span>
                            ) : (
                              <span>
                                {item.label}
                                {!item.uptodate ? (
                                  <Tooltip
                                    title={item.outdatedReason}
                                    aria-label='scenario outofdate'>
                                    <ErrorOutlineIcon
                                      color='error'
                                      style={{
                                        marginLeft: 10,
                                        marginTop: -10,
                                        marginBottom: -7,
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                              </span>
                            )}
                          </Button>
                          {item.id >= 0 ? (
                            !_.isEmpty(remainingScenarios[item.name]) ? (
                              <IconButton
                                disableRipple
                                style={{ width: 25, height: 25 }}
                                onClick={() => handleExpandScenario(item.name)}>
                                {showScenarios[item.name] ? "-" : "+"}
                              </IconButton>
                            ) : null
                          ) : null}
                        </Box>

                        {showScenarios[item.name] &&
                        !_.isEmpty(remainingScenarios[item.name]) ? (
                          <MenuList>
                            {remainingScenarios[item.name].map(
                              (subitems, jdx) => (
                                <MenuItem key={jdx}>
                                  <Button
                                    style={{
                                      all: "unset",
                                      font: "Roboto Regular",
                                      fontSize: 14,
                                    }}
                                    className={classes.menuItemButton}
                                    onClick={(event) => {
                                      subitems.props.handleClick(subitems.id);
                                      handleClose(event);
                                      setShowErrorIcon(false);
                                    }}>
                                    - <span>{subitems.label}</span>
                                    {!subitems.uptodate ? (
                                      <Tooltip
                                        title={subitems.outdatedReason}
                                        aria-label='scenario outofdate'>
                                        <ErrorOutlineIcon
                                          color='error'
                                          style={{
                                            marginLeft: 10,
                                            marginTop: -10,
                                            marginBottom: -7,
                                          }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                </MenuItem>
                              )
                            )}
                          </MenuList>
                        ) : null}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {currentState.duplicateScenario ? (
        <SingleFieldFadeInEditingPanel
          open={openCreateScenario}
          id={-2}
          overlines={["Scenario Name"]}
          body=''
          captions={null}
          onClose={() => {
            setOpenCreateScenario(false);
            currentState.duplicateScenario = false;
          }}
          required={true}
          type={constants.TEXT_FIELD}
          props={props}
        />
      ) : (
        <SingleFieldFadeInEditingPanel
          open={openCreateScenario}
          id={-1}
          overlines={["Scenario Name"]}
          body=''
          captions={null}
          onClose={() => {
            setOpenCreateScenario(false);
          }}
          required={true}
          type={constants.TEXT_FIELD}
          props={props}
        />
      )}
      {/* <p>
        <ErrorOutlineIcon color="error" style={{marginTop:5, paddingTop:5, marginLeft:3}} />
        {selectedScenarioOutdatedReason}
      </p> */}
    </Box>
  );
};

export default ScenarioDropdown;
