import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";

export const mapToViewModel_deal = (dealStages, data, sponsors, pipelineDealsData) => {
  dealStages = dealStages.slice(1, 10);
  const recourses = ["Non-Recourse", "Recourse"];
  const seniority = [1, 2, 3, 4, 5];
  const stages = [
    { label: "Lost",  value: "Lost"},
    { label: "Pipeline", value: "Initial Review" },
    { label: "Closed", value: "Closed"},
    { label: "Managed", value: "Managed" },
    { label: "Expired", value: "Expired" },
    { label: "Archive", value: "Archive" },
    { label: "Info Only", value:"Info Only"}
  ]
  const pipelineOnly = pipelineDealsData?.filter(function (d) {
    return d.application === "Pipeline"
  });
  var thecolumns = [
    {
      label: ["Sponsor"],
      field: "sponsor",
      editable: data === null ? true : data.editableDeal ? true : false,
      required: true,
      items: sponsors
        ? sponsors.map((sponsor) => {
            return { label: sponsor.name, value: sponsor.id };
          })
        : [],
      // initialEditValue: sponsors,
      type: constants.AUTO_COMPLETE,
      inputFieldType: constants.AUTO_COMPLETE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      label: ["Deal Name"],
      field: "dealName",
      editable: true,
      required: true,
      type: constants.TEXT,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      label: ["Facility Tranche Name"],
      field: "facilityTrancheName",
      editable: true,
      required: true,
      type: constants.TEXT,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      label: ["Tranche Seniority"],
      field: "trancheSeniority",
      editable: true,
      required: true,
      items: seniority.map((x) => {
        return { label: x, value: x };
      }),
      initialEditValue: seniority[0],
      type: constants.SELECT,
      inputFieldType: constants.SELECT,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      label: ["Recourse"],
      field: "recourse",
      editable: data === null ? true : data.editableDeal ? true : false,
      required: true,
      items: recourses.map((x) => {
        return { label: x, value: x };
      }),
      // initialEditValue: sponsors,
      type: constants.SELECT,
      inputFieldType: constants.SELECT,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
  ];

  if(data?.editableDeal){
    thecolumns.unshift(      
      {
      label: ["Pipeline Deal"],
      field: "globalId",
      editable: true,
      required: false,
      items: pipelineOnly.map((deal) => {
        return { label: deal.id + " - "+ deal.dealName +" ("+deal.stage+")", value: deal.id };
      }),
      // initialEditValue: sponsors,
      type: constants.AUTO_COMPLETE,
      inputFieldType: constants.AUTO_COMPLETE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      label: ["Stage"],
      field: "stage",
      editable: true,
      required: true,
      items: stages.map((stage) => {
            return { label: stage.label, value: stage.value };
          }),
      initialEditValue: data.stage,
      type: constants.AUTO_COMPLETE,
      inputFieldType: constants.AUTO_COMPLETE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    )
  } 
  return {
    columns: thecolumns,
    data,
  };
};
