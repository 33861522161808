import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Grow,
} from "@material-ui/core";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import { makeStyles } from "@material-ui/core/styles";
import { useMsal } from "@azure/msal-react";
import moment from "moment";
import _ from "lodash";

import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";
import * as constants from "../../../../constants";

const useStyles = makeStyles({
  liabSelectButton: {
    minWidth: 100,
    maxHeight: 28,
    margin: "16px 0 0 20px",
    backgroundColor: "#023059",
    color: "#F6F6F6",
    font: "Roboto Regular",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#023059",
    },
    textTransform: "none",
  },
  dropDown: {
    transformOrigin: "center",
  },
  divider: { opacity: 0.3, backgroundColor: "#023059" },
  icon: {
    fontSize: 14,
    verticalAlign: "text-top",
  },
  menuItemRoot: {
    width: "100%",
    maxHeight: 30,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  menuItemButton: {
    font: "Roboto Regular",
    fontSize: 14,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  text: {
    color: "#023059",
    lineHeight: 1.8,
    fontSize: 14,
    zIndex: 20,
    "&:hover": {
      fontWeight: 600,
      cursor: "pointer",
    },
    minWidth: 100,
  },
  textSelected: {
    fontWeight: 600,
  },
  textNoHoverEffect: {
    color: "#023059",
    lineHeight: 1.8,
    fontSize: 14,
  },
});

const LiabilityModelSelect = ({
  selectedModelType,
  deal,
  handleSelectLiabilityModel,
}) => {
  const msalContext = useMsal();
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [remainingItems, setRemainingItems] = useState({});
  const [showItems, setShowItems] = useState({});

  const modelInfo = _.template(
    `<%= modelType %> / Upload (<%= uploadDate %>) / Model - <%= modelDate %>`
  );

  const modelInfoSubtype = _.template(
    `<%= modelType %> - <%= modelSubtype %> / Upload (<%= uploadDate %>) / Model - <%= modelDate %>`
  );

  useEffect(() => {
    const fetchData = async () => {
      const modelSelectItems = await apiRequest(
        {
          url: apiRoutes.LiabilityModel_GetLiabilityArchiveSelectView,
          params: {
            dealId: deal.dealId,
          },
          method: "GET",
        },
        msalContext
      );

      if (modelSelectItems && modelSelectItems.status === response.OK) {
        let data = modelSelectItems.data.map((item) => {
          item.modelSubtype
            ? (item["label"] = modelInfoSubtype({
                modelType: item.modelType,
                modelSubtype: item.modelSubtype,
                uploadDate: moment
                  .utc(item.asOfDate)
                  .format(constants.DATE_FORMAT),
                modelDate: moment
                  .utc(item.modelStartDate)
                  .format(constants.DATE_FORMAT),
              }))
            : (item["label"] = modelInfo({
                modelType: item.modelType,
                uploadDate: moment
                  .utc(item.asOfDate)
                  .format(constants.DATE_FORMAT),
                modelDate: moment
                  .utc(item.modelStartDate)
                  .format(constants.DATE_FORMAT),
              }));

          return item;
        });

        let remainingItems = {};
        let groupedItems = _.groupBy(data, (Item) => Item.modelType);

        let filteredItems = [];

        for (const [key, value] of Object.entries(groupedItems)) {
          if (key === "Base") filteredItems.unshift(value[0]);
          else filteredItems.push(value[0]);
          remainingItems[key] = _.chain(value.slice(1))
            .filter((o) => o.label !== selectedModelType)
            .value();
        }

        let items = _.chain(filteredItems)
          .filter(
            (o) =>
              o.label !== selectedModelType ||
              (o.label === selectedModelType &&
                remainingItems[o.modelType].length > 0)
          )
          .value();

        if (!selectedModelType.includes("Base") && !("Base" in groupedItems)) {
          let item = {
            label: "Base",
            dealId: deal.dealId,
            modelType: null,
            asOfDate: null,
          };
          items.push(item);
          remainingItems["Base"] = [item];
        }

        setRemainingItems(remainingItems);
        setSelectedItem(items);
      }
    };
    fetchData();
  }, [msalContext]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleExpandItems = (scename) => {
    setShowItems({
      [scename]: showItems[scename] ? !showItems[scename] : true,
    });
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box className={selectedItem.length ? classes.dropDown : null}>
      <Button
        variant='contained'
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup='true'
        className={classes.liabSelectButton}
        onClick={handleToggle}>
        <span style={{ float: "left", minWidth: 100 }} mr={1}>
          {selectedModelType}
        </span>
        <span>
          {selectedItem.length ? (
            open ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )
          ) : null}
        </span>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement='bottom-start'
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 50,
          // width: anchorRef.current ? anchorRef.current.offsetWidth - 8 : "100%",
          maxHeight: 500,
          overflow: "auto",
        }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} className={classes.drowDown}>
            <Paper square={true}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id='split-button-menu'
                  style={{ display: "flex", flexDirection: "column" }}>
                  {selectedItem.map((item, idx) => (
                    <MenuItem
                      key={idx}
                      disableRipple
                      style={{ display: "flex", flexDirection: "column" }}>
                      <Box style={{ width: "100%" }}>
                        <Box className={classes.menuItemRoot}>
                          <Button
                            className={classes.menuItemButton}
                            style={{
                              all: "unset",
                              flexGrow: 1,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              font: "Roboto Regular",
                              fontSize: 14,
                            }}
                            disableRipple
                            onClick={(event) => {
                              handleSelectLiabilityModel(deal.dealId, item);
                              handleClose(event);
                            }}>
                            <span>{item.label}</span>
                          </Button>
                          {!_.isEmpty(remainingItems[item.modelType]) ? (
                            <IconButton
                              disableRipple
                              style={{ width: 25, height: 25 }}
                              onClick={() => handleExpandItems(item.modelType)}>
                              {showItems[item.modelType] ? "-" : "+"}
                            </IconButton>
                          ) : null}
                        </Box>
                      </Box>
                      <Box>
                        {showItems[item.modelType] &&
                        !_.isEmpty(remainingItems[item.modelType]) ? (
                          <MenuList>
                            {remainingItems[item.modelType].map(
                              (subitems, jdx) => (
                                <MenuItem key={jdx}>
                                  <Button
                                    className={classes.menuItemButton}
                                    style={{
                                      all: "unset",
                                      flexGrow: 1,
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      // maxWidth: item.id < 0 ? "100%" : 260,
                                      font: "Roboto Regular",
                                      fontSize: 14,
                                    }}
                                    disableRipple
                                    onClick={(event) => {
                                      handleSelectLiabilityModel(
                                        deal.dealId,
                                        subitems
                                      );
                                      handleClose(event);
                                    }}>
                                    <span>{subitems.label}</span>
                                  </Button>
                                </MenuItem>
                              )
                            )}
                          </MenuList>
                        ) : null}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default LiabilityModelSelect;
