import _ from "lodash";

export const updateData = (scenario, sectionIdx, newData, actionType) => {
  scenario.leases = [...scenario.leases];
  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };
  let extensions = [...scenario.leases[sectionIdx].extensions];


  if (actionType === "leasePanelUpdate") {
    for(let i = 0; i < extensions.length; i++){
      extensions[i].rentPayments = newData.filter((x) => x.selectExtension === i);
    }
    //
  } else {
    const rentPayment = {
      amount: parseInt(newData.rent),
      frequency: newData.paymentFrequency,
      from: newData.fromDate,
      paymentDate: newData.paymentDate,
      to: newData.toDate,
    }

    if(extensions[newData.selectExtension].rentPayments[newData.id].pbhRate || newData.pbhRate === "Yes") {
      rentPayment.amount = null;
      rentPayment.pbhRate = parseInt(newData.rent);
    } else {
      rentPayment.pbhRate = null
    }

    extensions[newData.selectExtension].rentPayments[newData.id] = rentPayment;
  }
  
  return scenario;
};
