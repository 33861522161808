import moment from "moment";
import * as constants from "../../../constants";

const parseDate = (dateStr) => {
  if (isNaN(dateStr)) {
    //Checked for numeric
    var dt = new Date(dateStr);
    if (isNaN(dt.getTime())) {
      //Checked for date
      return dateStr; //Return string if not date.
    } else {
      return dt; //Return date **Can do further operations here.
    }
  } else {
    return dateStr; //Return string as it is number
  }
};

export const prepaymentsUpdater = (
  fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  loanSummary,
  columns
) => {
  let primaryValue = newData.primaryValue;
  let columnType = columns.type || columns[1].type;
  if (
    columnType === constants.DATE &&
    parseDate(primaryValue) instanceof Date
  ) {
    primaryValue = moment.utc(primaryValue).format(constants.DATE_FORMAT);
  }

  if (actionType === constants.UPDATE_ROW) {
    if (newData.rowIdx.includes("addRow")) {
      let rowIdx = parseInt(newData.rowIdx.replace("addRow", ""));
      const rowData = loanSummary[sectionName].additionalRows[rowIdx];
      rowData.rowTitle = newData.rowTitle;
      rowData.primaryValue = newData.primaryValue;
      rowData.secondaryValue = newData.secondaryValue;
      rowData.footNotes = newData.footNote;
    } else {
      const rowData = loanSummary[sectionName][fieldName];
      let innerRowIdx = parseInt(newData.rowIdx);
      const innerRowData = rowData[innerRowIdx];
      innerRowData.primaryValue = newData.primaryValue;
      innerRowData.secondaryValue = newData.secondaryValue;
      innerRowData.footNotes = newData.footNote;
    }
  } else if (actionType === constants.ADD_ROW) {
    const sectionData = loanSummary[sectionName];
    if (sectionIdx) {
      let sectionRowData = null;
      if (sectionIdx[0] === "Mandatory Prepayments")
        sectionRowData = sectionData.mandatoryPrepayments;
      else if (sectionIdx[0] === "Voluntary Prepayments")
        sectionRowData = sectionData.voluntaryPrepayments;
      else if (sectionIdx[0] === "Prepayment Fees")
        sectionRowData = sectionData.prepaymentFees;

      if (sectionRowData) {
        sectionRowData.push({
          primaryValue: primaryValue,
          secondaryValue: newData.secondaryValue,
          footNotes: newData.footNote,
        });
      }
    } else {
      const sectionAddRow = loanSummary[sectionName].additionalRows;
      sectionAddRow.push({
        rowTitle: newData.rowTitle,
        primaryValue: newData.primaryValue,
        secondaryValue: newData.secondaryValue,
        footNotes: newData.footNote,
      });
    }
  } else if (actionType === constants.DELETE_ROW) {
    if (newData.rowIdx.includes("addRow")) {
      let rowIdx = parseInt(newData.rowIdx.replace("addRow", ""));
      const rowDatas = loanSummary[sectionName].additionalRows;
      rowDatas.splice(rowIdx, 1);
    } else {
      const rowData = loanSummary[sectionName][fieldName];
      let innerRowIdx = parseInt(newData.rowIdx);
      rowData.splice(innerRowIdx, 1);
    }
  }

  return loanSummary;
};
