import React from "react";

import { Grid} from "@material-ui/core";

import PoolName from "./PoolName";
import PoolDeals from "./PoolDeals";
import PoolAssetCount from "./PoolAssetCount";

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chips: {
    borderRadius: 5,
    margin: "0px 0px 0px 8px",
    padding: "0px 8px 0px 10px",
    fontWeight: "normal",
    height: 24,
    lineHeight: "24px",
    letterSpacing: "2px",
  },
}));

const PoolSummary = ({
  assetPool,
  airlineLogos,
  liabilityData,
  assetPoolId,
}) => {
  const { assetPool_Assets, assetPool_PastAssets, assetPool_ArchivedAssets } = assetPool;

  return (
    <Grid
      container
      alignItems='center'
      spacing={2}
      style={{ margin: "0 -20px 0 0" }}>
      <Grid item md={5} sm={5}>
        <PoolName assetPool={assetPool} />
      </Grid>
      <Grid item md={4} sm={4}>
        <PoolAssetCount assets={assetPool_Assets} pastAssets={assetPool_PastAssets.concat(assetPool_ArchivedAssets)} airlineLogos={airlineLogos} />
      </Grid>
      <Grid item md={3} sm={3}>
        <PoolDeals assetPool={assetPool} />
      </Grid>
      {/* <Grid item md={3} sm={3}>
        {liabilityData ? (
          <PoolLiabilityPreview
            liabilityData={liabilityData}
            assetPoolId={assetPoolId}
            assetPool={assetPool}
          />
        ) : (
          <></>
        )}
      </Grid> */}
    </Grid>
  );
};

export default PoolSummary;
