import _ from "lodash";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import moment from "moment";

export function mapToViewModel_conversionEvents(data) {
  return {
    label: "Conversion Event",
    showOptions: false,
    errorsOnSubmit: {},
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: "Conversion Event",
    columns: [
      {
        label: ["EVENT START DATE"],
        field: "startDate",
        captions: [],
        span: [4, 4],
        editable: false,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
      },
      {
        label: ["EVENT END DATE"],
        field: "endDate",
        captions: [],
        span: [4, 4],
        editable: false,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
      },
      {
        label: ["EVENT COST"],
        field: "cost",
        captions: [],
        span: [4, 4],
        editable: false,
        required: false,
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      },
    ],
    data: data
      ? {
          startDate: moment.utc(data.startDate).format(constants.DATE_FORMAT),
          endDate: moment.utc(data.endDate).format(constants.DATE_FORMAT),
          cost: _.round(data.cost),
        }
      : {},
  };
}
