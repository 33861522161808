import _ from "lodash";
import React, { Component } from "react";
import { Box, Button, Paper, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AssetSeriesAndOperator from "./AssetSeriesAndOperator";
import AssetDealGroups from "./AssetDealGroups";
import * as constants from "../../../../constants";

const styles = (theme) => ({
  root: {
    height: "100%",
    width: 350,
    position: "fixed",
    top: 56,
    right: 0,
    // float: "right",
    zIndex: 50,
    backgroundColor: "#FBFBFB",
  },
  closeIcon: { position: "relative", float: "right" },
  button1: { bottom: 10 },
  button2: { bottom: 60 },
  buttons: {
    position: "absolute",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    height: 40,
    width: "90%",
    textAlign: "center",
    color: "#FFFFFF",
  },
  deleteAssetButton: {
    backgroundColor: "#A12425",
    "&:hover": { opacity: 0.8, backgroundColor: "#A12425" },
  },
  moreDetailButton: {
    backgroundColor: "#023059",
    "&:hover": { opacity: 0.8, backgroundColor: "#023059" },
  },
});

class SlideInPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      assetDetailRedirect: false,
      leaseSummaryRedirect: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = (height, event) => {
    this.setState({ height: window.innerHeight });
  };

  setAssetDetailRedirect = () => {
    this.setState({
      assetDetailRedirect: true,
    });
  };

  setLeaseSummaryRedirect = () => {
    this.setState({
      leaseSummaryRedirect: true,
    });
  };

  singleAssetBreadCrumb = (asset) => {
    const assetInfo = _.template(`<%= subseries %> (<%= serialNumber %>)`);
    const { subseries, serialNumber } = asset;

    return [
      {
        label: "Assets",
        type: constants.LINK,
        tooltip: "Back to Assets",
        props: { to: "/assets" },
      },
      {
        label: assetInfo({ subseries, serialNumber }),
        type: constants.TEXT,
      },
    ];
  };

  render() {
    const { asset, classes, onSlidePanelClose } = this.props;
    const { assetDetailRedirect, leaseSummaryRedirect } = this.state;

    return (
      <Paper
        className={classes.root}
        style={{
          height: this.state.height - 58,
        }}>
        <IconButton
          className={classes.closeIcon}
          onClick={onSlidePanelClose}
          color='primary'>
          <CloseIcon />
        </IconButton>
        <AssetSeriesAndOperator asset={asset} />
        <AssetDealGroups
          assetId={asset.id}
          assetSubSeries={asset.subseries}
          asset={asset}
        />
        <Link
          to={{
            pathname: `/assets/leaseSummary/${asset.id}?assetType=${asset.assetType}`,
            state: asset,
          }}>
          <Box className={`${classes.button2} ${classes.buttons}`}>
            <Button
              className={`${classes.button} ${classes.moreDetailButton}`}
              // onClick={this.setLeaseSummaryRedirect}
            >
              Lease Summary
            </Button>
          </Box>
        </Link>
        <Link
          to={{
            pathname: `/assets/assetDetails/${asset.id}?assetType=${asset.assetType}&serialNumber=${asset.serialNumber}`,
            state: {
              singleAsset: true,
              assetModel: {
                id: asset.id,
                assetType: asset.assetType,
                serialNumber: asset.serialNumber,
              },
              breadcrumbs: this.singleAssetBreadCrumb(asset),
            },
          }}>
          <Box className={`${classes.button1} ${classes.buttons}`}>
            <Button
              className={`${classes.button} ${classes.moreDetailButton}`}
              // onClick={this.setAssetDetailRedirect}
            >
              Asset Details
            </Button>
          </Box>
        </Link>
        {/* {assetDetailRedirect ? (
          <Link
            to={{
              pathname: `/assets/assetDetails/${asset.id}?assetType=${asset.assetType}&serialNumber=${asset.serialNumber}`,
              state: {
                singleAsset: true,
                assetModel: {
                  id: asset.id,
                  assetType: asset.assetType,
                  serialNumber: asset.serialNumber,
                },
                breadcrumbs: this.singleAssetBreadCrumb(asset),
              },
            }}
          />
        ) : (
          <></>
        )}
        {leaseSummaryRedirect ? (
          <Link
            to={{
              pathname: `/assets/leaseSummary/${asset.id}?assetType=${asset.assetType}`,
              state: asset,
            }}
          />
        ) : (
          <></>
        )} */}
        {/* <Box className={classes.buttons}>
          <Button
            className={`${classes.button} ${classes.deleteAssetButton}`}
            onClick={this.handleAssetDelete}
          >
            Delete Asset
          </Button>
        </Box> */}
      </Paper>
    );
  }
}

export default withStyles(styles)(SlideInPanel);
