import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  Fade,
  Modal,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import MultiFieldsEditComponent from "../../../Components/Common/Editing/EditingComponents/MultiFieldsEditComponent";
import InputFieldComponent from "../../../Components/Common/Editing/EditingComponents/InputFieldComponent";
import { mapToViewModel_deal } from "../mapToViewModels/dealViewModel";
import { mapToViewModel_dealSelect } from "../mapToViewModels/dealSelectViewModel";
import { DealRelatedDataContext } from "../../../context/DealRelatedDataContext";
import { StaticDataContext } from "../../../context/StaticDataContext";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modelBox: {
    overflowX: "hidden",
    overflowY: "auto",
    padding: 30,
    margin: "auto",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  switchButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    float: "center",
    margin: "0 0 20px 0",
  },
  multiFields: {
    maxWidth: 400,
    maxHeight: 550,
    overflowX: "hidden",
    overflowY: "auto",
    padding: 30,
    margin: "auto",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  buttons: {
    padding: "30px 0px 0px 69px",
    textAlign: "right",
  },
  cancelText: {
    height: 16,
    width: 80,
    color: "rgba(0,0,0,0.38)",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "2px",
    lineHeight: "16px",
    textAlign: "left",
  },
  submitText: {
    height: 16,
    width: 80,
    color: "#0D47A1",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
  submitTextDisabled: {
    color: "rgba(0,0,0,0.38)",
  },
  submitButton: {
    width: 104,
    height: 36,
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: 4,
    backgroundColor: "rgba(98,2,238,0)",
  },
});

const RenderDealDetails = (label, value) => {
  return (
    <Grid item sm={12}>
      <Typography
        variant='caption'
        color='textSecondary'
        style={{
          fontSize: 12,
          margin: "0px 0px 0px 16px",
        }}>
        {label}
      </Typography>
      <Typography
        variant='body1'
        color='textPrimary'
        style={{
          fontSize: 14,
          maxHeight: label === "Description" ? 150 : "100%",
          overflow: "auto",
          margin: "0px 0px 0px 8px",
        }}>
        {value}
      </Typography>
    </Grid>
  );
};

const DealAdd = ({ dealStages, handleDealAdd, open, setOpen }) => {
  const classes = useStyles();
  const { pipelineDealsData } = useContext(DealRelatedDataContext);
  const { sponsorsData } = useContext(StaticDataContext);
  const [switchDealAdd, setSwitchDealAdd] = useState(true);
  const [notValidCols, setNotValidCols] = useState(false);
  const [selectedPipelineDealData, setSelectedPipelineDealData] =
    useState(null);

  const assetModelDealprops = {
    funcs: {
      handleValuesUpdate: handleDealAdd,
      validateData: (newData, columns) => {
        let valid = true;
        if (
          !newData ||
          !newData["sponsor"] ||
          !newData["dealName"] ||
          !newData["recourse"] ||
          !newData["facilityTrancheName"] ||
          !newData["trancheSeniority"]
        ) {
          valid = false;
        }

        if (!valid) {
          setNotValidCols(true);
        }

        return valid;
      },
    },
  };

  const handleSelectedPipelineDeal = (field, value) => {
    let update = { ...selectedPipelineDealData };
    switch (field) {
      case "globalId":
        let findDeal = _.find(pipelineDealsData, (deal) => deal.id === value);
        if (findDeal) {
          findDeal["globalId"] = value;
          setSelectedPipelineDealData(findDeal);
        }
        break;
      case "recourse":
        update["recourse"] = value;
        setSelectedPipelineDealData(update);
        break;
      case "facilityTrancheName":
        update["facilityTrancheName"] = value;
        setSelectedPipelineDealData(update);
        break;
      case "trancheSeniority":
        update["trancheSeniority"] = value;
        setSelectedPipelineDealData(update);
        break;
    }
  };

  const handleSubmitAddPipelineDeal = (e) => {
    e.preventDefault();
    let valid = true;
    if (
      !selectedPipelineDealData ||
      !selectedPipelineDealData["globalId"] ||
      !selectedPipelineDealData["recourse"] ||
      !selectedPipelineDealData["facilityTrancheName"] ||
      !selectedPipelineDealData["trancheSeniority"]
    ) {
      valid = false;
    }

    if (valid) {
      handleDealAdd(null, null, "Select Deal", selectedPipelineDealData, null);
      setOpen(false);
    } else {
      setNotValidCols(true);
    }
  };

  const handleSwitchDealAdd = () => {
    setSwitchDealAdd(!switchDealAdd);
    setSelectedPipelineDealData(null);
    setNotValidCols(false);
  };

  return (
    <Modal
      open={open}
      className={classes.modal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      style={{ width: "100%" }}>
      <Fade in={open}>
        {switchDealAdd ? (
          <Box className={classes.modelBox} style={{ maxWidth: 350 }}>
            <Box className={classes.switchButton}>
              <IconButton
                variant='outlined'
                size='small'
                color='primary'
                onClick={() => handleSwitchDealAdd()}>
                <SwapHorizIcon />
                <span style={{ margin: "0px 8px 0px 8px" }}>
                  Switch To Add Deal From Scratch
                </span>
              </IconButton>
            </Box>
            {notValidCols ? (
              <Box
                style={{
                  margin: "20px 0",
                  textAlign: "center",
                }}>
                <span style={{ color: "red" }}>Please fill in all fields</span>
              </Box>
            ) : null}
            {pipelineDealsData ? (
              <form onSubmit={handleSubmitAddPipelineDeal}>
                <Box className={classes.multiFields} style={{ all: "unset" }}>
                  <Grid container spacing={4}>
                    {mapToViewModel_dealSelect(pipelineDealsData).columns.map(
                      (c, i) =>
                        c.editable ? (
                          <Grid item key={i} sm={12}>
                            <Box width={"100%"}>
                              <InputFieldComponent
                                label={c.label}
                                items={c.items}
                                value={
                                  selectedPipelineDealData &&
                                  selectedPipelineDealData[c.field]
                                }
                                type={c.inputFieldType}
                                valueType={c.type}
                                fullWidth={true}
                                variant={"filled"}
                                datePickervariant={"filled"}
                                onChange={(value) =>
                                  handleSelectedPipelineDeal(c.field, value)
                                }
                                required={c.required}
                                keyboardButtonProps={{
                                  style: { padding: "12px 0 0 0" },
                                }}
                                errorOnSubmit={null}
                                multiline={c.multiline}
                                rows={c.numRows}
                                initialEditValue={c.initialEditValue}
                              />
                            </Box>
                          </Grid>
                        ) : null
                    )}
                    {selectedPipelineDealData ? (
                      <>
                        {RenderDealDetails(
                          "Description",
                          selectedPipelineDealData.description
                        )}
                        {RenderDealDetails(
                          "Owner",
                          selectedPipelineDealData.owner
                        )}
                        {RenderDealDetails(
                          "Sponsor",
                          selectedPipelineDealData.sponsor
                        )}
                        {RenderDealDetails(
                          "Stage",
                          selectedPipelineDealData.stage
                        )}
                      </>
                    ) : null}
                  </Grid>

                  <Box className={classes.buttons}>
                    <Button
                      onClick={() => {
                        setOpen(false);
                        setSelectedPipelineDealData(null);
                      }}>
                      <span className={classes.cancelText}>CANCEL</span>
                    </Button>

                    <Button
                      className={classes.submitButton}
                      variant='outlined'
                      type='submit'>
                      <span className={classes.submitText}>SAVE</span>
                    </Button>
                  </Box>
                </Box>
              </form>
            ) : null}
          </Box>
        ) : (
          <Box className={classes.modelBox} style={{ maxWidth: 350 }}>
            <Box className={classes.switchButton}>
              <IconButton
                className={classes.switchButton}
                variant='outlined'
                size='small'
                color='primary'
                onClick={() => handleSwitchDealAdd()}>
                <SwapHorizIcon />
                <span style={{ margin: "0px 8px 0px 8px" }}>
                  Switch To Pipeline Deal
                </span>
              </IconButton>
            </Box>
            {notValidCols ? (
              <Box
                style={{
                  margin: "20px 0",
                  textAlign: "center",
                }}>
                <span style={{ color: "red" }}>Please fill in all fields</span>
              </Box>
            ) : null}

            <MultiFieldsEditComponent
              columns={
                mapToViewModel_deal(dealStages, null, sponsorsData).columns
              }
              styles={{ all: "unset" }}
              props={assetModelDealprops}
              onClose={() => {
                setOpen(false);
                setSwitchDealAdd(true);
              }}
              tableName={"Add Deal"}
            />
          </Box>
        )}
      </Fade>
    </Modal>
  );
};

export default DealAdd;
