import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { mapToViewModel } from "../../mapToViewModels/index";
import VerticalTable from "../../../Common/Tables/VerticalTable/VerticalTable";
import MultiFieldsFadeInEditingPanel from "../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import VfSnackerBar from "../../../Common/UtilComponents/VfSnackerBar";
import { AddBox, Visibility, DeleteForever } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import MXReserve_LessorContrib from "./Components/MXReserves_LessorContrib";
import RedeliveryConditions from "./Components/RedeliveryConditions";
import Banner from "./Components/Banner";
import * as constants from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  scrollMargin: {
    paddingTop: "70px",
    marginTop: "-70px",
  },
  pageBreak: {
    pageBreakAfter: "always",
  },
}));

const handleSetFootNoteData = (footnoteData, setFootNote, setOpen) => {
  setFootNote(footnoteData);
  setOpen(true);
};

const formatData = (
  assetType,
  componentType,
  generateEditableProps,
  leaseSummary,
  setOpen,
  setFootNote
) => {
  let props = generateEditableProps(1, componentType);

  const threeDotBtnOptions = (fieldName, footnoteData) => {
    return [
      {
        id: 0,
        icon: Visibility,
        label: constants.VIEW,
      },
      {
        id: 1,
        icon: AddBox,
        label: constants.ADD_ROW,
      },
      {
        id: 2,
        icon: AddBox,
        label: `Add FootNote`,
        handleClick: () => {
          handleSetFootNoteData(footnoteData, setFootNote, setOpen);
        },
      },
      {
        id: 3,
        icon: DeleteForever,
        label: `Delete FootNote`,
        handleClick: () => {
          props.funcs.handleRowDelete(
            props.section,
            "footNotes",
            fieldName,
            "footNotes"
          );
        },
      },
    ];
  };

  return {
    data: mapToViewModel(
      assetType,
      componentType,
      props,
      leaseSummary,
      threeDotBtnOptions
    ),
    componentType,
  };
};

const LeaseContent = ({
  assetType,
  leaseSummary,
  generateEditableProps,
  editing,
  editAll,
  handlePublished,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [footNote, setFootNote] = useState(null);
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  let aircraftDetailsData = formatData(
    assetType,
    "aircraftDetails",
    generateEditableProps,
    leaseSummary
  );
  let leaseDetailsData = formatData(
    assetType,
    "leaseDetails",
    generateEditableProps,
    leaseSummary
  );
  let mxReserve_LessorContribData = formatData(
    assetType,
    "mxReserve_LessorContrib",
    generateEditableProps,
    leaseSummary,
    setOpen,
    setFootNote
  );
  let leaseOptionsData = formatData(
    assetType,
    "leaseOptions",
    generateEditableProps,
    leaseSummary
  );
  let redeliveryConditionsData = formatData(
    assetType,
    "redeliveryConditions",
    generateEditableProps,
    leaseSummary,
    setOpen,
    setFootNote
  );
  let engineSubstitutionData = formatData(
    assetType,
    "engineSubstitution",
    generateEditableProps,
    leaseSummary
  );
  let airworthinessDirectivesData = formatData(
    assetType,
    "airworthinessDirectives",
    generateEditableProps,
    leaseSummary
  );
  let insuranceData = formatData(
    assetType,
    "insurance",
    generateEditableProps,
    leaseSummary
  );
  let taxAndIndemnitiesData = formatData(
    assetType,
    "taxAndIndemnities",
    generateEditableProps,
    leaseSummary
  );
  let other_LegalData = formatData(
    assetType,
    "other_Legal",
    generateEditableProps,
    leaseSummary
  );

  return (
    <Box style={{ padding: "0px 0 40px 0" }}>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        message={message}
      />

      {editing && (
        <Banner
          bannerText={"Changes were made to the Lease Summary."}
          submitButtonLabel={"Publish"}
          leaseSummary={leaseSummary}
          setsnackOpen={setsnackOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          handlePublished={handlePublished}
        />
      )}

      <div
        id="Aircraft Details"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable tableData={aircraftDetailsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div
        id="Lease Details"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable tableData={leaseDetailsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div
        id="Maintenance Reserves / Lessor Contributions"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <MXReserve_LessorContrib
          data={mxReserve_LessorContribData.data}
          editAll={editAll}
        />
      </div>
      <br></br>
      <div
        id="Lease Options"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable tableData={leaseOptionsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div
        id="Redelivery Conditions"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <RedeliveryConditions
          data={redeliveryConditionsData.data}
          editAll={editAll}
        />
      </div>
      <br></br>
      <div
        id="Engine Substitution"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable
          tableData={engineSubstitutionData.data}
          editAll={editAll}
        />
      </div>
      <br></br>
      <div
        id="Airworthiness Directives"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable
          tableData={airworthinessDirectivesData.data}
          editAll={editAll}
        />
      </div>
      <br></br>
      <div
        id="Insurance"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable tableData={insuranceData.data} editAll={editAll} />
      </div>
      <br></br>
      <div
        id="Tax and Indemnities"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable
          tableData={taxAndIndemnitiesData.data}
          editAll={editAll}
        />
      </div>
      <br></br>
      <div
        id="Other/Legal"
        className={`${classes.scrollMargin} ${classes.pageBreak}`}
      >
        <VerticalTable tableData={other_LegalData.data} editAll={editAll} />
      </div>

      {footNote ? (
        <MultiFieldsFadeInEditingPanel
          open={open}
          columns={[
            {
              label: ["Foot Notes"],
              field: "footNote",
              editable: true,
              multiline: true,
              numRows: 3,
              required: false,
              type: constants.STRING,
              inputFieldType: constants.TEXT_FIELD,
            },
          ]}
          data={{
            footNote: footNote.footNotes,
          }}
          tableName={footNote.tableName}
          props={footNote.props}
          onClose={() => setOpen(false)}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default LeaseContent;
