import _ from "lodash";
import * as constants from "../../../constants";
import { apiRequest, response } from "../../../utils/ApiRequest";
import apiRoutes from "../../../utils/ApiRoutes";
import { async } from "q";

const postApuRunout = async (id, value, msalContext) => {
  let apiendpoint = apiRoutes.GenericApu_postRunout;
  apiendpoint = apiendpoint({ id, value });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
    },
    msalContext
  );
  return resp;
};

export const apuRunoutUpdater = (
  tableName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  genericData,
  msalContext
) => {
    let apuData = genericData.apUs;
    let selectedApuData = _.find(apuData, { 'id': newData.Id });
    
    postApuRunout(newData.Id, newData.apuRunoutValue,msalContext);
    selectedApuData.runoutValue = newData.apuRunoutValue;
    return genericData
}