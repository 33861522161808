import React, {useState} from "react";
import { Box, Typography, Button } from "@material-ui/core";

import MultiFieldsEditComponent from "../../Common/Editing/EditingComponents/MultiFieldsEditComponent";
import { makeStyles } from "@material-ui/core/styles";
// import { values } from "lodash";

const useStyles = makeStyles((theme) => ({
  title: { color: "rgba(0,0,0,0.87)", fontSize: 34, textAlign: "center" },
  subTitle: {
    color: "rgba(0,0,0,0.87)",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "center",
  },
}));

const handleSkipAsset = (props, selected, subTitle) => {
  if (subTitle.includes("Life Percentage"))
    props.funcs.handleValuesUpdate(null, null, null, 101);
  else props.funcs.handleValuesUpdate(null, null, null, null);
};

const logError = (error) => {
  console.log(error);
}

const AssetInput = ({ columns, selected, props, subTitle }) => {
  const classes = useStyles();
  const skippableSubTitle = ["AFCheck","APU", "Engines", "Life Percentage"];
  const dashindex = subTitle.indexOf(" -");

  var newsub = subTitle;
  if(dashindex != -1)
    newsub = subTitle.slice(0,subTitle.indexOf(" -"));

 // console.log(props);


  return (
    <Box>
      <Box mb={4}>
        <Typography variant='subtitle1' className={classes.title}>
          Asset Creation
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant='subtitle2' className={classes.subTitle}>
          {subTitle}
        </Typography>
      </Box>
      <Box mb={18}>
        <MultiFieldsEditComponent
          columns={columns}
          data={selected}
          props={props}
          submitButtonText='Continue'
          onClose={() => null}
          onCancel={() => window.location.reload()}
          errorsOnSubmit={(e) => logError(e)}
        />
        {skippableSubTitle.includes(newsub)  ? (
          <Box style={{ textAlign: "center" }}>
            <Button
              onClick={() => handleSkipAsset(props, selected, subTitle)}
              style={{
                margin: 20,
                color: "#023059",
                fontFamily: "Roboto",
                fontSize: 16,
                textTransform: "none",
              }}>
              {"Skip " + subTitle}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default AssetInput;
