import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import VfSnackerBar from "../../../Common/UtilComponents/VfSnackerBar";
import Asset from "./Asset";
import ApiRoutes from "../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
//import AddAssetDialog from "./AddAssetDialog";
import AssetPoolAssetGrid from "./AssetPoolAssetGrid";

const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  addAssetBox: {
    height: 68,
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#DDE0E5",
    boxShadow: "unset",
    paddingTop: 15,
    paddingBottom: 15,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.background.hover}`,
    },
  },
  addAssetContent: { width: "100%", paddingRight: 7, paddingTop: 6 },
}));
const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
  if (a_start < b_start && b_start < a_end) return true; // b starts in a
  if (a_start < b_end   && b_end   < a_end) return true; // b ends in a
  if (b_start <  a_start && a_end   <  b_end) return true; // a in b
  return false;
}
const multipleDateRangeOverlaps = (timeIntervals) => {
  let i = 0, j = 0;
  let conflicts = [];
  for (i = 0; i < timeIntervals.length - 1; i += 1) {
      for (j = i + 1; j < timeIntervals.length; j += 1) {
              let a_start = new Date(timeIntervals[i].enterDate);
              let a_end = timeIntervals[i].exitDate !== null ? new Date(timeIntervals[i].exitDate) : new Date("12-31-9999");
              let b_start = new Date(timeIntervals[j].enterDate);
              let b_end = timeIntervals[j].exitDate !== null ? new Date(timeIntervals[j].exitDate) : new Date("12-31-9999");
              let overlap = dateRangeOverlaps(a_start, a_end,b_start,b_end);
              if (overlap){
               conflicts.push({first: timeIntervals[i], second: timeIntervals[j]})
              }
          }
  }
  return conflicts;
}
function createUTCDate(date){
  if(date.getFullYear() === 1){
    return date;
  }
  if(date.getFullYear() === 9999){
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }
  let returnme =  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  returnme.setMinutes(returnme.getMinutes() + returnme.getTimezoneOffset());
  return returnme;
}


const Assets = ({
  user,
  assetPool,
  assetPoolAssets,
  assetPoolPastAssets,
  assetPoolFutureAssets,
  assetPoolArchivedAssets,
  allAssets,
  airlineLogos,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const [open, setOpen] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [duration, setDuration]= useState(5000);
  const [allAPassets, setAllAPassets] = useState([]);
  const [uniqueCurrent, setUniqueCurrent] = useState([]);
  const [current, setCurrent] = useState(assetPoolAssets);
  const [future, setFuture] = useState(assetPoolFutureAssets);
  const [past, setPast] = useState(assetPoolPastAssets);
  const [archived, setArchived] = useState(assetPoolArchivedAssets);

  const setloading = () =>{
    setMessage(`Updating Asset Pool...`);
    setsnackOpen(true);
    setDuration(5000);
    setSeverity("info");
  }

  //TODO:: IF STATUS OKAY, UPDATE WITH THE RETURNED RESULTS ASSETPOOL DATA
  const submitAssetPool = async (rows, removed, edited) => {
    setloading();
    let added = rows.filter((x) => x.status === "NEW");
    //create utc dates to avoid dates changing based on time zone (except 9999 / 1)
    added.forEach((a)=>{
        a.exitDate = a.exitDate ? createUTCDate(a.exitDate) : null;
        a.enterDate = createUTCDate(a.enterDate);
    });
    removed.forEach((r)=>{
      r.exitDate = r.exitDate ? createUTCDate(r.exitDate) : null;
      r.enterDate = createUTCDate(r.enterDate);
    });
    edited.forEach((e) => {
      e.exitDate =  e.exitDate ? createUTCDate(e.exitDate) : null
      e.enterDate = createUTCDate(e.enterDate);
    });
    //if edited is empty and removed is empty and the count is the same, dont send the request.
    if(added.length < 1 && removed.length < 1 && edited.length < 1){
      setsnackOpen(false);
      return "No Changes to Submit";
    }
    // validation of dates here
    let enggroup = [];
    let acgroup = [];
    let group = Object.groupBy(rows, ({assetType})=>assetType);
    if(group.Aircraft && group.Aircraft !== undefined && group.Aircraft.length > 0)
      acgroup = Object.groupBy(group.Aircraft,({assetId})=>assetId);
    if(group.Engine && group.Engine !== undefined && group.Engine.length > 0)
      enggroup = Object.groupBy(group.Engine, ({assetId})=>assetId);
    let msg = "";
    _.forIn(acgroup, function (value, key) {
      if(value.length <= 1)
        return;
      //go through each and set to or from date to newvalue
      value.forEach((v)=>{
        if(v.Field === "enterDate"){
          v.enterDate = v.NewValue;
        }
        if(v.Field === "exitDate"){
          v.exitDate = v.NewValue;
        }
      });
      
      let overlaps = multipleDateRangeOverlaps(value);
      if(overlaps.length > 0){
         msg = "AssetId: "+value[0].assetId+" -- Date Range: "+new Date(overlaps[0].first.enterDate).toLocaleDateString('en-US') +" - "+ new Date(overlaps[0].first.exitDate).toLocaleDateString('en-US') + 
                  "\n Overlaps with "+new Date(overlaps[0].second.enterDate).toLocaleDateString('en-US') +" - "+ new Date(overlaps[0].second.exitDate).toLocaleDateString('en-US');
      }
    });
    _.forIn(enggroup, function (value, key) {
      if(value.length <= 1)
        return;
      value.forEach((v)=>{
        if(v.Field === "enterDate"){
          v.enterDate = v.NewValue;
        }
        if(v.Field === "exitDate"){
          v.exitDate = v.NewValue;
        }
      });
      let overlaps = multipleDateRangeOverlaps(value);
      if(overlaps.length > 0){
         msg = "AssetId: "+value[0].assetId+" -- Date Range: "+new Date(overlaps[0].first.enterDate).toLocaleDateString('en-US') +" - "+ new Date(overlaps[0].first.exitDate).toLocaleDateString('en-US') + 
                  "\n Overlaps with "+new Date(overlaps[0].second.enterDate).toLocaleDateString('en-US') +" - "+ new Date(overlaps[0].second.exitDate).toLocaleDateString('en-US');
      }
    });
    if(msg !== ""){
      setMessage(`Failed to Edit Asset Pool.`);
      setSeverity("warning");
      setsnackOpen(true);
      return msg;
    }
    let userName = msalContext.accounts[0].name;
    let data = { addedAssets : added, edited: edited, removed: removed }
    const result = await apiRequest(
      {
        url: ApiRoutes.AssetPool_SubmitAssets({
          user: userName,
        }),
        data: data,
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      msalContext
    );
    setsnackOpen(false);
    if (result.status === response.OK) {
      setMessage(`Successfully Submitted Assets.`);
      setSeverity("info");
      setsnackOpen(true);
      setCurrent(result.data.assetPool_Assets);
      setFuture(result.data.assetPool_FutureAssets);
      setPast(result.data.assetPool_PastAssets);
      setArchived(result.data.assetPool_ArchivedAssets)
    } else {
          setMessage(`Failed to Edit Asset Pool.`);
          setSeverity("warning");
          setsnackOpen(true);
    }
  }
  const editAsset = async (asset,newdata) =>{
    let others = allAPassets.filter((x)=> x.assetId === asset.assetId && x.assetType === asset.assetType && x.enterDate !== asset.enterDate && x.exitDate !== asset.exitDate);
    if(others !== undefined && others.length > 0 ){
      others.push({subseries: asset.subseries, serialNumber: asset.serialNumber, enterDate:newdata.enterDate,exitDate:newdata.exitDate});
    }
    let overlaps = multipleDateRangeOverlaps(others);
    if(overlaps.length > 0){
      let mes = "Date Range: "+new Date(overlaps[0].first.enterDate).toLocaleDateString('en-US') +" - "+ new Date(overlaps[0].first.exitDate).toLocaleDateString('en-US') + 
                "\n Overlaps with "+new Date(overlaps[0].second.enterDate).toLocaleDateString('en-US') +" - "+ new Date(overlaps[0].second.exitDate).toLocaleDateString('en-US')
      setMessage(mes);
      setsnackOpen(true);
      setSeverity("warning");
      setDuration(10000);
      return;
    }
    let userName = msalContext.accounts[0].name;
    let data = { log : asset, edit:newdata }
    const result = await apiRequest(
      {
        url: ApiRoutes.AssetPool_EditAsset({
          user: userName,
        }),
        data: data,
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      msalContext
    );
    if (result.status === response.OK) {
      setMessage(`Successfully Edited Asset.`);
      setsnackOpen(true);
      setSeverity("info");
      setCurrent(result.data.assetPool_Assets);
      setFuture(result.data.assetPool_FutureAssets);
      setPast(result.data.assetPool_PastAssets);
      setArchived(result.data.assetPool_ArchivedAssets);
    } else {
          setMessage(`Failed to Edit Asset.`);
          setsnackOpen(true);
          setSeverity("warning");
    }
  
    return;
  }
  const exitAsset = async (asset,newdata) =>{
    let userName = msalContext.accounts[0].name;
    let data = { log : asset, exit:newdata }
    const result = await apiRequest(
      {
        url: ApiRoutes.AssetPool_ExitAsset({
          user: userName,
        }),
        data: data,
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      msalContext
    );
    if (result.status === response.OK) {
      setMessage(`Successfully Exited From Pool.`);
      setsnackOpen(true);
      setSeverity("info");
      setCurrent(result.data.assetPool_Assets);
      setFuture(result.data.assetPool_FutureAssets);
      setPast(result.data.assetPool_PastAssets);
      setArchived(result.data.assetPool_ArchivedAssets);
    } else {
          setMessage(`Failed to Exit Asset.`);
          setsnackOpen(true);
          setSeverity("warning");
    }
    return;
  }
  const deleteAssetRecord = async (asset) => {
    let userName = msalContext.accounts[0].name;
    const result = await apiRequest(
      {
        url: ApiRoutes.AssetPool_DeleteRecord({
          user: userName,
        }),
        data: asset,
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      msalContext
    );
    if (result.status === response.OK) {
            setMessage(`Successfully Removed Record.`);
            setsnackOpen(true);
            setSeverity("info");
            setCurrent(result.data.assetPool_Assets);
            setFuture(result.data.assetPool_FutureAssets);
            setPast(result.data.assetPool_PastAssets);
            setArchived(result.data.assetPool_ArchivedAssets);
    } else {
            setMessage(`Failed to add to Remove Record.`);
            setsnackOpen(true);
            setSeverity("warning");
    }
    return;
  }

  useEffect (() => {
    const concatassets = (past,current,future,archived) =>{
      let allarray = [];
      past.forEach((e)=>{
        e.status = "EXPIRED";
        allarray.push(e);
      });
      current.forEach((e)=>{
        e.status = "CURRENT";
        allarray.push(e);
      });
      future.forEach((e)=>{
        e.status = "FUTURE";
        allarray.push(e);
      });
      archived.forEach((e)=>{
        e.status = "ARCHIVED";
        allarray.push(e);
      });

      return allarray;
    };
    const FilterDuplicates = (current,future,past) => {
      let currentfuture = current.concat(future.filter(i2 => !current.some(i1 => i1.assetId === i2.assetId && i1.assetType === i2.assetType)));
     return currentfuture.concat(past.filter(i2 => !currentfuture.some(i1 => i1.assetId === i2.assetId && i1.assetType === i2.assetType)));
    };
    setAllAPassets(concatassets(past,current,future,archived));
    setUniqueCurrent(FilterDuplicates(current,future,past));

  }, [past,current,future,archived]);
  

  return (
    <Box className={classes.root}>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        duration={duration}
        message={message}
      />
        {/* ADD ASSET */}
        <Grid container spacing={2} alignItems='center' style={{paddingTop:15}}>
        <Grid item sm={6} lg={3} md={4}>
            <Box className={classes.addAssetBox} onClick={() => setOpen(true)}>
              <Grid
                container
                className={classes.addAssetContent}
                spacing={1}
                justify='center'>
                <Grid item>
                  <AddCircleOutlineIcon color='primary' />
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1' color='primary'>
                    Add Asset Or Edit All
                  </Typography>
                </Grid>
              </Grid>
            </Box>
        </Grid>
      </Grid>
      {future && future.length > 0 ?  
      (
        <div>
          <p>Future Assets</p>
          <Grid container spacing={2} alignItems='center'>
            {future.map((asset, index) => (
              <Grid key={index} item sm={6} lg={3} md={4}>
                <Asset
                  user={user}
                  assetPool={assetPool}
                  asset={asset}
                  assetPoolAssets={uniqueCurrent}
                  airlineLogo={_.find(
                    airlineLogos,
                    (o) => o.operator === asset.operator
                  )}
                  deleteAssetfcn={deleteAssetRecord}
                  editAssetfcn={editAsset}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null }
      {current && current.length > 0 ?  
      (
        <div>
          <p >Current Assets</p>
          <Grid container spacing={2} alignItems='center'>
            {current.map((asset, index) => (
              <Grid key={index} item sm={6} lg={3} md={4}>
                <Asset
                  user={user}
                  assetPool={assetPool}
                  asset={asset}
                  assetPoolAssets={uniqueCurrent}
                  airlineLogo={_.find(
                    airlineLogos,
                    (o) => o.operator === asset.operator
                  )}
                  deleteAssetfcn={deleteAssetRecord}
                  editAssetfcn={editAsset}
                  status={"current"}
                  exitAssetfcn={exitAsset}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null }
      {past && past.length > 0 ?  
      (
        <div>
          <p>Past Assets</p>
          <Grid container spacing={2} alignItems='center'>
            {past.map((asset, index) => (
              <Grid key={index} item sm={6} lg={3} md={4}>
                <Asset
                  user={user}
                  assetPool={assetPool}
                  asset={asset}
                  assetPoolAssets={uniqueCurrent}
                  airlineLogo={_.find(
                    airlineLogos,
                    (o) => o.operator === asset.operator
                  )}
                  deleteAssetfcn={deleteAssetRecord}
                  editAssetfcn={editAsset}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) 
      : null } 
      {archived && archived.length > 0 ?(
          <div>
          <p>Archived Assets</p>
          <Grid container spacing={2} alignItems='center'>
            {archived.map((asset, index) => (
              <Grid key={index} item sm={6} lg={3} md={4}>
                <Asset
                  user={user}
                  assetPool={assetPool}
                  asset={asset}
                  assetPoolAssets={uniqueCurrent}
                  airlineLogo={_.find(
                    airlineLogos,
                    (o) => o.operator === asset.operator
                  )}
                  deleteAssetfcn={deleteAssetRecord}
                  editAssetfcn={editAsset}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}

      {/* 
        TODO:: CHANGE THIS TO A TABLE WHERE YOU CAN: PICK TO AND FROM, POPULATE ROWS IN A TABLE BASED ON THAT AND MULTI AUTOSELECT OF ASSETS 
            USE 
                import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
                import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
                import { DatePicker } from "@mui/x-date-pickers/DatePicker";   

           PASS: 
                Available Assets, All AssetPool Assets, AssetPool
      */}
      <AssetPoolAssetGrid
        availableAssets={allAssets}
        assetpool={assetPool}
        assetpoolAssets={allAPassets}
        open={open}
        setopen={setOpen}
        updatefcn={submitAssetPool}
      />
      {/* {availableAssets ? (
        <MultiFieldsFadeInEditingPanel
          open={open}
          columns={availableAssets.columns}
          props={props}
          onClose={() => setOpen(false)}
        />
      ) : null} */}
    </Box>
  );
};

export default Assets;
