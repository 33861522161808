import _ from "lodash";
import moment from "moment";

export const updateData = (newData, scenario) => {
  let idx = null;
  let llpStackScrapOverrides = null;
  let llpStackPriceEscOverrides = null;

  const overrideDate = moment
    .utc(newData.svStartDate)
    .toISOString()
    .split(".")[0];

  if (_.isEmpty(scenario.llpStackScrapOverrides)) {
    llpStackScrapOverrides = [
      {
        enginePosition: newData.enginePosition,
        scrapSaleCutoff: newData.scrapValue,
        overrideDate: overrideDate,
      },
    ];
  } else {
    llpStackScrapOverrides = scenario.llpStackScrapOverrides;

    idx = _.findIndex(
      llpStackScrapOverrides,
      (o) => o.enginePosition === newData.enginePosition
    );
    if (idx === -1) {
      llpStackScrapOverrides.push({
        enginePosition: newData.enginePosition,
        scrapSaleCutoff: newData.scrapValue,
        overrideDate: overrideDate,
      });
    } else {
      llpStackScrapOverrides[idx] = { ...llpStackScrapOverrides[idx] };
      llpStackScrapOverrides[idx].scrapSaleCutoff = newData.scrapValue;
      llpStackScrapOverrides[idx].overrideDate = overrideDate;
    }
  }

  if (_.isEmpty(scenario.llpStackPriceEscOverrides)) {
    llpStackPriceEscOverrides = [
      {
        enginePosition: newData.enginePosition,
        escalationRate: newData.escalationRate,
        overrideDate: overrideDate,
      },
    ];
  } else {
    llpStackPriceEscOverrides = scenario.llpStackPriceEscOverrides;
    idx = _.findIndex(
      llpStackPriceEscOverrides,
      (o) => o.enginePosition === newData.enginePosition
    );
    if (idx === -1) {
      llpStackPriceEscOverrides.push({
        enginePosition: newData.enginePosition,
        escalationRate: newData.escalationRate,
        overrideDate: overrideDate,
      });
    } else {
      llpStackPriceEscOverrides[idx] = { ...llpStackPriceEscOverrides[idx] };
      llpStackPriceEscOverrides[idx].escalationRate = newData.escalationRate;
      llpStackPriceEscOverrides[idx].overrideDate = overrideDate;
    }
  }

  scenario.llpStackPriceEscOverrides = llpStackPriceEscOverrides;
  scenario.llpStackScrapOverrides = llpStackScrapOverrides;

  return scenario;
};
