import _ from "lodash";
import moment from "moment";
import React from "react";
import * as utils from "../../../../utils/utils";
import VfChart from "../../../Common/UtilComponents/VfChart";

const getChartData = (report) => {
  const filteredLeases = report.leases.filter((el) => !_.isEmpty(el));
  let chartCutOffDate = null;
  if (!_.isEmpty(filteredLeases)) {
    let getDates = filteredLeases.map((e) => new Date(e.slice(-1)[0].endDate));
    chartCutOffDate = moment
      .utc(new Date(Math.max.apply(null, getDates)))
      .add(6, "M");
  } else {
    chartCutOffDate = moment.utc(_.last(Object.keys(report.cashFlows)));
  }
  const evaluatingDatesData = Object.entries(report.cashFlows).filter(
    ([key, value]) => moment.utc(key).isBefore(chartCutOffDate, "month")
  );
  let appraisedHalfLifeValues = [];
  let mxAdjustedHalfLifeValues = [];

  // sum of halflife values from all assets by looping through cash flow and using appraisal for each asset

  evaluatingDatesData.forEach((data) => {
    // let halfLifes = [];
    // let evaluateDate = new Date(data[0]);
    // report.fleetInfo.forEach((evalue) => {
    //   let currValuation = _.find(evalue.appraisal.futureValuations, (d) => {
    //     return new Date(d.date) > evaluateDate;
    //   });

    //   let nextEvaluationDate = new Date(
    //     moment.utc(evaluateDate).add(1, "years")
    //   );
    //   let nextValuation = _.find(evalue.appraisal.futureValuations, (d) => {
    //     return new Date(d.date) > nextEvaluationDate;
    //   });

    //   let currValuationDate = new Date(currValuation.date);
    //   let nextValuationtDate = new Date(nextValuation.date);

    //   let halfLife = _.round(
    //     utils.linearInterpolation(
    //       currValuationDate,
    //       evaluateDate,
    //       nextValuationtDate,
    //       currValuation["halfLifeBase_inflated"],
    //       nextValuation["halfLifeBase_inflated"],
    //       "days"
    //     ) * Math.pow(10, 6)
    //   );
    //   halfLifes.push(halfLife);
    // });

    appraisedHalfLifeValues.push(_.round(data[1].halfLifeValue));
    mxAdjustedHalfLifeValues.push(_.round(data[1].value));
    // mxAdjustedHalfLifeValues.push(_.round(_.sum(halfLifes)+ data[1].valueAboveHalfLife))
  });

  const evaluateDates = evaluatingDatesData.map((data) =>
    moment.utc(data[0]).format("MM-YYYY")
  );
  const mxCashReserve = evaluatingDatesData.map((data) =>
    parseInt(data[1].mxCashReserve)
  );

  const getMXEvents = [];

  report.mxEvents.forEach((data) => {
    let eventDate = moment.utc(data.eventStartDate).format("MM-YYYY");
    let mxCost = _.round(data.mxCost).toLocaleString();
    let itemindex = null;
    let type = null;
    if (data.type.includes("LLP")) {
      type = "ELLP";
    } else if (data.type.includes("PR")) {
      type = "EPR";
    } else if (data.type.includes("Check")) {
      type = "HMV";
    } else if (data.type.includes("Gear")) {
      type = "LG";
    } else if (data.type.includes("Swap")) {
      type = "ES";
    } else if (data.type.includes("EngineOther")) {
      type = "EP_O";
    } else {
      type = data.type;
    }

    if (evaluateDates.includes(eventDate)) {
      itemindex = evaluateDates.indexOf(eventDate);
      let repeatDates = _.filter(getMXEvents, ["x", itemindex]);
      // handle multiple events on the same date
      if (repeatDates.length >= 1) {
        getMXEvents.push({
          x: itemindex,
          y: 2000000 * (repeatDates.length + 1),
          name: data.name,
          eventType: type,
          date: eventDate,
          cost: mxCost,
        });
      } else {
        getMXEvents.push({
          x: itemindex,
          y: 2000000,
          name: data.name,
          eventType: type,
          date: eventDate,
          cost: mxCost,
        });
      }
    }
  });

  return {
    evaluateDates: evaluateDates,
    mxAdjustedHalfLifeValues: mxAdjustedHalfLifeValues,
    appraisedHalfLifeValues: appraisedHalfLifeValues,
    mxCashReserve: mxCashReserve,
    MXEvents: getMXEvents,
  };
};

const cashFlowAssetValueChart = ({ report }) => {
  const chartData = getChartData(report);

  let series = [
    {
      type: "area",
      name: "Cash Reserve",
      data: chartData.mxCashReserve,
      color: "rgba(2, 48, 89, 0.2)",
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, "rgba(2, 48, 89, 0.3)"],
          [0.5, "rgba(2, 48, 89, 0.3)"],
          [1, "rgba(2, 48, 89, 0.1)"],
        ],
      },
    },
    {
      type: "area",
      name: "Maintenance Adjusted Value",
      data: chartData.mxAdjustedHalfLifeValues,
      color: "rgba(2, 48, 89, 0.6)",
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, "rgba(2, 48, 89, 0.6)"],
          [0.5, "rgba(2, 48, 89, 0.6)"],
          [1, "rgba(2, 48, 89, 0.1)"],
        ],
      },
    },
    {
      type: "spline",
      name: "Appraised Half-Life Value",
      lineWidth: 2,
      color: "#B5292A",
      data: chartData.appraisedHalfLifeValues,
    },
    {
      type: "bubble",
      name: "Maintenance Events",
      data: chartData.MXEvents,
      color: "#023059",
      tooltip: {
        pointFormat:
          '<table><tr><th colspan="2"><h3>{point.eventType}</h3></th></tr>' +
          "<tr><th>Event Position:</th><td>{point.name}</td></tr>" +
          "<tr><th>Event Cost <small>(U.S. $)</small>:</th><td>{point.cost}</td></tr>" +
          "<tr><th>Event Date:</th><td>{point.date}</td></tr></table>",
        followPointer: true,
      },
      visible: false,
    },
  ];

  if (_.sum(chartData.appraisedHalfLifeValues) === 0) series.splice(2, 1);

  const options = {
    title: {
      text: "Asset Value Chart",
    },
    credits: { enabled: false },
    xAxis: {
      categories: chartData.evaluateDates,
    },
    yAxis: {
      min: 0,
    },
    tooltip: {
      useHTML: true,
      split: true,
      distance: 30,
      padding: 5,
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#bababa",
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
      spline: {
        marker: {
          enabled: false,
        },
        dashStyle: "Dash",
      },
      bubble: {
        maxSize: 20,
        dataLabels: {
          enabled: true,
          format: "{point.eventType}",
          allowOverlap: true,
          color: "#000",
        },
      },
    },
    series: series,
  };

  return <VfChart options={options} />;
};

export default cashFlowAssetValueChart;
