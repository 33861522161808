import moment from "moment";
import React, { useState, useEffect } from "react";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import * as constants from "../../../../../../constants";
import * as mapToViewModels from "../../../../mapToViewModels/index";
import MultiFunctionTable from "../../../../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import DataTable from "../../../../../Common/Tables/DataTable/DataTable";
import LoadingPanel from "../../../../../Common/LoadingPanel";
import CashFlowAssetValueChart from "./CashflowComponent/AssetValueChart";
import AssetCashFlowTimeSeriesChart from "./CashflowComponent/AssetCashFlowTimeSeries";
import TableNamePanel from "../../../../../Common/TableNamePanel";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const formatData = (data, componentType, mapToViewModel) => {
  const runDate = moment.utc().endOf("month").format(constants.DATE_FORMAT);
  return data.map((item) => ({
    header: null,
    data:
      componentType === constants.ASSET_STATUS
        ? mapToViewModel(item.assetInfo, runDate)
        : mapToViewModel(item),
    componentType: componentType,
  }));
};

const Cashflow = ({ report }) => {
  // debugger;
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [eventValue, setEventValue] = useState(0);
  const [checkAppraisal, setCheckAppraisal] = useState(true);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEventTabChange = (event, newValue) => {
    setEventValue(newValue);
  };

  useEffect(() => {
    const timer = report ? setTimeout(() => setLoading(false), 100) : null;
    if (report && !report.appraisal) setCheckAppraisal(false);
    return () => clearTimeout(timer);
  });

  if (report) {
    //console.log(report);
    const { leases, mxEvents, eolEvents, cashFlows, conversionEvents, partoutEvents,scenario } = report;
    
    const leaseInfo = formatData(
      [{ leases, mxEvents, eolEvents, cashFlows, conversionEvents, partoutEvents,scenario }],
      constants.LEASE_REPORT,
      mapToViewModels.leaseReportsViewModel
    );

    return loading ? (
      <LoadingPanel />
    ) : (
      <Box>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <MultiFunctionTable tableData={leaseInfo[0].data.leaseInfo} />
          </Grid>

          {checkAppraisal ? (
            <>
              <Grid item sm={12}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  indicatorColor='primary'
                  textColor='primary'
                  justify='end'>
                  <Tab key='assetValue' label='Value Over Time' />
                  <Tab key='assetCashflow' label='Cashflow Time Series' />
                </Tabs>
              </Grid>
              {value === 0 ? (
                <Grid item xs={12} sm={12} md={12}>
                  <CashFlowAssetValueChart report={report} />
                </Grid>
              ) : value === 1 ? (
                <Grid item xs={12} sm={12} md={12}>
                  <AssetCashFlowTimeSeriesChart report={report} />
                </Grid>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {conversionEvents && conversionEvents.length > 0 ? (
            <Grid item sm={12} style={{ minWidth: "100%" }}>
              <DataTable tableData={leaseInfo[0].data.conversionEvents} />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item sm={12}>
            <MultiFunctionTable
              tableData={leaseInfo[0].data.maintenanceEvents}
            />
          </Grid>
          <Grid item sm={12}>
            <Box
              bgcolor={"#FFFFFF"}
              style={{
                boxShadow:
                  "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
                padding: "16px 16px",
                overflowX: "auto",
                borderRadius: 12,
              }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Box style={{ width: 300 }}>
                  <TableNamePanel label={"Redelivery Events"} caption={null} />
                </Box>
                <Tabs
                  value={eventValue}
                  onChange={handleEventTabChange}
                  indicatorColor='primary'
                  textColor='primary'>
                  {leaseInfo[0].data.redeliveryEvents.map((rEvent) => (
                    <Tab
                      key={rEvent.deliveryDate}
                      label={rEvent.deliveryDate}
                      disableRipple={true}
                    />
                  ))}
                </Tabs>
              </Box>

              {leaseInfo[0].data.redeliveryEvents.map((rEvent, idx) => (
                <TabPanel key={idx} value={eventValue} index={idx}>
                  <MultiFunctionTable tableData={rEvent} noPadding={true} />
                </TabPanel>
              ))}
            </Box>
          </Grid>
          <Grid item sm={12}>
            <MultiFunctionTable tableData={leaseInfo[0].data.cashFlows} />
          </Grid>
        </Grid>
      </Box>
    );
  } else return null;
};

export default Cashflow;
