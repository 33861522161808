import _ from "lodash";

export const updateData = (assetDetails, newData) => {
  assetDetails.airframeChecks = [...assetDetails.airframeChecks];
  for (let i in assetDetails.airframeChecks) {
    let airframeCheck = { ...assetDetails.airframeChecks[i] };
    let maintenanceStatus = [...airframeCheck.maintenanceStatus];
    maintenanceStatus[0] = { ...maintenanceStatus[0] };
    maintenanceStatus[0].sinceNew = {
      flightHour: newData.flightHours && parseInt(newData.flightHours),
      flightCycle: newData.flightCycles && parseInt(newData.flightCycles),
      day: newData.months && parseInt(_.round(newData.months * 30.44)),
      activeHour: newData.activeHours,
      statusAsOf: newData.statusAsOf,
    };
    airframeCheck.maintenanceStatus = maintenanceStatus;
    assetDetails.airframeChecks[i] = airframeCheck;
  }
  return assetDetails;
};
