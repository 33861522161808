import _ from "lodash";
import React, { Component } from "react";
import { Box } from "@material-ui/core";
import VfSnackerBar from "../Common/UtilComponents/VfSnackerBar";
import AssetTypeCard from "./Components/AssetTypeCard";
import Airframe from "./Components/AssetComponent/Airframe";
import LifePercent from "./Components/AssetComponent/LifePercent";
import AFCheck from "./Components/AssetComponent/AFCheck";
import Engine from "./Components/AssetComponent/Engine";
import APU from "./Components/AssetComponent/APU";
import Preview from "./Components/Preview";
import * as constants from "../../constants";
import TransitionComponent from "./Components/TransitionComponent";
import ErrorBoundary from "../../Pages/GeneralErrorPage";

class AssetCreation extends Component {
  state = {
    selectingAssetType: true,
    selectedType: null,
    currStep: 0,
    airframe: {},
    airframeChecks: [],
    apu: {},
    engines: [],
    snackOpen: false,
    severity: "info",
    message: "",
    existingAssets: false,
    lifePercent: null,
    statusDate: null,
    createScenario: false,
    assetTypeToStep: {"aircraft":0,"engine":3,"apu":2}
  };

  generateProps = (field) => {
    const validateDataByField = (field) => (value) => {
      if (field === constants.ENGINES) {
        const newEngineModel = value.engineModel;
        const newEngineSerialNumber = value.serialNumber;
        let assetpairs = this.props.location.state.assets.map(
          ({serialNumber,subseries}) => { return {serialNumber, subseries}}
        );
        for (let engine of assetpairs) {
          const { subseries, serialNumber } = engine;
          if (
            subseries === newEngineModel.engineSubSeries &&
            serialNumber === newEngineSerialNumber
          ) {
            this.setState({
              snackOpen: true,
              severity: "error",
              message: (
                <span>
                  Engine,&nbsp;
                  <span style={{ fontWeight: 600, fontSize: 15 }}>
                    {subseries}({serialNumber})
                  </span>
                  &nbsp;already exists.
                </span>
              ),
            });
            return false;
          }
        }
      }
      let noEmpty = true;

      if (value) {
        if(Object.keys(value).includes("llps_Static")) {
          if(value.llps_Static.length == 0)
            delete value.llps_Static
        }

        Object.values(value).map((e) => {
          //console.log(e);
          noEmpty = !_.isEmpty(e) && e !== "" && noEmpty;
          
          return null;
        });
        

        return noEmpty;
      } else return false;
    };

    // const existingSerialNumbers = this.props.location.state.assets.map(
    //   (data) => data.serialNumber
    // );
    // const existingSubseries = this.props.location.state.assets.map(
    //   (data) => data.subseries
    // );
    let assetpairs = this.props.location.state.assets.map(
      ({serialNumber,subseries}) => [serialNumber, subseries]
    );
    let onwings = this.props.location.state.onwing.map(
      ({serialNumber,subseries}) => [serialNumber, subseries]
    );
    let combo = assetpairs.concat(onwings);
    // console.log(combo);
    // console.log(this.state.engines);
    // console.log(existingSubseries);
    // console.log(existingSerialNumbers);
    // console.log(assetpairs);

    return {
      funcs: {
        validateData: validateDataByField(field),
        // only value is useful
        handleValuesUpdate: (section, sectionIdx, tableName, value) => {
          // Check for existing Assets, need to implement engine asset check
          // console.log(field);
          // console.log(value);

          const { selectedType, lifePercent, statusDate, createScenario } =
            this.state;
          var allgood = true;
          if (field === "LifePercent" && (value === null || value === 101)) {
            this.setState({ lifePercent: 101 });
            this.setState({ statusDate: null });
          } else if (field === "LifePercent") {
            if (value.lifePercent > 1 || value.lifePercent < 0) {
              allgood = false;
              this.setState({
                snackOpen: true,
                severity: "warning",
                message: `Please enter a Life Percent between 0 - 1 (E.g. 0.5 for 50%)`,
              });
              setTimeout(() => {
                //window.location.reload(true);
              }, 2000);
            } else {
              this.setState({ lifePercent: value.lifePercent });
              this.setState({ statusDate: value.asOfDate });
              if (value.createScenario === "true")
                this.setState({ createScenario: true });
              else this.setState({ createScenario: false });
            }
          }
          //CHECK TO SEE IF THE SAME SERIAL NUMBER & SUBSERIES COMBO EXISTS
          var ap = JSON.stringify(combo);
          var ca = JSON.stringify([value?.serialNumber,value?.airframeModel?.aircraftSubSeries]);
          //check aircraft combos
          var finalac = ap.indexOf(ca);
          var ce = JSON.stringify([value?.serialNumber,value?.engineModel?.engineSubSeries])
          //check engine combos
          var finaleg = ap.indexOf(ce);
          
          // console.log(ap);
          // console.log(ce);
          // console.log(finaleg);
          if(selectedType === constants.ENGINE){
            if(value?.serialNumber === null || value?.serialNumber === "" || value?.serialNumber === undefined){
              this.setState({
                snackOpen: true,
                severity: "warning",
                message: `Serial Number is REQUIRED.`,
              });
              allgood = false;
            }
            // else if(value?.manufactureDate === null || value?.manufactureDate === "" || value?.manufactureDate === undefined){
            //   this.setState({
            //     snackOpen: true,
            //     severity: "warning",
            //     message: `Manufacture Date is REQUIRED.`,
            //   });
            //   allgood = false;
            // }
          }

          if (
            (field === "airframe" && finalac !== -1) ||
            (selectedType === constants.ENGINE && finaleg !== -1)
          ) {
            this.setState({
              snackOpen: true,
              severity: "warning",
              message: `This ${field} asset with serial number:${value.serialNumber} already exists.`,
            });
            setTimeout(() => {
              //window.location.reload(true);
            }, 2000);
          } else if (allgood) {
            this.setState((prevState) => ({
              currStep: prevState.currStep + 1,
              [field]:
                field === constants.ENGINES
                  ? [...this.state.engines, value]
                  : value,
            }));
          }
        },
      },
    };
  };

  handleAssetTypeSelect = (selectedType) => {
    const{assetTypeToStep} = this.state;
    // console.log(assetTypeToStep);
    // console.log(selectedType);
    // console.log(assetTypeToStep[selectedType]);
    this.setState({
      selectingAssetType: false,
      selectedType,
      currStep: assetTypeToStep[selectedType],
    });
  };
  handleAddFleetId = (id) =>{
    if(id && id.length > 0){
      var af = this.state.airframe;
      af.FGID = id[0];
      this.setState({airframe:af});
    }
  }

  handleSnackerBarOpen = (open) => {
    this.setState({ snackOpen: open });
  };
  SetPercent = (percent) => {
    this.setState({ lifePercent: percent });
  };
  setFleetDataOpen = () => {
    this.setState({openFD: true});
  }

  getAssetComponentInput = () => {
    const {
      currStep,
      airframe,
      airframeChecks,
      apu,
      engines,
      selectedType,
      lifePercent,
      statusDate,
      createScenario,
      assetTypeToStep
    } = this.state;
    const { airframeModel } = airframe;
    if (
      (airframeModel &&
        engines.length === airframeModel.numberOfEngines &&
        lifePercent == null) ||
      (selectedType !== constants.AIRCRAFT &&
        engines.length === 1 &&
        lifePercent == null) || 
      ( selectedType === constants.APU && lifePercent == null && currStep != assetTypeToStep[constants.APU])
    )
      return <LifePercent props={this.generateProps("LifePercent")} />;
      //console.log(selectedType, apu,lifePercent);
    if (
      (airframeModel &&
        engines.length === airframeModel.numberOfEngines &&
        lifePercent != null) ||
      (selectedType === constants.ENGINE &&
        engines.length === 1 &&
        lifePercent != null) || (selectedType === constants.APU &&
          apu != null &&
          lifePercent != null)
    )
      return (
        <TransitionComponent
          assetType={selectedType}
          airframe={airframe}
          airframeChecks={airframeChecks != null ? airframeChecks.airframeChecks : []}
          apu={apu}
          engines={engines}
          lifePercent={lifePercent}
          statusDate={statusDate}
          createScenario={createScenario}
        />
      );

    switch (currStep) {
      case 0:
        return (
          <Airframe
            props={this.generateProps(constants.Airframe.toLowerCase())}
          />
        );
      case 1:{
        return (
          <AFCheck
            ACId={airframeModel.id}
            airframe={airframe}
            update={this.handleAddFleetId}
            props={this.generateProps(constants.AIRFRAME_CHECKS)}
          />
        );
      }
      case 2:
      {
        if(selectedType === constants.APU){
          return (
            <APU
              ACId={-1}
              IsAsset={selectedType === constants.APU}
              props={this.generateProps(constants.APU)}
            />
          );
        }
        return (
          <APU
            ACId={airframeModel.id}
            IsAsset={selectedType === constants.APU}
            props={this.generateProps(constants.APU)}
          />
        );
      }
      default:
        return (
          // check if msn and enginesubseries combination exist
          <Engine
            ACId={airframeModel && airframeModel.id}
            totalEngines={airframeModel && airframeModel.numberOfEngines}
            engineNo={engines.length + 1}
            selectedType={selectedType}
            props={this.generateProps(constants.ENGINES)}
          />
        );
    }
  };

  render() {
    const {
      airframe,
      airframeChecks,
      apu,
      engines,
      selectingAssetType,
      snackOpen,
      severity,
      message,
      openFD
    } = this.state;

    return (
      <ErrorBoundary>
        <Box mt={4}>
          <VfSnackerBar
            snackOpen={snackOpen}
            setsnackOpen={this.handleSnackerBarOpen}
            severity={severity}
            message={message}
          />

          {selectingAssetType ? (
            <AssetTypeCard onAssetTypeSelect={this.handleAssetTypeSelect} />
          ) : (
            <Box>
              {this.getAssetComponentInput()}
              <Preview
                airframe={airframe}
                airframeChecks={airframeChecks != null ? airframeChecks.airframeChecks : []}
                apu={apu}
                engines={engines}
              />
            </Box>
          )}
        </Box>
      </ErrorBoundary>
    );
  }
}

export default AssetCreation;
