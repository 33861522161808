import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import DataTable from "../../../../../Common/Tables/DataTable/DataTable";
import SectionHeader from "../../../../../Common/Section/SectionHeader";
import Utilizations from "./Utilizations/Utilizations";
import { mapToViewModel_SVAssumptions } from "../../../../mapToViewModels/index";
import ShopVisitAssumptions from "./ShopVisitAssumptions/ShopVisitAssumptions";
import * as utils from "../../../../../../utils/utils";

const MaintAndUtils = ({
  assetDetails,
  handleValueChange,
  generateEditableProps,
  scenario,
}) => {
  const { svAssumptions, engineLLPScrapValues } = mapToViewModel_SVAssumptions(
    assetDetails,
    generateEditableProps,
    scenario
  );
  const [selectedEvent, setSelectedEvent] = useState(svAssumptions[0].label);
  const url = new URL(window.location.href);
  const urlValues = utils.parseUrlObject(url);

  const handleEventChange = (eventName) => {
    setSelectedEvent(eventName);

    utils.sessionStoreHelper(`mxUtilsEvents${assetDetails.id}`, eventName, 24);

    let originalUrl = url.href;
    if (urlValues.mxUtilsEvents) {
      originalUrl = url.href.replace(
        `&mxUtilsEvents=${urlValues.mxUtilsEvents}`,
        ""
      );
    }
    let newUrl = originalUrl + `&mxUtilsEvents=${eventName}`;
    window.history.replaceState({}, null, newUrl);
  };

  useEffect(() => {
    let svEvent = utils.sessionGetDataHelper(`mxUtilsEvents${assetDetails.id}`);

    if (svEvent) {
      handleEventChange(svEvent);
    } else if (urlValues.mxUtilsEvents) {
      let mxUtilsEvents = decodeURIComponent(urlValues.mxUtilsEvents);
      handleEventChange(mxUtilsEvents);
    }
  }, [assetDetails]);

  return (
    <Box>
      <ShopVisitAssumptions
        svAssumptions={svAssumptions}
        onEventChange={handleEventChange}
        selectedEvent={selectedEvent}
        generateEditableProps = {generateEditableProps}
        scenario = {scenario}
        assetDetails = {assetDetails}
      />

      <SectionHeader label={"LLP Value Assumptions"} fontSize={20} />
      <Grid container spacing={2} style={{ minWidth: "100%" }}>
        {engineLLPScrapValues.map((o, idx) => (
          <Grid key={idx} item sm={6}>
            <DataTable tableData={o} />
          </Grid>
        ))}
      </Grid>

      <Utilizations
        handleValueChange={handleValueChange}
        scenario={scenario}
        generateEditableProps={generateEditableProps}
      />
    </Box>
  );
};

export default MaintAndUtils;
