import React from "react";

const ApuSVG = ({
  width = 30,
  height = 30,
  viewBox = "0 0 30 30",
  color = "#023059",
  style = null,
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} style={{ ...style }}>
        <title>APU</title>
        <g id="Volofin-Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Modules" transform="translate(-875.000000, -278.000000)" fill="#023059" fill-rule="nonzero">
                <g id="Auxiliary-power-unit-v1" transform="translate(875.000000, 278.000000)">
                    <path d="M19.6958678,10.0603448 L16.5371901,10.0603448 L16.5371901,8.49137931 L19.6958678,8.49137931 C20.5231405,8.49137931 21.2,7.78534483 21.2,6.92241379 C21.2,6.05948276 20.5231405,5.35344828 19.6958678,5.35344828 L16.5371901,5.35344828 L16.5371901,3.39224138 C16.5371901,3 16.3867769,3 15.9355372,3 L12.1752066,3 C9.09173554,3 6.60991736,5.35344828 6.2338843,8.49137931 L6.15867769,8.49137931 C4.50413223,8.49137931 3,9.5112069 3,11.237069 L3,18.2974138 C3,20.0232759 4.50413223,21.8275862 6.15867769,21.8275862 L12.9272727,21.8275862 C13.3785124,21.8275862 13.6793388,21.5137931 13.6793388,21.0431034 C13.6793388,20.5724138 13.3785124,20.2586207 12.9272727,20.2586207 L6.15867769,20.2586207 C5.33140496,20.2586207 4.50413223,19.1603448 4.50413223,18.2974138 L4.50413223,11.237069 C4.50413223,10.3741379 5.33140496,10.0603448 6.15867769,10.0603448 L6.2338843,10.0603448 C6.60991736,13.1982759 9.16694215,15.5517241 12.1752066,15.5517241 L15.9355372,15.5517241 C16.3867769,15.5517241 16.5371901,14.8456897 16.5371901,14.375 L16.5371901,13.1982759 L19.6958678,13.1982759 C20.5231405,13.1982759 21.2,12.4922414 21.2,11.6293103 C21.2,10.7663793 20.5231405,10.0603448 19.6958678,10.0603448 Z M16.5371901,6.13793103 L19.6958678,6.13793103 C20.1471074,6.13793103 20.4479339,6.45172414 20.4479339,6.92241379 C20.4479339,7.39310345 20.1471074,7.70689655 19.6958678,7.70689655 L16.5371901,7.70689655 L16.5371901,6.13793103 Z M15.0330579,13.9827586 L12.1752066,13.9827586 C9.69338843,13.9827586 7.66280992,11.8646552 7.66280992,9.27586207 C7.66280992,6.68706897 9.69338843,4.56896552 12.1752066,4.56896552 L15.0330579,4.56896552 L15.0330579,13.9827586 Z M19.6958678,12.4137931 L16.5371901,12.4137931 L16.5371901,10.8448276 L19.6958678,10.8448276 C20.1471074,10.8448276 20.4479339,11.1586207 20.4479339,11.6293103 C20.4479339,12.1 20.1471074,12.4137931 19.6958678,12.4137931 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default ApuSVG;