import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

function generateColumns() {
  return [
    {
      label: "Serial Number",
      caption: null,
      field: "serialNumber",
      filter: true,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    {
      label: "Asset Model",
      caption: null,
      field: "assetModel",
      filter: true,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    {
      label: "Start Date",
      caption: null,
      field: "startDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "End Date",
      caption: null,
      field: "endDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "Cost",
      caption: "U.S. $",
      field: "cost",
      align: constants.RIGHT,
      filter: false,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
  ];
}

export function generateData(events, options) {
  let data = events
    ? events.map((e) => ({
        serialNumber: e.serialNumber,
        assetModel: e.assetModel,
        startDate: e.startDate,
        endDate: e.endDate,
        cost: e.cost,
      }))
    : [];

  //   data = _.sortBy(data, "date");

  const total = {
    // serialNumber: "Total",
    // component: null,
    // startDate: null,
    // endDate: null,
    // type: null,
    // date: null,
    // cost: _.sumBy(events, "mxCost"),
    // fundedBy: null,
    // sinceEvent: null,
    // hidden: null,
  };

  return [data, total];
}

export default function mapToViewModel_conversionEvents(events, options) {
  const columns = generateColumns();
  const [data, total] = generateData(events, options);

  const tableData = {
    label: "Conversion Events",
    caption: null,
    options: { filter: false, pagination: false },
    optionsItems: {},
    tableType: constants.MULTIFUNCTION_TABLE,
    columns: columns,
    data,
    // total,
  };

  return tableData;
}
