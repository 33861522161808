import React from "react";

const BookMark = ({
  prefix,
  value,
  width = 30,
  height = 30,
  color = "#023059",
}) => {
  return (
    <svg width={width} height={height} viewBox='0 -4 28 28'>
      <title>EAC428C7-1C49-4AF8-BA08-B51F4B0C214D</title>
      <g
        id='Volofin-Pages'
        stroke='none'
        strokeWidth='0.8'
        fill='None'
        fillRule='evenodd'>
        <g
          id='E.-Report-E.2.1.1.3.1-Cash-Flow-notes'
          transform='translate(-369.000000, -2464.000000)'>
          <g id='bookmark' transform='translate(369.000000, 2464.000000)'>
            <rect id='Rectangle' x='3' y='3' width='18' height='18'></rect>
            <path
              d='M19.224944,24.4142852 L11.612472,19.1399296 L4,24.4142852 L4,1.631244 C4,0.7068724 4.7068724,0 5.631244,0 L17.5937,0 C18.5180716,0 19.224944,0.7068724 19.224944,1.631244 L19.224944,24.4142852 Z M11.612472,17.8349344 L18.137448,22.3480428 L18.137448,1.631244 C18.137448,1.3049952 17.9199488,1.087496 17.5937,1.087496 L5.631244,1.087496 C5.3049952,1.087496 5.087496,1.3049952 5.087496,1.631244 L5.087496,22.3480428 L11.612472,17.8349344 Z'
              id='Shape'
              fill={color}
              fillRule='nonzero'></path>
            <text
              id='Caption'
              opacity='0.9'
              fontFamily='Roboto-Regular, Roboto'
              fontSize='9'
              fontWeight='normal'
              line-spacing='10'
              letterSpacing='0.333333338'
              fill={color}>
              <tspan
                x={
                  (value < 10 || typeof value === "string") && !prefix
                    ? "9"
                    : "6.3"
                }
                y='14.41894531'>
                {`${prefix || ""}${value}`}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BookMark;
