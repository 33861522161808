import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Tooltip,
  Popover,
  Card,
  Button,
} from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { Box, Grid, Link } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";

import AppsIcon from "@material-ui/icons/Apps";
import PersonIcon from "@material-ui/icons/Person";

import appIcon from "../../img/vectorIconDark.svg";
import assetModelLogoDark from "../../img/assetModelLogoDark.svg";
import pipelineLogoDark from "../../img/pipelineLogoDark.svg";
// import capitalMarketsLogoDark from "../../img/capitalMarketLogoDark.svg";
import riskManagerLogoDark from "../../img/riskManagerLogoDark.svg";
import { Link as RouteLink, useHistory } from "react-router-dom";
import appConfig from "../../appConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    maxHeight: "56px",
    color: "#ffffff",
    backgroundColor: "#023059",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "unset",
  },

  menuButton: {
    marginRight: 16,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    maxWidth: 1500,
    minWidth: 1024,
    display: "flex",
    width: "95%",
    padding: "0px 10px",
    margin: "auto",
    height: "56px!important",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "56px!important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  imgBox: {
    //flexGrow: 1,
    textAlign: "left",
  },
  img: {
    maxWidth: "60px",
    marginRight: "32px",
    marginTop: "10px",
  },
  active: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFFFFF",
  },
  appIcon: {
    height: "2.5em",
    width: "2.5em",
    verticalAlign: "middle",
  },
  appsMenu: {
    padding: theme.spacing(2),
  },
  appsMenuIcon: {
    height: "3em",
    width: "3em",
    margin: "5px auto",
  },
  appsTitleBarIcon: {
    height: "64px",
    width: "64px",
    position: "absolute",
    left: "48%",
    top: "25%",
    borderRadius: "0px 0px 0px 10px",
    boxShadow: "0px 3px 3px #AAA",
  },
  link: {
    color: "#FFFFFF",
    textDecoration: "none",
    cursor: "pointer",
  },
}));

const appList = [
  {
    appName: "Asset Model",
    appURL: appConfig.REACT_APP_ASSETMODEL_URL,
    appLogo: assetModelLogoDark,
  },
  {
    appName: "Pipeline System",
    appURL: appConfig.REACT_APP_PIPELINE_URL,
    appLogo: pipelineLogoDark,
  },
  {
    appName: "Risk Manager",
    appURL: appConfig.REACT_APP_RISKMANAGER_URL,
    appLogo: riskManagerLogoDark,
  },
  // {
  //   appName: "Capital Markets",
  //   appURL: appConfig.REACT_APP_CAPITALMARKETS_URL,
  //   appLogo: capitalMarketsLogoDark,
  // },
];

export default function MiniDrawer(props) {
  const classes = useStyles(props.classes);
  const [open] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [appsPopOver, setappsPopOver] = React.useState(null);

  const history = useHistory();
  const navigateTo = () => {
    history.push("/exportHistory");
    setAnchorEl(null);
  };
  const labels = [
    {
      value: ["ASSET", "MODEL"],
      content: (values) => (
        <>
          {values[0]}
          <br />
          {values[1]}
        </>
      ),
    },
    {
      value: "HOME",
      content: (value) => (
        <RouteLink to='/' className={classes.link}>
          {value}
        </RouteLink>
      ),
    },
    {
      value: "DEALS",
      content: (value) => (
        <RouteLink to='/deals' className={classes.link}>
          {value}
        </RouteLink>
      ),
    },
    {
      value: "ASSET POOLS",
      content: (value) => (
        <RouteLink to='/assetpools' className={classes.link}>
          {value}
        </RouteLink>
      ),
    },
    {
      value: "ASSETS",
      content: (value) => (
        <RouteLink to='/assets' className={classes.link}>
          {value}
        </RouteLink>
      ),
    },
    {
      value: "GENERIC",
      content: (value) => (
        <RouteLink to='/genericAssumptions' className={classes.link}>
          {value}
        </RouteLink>
      ),
    },
    {
      value: "VALUATIONS",
      content: (value) => (
        <RouteLink to='/valuations' className={classes.link}>
          {value}
        </RouteLink>
      ),
    },
  ];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAppsMenuClick = (event) => {
    setappsPopOver(event.currentTarget);
  };

  const handleAppsMenuClose = () => {
    setappsPopOver(null);
  };

  const openAppMenu = Boolean(appsPopOver);
  const id = openAppMenu ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Box display='flex' flexGrow={1}>
          <Toolbar className={classes.toolbar}>
            {/* <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          </Box> */}
            <Box flexShrink={1}>
              <Link to='/'>
                <img className={classes.appIcon} src={appIcon} alt='App Logo' />
              </Link>
            </Box>

            {labels.map((label, idx) => (
              <Box key={idx} flexShrink={1} justifyContent='flex-start' mx={2}>
                <Typography
                  variant='subtitle1'
                  noWrap
                  align='left'
                  style={{ lineHeight: "1em" }}>
                  {label.content(label.value)}
                </Typography>
              </Box>
            ))}

            <Box display='flex' flexGrow={1} justifyContent='center'>
              {/* <Box>
                <img
                  src={assetModelLogoDark}
                  className={classes.appsTitleBarIcon}
                  alt=""
                />
              </Box> */}
            </Box>

            <Box>
              <Box flexShrink={1} style={{ display: "inline-flex" }}>
                <Tooltip
                  title='Application Dashboard'
                  aria-label='Application Dashboard'>
                  <IconButton
                    aria-label='applications'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    aria-describedby={id}
                    onClick={handleAppsMenuClick}
                    size={"medium"}
                    color='inherit'
                    tooltip='Application Dashboard'>
                    <AppsIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={openAppMenu}
                  anchorEl={appsPopOver}
                  onClose={handleAppsMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}>
                  <Card className={classes.appsMenu}>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "0.5em" }}>
                      {appList.map((app, index) => (
                        <Grid
                          item
                          xs
                          key={index}
                          >
                          <Link
                            href={app.appURL || "/"}
                            target='_blank'
                            underline='none'>
                            <Box display='flex' flexGrow={1}>
                              <img
                                src={app.appLogo}
                                className={classes.appsMenuIcon}
                                alt=''
                              />
                            </Box>
                            <Typography variant='caption' noWrap>
                              {app.appName}
                            </Typography>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>

                    <Button
                      color='primary'
                      fullWidth={true}
                      variant='contained'
                      size='small'
                      href={appConfig.REACT_APP_VFVECTOR_URL}>
                      View all Apps
                    </Button>
                  </Card>
                </Popover>
              </Box>

              <Box flexShrink={1} style={{ display: "inline-flex" }}>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                  size={"medium"}>
                  <PersonIcon />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openMenu}
                  onClose={handleClose}>
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={navigateTo}>Export History</MenuItem>
                  <MenuItem onClick={props.handleSignOut}>Sign Out</MenuItem>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </div>
  );
}
