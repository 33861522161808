import _ from "lodash";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import VfSelect from "../../../Common/UtilComponents/VfSelect";
import moment from "moment";
import React from "react";

export function mapToViewModel_appraisalSummary(asset, props, appraisalOptions) {

  let data = asset?.valuation;
  let additionaldata = asset?.additionalValuations;
  // if(props.section === "Appraisal Summary"){
  //   console.log(asset);
  //   console.log(data);
  //   console.log(props);
  // }
  let items = [];
  let dateItems = [];
  if(data?.appraiser != null){
    items.push({
      label: data.appraiser,
      value: data.appraiser
    });
    dateItems.push({
      label: moment.utc(data.valuationDate).format(constants.DATE_FORMAT),
      value: data.id
    })
  }
  if(additionaldata != null && additionaldata.length > 0){
    additionaldata.forEach(a => {
      if(a.appraiser === data.appraiser){
        dateItems.push({
          label: moment.utc(a.valuationDate).format(constants.DATE_FORMAT),
          value: a.id
        });
      }
      if(items.some(x=> x.label === a.appraiser))
        return;
      items.push({
        label: a.appraiser,
        value: a.appraiser
      });
    });
  }
  //if()
  var columns = [
    {
      label: ["CBV - HALFLIFE"],
      field: "cbvHalflife",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
    },
    {
      label: ["CMV - HALFLIFE"],
      field: "cmvHalflife",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
    },
    {
      label: ["CBV - FULLLIFE"],
      field: "cbvfulllife",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
    },
    {
      label: ["CMV - FULLLIFE"],
      field: "cmvfulllife",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
    },
    {
      label: ["Market Lease Rate"],
      field: "mlr",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(constants.PERCENTAGE, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
    },
    {
      label: ["Specs"],
      field: "specs",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.TEXT,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(constants.TEXT_CELL, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL,null),
    },
  ];
  if(props.section === "Appraisal Summary")
  {
    columns.unshift(
    {
      label: [constants.APPRAISER],
      field: "appraiser",
      captions: null,
      span: [3, 3],
      content: (_) => {
        return (
          <VfSelect
            items={items}
            fontSize={14}
            value={data?.appraiser != null ? data?.appraiser : constants.ASCEND}
            type={constants.APPRAISER}
            onSelectChange={(type, value) => handleSelectChange(type, value)}
            minWidth={130}
            padding={"0 0 20px 0"}
          />
        );
      },
    },
    {
      label: ["APPRAISAL DATE"],
      field: "appraisalDate",
      captions: null,
      span: [3, 3],
      content: (_) => {
        return (
          <VfSelect
            items={dateItems}
            fontSize={14}
            value={dateItems[0]?.value}
            type={"Appraisal Date"}
            onSelectChange={(type, value) => handleSelectDateChange(type, value)}
            minWidth={200}
            padding={"0 0 20px 0"}
          />
        );
      },
    }
  );
  }
  else {
    data = asset
    //columns.unshift(),
    columns.unshift(
      {
        label: ["APPRAISER"],
        field: "appraiser",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["APPRAISAL DATE"],
        field: "appraisalDate",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
      }
    )
  }
  if(data?.appraiser != null && data.appraiser === constants.IBA){
    columns.splice(4,0,
      {
        label: ["CSV - HALFLIFE"],
        field: "csvHalflife",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      });
    columns.splice(5,0,
      {
        label: ["CSV - FULLLIFE"],
        field: "csvfulllife",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      });
  }
  const handleSelectChange = (type,value) =>{
    props.funcs.handleValueChange("swapAppraisal")(props.section, props.sectionIdx, "Appraisal Summary", value);
  }
  const handleSelectDateChange = (type,value) =>{
    props.funcs.handleValueChange("swapAppraisalDate")(props.section, props.sectionIdx, "Appraisal Summary", value);
  }
  return {
    label: props.section,
    showOptions: true,
    tableThreeDotBtnOptions: appraisalOptions,
    errorsOnSubmit: {},
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: "Appraisal Summary",
    columns: columns,
    data: data
      ? {
          appraiser: data.appraiser != null ? data.appraiser : constants.ASCEND,
          appraisalDate: moment
            .utc(data.valuationDate)
            .format(constants.DATE_FORMAT),
          cbvHalflife: _.round(data.halfLife_Base * Math.pow(10, 6)),
          cmvHalflife: _.round(data.halfLife_Market * Math.pow(10, 6)),
          csvHalflife: _.round(data.halfLife_Soft * Math.pow(10, 6)),
          cbvfulllife: _.round(data.fullLife_Base * Math.pow(10, 6)),
          cmvfulllife: _.round(data.fullLife_Market * Math.pow(10, 6)),
          csvfulllife: _.round(data.fullLife_Soft * Math.pow(10, 6)),
          mlr: _.round(data.monthlyLeaseRate_Market * Math.pow(10, 6)),
          specs: data.specAdjusters,
          //blr: _.round(data.monthlyLeaseRate_Base * Math.pow(10, 6)),
        }
      : {},
  };
}
