import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Box, makeStyles } from "@material-ui/core";
import TopNavBar from "../Components/TopNavBar";
import Login from "../Pages/Login";
import PrivateRoute from "./PrivateRoute";
import routes from "../utils/routes";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight: "34px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    height: "100vh",
  },
}));

const AppRoutes = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleSignOut = () => instance.logout();

  return (
    <BrowserRouter>
      {/* TODO: Consider rafactoring how the TopNavBar component and content wrapper Box is applied. */}
      {isAuthenticated && (
        <TopNavBar classes={classes} handleSignOut={handleSignOut} />
      )}
      <Box className={isAuthenticated ? classes.content : ""}>
        <div
          className={isAuthenticated ? classes.toolbar : ""}
          style={{ minHeight: isAuthenticated ? "34px" : "" }}
        />

        {routes.map((route, idx) => (
          <PrivateRoute
            key={idx}
            exact
            path={route.path}
            component={route.component}
          />
        ))}
      </Box>
      <Route exact path='/login'>
        <Login />
      </Route>
    </BrowserRouter>
  );
};

export default AppRoutes;
