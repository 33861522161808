import * as constants from "../../../../constants";
import moment from "moment";

export const updateScenarioScheduledShopvisits = (
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario
) => {
  const template = { component:"", scheduledVisitOverrides: [] };
  let componentName = sectionName;
  newData.endDate = moment
    .utc(newData.startDate)
    .add(parseInt(newData.daysDown), "days")
    .endOf("day")
    .toISOString();
  //get the real component name from asset details from the section name
  // if its EPR, ELLP, EngineOther, LG need to change the component name

  let esns = assetDetails.engines.map((e) => e.serialNumber);
  let positions = assetDetails.engines.map((e) => e.position);
  if(esns.some(s => sectionName.includes(s))){
    //find the engine position of said engine
    let esn = esns.filter(e => sectionName.includes(e) )
    if(esn.length >= 1){
        esn = esn[0];
    }
    let ind = esns.indexOf(esn);
    let position = positions[ind];
    //EPR
    if(sectionName.includes("EPR -")){
        componentName = "EPR "+position;
    }
    //ELLP
    else if (sectionName.includes("ELLP -")){
        componentName = "LLPStack "+position;
    }
    //OTHER
    else{
        componentName = sectionName.slice(0,sectionName.indexOf(" -")) + " " +position;
    }
  }
  if(sectionName.includes("LG")){
    let lgs = assetDetails.landingGears;
    let lgnames = lgs.map((l) => l.position);
    let sub = sectionName.replace("LG - ", "");
    componentName =  lgnames[lgnames.findIndex(e=> e.includes(sub))];
  }
  
  let targets = scenario.scenarioShopvisits.filter(x=>x.component == componentName)
  let target = null;
  if(targets.length > 0){
    target = targets[0];
  } 
  newData.event = componentName;
 
  // console.log(actionType);
  // console.log(newData);
  //IF SWAP  = TRUE AND swapinfo == null 
    //ADD IT
  //IF SWAP != TRUE BUT greenTimeFlightCycles != null
    // UPDATE SWAPINFO WITH NEW DATA
  // ELSE (swap = false)
    // DELETE SWAP INFO 

  if (actionType === constants.ADD_ROW)
  {
    //exists already, just add to it
    if(target)
        target.scheduledVisitOverrides.push(newData);
    else
        scenario.scenarioShopvisits.push({component:componentName, scheduledVisitOverrides: [newData]})
  }
  else if( actionType === constants.DELETE_ROW){
    //deleting a swap
    if(newData.greenTimeFlightCycles){
      target.scheduledVisitOverrides[newData.id].swapInfo = null;
      target.scheduledVisitOverrides[newData.id].swap = false;
    }
    //deleting a visit
    else {
      let removed = target.scheduledVisitOverrides.filter((row, idx) => idx !== newData.tableData.id);
      target.scheduledVisitOverrides = removed;
    }
  }
  //edit
  else{
    //adding swap to visit without one
    if(newData.swap && !newData.swapInfo){
      //console.log("add new swap");
      target.scheduledVisitOverrides[newData.id].swapInfo = {};
    }
    //editing swap that already exists
    else if (newData.greenTimeFlightCycles && newData.swap == null){
      //console.log("swap exists edit");
      target.scheduledVisitOverrides[newData.id].swapInfo = newData;

    }
    //its just a regular shop visit
    else 
    {
      //remove swapinfo if swap changed to no
      if(target.scheduledVisitOverrides[newData.id] && newData.swap == false)
      {
        //console.log("remove swap due to flag swap")
        target.scheduledVisitOverrides[newData.id].swapInfo = null;
      }
      target.scheduledVisitOverrides[newData.id] = newData;
    }
  }
    


  scenario.scenarioShopvisits = [...scenario.scenarioShopvisits];

  return scenario;
};