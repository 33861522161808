import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import DataTable from "../../Common/Tables/DataTable/DataTable";
import MultiFunctionTable from "../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import * as constants from "../../../constants";
import { mapToViewModel } from "../mapToViewModels/index"

const formatData = (genericData, generateEditableProps, selectedSubseries, handleSelectChange) => {
  const componentType = constants.Engine;
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModel(
      genericData,
      componentType,
      handleSelectChange,
      props,
      selectedSubseries,
    ),
    componentType,
  };
};

const GenericEngine = ({ genericData, generateEditableProps, urlValues, handleEngSubseriesChange }) => {
  const sortedEngineData = _.sortBy(genericData.engines, [function(o) { return o.subseries; }]);
  const [selectedSubseries, setSelectedSubseries] = useState(sortedEngineData[0].subseries)
  
  useEffect(() => {
    handleEngSubseriesChange(sortedEngineData[0].subseries)
    if (urlValues.engSelect) setSelectedSubseries( decodeURI(urlValues.engSelect) )
  }, []);

  const handleSelectChange = (type, value) => {
    let newUrl = `?tab=1&engSelect=${value}`
    window.history.pushState({}, null, newUrl);
    setSelectedSubseries(value)
    handleEngSubseriesChange(value)
  }

  const engineData = formatData(sortedEngineData, generateEditableProps, selectedSubseries, handleSelectChange);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DataTable tableData={engineData.data.engineDetails} />
        </Grid>
        <Grid item sm={12}>
          <DataTable tableData={engineData.data.firstRun} />
        </Grid>
        <Grid item sm={12}>
          <DataTable tableData={engineData.data.matureRun} />
        </Grid>
        <Grid item sm={12}>
          <MultiFunctionTable tableData={engineData.data.llpStack} containerMaxHeight={"100%"}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenericEngine;
