import _ from "lodash";
import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import Root from "../../Hoc/Root";

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    color: "#023059",
    fontWeight: 600,
    fontSize: 18,
  },
  section: {
    paddingTop: 4,
    paddingBottom: 4,
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
  sectionSelected: {
    borderWidth: "0 0 0 8px",
    borderStyle: "solid",
    borderColor: "#023059",
    backgroundColor: "#E5EAEE",
    paddingLeft: 8,
    borderTopLeftRadius: 8,
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: "#023059",
    opacity: 0.5,
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4,
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
  caption: { fontSize: 10 },
  selected: {
    fontWeight: 600,
    color: "#023059",
  },
}));

const SideNavBarListSectionItem = ({
  label,
  labelIcon,
  listItems,
  listItemIcons,
  subListItems,
  defaultOpen,
  selected,
  onSelectedChange,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(defaultOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleItemClick = (value) => {
    onSelectedChange(value);
  };

  return (
    <Root>
      <ListItem
        className={
          _.findIndex(listItems, (o) => o.label === selected.label) >= 0
            ? `${classes.section} ${classes.sectionSelected}`
            : classes.section
        }
        button
        onClick={handleClick}
      >
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            className: classes.sectionLabel,
          }}
        />
        {open ? (
          <ExpandLess className={classes.sectionLabel} />
        ) : (
          <ExpandMore className={classes.sectionLabel} />
        )}
      </ListItem>
      {listItems ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {listItems.map((listItem, idx) => (
            <List key={idx} component="div" disablePadding>
              {subListItems && subListItems.length && subListItems[idx] ? (
                <SideNavBarListSectionItem
                  label={listItem}
                  labelIcon={listItemIcons[idx]}
                  listItems={subListItems[idx]}
                  listItemIcons={[]}
                  defaultOpen={false}
                  selected={selected}
                  onSelectedChange={onSelectedChange}
                />
              ) : (
                <ListItem
                  button
                  className={classes.listItem}
                  onClick={() => handleItemClick(listItem)}
                >
                  {listItemIcons && listItemIcons[idx] ? (
                    <ListItemIcon>{listItemIcons[idx]}</ListItemIcon>
                  ) : null}
                  <ListItemText
                    primary={
                      <span>
                        {listItem.label} &nbsp;
                        <span className={classes.caption}>
                          {listItem.caption}
                        </span>
                      </span>
                    }
                    classes={{
                      primary: _.isEqual(selected, listItem)
                        ? classes.selected
                        : classes.text,
                    }}
                  />
                </ListItem>
              )}
            </List>
          ))}
        </Collapse>
      ) : null}
    </Root>
  );
};

export default SideNavBarListSectionItem;
