import _ from "lodash";
import React from "react";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { mapToViewModel_LLPStatus } from "./llpStatusViewModel";
import ThreeDotButton from "../../../../Common/ThreeDotButton";

function generateDate(comps) {
  let data = comps.map((c) => ({
    componentName: [c.componentName, c.serialNumber],
    lastMajorMxEventDate: c.lastMajorMxEventDate,
    nextMajorMxEventDate: c.nextMajorMxEventDate,
    nextMajorMxEventCost: _.round(c.nextMajorMxEventCost ),
    valueAboveHalfLife: _.round(c.valueAboveHalfLife ),
    sinceLastEvent: c.componentName.includes("LLP")
      ? [
          {
            label: "FC C.",
            value: null,
          },
          {
            label: "FC",
            value: utils.ifValueExist(c.toEvent_FC, true)
              ? c.fromEvent_FC
              : null,
          },
        ]
      : c.name === constants.APU_OVERHAUL
      ? [
          {
            label: "APUH",
            value: utils.ifValueExist(c.toEvent_AH, true)
              ? c.fromEvent_AH
              : null,
          },
        ]
      : [
          {
            label: "FH",
            value: utils.ifValueExist(c.toEvent_FH, true)
              ? c.fromEvent_FH
              : null,
          },
          {
            label: "FC",
            value: utils.ifValueExist(c.toEvent_FC, true)
              ? c.fromEvent_FC
              : null,
          },
          {
            label: "MO",
            value: utils.ifValueExist(c.toEvent_Day, true)
              ? _.round(c.fromEvent_Day / 30.44)
              : null,
          },
        ],
    toNextEvent: c.componentName.includes("LLP")
      ? [
          { label: "FC C.", value: _.round(c.toEvent_FC_Core) },
          { label: "FC", value: _.round(c.toEvent_FC) },
        ]
      : [
          { label: "FH", value: _.round(c.toEvent_FH) },
          { label: "FC", value: _.round(c.toEvent_FC) },
          { label: "MO", value: _.round(c.toEvent_MO) },
        ],
    lifePctBar: _.round(c.lifePct * 100),
    interval: [
      {
        label: "FH",
        value:
          c.fromEvent_FH && c.toEvent_FH ? c.fromEvent_FH + c.toEvent_FH : null,
      },
      {
        label: "FC",
        value:
          c.fromEvent_FC && c.toEvent_FC ? c.fromEvent_FC + c.toEvent_FC : null,
      },
      {
        label: "MO",
        value:
          c.fromEvent_MO && c.toEvent_MO ? c.fromEvent_MO + c.toEvent_MO : null,
      },
    ],
    hidden: c.llpStatus.length ? mapToViewModel_LLPStatus(c.llpStatus) : null,
  }));

  let total = {
    componentName: "Total",
    lastMajorMxEventDate: null,
    nextMajorMxEventDate: null,
    nextMajorMxEventCost: _.round(
      _.sumBy(comps, "nextMajorMxEventCost") 
    ),
    valueAboveHalfLife: _.round(_.sumBy(comps, "valueAboveHalfLife") ),
    sinceLastEvent: [],
    toNextEvent: [],
    // lifePctBar: null,
    interval: [],
    hidden: null,
  };
  return [data, total];
}

export default function mapToViewModel_ComponentStatus(asOfDate, comps, options) {
  let getData = null;
  let data = [];
  let total = {};
  if (comps) {
    getData = generateDate(comps);
    data = getData[0]
    total = getData[1]
  }

  let months = [];
  let years = [];
  for (let i = 1; i < 13; i++) {
    let monthLabel = String(i);
    if(i< 10) monthLabel = "0"+String(i) 
    months.push({
      label: monthLabel,
      value: i,
      // props: { handleClick: (i) => console.log(i) }
    });
  }

  for (let i = 2020; i < 2030; i++) {
    years.push({
      label: String(i),
      value: i,
      // props: { handleClick: (i) => console.log(i) }
    });
  }

  return {
    label: "Component Status",
    caption: `As of ${asOfDate}`,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { 
      pagination: false, 
      refresh: false, 
      threeDotButton: true,
    },
    optionsItems: {
      pagination: {
        page: 0,
        rowsPerPage: 10,
        tableName: "Component Status",
        handleChangePage: null,
        handleChangeRowsPerPage: null,
      },
      threeDotButton: {
        items: [
          {
            id: 0,
            label: "Change Status Date",
            handleClick: options.openChangeStatusDate,
          },
          {
            id: 1,
            label: "Download Current Status",
            handleClick: options.handleCompStatusExport,
          }
        ],
        content: (options) => <ThreeDotButton options={options} />,
      },
    },
    span: [12, 12, 12],
    columns: [
      {
        label: "Name",
        caption: null,
        field: "componentName",
        align: constants.LEFT,
        indent: 16,
        contentType: constants.BUTTON,
        content: (isButton, value, handleClick, indent) => {
          if (isButton === "Total") return "Total"
          else return utils.renderEventName(isButton, value, handleClick, indent)
        },
      },

      {
        label: (
          <span>
            Mx Event <br /> Previous
          </span>
        ),
        caption: null,
        field: "lastMajorMxEventDate",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.DATE_CELL
          ),
      },
      {
        label: (
          <span>
            Mx Event <br /> Next
          </span>
        ),
        caption: null,
        field: "nextMajorMxEventDate",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.DATE_CELL
          ),
      },
      {
        label: (
          <span>
            Mx Cost <br /> Next Event
          </span>
        ),
        caption: "U.S. $",
        field: "nextMajorMxEventCost",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL,
            "$",
            0,
            false
          ),
      },
      {
        label: "Since Last Event",
        caption: null,
        field: "sinceLastEvent",
        align: constants.RIGHT,
        indent: 16,
        content: (items, indent) =>
          utils.renderMultiValueCellValue(items, indent, 100, null, true),
      },
      {
        label: "To Next Event",
        caption: null,
        field: "toNextEvent",
        align: constants.RIGHT,
        indent: 16,
        content: (items, indent) =>
          utils.renderMultiValueCellValue(items, indent, 100),
      },
      {
        label: "Life Left %",
        caption: null,
        field: "lifePctBar",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) => utils.renderLifePctBar(value, indent),
      },
      {
        label: <span>Value to H.L.</span>,
        caption: "U.S. $",
        field: "valueAboveHalfLife",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL,
            "$"
          ),
      },
    ],
    data,
    total,
  };
}
