import _, { forEach } from "lodash";
import * as constants from "../../../../constants";

function getTemplate() {
  return {
    isCashReserve: true,
    rateType: null,
    rateInterval: {
      activeHour: null,
      day: null,
      flightCycle: null,
      flightHour: null,
      monthly: null,
      escalationRate: null,
    },
    rateDate: null,
  };
}

export const updateData = (
  actionType,
  leaseIdx,
  newData,
  scenario,
  componentName
) => {
  scenario.leases = [...scenario.leases];
  scenario.leases[leaseIdx] = { ...scenario.leases[leaseIdx] };
  scenario.leases[leaseIdx].componentLeaseInfo = [
    ...scenario.leases[leaseIdx].componentLeaseInfo,
  ];
  const targetLeaseInfoIdx = _.findIndex(
    scenario.leases[leaseIdx].componentLeaseInfo,
    (o) => o.componentName === componentName
  );

  const targetLeaseInfo = {
    ...scenario.leases[leaseIdx].componentLeaseInfo[targetLeaseInfoIdx],
  };

  let maintenanceReserveRates = [...targetLeaseInfo.maintenanceReserveRates];
  const keys = ["activeHour", "flightHour", "flightCycle", "monthly", "day"];

  if (actionType === constants.DELETE_ROW) {
    maintenanceReserveRates.splice(newData.id, 1);
  } else if (actionType === constants.ADD_ROW) {
    // maintenanceReserveRates = [];
    const template = getTemplate();
    template.isCashReserve = newData.reserveType === "Cash";
    template.rateDate = newData.rateEffectiveDate;
    template.rateType = newData.reserveType;
    template.rateInterval.escalationRate = parseFloat(newData.annualEscRate);
    for (let key in newData) {
      if (keys.includes(key) && newData[key]) {
        template.rateInterval[key] = parseFloat(newData[key]);
      }
    }
    maintenanceReserveRates.push(template);
  } else {
    // console.log(newData);
    // console.log(maintenanceReserveRates);
    // console.log(targetLeaseInfo);
    // console.log(scenario.leases[leaseIdx].componentLeaseInfo);
    if(newData.mxEvent === newData.eventOrigin.value){
      const mxReservRate = maintenanceReserveRates[newData.id];
      mxReservRate.isCashReserve = newData.reserveType === constants.CashReserve;
      mxReservRate.rateDate = newData.rateEffectiveDate;
      mxReservRate.rateType = newData.reserveType;
      mxReservRate.rateInterval.escalationRate = parseFloat(
        newData.annualEscRate
      );

      keys.forEach((key) => {
        if (newData[key]) {
          mxReservRate.rateInterval[key] = parseFloat(newData[key]);
        } else mxReservRate.rateInterval[key] = null;
      });
    }// end if
    else{
      //remove old, add to new
      const oldtargetLeaseInfoIdx = _.findIndex(
        scenario.leases[leaseIdx].componentLeaseInfo,
        (o) => o.componentName === newData.eventOrigin.value
      );
      const oldtargetLeaseInfo = {
        ...scenario.leases[leaseIdx].componentLeaseInfo[oldtargetLeaseInfoIdx],
      };
      let oldmaintenanceReserveRates = oldtargetLeaseInfo.maintenanceReserveRates;
      oldmaintenanceReserveRates.splice(newData.id, 1);
      //add to new
      const template = getTemplate();
      template.isCashReserve = newData.reserveType === "Cash";
      template.rateDate = newData.rateEffectiveDate;
      template.rateType = newData.reserveType;
      template.rateInterval.escalationRate = parseFloat(newData.annualEscRate);
      for (let key in newData) {
        if (keys.includes(key) && newData[key]) {
          template.rateInterval[key] = parseFloat(newData[key]);
        }
      }
      maintenanceReserveRates.push(template);
    }// end else

  }

  targetLeaseInfo.maintenanceReserveRates = maintenanceReserveRates;
  scenario.leases[leaseIdx].componentLeaseInfo[targetLeaseInfoIdx] =
    targetLeaseInfo;

  return scenario;
};
