import _ from "lodash";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { AddBox, Edit, Visibility, DeleteOutline } from "@material-ui/icons";
import DataTable from "../../../../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../../../../Common/Tables/EditableTable/EditableTable";
import * as constants from "../../../../../../../constants";
import ThreeDotButton from "../../../../../../Common/ThreeDotButton";
import TableNamePanel from "../../../../../../Common/TableNamePanel";

const useStyles = makeStyles((theme) => ({
  rectangle13: {
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "0 16px 16px 16px",
    marginBottom: 20,
    borderRadius: 12,
  },
}));

const ReserveCaps = ({reserveCaps, idx, leaseIndex, handleValueChange}) => {
  const classes = useStyles();
  const [isAccountCapEditing, setIsAccountCapEditing] = useState(true);

  const reserveAccountCapsProps = (idx) => { 
    return _.compact([
    {
      id: 0,
      label: !isAccountCapEditing ? constants.VIEW : constants.EDIT,
      icon: !isAccountCapEditing ? Visibility : Edit,
      handleClick: () => setIsAccountCapEditing(!isAccountCapEditing),
    },
    // For adding a row of account cap to a specific section
    !isAccountCapEditing ? {
      id: 1,
      label: `${constants.ADD} Row`,
      icon: AddBox,
      handleClick: () =>
        handleValueChange(constants.ADD_ROW)(
          constants.LEASES,
          leaseIndex,
          constants.cashReserveCap,
          idx,
        ),
    } : null,
    // For deleting the entire section
    !isAccountCapEditing ? {
      id: 2,
      label: `${constants.DELETE} Cap`,
      icon: DeleteOutline,
      handleClick: () =>
        handleValueChange(constants.DELETE_ROW)(
          constants.SCENARIO,
          leaseIndex,
          "Account Caps",
          idx
        ),
      } : null,
    ]);
  }

  const renderTable = (data, span = [], accountcaps=false, setDisplayStlye = true) => {
    const display = { display: "grid" };

    const table =
      data.tableType === constants.DATA_TABLE ? (
        <DataTable tableData={data} />
      ) : (
        accountcaps ? <EditableTable table={data} isEditingSection={isAccountCapEditing} />
        :  <EditableTable table={data} />
        );
    return (
      <Grid
        style={setDisplayStlye ? display : {}}
        item
        sm={span[0]}
        lg={span[1]}
      >
        {table}
      </Grid>
    );
  };

  return (
    <Box className={classes.rectangle13}>
      <Box sm={2}>
        <TableNamePanel
          label={`Cash Reserve Cap ${idx + 1}`}
          buttons={[
            <ThreeDotButton fontSize={"medium"} 
            options={reserveAccountCapsProps(idx)} />,
          ]}
        />
      </Box>
      <Box>
        <Grid container spacing={2}>
          {renderTable(reserveCaps.reserveAccountCaps, [6, 6], true)}
          {renderTable(reserveCaps.accounts, [6, 6])}
        </Grid>
      </Box>
    </Box>
  )
}

export default ReserveCaps;