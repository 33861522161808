import React from "react";
import _ from "lodash";
import mapToViewModel_LLPReplacements from "./llpReplacementsViewModel";
import TableFilter from "./../../../../Common/Tables/MultiFunctionTable/Components/TableFilter";

import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

import BookMark from "./../../../../Common/SvgComponents/BookMark";
import { Box } from "@material-ui/core";

function generateColumns() {
  return [
    {
      label: "Serial Number",
      caption: null,
      field: "serialNumber",
      filter: true,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    {
      label: "Event",
      caption: null,
      field: "name",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      contentType: constants.BUTTON,
      clickableCellIcon: (value) => <BookMark value={""} color={"#64b5f6"} />,
      content: (isButton, value, handleClick, indent) => (
        <Box
          style={{
            width: "100%",
          }}>
          {utils.renderEventName(isButton, value, handleClick, indent)}
        </Box>
      ),
    },
    {
      label: "Component Type",
      caption: null,
      field: "type",
      hidden: true,
      filter: true,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    {
      label: "Redelivery Date",
      caption: null,
      field: "redeliveryDate",
      filter: true,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: (
        <span>
          EOL Comp <br /> Positive
        </span>
      ),
      caption: "U.S. $",
      field: "lesseeToLessor",
      filter: false,
      align: "right",
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: (
        <span>
          EOL Comp <br /> Negative
        </span>
      ),
      caption: "U.S. $",
      field: "lessorToLessee",
      filter: false,
      align: "right",
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: "Reserve SetOff",
      caption: "U.S. $",
      field: "cashReserveToLessee",
      filter: false,
      align: "right",
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: (
        <span>
          EOL Comp <br /> Net
        </span>
      ),
      caption: "U.S. $",
      field: "eolNet",
      filter: false,
      align: "right",
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: "Since Last Event",
      caption: null,
      field: "sinceLastEvent",
      filter: false,
      align: "right",
      indent: 16,
      content: (items, indent) =>
        utils.renderMultiValueCellValue(items, indent, 100, null, true),
    },
    {
      label: "To Next Event",
      caption: null,
      field: "toNextEvent",
      filter: false,
      align: "right",
      indent: 16,
      content: (items, indent) =>
        utils.renderMultiValueCellValue(items, indent),
    },
    {
      label: "Life Left %",
      caption: null,
      field: "lifePctBar",
      filter: false,
      align: "right",
      indent: 16,
      content: (value, indent) =>
        utils.ifValueExist(value, true) &&
        utils.renderLifePctBar(value, indent),
    },
    {
      label: "Value to H.L.",
      caption: "U.S. $",
      field: "hlAdj",
      filter: false,
      align: "right",
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
  ];
}

function formatData(events, options) {
  let data = events.map((e) => ({
    serialNumber: e.serialNumber,
    name: [e.name, e.componentSerialNumber],
    type: e.type,
    redeliveryDate: e.redeliveryDate,
    lesseeToLessor: _.round(e.lesseeToLessor),
    lessorToLessee: _.round(e.lessorToLessee),
    cashReserveToLessee: _.round(e.cashReserveToLessee),
    eolNet: _.round(
      e.lesseeToLessor + e.lessorToLessee + e.cashReserveToLessee
    ),
    sinceLastEvent: e.name.includes("LLP")
      ? [
          {
            label: "FC C.",
            value: null,
          },
          {
            label: "FC",
            value: utils.ifValueExist(e.toEvent_FC, true)
              ? _.round(e.sinceEvent_FC)
              : null,
          },
        ]
      : [
          {
            label: "FH",
            value: utils.ifValueExist(e.toEvent_FH, true)
              ? _.round(e.sinceEvent_FH)
              : null,
          },
          {
            label: "FC",
            value: utils.ifValueExist(e.toEvent_FC, true)
              ? _.round(e.sinceEvent_FC)
              : null,
          },
          {
            label: "MO",
            value: utils.ifValueExist(e.toEvent_Day, true)
              ? _.round(e.sinceEvent_Day / 30.44)
              : null,
          },
          {
            label: "APUH",
            value:
              e.name === constants.APU_OVERHAUL &&
              utils.ifValueExist(e.toEvent_AH, true)
                ? _.round(e.sinceEvent_AH)
                : null,
          },
        ],
    toNextEvent: e.name.includes("LLP")
      ? [
          {
            label: "FC C.",
            value: e.toEvent_FC_Core && _.round(e.toEvent_FC_Core),
          },
          {
            label: "FC",
            value: e.toEvent_FC && _.round(e.toEvent_FC),
          },
        ]
      : e.name === constants.APU_OVERHAUL
      ? [
          {
            label: "APUH",
            value: e.name === constants.APU_OVERHAUL && _.round(e.toEvent_AH),
          },
        ]
      : [
          {
            label: "FH",
            value: e.toEvent_FH && _.round(e.toEvent_FH),
          },
          {
            label: "FC",
            value: e.toEvent_FC && _.round(e.toEvent_FC),
          },
          {
            label: "MO",
            value: e.toEvent_FH && _.round(e.toEvent_Day / 30.44),
          },
        ],
    lifePctBar: _.round(e.lifePct * 100),
    hlAdj: _.round(e.hlAdj),
    hidden:
      e.llpReplacements && e.llpReplacements.length
        ? mapToViewModel_LLPReplacements(e.llpReplacements, options)
        : null,
  }));

  data = _.sortBy(data, ["redeliveryDate"]);

  const eolNetValues = events.map(
    (e) => e.lesseeToLessor + e.lessorToLessee + e.cashReserveToLessee
  );

  const total = {
    serialNumber: "Total",
    name: [],
    redeliveryDate: "",
    lesseeToLessor: _.sumBy(events, "lesseeToLessor"),
    lessorToLessee: _.sumBy(events, "lessorToLessee"),
    cashReserveToLessee: _.sumBy(events, "cashReserveToLessee"),
    cashReserveToLessor: _.sumBy(events, "cashReserveToLessor"),
    hlAdj: _.sumBy(events, "hlAdj"),
    eolNet: _.sum(eolNetValues),
    sinceLastEvent: [],
    toNextEvent: [],
    lifePctBar: null,
    hidden: null,
    style: {
      // bgColor: "#FFFFFF",
      bgColor: "rgba(0, 0, 0, 0.38)",
      color: "#000000",
      fontWeight: 800,
    },
  };

  return [data, total];
}

export default function mapToViewModel_redeliveryEvents(events, options) {
  const columns = generateColumns();
  const [data, total] = formatData(events, options);

  return {
    label: constants.REDELIVERY_EVENTS,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: true, pagination: true },
    optionsItems: {
      filter: {
        tableName: constants.redeliveryEvents,
        filterData: utils.generateFilterData(columns, events),
        filterList: utils.generateFilterData(columns, events),
        handleFilterUpdate: options.filter.handleFilterUpdate,
        handleFilterReset: options.filter.handleFilterReset,
        content: (columns, items) => (
          <TableFilter
            columns={columns}
            tableName={items.tableName}
            filterData={items.filterData}
            filterList={items.filterList}
            onFilterUpdate={items.handleFilterUpdate}
            onFilterReset={items.handleFilterReset}
          />
        ),
      },
      pagination: {
        page: 0,
        rowsPerPage: 10,
        tableName: constants.redeliveryEvents,
        handleChangePage: options.pagination.handleChangePage,
        handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
      },
    },
    span: [12, 12, 12],
    columns: columns,
    data,
    total,
  };
}
