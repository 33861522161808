import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const uploadMxEvent = async (compType, compId, newdata, msalContext) => {
  let apiendpoint = apiRoutes.PostMaintenanceEvents;
  apiendpoint = apiendpoint({ compType, compId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newdata,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    newdata = resp.data;
  }
};

export const updateData = (actionType, assetDetails, newData, msalContext) => {
  assetDetails.airframeChecks = [...assetDetails.airframeChecks];
  const idx = _.findIndex(
    assetDetails.airframeChecks,
    (o) => o.checkName === newData.event
  );

  let table = [...assetDetails.airframeChecks[idx].maintenanceEvents];
  newData.endDate = moment
    .utc(newData.startDate)
    .add(newData.daysDown, "days")
    .toISOString()
    .split(".")[0];
  if (actionType === constants.ADD_ROW) {
    table.push(newData);
  } else if (actionType === constants.UPDATE_ROW) {
    table[newData.id] = { ...table[newData.id] };
    table[newData.id] = newData;
  } else {
    table = table.filter((row, idx) => idx !== newData.id);
  }

  uploadMxEvent(
    assetDetails.airframeChecks[idx].componentType,
    assetDetails.airframeChecks[idx].componentId,
    table,
    msalContext
  );
  assetDetails.airframeChecks[idx].maintenanceEvents = table;
  return assetDetails;
};
