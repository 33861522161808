import * as constants from "../../../../constants";

export const updateEOLCompCaps = (
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario
) => {
  const template = { eoLcompFloor: null, eoLcompCap: null};

  scenario.leases = [...scenario.leases];

  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };

  scenario.leases[sectionIdx].eoLcompCap = newData.eoLcompCap;
  scenario.leases[sectionIdx].eoLcompFloor = newData.eoLcompFloor;

  return scenario;
};