import React, { useState } from "react";

import { Grid, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as Loan } from "../../../../../assets/images/others/LoanButton.svg";
import { ReactComponent as LoanFilled } from "../../../../../assets/images/others/LoanButtonFilled.svg";
import { ReactComponent as FullRecourse } from "../../../../../assets/images/others/FullRecourse.svg";
import { ReactComponent as NonRecourse } from "../../../../../assets/images/others/NonRecourse.svg";
import * as constants from "../../../../../constants";

import DealName from "./DealName";
import DealStatus from "./DealStatus";
import DealAssetCount from "./DealAssetCount";
import DealLiabilityPreview from "./DealLiabilityPreview";

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chips: {
    borderRadius: 5,
    margin: "0px 0px 0px 8px",
    padding: "0px 8px 0px 10px",
    fontWeight: "normal",
    height: 24,
    lineHeight: "24px",
    letterSpacing: "2px",
  },
}));

const LoanIcon = ({ show, style }) => {
  if (show) return <LoanFilled style={style} />;
  else return <Loan style={style} />;
};

const RecourseIcon = ({ recourse, style }) => {
  //const classes = useStyles();

  if (recourse === "Recourse") return <FullRecourse style={{ ...style }} />;
  else return <NonRecourse style={{ ...style }} />;
  // if (recourse === "Recourse") return <span style={{ ...style, border: "1px solid #2ABF43", background: "rgba(42,191,67,0.4)", color: "#2ABF43" }} className={classes.chips} >FR</span>;
  // else return <span style={{ ...style, border: "1px solid #FA6400", background: "rgba(250,100,0,0.4)", color: "#FA6400" }} className={classes.chips} >NR</span>;
};

const DealSummary = ({
  deal,
  airlineLogos,
  liabilityData,
  expectedLossRateData,
  dealId,
}) => {
  const { assets, stage } = deal;
  const [show, setShow] = useState(false);

  return (
    <Grid
      container
      alignItems='center'
      spacing={2}
      style={{ margin: "0 -30px 0 0" }}>
      <Grid item md={3} sm={3}>
        <DealName deal={deal} />
      </Grid>
      <Grid item md={3} sm={3}>
        <DealAssetCount assets={assets} airlineLogos={airlineLogos} />
      </Grid>
      <Grid item md={4} sm={4}>
        <DealLiabilityPreview
          liabilityData={liabilityData}
          expectedLossRateData={expectedLossRateData}
          dealId={dealId}
          deal={deal}
        />
      </Grid>
      <Grid item md={1} sm={1}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6}>
            {deal && deal.recourse ? (
              <RecourseIcon recourse={deal.recourse} style={{ padding: 4 }} />
            ) : (
              constants.___
            )}
          </Grid>
          <Tooltip title='Go to Loan Summary' placement='bottom' arrow>
            <Grid
              item
              md={6}
              sm={6}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}>
              <Link
                onClick={(event) => event.stopPropagation()}
                to={{
                  pathname: `/deal/:dealName/portfolio/loanSummary/${dealId}`,
                }}>
                <LoanIcon show={show} style={{ marginTop: 9 }} />
              </Link>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid item md={1} sm={1}>
        <DealStatus stage={stage} />
      </Grid>
    </Grid>
  );
};

export default DealSummary;
