import _ from "lodash";
import React, { useState } from "react";
import { Typography, TableCell, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import * as constants from "../../../../constants";
import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel";

const useStyles = makeStyles((theme) => ({
  primaryValue: {
    fontWeight: 550,
    fontSize: 12,
    color: "#023059",
  },
  secondaryValue: {
    fontWeight: 410,
    color: "rgba(2, 48, 89, 0.8)",
    fontSize: 12,
    paddingTop: 3,
  },
  footNotes: {
    fontWeight: 550,
    color: "#969696",
    fontSize: 10,
    paddingTop: 3,
  },
  cellspacing: {
    display: "flex",
    justifyContent: "space-between",
  },
  editingButtons: {
    // maxWidth: 60,
    maxHeight: 30,
    display: "flex",
    justifyContent: "center",
  },
  topAlignment: {
    // margin: -5,
  },
}));

const VerticalTableCell = ({ data, props, editing, column, rowIdx }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const editingColumns = [
    {
      label: ["Row Title"],
      field: "rowTitle",
      editable: true,
      required: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
    },
    {
      label: ["Sub Title"],
      field: "primaryValue",
      editable: true,
      required: false,
      multiline: true,
      numRows: 2,
      type: column.type,
      inputFieldType: column.inputFieldType,
    },
    {
      label: ["Body"],
      field: "secondaryValue",
      editable: true,
      multiline: true,
      numRows: 3,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
    },
    {
      label: ["Foot Notes"],
      field: "footNote",
      editable: true,
      multiline: true,
      numRows: 3,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
    },
  ];

  const columnData = {
    rowTitle: column.label[0],
    primaryValue: data ? data.primaryValue : null,
    secondaryValue: data ? data.secondaryValue : null,
    footNote: data ? data.footNotes : null,
    // multi-row cell passing of target row value to updater
    rowIdx: rowIdx !== undefined ? String(rowIdx) : column.field,
  };

  const {
    section,
    sectionIdx,
    funcs: { handleRowDelete },
  } = props;

  const handleDeleteRow = () => {
    handleRowDelete(section, sectionIdx, column.field, columnData, column);
    setDeleting(false);
  };

  return data ? (
    <TableCell align='left' className={classes.cellspacing}>
      {!deleting ? (
        <span>
          <Typography variant='body1' className={classes.primaryValue}>
            {!data.primaryValue && !data.secondaryValue && !data.footNotes
              ? constants.___
              : data.primaryValue}
          </Typography>
          <Typography variant='body2' className={classes.secondaryValue}>
            {data.secondaryValue}
          </Typography>
          <Typography variant='caption' className={classes.footNotes}>
            {data.footNotes}
          </Typography>
        </span>
      ) : (
        <Typography variant='subtitle2'>
          Are you sure you want to delete this row?
        </Typography>
      )}
      {editing && !deleting ? (
        <span className={classes.editingButtons}>
          <IconButton
            className={classes.topAlignment}
            onClick={() => {
              setOpen(true);
            }}>
            <CreateIcon />
          </IconButton>
          <IconButton
            className={classes.topAlignment}
            onClick={() => {
              setDeleting(true);
            }}>
            <DeleteForeverIcon />
          </IconButton>
        </span>
      ) : (
        <></>
      )}
      {deleting ? (
        <span className={classes.editingButtons}>
          <IconButton
            className={classes.topAlignment}
            onClick={() => {
              handleDeleteRow();
            }}>
            <CheckIcon />
          </IconButton>
          <IconButton
            className={classes.topAlignment}
            onClick={() => {
              setDeleting(false);
            }}>
            <ClearIcon />
          </IconButton>
        </span>
      ) : (
        <></>
      )}
      <MultiFieldsFadeInEditingPanel
        open={open}
        columns={editingColumns}
        data={columnData}
        tableName={column.field}
        props={props}
        onClose={() => setOpen(false)}
      />
    </TableCell>
  ) : (
    <></>
  );
};

export default VerticalTableCell;
