import { Updater } from "./mxReserve_LessorContrib";
import { mxReserveRateUpdater } from "./MXReserveRate";
import { engineRestRateMatrixUpdater } from "./EngineRestRateMatrix";
import { mxReserveBalanceUpdater } from "./MXReserveBalance";

export function MXReserve_LessorContribUpdater(
  fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  leaseSummary,
  columns
) {
  switch (fieldName) {
    case "Maintenance Reserve Rates":
      return mxReserveRateUpdater(
        actionType,
        sectionName,
        sectionIdx,
        newData,
        leaseSummary
      );
    case "Maintenance Reserve Balance":
      return mxReserveBalanceUpdater(
        actionType,
        sectionName,
        sectionIdx,
        newData,
        leaseSummary
      );
    case "Engine Restoration Rate Matrix":
      return engineRestRateMatrixUpdater(
        actionType,
        sectionName,
        sectionIdx,
        newData,
        leaseSummary
      );
    default:
      return Updater(
        fieldName,
        actionType,
        sectionName,
        sectionIdx,
        newData,
        leaseSummary,
        columns
      );
  }
}
