import * as constants from "../../../../constants";
import { updateData as updateEngineStatus } from "./engineStatus";
import { updateData as updateEngineLLPStatus } from "./engineLLPStatus";
import { updateData as updateSchedulesShopVisit } from "../common/scheduledShopVisit";
import { updateData as updateShopVisitHistory } from "../common/shopVisitHistory";
import { updateData as updateShopVisitAssumptions } from "./engineShopVisitAssumptions";
import { updateData as updateEngineLLPShopVisitAssumptions } from "./engineLLPShopAssumptions";
import { updateData as updateEngineLLPScrapValue } from "./engineLLPScrapValue";

export function updateEngineInfo(
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario,
  msalContext
) {
  switch (tableName) {
    case constants.engineStatus:
      return updateEngineStatus(actionType, assetDetails, newData, msalContext);
    case constants.engineLLPStatus:
      return updateEngineLLPStatus(
        actionType,
        assetDetails,
        newData,
        msalContext
      );
    case constants.engineLLPShopVisitAssumptions:
      return updateEngineLLPShopVisitAssumptions(
        actionType,
        newData,
        scenario,
        msalContext
      );
    case constants.scrapSaleCutoff:
      return updateEngineLLPScrapValue(newData, scenario, msalContext);
    case constants.scheduledShopVisit:
      return updateSchedulesShopVisit(
        tableName,
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        msalContext
      );
    case constants.shopVisitHistory:
      return updateShopVisitHistory(
        tableName,
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        msalContext
      );
    case constants.shopVisitAssumptions:
      return updateShopVisitAssumptions(
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        scenario,
        msalContext
      );
    default:
      return;
  }
}
