import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import { styles } from "../index";

export function mapToViewModel_redeliveryConditions(leaseSummary, props, threeDotBtnOptions) {
  let data = [];
  const leaseData = leaseSummary.redeliveryConditions.redeliveryConditions
  if(leaseData.dataTable) {
    data = leaseData.dataTable.map((e, idx) => {
      return {
        id: idx,
        component: e.component,
        specificRequirementsMinTimeRemaining: e.specificRequirementsMinTimeRemaining,
      };
    })
  }

  const {
    section,
    funcs: { handleValuesUpdate, validateData },
  } = props

  const footNoteData = {
    footNotes: leaseData.footNotes,
    tableName:  "Redelivery Conditions",
    props: { 
      section: section,
      sectionIdx: "footNotes",
      funcs: { handleValuesUpdate, validateData } 
    },
  };

  return {
    label: "Redelivery Conditions",
    caption: leaseData.footNotes,
    props,
    threeDotBtnOptions: threeDotBtnOptions("Redelivery Conditions", footNoteData),
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: "Redelivery Conditions",
    errorsOnSubmit: {},
    columns: [
      {
        title: "Component",
        field: "component",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.component, null),
      },
      {
        title: "Specific Requirements and Minimum Time Remaining",
        field: "specificRequirementsMinTimeRemaining",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        align: "left",
        cellStyle: { ...styles.cellStyle, textAlign: "left", width: 750 , minWidth: 750 },
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.specificRequirementsMinTimeRemaining, null),
      },
    ],
    data: data,
  };
}
