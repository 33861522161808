import _ from "lodash";
import { renderCell, styles } from "../common/";

import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

import React from "react";
import RenderComment from "../../Components/MainContent/Components/Scenario/ShopVisitAssumptions/SV_Comments";

function generateEngineLLPShopVisitAssumptions(data, scenario) {
  let sv = [];
  const llps = data.llpStack.llPs;

  let buildStandardOverrides = _.find(
    scenario.buildStandardOverrides,
    (o) => o.component === data.position
  );

  buildStandardOverrides = buildStandardOverrides
    ? buildStandardOverrides.llps[0].overrides
    : [];

  const runNumbers = buildStandardOverrides.map((e) => e.shopVisitNumber);

  let maxRunNumber = _.max(runNumbers);

  maxRunNumber = maxRunNumber < 5 || !maxRunNumber ? 5 : maxRunNumber;

  for (var i = 1; i <= maxRunNumber; i++) {
    if (runNumbers && runNumbers.includes(i)) {
      const runNumber = i;
      const override = _.find(
        buildStandardOverrides,
        (o) => o.shopVisitNumber === runNumber
      );
      let cutOffOverride = override.newBuildStandard.cutOff;
      if (cutOffOverride === -1) {
        cutOffOverride = null;
      }
      let replaceCyclesOverride = override.newBuildStandard.replaceCycles;
      if (replaceCyclesOverride === -1) {
        replaceCyclesOverride = null;
      }

      let cutOffComment = override.newBuildStandard.cutOffComment;
      let replaceCyclesComment = override.newBuildStandard.replaceCyclesComment;

      sv.push({
        component: data.position,
        id: i - 1,
        runNumber: i,
        cutOff: {
          override: cutOffOverride,
          generic: llps[0].buildStandard[0].cutOff,
        },
        replaceCycles: {
          override: replaceCyclesOverride,
          generic: llps[0].buildStandard[0].replaceCycles,
        },
        cutOffComment,
        replaceCyclesComment,
      });
    } else if (i <= 5) {
      sv.push({
        component: data.position,
        id: i - 1,
        runNumber: i,
        cutOff: {
          override: null,
          generic: llps[0].buildStandard[0].cutOff,
        },
        replaceCycles: {
          override: null,
          generic: llps[0].buildStandard[0].replaceCycles,
        },
      });
    }
  }

  return sv;
}

export function mapToViewModel_EngineLLPShopVisitAssumptions(
  data,
  props,
  scenario
) {
  const isDeletable = (rowData) => {
    let isDeletable = false;
    const component = data.position;
    let buildStandardOverrides = _.find(
      scenario.buildStandardOverrides,
      (o) => o.component === component
    );

    buildStandardOverrides = buildStandardOverrides
      ? buildStandardOverrides.llps[0].overrides
      : [];

    let overrides = _.find(
      buildStandardOverrides,
      (o) => o.shopVisitNumber === rowData.runNumber
    );

    if (overrides) {
      if (
        overrides.newBuildStandard.cutOff ||
        overrides.newBuildStandard.replaceCycles
      ) {
        isDeletable = true;
      }
    }
    return isDeletable;
  };

  const llps = data.llpStack.llPs;
  return {
    label: `${constants.ENGINE_LLP} - ${data.serialNumber}`,
    caption: null,
    boxShadow: "unset",
    tableType: constants.EDITABLE_TABLE,
    props,
    showOptions: true,
    tableName: constants.engineLLPShopVisitAssumptions,
    isDeletable: isDeletable,
    errorsOnSubmit: {},
    columns: [
      {
        title: "Run Number",
        field: "runNumber",
        editable: constants.NEVER,
        cellStyle: styles.cellStyle,
      },
      {
        title: "Cuf Off",
        field: "cutOff",
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: constants.ALWAYS,
        required: false,
        initialEditValue: _.round(llps[0].buildStandard[0].cutOff, 2),
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => renderCell(rowData)("cutOff", constants.NUMBER),
      },
      {
        title: "Interval FH Comment",
        field: "cutOffComment",
        required: false,
        type: constants.STRING,
        inputFieldType: constants.TEXT_FIELD,
        multiline: true,
        hidden: true,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Replace Cycles",
        field: "replaceCycles",
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: constants.ALWAYS,
        required: false,
        initialEditValue: _.round(llps[0].buildStandard[0].replaceCycles, 2),
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          renderCell(rowData)("replaceCycles", constants.NUMBER),
      },
      {
        title: "Interval FH Comment",
        field: "replaceCyclesComment",
        required: false,
        type: constants.STRING,
        inputFieldType: constants.TEXT_FIELD,
        multiline: true,
        hidden: true,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Comments",
        field: "view",
        width: "5%",
        editable: constants.NEVER,
        cellStyle: styles.cellStyle,
        render: (rowData) => <RenderComment rowData={rowData} llp={true} />,
      },
    ],
    data: generateEngineLLPShopVisitAssumptions(data, scenario),
  };
}
