import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import _ from "lodash";
import React from "react";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const VfSnackerBar = ({
  snackOpen,
  setsnackOpen,
  severity = "info",
  message,
  duration = 1500,
  verticalAlign = "top",
  horizontalAlign = "center",
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackOpen(false);
  };

  return (
    <Snackbar
      open={snackOpen}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: verticalAlign, horizontal: horizontalAlign }}>
      <Alert
        onClose={handleClose}
        severity={severity}
        style={{ position: "relative", zIndex: 50, top: 60 }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default VfSnackerBar;
