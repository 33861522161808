import _ from "lodash";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export default function mapToViewModel_LLPReplacements(llps, tableName) {
  let totalReplaced = 0;
  let data = llps.map((llp) => {
    if (llp.isReplaced) totalReplaced += 1;
    return {
      startDate: llp.startDate,
      partName: llp.partName,
      partNumber: llp.partNumber,
      module: utils.renderValueWithIcon(llp.module, !llp.isReplaced),
      cyclesBefore: llp.cyclesBefore && _.round(llp.cyclesBefore),
      cyclesAfter: llp.cyclesAfter && _.round(llp.cyclesAfter),
      pricePerCycle: llp.pricePerCycle,
      isReplaced: llp.isReplaced,
      hidden: [],
      style: {
        // fontWeight: llp.isReplaced ? 600 : 500,
        height: 48,
        bgColor: constants.HIDDEN_ROW_BG_COLOR,
      },
    };
  });

  data = _.orderBy(
    data,
    ["isReplaced", "module", "partName"],
    ["desc", "asc", "asc"]
  );

  return {
    label: null,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { pagination: false },
    optionsItems: {
      pagination: {},
    },
    clickableCellIconValue:
      tableName === constants.maintenanceEvents ? totalReplaced : llps.length,
    columns: [
      {
        label: "Module",
        caption: null,
        field: "module",
        align: constants.LEFT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Part Name",
        caption: null,
        field: "partName",
        align: constants.LEFT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Part Number",
        caption: null,
        field: "partNumber",
        align: constants.LEFT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Cycles Before",
        caption: null,
        field: "cyclesBefore",
        align: constants.RIGHT,
        indent: 16,
        hidden: tableName !== constants.maintenanceEvents,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Cycles After",
        caption: null,
        field: "cyclesAfter",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      // {
      //   label: "Cycles Left",
      //   caption: null,
      //   field: "cyclesAfter",
      //   align: constants.RIGHT,
      //   indent: 16,
      //   content: (value, indent) =>
      //     utils.renderCellValue(
      //       value,
      //       { paddingRight: indent },
      //       constants.NUMERIC_CELL
      //     ),
      // },
      {
        label: "Price Per Cycle",
        caption: null,
        field: "pricePerCycle",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      // {
      //   label: "Is Replaced",
      //   caption: null,
      //   field: "isReplaced",
      //   indent: 16,
      //   content: (value) => (value ? "Yes" : "No"),
      // },
    ],
    data: data,
  };
}
