import React, { useContext } from "react";
import AssetPoolComponents from "../Components/AssetPool/AssetPoolComponents";
import { DealRelatedDataContext } from "../context/DealRelatedDataContext";

const AssetPools = (props) => {
  const { assetPoolData } = useContext(DealRelatedDataContext);

  return (
    <AssetPoolComponents
      {...props.location.state}
      allAssetPoolData={assetPoolData}
    />
  );
};

export default AssetPools;
