import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Box, TableHead, TableRow, Typography } from "@material-ui/core";
import MultiFunctionTableCell from "./MultiFunctionTableCell";
import FilterListIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles({
  tableRow: {
    // backgroundColor: "rgb(229,232,235)",
    backgroundColor: "#E5E8EB",
  },
  tableHeaderCell: {
    height: 38,
    // backgroundColor: "#E5E8EB",
  },
  tableHeaderValue: {
    // color: "#263238",
    height: "100%",
    color: "#50708A",
    fontSize: 12,
    letterSpacing: "0.4px",
    lineHeight: "12px",
    fontWeight: 500,
    textAlign: "center",
    paddingTop: 12,
    paddingBottom: 12,
  },
  tableHeaderCaptionCell: {
    height: 20,
    backgroundColor: "#FFFFFF",
  },
  tableHeaderCaptionValue: {
    fontSize: 10,
    // color: "rgba(0,0,0,0.38)",
    color: "#546E7A",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "10px",
    textAlign: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    width: 1,
  },
});

function renderCellValues(
  field,
  classes,
  label,
  caption,
  align = null,
  indent = 0,
  order,
  orderBy,
  onRequestSort,
  sorting
) {
  const style = {
    paddingLeft: align === "left" ? indent : 0,
    paddingRight: align === "right" ? indent : 0,
    wordBreak: "initial",
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return [
    sorting ? (
      <TableSortLabel
        key={0}
        active={orderBy === field}
        direction={orderBy === field ? order : "asc"}
        onClick={createSortHandler(field)}
        style={style}
        className={classes.tableHeaderCell}>
        <Typography
          variant='subtitle2'
          style={{ textAlign: align }}
          className={classes.tableHeaderValue}>
          {label}
        </Typography>
        {orderBy === field ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </TableSortLabel>
    ) : (
      <Box key={0} style={style} className={classes.tableHeaderCell}>
        <Typography
          variant='subtitle2'
          style={{ textAlign: align }}
          className={classes.tableHeaderValue}>
          {label}
        </Typography>
      </Box>
    ),
    sorting ? (
      <Box
        key={2}
        style={{ textAlign: "left" }}
        className={classes.tableHeaderCaptionCell}>
        <FilterListIcon />
        <hr
          style={{
            width: "100%",
            height: 2,
          }}
        />
      </Box>
    ) : null,
    <Box key={1} style={style} className={classes.tableHeaderCaptionCell}>
      <Typography variant='caption' className={classes.tableHeaderCaptionValue}>
        {caption}
      </Typography>
    </Box>,
  ];
}

const MultiFunctionTableHead = ({
  columnHeaders,
  order,
  orderBy,
  onRequestSort,
}) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {columnHeaders.map((header, idx) =>
          header.hidden ? null : (
            <MultiFunctionTableCell
              key={`${header.label}_${idx}`}
              border={"none"}
              // align={header.align}
              zIndex={header.zIndex}
              align={
                header.align === "right"
                  ? "right"
                  : header.align === "left"
                  ? "left"
                  : "center"
              }
              sortDirection={orderBy === header.field ? order : false}>
              {renderCellValues(
                header.field,
                classes,
                header.label,
                header.caption,
                header.align,
                header.indent,
                order,
                orderBy,
                onRequestSort,
                header.sorting
              )}
            </MultiFunctionTableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default MultiFunctionTableHead;
