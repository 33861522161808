import { mapToViewModel_airframeDetails } from "./aircraft/airframeDetails";
import { mapToViewModel_checkStatus } from "./aircraft/checkStatus";
import { mapToViewModel_checkHistory } from "./aircraft/checkHistory";
import { mapToViewModel_statuSinceNew } from "./aircraft/statusSinceNew";
import { mapToViewModel_scheduledShopVisit } from "./aircraft/scheduledShopVisit";

export default function mapToViewModel_AssetInfo(data, props,scenario,additionalProps) {
  return {
    airframeDetails: mapToViewModel_airframeDetails(data, props,additionalProps),
    statusSinceNew: mapToViewModel_statuSinceNew(data, props),
    checkStatus: mapToViewModel_checkStatus(data.airframeChecks, props),
    checkHistory: mapToViewModel_checkHistory(data.airframeChecks, props),
    scheduledShopVisit: mapToViewModel_scheduledShopVisit(
      data.airframeChecks,
      props
    ),
  };
}
