import _ from "lodash";
import moment from "moment";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";

export function mapToViewModel_aircraftDetails(assetType, leaseSummary, props) {
  let columns = [
    {
      label: ["MSN"],
      field: "msn",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Model"],
      field: "model",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Date of Manufacture"],
      field: "dom",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: ["Engine Type"],
      field: "engineType",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Thrust rating"],
      field: "thrustRating",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["ESNs"],
      field: "eSNs",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Registration"],
      field: "registration",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Country of Registration"],
      field: "countryOfRegistration",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Owning Entity"],
      field: "owningEntity",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lessor"],
      field: "lessor",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Convertor & Location"],
      field: "convertorLocation",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Conversion Phase Completion Date"],
      field: "conversionPhaseCompDate",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
  ];

  let data = {
    model: leaseSummary.aircraftDetails.model,
    msn: leaseSummary.aircraftDetails.msn,
    dom: leaseSummary.aircraftDetails.dom,
    engineType: leaseSummary.aircraftDetails.engineType,
    eSNs: leaseSummary.aircraftDetails.eSNs,
    thrustRating: leaseSummary.aircraftDetails.thrustRating,
    registration: leaseSummary.aircraftDetails.registration,
    countryOfRegistration: leaseSummary.aircraftDetails.countryOfRegistration,
    owningEntity: leaseSummary.aircraftDetails.owningEntity,
    lessor: leaseSummary.aircraftDetails.lessor,
    convertorLocation: leaseSummary.aircraftDetails.convertorLocation,
    conversionPhaseCompDate: leaseSummary.aircraftDetails.conversionPhaseCompDate,
  };

  leaseSummary.aircraftDetails.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  })

  return {
    label: assetType === "Aircraft" ? "Aircraft Details" : "Engine Details",
    caption: `As of ${moment.utc(leaseSummary.lastUpdatedDate).format(constants.DATE_FORMAT)}`,
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Aircraft Details",
    columns: columns,
    data: data,
  };
}
