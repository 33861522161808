import mapToViewModel_ScenarioSummaries from "./scenarioSummariesViewModel";

export default function mapToViewModel_Scenarios(data, props, additionalProps) {
  return {
    scenarioSummaries: mapToViewModel_ScenarioSummaries(
      data,
      props,
      additionalProps
    ),
  };
}
