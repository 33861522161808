import _ from "lodash";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import { renderCell, renderComment, styles } from "./";
import moment from "moment";

import React from "react";
import RenderComment from "../../Components/MainContent/Components/Scenario/ShopVisitAssumptions/SV_Comments";

function generateShopVisitAssumptions(data, scenario, findExchangeOverrides) {
  if (!data) return [];
  let sv = [];
  //console.log(data);
  const component =
    "position" in data
      ? data.position
      : "checkName" in data
      ? data.checkName
      : "eventName" in data
      ? data.eventName +" "+ data.EnginePosition
      : constants.APU.toUpperCase();
  let costOverrides = _.find(
    scenario.costOverrides,
    (o) => o.component === component
  );
  costOverrides = costOverrides ? costOverrides.overrides : [];

  let intervalOverrides = _.find(
    scenario.intervalOverrides,
    (o) => o.component === component
  );
  intervalOverrides = intervalOverrides ? intervalOverrides.overrides : [];

  let downtimeOverrides = _.find(
    scenario.downtimeOverrides,
    (o) => o.component === component
  );
  downtimeOverrides = downtimeOverrides ? downtimeOverrides.overrides : [];

  const runNumbers = intervalOverrides.map((e) => e.shopVisitNumber);
  let maxRunNumber =
    intervalOverrides.length &&
    _.maxBy(intervalOverrides, "shopVisitNumber").shopVisitNumber;
  maxRunNumber = maxRunNumber < 5 ? 5 : maxRunNumber;

  for (var i = 1; i <= maxRunNumber; i++) {
    let isFirstRun =
      i === 1 &&
      data.maintenanceStatus.length !== 0 &&
      data.maintenanceStatus[0].isFirstRun;

    const { interval, cost, downtime } = _.find(
      data.genericMxInfo,
      (o) => !(o.isFirstRun ^ isFirstRun)
    );

    const shopVisitNumber = i;
    if (runNumbers && runNumbers.includes(i)) {
      const {
        newInterval,
        intervalFHComment,
        intervalFCComment,
        intervalHourComment,
        intervalDayComment,
      } = _.find(
        intervalOverrides,
        (e) => e.shopVisitNumber === shopVisitNumber
      );
      const { newCost, svCostComment, costEscComment, costDateComment } =
        _.find(costOverrides, (e) => e.shopVisitNumber === shopVisitNumber);

      const { newDowntime, svDownDaysComment } = _.find(
        downtimeOverrides,
        (e) => e.shopVisitNumber === shopVisitNumber
      );

      sv.push({
        component,
        id: i - 1,
        runNumber: i,
        intervalFH: {
          override: newInterval.flightHour,
          generic: interval.flightHour,
        },
        intervalFC: {
          override: newInterval.flightCycle,
          generic: interval.flightCycle,
        },
        intervalHour: {
          override: newInterval.activeHour,
          generic: interval.activeHour,
        },
        intervalDay: {
          override: newInterval.day,
          generic: interval.day,
        },
        svCost: {
          override: newCost.cost,
          generic: cost.cost,
        },
        costEsc: {
          override: newCost.costEscalation,
          generic: cost.costEscalation,
        },
        costDate: {
          override: newCost.costDate,
          generic: cost.costDate,
        },
        svDownDays: {
          generic: downtime,
          override: newDowntime,
        },
        intervalFHComment,
        intervalFCComment,
        intervalHourComment,
        intervalDayComment,
        svCostComment,
        costEscComment,
        costDateComment,
        svDownDaysComment,
      });
    } else if (i <= 5) {
      sv.push({
        component,
        id: i - 1,
        runNumber: i,
        intervalFH: {
          override: null,
          generic: interval.flightHour,
        },
        intervalFC: {
          override: null,
          generic: interval.flightCycle,
        },
        intervalHour: {
          override: null,
          generic: interval.activeHour,
        },
        intervalDay: {
          override: null,
          generic: interval.day,
        },
        svCost: {
          override: null,
          generic: cost.cost,
        },
        costEsc: {
          override: null,
          generic: cost.costEscalation,
        },
        costDate: {
          override: null,
          generic: cost.costDate,
        },
        svDownDays: {
          override: null,
          generic: downtime,
        },
      });
    }

    if (findExchangeOverrides) {
      let swap = _.find(
        findExchangeOverrides.exchangeOverrides,
        (o) => o.shopVisitNumber === i
      );
      if (!_.isEmpty(swap)) {
        sv[i - 1].swap = true;
      }
    } else sv[i - 1].swap = false;
  }

  return sv;
}

export function mapToViewModel_ShopVisitAssumptions(data, props, scenario) {
  const isDeletable = (rowData) => {
    const component =
      "position" in data
        ? data.position
        : "checkName" in data
        ? data.checkName
        : "eventName" in data
        ? data.eventName +" "+  data.EnginePosition
        : constants.APU.toUpperCase();

    let intervalOverrides = _.find(
      scenario.intervalOverrides,
      (o) => o.component === component
    );

    intervalOverrides = intervalOverrides ? intervalOverrides.overrides : [];

    return _.find(
      intervalOverrides,
      (o) => o.shopVisitNumber === rowData.runNumber
    );
  };
  const label =
    "position" in data
      ? data.componentType.toUpperCase() === "EnginePR".toUpperCase()
        ? `${constants.ENGINE_PR} - ${data.serialNumber}`
        : `LG - ${data.position}`
        //.split(" ")[0]
      : "checkName" in data
      ? data.checkName
      : "eventName" in data
      ? `${data.eventName} - ${data.serialNumber}`
      : constants.APU.toUpperCase();

  const { interval, cost, downtime } = _.find(
    data.genericMxInfo,
    (o) => !o.isFirstRun
  );

  const swapItems = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  let columns = [
    {
      title: "Run Number",
      field: "runNumber",
      editable: constants.NEVER,
      cellStyle: styles.cellStyle,
    },
    {
      title: "Interval FH",
      field: "intervalFH",
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
      initialEditValue: _.round(interval.flightHour, 2),
      required: false,
      cellStyle: styles.cellStyle,
      hidden: props.section === constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => renderCell(rowData)("intervalFH", constants.NUMBER),
    },
    {
      title: "Interval FH Comment",
      field: "intervalFHComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Interval FC",
      field: "intervalFC",
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
      initialEditValue: _.round(interval.flightCycle, 2),
      required: false,
      cellStyle: styles.cellStyle,
      hidden: props.section === constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => renderCell(rowData)("intervalFC", constants.NUMBER),
    },
    {
      title: "Interval FC Comment",
      field: "intervalFCComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Interval Hour",
      field: "intervalHour",
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
      initialEditValue: _.round(interval.activeHour, 2),
      required: props.section === constants.apUs,
      cellStyle: styles.cellStyle,
      hidden: props.section !== constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        renderCell(rowData)("intervalHour", constants.NUMBER),
    },
    {
      title: "Interval Hour Comment",
      field: "intervalHourComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Interval Day",
      field: "intervalDay",
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
      initialEditValue: _.round(interval.day, 2),
      required: false,
      cellStyle: styles.cellStyle,
      hidden: props.section === constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => renderCell(rowData)("intervalDay", constants.NUMBER),
    },
    {
      title: "Interval Comment",
      field: "intervalDayComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "SV Cost",
      field: "svCost",
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
      initialEditValue: _.round(cost.cost, 2),
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        renderCell(rowData)("svCost", constants.NUMBER, rowData.swap, "$"),
    },
    {
      title: "Cost Comment",
      field: "svCostComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Cost Esc",
      field: "costEsc",
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(constants.PERCENTAGE, value, required),
      render: (rowData) =>
        renderCell(rowData)("costEsc", constants.NUMBER, rowData.swap),
    },
    {
      title: "Cost Esc Comment",
      field: "costEscComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Cost Date",
      field: "costDate",
      type: constants.DATE,
      inputFieldType: constants.DATE,
      editable: constants.ALWAYS,
      initialEditValue: moment(cost.costDate).format(constants.DATE_FORMAT),
      required: true,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        renderCell(rowData)("costDate", constants.DATE, rowData.swap),
    },
    {
      title: "Cost Date Comment",
      field: "costDateComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "SV Down Days",
      field: "svDownDays",
      required: true,
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
      initialEditValue: downtime,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => renderCell(rowData)("svDownDays", constants.NUMBER),
    },
    {
      title: "Downtime Comment",
      field: "svDownDaysComment",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      multiline: true,
      hidden: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
  ];

  let shouldRenderDetailPanel = false;
  let findExchangeOverrides = null;
  if ("position" in data) {
    findExchangeOverrides = _.find(
      scenario.exchangeOverrides,
      (o) => o.component === data.position
    );

    if (!_.isEmpty(findExchangeOverrides)) shouldRenderDetailPanel = true;

    if (data.position.includes("Engine Position")) {
      columns.push({
        title: "Swap",
        field: "swap",
        required: false,
        cellStyle: styles.cellStyle,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items: swapItems,
        initialEditValue: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => (rowData.swap ? constants.YES : constants.NO),
      });
    }
  }

  columns.push({
    title: "Comments",
    field: "view",
    width: "5%",
    editable: constants.NEVER,
    cellStyle: styles.cellStyle,
    render: (rowData) => <RenderComment rowData={rowData} />,
  });

  const detailPanelData = (rowData) => {
    if (!_.isEmpty(findExchangeOverrides)) {
      let swaprows = _.find(
        findExchangeOverrides.exchangeOverrides,
        (o) => o.shopVisitNumber === rowData.runNumber
      );
      if (!_.isEmpty(swaprows)) {
        let newRowData = {
          swapcost: swaprows.cost,
          swapcostDate: swaprows.costDate,
          swapescalationRate: swaprows.escalationRate,
          greenTimeFlightCycles: swaprows.greenTimeFlightCycles,
          greenTimeFlightHours: swaprows.greenTimeFlightHours,
          swapSinceEventFlightHours: swaprows.swapSinceEventFlightHours,
          swapSinceEventFlightCycles: swaprows.swapSinceEventFlightCycles,
          overrideDate: swaprows.overrideDate,
          overrideDescription: swaprows.overrideDescription,
          shopVisitNumber: swaprows.shopVisitNumber,
          component: findExchangeOverrides.component,
        };
        return newRowData;
      } else return null;
    }
  };

  let panelColumns = [
    {
      label: ["Cost"],
      field: "swapcost",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 14,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
    },
    {
      label: ["Cost Date"],
      field: "swapcostDate",
      type: constants.DATE,
      inputFieldType: constants.DATE,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 14,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: ["Esc Rate"],
      field: "swapescalationRate",
      type: constants.PERCENTAGE,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 10,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) => `${_.round(value * 100, 3).toFixed(2)}%`,
    },
    {
      label: ["GreenTime FH"],
      field: "greenTimeFlightHours",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: ["GreenTime FC"],
      field: "greenTimeFlightCycles",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: ["SinceEvent FH"],
      field: "swapSinceEventFlightHours",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: ["SinceEvent FC"],
      field: "swapSinceEventFlightCycles",
      type: constants.NUMBER,
      inputFieldType: constants.TEXT_FIELD,
      cellStyle: styles.cellStyle,
      editable: true,
      required: false,
      width: 12,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
  ];

  const detailPanel = {
    label: null,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: false, pagination: false },
    shouldRender: shouldRenderDetailPanel,
    detailPanelData: detailPanelData,
    inPanelEditing: false,
    tooltip: "Show Swap",
    columns: panelColumns,
    panelLetter: "S",
  };

  return {
    label,
    style: { boxShadow: "unset" },
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    props,
    showOptions: true,
    tableName: constants.shopVisitAssumptions,
    isDeletable: isDeletable,
    detailPanel: detailPanel,
    errorsOnSubmit: {},
    columns: columns,
    data: scenario
      ? generateShopVisitAssumptions(data, scenario, findExchangeOverrides)
      : [],
  };
}
