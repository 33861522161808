// dropDownList.jsx
export const ADD = "Add";
export const ADD_ROW = "Add Row";
export const ADD_COLUMN = "Add Column";
export const DELETE_ROW = "Delete Row";
export const UPDATE_ROW = "Update Row";
export const DELETE = "Delete";
export const CLOSE = "Close";
export const EDIT = "Edit";
export const BACK_TO_EDIT = "Back to Edit";
export const VIEW = "View Only";

// table types
export const DATA_TABLE = "DATA_TABLE";
export const EDITABLE_TABLE = "EDITABLE_TABLE";
export const FILTERING_TABLE = "FILTERING_TABLE";
export const EXPANDABLE_TABLE = "EXPANDABLE_TABLE";
export const MULTIFUNCTION_TABLE = "MULTIFUNCTION_TABLE";

//AssetDetail.jsx; AssetComponentView.jsx
//Component Types
export const ASSET_DETAILS = "ASSET_DETAILS";
export const GENERAL_INFO = "generalInfo";
export const AIRFRAME_CHECKS = "airframeChecks";
export const ENGINE = "engine";
export const ENGINES = "engines";
export const LANDING_GEAR = "landingGear";
export const LANDING_GEARS = "landingGears";
export const APU = "apu";
export const apUs = "apUs";
export const LEASE = "lease";
export const LEASES = "leases";
export const EXPECTED_UTILIZATIONS = "expectedUtilizations";
export const SCENARIO_SUMMARIES = "Scenario Summaries";
export const Cash_Reserve_Accounts = "Cash Reserve Accounts";
export const SV_ASSUMPTIONS = "svAssumptions";
export const ENGINE_PR = "EPR";
export const ENGINE_LLP = "ELLP";
export const CASHFLOW_REPORT = "CASHFLOW_REPORT";
export const PORTFOLIO_REPORT = "PORTFOLIO_REPORT";

// AssetDetails.jsx
//SECTION headers and labels
export const Asset_Details = "Asset Details";
export const Summary = "Summary";
export const Airframe_Checks = "Airframe Checks";
export const Engines = "Engines";
export const Landing_Gears = "Landing Gears";
export const APUs = "APUs";
export const Leases = "Leases";
export const Lease = "Lease";
export const Utilization = "Utilization";
export const ScenarioSummaries = "Scenario Summaries";
export const Cashflow_Report = "Cashflow Report";
export const Portfolio_Report = "Portfolio Report";
export const Refresh = "Refresh";

// LeaseDetails.jsx
// SECTION Headers
export const MAINTEANCE_RESERVE = "Maintenance Reserve";
export const MAINTENANCE_FUNDING_AND_ADJUSTMENT =
  "Maintenance Funding and Adjustment";
export const STATUS_REQUIREMENTS = "Status Requirements";
export const CashReserve = "CashReserve";
export const CASH = "Cash";
export const CREDIT = "Credit";
export const SCENARIO = "scenario";

// Others
export const ALWAYS = "always";
export const NEVER = "never";
export const YES = "Yes";
export const NO = "No";
export const ALL = "All";
export const LGS = ["Nose", "Wing", "Body", "Main", "Center"];
export const NOSE = "Nose";
export const LEFT_WING = "Left Wing";
export const LEFT_BODY = "Left Body";
export const RIGHT_WING = "Right Wing";
export const RIGHT_BODY = "Right Body";
export const LEFT_MAIN = "Left Main";
export const RIGHT_MAIN = "Right Main";
export const CENTER = "Center";
export const DATE_FORMAT = "MMM-DD-YY";
export const FULL_YEAR_DATE = "MMM-DD-YYYY";
export const MONTH_COMP_FORMAT = "MMM-YYYY";
export const LOCAL_DATETIME_FORMAT = "MMM-DD-YYYY hh:mm A";
export const AS_OF_DATE = "As of Date";
export const NA = "N/A";
export const NONE = "None";
export const LC = "LC";
export const AIRCRAFT = "aircraft";
export const Airframe = "Airframe";
export const LG = "LG";
export const Engine = "Engine";
export const OnWingEngine = "OnWingEngine";
export const BUTTON = "BUTTON";
export const ___ = "---";
export const NaN = "NaN";
export const ASCEND = "ASCEND";
export const ORIEL = "ORIEL";
export const IBA = "IBA";
export const BASE = "Base";
export const MARKET = "Market";
export const DOWN = "Down";
export const RIGHT = "right";
export const LEFT = "left";
export const LINK = "Link";
export const DROPDOWN = "Dropdown";
export const CANCEL = "Cancel";
export const CHANGE_COMMON_SCENARIO = "Select Common Scenario";
export const DEFAULT_BASE = "Use Default Base";
export const MENU = "Menu";
export const REFRESH = "Refresh";
export const RESET = "Reset";
export const RADIO = "Radio";
export const PAGINATION = "Pagination";
export const FILTER = "Filter";
export const TOGGLE_MENU = "Toggle Menu";
export const SINGEL_SCENARIO_UPDATE = "Single Scenario Update";
export const COMMON_SCENARIO_UPDATE = "Common Scenario Update";
export const SUBMIT = "Submit";
export const UPDATE = "Update";
export const FlightHour = "FlightHour";
export const FlightCycle = "FlightCycle";
export const Day = "Day";
export const Monthly = "Monthly";
export const ActiveHour = "ActiveHour";
export const Quarterly = "Quarterly";
export const Contractual = "Contractual";
export const UnderNegotiation = "UnderNegotiation";
export const Assumption = "Assumption";
export const ISOFormat = "YYYY-MM-DDT12:00:00+00:00";
// Schema Types
export const PERCENTAGE = "percentage";
export const NUMERIC = "numeric";
export const NEG_NUMERIC = "neg_numeric";
export const NUMBER = "number";
export const NEG_NUMBER = "neg_number";
export const STATUSMXCH = "StatusMXCH";
export const DOLLARNUMBER = "dollarNumber";
export const TEXT = "text";
export const TEXT_FIELD = "textField";
export const STRING = "string";
export const MULTILINE = "multiline";
export const DATE = "date";
export const SELECT = "select";
export const SELECT_CHECK_BOX = "selectCheckBox";
export const CHECK_BOX = "checkBox";
export const AUTO_COMPLETE = "autoComplete";
export const MULTI_AUTO = "multiAuto";
export const RICH_TEXT_FIELD = "richTextField";
// Select Type
// SimulateReport.jsx
export const APPRAISER = "APPRAISER";
export const APPRAISAL = "Appraisal";
export const VALUE_TYPE = "VALUE TYPE";
export const PART_OUT_VALUE_TYPE = "PART OUT VALUE TYPE";
export const DISCOUNT_RATE = "DISCOUNT RATE";

// Redelivery Events - Lease Report
export const APU_OVERHAUL = "APU Overhaul";

// Input and Cell Types
export const NUMERIC_CELL = "NUMERIC_CELL";
export const DATE_CELL = "DATE_CELL";
export const TEXT_CELL = "TEXT_CELL";

// sessionStorage
export const SELECTED_SCENARIOS = "SELECTED_SCENARIOS";

// Table Names
export const CASH_FLOWS = "Cash Flows";
export const cashFlows = "cashFlows";
export const FLEET_SUMMARY = "Fleet Summary";
export const fleetInfo = "fleetInfo";
export const LEASE_SUMMARY = "Lease Summary";
export const leaseInfo = "leaseInfo";
export const MAINTENANCE_EVENTS = "Maintenance Events";
export const maintenanceEvents = "maintenanceEvents";
export const REDELIVERY_EVENTS = "Redelivery Events";
export const redeliveryEvents = "redeliveryEvents";
export const LEASE_REPORT = "Lease Report";
export const leaseReport = "leaseReport";
export const ASSET_STATUS = "Asset Status";
export const ASSET_VALUATION = "Asset Valuation";
export const Valuation = "Valuation";
export const LEASE_RENT = "Lease Rent";
export const checkStatus = "checkStatus";
export const averageOperation = "averageOperation";
export const statusSinceNew = "statusSinceNew";
export const engineStatus = "engineStatus";
export const landingGearStatus = "landingGearStatus";
export const apuStatus = "apuStatus";
export const overhaulHistory = "overhaulHistory";
export const shopVisitAssumptions = "shopVisitAssumptions";
export const shopVisitHistory = "shopVisitHistory";
export const scheduledShopVisit = "scheduledShopVisit";
export const scenarioScheduledShopVisit = "scenarioScheduledShopVist";
export const engineLLPStatus = "engineLLPStatus";
export const engineLLPShopVisitAssumptions = "engineLLPShopVisitAssumptions";
export const scrapSaleCutoff = "scrapSaleCutoff";
export const maintenanceReserveRates = "maintenanceReserveRates";
export const creditLineReserve = "creditLineReserve";
export const cashReserveAccounts = "cashReserveAccounts";
export const cashReserveCap = "cashReserveCap";
export const llpReplacementCostClaimable = "llpReplacementCostClaimable";
export const maintenanceFunding = "maintenanceFunding";
export const llpReplCostTypeTableData = "llpReplCostTypeTableData";
export const deliveryConditions = "deliveryConditions";
export const redeliveryConditions = "redeliveryConditions";
export const redeliveryAdjustments = "redeliveryAdjustments";
export const leaseSummary = "leaseSummary";
export const leaseList = "leaseList";
export const leaseExtension = "leaseExtension";
export const leaseRent = "leaseRent";
export const utilizationByDate = "utilizationByDate";
export const utilizationSelectedComponents = "components";
export const exportHistory = "Exports";
// Button Types
export const SINGLE_BUTTON = "SINGLE_BUTTON";
export const THREE_DOT_BUTTON = "THREE_DOT_BUTTON";
export const FEATURE = "FEATURE";

//DATES 
export const MaxDate = "12/31/9999";
export const EOL = "EoL";
export const MinDate = "1/1/1";
export const DOM = "DoM";
// REGEX
export const RAW_DATE_REG =
  "^[0-9]{4}[-][0-9]{2}[-][0-9]{2}T[0-9]{2}[:][0-9]{2}[:][0-9]{2}[.][0-9]{3}[+][0-9]{2}[:][0-9]{2}";
export const RAW_DATE_REG2 =
  "^[0-9]{4}[-][0-9]{2}[-][0-9]{2}T[0-9]{2}[:][0-9]{2}[:][0-9]{2}-[0-9]{2}:[0-9]{2}";
export const RAW_DATE_REG3 =
  "^[0-9]{4}[-][0-9]{2}[-][0-9]{2}T[0-9]{2}[:][0-9]{2}[:][0-9]{2}[+][0-9]{2}[:][0-9]{2}";
export const NUMBER_REG = /^\d+(\.\d+)?$/;

// Colors
export const HIDDEN_ROW_BG_COLOR = "rgba(0, 0, 0, 0.03)";

export const API_REQUEST_CANCEL_MSG =
  "Operation Canceled due to component unmount.";
