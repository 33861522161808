// import _ from "lodash";
import moment from "moment";
import React from "react";
import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
  rectangle13: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 6,
    height: 500,
    width: "100%",
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    minWidth: 650,
  },
  compTitle: {
    color: "#023059",
    fontSize: 14,
    marginBottom: 20,
  },
  topSpace: {
    marginTop: 20,
  },
  IndicatorIcon: {
    // border: "0mm solid #023059",
    backgroundColor: "#F1F3F8",
    color: "#023059",
    borderRadius: "50%",
    fontSize: 22,
    fontWeight: 550,
    padding: "8px 16px",
  },
  listItem2: {
    flexDirection: "row",
  },
  dataUser: {
    marginLeft: "auto",
    textAlign: "right",
  },
  changeValue: {
    color: "#023059",
    fontSize: 14,
    fontWeight: "bold",
  },
  changeDescription: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    // height: 40,
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  changeDate: {
    color: "#565682",
    fontSize: 14,
    fontWeight: "bold",
  },
  changeUser: {
    fontSize: 12,
  },
}));

const RecentActivity = ({ recentActivity }) => {
  const classes = useStyles();

  recentActivity = recentActivity.sort(function compare(a, b) {
    var dateA = new Date(a.dateChanged);
    var dateB = new Date(b.dateChanged);
    return dateB - dateA;
  });

  return (
    <Box className={classes.rectangle13}>
      <Typography variant='subtitle2' className={classes.compTitle}>
        Recent Activity
      </Typography>
      <Box style={{ overflow: "auto", height: 430 }}>
        {recentActivity ? (
          recentActivity.map((activity, i) => (
            <Grid key={i} container spacing={0}>
              <Grid item sm={9}>
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <span className={classes.IndicatorIcon}>
                      {activity.fieldChanged.charAt(0)}
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.changeValue,
                      secondary: classes.changeDescription,
                    }}
                    primary={
                      activity.dealName == null
                        ? "Generic"
                        : activity.dealName.charAt(0).toUpperCase() +
                          activity.dealName.slice(1)
                    }
                    // secondary={<><span>{activity.description} </span><span>{activity.serialNumber}</span></>} />
                    secondary={
                      activity.serialNumber
                        ? `${activity.description}, SN: ${activity.serialNumber}`
                        : activity.description
                    }
                  />
                </ListItem>
              </Grid>
              <Grid item sm={3} className={classes.dataUser}>
                <ListItem className={classes.listItem2}>
                  <ListItemText
                    className={classes.dataUser}
                    classes={{
                      primary: classes.changeDate,
                      secondary: classes.changeUser,
                    }}
                    primary={moment(activity.dateChanged).format(
                      Constants.DATE_FORMAT
                    )}
                    secondary={activity.user}
                  />
                </ListItem>
              </Grid>
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default RecentActivity;
