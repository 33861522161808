import axios from "axios";
import appConfig from "../../appConfig";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";

const BASE_URL = appConfig.REACT_APP_ASSETMODEL_API_URL;

// getting an access token for each API call. these access tokens expire in 1 hour.
// we are able to get the access token because the user & refresh tokens were setup in the index.js file
const acquireAccessTokenAsync = async (msalContext) => {
  const { instance, inProgress, accounts } = msalContext;

  const accessTokenRequest = {
    scopes: loginRequest.scopes,
    account: instance.getActiveAccount() || accounts[0],
  };

  if (inProgress === InteractionStatus.None) {
    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      return accessTokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(accessTokenRequest);
        console.log("REDIRECTING");
      }
      console.log(`useApiRequest: ${error}`);
    }
  }
};

export const response = {
  OK: 200,
  PUT_OK: 204,
  POST_OK: 201,
  NOT_FOUND: 404,
};

export const apiRequest = async function (
  options,
  msalContext,
  customURL = false
) {
  // const { instance, inProgress, accounts } = msalContext;
  const accessToken = await acquireAccessTokenAsync(msalContext);

  if (accessToken) {
    return axios({
      baseURL: customURL ? null : BASE_URL,
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": options.headers
          ? options.headers["Content-Type"]
          : "application/json",
        "Access-Control-Allow-Origin": "*",
        ...options.headers,
      },
    });
  }
};

export default apiRequest;
