import { generateGenericComponent } from "./genericComponent";

export const generateLandingGear = (position, genericComponent) => {
  const returnMe = {
    position: position,
    partNumber: null,
    serialNumber: null,
    genericId: 0,
    genericMxInfo: [],
    shopVisitHistory: [],
    maintenanceStatus: [],
    maintenanceEvents: [],
    utilizationHistory: [],
    componentType: genericComponent.componentType.type,
    vfStackSeries: null,
  };

  returnMe.genericId = genericComponent.id;
  returnMe.genericMxInfo = generateGenericComponent(genericComponent);

  return returnMe;
};
