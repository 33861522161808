import React, { useState, useEffect } from "react";
import { Box, TableContainer } from "@material-ui/core";
import VerticalTableBody from "./VerticalTableBody";
import TableNamePanel from "../../TableNamePanel";
import ThreeDotButton from "../../ThreeDotButton";
import * as constants from "../../../../constants";
import { Edit, AddBox, Visibility } from "@material-ui/icons";
import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel";

const newRowColumns = [
  {
    label: ["Row Title"],
    field: "rowTitle",
    editable: true,
    required: true,
    type: constants.STRING,
    inputFieldType: constants.TEXT_FIELD,
  },
  {
    label: ["Sub Title"],
    field: "primaryValue",
    editable: true,
    multiline: true,
    numRows: 2,
    required: false,
    type:  constants.STRING,
    inputFieldType: constants.TEXT_FIELD,
  },
  {
    label: ["Body"],
    field: "secondaryValue",
    editable: true,
    multiline: true,
    numRows: 3,
    required: false,
    type: constants.STRING,
    inputFieldType: constants.TEXT_FIELD,
  },
  {
    label: ["Foot Notes"],
    field: "footNote",
    editable: true,
    multiline: true,
    numRows: 3,
    required: false,
    type: constants.STRING,
    inputFieldType: constants.TEXT_FIELD,
  },
]

const newRowColumnData = {
  rowTitle: null,
  primaryValue: null,
  secondaryValue: null,
  footNote: null,
}

const VerticalTableContainer = ({ tableData, editAll }) => {
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const marginBottom = 10;

  useEffect(() => {
    if (editAll) setEditing(true)
  }, [editAll]);

  // Add or Edit opens up editing pop up, thus these buttons only have fixed behavior.
  let threeDotBtnOptions = [];
  if (tableData.tableThreeDotBtnOptions) {
    for (let i = 0; i < tableData.tableThreeDotBtnOptions.length; i++) {
      if (
        constants.EDIT === tableData.tableThreeDotBtnOptions[i].label ||
        tableData.tableThreeDotBtnOptions[i].label.includes("Add")
      ) {
        tableData.tableThreeDotBtnOptions[i].handleClick = () => setOpen(!open);
      }
      threeDotBtnOptions.push(tableData.tableThreeDotBtnOptions[i]);
    }
  }

  // default button, you hide it by setting showOptions = false
  if (!threeDotBtnOptions.length)
    threeDotBtnOptions.push({
      id: 0,
      icon: Edit,
      label: constants.EDIT,
      handleClick: () => setEditing(!editing),
    });

  const defaultOptions = [
    {
      id: 0,
      icon: Visibility,
      label: constants.VIEW,
      handleClick: () => setEditing(!editing),
    },
    {
      id: 1,
      icon: AddBox,
      label: constants.ADD_ROW,
      handleClick: () => setOpen(!open),
    },
  ];
    
  let props = tableData.props
  const {
    section,
    funcs: { handleRowAdd, validateData },
  } = props
  const handleValuesUpdate = handleRowAdd

  return (
    <>{tableData.label && (
      <Box style={{ marginBottom }}>
        <TableNamePanel
          label={tableData.label}
          caption={tableData.caption}
          buttons={
            tableData.showOptions
              ? [<ThreeDotButton options={editing ? defaultOptions : threeDotBtnOptions} />]
              : null
          }
          data={tableData.data}
        />
      </Box>
    )}
  
    <Box style={{ marginBottom }}>
      {<TableContainer
        component={Box} >
          <VerticalTableBody
            tableData={tableData}
            editing={editing}
            newRowColumns={newRowColumns}
            />
      </TableContainer>}
    </Box>

    <MultiFieldsFadeInEditingPanel
      open={open}
      columns={newRowColumns}
      data={newRowColumnData}
      tableName={tableData.tableName}
      // // errorsOnSubmit={tableData.errorsOnSubmit}
      props={{ 
        section: section,
        funcs: {handleValuesUpdate, validateData} }}
      onClose={() => setOpen(false)}
      />
    
    </>
  )
};

export default VerticalTableContainer;