import React, { useState, useEffect, useMemo } from "react";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
// import SortIcon from "@material-ui/icons/Sort";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import MultiFieldsEditComponent from "../../Common/Editing/EditingComponents/MultiFieldsEditComponent";
import { mapToViewModel_pool } from "../mapToViewModels/poolViewModel";
import SectionHeader from "../../Common/Section/SectionHeader";

const useStyles = makeStyles({
  root: { maxWidth: "100%", margin: "auto" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  threeDotButton: { display: "inline-flex", float: "right" },
});

const PoolHeading = ({ searchPoolName, searchPool, handleAssetPoolAdd }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [poolNameQuery, setPoolNameQuery] = useState(searchPoolName);
  // const props = {
  //   funcs: handleAssetPoolAdd,
  // };
  const props = {
    funcs: { handleValuesUpdate: handleAssetPoolAdd, validateData: () => true },
  };

  const handleDebouncedSearch = useMemo(
    () =>
      _.debounce((query) => {
        searchPool(query);
      }, 500),
    [searchPool]
  );

  const handleSearch = (event) => {
    const searchQuery = event.currentTarget.value;
    setPoolNameQuery(searchQuery);
    handleDebouncedSearch(searchQuery);
  };

  useEffect(() => {
    return () => {
      handleDebouncedSearch.cancel();
    };
  }, [handleDebouncedSearch]);
  useEffect(() => {
    setPoolNameQuery(searchPoolName);
  }, [searchPoolName]);

  return (
    <Box className={classes.root}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "48px 0 8px 0",
        }}>
        <SectionHeader label={"Asset Pools"} />

        <Box
          alignItems='center'
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
          }}>
          <Box style={{ display: "flex", justifyContent: "flex-start" }}>
            <TextField
              id='input-with-icon-textfield-searchPoolName'
              style={{ maxHeight: 28, margin: "12px 8px 0px 8px" }}
              label={
                <Typography
                  variant='button'
                  color='primary'
                  style={{ fontSize: 14, maxHeight: 28 }}>
                  SEARCH
                </Typography>
              }
              InputLabelProps={{ style: { top: -8 } }}
              inputProps={{ style: { height: 8 } }}
              variant='outlined'
              size='small'
              onChange={handleSearch}
              value={poolNameQuery}
            />
          </Box>
          <Button
            style={{ maxHeight: 28, margin: "12px 0 0 16px" }}
            variant='outlined'
            color='primary'
            onClick={() => setOpen(true)}>
            <span style={{ margin: "0px 8px 0px 8px" }}>{"Add Pool"}</span>
          </Button>
        </Box>

        <Modal
          open={open}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ width: "100%" }}>
          <Fade in={open}>
            <MultiFieldsEditComponent
              columns={mapToViewModel_pool(null).columns}
              props={props}
              onClose={() => setOpen(false)}
              tableName={"Add Pool"}
            />
          </Fade>
        </Modal>
      </Box>
    </Box>
  );
};

export default PoolHeading;
