import _ from "lodash";
import * as constants from "../../../../constants";

export const updateRunoutOverrides = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
) => {
  const parameters = scenario.parameters
  if (actionType === constants.UPDATE_ROW) {
    newData ?
    parameters.runoutFloorValues = {
        airframeValue:newData.airframeValue,
        combinedLandingGearValue: newData.combinedLandingGearValue,
        apuValue: newData.apuValue,
        singleEngineValue: newData.singleEngineValue
    }
    :
    parameters.runoutFloorValues = null;
    ;
  }

  return scenario;
};