import _, { forEach } from "lodash";
import moment from "moment";
import * as constants from "../../../../../../../constants";

const checkStatus = (comp, currentLease, llp = false) => {
  let maintStatus = null;
  if (llp)
    maintStatus = _.find(comp.llpStack.llPs[0].status, (status) =>
      moment(status.statusDate).isSame(
        currentLease.leaseStartDate,
        "hour",
        "[]"
      )
    );
  else
    maintStatus = _.find(comp.maintenanceStatus, (status) =>
      moment(status.statusAsOf).isSame(
        currentLease.leaseStartDate,
        "hour",
        "[]"
      )
    );
  return _.isEmpty(maintStatus) ? true : false;
};

const checkBalance = (reserveAccount, lease) => {
  var returnme = false;
  var s = lease.leaseStartDate;
  var e = lease.leaseEndDate;
  reserveAccount.cashBalanceByDate.forEach((b) => {
    if (s <= b.date && e >= b.date) {
      //console.log(b);
      returnme = true;
    }
  });
  return returnme;
};

export const simulationValidation = (assetDetails, scenario) => {
  let errorMessages = [];
  let validationSuccess = false;
  let simulationDate = moment
    .utc(new Date())
    .subtract(1, "months")
    .endOf("month");
  // let leases = scenario.leases;
  // let currentLease = leases[leases.length - 1]
  let currentLease = _.find(scenario.leases, (lease) =>
    moment(simulationDate).isBetween(
      lease.leaseStartDate,
      lease.leaseEndDate,
      "hour",
      "[]"
    )
  );
  //check start / end dates
  scenario.leases.forEach((lease) => {
    if (lease.leaseStartDate > lease.leaseEndDate)
      errorMessages.push({
        checkName: "Aircraft",
        checkType: "Lease Dates",
        errorMessage:
          "Lease from " +
          lease.leaseStartDate +
          " through " +
          lease.leaseEndDate +
          " ends before it begins.",
      });
  });

  // let currentLeaseReserveAccounts = currentLease
  //   ? _.flattenDeep(currentLease.reserveAccounts.map((acc) => acc.components))
  //   : [];

  if (!_.isEmpty(assetDetails.airframeChecks)){
    // check if there DOM for the aircraft
    if (!assetDetails.manufactureDate) {
      errorMessages.push({
        checkName: "Aircraft",
        checkType: "Other",
        errorMessage:
          "aircraft: " +
          assetDetails.msn +
          " does not have date of manufacture.",
      });
    }

    assetDetails.airframeChecks.forEach((afc) => {
      // staus check also check to make sure at least one value is entered
      if (afc.maintenanceStatus === null || _.isEmpty(afc.maintenanceStatus)) {
        errorMessages.push({
          checkName: "Aircraft",
          checkType: "MX Status",
          errorMessage:
            "airframe check" + afc.checkName + " Does not have a check status",
        });
      }

      let mostRecentStatus = afc.maintenanceStatus.sort(function (a, b) {
        return new Date(b.statusAsOf) - new Date(a.statusAsOf);
      })[0];
      let sinceEvent = mostRecentStatus.sinceEvent;
      //use generic interval UNLESS
      let isFirstRun =
        afc.maintenanceStatus.length !== 0 &&
        afc.maintenanceStatus[0].isFirstRun;

      const { interval } = _.find(
        afc.genericMxInfo,
        (o) => !(o.isFirstRun ^ isFirstRun)
      );

      let genover = interval;
      let findIntervalOverride = _.find(
        scenario.intervalOverrides,
        (interval) => interval.component === afc.checkName
      );
      // Override for first shop visit exists
      if (findIntervalOverride) {
        let intervalOverride = _.find(
          findIntervalOverride.overrides,
          (visit) => visit.shopVisitNumber === 1
        );
        genover = intervalOverride ? intervalOverride.newInterval : genover;
      }

      // toevent check
      if (sinceEvent.activeHour != null) {
        if (sinceEvent.activeHour > parseInt(genover.activeHour))
          errorMessages.push({
            checkName: "Aircraft",
            checkType: "Interval Check",
            errorMessage:
              "Active Hour ToEvent Cannot Be Negative for airframe check " +
              afc.checkName,
          });
      }
      if (sinceEvent.day != null) {
        if (sinceEvent.day > parseInt(genover.day))
          errorMessages.push({
            checkName: "Aircraft",
            checkType: "Interval Check",
            errorMessage:
              "Day ToEvent Cannot Be Negative for airframe check " +
              afc.checkName,
          });
      }
      if (sinceEvent.flightCycle != null) {
        if (sinceEvent.flightCycle > parseInt(genover.flightCycle))
          errorMessages.push({
            checkName: "Aircraft",
            checkType: "Interval Check",
            errorMessage:
              "FlightCycle ToEvent Cannot Be Negative for airframe check " +
              afc.checkName,
          });
      }
      if (sinceEvent.flightHour != null) {
        if (sinceEvent.flightHour > parseInt(genover.flightHour))
          errorMessages.push({
            checkName: "Aircraft",
            checkType: "Interval Check",
            errorMessage:
              "FlightHour ToEvent Cannot Be Negative for airframe check" +
              afc.checkName,
          });
      }

      // utilization checks
      let airframeUtilization = false;
      scenario.expectedUtilizations.forEach((utilization) => {
        if (utilization.components.includes("Airframe")) {
          utilization.utilizationByDate.forEach((byDate) => {
            if (
              moment(mostRecentStatus.statusAsOf).isBetween(
                byDate.startDate,
                byDate.endDate,
                "hour",
                "[]"
              )
            )
              airframeUtilization = true;
          });
        }
      });
      if (!airframeUtilization) {
        errorMessages.push({
          checkName: "Aircraft",
          checkType: "Utilizations",
          errorMessage:
            afc.checkName +
            "'s Most Recent Status Is Not Covered By the utilizations Provided",
        });
      }

      // reserve accounts check
      // if (!_.isEmpty(scenario.leases)) {
      //   scenario.leases.forEach((lease) => {
      //     let accountComponents = _.flattenDeep(
      //       lease.reserveAccounts.map((a) => a.components)
      //     );
      //     if (
      //       accountComponents.includes(afc.checkName) &&
      //       afc.reserveAccount == null
      //     )
      //       errorMessages.push({
      //         checkName: "Aircraft",
      //         checkType: "Reserve Accounts",
      //         errorMessage:
      //           "No Reserve Account Entered in Asset Summary for " +
      //           afc.checkName,
      //       });
      //     // else if(
      //     //   accountComponents.includes(afc.checkName) && !checkBalance(afc.reserveAccount, lease)
      //     // ){
      //     //   errorMessages.push({
      //     //     checkName: "Aircraft",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered within lease dates "+lease.leaseStartDate+", "+lease.leaseEndDate+" in Asset Summary for " +
      //     //       afc.checkName,
      //     //   });
      //     // }

      //     let leaseCompInfo = _.find(
      //       lease.componentLeaseInfo,
      //       (comp) => comp.componentName === afc.checkName
      //     );

      //     // // check when maintenanceReserveRates table Reserve Type	is "CASH" then reserve account for component must be in Cash reserver account table in leases.
      //     // if (
      //     //   leaseCompInfo &&
      //     //   !_.isEmpty(leaseCompInfo.maintenanceReserveRates) &&
      //     //   leaseCompInfo.maintenanceReserveRates[0].rateType ===
      //     //     constants.CASH &&
      //     //   !accountComponents.includes(leaseCompInfo.componentName)
      //     // )
      //     //   errorMessages.push({
      //     //     checkName: "Aircraft",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered in Cash Reserve Accounts table for " +
      //     //       afc.checkName,
      //     //   });
      //   });
      // }
    });
  }
  if (assetDetails.engines) {
    assetDetails.engines.forEach((eng) => {
      // staus check also check to make sure at least one value is entered
      if (eng.maintenanceStatus === null || _.isEmpty(eng.maintenanceStatus)) {
        errorMessages.push({
          checkName: "Engine",
          checkType: "MX Status",
          errorMessage: eng.position + " Does not have a maintenance status",
        });
      }
      if (
        eng.llpStack.llPs[0].status == null ||
        _.isEmpty(eng.llpStack.llPs[0].status)
      ) {
        errorMessages.push({
          checkName: "Engine LLP",
          checkType: "MX Status",
          errorMessage:
            "LLPStack for " +
            eng.position +
            " Does not have a maintenance status",
        });
      }

      let mostRecentStatus = eng.maintenanceStatus.sort(function (a, b) {
        return new Date(b.statusAsOf) - new Date(a.statusAsOf);
      })[0];
      let sinceEvent = mostRecentStatus.sinceEvent;
      //use generic interval UNLESS
      let isFirstRun =
        eng.maintenanceStatus.length !== 0 &&
        eng.maintenanceStatus[0].isFirstRun;

      const { interval } = _.find(
        eng.genericMxInfo,
        (o) => !(o.isFirstRun ^ isFirstRun)
      );

      let genover = interval;
      let findIntervalOverride = _.find(
        scenario.intervalOverrides,
        (interval) => interval.component === eng.position
      );
      // Override for first shop visit exists
      if (findIntervalOverride) {
        let intervalOverride = _.find(
          findIntervalOverride.overrides,
          (visit) => visit.shopVisitNumber === 1
        );
        genover = intervalOverride ? intervalOverride.newInterval : genover;
      }

      // toevent check
      if (sinceEvent.activeHour != null) {
        if (sinceEvent.activeHour > parseInt(genover.activeHour))
          errorMessages.push({
            checkName: "Engine",
            checkType: "Interval Check",
            errorMessage:
              "Active Hour ToEvent Cannot Be Negative for " + eng.position,
          });
      }
      if (sinceEvent.day != null) {
        if (sinceEvent.day > parseInt(genover.day))
          errorMessages.push({
            checkName: "Engine",
            checkType: "Interval Check",
            errorMessage: "Day ToEvent Cannot Be Negative for " + eng.position,
          });
      }
      if (sinceEvent.flightCycle != null) {
        if (sinceEvent.flightCycle > parseInt(genover.flightCycle))
          errorMessages.push({
            checkName: "Engine",
            checkType: "Interval Check",
            errorMessage:
              "FlightCycle ToEvent Cannot Be Negative for " + eng.position,
          });
      }
      if (sinceEvent.flightHour != null) {
        if (sinceEvent.flightHour > parseInt(genover.flightHour))
          errorMessages.push({
            checkName: "Engine",
            checkType: "Interval Check",
            errorMessage:
              "FlightHour ToEvent Cannot Be Negative for " + eng.position,
          });
      }

      // get lastest llp status
      let mostRecentllpStatus = eng.llpStack.llPs[0].status.sort(function (
        a,
        b
      ) {
        return new Date(b.statusDate) - new Date(a.statusDate);
      })[0];

      // utilization checks
      let engineUtilization = false;
      let ellpUtilization = false;
      let ellpMissingStatus = false;
      scenario.expectedUtilizations.forEach((utilization) => {
        if (utilization.components.includes(eng.position)) {
          utilization.utilizationByDate.forEach((byDate) => {
            if (
              moment(mostRecentStatus.statusAsOf).isBetween(
                byDate.startDate,
                byDate.endDate,
                "hour",
                "[]"
              )
            )
              engineUtilization = true;
            if (!mostRecentllpStatus) ellpMissingStatus = true;
            else if (
              moment(mostRecentllpStatus.statusDate).isBetween(
                byDate.startDate,
                byDate.endDate,
                "hour",
                "[]"
              )
            )
              ellpUtilization = true;
          });
        }
      });

      if (!engineUtilization) {
        errorMessages.push({
          checkName: "Engine",
          checkType: "Utilizations",
          errorMessage:
            eng.position +
            "'s Most Recent Status Is Not Covered By the utilizations Provided",
        });
      }

      if (!ellpUtilization) {
        errorMessages.push({
          checkName: "Engine LLP",
          checkType: "Utilizations",
          errorMessage:
            eng.position +
            "'s Most Recent LLP Status Is Not Covered By the utilizations Provided",
        });
      }
      if (ellpMissingStatus) {
        errorMessages.push({
          checkName: "Engine LLP",
          checkType: "Utilizations",
          errorMessage: eng.position + " is missing LLP status",
        });
      }

      // reserve accounts check
      // if (!_.isEmpty(scenario.leases)) {
      //   scenario.leases.forEach((lease) => {
      //     let accountComponents = _.flattenDeep(
      //       lease.reserveAccounts.map((a) => a.components)
      //     );
      //     if (
      //       accountComponents.includes(eng.position) &&
      //       eng.reserveAccount == null
      //     )
      //       errorMessages.push({
      //         checkName: "Engine",
      //         checkType: "Reserve Accounts",
      //         errorMessage:
      //           "No Reserve Account Entered in Asset Summary for " +
      //           eng.position,
      //       });
      //     // else if(
      //     //     accountComponents.includes(eng.position) && !checkBalance(eng.reserveAccount, lease)
      //     //   ){
      //     //     errorMessages.push({
      //     //       checkName: "Engine",
      //     //       checkType: "Reserve Accounts",
      //     //       errorMessage:
      //     //         "No Reserve Account Entered within lease dates "+lease.leaseStartDate+", "+lease.leaseEndDate+" in Asset Summary for " +
      //     //         eng.position,
      //     //     });
      //     //   }

      //     // find component lease info from leases to get maintenance reserve rates information
      //     let leaseCompInfo = _.find(
      //       lease.componentLeaseInfo,
      //       (comp) => comp.componentName === eng.position
      //     );

      //     let leaseCompInfollp = _.find(
      //       lease.componentLeaseInfo,
      //       (comp) => comp.componentName === `LLP Stack ${eng.position}`
      //     );

      //     // // check when maintenanceReserveRates table Reserve Type	is "CASH" then reserve account for component must be in Cash reserver account table in leases.
      //     // if (
      //     //   leaseCompInfo &&
      //     //   !_.isEmpty(leaseCompInfo.maintenanceReserveRates) &&
      //     //   leaseCompInfo.maintenanceReserveRates[0].rateType ===
      //     //     constants.CASH &&
      //     //   !accountComponents.includes(leaseCompInfo.componentName)
      //     // )
      //     //   errorMessages.push({
      //     //     checkName: "Engine",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered in Cash Reserve Accounts table for " +
      //     //       eng.position,
      //     //   });

      //     // if (
      //     //   leaseCompInfollp &&
      //     //   !_.isEmpty(leaseCompInfollp.maintenanceReserveRates) &&
      //     //   leaseCompInfollp.maintenanceReserveRates[0].rateType ===
      //     //     constants.CASH &&
      //     //   !accountComponents.includes(leaseCompInfollp.componentName)
      //     // )
      //     //   errorMessages.push({
      //     //     checkName: "Engine LLP",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered in Cash Reserve Accounts table for LLP Stack " +
      //     //       eng.position,
      //     //   });

      //     // handle check for supplymental events
      //   });
      // }
    });
  }

  if (assetDetails.landingGears) {
    assetDetails.landingGears.forEach((lg) => {
      // staus check also check to make sure at least one value is entered
      if (lg.maintenanceStatus === null || _.isEmpty(lg.maintenanceStatus)) {
        errorMessages.push({
          checkName: "Landing Gear",
          checkType: "MX Status",
          errorMessage:
            "Landing Gear " +
            lg.position +
            " Does not have a maintenance status",
        });
      } else {
        let mostRecentStatus = lg.maintenanceStatus.sort(function (a, b) {
          return new Date(b.statusAsOf) - new Date(a.statusAsOf);
        })[0];

        //use generic interval UNLESS
        let isFirstRun =
          lg.maintenanceStatus.length !== 0 &&
          lg.maintenanceStatus[0].isFirstRun;

        const { interval } = _.find(
          lg.genericMxInfo,
          (o) => !(o.isFirstRun ^ isFirstRun)
        );

        let genover = interval;
        let findIntervalOverride = _.find(
          scenario.intervalOverrides,
          (interval) => interval.component === lg.position
        );
        // Override for first shop visit exists
        if (findIntervalOverride) {
          let intervalOverride = _.find(
            findIntervalOverride.overrides,
            (visit) => visit.shopVisitNumber === 1
          );
          genover = intervalOverride ? intervalOverride.newInterval : genover;
        }

        let sinceEvent = mostRecentStatus.sinceEvent;
        // toevent check
        if (sinceEvent.activeHour != null) {
          if (sinceEvent.activeHour > parseInt(genover.activeHour))
            errorMessages.push({
              checkName: "Landing Gear",
              checkType: "Interval Check",
              errorMessage:
                "Active Hour ToEvent Cannot Be Negative for Landing Gear " +
                lg.position,
            });
        }
        if (sinceEvent.day != null) {
          if (sinceEvent.day > parseInt(genover.day))
            errorMessages.push({
              checkName: "Landing Gear",
              checkType: "Interval Check",
              errorMessage:
                "Day ToEvent Cannot Be Negative for Landing Gear " +
                lg.position,
            });
        }
        if (sinceEvent.flightCycle != null) {
          if (sinceEvent.flightCycle > parseInt(genover.flightCycle))
            errorMessages.push({
              checkName: "Landing Gear",
              checkType: "Interval Check",
              errorMessage:
                "FlightCycle ToEvent Cannot Be Negative for Landing Gear " +
                lg.position,
            });
        }
        if (sinceEvent.flightHour != null) {
          if (sinceEvent.flightHour > parseInt(genover.flightHour))
            errorMessages.push({
              checkName: "Landing Gear",
              checkType: "Interval Check",
              errorMessage:
                "FlightHour ToEvent Cannot Be Negative for Landing Gear " +
                lg.position,
            });
        }

        // utilization checks
        let lgUtilization = false;
        scenario.expectedUtilizations.forEach((utilization) => {
          if (utilization.components.includes("Landing Gears")) {
            utilization.utilizationByDate.forEach((byDate) => {
              if (
                moment(mostRecentStatus.statusAsOf).isBetween(
                  byDate.startDate,
                  byDate.endDate,
                  "hour",
                  "[]"
                )
              )
                lgUtilization = true;
            });
          }
        });
        if (!lgUtilization) {
          errorMessages.push({
            checkName: "Landing Gear",
            checkType: "Utilizations",
            errorMessage:
              "Landing Gear " +
              lg.position +
              "'s Most Recent Status Is Not Covered By the utilizations Provided",
          });
        }
      }

      // reserve accounts check
      // if (!_.isEmpty(scenario.leases)) {
      //   scenario.leases.forEach((lease) => {
      //     let accountComponents = _.flattenDeep(
      //       lease.reserveAccounts.map((a) => a.components)
      //     );
      //     if (
      //       accountComponents.includes(`LG ${lg.position}`) &&
      //       lg.reserveAccount == null
      //     )
      //       errorMessages.push({
      //         checkName: "Landing Gear",
      //         checkType: "Reserve Accounts",
      //         errorMessage:
      //           "No Reserve Account Entered in Asset Summary for LG " +
      //           lg.position,
      //       });
      //     // else if(
      //     //   accountComponents.includes(lg.position) && !checkBalance(lg.reserveAccount, lease)
      //     // ){
      //     //   errorMessages.push({
      //     //     checkName: "Aircraft",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered within lease dates "+lease.leaseStartDate+", "+lease.leaseEndDate+" in Asset Summary for " +
      //     //       lg.position,
      //     //   });
      //     // }

      //     let leaseCompInfo = _.find(
      //       lease.componentLeaseInfo,
      //       (comp) => comp.componentName === lg.position
      //     );

      //     // // check when maintenanceReserveRates table Reserve Type	is "CASH" then reserve account for component must be in Cash reserver account table in leases.
      //     // if (
      //     //   leaseCompInfo &&
      //     //   !_.isEmpty(leaseCompInfo.maintenanceReserveRates) &&
      //     //   leaseCompInfo.maintenanceReserveRates[0].rateType ===
      //     //     constants.CASH &&
      //     //   !accountComponents.includes(`LG ${leaseCompInfo.componentName}`)
      //     // )
      //     //   errorMessages.push({
      //     //     checkName: "Landing Gear",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered in Cash Reserve Accounts table for LG " +
      //     //       lg.position,
      //     //   });
      //   });
      // }
    });
  }
  if (assetDetails.apUs) {
    assetDetails.apUs.forEach((apu) => {
      // staus check also check to make sure at least one value is entered
      if (apu.maintenanceStatus === null || _.isEmpty(apu.maintenanceStatus)) {
        errorMessages.push({
          checkName: "APU",
          checkType: "MX Status",
          errorMessage:
            "APU model " + apu.model + " Does not have a maintenance status",
        });
      }

      let mostRecentStatus = apu.maintenanceStatus.sort(function (a, b) {
        return new Date(b.statusAsOf) - new Date(a.statusAsOf);
      })[0];

      let sinceEvent = mostRecentStatus.sinceEvent;

      //use generic interval UNLESS
      let isFirstRun =
        apu.maintenanceStatus.length !== 0 &&
        apu.maintenanceStatus[0].isFirstRun;

      const { interval } = _.find(
        apu.genericMxInfo,
        (o) => !(o.isFirstRun ^ isFirstRun)
      );

      let genover = interval;
      let findIntervalOverride = _.find(
        scenario.intervalOverrides,
        (interval) => interval.component === "APU"
      );
      // Override for first shop visit exists
      if (findIntervalOverride) {
        let intervalOverride = _.find(
          findIntervalOverride.overrides,
          (visit) => visit.shopVisitNumber === 1
        );
        genover = intervalOverride ? intervalOverride.newInterval : genover;
      }

      // toevent check
      if (sinceEvent.activeHour != null) {
        if (sinceEvent.activeHour > parseInt(genover.activeHour))
          errorMessages.push({
            checkName: "APU",
            checkType: "Interval Check",
            errorMessage: "Active Hour ToEvent Cannot Be Negative for APU",
          });
      }

      // utilization checks
      let apuUtilization = false;
      scenario.expectedUtilizations.forEach((utilization) => {
        if (utilization.components.includes("APU")) {
          utilization.utilizationByDate.forEach((byDate) => {
            if (
              moment(mostRecentStatus.statusAsOf).isBetween(
                byDate.startDate,
                byDate.endDate,
                "hour",
                "[]"
              )
            )
              apuUtilization = true;
          });
        }
      });
      if (!apuUtilization) {
        errorMessages.push({
          checkName: "APU",
          checkType: "Utilizations",
          errorMessage:
            "APU model" +
            apu.model +
            "'s Most Recent Status Is Not Covered By the utilizations Provided",
        });
      }

      // reserve accounts check
      // if (!_.isEmpty(scenario.leases)) {
      //   scenario.leases.forEach((lease) => {
      //     let accountComponents = _.flattenDeep(
      //       lease.reserveAccounts.map((a) => a.components)
      //     );
      //     if (accountComponents.includes("APU") && apu.reserveAccount == null)
      //       errorMessages.push({
      //         checkName: "APU",
      //         checkType: "Reserve Accounts",
      //         errorMessage:
      //           "No Reserve Account Entered in Asset Summary for APU model " +
      //           apu.model,
      //       });
      //     // else if(
      //     //   accountComponents.includes("APU") && !checkBalance(apu.reserveAccount, lease)
      //     // ){
      //     //   errorMessages.push({
      //     //     checkName: "Aircraft",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered within lease dates "+lease.leaseStartDate+", "+lease.leaseEndDate+" in Asset Summary for APU"
      //     //   });
      //     // }

      //     let leaseCompInfo = _.find(
      //       lease.componentLeaseInfo,
      //       (comp) => comp.componentName === apu.componentType
      //     );

      //     // // check when maintenanceReserveRates table Reserve Type	is "CASH" then reserve account for component must be in Cash reserver account table in leases.
      //     // if (
      //     //   leaseCompInfo &&
      //     //   !_.isEmpty(leaseCompInfo.maintenanceReserveRates) &&
      //     //   leaseCompInfo.maintenanceReserveRates[0].rateType ===
      //     //     constants.CASH &&
      //     //   !accountComponents.includes(leaseCompInfo.componentName)
      //     // )
      //     //   errorMessages.push({
      //     //     checkName: "APU",
      //     //     checkType: "Reserve Accounts",
      //     //     errorMessage:
      //     //       "No Reserve Account Entered in Cash Reserve Accounts table for APU model " +
      //     //       apu.componentType,
      //     //   });
      //   });
      // }
    });
  }

  if (_.isEmpty(errorMessages)) {
    validationSuccess = true;
  }

  return { validationSuccess, errorMessages };
};
