import _ from "lodash";
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Box, Button, Badge, Tooltip, Slide } from "@material-ui/core";
import { apiRequest, response } from "../../../../../../../utils/ApiRequest";
import ApiRoutes from "../../../../../../../utils/ApiRoutes";
import * as utils from "../../../../../../../utils/utils";
import * as constants from "../../../../../../../constants";
import { simulationValidation } from "../SimulationValidation/simulationValidation";
import SlideInErrorPanel from "../SimulationValidation/SlideInErrorPanel";
import VfAlertDialogBox from "../../../../../../Common/UtilComponents/VfAlertDialogBox";

const StyledBadge = withStyles((theme) => ({
  badge: {
    //   right: -2,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const runScenarioSiminBE = async (selectedScenario, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.VectorDeal_ScenarioSimulate({
        id: selectedScenario.id,
      }),
      method: "POST",
      headers: { "Content-Type": "text/json" },
    },
    msalContext
  );
};

const runScenarioSim = async (
  selectedScenario,
  handleScenarioLoading,
  handleSnackerBarOpen,
  msalContext
) => {
  try {
    const result = await runScenarioSiminBE(selectedScenario, msalContext);
    if (result.status === response.OK) {
      handleSnackerBarOpen(
        true,
        "info",
        "Successfully Run Scenario Simulation."
      );
      setTimeout(() => {
        handleScenarioLoading(false, "simulate");
      }, 1500);
    } else {
      handleScenarioLoading(false);
      handleSnackerBarOpen(
        true,
        "warning",
        "Failed to Run Scenario Simulation."
      );
    }
  } catch (ex) {
    console.log(ex);
    var ermsg = `${ex}`;
    if(ex.response){
      console.log(ex.response);
      if(ex.response.data.includes("MxReserveAcct"))
        ermsg = "Reserve Account Balance Date Issue; check lease / CR dates";
      else
        ermsg = ex.response.data;
    }
      
      
    handleScenarioLoading(false);
    handleSnackerBarOpen(true, "error", ermsg, 5000);
  }
};

const ScenarioSimulation = ({
  classes,
  setOpenCreateScenario,
  assetDetails,
  scenario,
  currentState,
  selectedScenario,
  handleScenarioLoading,
  errors,
  showErrorIcon,
  setShowErrorIcon,
  handleSnackerBarOpen,
  msalContext,
}) => {
  const [openErrorPanel, setOpenErrorPanel] = useState(false);
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const error = errors.reportError;

  const handleSlidePanelClose = () => {
    setOpenErrorPanel(false);
  };

  const handleSimulationButtonClick = () => {
    const { validationSuccess, errorMessages } = simulationValidation(
      assetDetails,
      scenario
    );
    if (validationSuccess) {
      setShowErrorIcon(false);
      if (error) {
        handleScenarioLoading(true);
        runScenarioSim(
          selectedScenario,
          handleScenarioLoading,
          handleSnackerBarOpen,
          msalContext
        );
      } else {
        // setsimulateORexport(true);
        handleAlertDialogVisible(true);
      }
    } else {
      // handleSnackerBarOpen(true, "error", "There are errors that prevent simulation, please open the error panel for details.")
      setShowErrorIcon(true);
      setErrorMessages(errorMessages);
      setOpenErrorPanel(true);
    }
  };

  const handleAlertDialogVisible = async (open) => {
    setAlertDialogOpen(open);
  };

  return (
    <Box style={{ maxWidth: 100 }}>
      {selectedScenario && isAlertDialogOpen ? (
        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={handleAlertDialogVisible}
          dialogTitle={"Simulation Options"}
          dialogBody={
            "This scenario has already been simulated before, please duplicate current scenario and simulate."
          }
          onAccept={
            moment.utc(selectedScenario.date).format(constants.DATE_FORMAT) ===
            moment.utc(new Date()).format(constants.DATE_FORMAT)
              ? () => {
                  handleScenarioLoading(true);
                  runScenarioSim(
                    selectedScenario,
                    handleScenarioLoading,
                    handleSnackerBarOpen,
                    msalContext
                  );
                  handleAlertDialogVisible(false);
                }
              : null
          }
          customButton={{
            label: "Duplicate",
            handleClick: () => {
              currentState.duplicateScenario = true;
              setOpenCreateScenario(true);
            },
          }}
        />
      ) : null}

      <Tooltip title='Simulate Scenario' aria-label='Simulate Scenario'>
        <Button
          variant='outlined'
          color='primary'
          className={`${classes.buttonHeight} ${classes.runButton}`}
          onClick={() => {
            handleSimulationButtonClick();
          }}>
          <span
            style={{
              margin: "0px 8px 0px 8px",
              display: "flex",
              maxWidth: 100,
              justifyContent: "space-between",
            }}>
            {"RUN"}
            {showErrorIcon ? (
              <StyledBadge
                badgeContent={errorMessages.length}
                color='error'
                style={{ colorPrimary: "#E02020" }}
                classes={{ badge: classes.customBadge }}>
                <Box style={{ marginLeft: 20 }}> </Box>
              </StyledBadge>
            ) : (
              <></>
            )}
          </span>
        </Button>
      </Tooltip>

      <Box>
        <Slide direction='left' in={openErrorPanel} mountOnEnter unmountOnExit>
          <SlideInErrorPanel
            errorMessages={errorMessages}
            onSlidePanelClose={handleSlidePanelClose}
          />
        </Slide>
      </Box>
    </Box>
  );
};

export default ScenarioSimulation;
