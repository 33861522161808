import React from "react";

const DownloadIconSvg = ({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#023059",
  style = {
    fill: "grey",
  },
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} style={{ ...style }} >
      <g color={color}>
        <path d="M8 13h2.55v-3h2.9v3H16l-4 4l-4-4m11.35-2.96c2.6.18 4.65 2.32 4.65 4.96a5 5 0 0 1-5 5H6a6 6 0 0 1-6-6c0-3.09 2.34-5.64 5.35-5.96A7.496 7.496 0 0 1 12 4a7.49 7.49 0 0 1 7.35 6.04M19 18a3 3 0 0 0 3-3a2.98 2.98 0 0 0-2.78-2.96l-1.53-.11l-.3-1.5A5.503 5.503 0 0 0 12 6C9.94 6 8.08 7.14 7.13 8.97l-.5.95l-1.07.11A3.973 3.973 0 0 0 2 14a4 4 0 0 0 4 4h13z"/>
      </g>
    </svg>
  );
};

export default DownloadIconSvg;