import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState } from "@microsoft/signalr";
import { useMsal } from "@azure/msal-react";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import { useState, useEffect } from "react";
import appConfig from "../../appConfig";

//const BASE_URL = process.env.REACT_APP_ASSETMODEL_SIGNALR_URL;
const APP_URL = appConfig.REACT_APP_ASSETMODEL_SIGNALR_URL;

export const useSignalRHub = (hubName) => {
    const { instance, inProgress, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [isDisconnected, setIsDisconnected] = useState(true);
    const [hubConnection, setHubConnection] = useState(null);
    // console.log(process.env);
    // console.log(BASE_URL);
    // console.log(APP_URL);

    // used to force a reconnect to the SignalR hub
    const hardReconnect = () => {
        setIsDisconnected(hubConnection && hubConnection.state === HubConnectionState.Disconnected)
    }

    // get access token used for SignalR hub connection
    useEffect(() => {
        const acquireAccessTokenAsync = async () => {
            const accessTokenRequest = {
                scopes: loginRequest.scopes,
                account: instance.getActiveAccount() || accounts[0],
            };

            if (inProgress === InteractionStatus.None) {
                try {
                    const accessTokenResponse = await instance.acquireTokenSilent(
                        accessTokenRequest
                    );
                    setAccessToken(accessTokenResponse.accessToken);
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest);
                        console.log("REDIRECTING");
                    }
                    console.log(`SignalR token error: ${error}`);
                }
            }
        };
        acquireAccessTokenAsync();

    }, [instance, inProgress, accounts]);

    // create SignalR hub connection and start it
    useEffect(() => {
        if (accessToken && hubName && isDisconnected) {
            try {
                let connection = new HubConnectionBuilder()
                    .withUrl(`${APP_URL}${hubName}`, {
                        accessTokenFactory: () => accessToken,
                        transport: HttpTransportType.WebSockets,
                        skipNegotiation: true,
                    })
                    .withAutomaticReconnect()
                    .configureLogging(LogLevel.Information)
                    .build();
                    //connection.logging = true;
                connection
                    .start()
                    .then(() => {
                        console.log(`SignalR ${hubName} connection started`)
                        //console.log(connection);
                        
                        setHubConnection(connection);
                        setIsDisconnected(false);
                    });
            } catch (error) {
                console.log("There was an error establishing the SignalR connection: ", error);
            }
        }
    }, [accessToken, hubName, isDisconnected]);

    // stop SignalR hub connection when component unmounts
    useEffect(() => {
        return () => {
            if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
                hubConnection.stop();
                console.log(`SignalR ${hubName} connection stopped`)
            }
        }
    }, [hubConnection, hubName]);

    return { hubConnection, hardReconnect };
};