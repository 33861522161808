import { mapToViewModel_redeliveryLocation } from "./redeliveryLocation"
import { mapToViewModel_redeliveryConditions } from "./redeliveryCondition";
import { mapToViewModel_supRentatRedelivery } from "./suppRentRedelivery";

export function RedeliveryConditions(leaseSummary, props, threeDotBtnOptions) {
  return {
    redeliveryLocation: mapToViewModel_redeliveryLocation(leaseSummary, props),
    redeliveryConditions: mapToViewModel_redeliveryConditions(leaseSummary, props, threeDotBtnOptions),
    supRentatRedelivery: mapToViewModel_supRentatRedelivery(leaseSummary, props, threeDotBtnOptions),
  }
}