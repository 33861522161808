import _ from "lodash";
import React from "react";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export default function mapToViewModel_performanceRestAssump(
  apuData,
  props,
  selectedModel,
) {
  let selectedAPUData = _.find(apuData, { 'model': selectedModel });

  return {
    label: "Performance Restoration Assumptions",
    tableType: constants.DATA_TABLE,
    props,
    columns: [
      // {
      //   label: ["Interval Flight Hour"],
      //   field: "intervalFH",
      //   // captions: ["U.S. $"],
      //   span: [4, 4],
      //   indent: 0,
      //   content: (value) =>
      //     utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      // },
      // {
      //   label: ["Interval Flight Cycles"],
      //   field: "intervalFC",
      //   // captions: ["U.S. $"],
      //   span: [4, 4],
      //   indent: 0,
      //   content: (value) =>
      //     utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      // },
      
      {
        label: ["Cost"],
        field: "cost",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, "$"),
      },
      {
        label: ["Cost Date"],
        field: "costDate",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.DATE_CELL),
      },
      {
        label: ["Cost Escalation Rate"],
        field: "costEscRate",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          `${_.round(value * 100, 3).toFixed(2)}%`,
      },
      {
        label: ["Interval Active Hour"],
        field: "activeHour",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["Downtime"],
        field: "downtime",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
    ],
    data: {
      intervalFH: selectedAPUData.interval.flightHour,
      intervalFC: selectedAPUData.interval.flightCycle, 
      downtime: selectedAPUData.interval.downtime,
      activeHour: selectedAPUData.interval.activeHour,
      cost: selectedAPUData.cost.cost,
      costDate: selectedAPUData.cost.costDate,
      costEscRate: selectedAPUData.cost.escalation,
    },
  };
}
