import { useMsal } from "@azure/msal-react";
import { useState, useEffect} from "react";
import React from "react";
import apiRoutes from "../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(()=>({
  cancelText: {
    height: 20,
    width: 80,
    color: "rgba(0,0,0,0.38)",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "2px",
    lineHeight: "20px",
    textAlign: "left",
},
submitText: {
    height: 20,
    width: 80,
    color: "#0D47A1",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "20px",
},
}));
const columns = [
  {
    field: "aircraftId",
    headerName: "FG ID",
    width: 100,
    editable: false,
    hideable: false,
    sortable:false,
    // valueGetter: (params) => getFormattedDate(params.row.changedOn),
  },
  {
    field: "serialNumber",
    headerName: "MSN",
    width: 100,
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "aircraftType",
    headerName: "Aircraft Type",
    width: 150,
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "aircraftSeries",
    headerName: "Aircraft Series",
    width: 150,
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "aircraftSubseries",
    headerName: "Aircraft Subseries",
    width: 150,
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "engineSubseries",
    headerName: "Engine Subseries",
    width: 180,
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "dom",
    headerName: "D.O.M.",
    //width: 300,
    type: "date",
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "owner",
    headerName: "Owner",
    //width: 300,
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "operator",
    headerName: "Operator",
    //width: 300,
    editable: false,
    hideable: false,
    sortable:false,
  },
  {
    field: "status",
    headerName: "Status",
    //width: 300,
    editable: false,
    hideable: false,
    sortable:false,
  },
];

const AppNoRowsOverlay = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "50%",
    }}>
    <p severity='info'>No Fleet Data</p>
  </div>
);

const FleetDataDialog = ({msn,manufacturer,openme, setopenme, updatefcn}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fleetData, setFleetData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const msalContext = useMsal();

  useEffect(()=>{
    const getAvailableFleet = async (msn, manufacturer) => {
        const fleetreq = await apiRequest(
          {
            url: apiRoutes.FleetData_GetAvailableAssets({msn, manufacturer}),
            method: "GET",
          },
          msalContext
        );
    
        if (fleetreq.status === response.OK ) {
          if(fleetreq.data && fleetreq.data.length > 0){
            var result = fleetreq.data.map((c) => ({
              id: c.aircraftId,
              aircraftId:c.aircraftId,
              serialNumber: c.serialNumber,
              aircraftType: c.aircraftType,
              aircraftSeries: c.aircraftSeries,
              aircraftSubseries: c.aircraftSubseries,
              engineSubseries:c.engineSubseries,
              dom:c.dom,
              owner:c.owner,
              operator:c.operator,
              status:c.status
            }));
            setFleetData(result);
            setOpen(true);
            setopenme(false);
          }
        }
        else{
          setopenme(false);
        }
    };
      
    if(openme){
        getAvailableFleet(msn,manufacturer ? manufacturer : "");
        setopenme(false);
    }
  });
  const handleSelectChange =(id) =>{
    setSelectedId(id);
  }
  const handleClose = () => {
    //updatefcn(selectedId)
    setOpen(false);
  };
  const onAccept = () =>{
    updatefcn(selectedId)
    setOpen(false);
  }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}
        style={{height:'90vh'}}
        >
        <DialogTitle>Select Fleet Data 
          <p style={{fontSize:12}}>(use CTRL or CMD + click to unselect)</p> 
        </DialogTitle>
        <DialogContent 
          //sx={{ width:"100%"}}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1
             }}>
              <DataGrid
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: AppNoRowsOverlay,
                }}
                disableColumnMenu
                hideFooterPagination
                style={{ 
                         height: "50vh", 
                         width:"100%" }}
                rows={fleetData}
                // getRowId={(row) => row.id}
                columns={columns}
                //checkboxSelection
                disableColumnSelector
                disableColumnFilter
                onSelectionModelChange={(id)=>handleSelectChange(id)}
                disableMultipleRowSelection
                loading={loading}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{marginRight: 25, marginBottom: 20}}>
            <Button onClick={handleClose}>
              <span className={classes.cancelText}>Cancel</span>
            </Button>
            <Button onClick={onAccept} variant="outlined">
              <span className={classes.submitText}>SUBMIT</span>
            </Button>
        </DialogActions>
      </Dialog>
  );
};

export default FleetDataDialog;
