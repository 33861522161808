import React from "react";
import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import mapToViewModel_LLPReplacements from "./llpReplacementsViewModel";
import BookMark from "../../../Common/SvgComponents/BookMark";
import { Box } from "@material-ui/core";

export function mapToViewModel_redeliveryEvents(eolEvents) {
  let groupedEvents = _.groupBy(eolEvents, "redeliveryDate");
  return Object.values(groupedEvents).map((events) => {
    let data = events.map((e) => ({
      eventName: [e.name, e.componentSerialNumber],
      redeliveryDate: e.redeliveryDate,
      lesseeToLessor: e.lesseeToLessor,
      lessorToLessee: e.lessorToLessee,
      cashReserveToLessee: e.cashReserveToLessee,
      eolNet: e.lesseeToLessor + e.lessorToLessee + e.cashReserveToLessee,
      sinceLastEvent: e.name.includes("LLP")
        ? [
            {
              label: "FC C.",
              value: null,
            },
            {
              label: "FC",
              value: utils.ifValueExist(e.toEvent_FC, true)
                ? e.sinceEvent_FC
                : null,
            },
          ]
        : e.name === constants.APU_OVERHAUL
        ? [
            {
              label: "APUH",
              value: utils.ifValueExist(e.toEvent_AH, true)
                ? e.sinceEvent_AH
                : null,
            },
          ]
        : [
            {
              label: "FH",
              value: utils.ifValueExist(e.toEvent_FH, true)
                ? e.sinceEvent_FH
                : null,
            },
            {
              label: "FC",
              value: utils.ifValueExist(e.toEvent_FC, true)
                ? e.sinceEvent_FC
                : null,
            },
            {
              label: "MO",
              value: utils.ifValueExist(e.toEvent_Day, true)
                ? _.round(e.sinceEvent_Day / 30.44)
                : null,
            },
          ],
      toNextEvent: e.name.includes("LLP")
        ? [
            {
              label: "FC C.",
              value: e.toEvent_FC_Core && _.round(e.toEvent_FC_Core),
            },
            {
              label: "FC",
              value: e.toEvent_FC && _.round(e.toEvent_FC),
            },
          ]
        : e.name === constants.APU_OVERHAUL
        ? [
            {
              label: "APUH",
              value: e.toEvent_AH && _.round(e.toEvent_AH),
            },
          ]
        : [
            {
              label: "FH",
              value: e.toEvent_FH && _.round(e.toEvent_FH),
            },
            {
              label: "FC",
              value: e.toEvent_FC && _.round(e.toEvent_FC),
            },
            {
              label: "MO",
              value: e.toEvent_Day && _.round(e.toEvent_Day / 30.44),
            },
          ],
      lifePctBar: _.round(e.lifePct * 100),
      hlAdj: _.round(e.hlAdj),
      hidden:
        e.llpReplacements && e.llpReplacements.length
          ? mapToViewModel_LLPReplacements(e.llpReplacements)
          : null,
    }));

    const total = {
      eventName: "Total",
      redeliveryDate: "",
      lesseeToLessor: _.round(_.sumBy(events, "lesseeToLessor")),
      lessorToLessee: _.round(_.sumBy(events, "lessorToLessee")),
      cashReserveToLessee: _.round(_.sumBy(events, "cashReserveToLessee")),
      eolNet:
        _.sumBy(events, "lesseeToLessor") +
        _.sumBy(events, "lessorToLessee") +
        _.sumBy(events, "cashReserveToLessee"),
      hlAdj: _.round(_.sumBy(events, "hlAdj")),
      sinceLastEvent: [],
      toNextEvent: [],
      lifePctBar: null,
      hidden: null,
    };

    const redeliveryDate =
      events &&
      events.length &&
      moment.utc(events[0].redeliveryDate).format(constants.DATE_FORMAT);

    return {
      // label: "Redelivery Events",
      deliveryDate: redeliveryDate,
      tableType: constants.MULTIFUNCTION_TABLE,
      span: [12, 12, 12],
      columns: [
        {
          label: "Event",
          caption: null,
          field: "eventName",
          align: constants.LEFT,
          indent: 16,
          contentType: constants.BUTTON,
          clickableCellIcon: (value) => (
            <BookMark value={""} color={"#64b5f6"} />
          ),
          content: (isButton, value, handleClick, indent) => {
            if (isButton === "Total") return "Total";
            else
              return (
                <Box
                  style={{
                    width: "100%",
                  }}>
                  {utils.renderEventName(isButton, value, handleClick, indent)}
                </Box>
              );
          },
        },
        {
          label: (
            <span>
              EOL Comp <br /> Positive
            </span>
          ),
          caption: "U.S. $",
          field: "lesseeToLessor",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL,
              "$"
            ),
        },
        {
          label: (
            <span>
              EOL Comp <br /> Negative
            </span>
          ),
          caption: "U.S. $",
          field: "lessorToLessee",
          align: constants.RIGHT,
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL,
              "$"
            ),
        },
        {
          label: "Reserve SetOff",
          caption: "U.S. $",
          field: "cashReserveToLessee",
          align: constants.RIGHT,
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL,
              "$"
            ),
        },
        {
          label: (
            <span>
              EOL Comp <br /> Net
            </span>
          ),
          caption: "U.S. $",
          field: "eolNet",
          align: constants.RIGHT,
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL,
              "$"
            ),
        },
        {
          label: "Since Last Event",
          caption: null,
          field: "sinceLastEvent",
          align: constants.RIGHT,
          indent: 16,
          content: (items, indent) =>
            utils.renderMultiValueCellValue(items, indent, 100, null, true),
        },
        {
          label: "To Next Event",
          caption: null,
          field: "toNextEvent",
          align: constants.RIGHT,
          indent: 16,
          content: (items, indent) =>
            utils.renderMultiValueCellValue(items, indent),
        },
        {
          label: "Life Left %",
          caption: null,
          field: "lifePctBar",
          align: constants.RIGHT,
          indent: 16,
          content: (value, indent) =>
            value && utils.renderLifePctBar(value, indent),
        },
        {
          label: "Value to H.L.",
          caption: "U.S. $",
          field: "hlAdj",
          align: constants.RIGHT,
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
      ],
      data,
      total,
    };
  });
}
