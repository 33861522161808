import _ from "lodash";
import React, { Component } from "react";
import { Box } from "@material-ui/core";
import { MsalContext } from "@azure/msal-react";
import { withStyles } from "@material-ui/core/styles";
import * as constants from "../../constants";
import { apiRequest, response } from "../../utils/ApiRequest";
import apiRoutes from "../../utils/ApiRoutes";
import axios from "axios";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import VfSnackerBar from "../../Components/Common/UtilComponents/VfSnackerBar";
import VfBreadcrumbs from "../../Components/Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../../Components/Common/UtilComponents/VfBreadcrumbContainer";
import Pools from "./Components/Pools";
import PoolHeading from "./Components/PoolHeading";
import * as utils from "../../utils/utils";

const styles = {
  root: {
    paddingTop: 50,
    paddingBottom: 50,
  },
};

class AssetPoolComponents extends Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.tokenSourceAssetPools = null;
    this.state = {
      allAssetPoolData: [],
      assetPoolData: [],
      airlineLogos: [],
      assetPoolsSearchQuery: "",
      isloading: true,
      rowsPerPage: 10,
      currPage: 0,
      skip: 0,
      take: 10,
      searchPoolName: "",
      snackOpen: false,
      severity: "info",
      message: "",
      liabilityData: null,
    };
  }

  componentDidMount = async () => {
    //const { allAssetPoolData } = this.props;
    let allAssetPoolDataresp = await this.getAssetPoolData();
    let allAssetPoolData = allAssetPoolDataresp.data;
    
    const { skip, take} = this.state;

    if (!_.isEmpty(allAssetPoolData)) {
      let allAssetPoolAssets = [];
      allAssetPoolData.forEach((assetPool) => {
        allAssetPoolAssets = allAssetPoolAssets.concat(
          assetPool.assetPool_Assets
        );
      });

      const airlineLogos = await this.getAllAirlineLogos(allAssetPoolAssets);

      const assetPoolData = allAssetPoolData.slice(skip, skip + take);

      
      const url = new URL(window.location.href);
      const urlValues = utils.parseUrlObject(url);

      if(urlValues.search !== undefined && urlValues.search !== null && urlValues.search !== ""){
        let searchString = decodeURI(urlValues.search)
        this.setState({searchPoolName:searchString});
        let searchPools = _.chain(allAssetPoolData)
        .filter((o) => {
          const { assetPoolName, assetPool_Assets, assetPool_Deals } = o;
          const str = JSON.stringify({
            assetPoolName,
            assetPool_Assets,
            assetPool_Deals,
          });
  
          return (
            str.toUpperCase().includes(':"' + searchString.toUpperCase()) ||
            str.toUpperCase().includes(":'" + searchString.toUpperCase()) ||
            str.toUpperCase().includes(":" + searchString.toUpperCase())
          );
        })
        .sortBy(["assetPoolName"])
        .value();
        const searchedAssetPoolData = searchPools;
        this.setState({
          allAssetPoolData: allAssetPoolData,
          airlineLogos: airlineLogos,
          assetPoolData: searchedAssetPoolData,
          isloading: false,
        });
        return;
      }

      this.setState({
        allAssetPoolData: allAssetPoolData,
        airlineLogos: airlineLogos,
        assetPoolData: assetPoolData,
        isloading: false,
      });
    }
  };

  // shouldComponentUpdate = async (nextProps, nextState) => {
  //   if (this.props.allAssetPoolData !== nextProps.allAssetPoolData) {
  //     const { allAssetPoolData } = nextProps;
  //     const { skip, take } = this.state;

  //     if (!_.isEmpty(allAssetPoolData)) {
  //       let allAssetPoolAssets = [];
  //       allAssetPoolData.forEach((assetPool) => {
  //         allAssetPoolAssets = allAssetPoolAssets.concat(
  //           assetPool.assetPool_Assets
  //         );
  //       });

  //       const airlineLogos = await this.getAllAirlineLogos(allAssetPoolAssets);

  //       const assetPoolData = allAssetPoolData.slice(skip, skip + take);

  //       this.setState({
  //         allAssetPoolData: allAssetPoolData,
  //         airlineLogos: airlineLogos,
  //         assetPoolData: assetPoolData,
  //         isloading: false,
  //       });
  //     }
  //   }
  // };

  getAllAirlineLogos = async (assetList) => {
    let logos = [];
    const uniqOperators = _.compact(
      _.uniqBy(assetList, "operator").map((o) => o.operator)
    );

    const fetchLogo = async (operators) => {
      return apiRequest(
        {
          url: apiRoutes.Blob_GetAirlineLogos(),
          method: "POST",
          data: operators,
          options: { headers: { "Content-Type": "text/json" } },
        },
        this.context
      );
    };

    try {
      const result = await fetchLogo(uniqOperators);
      if (result.status === response.OK) {
        Object.entries(result.data).forEach(([key, value]) => {
          logos.push({
            operator: key,
            path: value,
          });
        });
      }

      return logos;
    } catch (ex) {}
  };

  getAssetPoolData = async () => {
    this.tokenSourceAssetPools = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiRoutes.AssetPool_GetAll,
        method: "GET",
        cancelToken: this.tokenSourceAssetPools.token,
      },
      this.context
    );
  };

  getAllLiabilityData = async (assetPoolIds) => {
    this.tokenSourceAssetPools = axios.CancelToken.source();
    return apiRequest(
      {
        url: apiRoutes.VectorDeal_GetDealLiabilityModelOutputsByDealIds(),
        method: "POST",
        data: assetPoolIds,
        options: { headers: { "Content-Type": "text/json" } },
        cancelToken: this.tokenSourceAssetPools.token,
      },
      this.context
    );
  };

  postNewAssetPooltoDB = async (newAssetPoolData, userName) => {
    return apiRequest(
      {
        url: apiRoutes.AssetPool_AddAssetPool({ user: userName }),
        data: newAssetPoolData,
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      this.context
    );
  };

  searchPool = (searchString) => {
    let searchPools = _.chain(this.state.allAssetPoolData)
      .filter((o) => {
        const { assetPoolName, assetPool_Assets, assetPool_Deals } = o;
        const str = JSON.stringify({
          assetPoolName,
          assetPool_Assets,
          assetPool_Deals,
        });

        return (
          str.toUpperCase().includes(':"' + searchString.toUpperCase()) ||
          str.toUpperCase().includes(":'" + searchString.toUpperCase()) ||
          str.toUpperCase().includes(":" + searchString.toUpperCase())
        );
      })
      .sortBy(["assetPoolName"])
      .value();

    const { skip, take, allAssetPoolData } = this.state;

    if (_.isEmpty(searchString)) {
      window.history.pushState({}, null, "?");
      let assetPoolData = allAssetPoolData.slice(skip, skip + take);
      this.setState({
        assetPoolData: assetPoolData,
        isloading: false,
      });
    } else {
      let newUrl = `?search=${searchString}`;
      window.history.pushState({}, null, newUrl);
      this.setState({
        currPage: 0,
        skip: 0,
        assetPoolData: searchPools,
        isloading: false,
      });
    }
  };

  handleChangeRowsPerPage = async (event) => {
    this.setState({ isloading: true });
    let rowsPerPage = parseInt(event.target.value, 10);

    let assetPoolData = this.state.allAssetPoolData.slice(0, 0 + rowsPerPage);

    this.setState({
      currPage: 0,
      skip: 0,
      take: rowsPerPage,
      rowsPerPage: rowsPerPage,
      assetPoolData: assetPoolData,
      isloading: false,
    });

    // this.handleUrlChange();
  };

  handleChangePage = async (event, newPage) => {
    this.setState({ isloading: true });
    const { rowsPerPage, allAssetPoolData } = this.state;

    let newSkip = rowsPerPage * (newPage - 1);
    let newTake = rowsPerPage;

    let assetPoolData = allAssetPoolData.slice(newSkip, newSkip + newTake);

    this.setState({
      currPage: newPage - 1,
      skip: newSkip,
      take: newTake,
      assetPoolData: assetPoolData,
      isloading: false,
    });

    // this.handleUrlChange();
  };

  handleAssetPoolAdd = async (_, __, ___, values, ____) => {
    var userName = this.context.accounts[0].name;
    // set id to 0 for insert new data
    let data = { id: 0, name: values.assetPoolName };
    try {
      const result = await this.postNewAssetPooltoDB(data, userName);
      if (result.status === response.OK) {
        this.setState({
          snackOpen: true,
          severity: "info",
          message: "Successfully added Pool.",
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1500);
      } else {
        this.setState({
          snackOpen: true,
          severity: "warning",
          message: "Failed to add Pool.",
        });
      }
    } catch (ex) {}
  };

  handleSnackerBarOpen = (open) => {
    this.setState({ snackOpen: open });
  };

  generateBreadCrumbs() {
    return [
      {
        label: "Asset Pools",
        type: constants.TEXT,
      },
    ];
  }

  render() {
    const {
      allAssetPoolData,
      assetPoolData,
      isloading,
      rowsPerPage,
      currPage,
      searchPoolName,
      airlineLogos,
      snackOpen,
      severity,
      message,
      liabilityData,
    } = this.state;

    let totalRows = allAssetPoolData.length;

    const assetPoolStages = [
      { label: "All", value: "All" },
      { label: "Pipeline", value: "Pipeline" },
      { label: "Closed", value: "Closed" },
      { label: "Managed", value: "Managed" },
      { label: "Expired", value: "Expired" },
      { label: "Lost", value: "Lost" },
      { label: "Info Only", value: "Info Only" },
      { label: "Archive", value: "Archive" },
    ];

    return (
      <ErrorBoundary>
        <Box m={1} flexGrow={1}>
          <VfSnackerBar
            snackOpen={snackOpen}
            setsnackOpen={this.handleSnackerBarOpen}
            severity={severity}
            message={message}
          />

          <VfBreadcrumbContainer
            breadcrumb={
              <VfBreadcrumbs breadcrumbs={this.generateBreadCrumbs()} />
            }
          />

          <PoolHeading
            searchPoolName={searchPoolName}
            searchPool={this.searchPool}
            handleAssetPoolAdd={this.handleAssetPoolAdd}
          />

          {/* <Grid container direction='row'></Grid>
          <Box style={{ margin: "48px 0 0 0" }}>
            <SectionHeader label={"Asset Pools"} />
          </Box> */}

          {/* <MultiFunctionTable tableData={tableData} containerMaxHeight={500} />

          <Box style={{ margin: "48px 0 0 0" }}></Box> */}
          <Box style={{ maxWidth: "100%" }}>
            <Pools
              liabilityData={liabilityData}
              assetPoolStages={assetPoolStages}
              currPage={currPage}
              assetPoolData={assetPoolData}
              isLoading={isloading}
              rowsPerPage={rowsPerPage}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              airlineLogos={airlineLogos}
              totalRows={totalRows}
              userName={"Jason"}
            />
          </Box>
        </Box>
      </ErrorBoundary>
    );
  }
}

export default withStyles(styles)(AssetPoolComponents);
