import _ from "lodash";
import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import Leases from "./Leases/Leases";
import Valuation from "./Valuation";
import Cashflow from "./Cashflow";
import SimulationParameter from "./SimulationParameter";
import Errors from "../../../../../Common/Errors";
import LoadingPanel from "../../../../../Common/LoadingPanel";
import MaintAndUtils from "./MaintAndUtils";
import Banner from "./Banner";
import RichTextInputComponent from "../../../../../Common/Editing/EditingComponents/RichTextInputComponent";
import * as constants from "../../../../../../constants";
import VfSnackerBar from "../../../../../Common/UtilComponents/VfSnackerBar";
import ThreeDotButton from "../../../../../Common/ThreeDotButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VfAlertDialogBox from "../../../../../Common/UtilComponents/VfAlertDialogBox";

const renderSection = (
  report,
  assetDetails,
  generateEditableProps,
  scenario,
  selected,
  handleValueChange,
  currentState
) => {
  switch (selected.label.toUpperCase()) {
    case constants.LEASES.toUpperCase():
      return (
        <Leases
          assetDetails={assetDetails}
          generateEditableProps={generateEditableProps}
          scenario={scenario}
          handleValueChange={handleValueChange}
          currentState={currentState}
        />
      );
    case "Simulation Params".toUpperCase():
      return (
        <SimulationParameter
          scenario={scenario}
          generateEditableProps={generateEditableProps}
          handleValueChange={handleValueChange}
          assetDetails={assetDetails}
        />
      );
    case constants.Valuation.toUpperCase():
      return <Valuation report={report.report} />;
    case constants.cashFlows.toUpperCase():
      return <Cashflow report={report.report} />;
    default:
      return (
        <MaintAndUtils
          assetDetails={assetDetails}
          generateEditableProps={generateEditableProps}
          scenario={scenario}
          handleValueChange={handleValueChange}
        />
      );
  }
};

const Scenario = ({
  errors,
  report,
  assetDetails,
  scenario,
  selected,
  generateEditableProps,
  handleValueChange,
  currentState,
  handleScenarioLoading,
  userName,
}) => {
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [outofdate, setoutofdate] = useState(true);
  const [isAlertDialogOpen, setisAlertDialogOpen] = useState(false);
  const loading = currentState.scenarioLoading;

  const error =
    errors.reportError &&
    (selected.label.toUpperCase() === constants.Valuation.toUpperCase() ||
      selected.label.toUpperCase() === constants.cashFlows.toUpperCase());

  if (report === undefined) {
    report = {
      reportNotes: "",
      report: null,
    };
  }

  const props = generateEditableProps(1, "scenarioNotes");

  let threeDotBtnOptions = [
    {
      id: 1,
      icon: DeleteForeverIcon,
      label: "Delete Note",
      handleClick: () => setisAlertDialogOpen(true),
    },
  ];

  const handleDismiss = () => {
    setoutofdate(false);
  };

  return (
    <Box>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        message={message}
      />

      <VfAlertDialogBox
        isOpen={isAlertDialogOpen}
        handleAlertDialogVisible={setisAlertDialogOpen}
        dialogTitle={"Confirm Delete Notes"}
        dialogBody={"Are you sure you would like to delete scenario notes?"}
        onAccept={() => {
          props.funcs.handleRowDelete(
            props.section,
            props.sectionIdx,
            "scenarioNotes",
            null
          );
          setisAlertDialogOpen(false);
        }}
      />

      {/* {outofdate ? (
        <Banner
        bannerText={"The Current Scenario is Out of Date"}
        submitButtonLabel={"Dismiss"}
        scenario = {scenario}
        assetDetails = {assetDetails}
        handleScenarioLoading = {handleScenarioLoading}
        setsnackOpen={setsnackOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        userName={userName}
        setDismiss={handleDismiss}
      />
      ): <></>} */}

      {!scenario || (!report && !error) || loading ? (
        <LoadingPanel />
      ) : (
        <>
          {currentState.editing ? (
            <Banner
              bannerText={"Changes were made to the current scenario."}
              submitButtonLabel={"Save"}
              scenario={scenario}
              assetDetails={assetDetails}
              handleScenarioLoading={handleScenarioLoading}
              setsnackOpen={setsnackOpen}
              setSeverity={setSeverity}
              setMessage={setMessage}
              userName={userName}
            />
          ) : (
            <> </>
          )}
          <Box
            style={{
              backgroundColor: "#E3F2FD",
              padding: 20,
              // marginBottom: 20,
              borderRadius: 12,
              minHeight: 150,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Box>
              <Typography
                variant='h4'
                style={{ color: "#023059", fontSize: 12, opacity: "70%" }}>
                {"SCENARIO / REPORT NOTES"}
              </Typography>
              <RichTextInputComponent
                value={scenario.scenarioComment}
                onChange={(value) =>
                  props.funcs.handleValuesUpdate(
                    props.section,
                    props.sectionIdx,
                    "scenarioNotes",
                    value
                  )
                }
                heightLimit={55}
              />
            </Box>
            <Box style={{ maxWidth: 20, marginRight: 20 }}>
              <ThreeDotButton options={threeDotBtnOptions} />
            </Box>
          </Box>
          {_.isEmpty(scenario) ? (
            <Box mt={4}>
              <Errors
                msg={"There is no scenario attached to this asset yet."}
              />
            </Box>
          ) : (
            <>
              {error ? (
                <Box mt={4}>
                  <Errors msg={"This scenario hasn't been simulated yet."} />
                </Box>
              ) : (
                <Box style={{ margin: "16px 0 16px 0" }}>
                  {renderSection(
                    report,
                    assetDetails,
                    generateEditableProps,
                    scenario,
                    selected,
                    handleValueChange,
                    currentState
                  )}
                  <br />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Scenario;
