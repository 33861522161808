import mapToViewModel_fleetInfo from "./fleetInfoViewModel";

export default function mapToViewModel_fleetSummary(
  portfolioData,
  deal,
  options,
  assetList
) {
  return {
    fleetInfo: mapToViewModel_fleetInfo(
      portfolioData.fleetInfo,
      portfolioData.scenarios,
      deal,
      options,
      assetList
    ),
  };
}
