import { default as mapToViewModel_engineDetails } from "./engineDetails";
import { default as mapToViewModel_performanceRestAssump } from "./performanceRestAssump";
import { default as mapToViewModel_llpDetails } from "./llpStackDetails";

export default function mapToViewModel_EngineGeneric(
  data,
  handleSelectChange,
  props,
  selectedSubseries,
) {
  return {
    engineDetails: mapToViewModel_engineDetails(data, handleSelectChange, props, selectedSubseries),
    firstRun: mapToViewModel_performanceRestAssump(data, props, "First Run", selectedSubseries),
    matureRun: mapToViewModel_performanceRestAssump(data, props, "Mature Run", selectedSubseries),
    llpStack: mapToViewModel_llpDetails(data, props, selectedSubseries),
  };
}
