import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

const getDataforTable = (report, dispositionValue, selectedAppraisal, runDate) => {
  let data = []
  let total = {}
  if (!_.isEmpty(report.leases)) {
    var lastLease = report.leases.filter(
      (x) => x.leaseStatus === "Contractual"
    );
    var lastLeaseEndDate = lastLease[lastLease.length - 1].endDate;
    var lastLeaseStartDate = lastLease[lastLease.length - 1].startDate;
    if (selectedAppraisal.dispositionAssumption === "Assumed"){
      lastLeaseEndDate = report.leases[report.leases.length - 1].endDate;
      lastLeaseStartDate = report.leases[report.leases.length - 1].startDate;
    }
      

    let leaseStart = moment.utc(lastLeaseStartDate);
    let leaseEnd = moment.utc(lastLeaseEndDate).add(1, 'M');
    let evaluatingDatesData = Object.entries(report.cashFlows).filter(([key, value]) => moment.utc(key).isBetween(leaseStart, leaseEnd) )

    let evaluateDates = evaluatingDatesData.map(data =>data[0])
    
    let cashflowValues = evaluatingDatesData.map(data => data[1])
    let leaseRents = cashflowValues.map(data => data.rent)
    let MXInandOuts = cashflowValues.map(data => data.mxReserveToLessor + data.mxExpenseFromLessor + data.redeliveryComp)
    let otherValues = cashflowValues.map(data => data.rrrCost + data.otherCF)
    
    let leaseRentTotal = _.round(_.sum(leaseRents))
    let MXInandOutTotal = _.round(_.sum(MXInandOuts))
    let otherValuesTotal = _.round(_.sum(otherValues))
    let totalofTatalValues = leaseRentTotal + MXInandOutTotal + dispositionValue.data.dispValue + otherValuesTotal
    
    // let dispRunDate = moment.utc(dispositionValue.data.dispDate).subtract(1, 'month').endOf("month");
    let leaseRentPValue = utils.XNPVcalculation(selectedAppraisal.LSdiscountRate, leaseRents, evaluateDates, runDate)
    let MXInandOutPValue = utils.XNPVcalculation(selectedAppraisal.LSdiscountRate, MXInandOuts, evaluateDates, runDate)
    let dispositionValuePValue = utils.XNPVcalculation(selectedAppraisal.DispdiscountRate, [dispositionValue.data.dispValue], [dispositionValue.data.dispDate], runDate)
    let otherValueValue = utils.XNPVcalculation(selectedAppraisal.LSdiscountRate, otherValues, evaluateDates, runDate)
    let totalofPValue = leaseRentPValue + MXInandOutPValue + dispositionValuePValue + otherValueValue
    
    data = [
      { valueCategory: "Lease Rent", totalAmount: leaseRentTotal, presentValue: leaseRentPValue },
      { valueCategory: "Mx In/Out", totalAmount: MXInandOutTotal, presentValue: MXInandOutPValue },
      { valueCategory: "Disposition", totalAmount: _.round(dispositionValue.data.dispValue), presentValue: dispositionValuePValue },
      { valueCategory: "Other", totalAmount: otherValuesTotal, presentValue: otherValueValue },
      // { valueCategory: "Total", totalAmount: totalofTatalValues, presentValue: totalofPValue },
    ]
  
    total = {
      valueCategory: "Total",
      totalAmount: totalofTatalValues,
      presentValue: totalofPValue,
    }
  }

  return [data, total];
}

export default function mapToViewModel_EncumberedValues(
  report,
  dispositionValue,
  runDate,
  selectedAppraisal,
) {
  let [data, total] = getDataforTable(report, dispositionValue, selectedAppraisal, runDate)
  let columns = [
    {
      label: ["Value Category"],
      field: "valueCategory",
      caption: null,
      align: constants.LEFT,  
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, {paddingLeft: indent}, constants.TEXT_CELL),
    },
    {
      label: ["Total Amount"],
      field: "totalAmount",
      caption: ["U.S. $"],
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Present Value"],
      field: "presentValue",
      caption: ["U.S. $"],
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
  ];

  return {
    label: "Encumbered Values",
    caption: `As of ${runDate}`,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { pagination: false, margtop:30 },
    optionsItems: {
      pagination: {
        page: 0,
        rowsPerPage: 10,
        tableName: null,
        handleChangePage: null,
        handleChangeRowsPerPage: null,
      },
    },
    span: [4, 4, 4],
    columns,
    data,
    total,
  };
}
