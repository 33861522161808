import _ from "lodash";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";
import * as constants from "../../../../constants";

const updateNotes = async (apiendpoint, Id, data, msalContext) => {
  apiendpoint = apiendpoint({ Id });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data,
    },
    msalContext
  );
};

export const assetNotes = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
  msalContext
) => {
  let apiendpoint = null;
  if (!_.isEmpty(assetDetails.airframeChecks)) {
    apiendpoint = apiRoutes.NotesAssets_PostAircraftNotes;
  } else {
    apiendpoint = apiRoutes.NotesAssets_PostEngineNotes;
  }

  if (actionType === constants.UPDATE_ROW) {
    assetDetails.assetNotes = newData;
  } else if (actionType === constants.DELETE_ROW) {
    assetDetails.assetNotes = null;
  }

  updateNotes(
    apiendpoint,
    assetDetails.id,
    { note: assetDetails.assetNotes },
    msalContext
  );

  return assetDetails;
};
