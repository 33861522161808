import _ from "lodash";
import moment from "moment";
import { styles } from "../../common";
import * as utils from "../../../../../utils/utils";
import * as constants from "../../../../../constants";

export function mapToViewModel_checkStatus(airframeChecks, props) {
  airframeChecks = _.sortBy(airframeChecks, (o) =>
    moment.utc(o.statusAsOf).format("X")
  );

  const items = airframeChecks.map((o) => ({
    label: o.checkName,
    value: o.checkName,
  }));

  //console.log(airframeChecks);

  return {
    label: "Check Status",
    caption:
    "\"Since Event\" values will be auto-populated if there is overhaul shop visit history",
    props,
    showOptions: true,
    sorting: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.checkStatus,
    // isDeletable: (rowData) => {
    //   return false;
    // },
    errorsOnSubmit: {},
    columns: [
      {
        title: "As Of Date",
        field: "asOfDate",
        defaultSort: "desc",
        sorting: true,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        initialEditValue: moment.utc().format(constants.ISOFormat),
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.asOfDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Check Name",
        field: "checkName",
        cellStyle: styles.cellStyle,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items,
        initialEditValue: items[0].value,
        required: true,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Hours Since New",
        field: "flightHours_sinceNew",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.flightHours_sinceNew,
            null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Cycles Since New",
        field: "flightCycles_sinceNew",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.flightCycles_sinceNew,
            null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Days Since New",
        field: "days_sinceNew",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.days_sinceNew,
            null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Hours Since Event",
        field: "flightHours_sinceEvent",
        type: constants.NUMERIC,
        inputFieldType: constants.STATUSMXCH,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        cellStyle: styles.cellStyle,
        items: airframeChecks,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.flightHours_sinceEvent,
            null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Cycles Since Event",
        field: "flightCycles_sinceEvent",
        type: constants.NUMERIC,
        inputFieldType: constants.STATUSMXCH,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        cellStyle: styles.cellStyle,
        items: airframeChecks,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.flightCycles_sinceEvent,
            null,
            constants.NUMERIC_CELL
          ),
      },
      {
        title: "Days Since Event",
        field: "days_sinceEvent",
        type: constants.NUMERIC,
        inputFieldType: constants.STATUSMXCH,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        cellStyle: styles.cellStyle,
        items: airframeChecks,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.days_sinceEvent,
            null,
            constants.NUMERIC_CELL
          ),
      },
    ],
    data: _.flatten(
      airframeChecks.map((e) =>
        e.maintenanceStatus.map((o, idx) => ({
          idx,
          statusId: o.statusId,
          checkName: e.checkName,
          isFirstRun: o.isFirstRun ? constants.YES : constants.NO,
          asOfDate: o.statusAsOf || new Date(),

          flightHours_sinceNew:
            o.sinceNew.flightHour && _.round(o.sinceNew.flightHour),
          flightCycles_sinceNew:
            o.sinceNew.flightCycle && _.round(o.sinceNew.flightCycle),
          days_sinceNew: o.sinceNew.day && _.round(o.sinceNew.day),

          flightHours_sinceEvent:
            o.sinceEvent.flightHour && _.round(o.sinceEvent.flightHour),
          flightCycles_sinceEvent:
            o.sinceEvent.flightCycle && _.round(o.sinceEvent.flightCycle),
          days_sinceEvent: o.sinceEvent.day && _.round(o.sinceEvent.day, 1),
        }))
      )
    ),
  };
}
