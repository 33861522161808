import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";

export const updateData = (tableName, actionType, sectionIdx, newData, scenario) => {
  let capId = null;
  let findCapIndex = null;
  let reserveIds = [];
  let capsByDateTemplate = {
    startDate: moment.utc().toISOString().split(".")[0],
    endDate: moment.utc().toISOString().split(".")[0],
    cap: 0,
  }

  scenario.leases = [...scenario.leases];
  scenario.leases[sectionIdx] = { ...scenario.leases[sectionIdx] };

  let reserveAccountCaps = [...scenario.leases[sectionIdx].reserveAccountCaps];

  if (tableName === constants.cashReserveCap) {
    if (actionType === constants.ADD_ROW) {
      // capId = newData
      // findCapIndex = _.findIndex(reserveAccountCaps, function(o) { return o.capId === capId });
      reserveAccountCaps[newData].capsByDate.push(capsByDateTemplate)
    } else if (actionType === constants.UPDATE_ROW) {
      capId = newData.capId
      findCapIndex = _.findIndex(reserveAccountCaps, function(o) { return o.capId === capId });
      capsByDateTemplate.startDate = newData.startDate;
      capsByDateTemplate.endDate = newData.endDate;
      capsByDateTemplate.cap = newData.RAcaps;
      reserveAccountCaps[findCapIndex].capsByDate[newData.id] = capsByDateTemplate
    } else if (actionType === constants.DELETE_ROW) {
      capId = newData.capId
      findCapIndex = _.findIndex(reserveAccountCaps, function(o) { return o.capId === capId });
      reserveAccountCaps[findCapIndex].capsByDate.splice(newData.id, 1)
    }

  } else if(tableName === "Accounts") {
    let getAccounts = newData.accounts.filter(data => !data.includes("Account"))
    getAccounts.forEach(element => {
      let values = element.split("-")
      capId = parseInt(values[0].trim())
      reserveIds.push(parseInt(values[1].trim() ) )
    });

    findCapIndex = _.findIndex(reserveAccountCaps, function(o) { return o.capId === capId });
    reserveAccountCaps[findCapIndex].reserveIds = reserveIds;
  }

  scenario.leases[sectionIdx].reserveAccountCaps = reserveAccountCaps;
};
