import _, { filter } from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Backdrop, Box, Fade, Modal, Typography } from "@material-ui/core";
import {
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Create,
  DeleteForever,
  FilterList,
  FirstPage,
  LastPage,
  Visibility,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import MaterialTable, { MTableToolbar } from "material-table";
import TableNamePanel from "../../TableNamePanel";
import ThreeDotButton from "../../ThreeDotButton";
import InputFieldComponent from "../../Editing/EditingComponents/InputFieldComponent";
// import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel"
import * as constants from "../../../../constants";
import XLSX from "xlsx";
import ExportIconSvg from "../../SvgComponents/ExportIconSvg";
import ImportIconSvg from "../../SvgComponents/ImportIconSvg";

const styles = {
  tableNamePanel: {
    // marginBottom: 16,
    padding: "0 0 8px 0",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  table: {
    height: "100%",
    width: "100%",
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    padding: "20px 16px",
  },
  headerStyle: {
    color: "#263238",
    textAlign: "center",
    padding: 0,
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    borderTop: "unset",
    boxShadow: "0px 1px 0px 0px rgba(224, 224, 224, 1)",
  },
  rowStyle: {
    backgroundColor: "#FFFFFF",
    borderBottom: "unset",
  },
  filterCellStyle: {
    maxWidth: 100,
    width: 100,
    overflow: "auto",
  },
  datePicker: {
    width: 70,
    fontSize: 14,
    textAlign: "center",
    marginLeft: 20,
  },
  leaseInput: {
    minWidth: "95%",
    fontSize: 14,
    textAlign: "center",
  },
  input: {
    width: 100,
    fontSize: 14,
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  LSTableHead: {
    paddingLeft: 6,
    display: "flex",
    justifyContent: "space-between",
  },
  LSTableTitle: {
    fontWeight: 550,
    color: "#023059",
    fontSize: 14,
    // padding: 10,
  },
  LSfootNotes: {
    fontWeight: 550,
    color: "#969696",
    textAlign: "center",
    // marginTop: -15,
  },
  displayNone: {
    display: "none",
  },
  iconClosed: {
    border: "0.5mm solid #023059",
    color: "#023059",
    borderRadius: "50%",
    fontSize: 12,
    // padding: "2px 4px",
  },
  iconOpen: {
    color: "#fff",
    backgroundColor: "#023059",
    borderRadius: "50%",
    fontSize: 12,
    // padding: "4px 6px",
  },
};

// isEditingSection are two code pollution.
// They come from the component: Utilization.jsx and Lease.jsx.
const FilteringTable = ({ table, maxHeight }) => {
  const { columns, data, style, tableOptions, label } = table;
  //const [filteredData, setFilteredData] = useState(columns);
  //const [newData, setNewData] = useState([]);
  // useEffect(()=>{
  //   console.log(columns);
  //   setFilteredData(columns);
  // },[columns]);
  //console.log(columns);

  const options = {
    search: false,
    filtering: true,
    draggable: false,
    headerStyle: styles.headerStyle,
    rowStyle: styles.rowStyle,
    filterCellStyle: styles.filterCellStyle,
    maxBodyHeight: maxHeight ? maxHeight : 300,
    exportButton: {csv:true},
    exportAllData:true,
    ...tableOptions,
  };
  const icons = {
    Add: <AddBox />,
    Clear: Clear,
    Check: Check,
    Delete: (props) =>
      props.disabled ? (
        <DeleteForever color='disabled' />
      ) : (
        <DeleteForever color='secondary' />
      ),
    Edit: Create,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    FirstPage: FirstPage,
    LastPage: LastPage,
    SortArrow: ArrowDropUpIcon,
    Export: SaveAltIcon,
    Filter: FilterList,
  };

  return (
    <Box style={{ width: "100%" }}>
      <MaterialTable
        style={style ? { ...styles.table, ...style } : styles.table}
        title={label}
        columns={columns}
        data={data}
        options={options}
        icons={icons}
        //onFilterChange={(e) => setFilteredData(e)}
      />
    </Box>
  );
};

export default FilteringTable;
