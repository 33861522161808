import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Input, InputLabel, TextField, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Root from "../../../Hoc/Root";
import VfCheckBox from "../../UtilComponents/VfCheckBox";
import VfSelect from "../../UtilComponents/VfSelect";
import VfSelectCheckBox from "../../UtilComponents/VfSelectCheckBox";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import NumberFormat from "react-number-format";
import MUIRichTextEditor from "mui-rte";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { index } from "d3";
// import { EditorState, convertToRaw } from 'draft-js'

const useStyles = makeStyles((theme) => ({
  warning: { color: "red", fontSize: 10 },
}));

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date).format("MM-DD-YY");
  }
}

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const InputFieldComponent = ({
  type,
  onChange,
  required,
  datePickervariant,
  errorOnSubmit,
  fullWidth,
  inputProps,
  isMultiline,
  items,
  keyboardButtonProps,
  label,
  value,
  valueType,
  variant,
  className,
  mtableEditMode,
  multiline = false,
  rows = 1,
  disabled = false,
}) => {
  const classes = useStyles();
  const [valid, setValid] = useState(
    type === constants.SELECT || !value || utils.validate(type, value, required)
  );

  const generateRadioButtons = () => {
    return (
      <Root>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>{label}</FormLabel>
          <RadioGroup
            row
            aria-label='position'
            name='position'
            defaultValue={items[1].value}>
            {items.map((item, idx) => (
              <FormControlLabel
                key={idx}
                value={item.value}
                control={<Radio color='primary' />}
                label={item.label}
                labelPlacement='bottom'
                onChange={(event) => {
                  setValid(utils.validate(type, event.target.value, required));
                  onChange(event.target.value);
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Root>
    );
  };

  const generateTextField = () => {
    return (
      <Root>
        <TextField
          fullWidth={fullWidth}
          label={label || ""}
          onChange={(event) => {
            setValid(utils.validate(valueType, event.target.value, required));
            onChange(event.target.value);
          }}
          value={value === null || value === undefined ? "" : value}
          error={valid ? false : true}
          variant={variant}
          InputLabelProps={{
            shrink: true,
          }}
          className={className}
          multiline={multiline}
          minRows={rows > 1 ? rows : multiline ? 3 : rows}
          disabled={disabled}
        />
        {valid ? null : (
          <div style={{ color: "red", fontSize: 12 }}>
            Please input a valid character
          </div>
        )}
      </Root>
    );
  };

  const generateDollarField = () => {
    return (
      <Root>
        <TextField
          fullWidth={fullWidth}
          label={label || ""}
          name={label}
          onChange={(event) => {
            setValid(utils.validate(valueType, event.target.value, required));
            onChange(event.target.value);
          }}
          value={value === null || value === undefined ? "" : value}
          error={valid ? false : true}
          variant={variant}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          multiline={multiline}
          minRows={rows}
          disabled={disabled}
        />
        {valid ? null : (
          <div style={{ color: "red", fontSize: 12 }}>
            Please input a valid number
          </div>
        )}
      </Root>
    );
  };

  const generateMultiAutoComplete = () => {
    const editableTableInputMinWidth = 120;
    const fontSize = 14;
    let indexes = [];
    //TODO: INSTEAD OF CHECKING FOR QEC LABEL, ADD LIST OF ASSETS THAT ALREADY EXIST IN ASSET POOL
    // FIX TO AVOID BREAKING ASSET POOL MULTI-AUTO
    if (label[0] === "Engine Specification Adjusters") {
      //turn neat split string into array
      value = !(value instanceof Array) ? value.split(", ") : ["None"];
      // get the default values from value then convert them to objects
      const dvs = value
        ? value.map((s) => {
            return {
              label: s,
              value: s,
            };
          })
        : [];

      //check the list of options for default values to exclude them.
      dvs.forEach(function (item, index, array) {
        indexes.push(items.findIndex((x) => x.value === item.value));
      });
    }
    return (
      <Root>
        <Autocomplete
          multiple
          filterSelectedOptions
          id={`autoselect`}
          onChange={(e, value) => {
            if (value && !_.isEmpty(value)) {
              if (
                value.slice(-1)[0] !== null &&
                value.slice(-1)[0].value !== null &&
                value.slice(-1)[0].value !== undefined
              ) {
                setValid(
                  utils.validate(constants.AUTO_COMPLETE, value, required)
                );
                onChange(value);
              }
            }
          }}
          options={items}
          defaultValue={
            indexes.length >= 1
              ? indexes.map((x) => {
                  return items[x];
                })
              : []
          }
          getOptionLabel={(items) => items?.label}
          style={{
            color: "#263238",
            padding: "27px 12px 10px !important",
            "&:focus": { backgroundColor: "transparent" },
          }}
          minwidth={editableTableInputMinWidth.toString()}
          fontSize={fontSize}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Select " + label}
              variant='outlined'
            />
          )}
          disabled={disabled}
        />
      </Root>
    );
  };

  const generateAutoComplete = () => {
    const editableTableInputMinWidth = 100;
    const fontSize = 14;

    let cellValue = _.find(items, (item) => item.value === value);

    return (
      <Root>
        <Autocomplete
          id={`autoselect`}
          value={cellValue}
          onChange={(e, value) => {
            if (value && value.value !== null && value.value !== undefined) {
              setValid(
                utils.validate(constants.AUTO_COMPLETE, value.value, required)
              );
              onChange(value.value);
            }
          }}
          options={items}
          getOptionLabel={(items) => items.label}
          getOptionDisabled={(items)=> items.disabled}
          style={{
            color: "#263238",
            padding: "27px 12px 10px !important",
            "&:focus": { backgroundColor: "transparent" },
          }}
          minwidth={editableTableInputMinWidth.toString()}
          fontSize={fontSize}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label ? "Select " + label : null}
              variant='outlined'
            />
          )}
          disabled={disabled}
        />
      </Root>
    );
  };

  const generateVfSelect = () => {
    const editableTableInputMinWidth = 100;
    const fontSize = 14;
    return (
      <Root>
        <VfSelect
          label={label}
          items={items}
          variant={variant}
          fullWidth={fullWidth}
          value={value !== null && value !== undefined ? value : ""}
          minWidth={!fullWidth && editableTableInputMinWidth}
          fontSize={fontSize}
          onSelectChange={(type, value, name) => {
            setValid(utils.validate(constants.SELECT, value, required));
            onChange(value);
          }}
          disabled={disabled}
        />
        {/* {valid ? null : (
          <div style={{ color: "red", fontSize: 10 }}>
            Please select a value.
          </div>
        )} */}
      </Root>
    );
  };

  const generateDatePicker = () => {
    const dateformat = "MM-DD-YYYY";

    return (
      <Root>
        <MuiPickersUtilsProvider moment={moment.utc} utils={LocalizedUtils}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            label={label || ""}
            fullWidth={fullWidth}
            inputVariant={datePickervariant || "standard"}
            placeholder={dateformat}
            format={dateformat}
            value={
              value && value !== constants.NA
                ? moment.utc(value).format(dateformat)
                : null
            }
            onChange={(value) => {
              setValid(utils.validate(constants.DATE, value, required));
              onChange(
                value && value.isValid()
                  ? moment(value).format(constants.FULL_YEAR_DATE)
                  : ""
              );
            }}
            helperText={""}
            inputProps={inputProps}
            KeyboardButtonProps={keyboardButtonProps}
            error={valid ? false : true}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
        {valid ? null : (
          <div className={classes.warning}>Please enter a valid date.</div>
        )}
      </Root>
    );
  };

  const generateCheckBox = () => {
    return (
      <VfCheckBox
        label={label}
        items={items}
        required={required}
        selected={value}
        valid={valid && !errorOnSubmit}
        onChange={onChange}
        disabled={disabled}
      />
    );
  };

  const generateSelectCheckBox = () => {
    const editableTableInputMinWidth = 100;
    const fontSize = 14;
    return (
      <Root>
        <VfSelectCheckBox
          label={label}
          items={items}
          variant={variant}
          fullWidth={fullWidth}
          value={value !== null && value !== undefined ? value : ""}
          minWidth={!fullWidth && editableTableInputMinWidth}
          fontSize={fontSize}
          onChange={(values) => {
            // setValid(utils.validate(constants.SELECT, value, required));

            onChange(values);
          }}
          disabled={disabled}
        />
        {/* {valid ? null : (
            <div style={{ color: "red", fontSize: 10 }}>
              Please select a value.
            </div>
          )} */}
      </Root>
    );
  };

  const generateRichTextField = () => {
    return (
      <Root>
        <MUIRichTextEditor
          defaultValue={value}
          label='Start typing...'
          inlineToolbar={true}
          // onChange={richTextChange}
          onSave={(event) => {
            onChange(event);
          }}
        />
      </Root>
    );
  };

  const generateStatusMXCHField = () => {
    // console.log(type, value, items, mtableEditMode);

    return (
      <Root>
        <Box
          shrink={true}
          style={{
            margin: "0px 2px",
            border: "1px solid #D3D3D3",
          }}>
          {"Autofilled if SV"}
        </Box>
        <Input
          type={"text"}
          multiline={isMultiline}
          onChange={(event) => {
            setValid(utils.validate("number", event.target.value, required));

            onChange(event.target.value);
          }}
          value={!value ? "" : value}
          error={valid ? false : true}
          inputProps={inputProps}
          required
          disabled={disabled}
        />
        {valid ? null : (
          <div className={classes.warning}>Please input a valid number</div>
        )}
      </Root>
    );
  };

  const generateInputField = () => {
    return (
      <Root>
        <InputLabel>{label[0]}</InputLabel>
        <Input
          type={"text"}
          multiline={isMultiline}
          onChange={(event) => {
            setValid(utils.validate(type, event.target.value, required));

            onChange(event.target.value);
          }}
          value={!value ? "" : value}
          error={valid ? false : true}
          inputProps={inputProps}
          required
          disabled={disabled}
        />
        {valid ? null : (
          <div className={classes.warning}>Please input a valid number</div>
        )}
      </Root>
    );
  };

  const getInputFieldByType = (type) => {
    switch (type) {
      case constants.SELECT:
        return generateVfSelect();
      case constants.SELECT_CHECK_BOX:
        return generateSelectCheckBox();
      case constants.DATE:
        return generateDatePicker();
      case constants.TEXT_FIELD:
        return generateTextField();
      case constants.CHECK_BOX:
        return generateCheckBox();
      case constants.AUTO_COMPLETE:
        return generateAutoComplete();
      case constants.MULTI_AUTO:
        return generateMultiAutoComplete();
      case constants.DOLLARNUMBER:
        return generateDollarField();
      case constants.RICH_TEXT_FIELD:
        return generateRichTextField();
      case constants.RADIO:
        return generateRadioButtons();
      case constants.STATUSMXCH:
        return generateStatusMXCHField();
      default:
        return generateInputField();
    }
  };

  return (
    // This is not what it is intended, but, until material table component
    // gives the control of cancel edting function. We have to stick
    // with the below cheap fix.
    <Root>
      {required && type !== constants.CHECK_BOX ? (
        !value ? (
          <div className={classes.warning}>*Required</div>
        ) : null
      ) : !errorOnSubmit || value ? null : (
        <div className={classes.warning}>*Required</div>
      )}
      {getInputFieldByType(type)}
    </Root>
  );
};

InputFieldComponent.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  datePickervariant: PropTypes.string,
  errorOnSubmit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  isMultiline: PropTypes.bool,
  items: PropTypes.array,
  keyboardButtonProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  value: PropTypes.any,
  valueType: PropTypes.string,
  variant: PropTypes.string,
};

export default InputFieldComponent;
