import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: 0,
    top: 56,
    width: "100%",
    backgroundColor: "rgba(2,48,89,0.05)",
    boxShadow: "0 0.5px 2px 0 rgba(14,37,63,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content:{
    maxWidth: 1500,
    minWidth: 1024,
    width: "95%",
    height: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));

const VfBreadcrumbContainer = ({
  breadcrumb,
  button,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box>
          {breadcrumb}
        </Box>
        <Box >
          {button}
        </Box>   
      </Box>    
    </Box>
  );
};

export default VfBreadcrumbContainer;
