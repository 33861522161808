import { default as mapToViewModel_apuDetails } from "./apuDetails";
import { default as mapToViewModel_performanceRestAssump } from "./performanceRestAssump";

export default function mapToViewModel_APUGeneric(
  data,
  handleSelectChange,
  props,
  selectedModel,
) {
  return {
    apuDetails: mapToViewModel_apuDetails(data, handleSelectChange, props, selectedModel),
    perRestAssump: mapToViewModel_performanceRestAssump(data, props, selectedModel),
  };
}