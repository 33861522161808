import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Fade,
  Modal,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import SponsorFilter from "./SponsorFilter";
import DealStageFilter from "./DealStageFilter";
import SectionHeader from "../../../Components/Common/Section/SectionHeader";
import DealAdd from "./DealAdd";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles({
  root: { maxWidth: "100%", margin: "auto" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modelBox: {
    overflowX: "hidden",
    overflowY: "auto",
    padding: 30,
    margin: "auto",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  switchButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    float: "center",
    margin: "0 0 20px 0",
  },
  threeDotButton: { display: "inline-flex", float: "right" },
});

const DealList = ({
  dealStages,
  handleChange,
  checkedDealStage,

  searchDealName,
  searchDeal,
  handleDealAdd,

  sponsorsList,
  searchSponsor,
  searchSponsorChange,
  handleSponsorChange,
  checkedSponsor,

  handleEditSTCOL,
  editSTR_COL,
}) => {
  const classes = useStyles();
  const [openDealAdd, setOpenDealAdd] = useState(false);
  const [dealNameQuery, setDealNameQuery] = useState(searchDealName);

  const handleDebouncedSearch = useMemo(
    () =>
      _.debounce((query) => {
        searchDeal(query);
      }, 500),
    [searchDeal]
  );

  const handleSearch = (event) => {
    const searchQuery = event.currentTarget.value;
    setDealNameQuery(searchQuery);
    handleDebouncedSearch(searchQuery);
  };

  useEffect(() => {
    return () => {
      handleDebouncedSearch.cancel();
    };
  }, [handleDebouncedSearch]);

  return (
    <Box className={classes.root}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "48px 0 8px 0",
        }}>
        <SectionHeader label={"Deals"} />

        <Box>
          <Button
            style={{ maxHeight: 28, marginLeft: 16, marginTop: 12 }}
            variant='outlined'
            color='primary'
            onClick={() => setOpenDealAdd(true)}>
            <span style={{ margin: "0px 8px 0px 8px" }}>{"Add Deal"}</span>
          </Button>
          {editSTR_COL ? (
            <Button
              style={{ maxHeight: 28, marginLeft: 16, marginTop: 12 }}
              variant='outlined'
              color='primary'
              onClick={() => handleEditSTCOL(false)}>
              <ArrowBackIosIcon />
              <span style={{ margin: "0px 8px 0px 8px" }}>{"Deals"}</span>
            </Button>
          ) : (
            <Button
              style={{ maxHeight: 28, marginLeft: 16, marginTop: 12 }}
              variant='outlined'
              color='primary'
              onClick={() => handleEditSTCOL(true)}>
              <span style={{ margin: "0px 8px 0px 8px" }}>{"Edit ST COL"}</span>
              <ArrowForwardIosIcon />
            </Button>
          )}
        </Box>
      </Box>
      <Box my={1} mb={1.5}>
        <Box
          alignItems='center'
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
          }}>
          <Box style={{ display: "flex", justifyContent: "flex-start" }}>
            <TextField
              id='input-with-icon-textfield-searchDealName'
              style={{ maxHeight: 28, margin: "0px 8px 0px 8px" }}
              label={
                <Typography
                  variant='button'
                  color='primary'
                  style={{ fontSize: 14, maxHeight: 28 }}>
                  SEARCH
                </Typography>
              }
              InputLabelProps={{ style: { top: -8 } }}
              inputProps={{ style: { height: 8 } }}
              variant='outlined'
              size='small'
              onChange={handleSearch}
              value={dealNameQuery}
            />
            <DealStageFilter
              dealStages={dealStages}
              handleChange={handleChange}
              checkedDealStage={checkedDealStage}
            />
          </Box>
          <Box>
            <SponsorFilter
              sponsorsList={sponsorsList}
              searchSponsor={searchSponsor}
              searchSponsorChange={searchSponsorChange}
              handleSponsorChange={handleSponsorChange}
              checkedSponsor={checkedSponsor}
            />
          </Box>
        </Box>

        <DealAdd
          dealStages={dealStages}
          handleDealAdd={handleDealAdd}
          open={openDealAdd}
          setOpen={setOpenDealAdd}
        />
      </Box>
    </Box>
  );
};

export default DealList;
