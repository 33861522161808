import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";
import _ from "lodash";

export function mapToViewModel_ExtensionRent(extensions, props) {
  let data = [];
  let selectExtension = [];

  if (!_.isEmpty(extensions)) {
    extensions.forEach((ext) => {
      if (ext.rentPayments) {
        data.push(
          ext.rentPayments.map((p, idx) => ({
            id: idx,
            fromDate: p.from,
            toDate: p.to,
            paymentDate: p.paymentDate,
            rent: p.amount ? p.amount : p.pbhRate,
            pbhRate: p.pbhRate ? constants.YES : constants.NO,
            selectExtension: p.selectExtension,
            paymentFrequency:
              moment.utc(p.to).diff(moment.utc(p.from), "days") <= 31
                ? constants.Monthly
                : constants.Quarterly,
          }))
        );
      }
    });
    data = _.flattenDeep(data);

    selectExtension = extensions.map((x, i) => {
      return {
        label: i + 1,
        value: i,
      };
    });
  }

  const items = [
    { label: constants.Monthly, value: constants.Monthly },
    { label: constants.Quarterly, value: constants.Quarterly },
  ];

  return {
    label: "Extension Rent",
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: "extensionRent",
    errorsOnSubmit: {},
    props,
    isDeletable: (rowData) => {
      return false;
    },
    selectExtension: selectExtension,
    showOptions: _.isEmpty(extensions) ? false : true,
    columns: [
      {
        title: "From Date",
        field: "fromDate",
        required: true,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.fromDate).format(constants.DATE_FORMAT),
      },
      {
        title: "To Date",
        field: "toDate",
        required: true,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.toDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Payment Date",
        field: "paymentDate",
        required: true,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.paymentDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Rent",
        field: "rent",
        required: true,
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.rent,
            null,
            constants.NUMERIC_CELL,
            "$"
          ),
      },
      {
        title: "PBH",
        field: "pbhRate",
        required: true,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items: [
          { label: constants.YES, value: constants.YES },
          { label: constants.NO, value: constants.NO },
        ],
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Payment Frequency",
        field: "paymentFrequency",
        required: true,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.NEVER,
        items: items,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
    ],
    data: data,
  };
}
