import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, TableFooter, TableRow, Typography, Divider } from "@material-ui/core";
import MultiFunctionTableCell from "./MultiFunctionTableCell";

const useStyles = makeStyles({
  tableFooterCell: {
    paddingTop: 2,
  },
  totalRowSeperator: {
    height: 30,
  },
});

function renderCellValues(classes, value, content, align = null, indent = 0) {
  const style = {
    paddingLeft: align === "left" ? indent : 0,
    paddingRight: align === "right" ? indent : 0,
    
  };

  const tableFooterValue = {
    fontSize: value === "Total" ? 14 : 12,
    letterSpacing: "0.4px",
    lineHeight: "12px",
    fontWeight: value === "Total" ? 700 : 500,
  };

  return [
    <Box key={0} style={style} className={classes.tableFooterCell}>
      <Typography variant="subtitle1" style={tableFooterValue}>
        {content ? content(value, 0) : value}
      </Typography>
    </Box>,
  ];
}

const MultiFunctionTableFooter = ({ columnHeaders, data }) => {
  const classes = useStyles();
  const sticky = { position: "sticky", bottom: 0, zIndex: 50, borderTop: "2px solid rgba(38, 50, 56, 0.5)", background: "#fff" };

  return (
    <TableFooter>
      <TableRow className={classes.totalRowSeperator} />
      <TableRow>
        {columnHeaders.map((header, idx) =>
          header.hidden ? null : (
            <MultiFunctionTableCell
              key={`${header.label}_${idx}`}
              border={"none"}
              align={header.align}
              sticky={sticky}
            >
              {renderCellValues(
                classes,
                data[header.field],
                header.content,
                header.align,
                header.indent
              )}
            </MultiFunctionTableCell>
          )
        )}
      </TableRow>
    </TableFooter>
  );
};

export default MultiFunctionTableFooter;
