import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/highcharts-more.js")(Highcharts);

const useStyles = makeStyles({
  rectangle13: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 12,
    height: "100%",
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const CFAssetValueChart = ({ options }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.rectangle13}>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </Paper>
  );
};

export default CFAssetValueChart;
