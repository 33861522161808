import _ from "lodash";

export const updateData = (scenario, sectionIdx, newData) => {
  scenario.leases = [...scenario.leases];
  scenario.leases[sectionIdx] = { ...scenario.leases[sectionIdx] };
  const componentLeaseInfo = [
    ...scenario.leases[sectionIdx].componentLeaseInfo,
  ];
  for (let key in newData) {
    const componentName = key.split("_")[1];
    const leaseInfoIdx = _.findIndex(
      componentLeaseInfo,
      (o) => o.componentName === componentName
    );

    let fundingPolicies = scenario.leases[sectionIdx].componentLeaseInfo[leaseInfoIdx].fundingPolicies

    for (var j = 0; j < fundingPolicies.length; j++) {
      fundingPolicies[j].llpReplaceCostType = newData[key];
    }
  }

  return scenario;
};
