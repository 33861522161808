import _ from "lodash";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";

export function mapToViewModel_financialCovenants(loanSummary, props) {
  let columns = [
    {
      label: ["LTV Test"],
      field: "ltvTest",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Frequency"],
      field: "frequency",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["1st LTV Test Date"],
      field: "firstLTVTestDate",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: ["LTV Ratio"],
      field: "ltvRatio",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Consequence"],
      field: "consequence",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
  ];

  let data = {
    ltvTest: loanSummary.financialCovenants.ltvTest,
    frequency: loanSummary.financialCovenants.frequency,
    firstLTVTestDate: loanSummary.financialCovenants.firstLTVTestDate,
    ltvRatio: loanSummary.financialCovenants.ltvRatio,
    consequence: loanSummary.financialCovenants.consequence,
  };

  loanSummary.financialCovenants.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  });

  return {
    label: "Financial Covenants",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Financial Covenants",
    columns: columns,
    data: data,
  };
}
