import _ from "lodash";
import React from "react";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";
import { Box } from "@material-ui/core";

export default function mapToViewModel_liabilityCashFlows(
  liabilityData,
  deal,
  options
) {
  let data = liabilityData.liabilityModelData.modelCashflow
    ? liabilityData.liabilityModelData.modelCashflow
    : [];

  return {
    liabilityCashflows: {
      label: "Liability Cashflows",
      caption: null,
      tableType: constants.MULTIFUNCTION_TABLE,
      options: { pagination: true },
      optionsItems: {
        pagination: {
          page: 0,
          rowsPerPage: 10,
          tableName: "liabilityCashflows",
          handleChangePage: options.pagination.handleChangePage,
          handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
        },
      },
      span: [12, 12, 12],
      columns: [
        {
          label: "Date",
          caption: null,
          field: "date",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.DATE_CELL
            ),
        },
        // {
        //   label: "HL Value",
        //   caption: "U.S. $",
        //   field: "hlValue",
        //   align: "right",
        //   indent: 16,
        //   content: (value, indent) =>
        //     utils.renderCellValue(
        //       value,
        //       { paddingRight: indent },
        //       constants.NUMERIC_CELL
        //     ),
        // },
        {
          label: "Asset Value",
          caption: "U.S. $",
          field: "assetValue",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Security Deposit",
          caption: "U.S. $",
          field: "securityDeposit",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Reserve / Gurantee",
          caption: "U.S. $",
          field: "lcReserve_Gurantee",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Cash Reserve",
          caption: "U.S. $",
          field: "cashReserve",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        // {
        //   label: "Portfolio Value",
        //   caption: "U.S. $",
        //   field: "portfolioValue",
        //   align: "right",
        //   indent: 16,
        //   content: (value, indent) =>
        //     utils.renderCellValue(
        //       value,
        //       { paddingRight: indent },
        //       constants.NUMERIC_CELL
        //     ),
        // },
        {
          label: "Loan Outstanding",
          caption: "U.S. $",
          field: "loanOutstanding",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "LTV",
          caption: "%",
          field: "ltvPct",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {_.round(value * 100, 3).toFixed(2)}
            </Box>
          ),
        },
        {
          label: "Max LTV",
          caption: "%",
          field: "maxLTVPct",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {_.round(value * 100, 3).toFixed(2)}
            </Box>
          ),
        },
        {
          label: "PPMT Regular",
          caption: "U.S. $",
          field: "ppmT_Regular",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "PPMT Disposition",
          caption: "U.S. $",
          field: "ppmT_Disposition",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "IPMT",
          caption: "U.S. $",
          field: "ipmt",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "EOL Comp To Lessor",
          caption: "U.S. $",
          field: "eolCompToLessor",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Lessor Exposure",
          caption: "U.S. $",
          field: "lessorExposure",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Interest Rate",
          caption: "%",
          field: "interestRatePct",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {_.round(value * 100, 3).toFixed(2)}
            </Box>
          ),
        },
      ],
      data: data.map((x) => {
        return {
          date: x.paymentDate,
          hlValue: x.hlValue,
          assetValue: x.assetValue,
          securityDeposit: x.securityDeposit,
          lcReserve_Gurantee: x.lcReserve_Gurantee,
          cashReserve: x.cashReserve,
          portfolioValue: x.portfolioValue,
          loanOutstanding: x.loanOutstanding,
          ltvPct: x.ltvPct,
          maxLTVPct: x.maxLTVPct,
          ppmT_Regular: x.ppmT_Regular,
          ppmT_Disposition: x.ppmT_Disposition,
          ipmt: x.ipmt,
          eolCompToLessor: x.eolCompToLessor,
          lessorExposure: x.lessorExposure,
          interestRatePct: x.interestRatePct,
        };
      }),
    },
  };
}
