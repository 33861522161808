import _ from "lodash";
import React, { useState } from "react";
import moment from "moment";
import {
  Box,
  TextField,
  InputAdornment,
  FormControlLabel,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import { apiRequest, response } from "../../../../../../../utils/ApiRequest";
import ApiRoutes from "../../../../../../../utils/ApiRoutes";
import * as utils from "../../../../../../../utils/utils";
import ThreeDotButton from "../../../../../../Common/ThreeDotButton";
import VfAlertDialogBox from "../../../../../../Common/UtilComponents/VfAlertDialogBox";
import VfSnackerBar from "../../../../../../Common/UtilComponents/VfSnackerBar";

const ScenarioThreeDotOptions = ({
  selectedScenario,
  handleSnackerBarOpen,
  scenario,
  report,
  accounts,
  msalContext,
}) => {
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  const [exportFileName, setExportFileName] = useState("");
  const [includeMR, setIncludeMR] = useState(false);
  const [includeCompRDAExposure, setIncludeCompRDAExposure] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [duration, setDuration] = useState(1500);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState(<Box style={{width: 280,display: "flex",justifyContent: "space-between",}}>Processing <CircularProgress color='inherit' size={20} /></Box>);

  const handleExportButtonClick = async (assetInfo, scenario) => {
    let scenarioName =
      scenario.name + "_" + moment.utc(scenario.date).format("MM-DD-YYYY");
    setExportFileName(
      "Cashflow_" + assetInfo.serialNumber + "_" + scenarioName
    );
    handleAlertDialogVisible(true);
  };

  const handleDownloadOutput = async () => {
    //dialog to add MR then make request to backend for new

    let downloadObject = {
      _id: selectedScenario.id,
      input: scenario,
      output: report,
    };
    let filename = `SN${scenario.serialNumber}-output.json`;
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [
          decodeURIComponent(
            encodeURI(JSON.stringify(downloadObject, null, 4))
          ),
        ],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href =
        "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(downloadObject, null, 4));
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleAlertDialogVisible = async (open) => {
    setAlertDialogOpen(open);
  };

  const handleExportFileNameChange = () => (event) => {
    setExportFileName(event.target.value);
  };

  const handleMRCheckBoxChange = (event) => {
    setIncludeMR(event.target.checked);
  };
  const handleExpCheckBoxChange = (event) => {
    setIncludeCompRDAExposure(event.target.checked);
  }

  const renderAlertDialogBody = (exportFileName) => {
    return (
      <div>
        <TextField
          id='filled-basic'
          label='Export File Name'
          variant='filled'
          defaultValue={exportFileName}
          onBlur={handleExportFileNameChange()}
          style={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>.xlsx</InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          label='Include MR'
          style={{ width: "100%" }}
          control={
            <Checkbox checked={includeMR} onChange={handleMRCheckBoxChange} />
          }
        />
          <FormControlLabel
          label='Include RDA Exposure By Component'
          style={{ width: "100%" }}
          control={
            <Checkbox checked={includeCompRDAExposure} onChange={handleExpCheckBoxChange} />
          }
        />
      </div>
    );
  };

  const exportCSV = async () => {
    var scenid = selectedScenario.id;
    let exportFileObj = {
      userName: accounts[0].username,
      fileName: report.report.assetInfo.serialNumber+ "_"+scenid,
      fileFormat: "Excel",
      cashFlowModel: {
        scenarioIds: [selectedScenario.id],
        asofdate: encodeURIComponent(
          `${moment.utc(new Date()).endOf("month").format("MM-DD-YYYY")}`
        ),
      },
    };
    setsnackOpen(true);
    const resp = await apiRequest(
      {
        url: ApiRoutes.VectorDeal_ExportAssetCSV({
          scenarioId: scenid,
        }),
        method: "POST",
        data: exportFileObj,
      },
      msalContext
    );

    if (resp.status === response.OK) {
      handleSnackerBarOpen(
        true,
        "success",
        "Asset Cashflow CSV Generated, Check Archive"
      );
      handleAlertDialogVisible(false);
    } else {
      this.handleSnackerBarOpen(true, "error", "Error Generating Cashflow CSV");
    }
  };

  const exportFile = async () => {
    const urlValues = utils.parseUrlObject(new URL(window.location.href));
    var addsheets = [
      "Scenario",
      "Aggregate_Data",
      "Component_Status",
      //"LLP_Status",
      "Mx_Events",
      "Rda_Events",
      "Engines",
      "LLP_Events",
      "Lease_Report",
      "Deal_Info",
      "Asset_Info",
      "AC_Info",
      "AC_CV",
      "AC_FV",
      "ENG_Info",
      "ENG_CV",
      "ENG_FV",
      "Simulation_Params",
    ];
    if (includeMR) addsheets.push("MR");
    if (includeCompRDAExposure) addsheets.push("Component_RedelivCompExp");

    let exportFileObj = {
      userName: accounts[0].username,
      fileName: exportFileName + ".xlsx",
      fileFormat: "Excel",
      cashFlowModel: {
        scenarioIds: [selectedScenario.id],
        asofdate: encodeURIComponent(
          `${moment.utc(new Date()).endOf("month").format("MM-DD-YYYY")}`
        ),
      },
      sheets: addsheets,
    };
    setsnackOpen(true);

    //TODO: CHANGE THIS FROM EXPORT PORTFOLIO TO EXPORT ASSET
    const resp = await apiRequest(
      {
        url: ApiRoutes.VectorDeal_ExportPortfolio({
          dealId: urlValues.dealId ? parseInt(urlValues.dealId) : -1,
        }),
        method: "POST",
        data: exportFileObj,
      },
      msalContext
    );

    if (resp.status === response.OK) {
      handleSnackerBarOpen(
        true,
        "success",
        "Asset Cash flow Report Generated Successfully !!"
      );
      handleAlertDialogVisible(false);
    } else {
      this.handleSnackerBarOpen(
        true,
        "error",
        "Error Generating Cash flow Report !!"
      );
    }
  };

  const exportMaintInfo = async () => {
    var scenid = selectedScenario.id;
    var user = accounts[0].username;
    setsnackOpen(true);
    const resp = await apiRequest(
      {
        url: ApiRoutes.VectorDeal_ExportMaintInfo({
          scenId: scenid,
          user: user
        }),
        method: "POST",
      },
      msalContext
    );

    if (resp.status === response.OK) {
      handleSnackerBarOpen(
        true,
        "success",
        "Asset Maintenance Info Excel Created, Check Archive"
      );
      handleAlertDialogVisible(false);
    } else {
      this.handleSnackerBarOpen(true, "error", "Error Generating MxInfo Excel");
    }
  };

  let threeDotBtnOptions = [];
  if (report)
    threeDotBtnOptions = [
      {
        id: 0,
        // icon: ExportIconSvg,
        label: "Export Cashflow",
        handleClick: () =>
          handleExportButtonClick(report.report.assetInfo, selectedScenario),
      },
      {
        id: 1,
        // icon: ExportIconSvg,
        label: "Download Output",
        handleClick: () => handleDownloadOutput(),
      },
      {
        id: 2,
        label: "Export Aggr CSVs",
        handleClick: () => exportCSV(),
      },
      {
        id: 3,
        label: "Export Maintenance Info",
        handleClick: () => exportMaintInfo(),
      },
    ];

  return (
    <Box style={{ maxWidth: 50 }}>
      <VfSnackerBar
          snackOpen={snackOpen}
          setsnackOpen={setsnackOpen}
          duration={duration}
          severity={severity}
          message={message}
        />
      {isAlertDialogOpen ? (
        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={handleAlertDialogVisible}
          dialogTitle={"Export Asset Cashflow Report"}
          dialogBody={renderAlertDialogBody(exportFileName)}
          onAccept={exportFile}
        />
      ) : null}

      <Box
        style={{
          backgroundColor: "transparent",
          margin: "-10px 0px 0px 0px",
          maxHeight: 28,
        }}>
        <ThreeDotButton options={threeDotBtnOptions} />
      </Box>
    </Box>
  );
};

export default ScenarioThreeDotOptions;
