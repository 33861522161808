import { default as mapToViewModel_airframeDetails } from "./airframeDetails";
import { default as mapToViewModel_airframeChecks } from "./airframeChecks";
import { default as mapToViewModel_lgChecks } from "./lgChecks";

export default function mapToViewModel_AirframeGeneric(
  data,
  handleSelectChange,
  props,
  selectedSubseries,
) {
  return {
    airframeDetails: mapToViewModel_airframeDetails(data, handleSelectChange, props, selectedSubseries),
    airframeChecks: mapToViewModel_airframeChecks(data, props, selectedSubseries),
    lgChecks: mapToViewModel_lgChecks(data, props, selectedSubseries),
  };
}
