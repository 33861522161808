import _ from "lodash";
import { default as mapToViewModel_acapuCombos } from "./ACAPUCombos";
import { default as mapToViewModel_acengCombos } from "./ACENGCombos";

export default function mapToViewModel_CombinationsGeneric(
  data,
  props,
) {

  const styles = {
    tableNamePanel: {
      margin: 10,
      marginBottom: 5,
      backgroundColor: "#f6f6f6",
    },
    cellStyle: {
      color: "#023059",
      textAlign: "center",
      fontSize: 11,
      height: 40,
      padding: 0,
      borderBottom: "unset",
      borderTop: "1px solid rgba(224, 224, 224, 1)",
    },
    boldCellStyle: {
      fontWeight: "bold",
      fontSize: 12,
    },
  };

  const sortedAirframeData = _.sortBy(data.airframes, [function(o) { return o.subseries; }]);
  const sortedAPUData = _.sortBy(data.apUs, [function(o) { return o.model; }]);
  const sortedEngineData = _.sortBy(data.engines, [function(o) { return o.subseries; }]);

  const airframeItems = sortedAirframeData.map((o) => ({
    label: o.subseries,
    value: o.subseries,
    id: o.id,
  }));
  const apuItems = sortedAPUData.map((o) => ({
    label: o.model,
    value: o.model,
    id: o.id,
  }));
  const engineItems = sortedEngineData.map((o) => ({
    label: o.subseries,
    value: o.subseries,
    id: o.id,
  }));

  // const sortACAPUCombos = _.sortBy(data.acApuCombos, ['aircraftSubseries', 'apuModel'])
  // const sortACENGCombos = _.sortBy(data.acEngCombos, ['aircraftSubseries', 'engineSubseries'])

  return {
    ACAPUCombos: mapToViewModel_acapuCombos(data.acApuCombos, props, styles, airframeItems, apuItems),
    ACENGCombos: mapToViewModel_acengCombos(data.acEngCombos, props, styles, airframeItems, engineItems),
  };
}