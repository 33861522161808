import _ from "lodash";
import React from "react";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

import { ReactComponent as CopaIcon } from "../../../../assets/images/airlines/CopaIcon.svg";
import { ReactComponent as EtihadIcon } from "../../../../assets/images/airlines/EtihadIcon.svg";
import { ReactComponent as SpiceJetIcon } from "../../../../assets/images/airlines/SpiceJetIcon.svg";

function mapToViewModelHeader_AssetSummary(data) {
  const snapShot = {
    name: "generalInfo",
    componentType: "GeneralInfo",
    series: data.aircraftSubSeries || data.engineSubSeries,
    operator: data.operator,
    buildYear: data.manufactureDate,
    flightHours_sinceNew: data.airframeChecks
      ? data.airframeChecks[0].maintenanceStatus[0].sinceNew.flightHour
      : data.engines[0].maintenanceStatus[0].sinceNew.flightHour,
    flightCycles_sinceNew: data.airframeChecks
      ? data.airframeChecks[0].maintenanceStatus[0].sinceNew.flightCycle
      : data.engines[0].maintenanceStatus[0].sinceNew.flightCycle,
  };

  return [
    {
      label: snapShot.series,
      caption: snapShot.operator,
      span: [3, 3, 6],
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },

    {
      label: snapShot.flightHours_sinceNew,
      caption: "Total Flight Hours",
      // field: "flightHours_sinceNew",
      span: [3, 3, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: snapShot.flightCycles_sinceNew,
      caption: "Total Flight Cycles",
      // field: "flightCycles_sinceNew",
      span: [3, 3, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: snapShot.buildYear,
      caption: "Year of Build",
      span: [3, 3, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
  ];
}

function mapToViewModelHeader_AirframeCheck(snapShot) {
  return [
    { label: snapShot.checkName, caption: null, span: [3, 4, 7] },

    {
      label: snapShot.maintenanceStatus[0].sinceEvent.flightHour,
      // field: "flightHours_sinceEvent",
      caption: "Hours Since",
      span: [2, 2, 1],
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: snapShot.maintenanceStatus[0].sinceEvent.flightCycle,
      // field: "flightCycles_sinceEvent",
      caption: "Cycles Since",
      span: [2, 2, 1],
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: snapShot.maintenanceStatus[0].sinceEvent.day,
      // field: "days_sinceEvent",
      caption: "Days Since",
      span: [2, 2, 1],
      content: (value) =>
        utils.renderCellValue(
          value,
          { paddingRight: 0 },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: snapShot.maintenanceStatus[0].statusAsOf,
      // field: "asOfDate",
      caption: "As of Date",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
  ];
}

function mapToViewModelHeader_Engine(snapShot) {
  return [
    {
      label: `${snapShot.engineSubSeries} (${snapShot.serialNumber})`,
      caption: null,
      span: [3, 6, 6],
    },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].sinceEvent.flightHour
        : constants.___,
      caption: "FH Since P.R.",
      // field: "flightHours_sinceEvent",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].sinceEvent.flightCycle
        : constants.___,
      caption: "FC Since P.R.",
      // field: "flightCycles_sinceEvent",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].statusAsOf
        : constants.___,
      caption: constants.AS_OF_DATE,
      // field: "asOfDate",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
  ];
}

function mapToViewModelHeader_LandingGear(snapShot) {
  return [
    {
      label: snapShot.position,
      caption: null,
      span: [3, 6, 6],
    },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].sinceEvent.day
        : constants.___,
      // field: "flightHours_sinceEvent",
      caption: "Months Since O.H.",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(
          _.round(value / 30.44),
          null,
          constants.NUMERIC_CELL
        ),
    },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].sinceEvent.flightCycle
        : constants.___,
      caption: "FC Since O.H.",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].statusAsOf
        : new Date(),
      // field: "asOfDate",
      caption: "As of Date",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
  ];
}

function mapToViewModelHeader_APU(snapShot) {
  return [
    { label: snapShot.model, caption: null, span: [4, 6, 8] },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].sinceEvent.activeHour
        : null,
      caption: "APUH Since P.R.",
      span: [4, 3, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.NUMERIC_CELL),
    },
    {
      label: snapShot.maintenanceStatus.length
        ? snapShot.maintenanceStatus[0].statusAsOf
        : new Date(),
      // field: "asOfDate",
      caption: "As of Date",
      span: [4, 3, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
  ];
}

function mapToViewModelHeader_Lease(snapShot) {
  return [
    {
      label: snapShot.lessee || constants.___,
      caption: null,
      span: [1, 3, 4],
      content: (value) => {
        return (
          value &&
          (value.includes("Copa") ? (
            <CopaIcon style={{ verticalAlign: "sub" }} />
          ) : value.includes("Etihad") ? (
            <EtihadIcon style={{ verticalAlign: "sub" }} />
          ) : value.includes("Spice") ? (
            <SpiceJetIcon style={{ verticalAlign: "sub" }} />
          ) : (
            value
          ))
        );
      },
    },

    {
      label: snapShot.leaseStartDate,
      caption: "Start Date",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: snapShot.leaseEndDate,
      caption: "End Date",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: utils.formatNumber(
        _.round(_.meanBy(snapShot.rentPayments, "amount"), 0),
        "$",
        "/m"
      ),
      caption: "Lease Rent",
      span: [3, 2, 2],
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: snapShot.leaseType,
      caption: null,
      span: [2, 2, 2],
      content: (value) => (
        <div
          style={{
            height: 30,
            width: 100,
            backgroundColor: "#023059",
            borderRadius: 4,
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "#FFFFFF",
              letterSpacing: "0.25px",
              fontSize: "14px",
              lineHeight: "30px",
            }}
          >
            {value}
          </span>
        </div>
      ),
    },
  ];
}

function mapToViewModelHeader_Utilization(_, idx) {
  return [
    {
      label: `Utilization #${idx}` || constants.___,
      caption: null,
      span: [12, 12, 12],
    },
  ];
}

const headerMapper = {
  assetSummary: mapToViewModelHeader_AssetSummary,
  airframeCheck: mapToViewModelHeader_AirframeCheck,
  engine: mapToViewModelHeader_Engine,
  landingGear: mapToViewModelHeader_LandingGear,
  apu: mapToViewModelHeader_APU,
  lease: mapToViewModelHeader_Lease,
  utilization: mapToViewModelHeader_Utilization,
};

export default headerMapper;
