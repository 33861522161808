import _ from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { Box, Button } from "@material-ui/core";
import LoadingPanel from "../Common/LoadingPanel";
import apiRoutes from "../../utils/ApiRoutes";
import { apiRequest, response } from "../../utils/ApiRequest";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import * as constants from "../../constants";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import { useMsal } from "@azure/msal-react";
import SectionHeader from "../Common/Section/SectionHeader";
import mapToViewModel_strvaluecolrating from "./mapToViewModels/editLiabilityLoans";
import EditableTable from "../Common/Tables/EditableTable/EditableTable";

const handleLiabilityModelExtrasUpdate = async (
  newData,
  deal,
  strv_colr_data,
  setSTRv_COLr_data,
  handleSnackerBarMessage,
  msalContext
) => {
  let checkAllValueFilled = true;
  let newValueList = [];

  const sc_data = _.cloneDeep(strv_colr_data);
  newData.forEach((el) => {
    el.collateralRating = parseFloat(el.collateralRating);
    el.strategicValue = parseFloat(el.strategicValue);
    el.id = null;
  });

  deal.assets.forEach((ast) => {
    const template = {
      assetId: null,
      collateralRating: null,
      dealId: null,
      serialNumber: null,
      strategicValue: null,
    };

    let newValues = _.find(newData, (x) => x.serialNumber === ast.serialNumber);

    if (newValues) {
      if (
        isNaN(newValues.collateralRating) ||
        isNaN(newValues.strategicValue)
      ) {
        checkAllValueFilled = false;
      } else {
        template.dealId = newValues.dealId;
        template.assetId = newValues.assetId;
        template.serialNumber = newValues.serialNumber;
        template.collateralRating = parseFloat(newValues.collateralRating);
        template.strategicValue = parseFloat(newValues.strategicValue);

        newValueList.push(template);
      }
    } else {
      if (deal.dealId in sc_data) {
        let oldValue = _.find(
          sc_data[deal.dealId],
          (x) => x.serialNumber === ast.serialNumber
        );
        if (oldValue) {
          newValueList.push(oldValue);
        } else {
          checkAllValueFilled = false;
        }
      } else {
        checkAllValueFilled = false;
      }
    }
  });

  if (checkAllValueFilled) {
    const result = await apiRequest(
      {
        url: apiRoutes.LiabilityModel_PostLiabilityModelExtras,
        method: "POST",
        data: newValueList,
        params: {
          dealId: deal.dealId,
        },
      },
      msalContext
    );

    if (result.status === response.OK) {
      sc_data[deal.dealId] = newValueList;
      setSTRv_COLr_data(sc_data);
    }
  } else {
    handleSnackerBarMessage(
      true,
      "error",
      "you have not enter all values for the assets in this deal"
    );
  }
};

const STRVandCOLR = ({ dealsData, handleSnackerBarMessage }) => {
  const msalContext = useMsal();
  //   const { pipelineDealsData } = useContext(DealRelatedDataContext);
  const [strv_colr_data, setSTRv_COLr_data] = useState([]);

  useEffect(() => {
    const fetchSTRVandCOLR = async () => {
      const strv_colr_list = await apiRequest(
        {
          url: apiRoutes.LiabilityModel_GetLiabilityModelExtras,
          method: "GET",
        },
        msalContext
      );

      if (strv_colr_list.status === response.OK) {
        let grouped = _.groupBy(strv_colr_list.data, "dealId");
        setSTRv_COLr_data(grouped);
      }
    };
    fetchSTRVandCOLR();
  }, []);

  return (
    <ErrorBoundary style={{ overflow: "hidden", paddingTop: 10 }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "48px 0 8px 0",
        }}>
        <SectionHeader label={"Strategic Value & Collateral Rating Editing"} />
        {/* <Button
          style={{ maxHeight: 28, marginLeft: 16, marginTop: 12 }}
          variant='outlined'
          color='primary'
          onClick={() => handleEditSTCOL(false)}>
          <span style={{ margin: "0px 8px 0px 8px" }}>{"Deals"}</span>
        </Button> */}
      </Box>
      {dealsData.map((deal, idx) => (
        <Box key={idx} style={{ margin: "0 0 20px 0" }}>
          {/* {deal.dealName} */}
          <EditableTable
            table={mapToViewModel_strvaluecolrating(deal, strv_colr_data, {
              sectionIdx: 0,
              section: "editLiabilityLoans",
              funcs: {
                handleValuesUpdate: (_, __, ___, newData) =>
                  handleLiabilityModelExtrasUpdate(
                    newData,
                    deal,
                    strv_colr_data,
                    setSTRv_COLr_data,
                    handleSnackerBarMessage,
                    msalContext
                  ),
                validateData: (x) => {
                  console.log(x);
                  return true;
                },
              },
            })}
            bulkEdit={true}
          />
        </Box>
      ))}
    </ErrorBoundary>
  );
};

export default STRVandCOLR;
