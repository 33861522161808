import React from "react";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import { styles } from "../common";
import { Button } from "@material-ui/core";
import ThreeDotButton from "../../../Common/ThreeDotButton";

const Contractual = "Contractual";
const UnderNegotiation = "UnderNegotiation";
const Assumption = "Assumption";

const NewLease = "NewLease";
const Amendment = "Amendment";

export default function mapToViewModel_LeaseList(
  props,
  scenario,
  lessees,
  additionalProps
) {
  const leases = scenario.leases;
  const leaseStatus = [
    { label: Contractual, value: Contractual },
    { label: UnderNegotiation, value: UnderNegotiation },
    { label: Assumption, value: Assumption },
  ];
  const leaseType = [
    { label: NewLease, value: NewLease },
    { label: Amendment, value: Amendment },
  ];
  const lesseeItems = lessees.map((x) => {
    return {
      label: x.name,
      value: x.name,
    };
  });

  return {
    label: "Overview",
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    tableName: constants.leaseList,
    props,
    options: {
      pagination: false,
      refresh: false,
      threeDotButton: true,
    },
    optionsItems: {
      threeDotButton: {
        items: [
          {
            id: 0,
            label: "Add Lease",
            handleClick: () => additionalProps.setAddNewLease(true),
          },
        ],
        content: (options) => <ThreeDotButton options={options} />,
      },
    },
    errorsOnSubmit: {},
    showOptions: true,
    columns: [
      {
        label: ["Lessee"],
        field: "lessee",
        type: constants.STRING,
        // initialEditValue: lesseeItems[0].value,
        inputFieldType: constants.AUTO_COMPLETE,
        editable: constants.ALWAYS,
        required: false,
        items: lesseeItems,
        cellStyle: styles.cellStyle,
        content: (value) => value || constants.___,
      },
      {
        label: ["Sub-lessee"],
        field: "subLessee",
        type: constants.STRING,
        inputFieldType: constants.AUTO_COMPLETE,
        editable: constants.ALWAYS,
        required: false,
        items: lesseeItems,
        cellStyle: styles.cellStyle,
        content: (value) => value || constants.___,
      },
      {
        label: ["Start Date"],
        field: "startDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) => moment.utc(value).format(constants.DATE_FORMAT),
      },
      {
        label: ["End Date"],
        field: "endDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) => moment.utc(value).format(constants.DATE_FORMAT),
      },
      {
        label: ["Lease Status"],
        field: "leaseStatus",
        type: constants.STRING,
        initialEditValue: leaseStatus[0].value,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: true,
        items: leaseStatus,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Lease Type"],
        field: "leaseType",
        type: constants.STRING,
        initialEditValue: leaseType[0].value,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: true,
        items: leaseType,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      // {
      //   label: "Deposit Cash",
      //   field: "securityDeposit_Cash",
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.NUMBER,
      //   editable: constants.ALWAYS,
      //   required: false,
      //   cellStyle: styles.cellStyle,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      //   content: (value) =>
      //     utils.renderCellValue(
      //       value.securityDeposit_Cash,
      //       null,
      //       constants.NUMERIC_CELL,
      //       "$"
      //     ),
      // },
      // {
      //   label: "Deposit LC",
      //   field: "securityDeposit_LC",
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.NUMBER,
      //   editable: constants.ALWAYS,
      //   required: false,
      //   cellStyle: styles.cellStyle,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      //   content: (value) =>
      //     utils.renderCellValue(
      //       value.securityDeposit_LC,
      //       null,
      //       constants.NUMERIC_CELL,
      //       "$"
      //     ),
      // },
      {
        label: ["RRR Cost"],
        field: "rrrCost",
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      },
      {
        label: "To Lease",
        field: "buttonText",
        editable: false,
        cellStyle: styles.cellStyle,
        content: (value) =>
          value &&
          utils.renderEventName(
            true,
            "Details",
            () => props.funcs.onClick(value),
            0,
            "Right"
          ),
      },
      {
        label: "Delete",
        field: "delete",
        editable: false,
        cellStyle: styles.cellStyle,
        content: (value) =>
          utils.renderEventName(
            true,
            "Delete",
            () => {
              additionalProps.setDeleteData(value);
              additionalProps.setIsAlertDialogOpen(true);
            },
            0,
            "Right",
            { color: "red" }
          ),
      },
    ],
    data: leases.map((lease) => ({
      leaseId: lease.leaseId,
      lessee: lease.lessee,
      subLessee: lease.subLessee,
      leaseStatus: lease.leaseStatus,
      leaseType: lease.leaseType,
      securityDeposit_Cash: lease.securityDeposit_Cash,
      securityDeposit_LC: lease.securityDeposit_LC,
      rrrCost: lease.rrR_Cost,
      startDate: lease.leaseStartDate,
      endDate: lease.leaseEndDate,
      buttonText: lease.leaseId,
      delete: lease,
    })),
  };
}
