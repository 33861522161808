import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

export default function mapToViewModel_LLPReplacements(llps, options) {
  let totalReplaced = 0;
  let data = llps.map((llp) => {
    if (llp.isReplaced) totalReplaced += 1;
    return {
      startDate: llp.startDate,
      partName: llp.partName,
      partNumber: llp.partNumber,
      module: utils.renderValueWithIcon(llp.module, !llp.isReplaced),
      cyclesBefore: llp.cyclesBefore && _.round(llp.cyclesBefore),
      cyclesAfter: llp.cyclesAfter && _.round(llp.cyclesAfter),
      pricePerCycle: llp.pricePerCycle,
      isReplaced: llp.isReplaced,
      hidden: [],
      style: {
        height: 48,
        zIndex: 0,
        bgColor: constants.HIDDEN_ROW_BG_COLOR,
      },
    };
  });

  data = _.orderBy(
    data,
    ["isReplaced", "module", "partName"],
    ["desc", "asc", "asc"]
  );

  return {
    label: null,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { pagination: false },
    optionsItems: {
      pagination: {
        page: 0,
        rowsPerPage: 10,
        handleChangePage: options.pagination.handleChangePage,
        handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
      },
    },
    clickableCellIconValue: totalReplaced,
    span: [12, 12, 12],
    columns: [
      {
        label: "Module",
        caption: null,
        field: "module",
        align: constants.LEFT,
        zIndex: 0,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Part Name",
        caption: null,
        field: "partName",
        align: constants.LEFT,
        zIndex: 0,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Part Number",
        caption: null,
        field: "partNumber",
        align: constants.LEFT,
        zIndex: 0,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      // {
      //   label: "Start Date",
      //   caption: null,
      //   field: "startDate",
      //   align: constants.RIGHT,
      //   indent: 16,
      //   content: (value, indent) =>
      //     utils.renderCellValue(
      //       value,
      //       { paddingRight: indent },
      //       constants.DATE_CELL
      //     ),
      // },
      //
      // maintenance event
      {
        label: "Cycles Before",
        caption: null,
        field: "cyclesBefore",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      // Component status && redelivery event
      {
        label: "Cycles After",
        caption: null,
        field: "cyclesAfter",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      // {
      //   label: "Cycles Left",
      //   caption: null,
      //   field: "cyclesAfter",
      //   align: constants.RIGHT,
      //   zIndex: 0,
      //   indent: 16,
      //   content: (value, indent) =>
      //     utils.renderCellValue(
      //       value,
      //       { paddingRight: indent },
      //       constants.NUMERIC_CELL
      //     ),
      // },
      {
        label: "Price Per Cycle",
        caption: "U.S. $",
        field: "pricePerCycle",
        align: constants.RIGHT,
        zIndex: 0,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL,
            null,
            2
          ),
      },
      // {
      //   label: "Is Replaced",
      //   caption: null,
      //   field: "isReplaced",
      //   indent: 16,
      //   content: (value) => (value ? "Yes" : "No"),
      // },
    ],
    data,
  };
}
