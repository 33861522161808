import _ from "lodash";
import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";

const Preview = ({ airframe, airframeChecks, apu, engines }) => {
  const componentInfo = (title, content, caption) => {
    return (
      <Box key={title} mt={2}>
        <Divider />
        <Typography variant="overline" style={{ color: "#959698" }}>
          {title.toUpperCase()}
        </Typography>
        <Typography
          variant="subtitle1"
          color="primary"
          style={{ fontWeight: 500, fontSize: 14, lineHeight: 1 }}
        >
          {content
            .map((e) => {
              if (e.includes("|")) return e.split("|")[0];
              else return e;
            })
            .join(", ")}
        </Typography>
        {caption && (
          <Typography
            variant="subtitle1"
            style={{ color: "rgba(0,0,0,0.6)", fontSize: 12 }}
          >
            {caption}
          </Typography>
        )}
      </Box>
    );
  };
  
  return (
    <Box
      flex={1}
      style={{
        position: "absolute",
        right:
          window.innerWidth <= 1500
            ? window.innerWidth <= 980
              ? -250
              : 200
            : 400,
        top: 180,
        maxWidth: 250,
      }}
    >
      <Typography variant="subtitle1" color="primary">
        Preview
      </Typography>
      {!_.isEmpty(airframe)
        ? componentInfo(
            "Asset Model",
            [airframe.airframeModel.aircraftSubSeries],
            airframe.serialNumber
          )
        : null}

      {!_.isEmpty(airframeChecks)
        ? componentInfo("Airframe Checks", airframeChecks)
        : null}

      {!_.isEmpty(apu)
        ? componentInfo("APU", [apu.apuModel.model], apu.serialNumber)
        : null}

      {!_.isEmpty(...engines)
        ? engines.map((engine, idx) => !_.isEmpty(engine) ?
            componentInfo(
              `Engine Position ${idx + 1}`,
              [engine.engineModel.engineSubSeries],
              engine.serialNumber
            ) : null
          )
        : null}
    </Box>
  );
};

export default Preview;
