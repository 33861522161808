import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const updateAsset = async (assetId, data, msalContext) => {
  let compType = "Engine";
  let apiendpoint = apiRoutes.PostAircraftUpdateInfo;
  let user = msalContext.accounts[0].name;
  apiendpoint = apiendpoint({user, compType, assetId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: data,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    data = resp.data;
  }
};

export const updateData = (assetDetails, sectionIdx, newData, msalContext) => {
  let qec = newData.qeC_Note;
  if(Array.isArray(qec))
    qec = qec.map((x) => {
      return x.value;
    });
  else
    qec = [qec];

  assetDetails.engines[sectionIdx].engineSubSeries = newData.engineSubSeries;
  assetDetails.engines[sectionIdx].serialNumber = newData.serialNumber;
  assetDetails.engines[sectionIdx].operator = newData.EPROperator;
  assetDetails.engines[sectionIdx].manufactureDate = newData.engineDOM;
  assetDetails.engines[sectionIdx].engineSpecificationAdjusterDescriptions = qec;
  //.map((x) => {});

  let postData = {
    subSeries: newData.engineSubSeries,
    serialNumber: newData.serialNumber,
    operator: newData.EPROperator,
    dOM: newData.engineDOM,
    qec_note: qec,
  };

  updateAsset(assetDetails.engines[sectionIdx].id, postData, msalContext);
  return assetDetails;
};
