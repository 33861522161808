/**
 * This file configures msal's PublicClientApplication and therefore the <MsalProvider /> instance for the application
 */

import { LogLevel } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "6e791b82-5056-4af3-9314-310d7d7ae66c",
    authority:
      "https://login.microsoftonline.com/8d44bafd-40e2-4e6b-a1ca-b86996ce9f7c",
    redirectUri: "/",
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: "/login",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add scopes to login request
export const loginRequest = {
  scopes: ["api://47f4cc65-b37b-4eb4-b9d4-4ff1686d4444/Dealflow.Read"],
};
