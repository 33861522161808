import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import MultiFunctionTableDataRow from "./MultiFunctionTableDataRow";
import { TableBody } from "@material-ui/core";

const useStyles = makeStyles({
  tableBody: {
    border: "none",
    backgroundColor: "#FFFFFF",
  },
});

const MultiFunctionTableBody = ({ columnHeaders, rows }) => {
  const classes = useStyles();

  return (
    <TableBody className={classes.tableBody}>
      {rows.map((row, idx) =>
        row ? (
          <MultiFunctionTableDataRow
            key={idx}
            columnHeaders={columnHeaders}
            row={row}
          />
        ) : null
      )}
    </TableBody>
  );
};

export default MultiFunctionTableBody;
