import { mapToViewModel_maintenanceEvents } from "./maintenanceEventsViewModel";
import { mapToViewModel_redeliveryEvents } from "./redeliveryEventsViewModel";
import { mapToViewModel_cashFlows } from "./cashFlowsViewModel";
import { mapToViewModel_leaseInfo } from "./leaseInfoViewModel";
import { mapToViewModel_conversionEvents } from "./conversionEventsViewModel";
//import { mapToViewModel_partoutEvents } from "./partoutEventsViewModel";
import moment from "moment";

export default function mapToViewModel_LeaseReports(data) {
  let simdate = moment.utc(Object.keys(data.cashFlows)[0]).startOf('month').format("MMM-DD-YYYY");
  if(data.scenario.simParameters?.simDate !== null && data.scenario.simParameters?.simDate !== undefined){
    simdate = moment.utc(data.scenario.simParameters.simDate).format("MMM-DD-YYYY");
  }
  
   return {
    leaseInfo: mapToViewModel_leaseInfo(data.leases, simdate),
    maintenanceEvents: mapToViewModel_maintenanceEvents(data.mxEvents),
    redeliveryEvents: mapToViewModel_redeliveryEvents(data.eolEvents),
    cashFlows: mapToViewModel_cashFlows(data.cashFlows),
    conversionEvents: mapToViewModel_conversionEvents(data.conversionEvents ? data.conversionEvents[0] : null),
    //partoutEvents: mapToViewModel_partoutEvents(data.partoutEvents ? data.partoutEvents : null), 
  };
}
