import React from "react";
import _ from "lodash";
import * as AssetDetailViewModel from "../../../../../mapToViewModels";
import { Box, Grid, Button } from "@material-ui/core";
import { AddBox, DeleteOutline } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import SectionHeader from "../../../../../../Common/Section/SectionHeader";
import Utilization from "./Utilization";
// import ThreeDotButton from "../../../../../../Common/ThreeDotButton";
import * as constants from "../../../../../../../constants";

const formatData = (data, componentType, generateEditableProps, scenario) => {
  return data.map((item, idx) => {
    let props = generateEditableProps(idx, componentType);
    return {
      data: AssetDetailViewModel.mapToViewModel(
        item,
        componentType,
        props,
        scenario
      ),
      componentType,
    };
  });
};

const Utilizations = ({
  scenario,
  generateEditableProps,
  handleValueChange,
}) => {
  const expectedUtilizations = formatData(
    scenario.expectedUtilizations,
    constants.EXPECTED_UTILIZATIONS,
    generateEditableProps,
    scenario
  );

  const utilizationProps = {
    // Add a utilization Section
    ADD: {
      id: 1,
      label: `${constants.ADD} Utilization`,
      icon: AddBox,
      handleClick: () =>
        handleValueChange(constants.ADD_ROW)(
          constants.SCENARIO,
          null,
          constants.EXPECTED_UTILIZATIONS,
          null
        ),
    },
    // For adding a row of utilization data
    ADD_ROW: {
      id: 2,
      label: `${constants.ADD} Row`,
      icon: AddBox,
      handleClick: (idx) =>
        handleValueChange(constants.ADD_ROW)(
          constants.EXPECTED_UTILIZATIONS,
          idx,
          constants.utilizationByDate,
          null
        ),
    },
    // For deleting the entire section
    DELETE: {
      id: 3,
      label: constants.DELETE,
      icon: DeleteOutline,
      handleClick: (idx) =>
        handleValueChange(constants.DELETE_ROW)(
          constants.SCENARIO,
          idx,
          constants.EXPECTED_UTILIZATIONS,
          null
        ),
    },
  };

  const addSection = utilizationProps.ADD;

  return (
    <Box>
      <SectionHeader
        label={"Utilization"}
        fontSize={20}
        button={
          <Button
            variant="outlined"
            color="primary"
            onClick={addSection.handleClick}
          >
            {<AddIcon fontSize="default" />}
            <span style={{ marginLeft: 8, marginRight: 8 }}>
              {addSection.label}
            </span>
          </Button>
        }
      />
      {expectedUtilizations.map((o, idx) => (
        <Grid key={idx} container spacing={2}>
          <Utilization
            idx={idx}
            utilization={o.data.utilization}
            components={o.data.components}
            utilizationProps={utilizationProps}
          />
        </Grid>
      ))}
    </Box>
  );
};

export default Utilizations;
