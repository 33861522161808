import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as d3 from "d3";
import { Box, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";
import TableNamePanel from "../../Common/TableNamePanel";

const styles = {
  rectangle13: {
    margin: "16px 0 16px 0",
    boxShadow:
      "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "16px 16px",
    overflowX: "auto",
    borderRadius: 12,
    backgroundColor: "#ffffff",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  paymentGap: {
    border: "2px solid #023059",
    borderRadius: 5,
    padding: "4px 16px 4px 24px",
    margin: "4px 4px 0 40px",
  },
  gapValue: {
    color: "#023059",
    fontSize: 24,
    lineHeight: "30px",
  },
  captions: {
    color: "#023059",
    fontSize: 10,
    opacity: 0.6,
  },
  expectedBox: {
    width: 20,
    height: 38,
    backgroundColor: "rgba(2,48,89,0.25)",
    border: "1px dashed #023059",
    borderRadius: "5px",
    margin: "0 auto",
  },
  actualBox: {
    width: 20,
    height: 38,
    backgroundColor: "#023059",
    // border: "1px dashed #023059",
    borderRadius: "5px",
    margin: "0 auto",
  },
  legendBox: {
    width: 40,
    height: 38,
    border: "1px solid #023059",
    borderRadius: "5px",
    margin: "0 auto",
  },
  legendCaptionMargin: {
    margin: "4px 0 0 0",
    textAlign: "center",
  },
  legendMargin: {
    margin: "6px 12px 0 0",
  },
  payGapTriangle: {
    margin: "4px 0 0 16px",
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
  },
};

class PaymentPerformancekSection extends Component {
  constructor(props) {
    super(props);
    this.interestChart = React.createRef();
    this.principalChart = React.createRef();
    this.state = {
      chartType: "current",
    };
  }

  componentDidMount = () => {
    this.paymentPerformanceBarChart(
      "#principalChart",
      this.principalChart.current.offsetWidth,
      "prin"
    );

    this.paymentPerformanceBarChart(
      "#interestChart",
      this.interestChart.current.offsetWidth,
      "int"
    );
  };

  paymentPerformanceBarChart = (chartId, chartRef, dataSelect) => {
    const { performanceData, liabilityData } = this.props;

    const svg = d3
      .select(chartId)
      .append("svg")
      .attr("height", 600)
      .attr("width", chartRef);

    const strokeWidth = 1.5;
    const margin = { top: 50, bottom: 50, left: 50, right: 25 };
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const width =
      +svg.attr("width") - margin.left - margin.right - strokeWidth * 2;
    const height = +svg.attr("height") - margin.top - margin.bottom;

    const lastUpdateDateIndex = _.findIndex(performanceData, (o) =>
      moment
        .utc(o.endDate)
        .isSame(
          moment.utc(
            liabilityData.liabilityModelData.modelCashflow[0].paymentDate
          ),
          "month"
        )
    );

    const beginIndex = lastUpdateDateIndex - 12;
    const data =
      lastUpdateDateIndex === -1
        ? performanceData.slice(-12)
        : performanceData.slice(
            beginIndex < 0 ? 0 : beginIndex,
            lastUpdateDateIndex + 1
          );

    // get y-axis max value
    const expectedValues = data.map((d) => d[`${dataSelect}PmtExpected`]);
    const actualValues = data.map((d) => d[`${dataSelect}PmtActual`]);
    const yMax = Math.max(..._.concat(expectedValues, actualValues));

    const numOfTicks = 10;

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map((d) => d["endDate"]))
      .padding(0.3);

    const yScale = d3.scaleLinear().range([height, 0]).domain([0, yMax]).nice();

    // vertical grid lines
    // const makeXLines = () => d3.axisBottom().scale(xScale);

    // x-axis labels
    var xAxis = chart
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat(
            (d) => `${moment.utc(d).format(constants.MONTH_COMP_FORMAT)}`
          )
      );

    xAxis
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");

    // y-axis labels and grid lines
    chart.append("g").call(
      d3
        .axisLeft(yScale)
        .tickFormat((d) => `${utils.thousandSeparator(d / 1000)}K`)
        .ticks(numOfTicks)
    );

    // horizontal grid lines
    const makeYLines = () => d3.axisLeft().scale(yScale);
    chart
      .append("g")
      .attr("class", "grid")
      .style("opacity", 0.2)
      .call(
        makeYLines().tickSize(-width, 0, 0).ticks(numOfTicks).tickFormat("")
      );

    const barGroups = chart.selectAll().data(data).enter().append("g");

    barGroups
      .append("rect")
      .attr("class", "bar")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("x", (d) => xScale(d["endDate"]) + xScale.bandwidth() / 2 - 15)
      .attr("y", (d) => yScale(d[`${dataSelect}PmtActual`]))
      .attr("height", (d) => height - yScale(d[`${dataSelect}PmtActual`]))
      .attr("width", 30)
      .attr("fill", "#023059");

    barGroups
      .append("rect")
      .attr("class", "bar")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("x", (d) => xScale(d["endDate"]) + xScale.bandwidth() / 2 - 30)
      .attr("y", (d) => yScale(d[`${dataSelect}PmtExpected`]))
      .attr("height", (d) => height - yScale(d[`${dataSelect}PmtExpected`]))
      .attr("width", 60)
      .attr("fill", "rgba(2,48,89,0.25)")
      .attr("stroke", "#023059")
      .attr("stroke-dasharray", 3);

    // payment gap indicator above bar
    const payGapYAxis = (d) =>
      Math.min(
        yScale(d[`${dataSelect}PmtActual`]),
        yScale(d[`${dataSelect}PmtExpected`])
      );

    barGroups
      .append("text")
      .attr("class", "value")
      .attr("x", (d) => xScale(d["endDate"]) + xScale.bandwidth() / 2)
      .attr("y", (d) => payGapYAxis(d) - 10)
      .text((d) => {
        let value = this.payGapValue(d, dataSelect);
        return value < 0
          ? `-$${utils.thousandSeparator(value * -1)}`
          : `+$${utils.thousandSeparator(value)}`;
      })
      .attr("fill", (d) =>
        this.payGapValue(d, dataSelect) < 0 ? "#E02020" : "#63AB17"
      )
      .attr("text-anchor", "middle");
    barGroups
      .append("rect")
      .attr("class", "chips")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("x", (d) => xScale(d["endDate"]) + xScale.bandwidth() / 2 - 30)
      .attr("y", (d) => payGapYAxis(d) - 28)
      .attr("height", 24)
      .attr("width", 60)
      .attr("border-radius", "px")
      .attr("stroke", (d) =>
        this.payGapValue(d, dataSelect) < 0 ? "#E02020" : "#63AB17"
      )
      .attr("fill", (d) =>
        this.payGapValue(d, dataSelect) < 0
          ? "rgba(224,32,32,0.3)"
          : "rgba(99,171,23,0.3)"
      );
  };

  paymentPerformanceLineChart = (chartId, chartRef, dataSelect) => {
    const { performanceData, liabilityData } = this.props;

    const lastUpdateDateIndex = _.findIndex(performanceData, (o) =>
      moment
        .utc(o.endDate)
        .isSame(
          moment.utc(
            liabilityData.liabilityModelData.modelCashflow[0].paymentDate
          ),
          "month"
        )
    );

    const svg = d3
      .select(chartId)
      .append("svg")
      .attr("height", 600)
      .attr("width", chartRef);

    const strokeWidth = 1.5;
    const margin = { top: 50, bottom: 60, left: 50, right: 25 };
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const width =
      +svg.attr("width") - margin.left - margin.right - strokeWidth * 2;
    const height = +svg.attr("height") - margin.top - margin.bottom;
    const grp = chart
      .append("g")
      .attr("transform", `translate(-${margin.left - strokeWidth},-0)`);

    const data =
      lastUpdateDateIndex === -1
        ? performanceData
        : performanceData.slice(0, lastUpdateDateIndex + 1);

    const lineData = [
      {
        id: "actual",
        color: "#1655A1",
        values: data.map((d) => {
          return {
            Date: new Date(d["endDate"]),
            value: d[`${dataSelect}PmtActual`],
          };
        }),
      },
      {
        id: "expected",
        color: "#C377F0",
        values: data.map((d) => {
          return {
            Date: new Date(d["endDate"]),
            value: d[`${dataSelect}PmtExpected`],
          };
        }),
      },
    ];

    // get y-axis max value
    const expectedValues = data.map((d) => d[`${dataSelect}PmtExpected`]);
    const actualValues = data.map((d) => d[`${dataSelect}PmtActual`]);
    const yMax = Math.max(..._.concat(expectedValues, actualValues));

    const numOfTicks = 10;

    const xScale = d3
      .scaleTime()
      .range([0, width])
      .domain([
        new Date(data[0]["endDate"]),
        new Date(data[data.length - 1]["endDate"]),
      ]);

    const yScale = d3.scaleLinear().range([height, 0]).domain([0, yMax]).nice();

    // vertical grid lines
    // const makeXLines = () => d3.axisBottom().scale(xScale);

    // x-axis labels
    var xAxis = chart
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat(
            (d) => `${moment.utc(d).format(constants.MONTH_COMP_FORMAT)}`
          )
          .ticks(30)
      );

    xAxis
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");

    // y-axis labels and grid lines
    chart.append("g").call(
      d3
        .axisLeft(yScale)
        .tickFormat((d) => `${utils.thousandSeparator(d / 1000)}K`)
        .ticks(numOfTicks)
    );

    // horizontal grid lines
    const makeYLines = () => d3.axisLeft().scale(yScale);
    chart
      .append("g")
      .attr("class", "grid")
      .style("opacity", 0.2)
      .call(
        makeYLines().tickSize(-width, 0, 0).ticks(numOfTicks).tickFormat("")
      );

    //draw line graph
    const line = d3
      .line()
      // .curve(d3.curveBasis)
      .x((dataPoint) => xScale(dataPoint.Date))
      .y((dataPoint) => yScale(dataPoint.value));

    const lineGroups = grp
      .selectAll(`${dataSelect}line`)
      .data(lineData)
      .enter()
      .append("g");

    lineGroups
      .append("path")
      .attr("transform", `translate(${margin.left},0)`)
      .attr("class", `${dataSelect}line`)
      .attr("d", function (d) {
        return line(d.values);
      })
      .attr("stroke", function (d) {
        return d.color;
      })
      .style("stroke-width", "2")
      .style("fill", "none");

    this.drawChartTooltips(
      svg,
      height,
      grp,
      margin,
      width,
      lineData,
      xScale,
      dataSelect,
      data
    );
  };

  drawChartTooltips = (
    svg,
    height,
    grp,
    margin,
    width,
    lineData,
    xScale,
    dataSelect,
    data
  ) => {
    const findTooltipData = (xDate) => {
      return _.find(data, function (o) {
        var a = moment.utc(o.endDate);
        var b = moment.utc(xDate);
        return a.diff(b, "month") === 0;
      });
    };

    // Add tooltips to chart
    var mouseG = grp
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .attr("class", "mouse-over-effects");

    mouseG
      .append("path") // this is the vertical line to follow mouse
      .attr("class", `${dataSelect}mouse-line`)
      .style("stroke", "#023059")
      .style("stroke-width", "2")
      .style("stroke-dasharray", "2, 2");

    svg
      .append("g")
      .append("rect")
      .attr("class", `${dataSelect}mouse-line-tooltips`)
      .attr("x", 72)
      .attr("y", 40)
      .attr("width", 300)
      .attr("height", 75)
      .style("fill", "#FFF")
      .style("stroke", "rgba(2, 48, 89, 0.2)")
      .style("stroke-width", "1")
      .style("border-radius", 16)
      .style("opacity", "0");

    let tooltipText = svg
      .append("g")
      .attr("class", `${dataSelect}tooltips`)
      .style("opacity", "0");

    tooltipText
      .append("text")
      .attr("x", 80 + 115)
      .attr("y", 60)
      .attr("id", `${dataSelect}tooltips-Date`)
      .style("font-weight", "bold")
      .style("fill", "#023059");

    tooltipText
      .append("text")
      .attr("x", 80 + 8)
      .attr("y", 90)
      .attr("id", `${dataSelect}tooltips-expected`)
      .attr("class", `${dataSelect}tooltips-text`);
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", 80 + 4)
      .attr("cy", 100)
      .attr("fill", lineData[1].color);
    tooltipText
      .append("text")
      .attr("x", 80 + 15)
      .attr("y", 103)
      .text("Expected")
      .attr("class", `${dataSelect}tooltips-caption`);

    tooltipText
      .append("text")
      .attr("x", 80 + 108)
      .attr("y", 90)
      .attr("id", `${dataSelect}tooltips-actual`)
      .attr("class", `${dataSelect}tooltips-text`);
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", 80 + 104)
      .attr("cy", 100)
      .attr("fill", lineData[0].color);
    tooltipText
      .append("text")
      .attr("x", 80 + 115)
      .attr("y", 103)
      .text("Actual")
      .attr("class", `${dataSelect}tooltips-caption`);

    tooltipText
      .append("text")
      .attr("x", 80 + 208)
      .attr("y", 90)
      .attr("id", `${dataSelect}tooltips-diff`)
      .attr("class", `${dataSelect}tooltips-text`);
    tooltipText
      .append("circle")
      .attr("id", `${dataSelect}tooltips-diff-triangle`)
      .attr("r", 5)
      .attr("cx", 80 + 204)
      .attr("cy", 100);
    tooltipText
      .append("text")
      .attr("x", 80 + 215)
      .attr("y", 103)
      .text("Difference")
      .attr("class", `${dataSelect}tooltips-caption`);

    d3.selectAll(`.${dataSelect}tooltips-text`)
      .style("font-weight", "bold")
      .style("fill", "#023059")
      .style("font-size", 18);

    d3.selectAll(`.${dataSelect}tooltips-caption`)
      .style("fill", "rgba(2, 48, 89, 0.6)")
      .style("font-size", 10);

    var points = document.getElementsByClassName(`${dataSelect}line`);

    const allLinesData = lineData;

    var mousePerLine = mouseG
      .selectAll(`.${dataSelect}mouse-per-line`)
      .data(allLinesData)
      .enter()
      .append("g")
      .attr("class", `${dataSelect}mouse-per-line`);

    mousePerLine
      .append("circle")
      .attr("r", 7)
      .style("stroke", function (d) {
        return d.color;
      })
      .style("fill", "none")
      .style("stroke-width", "1px")
      .style("opacity", "0");

    mouseG
      .append("svg:rect") // append a rect to catch mouse movements on canvas
      .attr("width", width) // can't catch mouse events on a g element
      .attr("height", height)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseout", function () {
        // on mouse out hide line, circles and text
        d3.selectAll(`.${dataSelect}tooltips`).style("opacity", "0");
        d3.select(`.${dataSelect}mouse-line`).style("opacity", "0");
        d3.select(`.${dataSelect}mouse-line-tooltips`).style("opacity", "0");
        d3.selectAll(`.${dataSelect}mouse-per-line circle`).style(
          "opacity",
          "0"
        );
      })
      .on("mouseover", function () {
        // on mouse in show line, circles and text
        d3.select(`.${dataSelect}mouse-line`).style("opacity", "0.5");
        d3.select(`.${dataSelect}mouse-line-tooltips`).style("opacity", "0.8");
        d3.selectAll(`.${dataSelect}mouse-per-line circle`).style(
          "opacity",
          "1"
        );
        d3.selectAll(`.${dataSelect}tooltips`).style("opacity", "1");
      })
      .on("mousemove", function (event) {
        // mouse moving over canvas
        var mouse = d3.pointer(event);
        let pos = null;
        d3.select(`.${dataSelect}mouse-line`).attr("d", function () {
          var d = "M" + mouse[0] + "," + height;
          d += " " + mouse[0] + "," + 0;
          return d;
        });
        d3.selectAll(`.${dataSelect}mouse-per-line`).attr(
          "transform",
          function (d, i) {
            var xDate = xScale.invert(mouse[0]);
            var bisect = d3.bisector(function (d) {
              return d.date;
            }).left;
            bisect(d.values, xDate);
            var beginning = 0,
              end = points[i].getTotalLength() + 50,
              target = null;

            while (true) {
              target = Math.floor((beginning + end) / 2);
              pos = points[i].getPointAtLength(target);
              if (
                (target === end || target === beginning) &&
                pos.x !== mouse[0]
              ) {
                break;
              }
              if (pos.x > mouse[0]) end = target;
              else if (pos.x < mouse[0]) beginning = target;
              else break; //position found
            }
            return "translate(" + mouse[0] + "," + pos.y + ")";
          }
        );

        const xDatePos = moment.utc(xScale.invert(pos.x));
        d3.select(`#${dataSelect}tooltips-Date`).text(
          xDatePos.format(constants.MONTH_COMP_FORMAT)
        );

        const findData = findTooltipData(xDatePos);
        if (findData) {
          d3.select(`#${dataSelect}tooltips-expected`).text(
            `${utils.thousandSeparator(
              (findData[`${dataSelect}PmtExpected`] / 1000).toFixed(0)
            )}K`
          );
          d3.select(`#${dataSelect}tooltips-actual`).text(
            `${utils.thousandSeparator(
              (findData[`${dataSelect}PmtActual`] / 1000).toFixed(0)
            )}K`
          );
          let diff =
            findData[`${dataSelect}PmtActual`] -
            findData[`${dataSelect}PmtExpected`];
          d3.select(`#${dataSelect}tooltips-diff`).text(
            `${utils.thousandSeparator((diff / 1000).toFixed(0))}K`
          );
          // if (diff < 0) {
          //   d3.select(`#${dataSelect}tooltips-diff-triangle`)
          //     .attr("d", d3.symbol().type("triangle-up"))
          //     .attr("fill", "green");
          // }
        }
      });
  };
  handleChartTypeToggle = (event) => {
    // d3.select(this.myVolatilityChart.current).select("svg").remove();
    let value = event.target.value;
    if (value === "historical") {
      d3.select(this.principalChart.current).select("svg").remove();
      d3.select(this.interestChart.current).select("svg").remove();

      this.paymentPerformanceLineChart(
        "#principalChart",
        this.principalChart.current.offsetWidth,
        "prin"
      );

      this.paymentPerformanceLineChart(
        "#interestChart",
        this.interestChart.current.offsetWidth,
        "int"
      );
    } else {
      d3.select(this.principalChart.current).select("svg").remove();
      d3.select(this.interestChart.current).select("svg").remove();

      this.paymentPerformanceBarChart(
        "#principalChart",
        this.principalChart.current.offsetWidth,
        "prin"
      );

      this.paymentPerformanceBarChart(
        "#interestChart",
        this.interestChart.current.offsetWidth,
        "int"
      );
    }

    this.setState({
      chartType: value,
    });
  };

  payGapValue = (data, dataSelect) => {
    return (
      (data[`${dataSelect}PmtActual`] - data[`${dataSelect}PmtExpected`]) /
      1000
    ).toFixed(0);
  };

  renderPayGap = (value) => {
    const { classes } = this.props;

    return (
      <Box className={`${classes.flexCenter} ${classes.paymentGap}`}>
        <Box>
          <Box className={classes.gapValue}>
            {value < 0
              ? `-$${utils.thousandSeparator(value * -1)}K`
              : `$${utils.thousandSeparator(value)}K`}
          </Box>
          <Box className={classes.captions}>payment gap</Box>
        </Box>
        {value < 0 ? (
          <Box
            className={classes.payGapTriangle}
            style={{ borderTop: "20px solid #E02020" }}></Box>
        ) : (
          <Box
            className={classes.payGapTriangle}
            style={{ borderBottom: "20px solid #63AB17" }}></Box>
        )}
      </Box>
    );
  };

  renderChartLegend = () => {
    const { classes } = this.props;
    const { chartType } = this.state;

    return (
      <>
        {chartType === "current" ? (
          <>
            <Box className={classes.legendMargin}>
              <Box className={classes.expectedBox}></Box>
              <Box
                className={`${classes.captions} ${classes.legendCaptionMargin}`}>
                Expected
              </Box>
            </Box>
            <Box className={classes.legendMargin}>
              <Box className={classes.actualBox}></Box>
              <Box
                className={`${classes.captions} ${classes.legendCaptionMargin}`}>
                Actual
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.legendMargin}>
              <Box className={classes.legendBox}>
                <hr
                  style={{
                    border: "2px solid #C377F0",
                    margin: "16px 0 0 0",
                  }}></hr>
              </Box>
              <Box
                className={`${classes.captions} ${classes.legendCaptionMargin}`}>
                Expected
              </Box>
            </Box>
            <Box className={classes.legendMargin}>
              <Box className={classes.legendBox}>
                <hr
                  style={{
                    border: "2px solid #1655A1",
                    margin: "16px 0 0 0",
                  }}></hr>
              </Box>
              <Box
                className={`${classes.captions} ${classes.legendCaptionMargin}`}>
                Actual
              </Box>
            </Box>
          </>
        )}
      </>
    );
  };

  render() {
    const { classes, performanceData, liabilityData } = this.props;
    const { chartType } = this.state;

    const lastUpdateDateIndex = _.findIndex(performanceData, (o) =>
      moment
        .utc(o.endDate)
        .isSame(
          moment.utc(
            liabilityData.liabilityModelData.modelCashflow[0].paymentDate
          ),
          "month"
        )
    );

    let lastUpdateDateData =
      lastUpdateDateIndex === -1
        ? performanceData[performanceData.length - 1]
        : performanceData[lastUpdateDateIndex];

    return performanceData ? (
      <Box>
        <Box className={classes.flexCenter}>
          <RadioGroup
            aria-label='chartSelect'
            name='chartSelect'
            row
            value={chartType}
            onChange={this.handleChartTypeToggle}>
            <FormControlLabel
              value='current'
              control={<Radio />}
              label='Current'
            />
            <FormControlLabel
              value='historical'
              control={<Radio />}
              label='Historical'
            />
          </RadioGroup>
        </Box>
        <Box className={classes.rectangle13}>
          <Box className={classes.flexSpace}>
            <Box>
              <Box style={{ width: 500 }}>
                <TableNamePanel
                  label={"Principal Payment Comparison"}
                  caption={null}
                />
              </Box>
              <Box className={classes.captions}>All values are $ thousands</Box>
            </Box>

            <Box className={classes.flexEnd}>
              {this.renderChartLegend()}
              {this.renderPayGap(this.payGapValue(lastUpdateDateData, "prin"))}
            </Box>
          </Box>
          <Box ref={this.principalChart} id={"principalChart"}></Box>
        </Box>
        <Box className={classes.rectangle13}>
          <Box className={classes.flexSpace}>
            <Box>
              <Box style={{ width: 500 }}>
                <TableNamePanel
                  label={"Interest Payment Comparison"}
                  caption={null}
                />
              </Box>
              <Box className={classes.captions}>All values are $ thousands</Box>
            </Box>
            <Box className={classes.flexEnd}>
              {this.renderChartLegend()}
              {this.renderPayGap(this.payGapValue(lastUpdateDateData, "int"))}
            </Box>
          </Box>
          <Box ref={this.interestChart} id={"interestChart"}></Box>
        </Box>
      </Box>
    ) : null;
  }
}

export default withStyles(styles)(PaymentPerformancekSection);
