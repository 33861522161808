import _ from "lodash";
import moment from "moment";
import { renderCell, styles } from "./";

import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export function mapToViewModel_ShopVisitHistory(data, props) {
  const columns = [
    {
      title: "SV Date",
      field: "svStartDate",
      cellStyle: styles.cellStyle,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      editable: constants.ALWAYS,
      required: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        moment.utc(rowData.svStartDate).format(constants.DATE_FORMAT),
    },
    {
      title: "Is Overhaul",
      field: "isOverhaul",
      initialEditValue: true,
      cellStyle: styles.cellStyle,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: false,
      items: [
        { label: constants.YES, value: true },
        { label: constants.NO, value: false },
      ],
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => (rowData.isOverhaul ? constants.YES : constants.NO),
    },
    {
      title: "Total Hours",
      field: "totalHours",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      hidden: props.section === constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(rowData.totalHours, null, constants.NUMERIC_CELL),
    },
    {
      title: "Total Cycles",
      field: "totalCycles",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      hidden: props.section === constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.totalCycles,
          null,
          constants.NUMERIC_CELL
        ),
    },
    {
      title: "Active Hour",
      field: "activeHours",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      hidden: props.section !== constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(
          rowData.activeHours,
          null,
          constants.NUMERIC_CELL
        ),
    },
    {
      title: "Day",
      field: "days",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      hidden: props.section === constants.apUs,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(rowData.days, null, constants.NUMERIC_CELL),
    },
    {
      title: "SV Cost",
      field: "cost",
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      required: false,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) =>
        utils.renderCellValue(rowData.cost, null, constants.NUMERIC_CELL, "$"),
    },
  ];

  return {
    label: "Shop Visit History",
    caption: "Since New at SV",
    props,
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.shopVisitHistory,
    errorsOnSubmit: {},
    columns:
      props.section !== constants.apUs
        ? columns
        : _.filter(columns, (o) => !o.hidden),
    data: data.map((e, idx) => {
      return {
        id: idx,
        svStartDate: e.startDate,
        svEndDate: e.endDate,
        isOverhaul: e.isOverhaul,
        totalHours: e.sinceNew.flightHour,
        totalCycles: e.sinceNew.flightCycle,
        activeHours: e.sinceNew.activeHour,
        days: e.sinceNew.day,
        cost: e.cost,
      };
    }),
  };
}
