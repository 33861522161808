import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";

export const mapToViewModel_assetPoolExit = (asset) => {
    let exitreasons = [
        {label:"Lease End", value:"Lease End"},
        {label:"Paid Off", value:"Paid Off"},
        {label:"Part Out", value:"Part Out"},
        {label:"Collateral Swap", value:"Collateral Swap"},
        {label:"Other", value:"Other"},
    ]
    let date = new Date();
    let yesterdate = new Date(date.setDate(date.getDate() - 1));
    let data = {
      asset:asset,
      exitReason: asset.exitReason,
      toDate: asset.exitDate,
      exitDate: yesterdate,
      comment: asset.comment,
    };
    return {
      data : data,
      columns: [
          {
            label: ["Exit Date"],
            field: "exitDate",
            captions: [],
            span: [3, 3],
            editable: true,
            required: false,
            type: constants.DATE,
            inputFieldType: constants.DATE,
            initialEditValue: asset.toDate,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
              value
                ? new Date(value)
                : constants.___,
          },
          {
            label: ["Exit Reason"],
            field: "exitReason",
            editable: true,
            span: [6, 6],
            required: true,
            items: exitreasons,
            initialEditValue: asset.exitReason,
            type: constants.SELECT,
            inputFieldType: constants.SELECT,
          },
          {
            label: ["Comment"],
            field: "comment",
            editable: true,
            span: [6, 6],
            required: true,
            initialEditValue: asset.comment,
            type: constants.TEXT_FIELD,
            inputFieldType: constants.TEXT_FIELD,
          }
      ],
    };
  };
  