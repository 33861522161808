import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../../utils/utils";
import * as constants from "../../../../../constants";

export function mapToViewModel_assetDetails(data, props) {
  const allMaintenanceStatus = _.flatten(
    data.airframeChecks.map((o) => o.maintenanceStatus)
  );

  const latestMaintenanceStatus = _.maxBy(allMaintenanceStatus, (o) =>
    moment.utc(o.statusAsOf).format("X")
  );
  return {
    label: "Asset Details",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    //style: { paddingTop: 30 },
    //caption: null,
    tableType: constants.DATA_TABLE,
    tableName: "Asset Details",
    columns: [
      {
        label: ["AIRCRAFT MASTERSERIES"],
        field: "aircraftMasterSeries",
        captions: [],
        span: [3, 3],
        editable: false,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["ENGINE MODEL"],
        field: "engineSubSeries",
        captions: [],
        span: [3, 3],
        editable: false,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["APU MODEL"],
        field: "apuSeries",
        captions: [],
        span: [3, 3],
        editable: false,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["SPECS"],
        field: "specAdjusters",
        captions: [],
        span: [3, 3],
        editable: false,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["MTOW (LBS)"],
        field: "mtow",
        captions: [],
        span: [3, 3],
        editable: false,
        required: false,
        type: constants.NUMBER,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["Date of Manufacture"],
        field: "aircraftDOM",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        initialEditValue: moment.utc().format(constants.ISOFormat),
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          moment.utc(value).format(constants.DATE_FORMAT),
      },
      {
        label: ["Total F.H."],
        field: "flightHours_sinceNew",
        captions: [],
        span: [2, 2],
        editable: false,
        required: false,
        type: constants.NUMBER,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["Total F.C."],
        field: "flightCycles_sinceNew",
        captions: [],
        span: [2, 2],
        editable: false,
        required: false,
        type: constants.NUMBER,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["As Of Date"],
        field: "asOfDate",
        captions: [],
        span: [2, 2],
        editable: false,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
      },

      // {
      //   label: ["ENGINE SERIAL NUMBER"],
      //   field: "engineSerialNumber",
      //   captions: [],
      //   span: [12, 12],
      // },
    ],
    data: {
      aircraftMasterSeries: data.aircraftMasterSeries,
      engineSubSeries: data.engines[0] && data.engines[0].engineSubSeries,
      apuSeries: _.isEmpty(data.apUs) ? null : data.apUs[0].model,
      mtow: data.fleetData != null ? data.fleetData.mtow : null,
      flightHours_sinceNew:
        latestMaintenanceStatus && latestMaintenanceStatus.sinceNew.flightHour,
      flightCycles_sinceNew:
        latestMaintenanceStatus && latestMaintenanceStatus.sinceNew.flightCycle,
      months_sinceNew:
        latestMaintenanceStatus &&
        _.round(latestMaintenanceStatus.sinceNew.day / 30.44),
      asOfDate: latestMaintenanceStatus && latestMaintenanceStatus.statusAsOf,
      specAdjusters: data.valuation && data.valuation.specAdjusters,
      aircraftDOM: data.manufactureDate ? moment.utc(data.manufactureDate).format(constants.DATE_FORMAT) : null,
      engineSerialNumber: _.join(
        data.engines.map((e) => e.serialNumber),
        ", "
      ),
    },
  };
}
