import React from "react";

import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AircraftSvg from "../../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../../Common/SvgComponents/EngineSvg";

import * as constants from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 28,
    marginRight: 0,
    display: "flex",
  },
  subseries: { fontWeight: 500, fontSize: 16 },
  operator: { fontWeight: 500, fontSize: 14 },
  caption: { fontSize: 10, color: "#023059", opacity: 0.5, marginTop: -4 },
  icon: { marginRight: 4, verticalAlign: "middle", display: "inline-flex" },
  airlineLogo: {
    width: 32,
    height: 32,
  },
  inlineBlock: { display: "inline-block" },
}));

const AssetTopInfo = ({ asset }) => {
  const classes = useStyles();
  const { assetType, subseries, operator, airlineLogo } = asset;

  const assetIcon =
    assetType.toUpperCase() === constants.AIRCRAFT.toUpperCase() ? (
      <AircraftSvg height={32} width={32} viewBox='0 0 25 25' />
    ) : (
      <EngineSvg height={32} width={32} viewBox='0 0 25 25' />
    );
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item sm={12} lg={12}>
          <span className={classes.icon}>{assetIcon}</span>
          <span className={classes.inlineBlock}>
            <Typography className={classes.subseries} color='primary'>
              {subseries}
            </Typography>
          </span>
        </Grid>
        {operator && (
          <Grid item sm={12} lg={12}>
            <span className={classes.icon} style={{ verticalAlign: "top" }}>
              <Avatar
                className={classes.airlineLogo}
                alt={operator || constants.___}
                src={airlineLogo}
              />
            </span>
            <Box className={classes.inlineBlock}>
              <Typography className={classes.operator} color='primary'>
                {operator || constants.___}
              </Typography>
              <Typography className={classes.caption}>Airline</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AssetTopInfo;
