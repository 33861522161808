import _ from "lodash";
import * as constants from "../../../../constants";

export const updateData = (
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario
) => {
  // console.log(
  //   actionType,
  //   assetDetails,
  //   sectionName,
  //   sectionIdx,
  //   newData,
  //   scenario
  // );

  const component = newData.component;
  const runToRemove = newData.runNumber;

  let allIntervalOverrides = [...scenario.intervalOverrides];
  let idx0 = _.findIndex(
    allIntervalOverrides,
    (o) => o.component === component
  );
  let componentIntervalOverrides =
    _.findIndex(allIntervalOverrides, (o) => o.component === component) === -1
      ? { component, overrides: [] }
      : { ...allIntervalOverrides[idx0] };

  let intervalOverrides = [...componentIntervalOverrides.overrides];
  intervalOverrides = intervalOverrides.filter(
    (row) => row.shopVisitNumber !== runToRemove
  );

  let allCostOverrides = [...scenario.costOverrides];
  let idx1 = _.findIndex(allCostOverrides, (o) => o.component === component);
  let componentCostOverrides =
    idx1 === -1 ? { component, overrides: [] } : { ...allCostOverrides[idx1] };

  let costOverrides = [...componentCostOverrides.overrides];
  costOverrides = costOverrides.filter(
    (row) => row.shopVisitNumber !== runToRemove
  );

  let allDowntimeOverrides = [...scenario.downtimeOverrides];
  let idx2 = _.findIndex(
    allDowntimeOverrides,
    (o) => o.component === component
  );
  let componentDowntimeOverrides =
    idx2 === -1
      ? { component, overrides: [] }
      : { ...allDowntimeOverrides[idx2] };

  let downtimeOverrides = [...componentDowntimeOverrides.overrides];
  downtimeOverrides = downtimeOverrides.filter(
    (row) => row.shopVisitNumber !== runToRemove
  );

  let exchangeOverrides = [...scenario.exchangeOverrides];

  const {
    intervalFH,
    intervalFC,
    intervalHour,
    intervalDay,
    svCost,
    costDate,
    costEsc,
    svDownDays,
    intervalFHComment,
    intervalFCComment,
    intervalHourComment,
    intervalDayComment,
    svCostComment,
    costEscComment,
    costDateComment,
    svDownDaysComment,
  } = newData;

  if (actionType !== constants.DELETE_ROW) {
    const overrideDate = new Date().toISOString().split(".")[0];
    const overrideDescription = "Simulator";

    const matureRunGenericInfo = _.find(
      assetDetails[sectionName][sectionIdx].genericMxInfo,
      (o) => !o.isFirstRun
    );

    const newInterval = {
      flightHour: _.isObjectLike(intervalFH)
        ? parseInt(intervalFH.override) || intervalFH.override === 0
          ? parseInt(intervalFH.override)
          : intervalFH.generic
        : parseInt(intervalFH) === 0
        ? parseInt(intervalFH)
        : parseInt(intervalFH) || matureRunGenericInfo.interval.flightHour,
      flightCycle: _.isObjectLike(intervalFC)
        ? parseInt(intervalFC.override) || intervalFC.override === 0
          ? parseInt(intervalFC.override)
          : intervalFC.generic
        : parseInt(intervalFC) === 0
        ? parseInt(intervalFC)
        : parseInt(intervalFC) || matureRunGenericInfo.interval.flightCycle,
      activeHour: _.isObjectLike(intervalHour)
        ? parseInt(intervalHour.override) || intervalHour.override === 0
          ? parseInt(intervalHour.override)
          : intervalHour.generic
        : parseInt(intervalHour) === 0
        ? parseInt(intervalHour)
        : parseInt(intervalHour) || matureRunGenericInfo.interval.activeHour,
      day: _.isObjectLike(intervalDay)
        ? parseInt(intervalDay.override) || intervalDay.override === 0
          ? parseInt(intervalDay.override)
          : intervalDay.generic
        : parseInt(intervalDay) === 0
        ? parseInt(intervalDay)
        : parseInt(intervalDay) || matureRunGenericInfo.interval.day,
    };

    const newCost = {
      cost: _.isObjectLike(svCost)
        ? parseInt(svCost.override) || svCost.override === 0
          ? parseInt(svCost.override)
          : svCost.generic
        : parseInt(svCost) === 0
        ? parseInt(svCost)
        : parseInt(svCost) || matureRunGenericInfo.cost.cost,
      costDate: _.isObjectLike(costDate)
        ? costDate.override || costDate.generic
        : costDate || matureRunGenericInfo.cost.costDate,
      costEscalation: _.isObjectLike(costEsc)
        ? parseFloat(costEsc.override) || costEsc.override === 0
          ? parseFloat(costEsc.override)
          : costEsc.generic
        : parseFloat(costEsc) === 0
        ? parseFloat(costEsc)
        : parseFloat(costEsc) || matureRunGenericInfo.cost.costEscalation,
    };

    const newDowntime = _.isObjectLike(svDownDays)
      ? parseInt(svDownDays.override) || svDownDays.override === 0
        ? parseInt(svDownDays.override)
        : svDownDays.generic
      : parseInt(svDownDays) === 0
      ? parseInt(svDownDays)
      : parseInt(svDownDays) || matureRunGenericInfo.downtime;

    const maxShopVisitNumber =
      intervalOverrides.length &&
      _.maxBy(intervalOverrides, (o) => o.shopVisitNumber).shopVisitNumber;

    const shopVisitNumber =
      newData.runNumber ||
      (intervalOverrides.length === 0 || maxShopVisitNumber <= 3
        ? 4
        : maxShopVisitNumber + 1);

    intervalOverrides.push({
      shopVisitNumber,
      overrideDate,
      overrideDescription,
      newInterval,
      intervalFHComment,
      intervalFCComment,
      intervalHourComment,
      intervalDayComment,
    });
    costOverrides.push({
      shopVisitNumber,
      overrideDate,
      overrideDescription,
      newCost,
      svCostComment,
      costEscComment,
      costDateComment,
    });

    downtimeOverrides.push({
      shopVisitNumber,
      overrideDate,
      overrideDescription,
      newDowntime,
      svDownDaysComment,
    });
  }

  componentIntervalOverrides.overrides = intervalOverrides;
  if (idx1 !== -1) allIntervalOverrides[idx0] = componentIntervalOverrides;
  else allIntervalOverrides.push(componentIntervalOverrides);
  scenario.intervalOverrides = allIntervalOverrides;

  componentCostOverrides.overrides = costOverrides;
  if (idx1 !== -1) allCostOverrides[idx1] = componentCostOverrides;
  else allCostOverrides.push(componentCostOverrides);
  scenario.costOverrides = allCostOverrides;

  componentDowntimeOverrides.overrides = downtimeOverrides;
  if (idx1 !== -1) allDowntimeOverrides[idx2] = componentDowntimeOverrides;
  else allDowntimeOverrides.push(componentDowntimeOverrides);
  scenario.downtimeOverrides = allDowntimeOverrides;

  scenario.exchangeOverrides = exchangeOverrides;

  return scenario;
};
