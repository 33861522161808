import { mapToViewModel_MXReserve_LessorContrib } from "./mxReserve_LessorContrib"
import { mapToViewModel_mxReserveRate } from "./MXReserveRate";
import { mapToViewModel_engineRestRateMatrix } from "./EngineRestRateMatrix";
import { mapToViewModel_mxReserveBalance } from "./MXReserveBalance";

export function MXReserve_LessorContrib(leaseSummary, props, threeDotBtnOptions) {
  return {
    verticalSection: mapToViewModel_MXReserve_LessorContrib(leaseSummary, props),
    mxReserveRate: mapToViewModel_mxReserveRate(leaseSummary, props, threeDotBtnOptions),
    engineRestorationRateMatrix: mapToViewModel_engineRestRateMatrix(leaseSummary, props, threeDotBtnOptions),
    mxReserveBalance: mapToViewModel_mxReserveBalance(leaseSummary, props, threeDotBtnOptions),
  }
}