import _ from "lodash";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import * as d3 from "d3";

const styles = {
  assetsKPI: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainTextColor: {
    color: "#023059",
    fontWeight: 500,
    fontSize: 16,
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  otherText: {
    all: "unset",
    color: "#023059",
    fontWeight: 500,
    fontSize: 16,
    overflow: "hidden",
    whiteSpace: "nowrap",
    opacity: "75%",
    marginLeft: "auto",
  },
  listItem: {
    flexDirection: "row",
  },
  compTitle: {
    color: "#023059",
    fontSize: 14,
  },
  rectangle13: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 6,
    height: 500,
    width: "100%",
    overflow: "hidden",
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    minWidth: 650,
  },
  topSpace: {
    marginTop: 20,
  },
};

class AssetPreview extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      narrowBody: [],
      wideBody: [],
      regionalJet: [],
      aircraft: [],
      engine: [],
    };
  }

  componentDidMount = () => {
    const { selectedStageData, liabilityData } = this.props;
    var [narrowBody, wideBody, regionalJet, aircraft, engine] = [
      [],
      [],
      [],
      [],
      [],
    ];

    if (selectedStageData) {
      let trunches = _.compact(
        _.flattenDeep(liabilityData.map((liab) => liab.loanDetails))
      );

      selectedStageData.forEach((asset) => {
        // get trunch data for each asset and
        let findTrunch = _.isEmpty(trunches)
          ? null
          : _.find(trunches, function (o) {
              return (
                // deal id is 0 in loanDetails...
                //liabilityData.dealId === asset.dealId &&
                // o.assetSerialNumber
                o.serialNumber === asset.serialNumber
              );
            });
        // use the mxAdjustedValue from trunch data and multiply by vfShare
        if (findTrunch) {
          //mX_Adj_HLValue
          asset["value"] = findTrunch.mxAdjValue;
          asset["vfShare"] = findTrunch.vfShare;
        } else asset["vfShare"] = 1;

        let modelClass = asset.modelClass;
        if (modelClass === "NB") narrowBody.push(asset);
        else if (modelClass === "WB") wideBody.push(asset);
        else regionalJet.push(asset);

        if (asset.assetType === "Aircraft") aircraft.push(asset);
        else engine.push(asset);
      });

      this.setState({
        narrowBody: narrowBody,
        wideBody: wideBody,
        regionalJet: regionalJet,
        aircraft: aircraft,
        engine: engine,
      });

      this.makePieChart(narrowBody, wideBody, regionalJet);
    }
  };

  makePieChart = (narrowBody, wideBody, regionalJet) => {
    var dataset = [
      { count: narrowBody.length, size: 50, color: "#FA6400" },
      { count: wideBody.length, size: 40, color: "#F7B500" },
      { count: regionalJet.length, size: 30, color: "#63AB17" },
      // { count: 0, size: 45, color: "#0091FF" }
    ];

    var width = 250;
    var height = 250;
    var donutWidth = 50;
    var innerRadius = Math.min(width, height) / 2 - donutWidth;

    // var color = d3.scale.category20b();

    var svg = d3
      .select("#pieChart")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    var arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(function (d) {
        return innerRadius + d.data.size;
      });

    var pie = d3
      .pie()
      .value(function (d) {
        return d.count;
      })
      .sort(null);

    var path = svg
      .selectAll("path")
      .data(pie(dataset))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", function (d) {
        return d.data.color;
      });
  };

  render() {
    const { classes, selectedStageData } = this.props;
    const { narrowBody, wideBody, regionalJet } = this.state;

    return (
      <Box className={classes.rectangle13}>
        <Typography variant='subtitle2' className={classes.compTitle}>
          Asset Type
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: 100,
          }}>
          <Box style={{ marginTop: 80, paddingRight: 50, width: 400 }}>
            <Grid container spacing={0}>
              <Grid item sm={6}>
                <ListItem className={classes.listItem}>
                  <FiberManualRecordIcon style={{ color: "#FA6400" }} />
                  <span className={classes.mainTextColor}>Narrow Body</span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem className={classes.listItem}>
                  <span className={classes.otherText}>
                    {(
                      (narrowBody.length / selectedStageData.length) *
                      100
                    ).toFixed(0) + "%"}
                  </span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem className={classes.listItem}>
                  <span className={classes.otherText}>
                    {"$" +
                      (
                        _.sumBy(narrowBody, (o) => o.value * o.vfShare) /
                        1000000
                      ).toFixed(0) +
                      "M"}
                  </span>
                </ListItem>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item sm={6}>
                <ListItem className={classes.listItem}>
                  <FiberManualRecordIcon style={{ color: "#F7B500" }} />
                  <span className={classes.mainTextColor}>WideBody</span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem className={classes.listItem}>
                  <span className={classes.otherText}>
                    {(
                      (wideBody.length / selectedStageData.length) *
                      100
                    ).toFixed(0) + "%"}
                  </span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem className={classes.listItem}>
                  <span className={classes.otherText}>
                    {"$" +
                      (
                        _.sumBy(wideBody, (o) => o.value * o.vfShare) / 1000000
                      ).toFixed(0) +
                      "M"}
                  </span>
                </ListItem>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item sm={6}>
                <ListItem className={classes.listItem}>
                  <FiberManualRecordIcon style={{ color: "#63AB17" }} />
                  <span className={classes.mainTextColor}>Regional</span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem className={classes.listItem}>
                  <span className={classes.otherText}>
                    {(
                      (regionalJet.length / selectedStageData.length) *
                      100
                    ).toFixed(0) + "%"}
                  </span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem className={classes.listItem}>
                  <span className={classes.otherText}>
                    {"$" +
                      (
                        _.sumBy(regionalJet, (o) => o.value * o.vfShare) /
                        1000000
                      ).toFixed(0) +
                      "M"}
                  </span>
                </ListItem>
              </Grid>
            </Grid>
            <hr style={{ borderTop: "1px solid #979797", width: "90%" }} />
            <Grid container spacing={0}>
              <Grid item sm={6}>
                <ListItem>
                  <FiberManualRecordIcon style={{ color: "#ffffff" }} />
                  <span className={classes.mainTextColor}>Total</span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem>
                  <span className={classes.otherText}>{100 + "%"}</span>
                </ListItem>
              </Grid>
              <Grid item sm={3}>
                <ListItem className={classes.listItem}>
                  <span className={classes.otherText}>
                    {"$" +
                      (
                        _.sumBy(selectedStageData, (o) => o.value * o.vfShare) /
                        1000000
                      ).toFixed(0) +
                      "M"}
                  </span>
                </ListItem>
              </Grid>
            </Grid>
          </Box>
          <Box id={"pieChart"}></Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(AssetPreview);
