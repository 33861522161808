import _ from "lodash";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Avatar, Badge, Box, Typography } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import AircraftSvg from "../../../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../../../Common/SvgComponents/EngineSvg";
import HistoryIcon from '@material-ui/icons/History';

export const withStyles = makeStyles((theme) => ({
  avatar: {
    marginLeft: -20,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.text.secondary}`,
    borderRadius: "50%",
  },

  badge: {
    fontFamily: "Roboto",
    right: 2,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    zIndex: 10,
  },
  badgeAircraft: {
    top: 25,
  },
  badgeEngine: {
    top: 28,
  },
  badgeLogo: {
    top: 20,
  },
  badgeExpired:{
    top: 15,
    left:12
  },
  // icon: { textAlign: "center" },
  assetCount: {
    // textAlign: "center",
    fontSize: 12,
    lineHeight: 3,
  },
  assetSeries: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "unset",
    // textAlign: "center",
  },
  operator: {
    fontSize: 10,
    // textAlign: "center",
  },
  container: {
    paddingLeft: 35,
    display: "flex",
    justifyContent: "flex-start",
  },
}));

const PoolAssetCount = ({ assets, pastAssets, airlineLogos }) => {
  // console.log(assets);
  const classes = withStyles();
  const totalCount = _.countBy(assets, "assetType");
  const aircraftCount = totalCount["Aircraft"] || 0;
  const expiredCount = pastAssets.length;
  const engineCount = assets.length - aircraftCount;
  
  const uniqOperators = _.compact(
    _.uniqBy(assets, "operator").map((o) => o.operator)
  );
  if (airlineLogos.length >= 3) {
    airlineLogos = airlineLogos.slice(0, 3);
  }
  return (
    <Box className={classes.container} alignItems='center'>
      <Box
        style={{
          marginRight: 20,
        }}>
        <AvatarGroup classes={{ avatar: classes.avatar }}>
          {airlineLogos.map((o, i) => {
            if (i <= 1) {
              return (
                <Avatar
                  key={i}
                  alt={o.operator}
                  src={o.path}
                  style={{ zIndex: i + 1 }}
                />
              );
            } else {
              return (
                <Badge
                  key={i}
                  badgeContent={uniqOperators.length}
                  overlap='circle'
                  color='primary'
                  classes={{ badge: `${classes.badge} ${classes.badgeLogo}` }}
                  style={{ zIndex: i + 1, marginRight: 10 }}>
                  <Avatar
                    key={i}
                    alt={o.operator}
                    src={o.path}
                    style={{ zIndex: i + 1 }}
                  />
                </Badge>
              );
            }
          })}
        </AvatarGroup>
      </Box>
      {aircraftCount === 0 ? null : (
        <Box style={{ marginRight: 30 }}>
          <Typography
            variant='subtitle1'
            color='primary'
            className={classes.icon}>
            <Badge
              classes={{ badge: `${classes.badge} ${classes.badgeAircraft}` }}
              badgeContent={aircraftCount}
              color='primary'
              showZero>
              <AircraftSvg width={44} height={44} viewBox='0 5 24 21' />
            </Badge>
          </Typography>
        </Box>
      )}
      {engineCount === 0 ? null : (
        <Box style={{ marginRight: 20 }}>
          <Typography
            variant='subtitle1'
            color='primary'
            className={classes.icon}>
            <Badge
              classes={{ badge: `${classes.badge} ${classes.badgeEngine}` }}
              badgeContent={engineCount}
              color='primary'
              showZero>
              <EngineSvg height={50} width={44} viewBox='0 -3 24 30' />
            </Badge>
          </Typography>
        </Box>
      )}
      {expiredCount === 0 ? null : (
        <Box style={{ marginRight: 30 }}>
          <Typography
            variant='subtitle1'
            color='primary'
            className={classes.icon}>
            <Badge
              classes={{ badge: `${classes.badge} ${classes.badgeExpired}` }}
              badgeContent={expiredCount}
              color='primary'
              showZero>
              <HistoryIcon width={44} height={44} viewBox='0 2 22 22' />
            </Badge>
          </Typography>
        </Box>
      )}
      {/* <LoanIconSvg /> */}
    </Box>
  );
};

export default PoolAssetCount;
