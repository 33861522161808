import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as d3 from "d3";
import {
  Grid,
  Box,
  Button,
  Tab,
  Tabs,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Divider,
  Badge,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ThreeDotButton from "../../../Common/ThreeDotButton";
import EventDetails from "./EventDetails";
import * as Constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

const styles = {
  root: {
    minWidth: 1250,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  containers: {
    height: 600,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  chartContainer: {
    marginRight: 20,
    // paddingRight: 20,
  },
  heading: {
    fontSize: 12,
    fontFamily: "Roboto",
  },
  tab: {
    minWidth: 80,
    width: 100,
    fontSize: 12,
  },
  chartLabel: {
    padding: 20,
    fontWeight: 500,
  },
  eventDate: {
    fontWeight: 600,
  },
  mainTextColor: {
    color: "#023059",
    // fontWeight: 600,
  },
  eventHeading: {
    display: "flex",
    justifyContent: "space-between",
  },
  circle: {
    width: 22,
    height: 22,
    // -webkit-border-radius: 25,
    // -moz-border-radius: 25,
    borderRadius: 25,
    background: "#0d5391",
    color: "white",
    textAlign: "center",
    marginTop: 22,
    marginLeft: -10,
  },
  mxButtons: {
    background: "#023059",
    color: "white",
    height: 40,
    borderRadius: 5,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
};

class StackedAreaChart extends Component {
  constructor(props) {
    super(props);
    this.myAreaChart = React.createRef();
    this.tabWidth = React.createRef();
    this.state = {
      value: 0,
      selectedmxEvent: null,
      mxDates: [],
      saleData: [],
      convEventData: [],
      chartCutOffDate: null,
      filteredLiabilityData: null,
    };
  }

  componentDidMount = () => {
    // move svg elements to front or back
    // can be used for handling of event circles being overdrawn in svg so force them to the front on mouse out of tooltip
    d3.selection.prototype.moveToFront = function () {
      return this.each(function () {
        this.parentNode.appendChild(this);
      });
    };

    d3.selection.prototype.moveToBack = function () {
      return this.each(function () {
        var firstChild = this.parentNode.firstChild;
        if (firstChild) {
          this.parentNode.insertBefore(this, firstChild);
        }
      });
    };

    this.drawChart();
    this.drawLegend();
  };

  getResidualValue = (report, evaluateDate, idx) => {
    const nextValuation = _.find(
      report.fleetInfo[idx].appraisal.futureValuations,
      (d) => {
        return new Date(d.date) > evaluateDate;
      }
    );
    const previousEvaluationDate = evaluateDate.setFullYear(
      evaluateDate.getFullYear() - 1
    );
    const currValuation = _.find(
      report.fleetInfo[idx].appraisal.futureValuations,
      (d) => {
        return new Date(d.date) > previousEvaluationDate;
      }
    );

    const currValuationDate = new Date(currValuation.date);
    const nextValuationtDate = new Date(nextValuation.date);
    const evaluateDateFormatted = moment.utc(evaluateDate).format("YYYY-MM-DD");

    return _.round(
      utils.linearInterpolation(
        currValuationDate,
        evaluateDateFormatted,
        nextValuationtDate,
        currValuation["halfLifeBase_inflated"],
        nextValuation["halfLifeBase_inflated"],
        "days"
      ) * Math.pow(10, 6)
    );
  };

  drawChart = () => {
    const { report, liabilityData } = this.props;

    let chartCutOffDate =
      liabilityData.liabilityModelData.modelCashflow[
        liabilityData.liabilityModelData.modelCashflow.length - 1
      ].paymentDate;
    let chartStartDate =
      liabilityData.liabilityModelData.modelCashflow[0].paymentDate;
    let data = liabilityData.liabilityModelData.modelCashflow;
    let saleData = [],
      sortEventData = [],
      convEventData = null,
      mxDates = null,
      filteredLeases = null;

    if (report) {
      filteredLeases = report.leases.filter((el) => !_.isEmpty(el));

      if (!_.isEmpty(filteredLeases)) {
        let getDates = filteredLeases.map(
          (e) => new Date(e.slice(-1)[0].endDate)
        );
        chartCutOffDate = moment
          .utc(new Date(Math.max.apply(null, getDates)))
          .add(6, "M");
      } else {
        chartCutOffDate = moment.utc(_.last(Object.keys(report.cashFlows)));
      }

      if (liabilityData.liabilityModelData.modelInfo.maturity)
        chartCutOffDate = moment
          .utc(liabilityData.liabilityModelData.modelInfo.maturity)
          .add(1, "M");

      filteredLeases.map((lease, idx) => {
        let evaluateDate = lease[0].endDate;
        let residualValue = null;
        try {
          residualValue = this.getResidualValue(
            report,
            new Date(evaluateDate),
            idx
          );
        } catch (err) {
          console.log(err);
        }
        saleData.push({
          serialNumber: lease[0].serialNumber,
          date: evaluateDate,
          saleValue: residualValue,
          lessee: lease[0].lessee,
        });
        return null;
      });

      // get conversion events data formated for chart
      convEventData = report.conversionEvents
        ? report.conversionEvents.map((conv) => ({
            serialNumber: conv.serialNumber,
            date: conv.startDate,
            endDate: conv.endDate,
            cost: conv.cost,
          }))
        : [];

      const eventData = saleData.concat(
        report.mxEvents,
        report.eolEvents,
        convEventData
      );
      const filteredEventData = eventData.filter(
        (event) =>
          new Date(event.date) >= new Date(chartStartDate) &&
          new Date(event.date) <= new Date(chartCutOffDate)
      );
      sortEventData = _.sortBy(filteredEventData, function (dateObj) {
        return new Date(dateObj.date);
      });
      mxDates = _.uniq(
        sortEventData.map((d) =>
          moment.utc(d.date).format(Constants.MONTH_COMP_FORMAT)
        )
      );
      data = liabilityData.liabilityModelData.modelCashflow.filter(
        (data) => moment.utc(data.paymentDate) < chartCutOffDate
      );
    }

    this.setState({
      saleData: saleData,
      convEventData: convEventData,
      mxDates: mxDates,
      selectedmxEvent: mxDates ? mxDates[0] : null,
      chartCutOffDate: chartCutOffDate,
      filteredLiabilityData: data,
    });

    // Create SVG and padding for the chart
    const svg = d3
      .select("#d3AreaChart")
      .append("svg")
      .attr("height", 480)
      .attr("width", this.myAreaChart.current.offsetWidth);

    this.setAreaChartGradient(svg, 0, 0.8, 0.4, 0.1);
    this.setAreaChartGradient(svg, 1, 0.6, 0.3, 0.1);
    this.setAreaChartGradient(svg, 2, 0.4, 0.2, 0.1);

    const strokeWidth = 1.5;
    const margin = { top: 10, bottom: 75, left: 50, right: 50 };
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const width =
      +svg.attr("width") - margin.left - margin.right - strokeWidth * 2;
    const height = +svg.attr("height") - margin.top - margin.bottom;
    const grp = chart
      .append("g")
      .attr("transform", `translate(-${margin.left - strokeWidth},-0)`);

    // Create stack
    const stack = d3
      .stack()
      .keys(["assetValue", "lcReserve_Gurantee", "cashReserve"]);
    const stackedValues = stack(data);
    const stackedData = [];
    // Copy the stack offsets back into the data.
    stackedValues.forEach((layer, index) => {
      const currentStack = [];
      layer.forEach((d, i) => {
        currentStack.push({
          values: d,
          Date: new Date(data[i].paymentDate),
        });
      });
      stackedData.push(currentStack);
    });

    const lineData = [
      {
        id: "HLValue",
        color: "#B5292A",
        values: data.map((d) => {
          return { Date: new Date(d.paymentDate), value: d.hlValue };
        }),
      },
      {
        id: "LoanOutstanding",
        color: "#FA6400",
        values: data.map((d) => {
          return { Date: new Date(d.paymentDate), value: d.loanOutstanding };
        }),
      },
    ];

    const LTVPctValues = [
      {
        id: "LTVPct",
        color: "#63AB17",
        values: data.map((d) => {
          return { Date: new Date(d.paymentDate), value: d.ltvPct };
        }),
      },
    ];

    const getMaxYaxisValues = () => {
      let nums1 = stackedValues[stackedValues.length - 1].map((x) => x[1]);
      let nums2 = data.map((x) => x.hlValue);
      let nums3 = data.map((x) => x.loanOutstanding);
      let yValue = d3.max(nums1.concat(nums2, nums3));
      let yTopSpacing = yValue * 0.2;
      return yValue + (yTopSpacing > 10000000 ? 10000000 : yTopSpacing);
    };
    const maxYLeftValue = d3.max(data.map((d) => d.ltvPct));

    // Create scales
    const yLeftScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, getMaxYaxisValues()])
      .nice();
    const yRightScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, maxYLeftValue + maxYLeftValue * 0.5])
      .nice();
    const xScale = d3
      .scaleTime()
      .range([0, width])
      .domain([new Date(data[0].paymentDate), new Date(chartCutOffDate)]);

    //draw area graph
    const area = d3
      .area()
      // .curve(d3.curveBasis)
      .x((dataPoint) => xScale(dataPoint.Date))
      .y0((dataPoint) => yLeftScale(dataPoint.values[0]))
      .y1((dataPoint) => yLeftScale(dataPoint.values[1]));

    const series = grp
      .selectAll(".series")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("class", "series");

    series
      .append("path")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .style("fill", (d, i) => {
        return `url(#bg-gradient${i})`;
      })
      .attr("d", (d) => area(d));

    //draw line graph
    const line = d3
      .line()
      // .curve(d3.curveBasis)
      .x((dataPoint) => xScale(dataPoint.Date))
      .y((dataPoint) => yLeftScale(dataPoint.value));

    const lines = grp.selectAll("line").data(lineData).enter().append("g");

    lines
      .append("path")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("class", "line")
      .attr("d", function (d) {
        return line(d.values);
      })
      .attr("stroke", function (d) {
        return d.color;
      })
      .style("stroke-width", "2")
      .style("fill", "none")
      .style("stroke-dasharray", "2, 2");

    // Add Area Outlines to chart
    const areaLineData = stackedData.map((data, idx) => {
      return {
        id: idx,
        color: "#023059",
        values: data.map((d) => {
          return { Date: new Date(d.Date), value: d.values[1] };
        }),
      };
    });

    const arealines = grp
      .selectAll("line")
      .data(areaLineData)
      .enter()
      .append("g");

    arealines
      .append("path")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("class", "line")
      .attr("d", function (d) {
        return line(d.values);
      })
      .attr("stroke", function (d) {
        return d.color;
      })
      .style("stroke-width", "2")
      .style("fill", "none");

    // Add LTVPct line to chart
    const LTVPctline = d3
      .line()
      // .curve(d3.curveBasis)
      .x((dataPoint) => xScale(dataPoint.Date))
      .y((dataPoint) => yRightScale(dataPoint.value));

    grp
      .append("path")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("class", "line")
      .attr("d", function (d) {
        return LTVPctline(LTVPctValues[0].values);
      })
      .attr("stroke", "#63AB17")
      .style("stroke-width", "2")
      .style("fill", "none");
    // .style("stroke-dasharray", ("3, 2"));

    // Add the X Axis
    chart
      .append("g")
      .attr("transform", `translate(0,${height + margin.top})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(data.length / 2)
          .tickFormat(d3.timeFormat("%b-%Y"))
        // .tickSizeOuter(0)
        // .tickSizeInner(0)
      )
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");
    // Add the Y Axis
    chart
      .append("g")
      .attr("transform", `translate(0, ${margin.top})`)
      .call(
        d3.axisLeft(yLeftScale).tickFormat(function (d) {
          return d / 1000000;
        })
        // .tickSizeOuter(0)
        // .tickSizeInner(0)
      );
    // Add Y Axis Label
    svg
      .append("text")
      .attr("class", "yLeftlabel")
      .attr("y", 10)
      .attr("x", 15)
      .text("Millions")
      .style("font-size", "10px");
    // Add right Y axis
    chart
      .append("g")
      .attr("transform", `translate(${width}, ${margin.top})`)
      // .attr("stroke", "#63AB17")
      .call(
        d3.axisRight(yRightScale).tickFormat(d3.format(".0%"))
        // .tickSizeOuter(0)
        // .tickSizeInner(0)
      );
    // Add Y Axis Label
    svg
      .append("text")
      .attr("class", "yRightlabel")
      .attr("transform", `translate(${width + margin.right}, 0)`)
      .attr("y", 10)
      .attr("x", 0)
      .text("LTV %")
      // .style("font-weight", "bold")
      .style("font-size", "10px");
    // .attr("fill", "#63AB17")

    this.drawChartTooltips(
      svg,
      height,
      grp,
      margin,
      width,
      data,
      lineData,
      LTVPctValues,
      areaLineData,
      xScale
    );
    if (report)
      this.drawCircleEvents(
        stackedData,
        mxDates,
        grp,
        margin,
        xScale,
        yLeftScale
      );
  };

  drawChartTooltips = (
    svg,
    height,
    grp,
    margin,
    width,
    data,
    lineData,
    LTVPctValues,
    areaLineData,
    xScale
  ) => {
    // Add tooltips to chart
    var mouseG = grp
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("class", "mouse-over-effects");

    mouseG
      .append("path") // this is the vertical line to follow mouse
      .attr("class", "mouse-line")
      .style("stroke", "#023059")
      .style("stroke-width", "2")
      .style("stroke-dasharray", "2, 2");

    svg
      .append("g")
      .append("rect")
      .attr("class", "mouse-line-tooltips")
      .attr("x", width * 0.8)
      .attr("y", 0)
      .attr("width", 220)
      .attr("height", 135)
      .style("fill", "#FFF")
      .style("stroke", "rgba(2, 48, 89, 0.2)")
      .style("stroke-width", "1")
      .style("border-radius", 16)
      .style("opacity", "0");

    let tooltipText = svg
      .append("g")
      .attr("class", "tooltips")
      .style("opacity", "0");

    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 10)
      .attr("y", 15)
      .attr("id", "tooltips-Date")
      .style("font-weight", "bold")
      .style("fill", "#023059");
    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 80)
      .attr("y", 15)
      .text("(Millions / %)")
      .style("font-size", 10)
      .style("fill", "#023059");

    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 10)
      .attr("y", 40)
      .attr("id", "tooltips-assetValue")
      .attr("class", "tooltips-text");
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", width * 0.8 + 8)
      .attr("cy", 50)
      .attr("fill", "rgba(2, 48, 89, 0.6)");
    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 15)
      .attr("y", 53)
      .text("Asset Value")
      .attr("class", "tooltips-caption");

    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 110)
      .attr("y", 40)
      .attr("id", "tooltips-LCRGurantee")
      .attr("class", "tooltips-text");
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", width * 0.8 + 108)
      .attr("cy", 50)
      .attr("fill", "rgba(2, 48, 89, 0.3)");
    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 115)
      .attr("y", 53)
      .text("LC Reserve/Gurantee")
      .attr("class", "tooltips-caption");

    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 10)
      .attr("y", 80)
      .attr("id", "tooltips-cashReserve")
      .attr("class", "tooltips-text");
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", width * 0.8 + 8)
      .attr("cy", 90)
      .attr("fill", "rgba(2, 48, 89, 0.2)");
    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 14)
      .attr("y", 93)
      .text("Cash Reserve")
      .attr("class", "tooltips-caption");

    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 110)
      .attr("y", 80)
      .attr("id", "tooltips-halfLife")
      .attr("class", "tooltips-text");
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", width * 0.8 + 108)
      .attr("cy", 90)
      .attr("fill", "#B5292A");
    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 115)
      .attr("y", 93)
      .text("Halflife Value")
      .attr("class", "tooltips-caption");

    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 10)
      .attr("y", 120)
      .attr("id", "tooltips-loanOutstanding")
      .attr("class", "tooltips-text");
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", width * 0.8 + 8)
      .attr("cy", 130)
      .attr("fill", "#FA6400");
    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 14)
      .attr("y", 133)
      .text("Loan Outstanding")
      .attr("class", "tooltips-caption");

    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 110)
      .attr("y", 120)
      .attr("id", "tooltips-LTVPct")
      .attr("class", "tooltips-text");
    tooltipText
      .append("circle")
      .attr("r", 5)
      .attr("cx", width * 0.8 + 108)
      .attr("cy", 130)
      .attr("fill", "#63AB17");
    tooltipText
      .append("text")
      .attr("x", width * 0.8 + 115)
      .attr("y", 133)
      .text("LTV Percentage")
      .attr("class", "tooltips-caption");

    d3.selectAll(".tooltips-text")
      .style("font-weight", "bold")
      .style("fill", "#023059")
      .style("font-size", 18);

    d3.selectAll(".tooltips-caption")
      .style("fill", "rgba(2, 48, 89, 0.6)")
      .style("font-size", 10);

    var formatComma = d3.format(",.1f");
    var formatPercent = d3.format(",.0%");

    var points = document.getElementsByClassName("line");

    const allLinesData = lineData.concat(LTVPctValues, areaLineData);

    var mousePerLine = mouseG
      .selectAll(".mouse-per-line")
      .data(allLinesData)
      .enter()
      .append("g")
      .attr("class", "mouse-per-line");

    mousePerLine
      .append("circle")
      .attr("r", 7)
      .style("stroke", function (d) {
        return d.color;
      })
      .style("fill", "none")
      .style("stroke-width", "1px")
      .style("opacity", "0");

    mouseG
      .append("svg:rect") // append a rect to catch mouse movements on canvas
      .attr("width", width) // can't catch mouse events on a g element
      .attr("height", height)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseout", function () {
        // on mouse out hide line, circles and text
        d3.selectAll(".tooltips").style("opacity", "0");
        d3.select(".mouse-line").style("opacity", "0");
        d3.select(".mouse-line-tooltips").style("opacity", "0");
        d3.selectAll(".mouse-per-line circle").style("opacity", "0");
      })
      .on("mouseover", function () {
        // on mouse in show line, circles and text
        d3.select(".mouse-line").style("opacity", "0.5");
        d3.select(".mouse-line-tooltips").style("opacity", "0.8");
        d3.selectAll(".mouse-per-line circle").style("opacity", "1");
        d3.selectAll(".tooltips").style("opacity", "1");
      })
      .on("mousemove", function (event) {
        // mouse moving over canvas
        var mouse = d3.pointer(event);
        let pos = null;
        d3.select(".mouse-line").attr("d", function () {
          var d = "M" + mouse[0] + "," + height;
          d += " " + mouse[0] + "," + 0;
          return d;
        });
        d3.selectAll(".mouse-per-line").attr("transform", function (d, i) {
          var xDate = xScale.invert(mouse[0]),
            bisect = d3.bisector(function (d) {
              return d.paymentDate;
            }).right;
          bisect(d.values, xDate);
          var beginning = 0,
            end = points[i].getTotalLength(),
            target = null;

          while (true) {
            target = Math.floor((beginning + end) / 2);
            pos = points[i].getPointAtLength(target);
            if (
              (target === end || target === beginning) &&
              pos.x !== mouse[0]
            ) {
              break;
            }
            if (pos.x > mouse[0]) end = target;
            else if (pos.x < mouse[0]) beginning = target;
            else break; //position found
          }

          return "translate(" + mouse[0] + "," + pos.y + ")";
        });
        const xDate = moment.utc(xScale.invert(pos.x)).endOf("month");
        const toolTipData = _.find(data, function (o) {
          var a = moment.utc(o.paymentDate);
          var b = moment.utc(xDate);
          return a.diff(b, "months") === 0;
        });
        // d3.select(".mouse-line-tooltips")
        // .attr("x", function() {
        //   if (mouse[0]/width > 0.8) return width*0.8
        //   else if (mouse[0]/width < 0.1) return width*0.1
        //   else return mouse[0]
        // })
        d3.select("#tooltips-Date").text(
          moment
            .utc(toolTipData.paymentDate)
            .format(Constants.MONTH_COMP_FORMAT)
        );
        d3.select("#tooltips-assetValue").text(
          `$${formatComma(_.round(toolTipData.assetValue / 1000000, 1))}`
        );
        d3.select("#tooltips-LCRGurantee").text(
          `$${formatComma(
            _.round(toolTipData.lcReserve_Gurantee / 1000000, 1)
          )}`
        );
        d3.select("#tooltips-cashReserve").text(
          `$${formatComma(_.round(toolTipData.cashReserve / 1000000, 1))}`
        );
        d3.select("#tooltips-halfLife").text(
          `$${formatComma(_.round(toolTipData.hlValue / 1000000, 1))}`
        );
        d3.select("#tooltips-loanOutstanding").text(
          `$${formatComma(_.round(toolTipData.loanOutstanding / 1000000, 1))}`
        );
        d3.select("#tooltips-LTVPct").text(formatPercent(toolTipData.ltvPct));
      });
  };

  drawCircleEvents = (
    stackedData,
    mxDates,
    grp,
    margin,
    xScale,
    yLeftScale
  ) => {
    // draw mxEvent circles
    const circleMXEventsData = stackedData[2].filter((data) =>
      mxDates.includes(
        moment.utc(data.Date).format(Constants.MONTH_COMP_FORMAT)
      )
    );

    // initialize selected tab value for events
    const checkEvents = this.handleCheckEventData(mxDates[0]);

    this.setState({
      value: checkEvents.mxEvents ? 0 : checkEvents.eolEvents ? 1 : 3,
    });

    const handleMXEventChange = (date) => {
      const { report } = this.props;
      const checkEvents = this.handleCheckEventData(date);

      this.setState({
        selectedmxEvent: moment.utc(date).format(Constants.MONTH_COMP_FORMAT),
        value: checkEvents.mxEvents ? 0 : checkEvents.eolEvents ? 1 : 3,
      });

      let index = mxDates.indexOf(
        moment.utc(date).format(Constants.MONTH_COMP_FORMAT)
      );
      this.handleSelectedEventHighlight(index);
    };

    grp.selectAll(".circle").remove();

    const circles = grp
      .selectAll(".circle")
      .data(circleMXEventsData)
      .enter()
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("id", (d, i) => `eventCircle${i}`);

    circles
      .append("circle")
      .attr("class", "circle")
      .attr("cx", function (d) {
        return xScale(d.Date);
      })
      .attr("cy", function (d) {
        return yLeftScale(d.values[1]);
      })
      .attr("r", 10)
      .style("fill", "#023059")
      .on("click", function (d, i) {
        handleMXEventChange(i.Date);
      });

    circles
      .append("text")
      .attr("id", (d, i) => `eventText${i}`)
      .attr("x", function (d) {
        return xScale(d.Date);
      })
      .attr("y", function (d) {
        return yLeftScale(d.values[1]) + 5;
      })
      .attr("text-anchor", "middle")
      .text(function (d, i) {
        return i + 1;
      })
      .style("fill", "white")
      .on("click", function (d, i) {
        handleMXEventChange(i.Date);
      });

    this.handleSelectedEventHighlight(0);
  };

  drawLegend = () => {
    // get liability data to check if there is a need to remove some legends
    const { liabilityData } = this.props;
    const lcReserveGuranteeList =
      liabilityData.liabilityModelData.modelCashflow.map(
        (d) => d.lcReserve_Gurantee
      );
    const mxCashReserveList =
      liabilityData.liabilityModelData.modelCashflow.map((d) => d.cashReserve);
    const halfLifeValueList =
      liabilityData.liabilityModelData.modelCashflow.map((d) => d.hlValue);
    // create legend for chart
    const svg = d3
      .select("#d3AreaChartLegend")
      .append("svg")
      .attr("height", 80)
      .attr("width", this.myAreaChart.current.offsetWidth)
      .style("padding-left", 20);

    let xCood = 0;

    // Asset Value Area
    svg
      .append("rect")
      .attr("x", xCood)
      .attr("y", 0)
      .attr("width", 50)
      .attr("height", 25)
      .attr("fill", "url(#bg-gradient0)")
      .attr("stroke-width", 1.5)
      .attr("stroke", "#023059");
    svg
      .append("text")
      .attr("x", xCood + 55)
      .attr("y", 15)
      .text("Asset Value")
      .attr("fill", "#023059")
      .attr("font-size", "10px");
    xCood = xCood + 120;

    if (_.sum(lcReserveGuranteeList) !== 0) {
      // LC Reserve Gurantee Area
      svg
        .append("rect")
        .attr("x", xCood)
        .attr("y", 0)
        .attr("width", 50)
        .attr("height", 25)
        .attr("fill", "url(#bg-gradient1)")
        .attr("stroke-width", 1.5)
        .attr("stroke", "#023059");
      svg
        .append("text")
        .attr("x", xCood + 55)
        .attr("y", 15)
        .text("LC Reserve/Gurantee")
        .attr("fill", "#023059")
        .attr("font-size", "10px");
      xCood = xCood + 160;
    }

    if (_.sum(mxCashReserveList) !== 0) {
      // MX Cash Reserve Area
      svg
        .append("rect")
        .attr("x", xCood)
        .attr("y", 0)
        .attr("width", 50)
        .attr("height", 25)
        .attr("fill", "url(#bg-gradient2)")
        .attr("stroke-width", 1.5)
        .attr("stroke", "#023059");
      svg
        .append("text")
        .attr("x", xCood + 55)
        .attr("y", 15)
        .text("Cash Reserve")
        .attr("fill", "#023059")
        .attr("font-size", "10px");
      xCood = xCood + 140;
    }

    if (_.sum(halfLifeValueList) !== 0) {
      // Halflife Value line
      svg
        .append("line")
        .style("stroke", "#B5292A")
        .style("stroke-width", "2")
        .style("fill", "none")
        .style("stroke-dasharray", "2, 2")
        .attr("x1", xCood)
        .attr("y1", 13)
        .attr("x2", xCood + 50)
        .attr("y2", 13);
      svg
        .append("text")
        .attr("x", xCood + 55)
        .attr("y", 15)
        .text("Halflife Value")
        .attr("fill", "#023059")
        .attr("font-size", "10px");
      xCood = xCood + 120;
    }

    // Loan Outstanding line
    svg
      .append("line")
      .style("stroke", "#FA6400")
      .style("stroke-width", "2")
      .style("fill", "none")
      .style("stroke-dasharray", "2, 2")
      .attr("x1", xCood)
      .attr("y1", 13)
      .attr("x2", xCood + 50)
      .attr("y2", 13);
    svg
      .append("text")
      .attr("x", xCood + 55)
      .attr("y", 15)
      .text("Loan Outstanding")
      .attr("fill", "#023059")
      .attr("font-size", "10px");
    xCood = xCood + 140;
    // LTV % line
    svg
      .append("line")
      .style("stroke", "#63AB17")
      .style("stroke-width", "2")
      .style("fill", "none")
      // .style("stroke-dasharray", ("2, 2"))
      .attr("x1", xCood)
      .attr("y1", 13)
      .attr("x2", xCood + 50)
      .attr("y2", 13);
    svg
      .append("text")
      .attr("x", xCood + 55)
      .attr("y", 15)
      .text("LTV %")
      .attr("fill", "#023059")
      .attr("font-size", "10px");
    xCood = xCood + 100;
    // Maintance Event Circles
    svg
      .append("circle")
      .attr("class", "circle")
      .attr("cx", xCood)
      .attr("cy", 12)
      .attr("r", 10)
      .style("fill", "#023059");
    svg
      .append("text")
      .attr("x", xCood)
      .attr("y", 17)
      .text("X")
      .attr("text-anchor", "middle")
      .attr("fill", "white");
    svg
      .append("text")
      .attr("x", xCood + 15)
      .attr("y", 15)
      .text("Event Indicator")
      .attr("fill", "#023059")
      .attr("font-size", "10px");
  };

  setAreaChartGradient = (svg, id, o1, o2, o3) => {
    const defs = svg.append("defs");
    const bgGradient = defs
      .append("linearGradient")
      .attr("id", `bg-gradient${id}`)
      .attr("gradientTransform", "rotate(90)");
    bgGradient
      .append("stop")
      .attr("stop-color", `rgba(2, 48, 89, ${o1})`)
      .attr("offset", "0%");
    bgGradient
      .append("stop")
      .attr("stop-color", `rgba(2, 48, 89, ${o2})`)
      .attr("offset", "60%");
    bgGradient
      .append("stop")
      .attr("stop-color", `rgba(2, 48, 89, ${o3})`)
      .attr("offset", "100%");
  };

  handleSelectedEventHighlight = (index) => {
    d3.selectAll(".circle")
      .attr("r", 10)
      .style("fill", "#023059")
      .style("stroke", "none");
    d3.select(`#eventCircle${index}`)
      .moveToFront()
      .select(".circle")
      .attr("r", 14)
      .style("fill", "#0d5391")
      .style("stroke-width", 2)
      .style("stroke", "white")
      .style("font-weight", "normal");
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleCheckEventData = (date) => {
    const { report } = this.props;

    const mxEvents = report.mxEvents.filter(
      (data) =>
        moment.utc(data.date).format(Constants.MONTH_COMP_FORMAT) ===
        moment.utc(date).format(Constants.MONTH_COMP_FORMAT)
    );

    const eolEvents = report.eolEvents.filter(
      (data) =>
        moment.utc(data.date).format(Constants.MONTH_COMP_FORMAT) ===
        moment.utc(date).format(Constants.MONTH_COMP_FORMAT)
    );

    return {
      mxEvents: !_.isEmpty(mxEvents),
      eolEvents: !_.isEmpty(eolEvents),
    };
  };

  handleNextPrevEvent = (direction) => {
    const { report } = this.props;
    const { selectedmxEvent, mxDates } = this.state;
    let index = mxDates.indexOf(selectedmxEvent);
    let nextSelectedmxEvent = null;
    switch (direction) {
      case "previous":
        if (index > 0 && index <= mxDates.length - 1) {
          nextSelectedmxEvent = mxDates[index - 1];
        } else {
          nextSelectedmxEvent = mxDates[mxDates.length - 1];
        }
        break;
      case "next":
        if (index >= 0 && index < mxDates.length - 1) {
          nextSelectedmxEvent = mxDates[index + 1];
        } else {
          nextSelectedmxEvent = mxDates[0];
        }
        break;
      default:
        break;
    }

    let circleIndex = mxDates.indexOf(nextSelectedmxEvent);
    this.handleSelectedEventHighlight(circleIndex);

    const checkEvents = this.handleCheckEventData(nextSelectedmxEvent);

    this.setState({
      selectedmxEvent: nextSelectedmxEvent,
      value: checkEvents.mxEvents ? 0 : checkEvents.eolEvents ? 1 : 3,
    });
  };

  handleComponentNameAbbreviation = (compName) => {
    let nameArray = compName.split(" ");
    if (compName.includes("LLPStack")) {
      return "ELLP Position " + nameArray[nameArray.length - 1];
    } else if (compName.includes("Engine")) {
      return "EPR Position " + nameArray[nameArray.length - 1];
    } else {
      return compName;
    }
  };

  render() {
    const { classes, report } = this.props;
    const { value, selectedmxEvent, mxDates, saleData, convEventData } =
      this.state;
    let mxEventData = null,
      eolEventData = null,
      saleEventData = null,
      selectedConvEventData = null,
      letterIndex = null;

    if (report && mxDates) {
      letterIndex = mxDates.indexOf(selectedmxEvent);
      mxEventData = report.mxEvents.filter(
        (data) =>
          moment.utc(data.date).format(Constants.MONTH_COMP_FORMAT) ===
          selectedmxEvent
      );
      eolEventData = report.eolEvents.filter(
        (data) =>
          moment.utc(data.date).format(Constants.MONTH_COMP_FORMAT) ===
          selectedmxEvent
      );
      saleEventData = saleData.filter(
        (data) =>
          moment.utc(data.date).format(Constants.MONTH_COMP_FORMAT) ===
          selectedmxEvent
      );
      selectedConvEventData = convEventData.filter(
        (data) =>
          moment.utc(data.date).format(Constants.MONTH_COMP_FORMAT) ===
          selectedmxEvent
      );
    }

    return (
      <Grid container className={classes.root} id={"d3LiabChart"}>
        <Grid item sm={9}>
          <Box className={`${classes.containers} ${classes.chartContainer}`}>
            <Box className={classes.eventHeading}>
              <Typography
                className={`${classes.mainTextColor} ${classes.chartLabel}`}
                align='left'
                variant='subtitle1'>
                Loan Balance vs Asset Value
              </Typography>
              <Box style={{ paddingTop: 15 }}>
                {/* <ThreeDotButton fontSize={"medium"} options={null} /> */}
              </Box>
            </Box>

            <Box ref={this.myAreaChart} id={"d3AreaChart"}></Box>
            <Box id={"d3AreaChartLegend"}></Box>
          </Box>
        </Grid>
        <Grid
          item
          sm={3}
          id={"d3LiabChartEvents"}
          className={`${classes.containers} ${classes.mxEvents}`}>
          <Grid container>
            <Grid item sm={12}>
              <Box className={classes.eventHeading}>
                <Box style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Typography
                    className={`${classes.mainTextColor} ${classes.chartLabel}`}
                    align='left'
                    variant='subtitle1'>
                    {"Chart Event"}
                  </Typography>
                  <Typography className={classes.circle}>
                    {letterIndex + 1}
                  </Typography>
                </Box>

                <Box
                  style={{
                    paddingTop: 15,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Box>
                    <Box
                      className={`${classes.mainTextColor} ${classes.eventDate}`}
                      align='left'>
                      {selectedmxEvent}
                    </Box>
                    <Box
                      align='left'
                      style={{
                        fontSize: 10,
                        color: "#023059",
                        opacity: "50%",
                      }}>
                      {"Event Date"}
                    </Box>
                  </Box>
                  <Box style={{ width: 20 }}>
                    {/* <ThreeDotButton fontSize={"medium"} options={null} /> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item ref={this.tabWidth} sm={12}>
              <Tabs
                value={value}
                onChange={this.handleTabChange}
                indicatorColor='primary'
                textColor='primary'
                justify='end'
                variant='fullWidth'>
                <Tab
                  key='1'
                  label={
                    <Badge
                      color='primary'
                      variant='dot'
                      invisible={_.isEmpty(mxEventData) ? true : false}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}>
                      MX_PAYMENT
                    </Badge>
                  }
                  disabled={_.isEmpty(mxEventData) ? true : false}
                  classes={{ root: classes.tab }}
                />
                <Tab
                  key='2'
                  label={
                    <Badge
                      color='primary'
                      variant='dot'
                      invisible={_.isEmpty(eolEventData) ? true : false}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}>
                      EOL COMP
                    </Badge>
                  }
                  disabled={_.isEmpty(eolEventData) ? true : false}
                  classes={{ root: classes.tab }}
                />
                <Tab
                  key='3'
                  label={
                    <Badge
                      color='primary'
                      variant='dot'
                      invisible={_.isEmpty(saleEventData) ? true : false}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}>
                      SALE
                    </Badge>
                  }
                  disabled={_.isEmpty(saleEventData) ? true : false}
                  classes={{ root: classes.tab }}
                />
                {_.isEmpty(selectedConvEventData) ? (
                  []
                ) : (
                  <Tab
                    key='4'
                    label={
                      <Badge
                        color='primary'
                        variant='dot'
                        invisible={
                          _.isEmpty(selectedConvEventData) ? true : false
                        }
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}>
                        Conversion
                      </Badge>
                    }
                    disabled={_.isEmpty(selectedConvEventData) ? true : false}
                    classes={{ root: classes.tab }}
                  />
                )}
              </Tabs>
            </Grid>
            <Grid item sm={12} style={{ height: 425, overflowY: "auto" }}>
              {value === 0 && mxEventData ? (
                mxEventData.map((data, idx) => {
                  return (
                    <ExpansionPanel key={idx}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panelmx${idx + 1}a-content`}
                        id={`panelmx${idx + 1}a-header`}>
                        <Typography className={classes.heading}>{`${
                          data.serialNumber
                        } - ${this.handleComponentNameAbbreviation(
                          data.name
                        )}`}</Typography>
                      </ExpansionPanelSummary>
                      <EventDetails detailType={"mxPayment"} data={data} />
                    </ExpansionPanel>
                  );
                })
              ) : value === 1 && eolEventData ? (
                eolEventData.map((data, idx) => {
                  return (
                    <ExpansionPanel key={idx}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`paneleol${idx + 1}a-content`}
                        id={`paneleol${idx + 1}a-header`}>
                        <Typography className={classes.heading}>{`${
                          data.serialNumber
                        } - ${this.handleComponentNameAbbreviation(
                          data.name
                        )}`}</Typography>
                      </ExpansionPanelSummary>
                      <EventDetails detailType={"EOLComp"} data={data} />
                    </ExpansionPanel>
                  );
                })
              ) : value === 2 && saleEventData ? (
                saleEventData.map((data, idx) => {
                  return (
                    <ExpansionPanel key={idx}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panelsale${idx + 1}a-content`}
                        id={`panelsale${idx + 1}a-header`}>
                        <Typography className={classes.heading}>
                          {data.lessee} {data.serialNumber} Sale
                        </Typography>
                      </ExpansionPanelSummary>
                      <EventDetails detailType={"sale"} data={data} />
                    </ExpansionPanel>
                  );
                })
              ) : value === 3 && selectedConvEventData ? (
                selectedConvEventData.map((data, idx) => {
                  return (
                    <ExpansionPanel key={idx}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panelsale${idx + 1}a-content`}
                        id={`panelsale${idx + 1}a-header`}>
                        <Typography className={classes.heading}>
                          {data.serialNumber} Conversion Event
                        </Typography>
                      </ExpansionPanelSummary>
                      <EventDetails detailType={"conv"} data={data} />
                    </ExpansionPanel>
                  );
                })
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            style={{
              position: "relative",
              top: 20,
              textAlign: "center",
            }}>
            <Box className={classes.mxButtons}>
              <Button
                color='inherit'
                variant='text'
                fullWidth={true}
                disabled={selectedmxEvent ? false : true}
                onClick={() => this.handleNextPrevEvent("previous")}>
                Previous
              </Button>
              <Divider
                orientation='vertical'
                flexItem
                style={{ background: "white" }}
              />
              <Button
                color='inherit'
                variant='text'
                fullWidth={true}
                disabled={selectedmxEvent ? false : true}
                onClick={() => this.handleNextPrevEvent("next")}>
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(StackedAreaChart);
