import mapToViewModel_leaseInfo from "./leaseInfoViewModel";
import mapToViewModel_maintenanceEvents from "./maintenanceEventsViewModel";
import mapToViewModel_redeliveryEvents from "./redeliveryEventsViewModel";
import mapToViewModel_cashFlows from "./cashFlowsViewModel";
import mapToViewModel_conversionEvents from "./conversionEventsViewModel";

export default function mapToViewModel_LeaseSummary(
  portfolioData,
  deal,
  options
) {
  return {
    leaseInfo: mapToViewModel_leaseInfo(portfolioData.leases, options),
    conversionEvents: mapToViewModel_conversionEvents(
      portfolioData.conversionEvents,
      options
    ),
    maintenanceEvents: mapToViewModel_maintenanceEvents(
      portfolioData.mxEvents,
      options
    ),
    redeliveryEvents: mapToViewModel_redeliveryEvents(
      portfolioData.eolEvents,
      options
    ),
    cashFlows: mapToViewModel_cashFlows(portfolioData.cashFlows, options),
  };
}
