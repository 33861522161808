import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";
import * as constants from "../../../../constants";

const deleteScenario = async (scenarioId, msalContext) => {
  let apiendpoint = apiRoutes.Scenario_DeleteScenario({ scenarioId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "DELETE",
    },
    msalContext
  );
  if (resp.status === response.OK) {
    var url = window.location.href;
    let newUrl = url.replace("&scenarioId="+scenarioId, "");
    window.history.pushState({}, null, newUrl);
    setTimeout(() => {
      window.location.reload(true);
    }, 1500);
  }
};

export const scenarioSummary = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
  msalContext
) => {
  if (actionType === constants.DELETE_ROW)
    deleteScenario(newData.id, msalContext);
};
