import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const uploadHistory = async (compType, compId, newdata, msalContext) => {
  let apiendpoint = apiRoutes.PostShopVisit;
  apiendpoint = apiendpoint({ compType, compId });

  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newdata,
    },
    msalContext
  );

  if (resp.status === response.OK) {
    newdata = resp.data;
  }
};

export const updateData = (actionType, assetDetails, newData, msalContext) => {
  assetDetails.airframeChecks = [...assetDetails.airframeChecks];
  const idx = _.findIndex(
    assetDetails.airframeChecks,
    (o) => o.checkName === newData.checkName
  );

  let table = [...assetDetails.airframeChecks[idx].shopVisitHistory];
  newData = {
    id: newData.id,
    startDate: newData.svStartDate,
    endDate: moment
      .utc(newData.svStartDate)
      .add(assetDetails.airframeChecks[idx].genericMxInfo[0].downtime, "days")
      .format(constants.ISOFormat),
    isOverhaul: newData.isOverhaul,
    sinceNew: {
      flightHour: newData.totalHours,
      flightCycle: newData.totalCycles,
      activeHour: newData.activeHours,
      day: newData.days,
    },
    cost: newData.cost,
  };

  if (actionType === constants.ADD_ROW) {
    table.push(newData);
  } else if (actionType === constants.UPDATE_ROW) {
    table[newData.id] = { ...table[newData.id] };
    table[newData.id] = newData;
  } else {
    table = table.filter((row, idx) => idx !== newData.id);
  }

  uploadHistory(
    assetDetails.airframeChecks[idx].componentType,
    assetDetails.airframeChecks[idx].componentId,
    table,
    msalContext
  );
  assetDetails.airframeChecks[idx].shopVisitHistory = table;

  return assetDetails;
};
