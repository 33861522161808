import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as utils from "../../../../utils/utils";
import VfChart from "../../../Common/UtilComponents/VfChart";
import Highcharts from "highcharts";

import { makeStyles } from "@material-ui/core/styles";
import { useMsal } from "@azure/msal-react";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";
import * as constants from "../../../../constants";

function getChartData(elData) {
  const result = {};
  for (const item of elData) {
    const date = new Date(item.asOfDate);
    const key = moment.utc(date).format(constants.DATE_FORMAT);
    let toolTipHtml = `<div title="title tooltip"><b>Obligors</b></div>
    <table><tr><td>Date:</td><td>${key} Bps</td>
    <td>Loss Rate:</td><td>${item.lossRate.toFixed(3)} Bps</td></tr>`;

    item.obligors.forEach((v) => {
      toolTipHtml += `<tr><td>Name:</td><td>${
        v.name
      }</td><td>Spread:</td><td>${v.creditSpread.toFixed(2)}</td></tr>`;
    });

    toolTipHtml += `</table>`;

    let data = {
      x: new Date(key),
      y: item.lossRate,
      toolTipHtml: toolTipHtml,
    };

    if (!result[key]) {
      result[key] = data;
    } else {
      result[key] = Object.assign(result[key], data);
    }
  }
  return result;
}

const ExpectedLossScatterPlot = ({ deal }) => {
  const msalContext = useMsal();
  const [options, setOptions] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const resp = await apiRequest(
        {
          url: apiRoutes.ExpectedLoss_GetExpectedLossChartView,
          params: {
            dealId: deal.dealId,
          },
          method: "GET",
        },
        msalContext
      );

      if (resp && resp.status === response.OK) {
        let formatData = getChartData(resp.data);
        let data = Object.values(formatData);

        const options = {
          chart: {
            type: "scatter",
          },
          title: {
            text: "Historical Expected Loss Rate",
          },
          credits: { enabled: false },
          xAxis: {
            type: "datetime",
            labels: {
              format: "{value:%d %b %y}",
            },
          },
          yAxis: {
            title: {
              text: "Loss Rate (Bps)",
            },
            min: 0,
          },
          tooltip: {
            enabled: true,
            title: {
              text: "Obligor",
            },
            useHTML: true,
            split: true,
            distance: 30,
            padding: 5,
            style: {
              fontSize: "10px",
              maxHeight: "100px",
              overflowY: "scroll",
            },
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                symbol: "circle",
              },
            },
          },
          series: [
            {
              name: "Expected Loss Rate",
              data,
              color: "rgba(2, 48, 89, 0.2)",
              tooltip: {
                pointFormat: "{point.toolTipHtml}",
              },
            },
          ],
        };

        setOptions(options);
      }
    };
    fetchData();
  }, [msalContext]);

  return options ? <VfChart options={options} /> : null;
};

export default ExpectedLossScatterPlot;
