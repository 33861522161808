import mapToViewModel_LeaseList from "./leaseListViewModel";

export default function mapToViewModel_Leases(
  props,
  scenario,
  airlines,
  additionalProps
) {
  return {
    leaseList: mapToViewModel_LeaseList(
      props,
      scenario,
      airlines,
      additionalProps
    ),
  };
}
