import React from "react";
import {
  Typography,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 600,
      width: 200,
    },
  },
};

const SponsorFilter = ({
  sponsorsList,
  handleSponsorChange,
  checkedSponsor,
}) => {
  const handleChange = (event) => {
    handleSponsorChange(event.target.value);
  };

  return (
    <FormControl
      style={{ minWidth: 120, maxWidth: 200, maxHeight: 28, marginBottom: 16 }}>
      <Select
        labelId='demo-mutiple-checkbox-label'
        id='demo-mutiple-checkbox'
        multiple
        displayEmpty
        value={checkedSponsor}
        onChange={handleChange}
        input={
          <Input
            startAdornment={
              checkedSponsor.length !== 0 ? (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='remove all selections'
                    onClick={() => handleSponsorChange([])}
                    edge='start'
                    style={{ margin: "0px 8px 0px -8px" }}
                    size='small'>
                    <CloseIcon style={{ height: 16, width: 16 }} />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              )
            }
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <Typography
                variant='subtitle2'
                color='primary'
                style={{ fontSize: 14, margin: "0px 0px 0px 16px" }}>
                Select Sponsors
              </Typography>
            );
          } else return selected.join(", ");
        }}
        MenuProps={MenuProps}
        variant='outlined'>
        <MenuItem disabled value=''>
          <em>Select Sponsors</em>
        </MenuItem>
        {sponsorsList.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={checkedSponsor.indexOf(name) > -1} />
            <ListItemText
              primary={name}
              disableTypography
              style={{
                fontSize: 14,
                maxWidth: 150,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SponsorFilter;
