import _ from "lodash";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Prompt } from "react-router";
import { Box, Tooltip, Button } from "@material-ui/core";
import LoadingPanel from "../Common/LoadingPanel";
import MainContent from "./Components/MainContent/MainContent";
import SideNavBar from "./Components/SideNavBar/SideNavBar.jsx";
import { apiRequest, response } from "../../utils/ApiRequest";
import apiRoutes from "../../utils/ApiRoutes";
import * as constants from "../../constants";
import * as stateUpdater from "./stateUpdate";
import VfSnackerBar from "../Common/UtilComponents/VfSnackerBar";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import SectionHeader from "../Common/Section/SectionHeader";
import ScenarioOptions from "./Components/MainContent/Components/Scenario/ScenarioOptions/ScenarioOptions";
import { scenarioTemplate } from "./scenarioTemplate";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import * as utils from "../../utils/utils";
import { Visibility } from "@material-ui/icons";
import { MsalContext } from "@azure/msal-react";
import AssetChangeHistory from "./Components/MainContent/Components/Asset/AssetSummaries/AsssetChanges";

class AssetComponents extends Component {
  static contextType = MsalContext;

  assetSummaryTokenSource = null;
  scenarioDTOTokenSource = null;
  reportTokenSource = null;
  allShopVisitEventNames = null;

  constructor(props) {
    super(props);

    this.state = {
      assetId: null,
      assetType: null,
      serialNumber: null,
      scenarioId: null,
      userName: null,
      editing: false,
      showScenarioNotes: false,
      showAssetNotes: false,
      assetDetails: null,
      scenario: {},
      selected: null,
      errors: {
        assetError: false,
        scenarioError: false,
        reportError: false,
      },
      duplicateScenario: false,
      scenarioLoading: false,
      snackOpen: false,
      severity: "info",
      message: "",
      singleAssetDetail: false,
      leaseReset: false,
      updateRenderKey: 0,
    };
  }

  generateEditableProps = (idx, section) => ({
    sectionIdx: idx,
    section,
    allShopVisitEventNames: this.allShopVisitEventNames,
    funcs: {
      handleValuesUpdate: this.handleValueChange(constants.UPDATE_ROW),
      handleRowAdd: this.handleValueChange(constants.ADD_ROW),
      handleColumnAdd: this.handleValueChange(constants.ADD_COLUMN),
      handleRowDelete: this.handleValueChange(constants.DELETE_ROW),
      validateData: this.validateData,
      // allow for custom props in child components
      handleValueChange: this.handleValueChange,
      handleScenarioChangeEvtFromTable: this.handleScenarioChangeEvtFromTable,
    },
  });

  getShopEventNames = (data, assetType) => {
    const eventNames = [
      constants.AIRFRAME_CHECKS,
      constants.ENGINES,
      constants.apUs,
      constants.LANDING_GEARS,
    ];

    let returnMe = {
      [constants.AIRFRAME_CHECKS]: [],
      [constants.ENGINES]: [],
      [constants.apUs]: [],
      [constants.LANDING_GEARS]: [],
      ["events"]: [],
    };
    if (assetType.toLowerCase() === constants.ENGINE.toLowerCase()) {
      returnMe[constants.ENGINES].push({
        checkName: data.position,
        label: `${constants.ENGINE_PR} - ${data.serialNumber}`,
        value: data.position,
      });
      returnMe[constants.ENGINES].push({
        checkName: `LLP Stack ${data.position}`,
        label: `${constants.ENGINE_LLP} - ${data.serialNumber}`,
        value: `LLP Stack ${data.position}`,
      });

      data.supplementalEvents.forEach((ev) => {
        returnMe["events"].push({
          checkName: ev.eventName + ` - ${data.serialNumber}`,
          label: `${ev.eventName} - ${data.serialNumber}`,
          value: ev.eventName + " " + data.position,
        });
      });
    } else {
      eventNames.forEach((event) => {
        switch (event) {
          case constants.AIRFRAME_CHECKS:
            data[event].map((e) => {
              returnMe[event].push({
                checkName: e.checkName,
                label: e.checkName,
                value: e.checkName,
              });
              return null;
            });
            break;
          case constants.ENGINES:
            data[event].map((e) => {
              const enginePR = `${constants.ENGINE_PR} - ${e.serialNumber}`;
              const engineLLP = `${constants.ENGINE_LLP} - ${e.serialNumber}`;
              returnMe[event].push({
                checkName: e.position,
                label: enginePR,
                value: e.position,
              });
              returnMe[event].push({
                checkName: `LLP Stack ${e.position}`,
                label: engineLLP,
                value: `LLP Stack ${e.position}`,
              });
              e.supplementalEvents.forEach((ev) => {
                returnMe["events"].push({
                  checkName: ev.eventName + ` - ${e.serialNumber}`,
                  label: `${ev.eventName} - ${e.serialNumber}`,
                  value: ev.eventName + " " + e.position,
                });
              });
              return null;
            });
            break;
          case constants.apUs:
            data[event].map((e) => {
              returnMe[event].push({
                checkName: e.componentType,
                label: "APU",
                value: "APU",
              });
              return null;
            });
            break;
          case constants.LANDING_GEARS:
            data[event].map((e) => {
              returnMe[event].push({
                checkName: `LG ${e.position}`,
                label: `LG - ${e.position}`,
                value: `LG ${e.position}`,
              });
              return null;
            });
            break;
          default:
            break;
        }
      });
    }
    returnMe = _.pickBy(returnMe, (o) => !_.isEmpty(o));

    return returnMe;
  };

  getAssetSummary = async (assetType, assetId) => {
    //CHANGE TO SINGLE ENDPOINT (GET ASSETDTO);
    const userName = this.props.userName;
    let apiEndPoint =
      assetType.toLowerCase() === constants.AIRCRAFT
        ? apiRoutes.Aircraft_GetAircraftDTO
        : assetType.toLowerCase() === constants.ENGINE ? apiRoutes.Engine_GetEngineDTO
        : apiRoutes.APU_GetAssetDTO;

    apiEndPoint = apiEndPoint({
      assetId,
      userName,
    });

    this.assetSummaryTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.assetSummaryTokenSource.token,
      },
      this.context
    );
  };

  getScenario = async (scenarioId) => {
    let apiEndPoint = apiRoutes.Aircraft_GetScenarioDTO({
      scenarioId,
    });

    this.scenarioDTOTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.scenarioDTOTokenSource.token,
      },
      this.context
    );
  };

  getReports = async (scenarioId) => {
    const apiEndPoint = apiRoutes.VectorDeal_GetSimulateAsset({
      scenarioId: scenarioId,
    });

    this.reportTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.reportTokenSource.token,
      },
      this.context
    );
  };

  updateStateIfStatusOkAssetSummary = async (
    assetType,
    id,
    serialNumber,
    inScenario
  ) => {
    try {
      const assetSummary = await this.getAssetSummary(assetType, id);
      const assetDetails = _.cloneDeep(assetSummary.data);

      this.allShopVisitEventNames = this.getShopEventNames(
        assetDetails,
        assetType
      );
      if (assetType.toLowerCase() === constants.ENGINE.toLowerCase()) {
        assetDetails.engines = [assetSummary.data];
        this.setState({
          assetDetails,
          errors: { ...this.state.errors, assetError: false },
        });
      } else {
        this.setState({
          assetDetails,
          errors: { ...this.state.errors, assetError: false },
        });
      }

      const currScenario = this.state.scenario;
      const scenarioSummaries = assetDetails.scenarioSummaries;
      switch (inScenario) {
        case "simulate":
          let selectedScenariosId = sessionStorage.getItem(serialNumber);
          return selectedScenariosId;
        case "publish":
          if ((scenarioSummaries, !_.isEmpty(currScenario))) {
            let findScenario = _.find(scenarioSummaries, (scenario) => {
              return (
                scenario.name === currScenario.name &&
                scenario.date.slice(0, 10) ===
                  currScenario.scenarioDate.slice(0, 10) &&
                scenario.author === currScenario.author
              );
            });
            if (findScenario) return findScenario.id;
            else return -1;
          } else
            return assetDetails.scenarioSummaries
              ? assetDetails.scenarioSummaries[0].id
              : -1;
        default:
          assetDetails.scenarioSummaries = _.orderBy(
            scenarioSummaries,
            [(obj) => new Date(obj.date)],
            ["desc"]
          );
          const baseScenario = _.find(
            assetDetails.scenarioSummaries,
            (o) => o.name === constants.BASE
          );
          return baseScenario
            ? baseScenario.id
            : !_.isEmpty(assetDetails.scenarioSummaries)
            ? assetDetails.scenarioSummaries[0].id
            : -1;
      }
    } catch (ex) {
      if (axios.isCancel(ex)) return -1;
      console.log(ex);
      this.setState({
        errors: { ...this.state.errors, assetError: true },
      });
      return -1;
    }
  };

  updateStateIfStatusOkScenario = async (scenarioId) => {
    try {
      let scenario = null;
      if (scenarioId !== -1) scenario = await this.getScenario(scenarioId);
      this.setState({
        scenario: scenario ? scenario.data : {},
        errors: { ...this.state.errors, scenarioError: false },
      });
    } catch (ex) {
      if (axios.isCancel(ex)) return;
      console.log(ex);
      this.setState({
        errors: { ...this.state.errors, scenarioError: true },
      });
    }
  };

  updateStateIfStatusOkCashFlow = async (scenarioId) => {
    try {
      let report = null;
      if (scenarioId !== -1) report = await this.getReports(scenarioId);
      this.setState({
        report: report ? report.data : {},
        errors: { ...this.state.errors, reportError: false },
      });
    } catch (ex) {
      if (axios.isCancel(ex)) return;
      console.log(ex);
      this.setState({
        errors: { ...this.state.errors, reportError: true },
      });
    }
  };

  tryUpdatingState = async (
    assetType,
    id,
    serialNumber,
    urlScenarioId,
    inScenario = null
  ) => {
    let scenarioId = await this.updateStateIfStatusOkAssetSummary(
      assetType,
      id,
      serialNumber,
      inScenario
    );

    if (urlScenarioId) scenarioId = urlScenarioId;

    sessionStorage.setItem(serialNumber, scenarioId);

    await this.updateStateIfStatusOkScenario(scenarioId);
    await this.updateStateIfStatusOkCashFlow(scenarioId);
  };

  componentDidMount = async () => {
    const userName = this.props.userName || this.props.accountObj.name;
    const urlValues = utils.parseUrlObject(new URL(window.location.href));
    const pathValues = urlValues.pathValues;
    const assetId = parseInt(pathValues[pathValues.length - 1]);

    this.setState({
      assetId: assetId,
      assetType: urlValues.assetType,
      serialNumber: urlValues.serialNumber,
      scenarioId: urlValues.scenarioId,
      userName: userName,
    });

    if (urlValues.selected) {
      let selected = urlValues.selected.includes("Maint")
        ? "Maint & Util"
        : decodeURI(urlValues.selected);
      this.setState({
        selected: { label: selected, caption: null },
      });
    } else {
      this.setState({
        selected: { label: "Summary", caption: null },
      });
    }

    this.tryUpdatingState(
      urlValues.assetType,
      assetId,
      urlValues.serialNumber,
      urlValues.scenarioId
    );
  };

  componentDidUpdate = (prevProps) => {
    if (this.state.editing) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    if (
      prevProps.assetModel &&
      prevProps.assetModel.id !== this.props.assetModel.id
    ) {
      this.setState({
        assetDetails: null,
        scenario: null,
        selected: null,
        error: null,
      });
      this.tryUpdatingState(
        prevProps.assetModel.assetType,
        prevProps.assetModel.id,
        prevProps.assetModel.serialNumber,
        null
      );
    }
  };

  componentWillUnmount = () => {
    const tokenSources = [
      this.assetSummaryTokenSource,
      this.reportTokenSource,
      this.scenarioDTOTokenSource,
    ];
    for (let tokenSource of tokenSources) {
      if (tokenSource) tokenSource.cancel(constants.API_REQUEST_CANCEL_MSG);
    }
  };

  handleUrlChange = (scenarioId = "", selected = "") => {
    let url = new URL(window.location.href);
    const urlValues = utils.parseUrlObject(url);
    const pathValues = urlValues.pathValues;
    const assetId = parseInt(pathValues[pathValues.length - 1]);
    let dealInfo = "";
    let poolInfo = "";
    let scenarios = "";
    if (urlValues.dealId)
      dealInfo = `&dealId=${urlValues.dealId}&dealName=${decodeURI(
        urlValues.dealName
      ).trim()}`;
    else if (urlValues.poolId)
      poolInfo = `&poolId=${urlValues.poolId}&poolName=${decodeURI(
        urlValues.poolName
      ).trim()}`;
    if (urlValues.scenarios) scenarios = `&scenarios=${urlValues.scenarios}`;
    if (urlValues.scenarioId && !scenarioId)
      scenarioId = `&scenarioId=${urlValues.scenarioId}`;
    if (urlValues.selected && !selected)
      selected = `&selected=${urlValues.selected}`;
    let assetInfo = `?assetType=${urlValues.assetType}&serialNumber=${urlValues.serialNumber}`;
    let newUrl =
      assetId +
      assetInfo +
      dealInfo +
      poolInfo +
      selected +
      scenarioId +
      scenarios;
    window.history.pushState({}, null, newUrl);
  };

  handleScenarioChangeEvt = async (scenarioId, scenarioName) => {
    const { serialNumber, userName, updateRenderKey } = this.state;
    this.handleUrlChange();

    if (scenarioId === -2) {
      this.setState((prevState) => {
        this.state = prevState;
      });
      const scenario = _.cloneDeep(this.state.scenario);
      scenario.id = -2;
      scenario.name = scenarioName;
      scenario.author = userName;
      scenario.date = moment.utc().format(constants.ISOFormat);
      scenario.serialNumber = serialNumber;
      scenario.outputId = null;

      this.setState(() => ({
        scenario,
        editing: true,
        selected: {
          label: "Maint & Util",
          caption: null,
        },
      }));

      // save valuation parameters from current scenario into the new duplicated scenario name
      this.handleSaveValuationParams(scenarioName);
    } else if (scenarioId === -1) {
      this.setState((prevState) => ({ scenario: null }));

      const scenario = _.cloneDeep(scenarioTemplate);
      scenario.name = scenarioName;
      scenario.author = userName;
      scenario.date = moment.utc().format(constants.ISOFormat);
      this.setState((prevState) => ({
        scenario,
        editing: true,
        errors: {
          ...this.state.errors,
          scenarioError: false,
          reportError: true,
        },
        selected: {
          label: "Maint & Util",
          caption: null,
        },
      }));
    } else {
      let selectedScenariosId = sessionStorage.getItem(serialNumber);

      this.handleUrlChange(`&scenarioId=${scenarioId}`, "");

      if (selectedScenariosId) {
        sessionStorage.setItem(serialNumber, scenarioId);
      }

      let newRenderKey = updateRenderKey + 1;

      this.setState(() => ({
        editing: false,
        leaseReset: this.state.selected.label === "Leases" ? true : false,
        updateRenderKey: newRenderKey,
      }));

      await this.updateStateIfStatusOkScenario(scenarioId);
      await this.updateStateIfStatusOkCashFlow(scenarioId);
    }
  };

  handleScenarioChangeEvtFromTable = (scenarioId, scenarioName) => {
    this.handleSelectedChange({
      label: "Maint & Util",
      caption: null,
    });
    this.handleScenarioChangeEvt(scenarioId, scenarioName);
  };

  handleButtonClick = () => {
    this.props.onPageChange(constants.CASHFLOW_REPORT);
  };

  handleScenarioLoading = (shouldLoad, fromComponent = null) => {
    const { assetId, assetType, serialNumber } = this.state;

    this.setState({
      scenarioLoading: shouldLoad,
      editing: false,
    });

    switch (fromComponent) {
      case "simulate":
        this.setState({
          selected: {
            label: "Cashflows",
            caption: null,
          },
        });
        this.tryUpdatingState(
          assetType,
          assetId,
          serialNumber,
          null,
          "simulate"
        );
        break;
      case "publish":
        // this.setState({
        //   selected: {
        //     label: "Maint & Util",
        //     caption: null,
        //   },
        // });
        this.tryUpdatingState(
          assetType,
          assetId,
          serialNumber,
          null,
          "publish"
        );
        break;
      default:
        break;
    }
  };

  // handleDiscardEdited = () => {
  //   this.setState({
  //     editing: false,
  //     assetDetails: null,
  //     scenario: null,
  //     selected: null,
  //     errors: {
  //       assetError: false,
  //       scenarioError: false,
  //       reportError: false,
  //     },
  //   });

  //   this.tryUpdatingState();
  // };

  handleValueChange = (action) => (section, sectionIdx, tableName, newData) => {
    const assetDetails = { ...this.state.assetDetails };
    const scenario = { ...this.state.scenario };
    if (action === "newcomp") {
      scenario.leases[sectionIdx].componentLeaseInfo.push(newData);
      this.setState({ assetDetails, scenario, editing: true });
      return;
    }
    if(action === "swapAppraisal"){
      //move appraisal to addition and new appr to primary
      let tempapp = assetDetails.valuation;
      //make sure it doesnt already exist in array
      assetDetails.additionalValuations.unshift(tempapp);
      // if(assetDetails.additionalValuations && !assetDetails.additionalValuations.find(x => x.appraiser == tempapp.appraiser)){
      //   assetDetails.additionalValuations.push(tempapp);
      // }
      // else
      //   return;
      //replace appraisal with new appraisal
      let newapp = assetDetails.additionalValuations.find(x => x.appraiser === newData);
      if(newapp){
        assetDetails.valuation = newapp;
        //remove old from additional
        assetDetails.additionalValuations = assetDetails.additionalValuations.filter(function( obj ) {
          return obj.id !== newapp.id;
        });
      }
      this.setState({ assetDetails, scenario, editing: false });
      return;
    }
    if(action === "swapAppraisalDate"){
      //move appraisal to addition and new appr to primary
      let tempapp = assetDetails.valuation;
      //make sure it doesnt already exist in array
      assetDetails.additionalValuations.unshift(tempapp);
      // if(assetDetails.additionalValuations && !assetDetails.additionalValuations.find(x => x.appraiser == tempapp.appraiser)){
      //   assetDetails.additionalValuations.push(tempapp);
      // }
      // else
      //   return;
      //replace appraisal with new appraisal
      let newapp = assetDetails.additionalValuations.find(x => x.id === newData);
      if(newapp){
        assetDetails.valuation = newapp;
        //remove old from additional
        assetDetails.additionalValuations = assetDetails.additionalValuations.filter(function( obj ) {
          return obj.id !== newapp.id;
        });
      }
      this.setState({ assetDetails, scenario, editing: false });
      return;
    }
    if(action === "removecomp"){
      scenario.leases[sectionIdx].reserveAccounts = newData;
      this.setState({ assetDetails, scenario, editing: true });
      return;
    }
    if(action === "FGID"){
      this.setState({assetDetails:newData});
      return;
    }

    stateUpdater.updateTable(
      tableName,
      action,
      assetDetails,
      section,
      sectionIdx,
      newData,
      scenario,
      this.context
    );

    const assetSections = [
      "generalInfo",
      "engines",
      "landingGears",
      "apUs",
      "Cash Reserve Accounts",
      "assetNotes",
      "scenarioNotes",
      "Purchase Price",
      "Fleet Data",
      "Asset Details",
      "Appraisal Summary",
      constants.SCENARIO_SUMMARIES,
    ];

    if (
      assetSections.includes(section) &&
      !tableName.includes("VisitAssumptions") &&
      !tableName.includes("scrapSaleCutoff")
    ) {
      this.setState({ assetDetails, scenario, editing: false });
    } else {
      this.setState({ assetDetails, scenario, editing: true });
    }
  };

  handleSelectedChange = (selected) => {
    this.handleUrlChange("", `&selected=${selected.label}`);

    this.setState({
      selected: selected,
      leaseReset: selected.label === "Leases" ? true : false,
    });
    window.scrollTo(0, 0);
  };

  handleSnackerBarOpen = (open) => {
    this.setState({ snackOpen: open });
  };

  handleShowNotes = (noteToChange, show) => {
    this.setState({
      [noteToChange]: !show,
    });
  };

  handleSaveValuationParams = async (scenarioName) => {
    const { report } = this.state.report;

    let valuationParameters = { ...report.valuationParameters };

    valuationParameters.scenarioName = scenarioName;
    delete valuationParameters["id"];

    let apiEndPoint = apiRoutes.VectorDeal_PostValuationParams();

    let resp = await apiRequest(
      {
        url: apiEndPoint,
        method: "POST",
        data: valuationParameters,
      },
      this.context
    );

    if (resp.status === response.OK) {
      this.setState({
        snackOpen: true,
        severity: "success",
        message: "The valuation parameters have been save to the scenario.",
      });
    }
  };

  validateData = (newData, columns, section, tableName, actionType) => {
    // console.log(newData, columns, section, tableName, actionType);
    let allValid = true;
    let errorsOnSubmit = {};
    let statusTables = [
      constants.checkStatus,
      constants.engineStatus,
      constants.apuStatus,
      constants.landingGearStatus,
    ];
    var tooSoon = false;
    let lastMonthEnd = new Date();
    lastMonthEnd.setDate(0);


    if (section === constants.LEASES) {
      if (tableName === constants.maintenanceReserveRates) {
        let isValid = false;
        if (newData.flightHour) isValid = true;
        else if (newData.flightCycle) isValid = true;
        else if (newData.activeHour) isValid = true;
        else if (newData.day) isValid = true;
        else if (newData.monthly) isValid = true;

        return isValid;
      }
      if (
        tableName === constants.redeliveryAdjustments &&
        newData.mxEvent.includes("ELLP")
      ) {
        if (
          newData.unitRate !== "FromMarket" ||
          newData.referenceRateAt !== "FromMarket"
        ) {
          return false;
        }
      }
      if (tableName === constants.leaseList) {
        if (moment.utc(newData.startDate).isBefore(newData.endDate, "day"))
          return true;
        else return false;
      }
      if (tableName === constants.leaseExtension) {
        if (
          moment
            .utc(newData.extensionStartDate)
            .isBefore(newData.extensionEndDate, "day")
        )
          return true;
        else return false;
      }
    }

    if (tableName === constants.checkStatus) {
      let checkStatus = null;
      const { assetDetails } = this.state;
      switch (section) {
        case constants.GENERAL_INFO:
          if (actionType === constants.ADD_ROW) {
            checkStatus = _.flatten(
              assetDetails.airframeChecks.map((o) =>
                o.maintenanceStatus.map((status) => {
                  return { ...status, checkName: o.checkName };
                })
              )
            );
          } else {
            checkStatus = _.flatten(
              assetDetails.airframeChecks.map((o) => {
                if (o.checkName === newData.checkName) return [];
                return o.maintenanceStatus;
              })
            );
          }

          break;
        case constants.engineStatus:
          checkStatus = _.flatten(
            assetDetails.engines.map((o) => {
              if (o.serialNumber === newData.serialNumber) return [];
              return o.maintenanceStatus;
            })
          );
          break;
        case constants.landingGearStatus:
          checkStatus = _.flatten(
            assetDetails.landingGears.map((o) => {
              if (o.position === newData.position) return [];
              return o.maintenanceStatus;
            })
          );
          break;
        case constants.apuStatus:
          checkStatus = _.flatten(
            assetDetails.apUs.map((o) => {
              if (o.model === newData.model) return [];
              return o.maintenanceStatus;
            })
          );
          break;
        default:
          break;
      }

      let sameDateSameDataSinceNew = true;
      let multipleAFCheckOFSameDate = false;
      if (!_.isEmpty(checkStatus)) {
        checkStatus.map((o) => {
          if (
            moment.utc(o.statusAsOf).diff(newData.asOfDate, "days") === 0 &&
            o.statusId != newData.statusId
          ) {
            const {
              flightHours_sinceNew,
              flightCycles_sinceNew,
              days_sinceNew,
            } = newData;
            // const days_sinceNew =
            //   s_sinceNew && _.round(parseInt(months_sinceNew) * 30.44);
            const { flightHour, flightCycle, day } = o.sinceNew;

            if (
              section === constants.GENERAL_INFO &&
              actionType === constants.ADD_ROW &&
              o.checkName === newData.checkName
            )
              multipleAFCheckOFSameDate = true;

            sameDateSameDataSinceNew =
              sameDateSameDataSinceNew &&
              flightHours_sinceNew === flightHour &&
              flightCycles_sinceNew === flightCycle &&
              day === days_sinceNew;
          }
          return null;
        });
      }

      if (multipleAFCheckOFSameDate) {
        this.setState({
          snackOpen: true,
          severity: "error",
          message:
            "Check Status: The same AF Check cannot multiple status as of the same date.",
        });
        return false;
      }
    }
    if (statusTables.includes(tableName)) {
      if (moment.utc(newData.asOfDate) > moment.utc(lastMonthEnd))
        tooSoon = true;
      if (tooSoon) {
        this.setState({
          snackOpen: true,
          severity: "error",
          message: "Cannot have a Status before Last Month's End",
        });
        return false;
      }
    }

    columns.forEach((c) => {
      if (!c.validate || _.isPlainObject(newData[c.field])) {
        errorsOnSubmit[c.field] = false;
      } else {
        let isValid = c.validate(c.type, newData[c.field], c.required);

        allValid &= isValid;
        errorsOnSubmit[c.field] = !isValid;
      }
    });
    return allValid;
  };

  renderBreadCrumb = (subseries, serialNumber) => {
    const assetInfo = _.template(`<%= subseries %> (<%= serialNumber %>)`);

    return [
      {
        label: "Assets",
        type: constants.LINK,
        tooltip: "Back to Assets",
        props: { to: "/assets" },
      },
      {
        label: assetInfo({ subseries, serialNumber }),
        type: constants.TEXT,
      },
    ];
  };

  renderAssetNotesButton = () => {
    const { assetId, assetType } = this.state;

    return (
      <Box style={{ marginTop: 12, zIndex: 20 }}>
        <AssetChangeHistory AssetId={assetId} AssetType={assetType} />
        <span style={{ margin: "0px 16px 0px 16px" }}>
          <Link
            to={{
              pathname: `/assets/leaseSummary/${assetId}?assetType=${assetType}`,
            }}>
            <Tooltip title='Go to Lease Summary' placement='bottom' arrow>
              <Button
                style={{ maxHeight: 28 }}
                variant='outlined'
                color='primary'>
                <span style={{ margin: "0px 8px 0px 8px" }}>Lease Summary</span>
              </Button>
            </Tooltip>
          </Link>
        </span>
      </Box>
    );
  };

  render() {
    const {
      assetDetails,
      report,
      selected,
      scenario,
      errors,
      snackOpen,
      severity,
      message,
      singleAssetDetail,
      userName,
      editing,
      updateRenderKey,
    } = this.state;

    const assetItems = [];
    if (assetDetails) {
      const listItem = {
        label: "Summary",
        caption: null,
      };
      assetItems.push(listItem);
    }

    if (assetDetails && !_.isEmpty(assetDetails.airframeChecks)) {
      const listItem = { label: constants.Airframe, caption: null };
      assetItems.push(listItem);
    }

    if (assetDetails && !_.isEmpty(assetDetails.engines)) {
      const listItem = {
        label: !_.isEmpty(assetDetails.airframeChecks)
          ? constants.Engines
          : constants.Engine,
        caption: null,
      };
      assetItems.push(listItem);
    }

    if (assetDetails && !_.isEmpty(assetDetails.landingGears)) {
      const listItem = { label: constants.LG, caption: null };
      assetItems.push(listItem);
    }

    if (assetDetails && !_.isEmpty(assetDetails.apUs)) {
      const listItem = { label: constants.APU.toUpperCase(), caption: null };
      assetItems.push(listItem);
    }
    if (assetDetails && !_.isEmpty(assetDetails.valuation)) {
      const listItem = { label: constants.APPRAISAL, caption: null };
      assetItems.push(listItem);
    }

    let fromUrlBreadCrumb = null;
    if (
      assetDetails &&
      this.props.location &&
      this.props.renderBreadcrumbs === undefined
    ) {
      const urlValues = utils.parseUrlObject(new URL(window.location.href));
      let assetType = urlValues.assetType.toLowerCase();
      let serialNumber = decodeURI(urlValues.serialNumber);
      let subseries = null;
      if (assetType === constants.AIRCRAFT) {
        subseries = assetDetails.aircraftSubSeries;
      } else {
        subseries = assetDetails.engineSubSeries;
      }
      fromUrlBreadCrumb = this.renderBreadCrumb(subseries, serialNumber);
    }

    const navBarSectionItems = [
      {
        label: "Asset",
        listItems: assetItems,
      },
    ];

    if (!_.isEmpty(scenario)) {
      navBarSectionItems.push({
        label: "Scenario",
        listItems: [
          { label: "Maint & Util", caption: null },
          { label: "Leases", caption: null },
          { label: "Simulation Params", caption: null },
          { label: "Cashflows", caption: null },
          { label: constants.Valuation, caption: null },
        ],
      });
    }

    const selectScenario = [
      "Maint & Util",
      "Leases",
      "Simulation Params",
      "Cashflows",
      "Valuation",
    ];

    return assetDetails ? (
      <ErrorBoundary>
        <VfSnackerBar
          snackOpen={snackOpen}
          setsnackOpen={this.handleSnackerBarOpen}
          severity={severity}
          message={message}
        />

        <VfBreadcrumbContainer
          breadcrumb={
            fromUrlBreadCrumb ? (
              <VfBreadcrumbs breadcrumbs={fromUrlBreadCrumb} />
            ) : (
              this.props.renderBreadcrumbs
            )
          }
        />

        <Prompt
          when={editing}
          message='You have unsaved changes to current scenario, are you sure you want to leave?
            "OK" to continue and discard changes.
            Or "Cancel" to stay on page.'
        />

        <Box
          spacing={10}
          flexGrow={1}
          mb={1}
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 10fr",
            marginTop: 20,
          }}>
          <Box style={{ width: 220, paddingRight: 10 }}>
            <SideNavBar
              assetDetails={assetDetails}
              selected={selected}
              onSelectedChange={this.handleSelectedChange}
              navBarSectionItems={navBarSectionItems}
              handleScenarioChangeEvt={this.handleScenarioChangeEvt}
              singleAssetDetail={singleAssetDetail}
            />
          </Box>
          <Box style={{ maxWidth: "100%" }} className='section-to-print'>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "30px 0px 16px 0px",
              }}>
              <SectionHeader
                label={
                  selected.label === "Maint & Util"
                    ? "Maintenance & Utilization"
                    : selected.label === "Simulation Params"
                    ? "Simulation Parameters"
                    : selected.label
                }
              />

              {selected === null || !scenario ? (
                <></>
              ) : selectScenario.includes(selected.label) ? (
                <ScenarioOptions
                  assetDetails={assetDetails}
                  scenario={scenario}
                  handleScenarioChangeEvt={this.handleScenarioChangeEvt}
                  currentState={this.state}
                  handleScenarioLoading={this.handleScenarioLoading}
                  selected={selected}
                  report={report}
                  errors={errors}
                  handleShowNotes={this.handleShowNotes}
                />
              ) : (
                this.renderAssetNotesButton()
              )}
            </Box>
            <MainContent
              key={updateRenderKey}
              selected={selected}
              assetDetails={assetDetails}
              report={report}
              scenario={scenario}
              generateEditableProps={this.generateEditableProps}
              handleValueChange={this.handleValueChange}
              handleScenarioChangeEvt={this.handleScenarioChangeEvt}
              errors={errors}
              currentState={this.state}
              handleScenarioLoading={this.handleScenarioLoading}
              userName={userName}
            />
          </Box>
        </Box>
      </ErrorBoundary>
    ) : (
      <LoadingPanel />
    );
  }
}

export default AssetComponents;
