import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import React from "react";
import apiRoutes from "../../../../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../../../../utils/ApiRequest";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

const columns = [
  //{ field:"id"},
  {
    field: "changedOn",
    headerName: "Changed On",
    flex: 0.2,
    //width: 100,
    type: "date",
    editable: false,
    hideable: false,
    // valueGetter: (params) => getFormattedDate(params.row.changedOn),
  },
  {
    field: "changedBy",
    headerName: "Changed By",
    //width: 100,
    flex: 0.2,
    editable: false,
    hideable: false,
  },
  {
    field: "fieldChanged",
    headerName: "Field Changed",
    //width: 100,
    flex: 0.2,
    editable: false,
    hideable: false,
  },
  {
    field: "description",
    headerName: "Description",
    //width: 300,
    flex: 0.5,
    editable: false,
    hideable: false,
  },
];

//const abortController = new AbortController();

const AppNoRowsOverlay = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "50%",
    }}>
    <p severity='info'>No Recent Changes</p>
  </div>
);

const AssetChangeHistory = ({ AssetId, AssetType }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const msalContext = useMsal();

  const getChangeHistory = async (id, assetType) => {
    const changesreq = await apiRequest(
      {
        url: apiRoutes.Asset_Changes({ id, assetType }),
        method: "GET",
      },
      msalContext
    );

    if (changesreq.status === response.OK) {
      var result = changesreq.data.map((c) => ({
        id: c.changeId,
        changedOn: moment.utc(c.dateChanged).format("MMM-DD-YY, h:mm a"),
        changedBy: c.user,
        fieldChanged: c.fieldChanged,
        description: c.description,
      }));
      setHistory(result);
      //dateChanged,user,fieldChanged,description
    }
  };

  const handleClickOpen = () => {
    getChangeHistory(AssetId, AssetType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: "inline" }}>
      <Button
        variant='outlined'
        size='small'
        onClick={handleClickOpen}
        startIcon={<Visibility />}
        sx={{ color: "#FFFFFF", fontWeight: 500 }}>
        Change History
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}>
        <DialogTitle>Asset Change History</DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: AppNoRowsOverlay,
                }}
                style={{ height: "70vh" }}
                rows={history}
                // getRowId={(row) => row.id}
                columns={columns}
                disableColumnSelector
                loading={loading}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "changedOn", sort: "desc" }],
                  },
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AssetChangeHistory;
