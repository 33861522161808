import * as constants from "../../../../constants";
import { updateData as updateLandingGearStatus } from "./landingGearStatus";
import { updateData as updateSchedulesShopVisit } from "../common/scheduledShopVisit";
import { updateData as updateShopVisitHistory } from "../common/shopVisitHistory";
import { updateData as updateShopVisitAssumptions } from "../common/shopVisitAssumptions";

export function updateLandingGearInfo(
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario,
  msalContext
) {
  switch (tableName) {
    case constants.landingGearStatus:
      return updateLandingGearStatus(
        actionType,
        assetDetails,
        newData,
        msalContext
      );
    case constants.scheduledShopVisit:
      return updateSchedulesShopVisit(
        tableName,
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        msalContext
      );
    case constants.shopVisitHistory:
      return updateShopVisitHistory(
        tableName,
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        msalContext
      );
    case constants.shopVisitAssumptions:
      return updateShopVisitAssumptions(
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        scenario,
        msalContext
      );
    default:
      return;
  }
}
