import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

export function mapToViewModel_LLPStatus(llps) {
  return {
    label: null,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { pagination: false },
    optionsItems: { pagination: {} },
    span: [12, 12, 12],
    columns: [
      {
        label: "Module",
        caption: null,
        field: "module",
        align: constants.LEFT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Part Name",
        caption: null,
        field: "partName",
        indent: 16,
        align: constants.LEFT,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingLeft: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Cycle Limit",
        caption: null,
        field: "cycleLimit",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Cycles Left",
        caption: null,
        field: "cyclesLeft",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "List Price",
        caption: "U.S. $",
        field: "listPrice",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Escalation Rate",
        caption: null,
        field: "expectedPriceEscalation",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            `${_.round(value, 3) * 100}%`,
            { paddingRight: indent },
            constants.TEXT_CELL
          ),
      },
      {
        label: "Is Core",
        caption: null,
        field: "isCore",
        content: (value) => (value ? "Yes" : "No"),
      },
    ],
    data: llps.map((llp) => ({
      module: llp.module,
      partName: llp.partName,
      cycleLimit: llp.cycleLimit,
      cyclesLeft: llp.cyclesLeft,
      listPrice: llp.listPrice,
      expectedPriceEscalation: llp.expectedPriceEscalation,
      isCore: llp.isCore,
      style: {
        // fontWeight: llp.isCore ? 800 : 500,
        height: 48,
      },
    })),
  };
}
