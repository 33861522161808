import React from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MultiFieldsEditComponent from "../EditingComponents/MultiFieldsEditComponent";

export const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const MultiFieldsFadeInEditingPanel = ({
  open,
  columns,
  data,
  errorsOnSubmit,
  onClose,
  props,
  tableName,
  styles,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MultiFieldsEditComponent
          columns={columns}
          data={data}
          props={props}
          tableName={tableName}
          errorsOnSubmit={errorsOnSubmit}
          onClose={onClose}
          styles={styles}
        />
      </Fade>
    </Modal>
  );
};

export default MultiFieldsFadeInEditingPanel;
