import _ from "lodash";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import * as d3 from "d3";
import * as utils from "../../../../utils/utils";
import * as Constants from "../../../../constants";
import TableNamePanel from "../../../Common/TableNamePanel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";

const styles = {
  rectangle13: {
    margin: "16px 0 16px 0",
    boxShadow:
      "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "16px 16px",
    overflowX: "auto",
    borderRadius: 12,
    backgroundColor: "#ffffff",
  },
  captions: {
    color: "#023059",
    fontSize: 10,
    opacity: 0.6,
  },
};

class ExpectedLossBarChart extends Component {
  constructor(props) {
    super(props);
    this.expectedLossChart = React.createRef();
    this.state = {
      switchState: false,
      selectedObj: "lossRatio",
    };
  }

  componentDidMount = () => {
    const { switchState, selectedObj } = this.state;
    this.drawExpectedLossBarChart(switchState, selectedObj);
  };

  drawExpectedLossBarChart = (switchState, selectedObj) => {
    const { expectedLossData } = this.props;
    const elData = Object.values(expectedLossData.expectedLoss.curve);

    const template = {
      lossAmount: null,
      lossRatio: null,
    };

    // selectedObj = selectedObj === "lossAmount" ? "lossAmount2" : selectedObj;

    const data = [];
    let nanCount = 0;
    for (const obj of elData) {
      const elTemp = _.cloneDeep(expectedLossData);

      if (obj[selectedObj] === "NaN") {
        nanCount += 1;
        elTemp[selectedObj] = 0;
      }

      elTemp[selectedObj] =
        (obj[selectedObj] * obj.numSamples) /
        expectedLossData.overall.numSamples;

      data.push(elTemp);
    }

    const svg = d3
      .select("#expectedLossChart")
      .append("svg")
      .attr("height", 600)
      .attr("width", this.expectedLossChart.current.offsetWidth);

    const strokeWidth = 1.5;
    const margin = { top: 50, bottom: 50, left: 50, right: 25 };
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    if (data.length === nanCount) {
      chart
        .append("text")
        .attr("x", 500)
        .attr("y", 300)
        .text("There is no data to plot the graph");
    } else {
      const width =
        +svg.attr("width") - margin.left - margin.right - strokeWidth * 2;
      const height = +svg.attr("height") - margin.top - margin.bottom;
      const yMax = Math.max(...data.map((d) => d[selectedObj]));

      const xScale = d3
        .scaleBand()
        .range([0, width])
        .domain(data.map((d, i) => i + 1))
        .padding(0.3);

      const yScale = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, yMax])
        .nice();

      // vertical grid lines
      // const makeXLines = () => d3.axisBottom()
      //   .scale(xScale)

      // x-axis labels
      var xAxis = chart
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale).tickFormat((d, i) => `Year ${i + 1}`));

      // xAxis.selectAll("text").attr("fill", "#97A4BA");
      // xAxis.selectAll("line").attr("fill", "#97A4BA");

      // y-axis labels and grid lines
      var yAxis = chart.append("g").call(d3.axisLeft(yScale));

      // horizontal grid lines
      const makeYLines = () => d3.axisLeft().scale(yScale);
      chart
        .append("g")
        .attr("class", "grid")
        .style("opacity", 0.2)
        .call(makeYLines().tickSize(-width, 0, 0));

      const barGroups = chart.selectAll().data(data).enter().append("g");

      barGroups
        .append("rect")
        .attr("class", "bar")
        .attr("rx", 6)
        .attr("ry", 6)
        .attr("x", (d, i) => xScale(i + 1) + xScale.bandwidth() / 2 - 30)
        .attr("y", (d) => yScale(d[selectedObj]))
        .attr("height", (d) => height - yScale(d[selectedObj]))
        .attr("width", 60)
        .attr("border-radius", "5px")
        .attr("fill", "#023059");

      // actual bps value indicator above bar
      barGroups
        .append("rect")
        .attr("class", "chips")
        .attr("rx", 6)
        .attr("ry", 6)
        .attr("x", (d, i) => xScale(i + 1) + xScale.bandwidth() / 2 - 35)
        .attr("y", (d) =>
          d[selectedObj] > 0.3 ? yScale(d[selectedObj]) - 22 : yScale(0.6) - 16
        )
        .attr("height", 20)
        .attr("width", 70)
        .attr("border-radius", "px")
        .attr("stroke", "#023059")
        .attr("fill", "none");
      barGroups
        .append("text")
        .attr("class", "value")
        .attr("x", (d, i) => xScale(i + 1) + xScale.bandwidth() / 2)
        .attr("y", (d) =>
          d[selectedObj] > 0.3 ? yScale(d[selectedObj]) - 8 : yScale(0.6)
        )
        .text((d) => {
          if (d[selectedObj]) {
            return switchState
              ? "$ " + utils.thousandSeparator(d[selectedObj].toFixed(0))
              : d[selectedObj].toFixed(2) + "Bps";
          } else {
            return Constants.___;
          }
        })
        .attr("text-anchor", "middle")
        .attr("fill", "#023059")
        .style("opacity", 0.7);
    }
  };

  handleSwitchChange = (event) => {
    d3.select(this.expectedLossChart.current).select("svg").remove();

    const selectedObj = event.target.checked ? "lossAmount" : "lossRatio";

    this.drawExpectedLossBarChart(event.target.checked, selectedObj);

    this.setState({
      switchState: event.target.checked,
      selectedObj: selectedObj,
    });
  };

  render() {
    const { classes, expectedLossData } = this.props;
    const { switchState, selectedObj } = this.state;

    return (
      <Box>
        <Box className={classes.rectangle13}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Box style={{ width: 300 }}>
              <TableNamePanel
                label={"Expected Loss"}
                caption={
                  "Sim Date: " +
                  moment
                    .utc(expectedLossData.eL_RunDate)
                    .format(Constants.DATE_FORMAT)
                }
              />
              {switchState ? (
                <Box className={classes.captions}>Amount in $</Box>
              ) : (
                <Box className={classes.captions}>
                  Bps: 1 Bps = 1/100th of 1% = 0.01%
                </Box>
              )}
            </Box>
            <Box style={{ margin: "10px 20px 0 0" }}>
              <span>Loss Rate</span>
              <Switch
                checked={switchState}
                onChange={this.handleSwitchChange}
                color='primary'
                name='switchGraph'
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <span>Loss Amount</span>
            </Box>

            <Box
              style={{
                border: "2px solid #023059",
                borderRadius: 5,
                padding: "4px 24px 4px 24px",
                margin: "4px 4px 0 0",
              }}>
              <Box
                style={{
                  color: "#023059",
                  fontSize: 24,
                  lineHeight: "30px",
                }}>
                {expectedLossData.expectedLoss.overall[selectedObj]
                  ? switchState
                    ? "$ " +
                      utils.thousandSeparator(
                        expectedLossData.expectedLoss.overall[
                          selectedObj
                        ].toFixed(0)
                      )
                    : expectedLossData.expectedLoss.overall[
                        selectedObj
                      ].toFixed(2) + " Bps"
                  : Constants.___}
              </Box>
              <Box
                style={{
                  color: "#023059",
                  fontSize: 10,
                  opacity: 0.6,
                }}>
                {switchState ? "Expected Loss Amount" : "Expected Loss Rate"}
              </Box>
            </Box>
          </Box>
          <Box ref={this.expectedLossChart} id={"expectedLossChart"}></Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(ExpectedLossBarChart);
