import React, { useEffect, useState, createContext } from "react";
import { useMsal } from "@azure/msal-react";
import apiRoutes from "../utils/ApiRoutes";
import { apiRequest, response } from "../utils/ApiRequest";

const StaticDataContext = createContext(undefined);

const StaticDataContextProvider = ({ children }) => {
  const [sponsorsData, setSponsorsData] = useState(null);
  const [airlinesData, setAirlinesData] = useState(null);
  const [lesseeData, setLesseeData] = useState(null);
  const [lessorData, setLessorData] = useState(null);
  const [manufacturerData, setManufacturerData] = useState(null);

 // const [assetsData, setAssetsData] = useState(null);
  const msalContext = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const companies = await apiRequest(
        {
          url: apiRoutes.Company_GetCompanies,
          method: "GET",
        },
        msalContext
      );

      if (companies && companies.status === response.OK) {
        setSponsorsData(companies.data.sponsors);
        setAirlinesData(companies.data.airlines);
        setLesseeData(companies.data.lessees);
        setLessorData(companies.data.lessors);
        setManufacturerData(companies.data.manufacturers);
      }
    };
    fetchData();
  }, [msalContext]);

  return (
    <StaticDataContext.Provider
      value={{
        sponsorsData: sponsorsData,
        airlinesData: airlinesData,
        lesseeData: lesseeData,
        lessorData: lessorData,
        manufacturerData: manufacturerData
      }}>
      {children}
    </StaticDataContext.Provider>
  );
};

export { StaticDataContextProvider, StaticDataContext };
