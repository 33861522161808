import _ from "lodash";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";

export function mapToViewModel_engineSubstitution(leaseSummary, props) {
  let columns = [
    {
      label: ["Engine substitution at any time"],
      field: "engineSubAtAnyTime",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Engine substitution at event of Loss/Casualty Occurrence"],
      field: "engineSubEventLoss_CasualtyOccurrence",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Engine Substitution at return"],
      field: "engineSubAtReturn",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lessor Substitution"],
      field: "lessorSub",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
  ];

  let data = {
    engineSubAtAnyTime: leaseSummary.engineSubstitution.engineSubAtAnyTime,
    engineSubEventLoss_CasualtyOccurrence: leaseSummary.engineSubstitution.engineSubEventLoss_CasualtyOccurrence,
    engineSubAtReturn: leaseSummary.engineSubstitution.engineSubAtReturn,
    lessorSub: leaseSummary.engineSubstitution.lessorSub,
  };

  leaseSummary.engineSubstitution.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  });

  return {
    label: "Engine Substitution",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Engine Substitution",
    columns: columns,
    data: data,
  };
}
