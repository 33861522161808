import _ from "lodash";
import * as constants from "../../../../constants";

export function aircraftsDetails(actionType, sectionName, sectionIdx, newData, loanSummary) {
  const section = loanSummary[sectionName].aircraftsDetails
  const rowData = section.dataTable
  
  if (sectionIdx === "footNotes") {
    newData = newData.footNote
  } else {
    newData = {
      id: newData.id,
      aircraft: newData.aircraft,
      msn: newData.msn,
      regMark: newData.regMark,
      yearOfManu: newData.yearOfManu,
      engines: newData.engines,
      eSNs: newData.eSNs,
      mortgage: newData.mortgage,
      idera: newData.idera,
      deregPoA: newData.deregPoA,
    }
  };

  if (actionType === constants.ADD_ROW) {
    rowData.push(newData);
  } else if (actionType === constants.DELETE_ROW) {
    if (sectionIdx === "footNotes") {
      section.footNotes = null;
    } else {
      section.dataTable = rowData.filter((row, idx) => idx !== newData.id);
    }
  } else {
    if (sectionIdx === "footNotes") {
      section.footNotes = newData;
    } else {
      rowData[newData.id] = { ...rowData[newData.id] };
      rowData[newData.id] = newData;
    }
  };

  return loanSummary
}
