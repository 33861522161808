import React from "react";
import { Avatar, Box } from "@material-ui/core";
// import { AddBox } from "@material-ui/icons";
import _ from "lodash";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";
import AircraftSvg from "../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../Common/SvgComponents/EngineSvg";
import OnWingEngineSvg from "../../Common/SvgComponents/OnWingEngineSvg";
import moment from "moment";

const cellStyle = { padding: "8px 16px" };

export function mapToViewModel_Assets(assets, cols, props) {
  let dealLookup = {};
  let dealNames = [];
  let data = [];
  let unfilteredcols = [
    {
      title: "Asset Type",
      caption: null,
      field: "assetType",
      cellStyle: cellStyle,
      lookup: {
        Aircraft: "Aircraft",
        Engine: "Engine",
        OnWingEngine: constants.OnWingEngine
      },
      render: (rowData) => (
        <Box style={{ width: "100%", display: "flex", maxHeight: 30 }}>
          {rowData.assetType.toUpperCase() ===
          constants.AIRCRAFT.toUpperCase() ? (
            <AircraftSvg
              height={30}
              width={30}
              viewBox='0 0 25 25'
              style={{ margin: "0 auto" }}
            />
          ) : rowData.assetType.toUpperCase() ===
          constants.OnWingEngine.toUpperCase() ? 
          (
            <OnWingEngineSvg
              height={30}
              width={30}
              viewBox='0 0 25 25'
              style={{ margin: "0 auto" }}
            />
          )
          : (
            <EngineSvg
              height={30}
              width={30}
              viewBox='0 0 25 25'
              style={{ margin: "0 auto" }}
            />
          )}
        </Box>
      ),
    },
    {
      title: "Operator",
      field: "operator",
      align: "left",
      sorting: true, 
      cellStyle: cellStyle,
      render: (rowData) => (
        <Box
          style={{
            width: 130,
            display: "flex",
            justifyContent: "flex-start",
          }}>
          {rowData.operatorRender.airlineLogo && (
            <span>
              <Avatar
                alt={rowData.operatorRender.operator || constants.___}
                style={{ width: 20, height: 20 }}
                src={rowData.operatorRender.airlineLogo}
              />
            </span>
          )}
          <span
            style={{
              marginLeft: 8,
              verticalAlign: rowData.operatorRender.airlineLogo
                ? "super"
                : "initial",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
            {rowData.operatorRender.operator || constants.___}
          </span>
        </Box>
      ),
    },
    {
      title: "Asset Model",
      caption: null,
      align: "left",
      field: "subSeries",
      sorting: true,
      
      
      cellStyle: cellStyle,
      render: (rowData) => (
        <Box
          style={{
            width: 100,
            display: "flex",
            justifyContent: "flex-start",
          }}>
          {rowData.subSeriesRender.manufacturerLogo && (
            <span>
              <Avatar
                alt={rowData.subSeriesRender.subSeries || constants.___}
                style={{ width: 20, height: 20 }}
                src={rowData.subSeriesRender.manufacturerLogo}
              />
            </span>
          )}
          <span
            style={{
              marginLeft: 8,
              verticalAlign: rowData.subSeriesRender.manufacturerLogo
                ? "super"
                : "initial",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
            {rowData.subSeriesRender.subSeries || constants.___}
          </span>
        </Box>
      ),
    },
    {
      title: "Vintage",
      caption: null,
      align: "left",
      sorting: true,
      field: "vintage",
      cellStyle: cellStyle,
    },
    {
      title: "Serial Number",
      caption: null,
      align: "left",
      sorting: true,
      
      
      field: "serialNumber",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Box
          style={{
            width: 80,
          }}>
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
            {rowData.serialNumber}
          </span>
        </Box>
      ),
    },
    {
      title: "Registration",
      caption: null,
      align: "left",
      sorting: true,
      
      
      field: "registration",
      cellStyle: cellStyle,
    },
    {
      title: "Deal",
      caption: null,
      align: "left",
      sorting: true,
      field: "dealInfo",
      
      
      lookup: dealLookup,
      cellStyle: cellStyle,
      render: (rowData) => (
        <Box
          style={{
            width: 100,
          }}>
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
            {rowData.dealInfo}
          </span>
        </Box>
      ),
    },
    {
      title: "Value",
      caption: null,
      align: "left",
      sorting: true,
      field: "assetValue",
      
      
      cellStyle: cellStyle,
      render: (rowData) =>
        utils.renderCellValue(
          rowData.assetValue,
          null,
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      title: "Appraiser",
      caption: null,
      align: "left",
      sorting: true,
      field: "appraiser",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Box
          style={{
            width: 75,
          }}>
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
            {rowData.appraiser}
          </span>
        </Box>
      ),
    },
    {
      title: "Appraisal Date",
      caption: null,
      align: "left",
      sorting: true,
      field: "valDate",
      cellStyle: cellStyle,
      render: (rowData) => (
        <Box
          style={{
            width: 80,
          }}>
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
             
            {rowData.valDate ? moment(rowData.valDate).format(constants.DATE_FORMAT) : "---"}
          
          </span>
        </Box>
      ),
    },
    {
      title: "Appraisal CMV",
      caption: null,
      align: "left",
      sorting: true,
      field: "cmv",
      cellStyle: cellStyle,
      render: (rowData) =>
      (
          <Box
          style={{
            width: 50,
          }}>
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}>
            {rowData.cmv ? "$"+rowData.cmv+" M" : "---"}
          </span>
        </Box>
      )

    },
    {
      title: "Appraisal CBV",
      caption: null,
      align: "left",
      sorting: true,
      field: "cbv",
      cellStyle: cellStyle,
      render: (rowData) =>
      (
        <Box
        style={{
          width: 50,
        }}>
        <span
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}>
          {rowData.cmv ? "$"+rowData.cmv+" M" : "---"}
        </span>
      </Box>
    )
    },
    {
      title: "Partout Base",
      caption: null,
      align: "left",
      sorting: true,
      field: "poValue_Base",
      cellStyle: cellStyle,
      render: (rowData) =>
        utils.renderCellValue(
          rowData?.poValue_Base,
          null,
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      title: "Partout Market",
      caption: null,
      align: "left",
      sorting: true,
      field: "poValue_Market",
      cellStyle: cellStyle,
      render: (rowData) =>
        utils.renderCellValue(
          rowData?.poValue_Market,
          null,
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      title: "HalfLife Value",
      caption: null,
      align: "left",
      sorting: true,
      field: "hlValue",
      cellStyle: cellStyle,
      render: (rowData) =>
        utils.renderCellValue(
          rowData?.hlValue,
          null,
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      title: "Details",
      caption: null,
      field: "details",
      cellStyle: cellStyle,
      textAlign: "right",
      contentType: constants.BUTTON,
      sorting: false,
      filtering: false,
      export: false,
      render: (rowData) => (
        <Box style={{ width: "100%", display: "flex" }}>
          {utils.renderEventName(
            true,
            rowData.details.title,
            () =>
              props.funcs.handleClick(
                rowData.details.assetId,
                rowData.details.assetType,
                rowData.details.serialNumber
              ),
            null,
            "Right",
            { margin: "0 auto" }
          )}
        </Box>
      ),
    },
  ]
  let filteredcols = unfilteredcols.map((c)=>{
    var item = cols.find(x=>x === c.title )
    if(item)
      return c;
    }).filter(item => item !== undefined);


  assets.forEach((e) => {
    data.push({
      id: e.id,
      assetType: e.assetType,
      operatorRender: {
        airlineLogo: e.airlineLogo,
        operator: e.operator,
      },
      operator: e.operator,
      registration: e.registration,
      vintage: e.manufactureDate
        ? new Date(e.manufactureDate).getFullYear()
        : null,
      serialNumber: e.serialNumber,
      appraiser: e.appraiser,
      hlValue: e.currentCashflow?.cashflow?.cashFlow?.halfLifeValue,
      cbv: e.cbv,
      cmv: e.cmv,
      valDate: e.valDate,
      poValue_Base: e.currentCashflow?.cashflow?.cashFlow?.poValue_Base,
      poValue_Market: e.currentCashflow?.cashflow?.cashFlow?.poValue_Market,
      subSeriesRender: {
        manufacturerLogo: e.manufacturerLogo,
        subSeries: e.subseries,
      },
      subSeries: e.subseries,
      assetValue: e.value,
      dealInfo: !_.isEmpty(e.dealInfo) ? e.dealInfo[0].dealName : constants.___,
      details: {
        title: "Details",
        assetId: e.assetType === constants.OnWingEngine ? e.aircraftId : e.id,
        assetType: e.assetType === constants.OnWingEngine ? "Aircraft" : e.assetType,
        serialNumber: e.assetType === constants.OnWingEngine ? e.msn : e.serialNumber,
      },
    });

    if (!_.isEmpty(e.dealInfo)) dealNames.push(e.dealInfo[0].dealName);
  });

  _.sortedUniq(dealNames).forEach((e) => {
    dealLookup[e] = e;
  });

  return {
    label: "Assets",
    caption: null,
    // tableType: constants.MULTIFUNCTION_TABLE,
    showOptions: true,
    tableType: constants.FILTERING_TABLE,
    tableOptions: {
      paging: true,
      sorting: true,
      //toolbar: false,
      pageSize: 20,
      pageSizeOptions: [20, 40, 60, 80, 100],
    },
    columns:filteredcols,
    data: data,
  };
}
