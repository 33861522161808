import React, { useState, useEffect } from "react";
import { Box,FormControl,FormLabel,FormGroup,FormControlLabel,Grid, Checkbox, TextField, LinearProgress,Button, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import * as constants from "../../constants";
import SectionHeader from "../Common/Section/SectionHeader";
import apiRoutes from "../../utils/ApiRoutes";
import { apiRequest, response } from "../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
import { DataGrid } from "@mui/x-data-grid";
import { blue } from "@material-ui/core/colors";


const renderBreadCrumbs = () => {
    return [
      {
        label: "Valuations",
        type: constants.TEXT,
        tooltip: "All Appraisals Page",
      },
    ];
};
const styles = {
    griditem: {
        display: "flex",
        justifyContent: "space-between",
      },
    griditemLast: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: -50,
    },
      smallTitle: {
        fontSize: 16,
        color: "#023059",
        fontWeight: 550,
      },
}
const AppNoRowsOverlay = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50%",
      }}>
      <p severity='info'>No Appraisal Data</p>
    </div>
);

const ValuationComponent = ({
    props
}) => {
    const [valuations, setValuations] = useState([]);
    const [aircraftVals, setAircraftValuations] = useState([]);
    const [engVals, setEngineValuations] = useState([]);
    const [appraisers,SetAppraisers] = useState([{label:"Ascend",value:0},{label:"IBA",value:0},{label:"Oriel",value:0}]);
    const [appraiserFilter, setAppraiserFilter] = useState([true,true,true]);

    const [domList, setDomList] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [engTypeList, setEngTypeList] = useState([]);
    const [afList, setAfList] = useState([]);

    const [filteredVals, setFilteredVals] = useState([]);

    const [gridVals, setGridVals] = useState([]);
    const [chartVals, setChartVals] = useState([]);

    const [airframeType, setAirframeType] = useState([]);
    const [assetTypeBox, setAssetTypeBox] = useState(1);
    const [engType, setEngType] = useState([]);
    const [vintage, setVintage] = useState([]);
    const [valDateFilter, setvalDateFilter] = useState([]);

    const msalContext = useMsal();

    //on load
    useEffect(()=>
    {
        const getValuations = async () =>{
            const resp = await apiRequest(
                {
                    url: apiRoutes.Valuation_ValuationPage,
                    method: "GET",
                },
                msalContext
            );
            if (resp.status === response.OK) {
                setValuations(resp.data);
                const acs = resp.data.filter((x) => x.assetType === "specificAircraft");
                const engs = resp.data.filter((x) => x.assetType === "specificEngine");
                setAircraftValuations(acs);
                let uniqueappr = [...new Set(acs.map(item => item.appraiser))];
                //checkbox for each of these appraisers
                setAppraiserFilter(new Array(uniqueappr.length).fill(true));
                let appcount = [];
                uniqueappr.forEach((a) => {
                    let filt = acs.filter((x) => x.appraiser === a);
                    appcount.push({label:a,value:filt.length});
                });
                SetAppraisers(appcount);
                setEngineValuations(engs);
                setFilteredVals(acs);
                setAfList(acs);
                setDateList(acs);
                setEngTypeList(acs);
                setDomList(acs);

                let temprows = acs.map(v => (
                    {
                        id : v.id,
                        assetSubseries: v.airframeSubSeries,
                        engineSubseries: v.engineSubSeries,
                        vintage: v.vintage,
                        appraiser: v.appraiser,
                        appraisalDate: new Date(v.valuationDate).toLocaleDateString('en-US'),
                        hlBase: v.halfLife_Base,
                        hlMarket: v.halfLife_Market
                    }
                ));
                setGridVals(temprows);
            }
        }
        getValuations();
    },[msalContext]);
    //useeffect for updating data grid rows
    useEffect(()=>{
        const formatGridRows = () =>{
            //console.log(aircraftVals);
            //console.log(filteredVals);
            let temprows = filteredVals.map(v => (
                {
                    id : v.id,
                    assetSubseries: assetTypeBox === -1 ? v.engineSubSeries : v.airframeSubSeries,
                    engineSubseries: assetTypeBox === -1 ? "---" : v.engineSubSeries,
                    vintage: assetTypeBox === -1 ? "---" : v.vintage,
                    appraiser: v.appraiser,
                    appraisalDate: new Date(v.valuationDate).toLocaleDateString('en-US'),
                    hlBase: v.halfLife_Base,
                    hlMarket: v.halfLife_Market
                }
            ));
            setGridVals(temprows);
        }
        formatGridRows();
    },[filteredVals])
    //for updating the filters for the grid 
    useEffect(() =>{
        const handleFilterGrid = () => {
            // Filter By Asset Type
            let filteredlist =
                assetTypeBox === -1 ? engVals : aircraftVals; 
            
            // Filter By Appraiser
            for(let i = 0; i<appraisers.length; i++){
                if(i < appraiserFilter.length && !appraiserFilter[i]){
                    filteredlist = filteredlist.filter((x) => x.appraiser !== appraisers[i].label);
                }
            }
            setAfList(filteredlist);
            //If Aircraft Filter 
            // By Airframe Type
            // By DOM
            if(assetTypeBox !== -1){
                if(airframeType.length > 0){
                    filteredlist = filteredlist.filter((x) => airframeType.includes(x.airframeSubSeries));
                }
                setDomList(filteredlist);
                if(vintage.length > 0)
                    filteredlist = filteredlist.filter((x) => vintage.includes(x.vintage));
            }
            setEngTypeList(filteredlist);
            // Filter By Engine Type
            if(engType.length > 0){
                filteredlist = filteredlist.filter((x) => engType.includes(x.engineSubSeries));
            }
            setDateList(filteredlist);
            // Filter By Valuation Date
            if(valDateFilter.length > 0){
                filteredlist = filteredlist.filter((x) => valDateFilter.includes(new Date(x.valuationDate).toLocaleDateString('en-US',{
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })));
            }
            setFilteredVals(filteredlist);
         }
        handleFilterGrid();
    },[assetTypeBox,appraiserFilter,airframeType,engType,vintage,valDateFilter,engVals,aircraftVals])
    const handleChangeAssetType = ()=>{
        //setAssetTypeBox(assetTypeBox*-1, () => { handleFilterGrid()} );
        let vs = aircraftVals;
        if(assetTypeBox*-1 === -1)
            vs = engVals;

        let uniqueappr = [...new Set(vs.map(item => item.appraiser))];
        //checkbox for each of these appraisers
        setAppraiserFilter(new Array(uniqueappr.length).fill(true));
        let appcount = [];
        uniqueappr.forEach((a) => {
            let filt = vs.filter((x) => x.appraiser === a);
            appcount.push({label:a,value:filt.length});
        });
        SetAppraisers(appcount);
        setAssetTypeBox(assetTypeBox*-1);

        return;
    }
    const handleChangeAppraiser = (target)=>{
        let tempfilter = [...appraiserFilter];
        tempfilter[parseInt(target.id)] = !tempfilter[parseInt(target.id)];
        setAppraiserFilter(tempfilter);
        return;
    }
    const handleChangeAirframe = (v) => {
        setAirframeType(v);
    }
    const handleChangeEng = (v) => {
        setEngType(v);
    }
    const handleChangeVintage = (v) => {
        setVintage(v);
    }
    const handleChangeValDate = (v) => {
        setvalDateFilter(v);
    }
    const downloadAppraisal =  async (id) => {
        const response = await apiRequest(
            {
              url:apiRoutes.Valuation_ExportExcel({id}),
              method: "GET",
              responseType: "blob",
              withCredentials: true,
              headers: {'Access-Control-Expose-Headers': 'Content-Disposition'}
            },
            msalContext
        );
        const type = response.headers["content-type"];
        const name = response.headers["content-disposition"]
        const parts = name.split(';');
        let filename = parts[1].split('=')[1];
        const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename.slice(1,-1)
        link.click();
    };
    const handleResetFilter = () =>{
        setAssetTypeBox(1);
        let uniqueappr = [...new Set(aircraftVals.map(item => item.appraiser))];
        //checkbox for each of these appraisers
        setAppraiserFilter(new Array(uniqueappr.length).fill(true));
        let appcount = [];
        uniqueappr.forEach((a) => {
            let filt = aircraftVals.filter((x) => x.appraiser === a);
            appcount.push({label:a,value:filt.length});
        });
        SetAppraisers(appcount);
        setAirframeType([]);
        setEngType([]);
        setVintage([]);
        setvalDateFilter([]);
    }

    const columns = [
        {
            field: "assetSubseries",
            headerName: "Subseries",
            flex: 0.2,
            //width: 100,
            editable: false,
            hideable: false,
            // valueGetter: (params) => getFormattedDate(params.row.changedOn),
        },
        {
            field: "engineSubseries",
            headerName: "Engine",
            flex: 0.2,
            //width: 100,
            editable: false,
            hideable: false,
            // valueGetter: (params) => getFormattedDate(params.row.changedOn),
        },
        {
            field: "vintage",
            headerName: "DOM",
            flex: 0.15,
            //width: 100,
            editable: false,
            hideable: false,
            // valueGetter: (params) => getFormattedDate(params.row.changedOn),
        },
        {
          field: "appraiser",
          headerName: "Appraiser",
          flex: 0.2,
          //width: 100,
          editable: false,
          hideable: false,
          // valueGetter: (params) => getFormattedDate(params.row.changedOn),
        },
        {
          field: "appraisalDate",
          headerName: "As Of",
          //width: 100,
          type: "date",
          flex: 0.2,
          editable: false,
          hideable: false,
        },
        {
            field: "hlBase",
            //headerName: "Half-Life Base Value",
            headerName: "HL Base",
            //width: 300,
            flex: 0.2,
            editable: false,
            hideable: true,
        },
        {
            field: "hlMarket",
            //headerName: "Half-Life Market Value",
            headerName: "HL Market",
            //width: 300,
            flex: 0.2,
            editable: false,
            hideable: true,
        },
        {
            field: "dl",
            headerName: "Download",
            width: 100,
            flex: 0.125,
            editable: false,
            hideable: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div >
                    <IconButton
                        onClick={() => {
                        downloadAppraisal(params.row.id);
                        }}
                        style={{fontSize:"10",color:blue}}
                    >
                    <CloudDownloadIcon/>
                    </IconButton>
                    </div>
                );
            }
            
        },
     ];

    return (
        <Box>
            <VfBreadcrumbContainer
            breadcrumb={<VfBreadcrumbs breadcrumbs={renderBreadCrumbs()} />}
            button={null}
            />
            <Box style={{ display: "flex", margin: "48px 0 8px 0" }}>
            <SectionHeader label={"Asset Valuations"} />
            </Box>
            <Button
                style={{ maxHeight: 28, marginRight:8}}
                variant='outlined'
                color='primary'
                onClick={handleResetFilter}>
                <span style={{ margin: "0px 8px 0px 8px" }}>Reset Filters</span>
            </Button>
            
            <p>Total Appraisals ({valuations.length})</p>
            <Grid container spacing={2} style={{ display: "flex",minWidth: 1250, marginBottom: 10 }}>
                <Grid item sm={2} style={styles.griditem} >
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Asset Type</FormLabel>
                        <FormGroup>
                        <FormControlLabel
                            control={
                            <Checkbox 
                                checked={assetTypeBox === 1} 
                                onChange={(e) => handleChangeAssetType(e.target)} 
                                name="Aircraft" id={1} />
                            }
                            label={"Aircraft ("+aircraftVals.length+")"}
                        />
                        <FormControlLabel
                            control={
                            <Checkbox checked={assetTypeBox === -1} onChange={(e) => handleChangeAssetType(e.target)} name="Engine"
                                    id={-1}
                            />
                            }
                            label={"Engine ("+engVals.length+")"}
                        />
                        </FormGroup>
                    </FormControl>
                </Grid>
                {/* Appraisers */}
                <Grid item sm={2} style={styles.griditem} >
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Appraiser</FormLabel>
                        <FormGroup>
                        {appraisers.map((item,index)=>{
                            return(
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            defaultChecked
                                            checked={appraiserFilter[index]}
                                            onChange={(e)=>handleChangeAppraiser(e.target)}
                                            id={index} 
                                        />
                                    }
                                    label={item.label+"("+item.value+")"}
                                />
                            )
                        })}
                        {
                        /* <FormControlLabel
                            control={
                            <Checkbox checked={true} onChange={handleChangeAssetType} name="Appraiser" />
                            }
                            label={"Ascend ("+aircraftVals.length+")"}
                        /> */
                        }
                        </FormGroup>
                    </FormControl>
                </Grid>
                {/* Airframe */}
                <Grid item sm={2} style={styles.griditem} >
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Airframe Type</FormLabel>
                        <FormGroup>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id={`autoselect`}
                            onChange={(e, value) => {handleChangeAirframe(value)}}
                            options={[...new Set(afList.map(a => a.airframeSubSeries))].sort().filter(function(e){return e})}
                            value={airframeType}
                            getOptionLabel={(items) => items}
                            style={{
                                color: "#263238",
                                "&:focus": { backgroundColor: "transparent" },
                                width:200,
                                display:"inline-block"
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label={"Select Airframe"}
                                />
                            )}
                            disabled={assetTypeBox === -1}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                {/* Engine */}
                <Grid item sm={2} style={styles.griditem} >
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Engine Type</FormLabel>
                        <FormGroup>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id={`autoselect`}
                            onChange={(e, value) => {handleChangeEng(value)}}
                            options={[...new Set(engTypeList.map(a => a.engineSubSeries))].sort().filter(function(e){return e})}
                               // aircraftVals.map(a => a.engineSubSeries)}
                            value={engType}
                            getOptionLabel={(items) => items}
                            style={{
                                color: "#263238",
                                "&:focus": { backgroundColor: "transparent" },
                                width:200,
                                display:"inline-block"
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label={"Select Engine"}
                                />
                            )}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                {/* DOM */}
                <Grid item sm={2} style={styles.griditem} >
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">D.O.M.</FormLabel>
                        <FormGroup>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id={`autoselect`}
                            onChange={(e, value) => {handleChangeVintage(value)}}
                            options={[...new Set(domList.map(item => item.vintage))].sort().reverse().filter(function(e){return e})}
                            value={vintage}
                            getOptionLabel={(items) => items}
                            style={{
                                color: "#263238",
                                "&:focus": { backgroundColor: "transparent" },
                                width:150,
                                display:"inline-block"
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label={"Select Vintage"}
                                />
                            )}
                            disabled={assetTypeBox === -1}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                {/* Val Date */}
                <Grid item sm={2} style={styles.griditemLast} >
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Valuation Date</FormLabel>
                        <FormGroup>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id={`autoselect`}
                            onChange={(e, value) => {handleChangeValDate(value)}}
                            options={[...new Set(dateList.map(item => new Date(item.valuationDate).toLocaleDateString('en-US',
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })))].filter(function(e){return e}).sort(function(a,b) {
                                a = a.split('/');
                                a.unshift(a.pop());
                                a = a.join('');
                                //console.log(a);
                                b = b.split('/');
                                //.reverse().join('');
                                b.unshift(b.pop());
                                b = b.join('');
                                //console.log(b);
                                //return a > b ? 1 : a < b ? -1 : 0;
                                 return a.localeCompare(b);         // <-- alternative 
                              }).reverse()}
                            value={valDateFilter}
                            getOptionLabel={(items) => items}
                            style={{
                                color: "#263238",
                                "&:focus": { backgroundColor: "transparent" },
                                width:200,
                                display:"inline-block"
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label={"Select"}
                                />
                            )}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Box
                style={{
                    position: "relative",
                    borderRadius: 12,
                    backgroundColor: "#FFFFFF",
                    padding: 20,
                    height: "100%",
                    boxShadow:
                    "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
                }}      
            >
                <DataGrid
                    components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: AppNoRowsOverlay,
                    }}
                    style={{ height: "70vh" }}
                    rows={gridVals}
                    columns={columns}
                    initialState={{
                    sorting: {
                        sortModel: [{ field: "appraisalDate", sort: "desc" }],
                    },
                    }}
                />
            </Box>

        </Box>
    );
};

export default ValuationComponent;