import _ from "lodash";
import React, { useEffect, useState } from "react";

import { Box, Chip, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LoadingPanel from "../../../Common/LoadingPanel";

const useStyles = makeStyles({
  root: {
    margin: "10px 28px 0 28px",
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 10,
  },
  dealGroups: {
    minHeight: 88,
    width: 302,
    marginTop: 8,
    backgroundColor: "#F6F6F6",
  },
  dealGroupsContent: {
    padding: "13px 8px",
  },
  chip: {
    height: 22,
    minWidth: 86,
    borderRadius: 6,
    fontSize: 13,
    fontWeight: 500,
    border: " 1px solid #023059",
    backgroundColor: "#BDC7D1",
    color: "#023059",
    marginRight: 8,
  },
  chipContent: {
    paddingLeft: 2,
    paddingRight: 2,
  },
});

const AssetDealGroups = ({ assetId, assetSubSeries, asset }) => {
  const classes = useStyles("test");
  const [dealGroups, setDealGroups] = useState(null);

  useEffect(() => {
    setDealGroups(asset.dealInfo);
  }, [asset.dealInfo]);

  const handleAssetDelete = async () => {};

  const handleSaveAssetGroupChange = async () => {};

  return dealGroups ? (
    <Box className={classes.root}>
      <Typography className={classes.label} color='primary'>
        Deal Groups
      </Typography>
      {/* <Typography className={classes.subtitle} color="primary">
        Start typing group name or create a new one.
      </Typography> */}
      <Box className={classes.dealGroups}>
        <Box className={classes.dealGroupsContent}>
          <Grid container spacing={2}>
            {dealGroups.map((o, idx) => {
              const label = `${o.dealName}/${assetSubSeries.split("-")[0]}/${
                o.sponsor
              }`;
              return (
                <Grid key={idx} item sm={12} lg={12}>
                  <Chip
                    classes={{ label: classes.label }}
                    className={classes.chip}
                    label={label}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  ) : (
    <LoadingPanel />
  );
};

export default AssetDealGroups;
