import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import MultiFunctionTable from "../../../../../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import VfAlertDialogBox from "../../../../../../Common/UtilComponents/VfAlertDialogBox";
import * as constants from "../../../../../../../constants";

const ScenarioSummaries = ({ scenarioSummaries, formatData }) => {
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleSetScenarioId = (scenarioId) => {
    setDeleteId(scenarioId);
    setIsAlertDialogOpen(true);
  };

  let scenarioSummariesTable = null;
  if (scenarioSummaries) {
    scenarioSummariesTable = formatData(
      scenarioSummaries,
      constants.SCENARIO_SUMMARIES,
      handleSetScenarioId
    );
  }

  const handleDeleteScenarioSummary = () => {
    if (scenarioSummariesTable) {
      const props = scenarioSummariesTable.props;
      props.funcs.handleRowDelete(
        props.section,
        props.sectionIdx,
        constants.ScenarioSummaries,
        { id: deleteId }
      );
    }
  };

  return (
    <Grid item sm={12}>
      <VfAlertDialogBox
        isOpen={isAlertDialogOpen}
        handleAlertDialogVisible={setIsAlertDialogOpen}
        dialogTitle={"Delete Scenario"}
        dialogBody={"Are you sure you want to Delete this Scenario?"}
        onAccept={handleDeleteScenarioSummary}
      />

      <MultiFunctionTable
        tableData={scenarioSummariesTable.data.scenarioSummaries}
        containerMaxHeight={400}
      />
    </Grid>
  );
};

export default ScenarioSummaries;
