import _ from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Paper, ListItem, ListItemText } from "@material-ui/core/";
import AssetInfo from "./AssetInfoNavBar";
import AddIcon from "@material-ui/icons/Add";
import apiRoutes from "../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
import * as constants from "../../../../constants";
import SideNavBarListSection from "../../../Common/NavBar/SideNavBarListSection";
// import SingleFieldFadeInEditingPanel from "../../../Common/Editing/EditingPanels/SingleFieldFadeInEditingPanel";
import AircraftSvg from "../../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../../Common/SvgComponents/EngineSvg";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 80,
    height: 80,
    top: -20,
    right: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `#023059`,
  },
  root: {
    display: "flex",
    paddingTop: 24,
    position: "sticky",
    top: 50,
  },
  infoCard: {
    minWidth: 200,
    maxWidth: 240,
    maxHeight: 190,
    backgroundColor: "#FFFFFF",
    borderRadius: "152.73px 12px 12px 12px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    width: "100%",
    padding: "unset",
    minWidth: 200,
    maxWidth: 240,
    margin: "10px 0",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4,
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
  sectionLabel: {
    color: "#023059",
    fontWeight: 600,
    fontSize: 18,
  },
  section: {
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: "white",
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
}));

const SideNavBar = ({
  assetDetails,
  selected,
  navBarSectionItems,
  onSelectedChange,
  handleScenarioChangeEvt,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const [airlineLogo, setAirlineLogo] = useState(null);
  // const [open, setOpen] = useState(false);
  // const props = {
  //   funcs: {
  //     validateCell: (...props) => {
  //         const scenarioName = props[4];
  //         if (!scenarioName) return false;
  //         return true;
  //     },
  //     handleCellUpdate: (...props) => {
  //         const index = props[3];
  //         const scenarioName = props[4];
  //         handleScenarioChangeEvt(index, scenarioName);
  //         setOpen(false);
  //         // handleSelectedChange("Maint & Util")
  //     },
  //   },
  // };

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const result = await apiRequest(
          {
            url: apiRoutes.Blob_GetAirlineLogo({
              operator: assetDetails.operator,
            }),
            method: "GET",
          },
          msalContext
        );

        if (result.status === response.OK)
          setAirlineLogo("data:image/png;base64," + result.data);
      } catch (ex) {}
    };

    fetchLogo();
  }, [assetDetails]);

  const handleSelectedChange = (value) => {
    onSelectedChange(value);
  };

  const { aircraftSubSeries, engines, manufactureDate, msn, registration } =
    assetDetails;

  return (
    <Box id={"asset_sidebar"} mt={2} className={classes.root}>
      <Box className={classes.drawerContainer}>
        <Paper className={classes.infoCard}>
          <AssetInfo
            generalInfo={[
              {
                icon: airlineLogo ? (
                  <Avatar
                    alt={assetDetails.operator || constants.___}
                    className={classes.avatar}
                    src={airlineLogo}
                  />
                ) : aircraftSubSeries ? (
                  <AircraftSvg height={80} width={80} viewBox='0 6 24 21' />
                ) : (
                  <EngineSvg height={80} width={80} viewBox='0 0 24 24' />
                ),
                label: aircraftSubSeries
                  ? aircraftSubSeries
                  : !_.isEmpty(engines)
                  ? engines[0].engineSubSeries
                  : null,
                caption: aircraftSubSeries
                  ? !_.isEmpty(engines)
                    ? engines[0].engineSubSeries
                    : null
                  : engines[0].serialNumber,
                style: {
                  label: {
                    right: -20,
                    textAlign: "right",
                    color: "#023059",
                    fontSize: 20,
                    fontWeight: 500,
                  },
                  caption: {
                    color: "#023059",
                    marginLeft: 50,
                    fontSize: 14,
                    fontWeight: 450,
                    opacity: 0.9,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: 100,
                  },
                },
              },
            ]}
            details={
              aircraftSubSeries
                ? [
                    {
                      label: "Year",
                      value: manufactureDate
                        ? manufactureDate.split("-")[0]
                        : constants.___,
                    },
                    { label: "Serial No.", value: msn },
                    {
                      label: "Registration",
                      value: registration || constants.___,
                    },
                  ]
                : null
            }
          />
        </Paper>
        <SideNavBarListSection
          navBarSectionItems={navBarSectionItems}
          selected={selected}
          onSelectedChange={handleSelectedChange}
        />
        {navBarSectionItems.length === 1 ? (
          <ListItem
            className={classes.section}
            button
            onClick={() => handleScenarioChangeEvt(-1, "Base")}>
            <ListItemText
              primary={"Add Scenario"}
              primaryTypographyProps={{
                className: classes.sectionLabel,
              }}
            />
            <AddIcon style={{ fill: "#023059" }} />
          </ListItem>
        ) : (
          <></>
        )}

        {/* <SingleFieldFadeInEditingPanel
          open={open}
          id={-1}
          overlines={["Scenario Name"]}
          body=""
          captions={null}
          onClose={() => setOpen(false)}
          required={true}
          type={constants.TEXT_FIELD}
          props={props}
          /> */}
      </Box>
    </Box>
  );
};

export default SideNavBar;
