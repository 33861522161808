import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const getTemplate = () => {
  return {
    statusId: 0,
    isFirstRun: false,
    sinceNew: {
      flightHour: null,
      flightCycle: null,
      day: null,
      activeHour: null,
    },
    sinceEvent: {
      flightHour: null,
      flightCycle: null,
      day: null,
      activeHour: null,
    },
    statusAsOf: null,
  };
};
const uploadStatus = async (compType, compId, newdata, msalContext) => {
  let apiendpoint = apiRoutes.PostMaintenanceStatus;
  let userName = msalContext.accounts[0].name;
  apiendpoint = apiendpoint({ compType, compId, userName });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newdata,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    newdata = resp.data;
  }
};
export const updateData = (actionType, assetDetails, newData, msalContext) => {
  assetDetails.airframeChecks = [...assetDetails.airframeChecks];
  const idx = _.findIndex(
    assetDetails.airframeChecks,
    (o) => o.checkName === newData.checkName
  );
  let airframeCheck = { ...assetDetails.airframeChecks[idx] };
  let maintenanceStatus = [...airframeCheck.maintenanceStatus];
  let shopVisitHistory = _.sortBy(airframeCheck.shopVisitHistory, [
    "startDate",
  ]).slice(-1)[0];

  const targetIdx = newData.idx;

  if (actionType === constants.DELETE_ROW) {
    maintenanceStatus = maintenanceStatus.filter((o, idx) => targetIdx !== idx);
  } else {
    const newMaintenanceStatus = getTemplate();

    if (shopVisitHistory && shopVisitHistory.isOverhaul) {
      let flightHours_sinceEvent =
        parseInt(newData.flightHours_sinceNew) -
        shopVisitHistory.sinceNew.flightHour;
      let flightCycles_sinceEvent =
        parseInt(newData.flightCycles_sinceNew) -
        shopVisitHistory.sinceNew.flightCycle;
      let days_sinceEvent =
        parseInt(newData.days_sinceNew) - shopVisitHistory.sinceNew.day;
      let activeHours_sinceEvent =
        parseInt(newData.activeHours_sinceNew) -
        shopVisitHistory.sinceNew.activeHour;

      newMaintenanceStatus.sinceEvent = {
        flightHour: newData.flightHours_sinceEvent
          ? parseInt(newData.flightHours_sinceEvent)
          : flightHours_sinceEvent,
        flightCycle: newData.flightCycles_sinceEvent
          ? parseInt(newData.flightCycles_sinceEvent)
          : flightCycles_sinceEvent,
        day: newData.days_sinceEvent
          ? parseInt(newData.days_sinceEvent)
          : days_sinceEvent,
        activeHour: newData.activeHours_sinceEvent
          ? newData.activeHours_sinceEvent
          : activeHours_sinceEvent,
      };
    } else {
      newMaintenanceStatus.sinceEvent = {
        flightHour:
          newData.flightHours_sinceEvent &&
          parseInt(newData.flightHours_sinceEvent),
        flightCycle:
          newData.flightCycles_sinceEvent &&
          parseInt(newData.flightCycles_sinceEvent),
        day: newData.days_sinceEvent && parseInt(newData.days_sinceEvent),
        activeHour: newData.activeHours_sinceEvent,
      };
    }

    newMaintenanceStatus.sinceNew = {
      flightHour:
        newData.flightHours_sinceNew && parseInt(newData.flightHours_sinceNew),
      flightCycle:
        newData.flightCycles_sinceNew &&
        parseInt(newData.flightCycles_sinceNew),
      day: newData.days_sinceNew && parseInt(newData.days_sinceNew),
      activeHour: newData.activeHours_sinceNew,
    };
    // newMaintenanceStatus.isFirstRun =
    //   maintenanceStatus.length && maintenanceStatus[targetIdx]
    //     ? maintenanceStatus[targetIdx].isFirstRun
    //     : false;
    newMaintenanceStatus.statusAsOf = moment
      .utc(newData.asOfDate)
      .format(constants.ISOFormat);
    if (actionType === constants.ADD_ROW)
      maintenanceStatus.push(newMaintenanceStatus);
    else {
      maintenanceStatus[targetIdx] = { ...maintenanceStatus[targetIdx] };
      newMaintenanceStatus.statusId = maintenanceStatus[targetIdx].statusId;
      maintenanceStatus[targetIdx] = newMaintenanceStatus;
    }
  }
  uploadStatus(
    airframeCheck.componentType,
    airframeCheck.componentId,
    maintenanceStatus,
    msalContext
  );
  airframeCheck.maintenanceStatus = maintenanceStatus;
  assetDetails.airframeChecks[idx] = airframeCheck;
  return assetDetails;
};
