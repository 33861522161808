import React, { useState } from "react";
import { Box, Hidden, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataTableBody from "./DataTableBody";
import ScoreBoard from "../../ScoreBoard";
import TableNamePanel from "../../TableNamePanel";
import ThreeDotButton from "../../ThreeDotButton";
import * as constants from "../../../../constants";
import { Edit } from "@material-ui/icons";
import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel";

const useStyles = makeStyles({
  rectangle13: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 12,
    // height: "100%",
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const DataTable = ({ tableData, scoreBoard }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const marginBottom = 15;

  // Add or Edit opens up editing pop up, thus these buttons only have fixed behavior.
  let threeDotBtnOptions = [];
  if (tableData.tableThreeDotBtnOptions) {
    for (let i = 0; i < tableData.tableThreeDotBtnOptions.length; i++) {
      if (
        constants.EDIT === tableData.tableThreeDotBtnOptions[i].label ||
        tableData.tableThreeDotBtnOptions[i].label.includes("Add")
      ) {
        tableData.tableThreeDotBtnOptions[i].handleClick = () => setOpen(!open);
      }
      threeDotBtnOptions.push(tableData.tableThreeDotBtnOptions[i]);
    }
  }

  // default button, you hide it by setting showOptions = false
  if (!threeDotBtnOptions.length)
    threeDotBtnOptions.push({
      id: 0,
      icon: Edit,
      label: constants.EDIT,
      handleClick: () => setOpen(!open),
    });

  return tableData.data && tableData.data.length === 0 ? null : (
    <Box id='datatable' style={{ minWidth: "100%"}}>
      <Paper className={classes.rectangle13} style={{ ...tableData.style }}>
        {tableData.label && (
          <Box style={{ marginBottom }}>
            <TableNamePanel
              label={tableData.label}
              caption={tableData.caption}
              buttons={
                tableData.showOptions
                  ? [<ThreeDotButton options={threeDotBtnOptions} />]
                  : null
              }
              data={tableData.data}
            />
          </Box>
        )}

        <Box style={{ marginBottom, overflowX: "hidden", overflowY: tableData.scrollable ? "scroll" : "hidden", maxHeight: tableData.scrollable ? 237 : ""}}>
          {
            <DataTableBody
              classes={classes}
              columns={tableData.columnGroups || tableData.columns}
              data={tableData.dataGroups || tableData.data}
              onOptionOpen={() => setOpen(!open)}
              modalProps={{
                tableName: tableData.tableName,
                props: tableData.props,
                errorsOnSubmit: tableData.errorsOnSubmit,
              }}
            />
          }
        </Box>

        {scoreBoard && (
          <ScoreBoard scores={scoreBoard.scores} title={scoreBoard.title} />
        )}
        <MultiFieldsFadeInEditingPanel
          open={open}
          columns={tableData.columns}
          data={tableData.data}
          tableName={tableData.tableName}
          errorsOnSubmit={tableData.errorsOnSubmit}
          props={tableData.props}
          onClose={() => setOpen(false)}
        />
      </Paper>
    </Box>
  );
};

export default DataTable;
