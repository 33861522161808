import React, { useEffect, useState, createContext } from "react";
import { useMsal } from "@azure/msal-react";
import apiRoutes from "../utils/ApiRoutes";
import { apiRequest, response } from "../utils/ApiRequest";

const DealRelatedDataContext = createContext(undefined);

const DealRelatedDataContextProvider = ({ children }) => {
  const [assetPoolData, setAssetPoolData] = useState(null);
  const [pipelineDealsData, setPipelineDealsData] = useState(null);
  const msalContext = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const assetpool = await apiRequest(
        {
          url: apiRoutes.AssetPool_GetAll,
          method: "GET",
        },
        msalContext
      );

      if (assetpool && assetpool.status === response.OK) {
        //console.log(assetpool.data);
        setAssetPoolData(assetpool.data);
      }

      const pipelineDeals = await apiRequest(
        {
          url: apiRoutes.VectorDeal_GetPipelineDeals,
          method: "GET",
        },
        msalContext
      );

      if (pipelineDeals && pipelineDeals.status === response.OK) {
        setPipelineDealsData(pipelineDeals.data);
      }
    };
    fetchData();
  }, [msalContext]);

  return (
    <DealRelatedDataContext.Provider
      value={{
        assetPoolData: assetPoolData,
        pipelineDealsData: pipelineDealsData,
      }}>
      {children}
    </DealRelatedDataContext.Provider>
  );
};

export { DealRelatedDataContextProvider, DealRelatedDataContext };
