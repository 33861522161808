import _ from "lodash";
import axios from "axios";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { apiRequest, response } from "../../utils/ApiRequest";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import apiRoutes from "../../utils/ApiRoutes";
import * as utils from "../../utils/utils";
import * as constants from "../../constants";
import * as stateUpdater from "./stateUpdate";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import VfSnackerBar from "../Common/UtilComponents/VfSnackerBar";
import VfAlertDialogBox from "../Common/UtilComponents/VfAlertDialogBox";
import LoadingPanel from "../Common/LoadingPanel";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  InputAdornment,
  Tooltip,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import GenericAirframe from "./Components/GenericAirframe";
import GenericEngine from "./Components/GenericEngine";
import GenericAPU from "./Components/GenericAPU";
import GenericCombinations from "./Components/GenericCombinations";
import SectionHeader from "../Common/Section/SectionHeader";
import { MsalContext } from "@azure/msal-react";

const styles = {
  root: {
    // paddingTop: 50,
    paddingBottom: 10,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

class GenericAssumpComponent extends Component {
  static contextType = MsalContext;

  genericAssumpTokenSource = null;
  constructor(props) {
    super(props);
    this.state = {
      genericData: null,
      tabValue: 0,
      snackOpen: false,
      severity: "info",
      message: "",
      urlValues: null,
      isAlertDialogOpen: false,
      isUploadDialogOpen: false,
      exportFileName: "",
      engineSubseries: null,
      uploadType: "Engine PR",
    };
  }

  generateEditableProps = (idx, section) => ({
    sectionIdx: idx,
    section,
    funcs: {
      handleValuesUpdate: this.handleValueChange(constants.UPDATE_ROW),
      handleRowAdd: this.handleValueChange(constants.ADD_ROW),
      handleColumnAdd: this.handleValueChange(constants.ADD_COLUMN),
      handleRowDelete: this.handleValueChange(constants.DELETE_ROW),
      validateData: this.validateData,
      // allow for custom props in child components
      handleValueChange: this.handleValueChange,
    },
  });

  getGenericAssumptions = async () => {
    let apiEndPoint = apiRoutes.VfVector_GenericPageInfo;

    this.genericAssumpTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.genericAssumpTokenSource.token,
      },
      this.context
    );
  };

  componentDidMount = async () => {
    const urlValues = utils.parseUrlObject(new URL(window.location.href));

    let genericData = await this.getGenericAssumptions();
    if (genericData.status === response.OK) {
      this.setState({
        genericData: genericData.data,
        urlValues: urlValues,
        tabValue: urlValues.tab ? parseInt(urlValues.tab) : 0,
      });
    }
  };

  // componentWillUnmount = () => {
  // };

  validateData = (newData, columns, section, tableName, actionType) => {
    // console.log(newData, columns, section, tableName, actionType)
    const genericData = { ...this.state.genericData };
    let allValid = true;
    let errorsOnSubmit = {};

    // check if combination of ac and apu/engine already exists
    if (section === "Combinations") {
      if (tableName === "Aircraft APU Combinations") {
        let acapuCombos = genericData.acApuCombos;
        let checkExistingCombos = _.find(acapuCombos, function (o) {
          return (
            o.aircraftSubseries === newData.aircraftSubseries &&
            o.apuModel === newData.apuModel
          );
        });
        if (!_.isEmpty(checkExistingCombos)) {
          this.handleSnackerBarOpen(
            true,
            "error",
            "This combination of Aircraft and APU already exists"
          );
          return false;
        }
      } else if (tableName === "Aircraft Engine Combinations") {
        let acengCombos = genericData.acEngCombos;
        let checkExistingCombos = _.find(acengCombos, function (o) {
          return (
            o.aircraftSubseries === newData.aircraftSubseries &&
            o.engineSubseries === newData.engineSubseries
          );
        });
        if (!_.isEmpty(checkExistingCombos)) {
          this.handleSnackerBarOpen(
            true,
            "error",
            "This combination of Aircraft and Engine already exists"
          );
          return false;
        }
      }
    }
    return allValid;
  };

  handleValueChange = (action) => (section, sectionIdx, tableName, newData) => {
    const genericData = { ...this.state.genericData };
    // console.log(section, sectionIdx, tableName, newData, genericData)
    stateUpdater.updateTable(
      tableName,
      action,
      section,
      sectionIdx,
      newData,
      genericData,
      this.context
    );
    this.setState({ genericData: genericData });
  };

  handleEngSubseriesChange = (engineSubseries) => {
    this.setState({ engineSubseries: engineSubseries });
  };

  handleTabChange = (event, newValue) => {
    let newUrl = `?tab=${newValue}`;
    window.history.pushState({}, null, newUrl);
    this.setState({ tabValue: newValue });
  };

  handleSnackerBarOpen = (open, severity, msg) => {
    this.setState({ snackOpen: open, severity: severity, message: msg });
  };

  handleExportFileNameChange = () => (event) => {
    this.setState({ exportFileName: event.target.value });
  };

  handleUploadFileChange = () => (event) => {
    this.setState({ uploadType: event.target.value });
  };

  handleExportButtonClick = async () => {
    const { engineSubseries } = this.state;
    this.setState({ exportFileName: "Generic_Engine_" + engineSubseries });
    this.handleAlertDialogVisible(true);
  };

  handleUploadButtonClick = async () => {
    this.handleUploadDialogVisible(true);
  };

  handleAlertDialogVisible = async (open) => {
    this.setState({ isAlertDialogOpen: open });
  };

  handleUploadDialogVisible = async (open) => {
    this.setState({ isUploadDialogOpen: open });
  };

  exportFile = async () => {
    const { engineSubseries, exportFileName } = this.state;
    let exportFileObj = {
      userName: this.context.accounts[0].username,
      fileName: exportFileName + ".xlsx",
      fileFormat: "Excel",
      engineSubseries: engineSubseries,
    };
    const resp = await apiRequest(
      {
        url: apiRoutes.VectorDeal_ExportGenericValues,
        method: "POST",
        data: exportFileObj,
      },
      this.context
    );
    if (resp.status === response.OK) {
      this.handleSnackerBarOpen(
        true,
        "success",
        "Generic Engine File Generated Successfully !!"
      );
      this.handleAlertDialogVisible(false);
    } else {
      this.handleSnackerBarOpen(true, "error", "Error Generic Engine File !!");
    }
  };

  uploadGenericFile = async (file) => {
    const { uploadType } = this.state;
    var username = this.context.accounts[0].name;
    let GenericData = new FormData();

    GenericData.append("fileName", file.name);
    GenericData.append("fileFormat", "Excel");
    GenericData.append("fileData", file);

    const resp = await apiRequest(
      {
        url: apiRoutes.VfVector_GenericFileUpload,
        method: "POST",
        data: GenericData,
        params: {
          user: username,
          filetype: uploadType,
        },
      },
      this.context
    );
    if (resp.status === response.OK) {
      this.handleSnackerBarOpen(
        true,
        "success",
        "Generic File Uploaded Successfully"
      );
      this.handleAlertDialogVisible(false);
      setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    } else {
      this.handleSnackerBarOpen(true, "error", "Error Uploading Generic File");
    }
  };

  renderAlertDialogBody = (exportFileName) => {
    return (
      <TextField
        id='filled-basic'
        label='Export File Name'
        variant='filled'
        defaultValue={exportFileName}
        onBlur={this.handleExportFileNameChange()}
        style={{ width: "100%" }}
        InputProps={{
          endAdornment: <InputAdornment position='start'>.xlsx</InputAdornment>,
        }}
      />
    );
  };

  renderUploadDialogBody = () => {
    return (
      <Box>
        <Select
          labelId='filetype'
          id='filetype'
          style={{ width: "50%" }}
          value={this.state.uploadType}
          onChange={this.handleUploadFileChange()}>
          <MenuItem value={"Engine PR"}>Engine PR</MenuItem>
          <MenuItem value={"LLP Stack"}>LLP Stack</MenuItem>
          <MenuItem value={"APU"}>APU</MenuItem>
          <MenuItem value={"Airframe"}>Airframe</MenuItem>
        </Select>
        <input
          type='file'
          accept='.xlsm, .xlsx'
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) this.uploadGenericFile(file);
          }}
          hidden
          id='upload-btn'
        />

        <Tooltip title='Upload ' aria-label='Upload Liability'>
          <Button
            style={{ maxHeight: 28, marginLeft: 16 }}
            variant='outlined'
            color='primary'>
            <label
              htmlFor='upload-btn'
              style={{ width: "100%", height: "100%" }}>
              <span style={{ margin: "0px 8px 0px 8px" }}>Choose File</span>
            </label>
          </Button>
        </Tooltip>
      </Box>
    );
  };

  renderExportButton = () => {
    return (
      <Tooltip title='Export Generic Engine Values' aria-label='Export Generic'>
        <Button
          style={{ maxHeight: 28, margin: "16px 0px 0px 16px" }}
          variant='outlined'
          color='primary'
          onClick={() => this.handleExportButtonClick()}>
          <span style={{ margin: "0px 8px 0px 8px" }}>Export</span>
        </Button>
      </Tooltip>
    );
  };

  renderBreadCrumbs = () => {
    return [
      {
        label: "Generic Values",
        type: constants.TEXT,
        tooltip: "Generic Assumption Values",
      },
    ];
  };

  render() {
    const { classes } = this.props;
    const {
      genericData,
      tabValue,
      urlValues,
      snackOpen,
      severity,
      message,
      isAlertDialogOpen,
      isUploadDialogOpen,
      exportFileName,
    } = this.state;

    return genericData ? (
      <ErrorBoundary>
        <VfBreadcrumbContainer
          breadcrumb={<VfBreadcrumbs breadcrumbs={this.renderBreadCrumbs()} />}
          button={null}
        />
        <VfSnackerBar
          snackOpen={snackOpen}
          setsnackOpen={this.handleSnackerBarOpen}
          duration={2000}
          severity={severity}
          message={message}
        />
        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={this.handleAlertDialogVisible}
          dialogTitle={"Export Generic Engine Values"}
          dialogBody={this.renderAlertDialogBody(exportFileName)}
          onAccept={this.exportFile}
        />

        <VfAlertDialogBox
          isOpen={isUploadDialogOpen}
          handleAlertDialogVisible={this.handleUploadDialogVisible}
          dialogTitle={"Upload Generic Values"}
          dialogBody={this.renderUploadDialogBody()}
          onAccept={this.exportFile}
        />

        <Box style={{ display: "flex", margin: "48px 0 8px 0" }}>
          <SectionHeader label={"Generic Values"} />
          <Box>
            <Tooltip
              title='Upload Generic Spreadsheet'
              aria-label='Upload Generic'>
              <Button
                style={{
                  maxHeight: 28,
                  margin: "16px 0px 0px 0px",
                  marginLeft: "18px",
                }}
                variant='outlined'
                color='primary'
                onClick={() => this.handleUploadButtonClick()}>
                <span style={{ margin: "0px 8px 0px 8px" }}>Upload</span>
              </Button>
            </Tooltip>
          </Box>
          <Box>{tabValue === 1 ? this.renderExportButton : <></>}</Box>
        </Box>

        <Box className={classes.root}>
          <Tabs
            value={tabValue}
            onChange={this.handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            justify='end'
            centered={true}
            variant='fullWidth'
            style={{ minWidth: "100%" }}>
            <Tab key='airframes' label='AIRFRAME' />
            <Tab key='engines' label='ENGINE' />
            <Tab key='apUs' label='APU' />
            <Tab key='combinations' label='COMBINATIONS' />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <GenericAirframe
              genericData={genericData}
              generateEditableProps={this.generateEditableProps}
              urlValues={urlValues}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <GenericEngine
              genericData={genericData}
              generateEditableProps={this.generateEditableProps}
              handleEngSubseriesChange={this.handleEngSubseriesChange}
              urlValues={urlValues}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <GenericAPU
              genericData={genericData}
              generateEditableProps={this.generateEditableProps}
              urlValues={urlValues}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <GenericCombinations
              genericData={genericData}
              generateEditableProps={this.generateEditableProps}
            />
          </TabPanel>
        </Box>
      </ErrorBoundary>
    ) : (
      <LoadingPanel />
    );
  }
}

export default withStyles(styles)(GenericAssumpComponent);
