import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import DataTable from "../../../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../../../Common/Tables/EditableTable/EditableTable";
import * as AssetDetailViewModel from "../../../../mapToViewModels";
import * as constants from "../../../../../../constants";
import { apiRequest, response } from "../../../../../../utils/ApiRequest";
import apiRoutes from "../../../../../../utils/ApiRoutes";
import { useMsal } from "@azure/msal-react";

const formatData = (assetDetails, scenario, generateEditableProps, ap) => {
  const componentType = constants.GENERAL_INFO;
  let props = generateEditableProps(null, componentType);
  return {
    data: AssetDetailViewModel.mapToViewModel(
      assetDetails,
      componentType,
      props,
      scenario,
      ap
    ),
    componentType,
  };
};

const Airframe = ({ assetDetails, scenario, generateEditableProps }) => {
  const msalContext = useMsal();
  const [AFCs, setAFCs] = useState(null);
  const [AvEngs, setAvEngs] = useState(null);
  useEffect(() => {
    const getAFCs = async () => {
      let apiEndPoint = apiRoutes.GenericAFC_GetACAFCs;
      let genericId = assetDetails.genericID;
      apiEndPoint = apiEndPoint({
        ACId : genericId
      });
      let afcs = await apiRequest(
        {
          url: apiEndPoint,
          method: "GET",
        },
        msalContext
      );
      if (afcs.status === response.OK) setAFCs(afcs.data);
    };
    const getEngs = async () => {
      let apiEndPoint = apiRoutes.Engine_AvailableEngines;
      let genericId = assetDetails.genericID;
      apiEndPoint = apiEndPoint({
        GenAcId : genericId
      });
      let engresp = await apiRequest(
        {
          url: apiEndPoint,
          method: "GET",
        },
        msalContext
      );
      if (engresp.status === response.OK) setAvEngs(engresp.data);
    };
    getAFCs();
    getEngs();
    // console.log(AvEngs);
  
    }, []);
  let Additionalprops = { AFCs: AFCs, Engs: AvEngs}; 
  const assetInfo = formatData(assetDetails, scenario, generateEditableProps,Additionalprops);
  //console.log(assetInfo.data.airframeDetails);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DataTable tableData={assetInfo.data.airframeDetails} />
        </Grid>
        <Grid item sm={12}>
          <EditableTable table={assetInfo.data.checkStatus} />
        </Grid>
        <Grid item sm={12}>
          <EditableTable table={assetInfo.data.checkHistory} />
        </Grid>
        <Grid item sm={12}>
          <EditableTable table={assetInfo.data.scheduledShopVisit} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Airframe;
