import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import DataTableCell from "./DataTableCell";
import * as constants from "../../../../constants";

const DataTableBody = ({ columns, data, modalProps, onOptionOpen }) => {
  return (
    <Grid container spacing={3}>
      {columns &&
        columns.length !== 0 &&
        columns.map((c, index) =>
          Array.isArray(c) ? (
            c.map((e) =>
              !e.hidden ? (
                <Grid key={index} xs={e.span[0]} sm={e.span[1]} item>
                  <DataTableCell
                    overlines={e.label}
                    body={data[index][e.field]}
                    captions={e.captions}
                    content={e.content}
                    modalProps={{
                      columns: c,
                      data: data[index],
                      ...modalProps,
                    }}
                    onOptionOpen={onOptionOpen}
                    showCellOptions={e.showCellOptions}
                    cellOptions={e.cellOptions}
                    cellOptionsFuncs={e.cellOptionsFuncs}
                  />
                </Grid>
              ) : null
            )
          ) : c.field === "cashReservetotal" ? (
            <Grid
              key={index}
              xs={c.span[0]}
              sm={c.span[1]}
              item
              style={c.blockStyle ? c.blockStyle : null}>
              <Divider />
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  color: "#023059",
                  margin: "4px 0 0 0",
                }}>
                <Typography
                  variant='subtitle1'
                  style={c.labelStyle ? c.labelStyle : null}>
                  {c.label ? c.label : constants.___}
                </Typography>
                <Typography
                  style={c.captionStyle ? c.captionStyle : null}
                  variant='caption'
                  display='block'>{`(${c.captions})`}</Typography>
                <Typography variant='subtitle1'>
                  {c.content
                    ? c.content(data[c.field])
                    : data[c.field] || constants.___}
                </Typography>
              </span>
            </Grid>
          ) : !c.hidden && !c.hiddenData ? (
            <Grid key={index} xs={c.span[0]} sm={c.span[1]} item>
              <DataTableCell
                overlines={c.label}
                body={data[c.field]}
                captions={c.captions}
                content={c.content}
                modalProps={{
                  columns: [c],
                  data: data,
                  ...modalProps,
                }}
                onOptionOpen={onOptionOpen}
                showCellOptions={c.showCellOptions}
              />
            </Grid>
          ) : null
        )}
    </Grid>
  );
};

export default DataTableBody;
