import _ from "lodash";
import React, { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import { Edit, Visibility } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../../../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../../../../Common/Tables/EditableTable/EditableTable";
import TableNamePanel from "../../../../../../Common/TableNamePanel";
import ThreeDotButton from "../../../../../../Common/ThreeDotButton";
import * as constants from "../../../../../../../constants";

const useStyles = makeStyles({
  rectangle13: {
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
    padding: "16px 16px 8px 16px",
    borderRadius: 12,
  },
});

// idx: locate where the current utilization is in the array
const Utilization = ({ components, idx, utilization, utilizationProps }) => {
  const classes = useStyles();
  const [isEditingUtilization, setIsEditingUtilization] = useState(true);

  const renderTable = (data, span = [], setDisplayStlye = true) => {
    const display = { display: "grid" };

    const table =
      data.tableType === constants.DATA_TABLE ? (
        <DataTable tableData={data} />
      ) : (
        <EditableTable
          table={data}
          isEditingSection={isEditingUtilization}
        />
      );
    return (
      <Grid
        style={setDisplayStlye ? display : {}}
        item
        sm={span[0]}
        lg={span[1]}
      >
        {table}
      </Grid>
    );
  };

  // The followings are for section editing.
  // Add_ROW: Add a row to the utilization table.
  // Edit: This is for editing Components table.
  // Edit (cont.): This function will be envoked in the DataTable component (setOpen).
  // Delete: Delete the entire section.
  const threeDotBtnOptions = _.compact([
    {
      id: 0,
      label: !isEditingUtilization ? constants.VIEW : constants.EDIT,
      icon: !isEditingUtilization ? Visibility : Edit,
      handleClick: () => setIsEditingUtilization(!isEditingUtilization),
    },
    !isEditingUtilization ? 
    {...utilizationProps.ADD_ROW, handleClick: () => utilizationProps.ADD_ROW.handleClick(idx)} : null,
    !isEditingUtilization ? 
    {...utilizationProps.DELETE, handleClick: () => utilizationProps.DELETE.handleClick(idx)} : null,
  ]);

  return (
    <Box flex={1} mb={4} ml={1} mr={1} className={classes.rectangle13}>
      <Box sm={2}>
        <TableNamePanel
          label={`Utilization ${idx + 1}`}
          buttons={[
            <ThreeDotButton fontSize={"medium"} options={threeDotBtnOptions} />,
          ]}
        />
      </Box>
      <Box>
        <Grid container spacing={2}>
          {renderTable(utilization, [8, 8])}
          {renderTable(components, [4, 4])}
        </Grid>
      </Box>
    </Box>
  );
};

export default Utilization;
