import _, { constant } from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

export const updateData = async (
  actionType,
  assetDetails,
  newData,
  msalContext
) => {
  const uploadStatus = async (engineId, status, msalContext) => {
    let apiendpoint = apiRoutes.PostLLPStatus;
    let userName = msalContext.accounts[0].name;
    apiendpoint = apiendpoint({ engineId, userName });
    let resp = await apiRequest(
      {
        url: apiendpoint,
        method: "POST",
        data: status,
      },
      msalContext
    );
    if (resp.status === response.OK) {
      status = resp.data;
    }
  };

  const deleteStatus = async (engineId, statusDate, msalContext) => {
    let apiendpoint = apiRoutes.DeleteLLPStatus;
    apiendpoint = apiendpoint({ engineId, statusDate });
    let resp = await apiRequest(
      {
        url: apiendpoint,
        method: "DELETE",
        headers: { "Content-Type": "text/json" },
      },
      msalContext
    );
    if (resp.status === response.OK) {
      statusDate = resp.data;
    }
  };

  assetDetails.engines = [...assetDetails.engines];
  let idx = null;
  if (actionType === constants.UPDATE_ROW) {
    idx = _.findIndex(
      assetDetails.engines,
      (o) =>
        o.serialNumber === newData[0].serialNumber &&
        o.position === newData[0].position &&
        o.engineSubSeries === newData[0].engineSubSeries
    );
  } else {
    idx = _.findIndex(
      assetDetails.engines,
      (o) =>
        o.serialNumber === newData.serialNumber &&
        o.position === newData.position &&
        o.engineSubSeries === newData.engineSubSeries
    );
  }

  assetDetails.engines[idx] = {
    ...assetDetails.engines[idx],
  };

  let llpStack = { ...assetDetails.engines[idx].llpStack };

  let llps = [...llpStack.llPs];

  if (actionType === constants.DELETE_ROW) {
    for (let i = 0; i < llps.length; i++) {
      let status = [...llps[i].status];
      llps[i].status = status.filter(
        (o) => moment.utc(o.statusDate).diff(newData.statusDate, "days") !== 0
      );
    }
    await deleteStatus(
      llpStack.localId,
      moment.utc(newData.statusDate).format("YYYY-MM-DD"),
      msalContext
    );
  } else if (actionType === constants.ADD_ROW) {
    for (let i = 0; i < llps.length; i++) {
      const statusTemplate = {
        statusId: 0,
        statusDate: null,
        isReplacementDate: false,
        cyclesBefore: null,
        cyclesAfter: null,
      };

      const llpCopy = llps[i];
      const status = [...llpCopy.status];
      statusTemplate.statusDate = newData.statusDate;
      status.push(statusTemplate);
      llpCopy.status = status;
      llps[i] = llpCopy;
    }
  } else {
    let llpEdits = newData.map((data) => {
      let selectedLLP = llps[data.id];
      let llpDate = data.statusDate;
      let statusIdx = _.findIndex(
        selectedLLP.status,
        (o) =>
          moment.utc(o.statusDate).format(constants.DATE_FORMAT) ===
          moment.utc(llpDate).format(constants.DATE_FORMAT)
      );

      if (data.isReplacement || data.isReplacement === "true") {
        selectedLLP.status[statusIdx].cyclesAfter = parseFloat(
          data.cyclesAfter
        );
        selectedLLP.status[statusIdx].isReplacementDate = data.isReplacement;
      } else {
        selectedLLP.status[statusIdx].cyclesAfter = parseFloat(
          data.cyclesBefore
        );
        selectedLLP.status[statusIdx].isReplacementDate = data.isReplacement;
      }

      selectedLLP.status[statusIdx].cyclesBefore = parseFloat(
        data.cyclesBefore
      );
      selectedLLP.status[statusIdx].statusDate = llpDate;

      return {
        // stackId: llpStack.localId,
        genllpId: selectedLLP.genericID,
        status: selectedLLP.status[statusIdx],
      };
    });
    await uploadStatus(llpStack.localId, llpEdits, msalContext);
  }

  llpStack.llPs = llps;
  assetDetails.engines[idx].llpStack = llpStack;

  return assetDetails;
};
