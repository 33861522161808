import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import DataTable from "../../Common/Tables/DataTable/DataTable";
import * as constants from "../../../constants";
import { mapToViewModel } from "../mapToViewModels/index"

const formatData = (genericData, generateEditableProps, handleSelectChange, selectedModel) => {
  const componentType = constants.apUs;
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModel(
      genericData,
      componentType,
      handleSelectChange,
      props,
      selectedModel,
    ),
    componentType,
  };
};

const GenericAPU = ({ genericData, generateEditableProps, urlValues }) => {
  const sortedAPUData = _.sortBy(genericData.apUs, [function(o) { return o.model; }]);
  const [selectedModel, setSelectedModel] = useState(sortedAPUData[0].model)

  useEffect(() => {
    if (urlValues.apuSelect) setSelectedModel( decodeURI(urlValues.apuSelect) )
  }, [urlValues.apuSelect]);

  const handleSelectChange = (type, value) => {
    let newUrl = `?tab=2&apuSelect=${value}`
    window.history.pushState({}, null, newUrl);
    setSelectedModel(value)
  }

  const apuData = formatData(sortedAPUData, generateEditableProps, handleSelectChange, selectedModel);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DataTable tableData={apuData.data.apuDetails} />
        </Grid>
        <Grid item sm={12}>
          <DataTable tableData={apuData.data.perRestAssump} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenericAPU;
