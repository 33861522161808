import React, { useState } from "react";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ThreeDotButton from "../../../Common/ThreeDotButton";
import * as constants from "../../../../constants";
import AircraftSvg from "../../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../../Common/SvgComponents/EngineSvg";
import { mapToViewModel_assetPoolAsset } from "../../mapToViewModels/assetPoolAssetViewModel";
import { mapToViewModel_assetPoolExit } from "../../mapToViewModels/assetPoolExitViewModel";
import MultiFieldsFadeInEditingPanel from "../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import VfAlertDialogBox from "../../../Common/UtilComponents/VfAlertDialogBox";

const useStyles = makeStyles((theme) => ({
  avatar: { width: 20, height: 20 },
  root: {
    height: 68,
    width: "100%",
    backgroundColor: `${theme.palette.primary.background.main}`,
    borderRadius: "4px",
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    margin: "auto",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.background.hover}`,
    },
  },
  content: {
    textAlign: "left",
    padding: "2px 5px",
  },
  subtitle1: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
  body2: {
    fontSize: 14,
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
  caption: {
    fontSize: 12,
    letterSpacing: "0.4px",
    width: "50%",
    display: "inline-block",
  },
  captionLeft: {
    textAlign: "left",
  },
  captionRight: {
    textAlign: "right",
  },
  verticalAlignMiddle: {
    verticalAlign: "middle",
  },
  icon: { marginRight: 10 },
  operator: { marginTop: 2, marginBottom: 2 },
  threeDotButton: { display: "inline-flex", float: "right" },
}));

const renderLink = (assetPool, asset, assetPoolAssets, children) => {
  return (
    <Link
      style={{ color: "#355A79", textDecoration: "none", display: "contents" }}
      to={{
        pathname: `/assetPool/assetModel/${asset.assetId}?poolId=${assetPool.assetPoolId}&poolName=${assetPool.assetPoolName}&assetType=${asset.assetType}&serialNumber=${asset.serialNumber}`,
        state: { assetPool, assets: assetPoolAssets, assetId: asset.assetId },
      }}>
      {children}
    </Link>
  );
};

const renderAssetInfo = (classes, icon, asset) => {
  return (
    <>
      <span className={classes.icon}>{icon}</span>
      <span className={classes.verticalAlignMiddle}>{asset.subseries}</span>
    </>
  );
};
const renderOperatorInfo = (classes, asset, airlineLogo) => {
  return (
    <Grid container className={classes.operator}>
      <Grid item sm={2}>
        <Avatar
          className={classes.avatar}
          alt={asset.operator}
          src={airlineLogo && airlineLogo.path}
        />
      </Grid>
      <Grid item sm={10}>
        <Typography variant='body2' color='secondary' className={classes.body2}>
          {asset.operator || constants.NA}
        </Typography>
      </Grid>
    </Grid>
  );
};

const renderRegistrationInfo = (classes, asset) => {
  let caption = "";
  if(asset.exitDate == null){
    caption = new Date(asset.enterDate).toLocaleDateString('en-US') +" - ∞ ";
  }
  if (asset.exitDate !== null) {
    let td = new Date(asset.exitDate).getFullYear();
    if(td >= 9999){
      caption = new Date(asset.enterDate).toLocaleDateString('en-US') +" - ∞ ";
    }
    else {
      caption = new Date(asset.enterDate).toLocaleDateString('en-US') +" - "+ new Date(asset.exitDate).toLocaleDateString('en-US');
    }
    if(new Date(asset.enterDate) > new Date(asset.exitDate)){
      caption = "Exited: "+new Date(asset.exitDate).toLocaleDateString('en-US');
    }
  }
  return (
    <>
      <Typography variant='caption' color='secondary'>
        <span className={`${classes.caption} ${classes.captionLeft}`}>
          {asset.serialNumber}
        </span>
      </Typography>
      <Typography variant='caption' color='secondary'>
        <span className={`${classes.caption} ${classes.captionRight}`}>
          {/* moment.utc(c.dateChanged).format("MMM-DD-YY, h:mm a") */}
          { caption }
        </span>
      </Typography>
    </>
  );
};

const Asset = ({ user, asset, assetPool, assetPoolAssets, airlineLogo,deleteAssetfcn,editAssetfcn,status,exitAssetfcn }) => {
  const classes = useStyles();
  const [openExit, setOpenExit] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  
  const handleAssetExit = async (...args) => {
    try {
      let { asset, exitDate, exitReason, comment} = args[3];
      let newdata = {
        exitDate:exitDate,
        exitReason:exitReason,
        comment:comment
      }
      await exitAssetfcn(asset, newdata);
    } catch (ex) {}
  };
  const exitprops = {
    funcs: { handleValuesUpdate: handleAssetExit, validateData: () => true },
  };
  const handleAssetEdit = async (...args) => {
    try {
      let { asset,enterDate, exitDate, comment} = args[3];
      let newdata = {
        enterDate:enterDate,
        exitDate:exitDate,
        comment:comment
      }
      if(asset.enterDate === enterDate && asset.exitDate === exitDate && asset.comment === comment){
        console.log("no changes");
        return;
      }
      await editAssetfcn(asset, newdata);
    } catch (ex) {}
  };
  let exitAsset = mapToViewModel_assetPoolExit(asset);
  const editprops = {
    funcs: { handleValuesUpdate: handleAssetEdit, validateData: () => true },
  };
  let editAsset = mapToViewModel_assetPoolAsset(asset);
  const handleRecordDelete = async () =>{
    await deleteAssetfcn(asset);
    setOpenAlert(false);
  }

  const icon =
    asset.assetType.toLowerCase() === constants.AIRCRAFT ? (
      <AircraftSvg
        height={20}
        width={20}
        viewBox={"0 0 25 25"}
        style={{ verticalAlign: "middle" }}
      />
    ) : (
      <EngineSvg
        height={20}
        width={20}
        viewBox={"0 0 25 25"}
        style={{ verticalAlign: "middle" }}
      />
    );

  const threeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
      handleClick: () => {setOpenEdit(true)},
    },
    {
      id: 1,
      icon: DeleteOutline,
      label: constants.DELETE + " Record",
      handleClick: () =>{ setOpenAlert(true)},
    }
  ];
  if(status === "current"){
    threeDotBtnOptions.push(
      {
        id: 2,
        icon: DeleteOutline,
        label: "Exit Pool",
        handleClick: () =>{ setOpenExit(true)},
      }
    )
  }


  return (
    <Box
      className={classes.root}
      // onMouseLeave={() => setShowThreeDotBtn(false)}
      // onMouseOver={() => setShowThreeDotBtn(true)}
    >
      {/* {openAlert ? ( */}
      <VfAlertDialogBox
        isOpen={openAlert}
        handleAlertDialogVisible={setOpenAlert}
        dialogTitle={"Confirm Asset Record"}
        dialogBody={"Are you sure you want to delete this record?"}
        onAccept={handleRecordDelete}
       />
        {/* ) : <></>
         } */}
      <Box className={classes.content}>
        <Grid container>
          <Grid item sm={12}>
            <Typography
              variant='subtitle1'
              color='primary'
              className={classes.subtitle1}>
              {renderLink(
                assetPool,
                asset,
                assetPoolAssets,
                renderAssetInfo(classes, icon, asset)
              )}

              <Box className={classes.threeDotButton}>
                {/* {showThreeDotBtn ? ( */}
                <ThreeDotButton
                  options={threeDotBtnOptions}
                  fontSize='medium'
                  padding={0}
                />
                {/* ) : null} */}
              </Box>
            </Typography>
          </Grid>
          {renderLink(
            assetPool,
            asset,
            assetPoolAssets,
            <>
              <Grid item sm={12}>
                {renderOperatorInfo(classes, asset, airlineLogo)}
              </Grid>
              <Grid item sm={12}>
                {renderRegistrationInfo(classes, asset)}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <MultiFieldsFadeInEditingPanel
          open={openEdit}
          columns={editAsset.columns}
          data={editAsset.data}
          props={editprops}
          onClose={() => setOpenEdit(false)}
      />
      <MultiFieldsFadeInEditingPanel
          open={openExit}
          columns={exitAsset.columns}
          data={exitAsset.data}
          props={exitprops}
          onClose={() => setOpenExit(false)}
      />


    </Box>
  );
};

export default Asset;
