import _ from "lodash";
import * as constants from "../../../../constants";
import moment from "moment";

export const updatePartoutEvents = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
) => {
  const parameters = scenario.parameters;

  if(actionType === constants.ADD_ROW){
    parameters.partoutEvent = {
        partoutDate: newData.partoutEventDate,
        components: []
    };
    //console.log(assetDetails);
    parameters.partoutEvent.components.push(
        {
            component:"Airframe",
            componentSN:assetDetails.msn,
            isSold: false,
            saleAmount: 0,
            saleDate: moment.utc().format("YYYY-MM-DD")
        }
    );
  //  assetDetails.landingGears.forEach(l => {
  //   parameters.partoutEvent.components.push(
  //     {
  //         component: "LG - " + l.position,
  //         componentSN: l.serialNumber,
  //         isSold: false,
  //         saleAmount: 0,
  //         saleDate: moment.utc().format("YYYY-MM-DD")
  //     }
  //   );
  //  });
  //  assetDetails.apUs.forEach(a => {
  //   parameters.partoutEvent.components.push(
  //     {
  //         component: a.componentType,
  //         componentSN: a.serialNumber,
  //         isSold: false,
  //         saleAmount: 0,
  //         saleDate: moment.utc().format("YYYY-MM-DD")
  //     }
  //   );
  //  });
    assetDetails.engines.forEach(e => {
      parameters.partoutEvent.components.push(
          {
              component: e.position,
              componentSN:e.serialNumber,
              isSold: false,
              saleAmount: 0,
              saleDate: moment.utc().format("YYYY-MM-DD")
          }
      );
    });

  }
  if (actionType === constants.UPDATE_ROW) {
    newData.forEach(element => {
      var objectToReplace = parameters.partoutEvent.components.find(arrayItem => arrayItem.component === element.component);
      Object.assign(objectToReplace, element);
    });

  }
  if(actionType === constants.DELETE_ROW){
    parameters.partoutEvent = null;
  }


  return scenario;
};