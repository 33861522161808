import React, { useState } from "react";
import _ from "lodash";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DataGrid
//    , useGridApiRef 
} from "@mui/x-data-grid";
import { LinearProgress,IconButton, TextField,Button, styled, darken,lighten,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";
import { Delete, Restore } from "@material-ui/icons";
import { useEffect } from "react";

const useStyles = makeStyles(()=>({
    cancelText: {
      height: 20,
      width: 80,
      color: "rgba(0,0,0,0.38)",
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: "2px",
      lineHeight: "20px",
      textAlign: "left",
  },
  submitText: {
      height: 20,
      width: 80,
      color: "#0D47A1",
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: "1.25px",
      lineHeight: "20px",
  },
  }));

class LocalizedUtils extends MomentUtils {
    getDatePickerHeaderText(date) {
      return moment.utc(date).format("MM-DD-YY");
    }
}
const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .super-app-theme--NEW': {
        backgroundColor: getBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
          ),
        },
        '&.Mui-selected': {
          backgroundColor: getSelectedBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
          ),
          '&:hover': {
            backgroundColor: getSelectedHoverBackgroundColor(
              theme.palette.success.main,
              theme.palette.mode,
            ),
          },
        },
    },
    '& .super-app-theme--EXPIRED': {
    backgroundColor: getBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
    ),
    '&:hover': {
        backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
        ),
    },
    '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
        ),
        '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
        ),
        },
    },
    },
    '& .super-app-theme--FUTURE': {
        backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        '&:hover': {
          backgroundColor: getHoverBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode,
          ),
        },
        '&.Mui-selected': {
          backgroundColor: getSelectedBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode,
          ),
          '&:hover': {
            backgroundColor: getSelectedHoverBackgroundColor(
              theme.palette.info.main,
              theme.palette.mode,
            ),
          },
        },
    },
    '& .super-app-theme--ARCHIVED': {
      backgroundColor: getBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
      ),
      '&:hover': {
          backgroundColor: getHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
          ),
      },
      '&.Mui-selected': {
          backgroundColor: getSelectedBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
          ),
          '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
              theme.palette.error.main,
              theme.palette.mode,
          ),
          },
      },
    },
}));
const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

function getFormattedDate(date) {
    if(date === null || date === undefined)
        return null;
    var year = date.getFullYear();
    if(year === 1)
        year = "0001";

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
} 
const AppNoRowsOverlay = () => (
<div
    style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "50%",
    }}>
    <p severity='info'>No Assets In Asset Pool</p>
</div>
);
const formatRows = (assets) =>{
    return _.orderBy(assets,[(a) => a.assetId],["asc"]).map((c,ind) => (
    {
      id: ind,
      assetPoolId:c.assetPoolId,
      assetId:c.assetId,
      assetType:c.assetType,
      subseries:c.subseries,
      serialNumber: c.serialNumber,
      enterDate: new Date(c.enterDate),
      //getFormattedDate(new Date(c.enterDate))
      exitDate: c.exitDate == null ? null : new Date(c.exitDate),
      //getFormattedDate(new Date(c.exitDate),
      status:c.status,
      fdKey: new Date(c.enterDate),
    }));
}



const AssetPoolAssetGrid = ({availableAssets,assetpool,assetpoolAssets,open, setopen, updatefcn}) => 
{
    const dateformat = "MM-DD-YYYY";

    const generateMultiAutoComplete = (assets) => {

        let items = assets ? assets.map((asset) => ({
            label: `${asset.subseries}, MSN: ${asset.serialNumber}`,
            value: asset,
        })) : [];
        //let indexes = [];
        return (
            <Autocomplete
              multiple
              filterSelectedOptions
              id={`autoselect`}
              onChange={(e, value) => {
                if (value && !_.isEmpty(value)) {
                  if (
                    value.slice(-1)[0] !== null &&
                    value.slice(-1)[0].value !== null &&
                    value.slice(-1)[0].value !== undefined
                  ) {
                    updateSelected(value);
                  }
                }
                else{
                    setSelectedAssets([]);
                }
              }}
              options={items}
              value={selectedAssets}
              getOptionLabel={(items) => items?.label}
              style={{
                color: "#263238",
                "&:focus": { backgroundColor: "transparent" },
                width:500,
                marginLeft:10,
                marginBottom:5,
                display:"inline-block"
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Select Assets"}
                />
              )}
            />
        );
    };
    const columns = [
        {
          field: "assetId",
          headerName: "ID",
          width: 100,
          editable: false,
          hideable: false,
          sortable:true,
          // valueGetter: (params) => getFormattedDate(params.row.changedOn),
        },
        {
            field: "assetType",
            headerName: "Asset Type",
            width: 150,
            editable: false,
            hideable: false,
            sortable:false,
        },
        {
          field: "serialNumber",
          headerName: "Serial Number",
          width: 150,
          editable: false,
          hideable: false,
          sortable:true,
        },
        {
          field: "subseries",
          headerName: "Asset Subseries",
          width: 150,
          editable: false,
          hideable: false,
          sortable:false,
        },
        {
            field: "enterDate",
            headerName: "Enter Date",
            width: 150,
            type:"date",
            valueFormatter: ({value}) => {
                return getFormattedDate(new Date(value)); 
            },
            editable: true,
            hideable: false,
            sortable:true,
        },
        {
            field: "exitDate",
            headerName: "Exit Date",
            width: 150,
            type:"date",
            valueFormatter: ({value}) => {
              if(value === null){
                return "---"
              }
                return getFormattedDate(new Date(value)); 
            },
            editable: true,
            hideable: false,
            sortable:true,
        },
        {
            field: "status",
            headerName: "Status",
            width: 100,
            editable: false,
            hideable: false,
            sortable:true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => {
                return(
                    <div>
                        <IconButton
                        tooltip="Undo"
                        onClick={(e)=>handleUndoClick(e,params.row.id)}
                        disabled={true}
                        //disabled={unsavedChangesRef.current.unsavedRows[params.row.id] === undefined}
                        >
                            <Restore/>
                        </IconButton>
                        <IconButton
                        tooltip="Delete"
                        onClick={(e)=>handleDeleteClick(e,params.row.id)}
                        >
                            <Delete></Delete>
                        </IconButton>
                    </div> 
                );
            }
        },
    ];
    
    const [valid,setValid] = useState(true);
    const [enterDate,setFromDate] = useState("0001-01-01T08:00:00Z");
    const [exitDate,setToDate] = useState(null);
    const [loading,setLoading] = useState(false);
    const [message,setMessage]= useState("");
    const [ToMessage,setToMessage]= useState("");
    const [rowdata, setRowdata] = useState([]);
    const [removedRows, setRemovedRows] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [dirty, setDirty] = useState(false);
    const [editedRows, setEditedRows] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const classes = useStyles();
  
    const handleCancel = () => {
        setRowdata(formatRows(assetpoolAssets));
        setRemovedRows([]);
        setSelectedAssets([]);
        setEditedRows([]);
        setDirty(false);
        setLoading(false);
        setErrMsg("");
        setopen(false);
    };
    const  onAccept = async () =>{
      if(dirty){
        setErrMsg("");
        let res = await updatefcn(rowdata,removedRows,editedRows)
        if(res !== "close" && res !== undefined){
          setErrMsg(res);
          return;
        }
      }
      //overlapping dates check result and create message 
      handleCancel();
    }
    const handleClose = () =>{
    }
    //TODO:: ONCE REACT IS UPDATED TO 18 USE GRID API TO TRACK CHANGES TO THE DATADGRID FOR UNDO FUNCTIONALITY
    // OR DO IT YOUSELF WITH THE EDITEDROWS HOOK
    //revert changes
    // const apiRef = useGridApiRef();
    // const unsavedChangesRef = React.useRef({
    //     unsavedRows: {},
    //     rowsBeforeChange: {}
    // });
    // const processRowUpdate = useCallback(async (newRow, oldRow) => {
    //     console.log("update");
    //     const rowId = newRow.id;
    //     console.log(newRow,oldRow);
        
    //     unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    //     if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
    //       unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    //     }
    //     return newRow;
    //  }
    // );
    // const columns = React.useMemo(() => {
    //     return [
    //         ...precolumns,
    //         {
    //             field: 'actions',
    //             type: 'actions',
    //             headerName: 'Actions',
    //             width: 300,
    //             renderCell: (params) => {
    //                 return(
    //                     <div>
    //                         <IconButton
    //                         tooltip="Undo"
    //                         onClick={(e)=>handleUndoClick(e,params.row.id)}
    //                         disabled={unsavedChangesRef.current.unsavedRows[params.row.id] === undefined}
    //                         >
    //                             <Restore/>
    //                         </IconButton>
    //                         <IconButton
    //                         tooltip="Delete"
    //                         onClick={(e)=>handleDeleteClick(e,params.row.id)}
    //                         >
    //                             <Delete></Delete>
    //                         </IconButton>
    //                     </div> 
    //                 );
    //             }
    //         },
    //     ];
    //   }, [precolumns, unsavedChangesRef, apiRef]);
    
    useEffect (() => {
        setLoading(true);
        if(assetpoolAssets !== null){
            setRowdata(formatRows(assetpoolAssets));
            setRemovedRows([]);
            setSelectedAssets([]);
            setEditedRows([]);
            setDirty(false);
            setLoading(false);
        }
    }, [assetpoolAssets]);
    //console.log(rowdata);

    const handleDeleteClick = (e,id) =>{
        let removed = rowdata.filter(a => {
            if(a.status !== "NEW" && a.id === id){
                let n = removedRows;
                n.push(a);
                setRemovedRows(n);
                setDirty(true);
            }
            return a.id !== id;
        });
        for(let i = 0; i < removed.length; i++){
            removed[i].id = i;
        }
        setRowdata(removed);   
    }
    const handleUndoClick = (e,id) => {
        console.log(id);
        // apiRef.current.updateRows([
        //     unsavedChangesRef.current.rowsBeforeChange[id],
        //   ]);
        //   delete unsavedChangesRef.current.rowsBeforeChange[id];
        //   delete unsavedChangesRef.current.unsavedRows[id];
    }
    const handleAddSelected = () => {
        let size = rowdata.length;
        let newassets = selectedAssets.map((c,ind) => ({
             id: ind+size,
             assetPoolId:assetpool.assetPoolId,
             assetId:c.value.id,
             assetType:c.value.assetType,
             subseries:c.value.subseries,
             serialNumber: c.value.serialNumber,
             enterDate: new Date(enterDate),
             //getFormattedDate(new Date(c.enterDate))
             exitDate: exitDate !== null ? new Date(exitDate) : null,
             //getFormattedDate(new Date(c.exitDate),
             status: "NEW",
        }));
        let combined = newassets.concat(rowdata);
        for(let i = 0; i < combined.length; i++){
            combined[i].id = i;
        }
        setDirty(true);
        setRowdata(combined);
        setSelectedAssets([]);
    };
    const updateSelected = (values) => {
           setSelectedAssets(values);
     };
    const handleCellEdit = (p,e) => {
        let {row, field, value } = p;
        let temp = rowdata;
        let addto = editedRows;
        if(value === undefined || value === null)
            return;
        if(field === "enterDate"){
            if(value === row.enterDate){
                return;
            }
            else if (value > row.exitDate){
                setToMessage("Exit Date Cannot be After Enter Date");
                return;
            }
            //check if new 
            if(row.status === "NEW"){
              let newguy = temp.find((x)=> x.id === row.id);
              newguy.enterDate = value;
              setRowdata(temp);
              return;
            }
            //FIND IF ITS IN EDITED ALREADY
            //TODO:: CHANGE THE fdKey TO UUID 
            let isin = addto.find((x)=> x.assetId === row.assetId && x.assetType === row.assetType && x.fdKey === row.fdKey && x.Field === "enterDate");
            if(isin !== undefined){
              //UPDATE THE NEW VALUE
              isin.NewValue = value;
              row.NewValue = value;
              setEditedRows(addto);
              let editme = temp.find((x)=> x.id === row.id);
              editme.Field = field;
              editme.NewValue = value;
              editme.enterDate = row.enterDate;
              setRowdata(temp);
              return;
            }
            //NOT IN SO ADD TO IT
            let copy = {
              Field: field,
              NewValue: value,
              assetId: row.assetId,
              assetPoolId: row.assetPoolId,
              assetType: row.assetType,
              fdKey: row.fdKey,
              enterDate: new Date(row.enterDate),
              id: row.id,
              serialNumber:row.serialNumber,
              status: row.status,
              subseries: row.subseries,
              exitDate: row.exitDate,
            };
            addto.push(copy);
            setEditedRows(addto);
            //make sure to keep old fromdate
            let editme = temp.find((x)=> x.id === row.id);
            editme.Field = field;
            editme.NewValue = value;
            editme.enterDate = row.enterDate;
            setRowdata(temp);

        }
        else {
            if(value === row.exitDate){
                return;
            }
            else if (value < row.enterDate){
                setToMessage("Exit Date Cannot be Before Enter Date");
                return;
            }
            if(row.status === "NEW"){
              let newguy = temp.find((x)=> x.id === row.id);
              newguy.exitDate = value;
              setRowdata(temp);
              return;
            }
            //FIND IF ITS IN EDITED ALREADY
            let addto = editedRows;
            let isin = addto.find((x)=> x.assetId === row.assetId && x.assetType === row.AssetType && x.enterDate === row.enterDate && x.Field === "exitDate");
            if(isin !== undefined){
            //UPDATE THE NEW VALUE
              row.NewValue = value;
              isin.NewValue = value;
              setEditedRows(addto);
              return;
            }
            //NOT IN SO ADD TO IT
            row.Field = field;
            row.NewValue = value;
            addto.push(row);
            setEditedRows(addto);
        }
        setDirty(true);
    }

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}
        style={{height:'90vh'}}
        >
        <DialogTitle>Select Assets to Add
          {/* <p style={{fontSize:12}}>(use CTRL or CMD + click to unselect)</p>  */}
        </DialogTitle>
        <DialogContent 
        >
        <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1}}>
                <div>
                    <div style={{marginBottom:5, display:"inline-block"}}>
                        <MuiPickersUtilsProvider moment={moment.utc} utils={LocalizedUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                label={"Choose Enter Date"}
                                fullWidth={false}
                                inputVariant={"standard"}
                                placeholder={dateformat}
                                format={dateformat}
                                value={enterDate}
                                onChange={(date,value) => {
                                    // setValid(utils.validate(constants.DATE, new Date(value), true));
                                    // if(valid){
                                    if(new Date(value) < new Date(exitDate)){
                                        setFromDate(value)
                                        setMessage("");
                                        setValid(true);
                                    }
                                    else{
                                        //setFromDate(constants.MinDate);
                                        setValid(false);
                                        setMessage("Enter Date must be before Exit Date");
                                    }       
                                    //}
                                    
                                }}
                                helperText={message}
                                error={valid ? false : true}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                            <KeyboardDatePicker
                                style={{marginLeft:10}}
                                disableToolbar
                                label={"Choose Exit Date"}
                                fullWidth={false}
                                inputVariant={"standard"}
                                placeholder={dateformat}
                                format={dateformat}
                                value={exitDate}
                                onChange={(value) => {
                                    //setValid(utils.validate(constants.DATE, value, true));
                                    if( new Date(value) > new Date(enterDate) )
                                    {
                                        setToDate(value)
                                        setToMessage("");
                                        setValid(true);
                                    }
                                    else{
                                        //setToDate(constants.MaxDate);
                                        setValid(false);
                                        setToMessage("Exit Date must be after Enter Date");
                                    }
                                    
                                }}
                                error={valid ? false : true}
                                helperText={ToMessage}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />

                        </MuiPickersUtilsProvider>
                    {/* asset dropdown goes here */}
                    {generateMultiAutoComplete(availableAssets)}
                    {/* Add Selected Assets Buttons */}
                    <Button
                        style={{ maxHeight: 28, marginLeft:10, marginTop:15 }}
                        variant='outlined'
                        color='primary'
                        disabled={selectedAssets.length === 0}
                        onClick={handleAddSelected}
                        >
                        <span style={{ margin: "0px 8px 0px 8px" }}>Add Selected Assets</span>
                    </Button>

                    </div>
                    <div style={{fontSize:18, color:"red", margin:5, display:"inline-block"}}>
                        {errMsg}
                    </div>
                    <StyledDataGrid
                        //processRowUpdate={processRowUpdate}
                        //apiRef={apiRef}
                        //experimentalFeatures={{ newEditingApi: true }}
                        getRowClassName={(params)=> `super-app-theme--${params.row.status}`}
                        components={{
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: AppNoRowsOverlay,
                        }}
                        disableColumnMenu
                        hideFooterPagination
                        style={{ 
                                height: "50vh", 
                                width:"100%" 
                                }}
                        rows={rowdata}
                        // getRowId={(row) => row.id}
                        columns={columns}
                        //checkboxSelection
                        onCellEditCommit={(params, e) => handleCellEdit(params, e)}
                        hideFooter
                        disableColumnSelector
                        disableColumnFilter
                        //onSelectionModelChange={(id)=> {}}
                        //disableMultipleRowSelection
                        loading={loading}
                    />
                    </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{marginRight: 25, marginBottom: 20}}>
                <Button onClick={handleCancel}>
                <span className={classes.cancelText}>Cancel</span>
                </Button>
                <Button onClick={onAccept} variant="outlined">
                <span className={classes.submitText}>SUBMIT</span>
                </Button>
            </DialogActions>
        </Dialog>
            
        );
};
export default AssetPoolAssetGrid;