import React, { useState } from "react";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import DataTable from "../../../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../../../Common/Tables/EditableTable/EditableTable";
// import SectionHeader from "../../../../../Common/Section/SectionHeader";
// import { ReactComponent as LandingGearIcon2x } from "../../../../../../assets/images/landingGear/LandingGearIcon2x.svg";
import * as constants from "../../../../../../constants";
import * as AssetDetailViewModel from "../../../../mapToViewModels";
import { mapToViewModelHeader } from "../../../../mapToViewModels";

const formatData = (
  landingGears,
  componentType,
  generateEditableProps,
  scenario
) => {
  return landingGears.map((item, idx) => {
    let props = generateEditableProps(idx, componentType);
    return {
      header: mapToViewModelHeader(item, componentType),
      data: AssetDetailViewModel.mapToViewModel(
        item,
        componentType,
        props,
        scenario
      ),
      componentType,
    };
  });
};

const LandingGear = ({ landingGears, generateEditableProps, scenario }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const data = formatData(
    landingGears,
    constants.LANDING_GEARS,
    generateEditableProps,
    scenario
  );

  return (
    <Box>
      {/* <SectionHeader
        label={constants.Landing_Gears}
        // icon={<LandingGearIcon2x />}
      /> */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        justify="end"
        style={{ marginBottom: 16, marginTop: -16 }}
      >
        {data.map((o) => (
          <Tab key={o.header[0].label} label={o.header[0].label} />
        ))}
      </Tabs>
      {data.map((lg, idx) =>
        idx === value ? (
          <Box key={idx} style={{ marginRight: 0 }}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <DataTable tableData={lg.data.LGInfo} />
              </Grid>
              <Grid item sm={12}>
                <EditableTable table={lg.data.landingGearStatus} />
              </Grid>
              <Grid item sm={12}>
                <EditableTable table={lg.data.shopVisitHistory} />
              </Grid>
              <Grid item sm={12}>
                <EditableTable table={lg.data.scheduledShopVisit} />
              </Grid>
            </Grid>
          </Box>
        ) : null
      )}
    </Box>
  );
};

export default LandingGear;
