import moment from "moment";

export const scenarioTemplate = {
  id: -1,
  author: "",
  name: "",
  scenarioDate: moment.utc().format("YYYY-MM-DD"),
  scenarioComment: "",
  published: true,
  lastUpdatedBy: "None",
  leases: [],
  expectedUtilizations: [
    {
      components: [],
      utilizationByDate: [],
    },
  ],
  parameters: {
    name: "Base",
    modelBehavior: "Standard",
    prLifeDenominator: "Actual",
    appraiser: "Ascend",
    valuationMethod: "Highest",
    mxAdjMethod: "Cost",
    hlValueType: "Market",
    ptValueType: "Market",
    afCheck_Minor_PPD: 0,
    afCheck_HMV_PPD: 0,
    enginePR_PPD: 0,
    engineLLP_PPD: 0,
    landingGear_PPD: 0,
    apU_PPD: 0,
    afCheck_Minor_RemainingDay: 0,
    afCheck_HMV_RemainingDay: 0,
    enginePR_RemainingDay: 0,
    engineLLP_RemainingDay: 0,
    landingGear_RemainingDay: 0,
    apU_RemainingDay: 0,
    llpPriceFactor: 1,
    enginePRMinLifeOutOfShop: 0.4,
    saleGap: 12,
    eolCompGap: 0,
    valuationFloor: 0,
    utilizationScaleFactor: 1,
    eprIntervalScaleFactor: 1,
    runDate: "0001-01-01T00:00:00+00:00",
  },
  downtimeOverrides: [],
  intervalOverrides: [],
  costOverrides: [],
  buildStandardOverrides: [],
  llpStackPriceEscOverrides: [],
  llpStackScrapOverrides: [],
  exchangeOverrides: [],
};
