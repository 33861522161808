import React, { useState } from "react";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ApiRoutes from "../../../../utils/ApiRoutes";
import ThreeDotButton from "../../../Common/ThreeDotButton";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
import * as constants from "../../../../constants";
import AircraftSvg from "../../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../../Common/SvgComponents/EngineSvg";
import VfSnackerBar from "../../../Common/UtilComponents/VfSnackerBar";

const useStyles = makeStyles((theme) => ({
  avatar: { width: 20, height: 20 },
  root: {
    height: 68,
    width: "100%",
    backgroundColor: `${theme.palette.primary.background.main}`,
    borderRadius: "4px",
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    margin: "auto",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.background.hover}`,
    },
  },
  content: {
    textAlign: "left",
    padding: "2px 5px",
  },
  subtitle1: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
  body2: {
    fontSize: 14,
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
  caption: {
    fontSize: 12,
    letterSpacing: "0.4px",
    width: "50%",
    display: "inline-block",
  },
  captionLeft: {
    textAlign: "left",
  },
  captionRight: {
    textAlign: "right",
  },
  verticalAlignMiddle: {
    verticalAlign: "middle",
  },
  icon: { marginRight: 10 },
  operator: { marginTop: 2, marginBottom: 2 },
  threeDotButton: { display: "inline-flex", float: "right" },
}));

const renderLink = (deal, asset, dealAssets, children) => {
  return (
    <Link
      style={{ color: "#355A79", textDecoration: "none", display: "contents" }}
      to={{
        pathname: `/deal/assetModel/${asset.id}?dealId=${deal.dealId}&dealName=${deal.dealName}&assetType=${asset.assetType}&serialNumber=${asset.serialNumber}`,
        state: { deal, assets: dealAssets, assetId: asset.id },
      }}>
      {children}
    </Link>
  );
};

const renderAssetInfo = (classes, icon, asset) => {
  return (
    <>
      <span className={classes.icon}>{icon}</span>
      <span className={classes.verticalAlignMiddle}>{asset.subseries}</span>
    </>
  );
};
const renderOperatorInfo = (classes, asset, airlineLogo) => {
  return (
    <Grid container className={classes.operator}>
      <Grid item sm={2}>
        <Avatar
          className={classes.avatar}
          alt={asset.operator}
          src={airlineLogo && airlineLogo.path}
        />
      </Grid>
      <Grid item sm={10}>
        <Typography variant='body2' color='secondary' className={classes.body2}>
          {asset.operator || constants.NA}
        </Typography>
      </Grid>
    </Grid>
  );
};

const renderRegistrationInfo = (classes, asset) => {
  return (
    <>
      <Typography variant='caption' color='secondary'>
        <span className={`${classes.caption} ${classes.captionLeft}`}>
          {asset.serialNumber}
        </span>
      </Typography>
      <Typography variant='caption' color='secondary'>
        <span className={`${classes.caption} ${classes.captionRight}`}>
          {asset.registration}
        </span>
      </Typography>
    </>
  );
};

const Asset = ({ user, asset, deal, dealAssets, airlineLogo }) => {
  const delay = 1500;
  const classes = useStyles();
  const msalContext = useMsal();
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  // const [showThreeDotBtn, setShowThreeDotBtn] = useState(false);
  const icon =
    asset.assetType === constants.AIRCRAFT ? (
      <AircraftSvg
        height={20}
        width={20}
        viewBox={"0 0 25 25"}
        style={{ verticalAlign: "middle" }}
      />
    ) : (
      <EngineSvg
        height={20}
        width={20}
        viewBox={"0 0 25 25"}
        style={{ verticalAlign: "middle" }}
      />
    );

  // const handleRemoveAssetFromDeal = async () => {
  //   const { dealId, dealName } = deal;
  //   const { id, assetType } = asset;

  //   try {
  //     const result = await apiRequest(
  //       {
  //         url: ApiRoutes.VectorDeal_RemoveFromDealByAssetType({
  //           assetType,
  //           user: user,
  //         }),
  //         data: { dealId, assetId: id },
  //         method: "DELETE",
  //         headers: { "Content-Type": "text/json" },
  //       },
  //       msalContext
  //     );

  //     if (result.status === response.OK) {
  //       setMessage(`Successfully deleted ${asset.subseries} from ${dealName}.`);
  //       setsnackOpen(true);
  //       setSeverity("info");
  //       setTimeout(() => {
  //         window.location.reload(true);
  //       }, delay);
  //     } else {
  //       setMessage(`Failed to delete ${asset.subseries} from ${dealName}.`);
  //       setsnackOpen(true);
  //       setSeverity("warning");
  //     }
  //   } catch (ex) {}
  // };

  // const threeDotBtnOptions = [
  //   {
  //     id: 0,
  //     icon: DeleteOutline,
  //     label: constants.DELETE,
  //     handleClick: handleRemoveAssetFromDeal,
  //   },
  // ];
  return (
    <Box
      className={classes.root}
      // onMouseLeave={() => setShowThreeDotBtn(false)}
      // onMouseOver={() => setShowThreeDotBtn(true)}
    >
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        message={message}
      />

      <Box className={classes.content}>
        <Grid container>
          <Grid item sm={12}>
            <Typography
              variant='subtitle1'
              color='primary'
              className={classes.subtitle1}>
              {renderLink(
                deal,
                asset,
                dealAssets,
                renderAssetInfo(classes, icon, asset)
              )}

              <Box className={classes.threeDotButton}>
                {/* {showThreeDotBtn ? ( */}
                {/* <ThreeDotButton
                  options={threeDotBtnOptions}
                  fontSize='small'
                  padding={0}
                /> */}
                {/* ) : null} */}
              </Box>
            </Typography>
          </Grid>
          {renderLink(
            deal,
            asset,
            dealAssets,
            <>
              <Grid item sm={12}>
                {renderOperatorInfo(classes, asset, airlineLogo)}
              </Grid>
              <Grid item sm={12}>
                {renderRegistrationInfo(classes, asset)}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Asset;
