import * as constants from "../../../../constants";
import { updateUtilization } from "./expectedUtilization";
import { updateReserveAccountsCaps } from "./reserveAccountCapsSection"
import { updateEOLCompCaps } from "./eolCompBounds";

export function updateSection(
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario
) {
  switch (tableName) {
    case constants.EXPECTED_UTILIZATIONS:
      return updateUtilization(
        tableName,
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        scenario
      );
    case "Account Caps":
      return updateReserveAccountsCaps(
        tableName,
        actionType,
        assetDetails,
        sectionName,
        sectionIdx,
        newData,
        scenario
      );
      case "eolCompBounds":
        return updateEOLCompCaps(
          tableName,
          actionType,
          assetDetails,
          sectionName,
          sectionIdx,
          newData,
          scenario
        );
    default:
      return;
  }
}
