import _ from "lodash";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";

export function mapToViewModel_MXReserve_LessorContrib(leaseSummary, props) {
  let columns = [
    {
      label: ["Mx Reserves Payable"],
      field: "mxReservePayable",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Letter of Credit in Lieu of Payment"],
      field: "letterofCreditinLieuofPayment",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Rights to Maintenance Reserves"],
      field: "rightsToMXReserves",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Maintenance Resever Rate Adjustment"],
      field: "mxReseverRateAdj",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Maintenace Reserve Balance As Of Date"],
      field: "mxReserveBalanceAsOfDate",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: ["Maintenance Redelivery Adjustments"],
      field: "mxRedeliveryAdj",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Maintenance Event Definitions"],
      field: "mxEventDefinitions",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Maintenance Event Notice"],
      field: "mxEventNotice",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lessor Contributions Applicable"],
      field: "lessorContribApplicable",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lessor Contributions Conditions"],
      field: "lessorContribConditions",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lessor's Contribution to first Maintenance Event"],
      field: "lessorContribFirstMXEvent",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
  ];

  let data = {
    mxReservePayable: leaseSummary.mxReserve_LessorContrib.mxReservePayable,
    letterofCreditinLieuofPayment: leaseSummary.mxReserve_LessorContrib.letterofCreditinLieuofPayment,
    rightsToMXReserves: leaseSummary.mxReserve_LessorContrib.rightsToMXReserves,
    mxReseverRateAdj: leaseSummary.mxReserve_LessorContrib.mxReseverRateAdj,
    mxReserveBalanceAsOfDate: leaseSummary.mxReserve_LessorContrib.mxReserveBalanceAsOfDate,
    mxRedeliveryAdj: leaseSummary.mxReserve_LessorContrib.mxRedeliveryAdj,
    mxEventDefinitions: leaseSummary.mxReserve_LessorContrib.mxEventDefinitions,
    mxEventNotice: leaseSummary.mxReserve_LessorContrib.mxEventNotice,
    lessorContribApplicable: leaseSummary.mxReserve_LessorContrib.lessorContribApplicable,
    lessorContribConditions: leaseSummary.mxReserve_LessorContrib.lessorContribConditions,
    lessorContribFirstMXEvent: leaseSummary.mxReserve_LessorContrib.lessorContribFirstMXEvent,
  };

  leaseSummary.mxReserve_LessorContrib.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  });

  return {
    label: "Maintenance Reserves / Lessor Contributions",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Maintenance Reserves / Lessor Contributions",
    columns: columns,
    data: data,
  };
}
