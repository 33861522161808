import _ from "lodash";

export const generateGenericComponent = (genericComponent) => {
  let genericMxInfos = [];
  const costs = genericComponent.ohCosts;
  const intervals = genericComponent.ohIntervals;
  const downTime = genericComponent.downTime;

  for (let i = 0; i < costs.length; i++) {
    const genericMxInfo = {
      interval: {
        flightHour: null,
        flightCycle: null,
        activeHour: null,
        day: null,
      },
      downtime: null,
      cost: {
        cost: null,
        costDate: null,
        costEscalation: null,
      },
      isOverHaul: true,
      isFirstRun: false,
      asOfDate: null,
    };

    genericMxInfo.cost.cost = costs[i].cost;
    genericMxInfo.cost.costDate = costs[i].costDate;
    genericMxInfo.cost.costEscalation = costs[i].costEscalation;
    genericMxInfo.downtime = downTime;
    genericMxInfo.isFirstRun = costs[i].isFirstRun;
    let interval = _.find(
      intervals,
      (o) => o.isFirstRun === costs[i].isFirstRun
    );
    interval = interval || intervals[i];
    genericMxInfo.interval.flightHour = interval.flightHour;
    genericMxInfo.interval.flightCycle = interval.flightCycle;
    genericMxInfo.interval.activeHour = interval.activeHour;
    genericMxInfo.interval.day = interval.day;
    genericMxInfo.asOfDate = interval.asOfDate;

    genericMxInfos.push(genericMxInfo);
  }
  return genericMxInfos;
};
