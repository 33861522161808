import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

const Contractual = "Contractual";
const UnderNegotiation = "UnderNegotiation";
const Assumption = "Assumption";

const NewLease = "NewLease";
const Amendment = "Amendment";

export function mapToViewModel_LeaseSummary(lease, props, lessors, lessees) {
  //debugger;
  const leaseStatus = [
    { label: Contractual, value: Contractual },
    { label: UnderNegotiation, value: UnderNegotiation },
    { label: Assumption, value: Assumption },
  ];
  const leaseType = [
    { label: NewLease, value: NewLease },
    { label: Amendment, value: Amendment },
  ];
  // const rentType = [
  //   { label: constants.Monthly, value: constants.Monthly },
  //   { label: constants.Quarterly, value: constants.Quarterly },
  // ];

  return {
    label: "Lease Summary",
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: constants.leaseSummary,
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    columns: [
      // {
      //   label: ["Lessee"],
      //   field: "lessee",
      //   captions: [],
      //   span: [3, 2],
      //   editable:true,
      //   required: true,
      //   type: constants.TEXT,
      //   inputFieldType: constants.TEXT_FIELD,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      {
        label: ["Lessee"],
        field: "lessee",
        captions: [],
        span: [3, 2],
        editable: true,
        required: true,
        items: lessees.map((lessee) => {
          return { label: lessee.name, value: lessee.name };
        }),
        type: constants.AUTO_COMPLETE,
        inputFieldType: constants.AUTO_COMPLETE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Sub-lessee"],
        field: "subLessee",
        captions: [],
        span: [3, 2],
        editable: true,
        required: true,
        items: lessees.map((lessee) => {
          return { label: lessee.name, value: lessee.name };
        }),
        type: constants.AUTO_COMPLETE,
        inputFieldType: constants.AUTO_COMPLETE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["STATUS"],
        field: "leaseStatus",
        captions: [],
        span: [1, "auto"],
        editable: true,
        required: false,
        type: constants.TEXT,
        items: leaseStatus,
        inputFieldType: constants.SELECT,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["TYPE"],
        field: "leaseType",
        captions: [],
        span: [1, "auto"],
        editable: true,
        required: false,
        type: constants.TEXT,
        items: leaseType,
        inputFieldType: constants.SELECT,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      // {
      //   label: ["LESSOR"],
      //   field: "sponsor",
      //   captions: [],
      //   span: [3, 2],
      //   editable:true,
      //   required: true,
      //   type: constants.TEXT,
      //   inputFieldType: constants.TEXT_FIELD,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      {
        label: ["LESSOR"],
        field: "sponsor",
        editable: true,
        captions: [],
        span: [3, 2],
        required: false,
        items: lessors.map((lessor) => {
          return { label: lessor.name, value: lessor.name };
        }),
        // initialEditValue: sponsors,
        type: constants.AUTO_COMPLETE,
        inputFieldType: constants.AUTO_COMPLETE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },

      // {
      //   label: ["OPERATOR"],
      //   field: "operator",
      //   captions: [],
      //   span: [3, 2],
      // },
      // {
      //   label: ["DEPOSIT CASH"],
      //   field: "securityDeposit_Cash",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.TEXT_FIELD,
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      // {
      //   label: ["DEPOSIT LC"],
      //   field: "securityDeposit_LC",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.TEXT_FIELD,
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      // {
      //   label: ["MX CASH RESERVE"],
      //   field: "reserveAccounts",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.TEXT_FIELD,
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      // {
      //   label: ["MX CASH RESERVE DATE"],
      //   field: "reserveAccountsDate",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.DATE,
      //   inputFieldType: constants.DATE,
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.DATE_CELL),
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      // {
      //   label: ["MX LC RESERVE"],
      //   field: "creditAccounts",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.TEXT_FIELD,
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      // {
      //   label: ["MX LC RESERVE DATE"],
      //   field: "creditAccountsDate",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.DATE,
      //   inputFieldType: constants.DATE,
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.DATE_CELL),
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      {
        label: ["LEASE START"],
        field: "leaseStartDate",
        captions: [],
        span: [3, "auto"],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["LEASE END"],
        field: "leaseEndDate",
        captions: [],
        span: [3, "auto"],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      // {
      //   label: ["LEASE AVG RENT"],
      //   field: "rentPayments",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.TEXT_FIELD,
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      // {
      //   label: ["LEASE RENT PAID"],
      //   field: "leaseRentPaid",
      //   captions: [],
      //   span: [3, 2],
      // },
      // {
      //   label: ["RENT TYPE"],
      //   field: "rentType",
      //   captions: [],
      //   span: [3, 2],
      //   editable: true,
      //   required: false,
      //   type: constants.TEXT,
      //   items: rentType,
      //   inputFieldType: constants.SELECT,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      // {
      //   label: ["RENT PAYMENT MADE"],
      //   field: "rentPaymentMade",
      //   captions: [],
      //   span: [3, 2],
      // },
    ],

    data: {
      lessee: lease.lessee || constants.___,
      subLessee: lease.subLessee || constants.___,
      leaseStatus: lease.leaseStatus,
      leaseType: lease.leaseType,
      sponsor: lease.sponsor,
      operator: lease.lessee,
      securityDeposit_Cash: lease.securityDeposit_Cash,
      securityDeposit_LC: lease.securityDeposit_LC,
      reserveAccounts: _.sumBy(lease.reserveAccounts, "cashBalance"),
      reserveAccountsDate: lease.reserveAccounts.length
        ? lease.reserveAccounts[0].date
        : null,
      creditAccounts: _.sumBy(lease.creditAccounts, "amount"),
      creditAccountsDate: lease.creditAccounts.length
        ? lease.creditAccounts[0].date
        : null,
      leaseStartDate: lease.leaseStartDate,
      leaseEndDate: lease.leaseEndDate,
      rentPayments: _.meanBy(lease.rentPayments, "amount"),
      leaseRentPaid:
        moment
          .utc(lease.rentPayments[1])
          .diff(moment.utc(lease.rentPayments[0]), "days") <= 31
          ? "Monthly"
          : "Quartly",
      rentType: "Fixed",
      rentPaymentMade: "In Advance",
    },
  };
}
