import _ from "lodash";
import editableTables from "../../common";
import * as constants from "../../../../../constants";
import { mapToViewModel_EngineLLPScrapValue } from "./engineLLPScrapValueViewModel";

function getShopVisitAssumptions(
  assetDetails,
  generateEditableProps,
  scenario
) {
  assetDetails.engines.forEach((e) => {
    e.supplementalEvents.forEach((s) => {
      s.serialNumber = e.serialNumber;
      s.EnginePosition = e.position;
    });
  });

  const data =
    assetDetails.componentType !== "EnginePR"
      ? {
          airframeChecks: assetDetails.airframeChecks.map(
            (airframeCheck, idx) =>
              editableTables.shopVisitAssumptions(
                airframeCheck,
                generateEditableProps(idx, constants.AIRFRAME_CHECKS),
                scenario
              )
          ),
          enginePR: assetDetails.engines.map((engine, idx) =>
            editableTables.shopVisitAssumptions(
              engine,
              generateEditableProps(idx, constants.ENGINES),
              scenario
            )
          ),
          engineEvents: assetDetails.engines.map((engine, idx) =>
            engine.supplementalEvents.map((eve, idxx) =>
              editableTables.shopVisitAssumptions(
                eve,
                generateEditableProps(idxx, constants.ENGINES),
                scenario
              )
            )
          ),
          engineLLP: assetDetails.engines.map((engine, idx) =>
            editableTables.engineLLPShopVisitAssumptions(
              engine,
              generateEditableProps(idx, constants.ENGINES),
              scenario
            )
          ),
          landingGears: assetDetails.landingGears.map((lg, idx) =>
            editableTables.shopVisitAssumptions(
              lg,
              generateEditableProps(idx, constants.LANDING_GEARS),
              scenario
            )
          ),
          apus: assetDetails.apUs.map((apu, idx) =>
            editableTables.shopVisitAssumptions(
              apu,
              generateEditableProps(idx, constants.apUs),
              scenario
            )
          ),
        }
      : {
          enginePR: assetDetails.engines.map((engine, idx) =>
            editableTables.shopVisitAssumptions(
              engine,
              generateEditableProps(idx, constants.ENGINES),
              scenario
            )
          ),
          engineLLP: assetDetails.engines.map((engine, idx) =>
            editableTables.engineLLPShopVisitAssumptions(
              engine,
              generateEditableProps(idx, constants.ENGINES),
              scenario
            )
          ),
          engineEvents: assetDetails.engines.map((engine, idx) =>
            engine.supplementalEvents.map((eve, idx) =>
              editableTables.shopVisitAssumptions(
                eve,
                generateEditableProps(idx, constants.ENGINES),
                scenario
              )
            )
          ),
        };

  return _.flatten(
    Object.keys(data).map((key) => {
      const returnMe = [];
      for (let e of data[key]) {
        if (e instanceof Array) {
          e.forEach((l) => {
            let componentType = null;
            if (l.label.includes("ELLP")) {
              componentType = "llps";
            } else {
              componentType = l.props.section;
            }
            returnMe.push({
              label: l.label,
              value: l,
              componentType: componentType,
            });
          });
        } else {
          let componentType = null;
          if (e.label.includes("ELLP")) {
            componentType = "llps";
          } else {
            componentType = e.props.section;
          }
          returnMe.push({
            label: e.label,
            value: e,
            componentType: componentType,
          });
        }
      }
      return returnMe;
    })
  );
}

const getLLPScrapValues = (engines, generateEditableProps, scenario) => {
  return engines.map((engine, index) => {
    const props = generateEditableProps(index, constants.ENGINES);
    return mapToViewModel_EngineLLPScrapValue(engine, props, scenario);
  });
};

export default function mapToViewModel_SVAssumptions(
  assetDetails,
  generateEditableProps,
  scenario
) {
  return {
    svAssumptions: getShopVisitAssumptions(
      assetDetails,
      generateEditableProps,
      scenario
    ),
    engineLLPScrapValues: getLLPScrapValues(
      assetDetails.engines,
      generateEditableProps,
      scenario
    ),
  };
}
