import React, { useState } from "react";

import { IconButton, Popover, SvgIcon } from "@material-ui/core";

import { ReactComponent as InfoIcon } from "../../assets/images/others/InfoIcon.svg";
import Root from "../../Components/Hoc/Root";

const InfoPopup = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Root>
      <IconButton
        style={{ padding: 0 }}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      >
        <SvgIcon
          children={<InfoIcon />}
          style={{
            fontSize: 16,
          }}
        />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <div
          style={{
            height: 100,
            width: 200,
            border: "2px solid #023059",
            background: "#E0E5E9",
          }}
        >
          <SvgIcon
            children={<InfoIcon />}
            style={{
              fontSize: 20,
              marginLeft: 5,
              verticalAlign: "text-top",
            }}
          />
          &nbsp;
          <span stlye={{ background: "none", font: "#D5D9DD" }}>
            Minimum Scrap Value
          </span>
        </div>
      </Popover>
    </Root>
  );
};

export default InfoPopup;
