import React from "react";

const ExportIconSvg = ({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  color = "#023059",
  style = {
    fill: "grey",
  },
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} style={{ ...style }} >
      <g color={color}>
        <path d="M23,12L19,8V11H10V13H19V16M1,18V6C1,4.89 1.9,4 3,4H15A2,2 0 0,1 17,6V9H15V6H3V18H15V15H17V18A2,2 0 0,1 15,20H3A2,2 0 0,1 1,18Z" />
      </g>
    </svg>
  );
};

export default ExportIconSvg;
