import _ from "lodash";

export default {
  FleetData_GetAvailableAssets: _.template("/FleetData/AvailableFleetData/<%=msn%>/<%=manufacturer%>"),
  FleetData_UpdateFGID: _.template("FleetData/UpdateFGID/<%=acid%>/<%=fgid%>/<%=user%>"),
  Company_GetCompanies:"/Company/GetCompanies",
  Homepage_GetMostRecentActivity: "/Home/HomepageData",
  Export_GetSheetNames: "VectorDeal/ExportSheets",
  // Asset pool endpoints
  AssetPool_GetFilterProfiles: _.template("AssetPool/GetFilterProfiles/<%=tablename%>/<%=username%>"),
  AssetPool_Simulate: _.template("AssetPool/Simulate/<%= assetPoolId%>"),
  AssetPool_GetAll: "AssetPool/GetAll",
  AssetPool_GetAssetPoolById: _.template("AssetPool/<%= id %>"),
  AssetPool_AddAssetPool: _.template("AssetPool/AssetPool/<%= user%>"),
  AssetPool_EditAssetPool: _.template(
    "AssetPool/EditName/<%= user%>/<%= assetPoolId %>"
  ),
  AssetPool_DeleteAssetPool: _.template(
    "AssetPool/DeleteAssetPool/<%= user%>/<%= assetPoolId %>"
  ),
  AssetPool_SubmitAssets: _.template(
    "AssetPool/SubmitAssets/<%= user%>"
  ),
  AssetPool_ExitAsset: _.template(
    "AssetPool/ExitAsset/<%= user%>"
  ),
  AssetPool_EditAsset: _.template(
    "AssetPool/EditAsset/<%= user%>"
  ),
  AssetPool_DeleteRecord: _.template(
    "AssetPool/DeleteRecord/<%= user%>"
  ),
  AssetPool_LinkAssetPoolToDeal: _.template(
    "AssetPool/LinkAssetPool/<%= dealId%>/<%= user%>/<%= assetPoolId%>"
  ),
  AssetPool_UnLinkAssetPoolToDeal: _.template(
    "AssetPool/UnLinkAssetPool/<%= dealId%>/<%= user%>"
  ),
  AssetPool_Cashflows: _.template(
    "AssetPool/AssetPoolCashflows/<%=assetPoolId%>"
  ),

  Scenario_PublishScenario: _.template(
    "/Scenario/PublishScenario/<%=ScenarioId %>"
  ),
  Scenario_GetScenarioByAsset: _.template(
    "/Scenario/ScenarioByAsset/<%= assetId %>/<%= assetType %>"
  ),
  Scenario_PostScenario: "/Scenario/MongoScenario",
  Scenario_DeleteScenario: _.template(
    "/Scenario/DeleteMongoScenario/<%= scenarioId %>"
  ),
  //Scenario_PostScenario: "/Scenario/CreateScenario",
  Valuation_ValuationPage: "/Valuation/ValuationPage",
  Valuation_GetSpecificAircraftValuation: _.template(
    "/VectorDeal/SpecificAircraftValuation/<%= aircraftSubSeries %>/<%= msn %>"
  ),
  Valuation_AssetAppraisalUpload: _.template(
    "/Valuation/UploadAssetAppraisal/<%= assetType %>/<%= assetId %>"
  ),
  Valuation_PortfolioAppraisalUpload: _.template(
    "/Valuation/UploadPortfolioAppraisals"
  ),
  Valuation_PortfolioEngineAppraisalUpload: _.template(
    "/Valuation/UploadEnginePortfolioAppraisals"
  ),
  Valuation_AssetSecondaryAppraisalUpload: _.template(
    "/Valuation/UploadSecondaryAssetAppraisal/<%= assetType %>/<%= assetId %>"
  ),
  Valuation_ExportCurrentAppraisal: _.template(
    "/Valuation/ExportAppraisalFile/<%= valuationId %>"
  ),
    Valuation_ExportCurrentAppraisalOrigin: _.template(
    "/Valuation/ExportAppraisalOriginFile/<%= valuationId %>"
  ),
  Valuation_DeleteValuation: _.template(
    "/Valuation/DeleteValuation/<%=valuationId%>"
  ),
  Valuation_ExportExcel: _.template("Valuation/ValuationExcel/<%= id %>"),
  Valuation_AircraftAppraisalTemplate: "Valuation/AircraftAppraisalTemplate",
  Valuation_EngineAppraisalTemplate: "Valuation/EngineAppraisalTemplate",
  LeaseSummary_GetDTO: _.template("LeaseSummary/GetLeaseSummaryDTO/<%= id %>"),
  LeaseSummary_GetAllLeaseSummaries: "/LeaseSummary/GetAllLeaseSummaries",
  LeaseSummary_PostDTO: "/LeaseSummary/PostLeaseSummary",
  LoanSummary_GetDTO: _.template("LoanSummary/GetLoanSummaryDTO/<%= id %>"),
  LoanSummary_GetAllLoanSummaries: "/LoanSummary/GetAllLoanSummaries",
  LoanSummary_PostDTO: "/LoanSummary/PostLoanSummary",
  // Aircraft_GetScenarioDTO: _.template(
  //   "/Aircraft/GetScenarioDTO/<%= scenarioId %>"
  // ),
  Asset_Changes: _.template("VfVector/AssetChanges/<%=id%>/<%=assetType%>"),
  Aircraft_GetScenarioDTO: _.template(
    "/Scenario/GetMongoScenario/<%= scenarioId %>"
  ),
  // Aircraft_GetAircraftDTO: _.template(
  //   "/Aircraft/GetAssetDTO/<%= assetId %>"
  // ),
  Aircraft_GetAircraftDTO: _.template(
    "/Aircraft/GetMongoAssetDTO/<%= assetId %>"
  ),
  Aircraft_PostLifeAircraftDTO: _.template(
    "/Aircraft/CreateAircraft/<%= life %>/<%= scen %>/<%= asof %>/<%= user %>"
  ),
  APU_PostLifeAPUDTO:_.template(
    "/Apu/CreateAPU/<%= life %>/<%= scen %>/<%= asof %>/<%= user %>"
  ),
  APU_GetAssetDTO:_.template("/Asset/GetAssetDTO/<%=AssetType%>/<%=AssetId%>"),
  PostAircraftUpdateInfo: _.template(
    "/Aircraft/UpdateInfo/<%=user%>/<%=compType%>/<%=assetId%>"
  ),
  PostReserveAccount: _.template("/Component/ReserveAccount/<%=user%>"),
  PostShopVisit: _.template("/Aircraft/ShopVisit/<%=compType%>/<%=compId%>"),
  PostMaintenanceStatus: _.template(
    "/Aircraft/MaintenanceStatus/<%=compType%>/<%=compId%>/<%=userName%>"
  ),
  PostMaintenanceEvents: _.template(
    "/Aircraft/MaintenanceEvents/<%=compType%>/<%=compId%>"
  ),
  PostUtilizationHistory: _.template(
    "/Aircraft/UtilizationHistory/<%=compType%>/<%=compId%>"
  ),
  DeleteLLPStatus: _.template(
    "/Aircraft/LLPStatus/<%=engineId%>/<%=statusDate%>"
  ),
  PostLLPStatus: _.template("/Aircraft/LLPStatus/<%=engineId%>/<%=userName%>"),
  Blob_GetAirlineLogo: _.template("/Blob/GetAirlineLogo/<%= operator %>"),
  Blob_GetAirlineLogos: _.template("/VectorDeal/GetAirlineLogos"),
  Blob_GetManufacturerLogo: _.template(
    "/Blob/GetManufacturerLogo/<%= Manufacturer %>"
  ),
  Blob_GetManufacturerLogos: _.template("/Blob/GetManufacturerLogos"),
  Engine_GetEngineDTO: _.template("/Engine/GetAssetDTO/<%= assetId %>"),
  Engine_PostLifeEngineDTO: _.template(
    "/Engine/CreateEngine/<%= life %>/<%= scen %>/<%= asof %>/<%= user %>"
  ),
  Engine_GetSeries: "/Engine/GetSeries",
  Engine_RemoveFromWing:_.template(
    "/Engine/RemoveFromWing/<%= EngId %>/<%= user %>"
  ),
  Engine_AddToWing:_.template("/Engine/AddToWing/<%=AcId%>/<%= user %>"),
  Engine_AvailableEngines:_.template("/Engine/AvailableEngines/<%=GenAcId%>"),
  Engine_PartOutSpecs: _.template(
    "/PartOut/PartOutSpecAdjusters/<%= genericId %>"
  ),
  FleetData_GetFleetRegistration: _.template(
    "/FleetData/<%= aircraftSubSeries %>/<%= msn %>"
  ),
  GenericAPU_GetACAPUs: _.template("/GenericApuModel/ACAPUs/<%= ACId %>"),
  GenericAFC_GetACAFCs: _.template("/GenericAFCheck/airframe/<%=ACId%>"),
  GenericAC_GetList: "/GenericAC/list",
  GenericAFCheck_GetGenericAFCheckByACId: _.template(
    "/GenericAFCheck/airframe/<%= ACId %>"
  ),
  GenericAC_postLgRunout: _.template("/GenericAC/lgrunout/<%=id%>/<%=value%>"),
  GenericApu_postRunout: _.template("/GenericApuModel/runout/<%=id%>/<%=value%>"),
  GenericEngine_GetList: "/GenericEngine/list",
  GenericEngine_GetACEngines: _.template(
    "/GenericEngine/ACEngines/<%= ACId %>"
  ),
  GenericEngine_GetGenericEngineEvents: _.template(
    "/Engine/EngineEvents/<%=id%>"
  ),
  GenericEngineLLPBuidStandard_GetGenericEngineLLPBuildStandard:
    "/GenericEngineLLPBuildStandard",
  GenericEngineLLPPrice_GetGenericEngineLLPPrice: "/GenericEngineLLPPrice",
  GenericComponent_GetGenericComponentById: _.template(
    "/GenericComponent/<%= genericComponentId %>"
  ),
  GenericOHComponentCost_GetGenericOHComponentCostByGenericComponentId:
    _.template("/GenericOHComponentCost/<%= genericComponentId %>"),
  GenericOHComponentInterval_GetGenericOHComponentIntervalByGenericComponentId:
    _.template("/GenericOHComponentInterval/<%= genericComponentId %>"),
  GenericEngineLLP_GetGenericEngineLLPByStackSeries: _.template(
    "/GenericEngineLLP/<%= genericLLPStackId %>"
  ),
  GenericLandingGear_GetGenericLandingGearByACId: _.template(
    "/GenericLandingGear/airframe/<%= ACId %>"
  ),
  NotesAssets_PostAircraftNotes: _.template("/Aircraft/AircraftNote/<%= Id %>"),
  NotesAssets_PostEngineNotes: _.template("/Engine/EngineNote/<%= Id %>"),
  NotesScenario_PostScenarioNotes: _.template(
    "/Scenario/ScenarioNote/<%= Id %>"
  ),
  VectorDeal_CreatePipelineAssets: _.template(
    "/VectorDeal/CreatePipelineAssets/<%= dealId %>"
  ),
  VectorDeal_GetScenarioIds: "/VectorDeal/GetScenarioIds",
  VectorDeal_LinkedDeals: "/VectorDeal/LinkedDeals",
  VectorDeal_GetAssets: "/VectorDeal/Assets",
  VectorDeal_GetOnWing: "/VectorDeal/OnWing",
  VectorDeal_GetDealScenarios: _.template(
    "/VectorDeal/DealScenarios/<%= dealId %>"
  ),
  VectorDeal_GetDealsWithAssets: _.template(
    "/VectorDeal/DealsWithAssets?skip=<%= skip %>&take=<%= take %>&searchDealName=<%= searchDealName %>&searchDealStage=<%= searchDealStage %>&searchSponsorName=<%= searchSponsorName %>"
  ),
  VectorDeal_GetDealsByDealId: _.template("/VectorDeal/<%= dealId %>"),
  // VectorDeal_GetPortfolioReportByDealId: _.template(
  //   "/VectorDeal/DealPortfolioReport/<%= dealid %>/<%= asOfDate %>"
  // ),
  VectorDeal_GetPortfolioReportByDealId: _.template(
    "/VectorDeal/MongoDealPortfolioReport/<%= dealid %>/<%= asOfDate %>"
  ),
  VectorDeal_GetSponsorsList: "/VectorDeal/GetSponsorsList",
  VectorDeal_GetReportNotesByScenario: _.template(
    "/VectorDeal/<%= scenarioId %>/ReportNotes"
  ),
  // VectorDeal_GetSimulateAsset: _.template(
  //   "/Scenario/GetOutput/<%= scenarioId %>"
  // ),
  VectorDeal_GetSimulateAsset: _.template(
    "/Scenario/MongoOutput/<%= scenarioId %>"
  ),
  VectorDeal_PostValuationParams: _.template(
    "/Scenario/UpdateValuationParameters"
  ),
  //VectorDeal_GetSimulatePortfolio: "/VectorDeal/SimulatePortfolio",
  VectorDeal_GetSimulatePortfolio: "/VectorDeal/MongoSimulatePortfolio",
  VectorDeal_GetScenarioSummaries: "/VectorDeal/DealScenarioSummaries",
  VectorDeal_LoginInformation: "/VectorDeal/LoginInformation",
  //Create deal from scratch
  VectorDeal_Deals: _.template("/VectorDeal/<%=user%>"),
  VectorDeal_CreateExistingDeal: _.template(
    "/VectorDeal/CreateExistingDeal/<%=user%>/<%=globalId%>"
  ),
  VectorDeal_DeleteUpdateDeals: _.template(
    "/VectorDeal/<%= dealid %>/<%= user%>"
  ),
  VectorDeal_RemovePipelineLink: _.template("/VectorDeal/RemoveLink/<%= dealid %>/<%= user%>"),
  // VectorDeal_ScenarioSimulate: _.template(
  //   "/Scenario/Simulate/<%= id %>"
  // ),
  VectorDeal_ScenarioSimulate: _.template("/Scenario/MongoSimulate/<%= id %>"),
  VectorDeal_GetAirlines: "/VectorDeal/Airlines",
  VectorDeal_GetSponsors: "/VectorDeal/Sponsors",
  VectorDeal_GetPipelineDeals: "/VectorDeal/PipelineDeals",
  VectorDeal_ExportPortfolio: _.template(
    "/VectorDeal/ExportPortfolioCashflows/<%= dealId %>"
  ),
  VectorDeal_ExportAssetCSV: _.template(
    "/VectorDeal/ExportAssetCSV/<%= scenarioId %>"
  ),
  VectorDeal_ExportMaintInfo: _.template(
    "/VfVector/ExportMaintenanceValues/<%= scenId %>/<%= user %>"
  ),
  VectorDeal_ExportGenericValues: "/VfVector/ExportGenericValues",
  VectorDeal_GetExportHistory: "/VectorDeal/GetExportHistory",
  VectorDeal_DownloadExportFile: "/VectorDeal/DownloadExportFile",
  VectorDeal_DeleteExportFile: "/VectorDeal/DeleteExportFile",

  VectorDeal_GetPortfolioCashflowHistory: _.template(
    "/VectorDeal/GetPortfolioCashflowHistory/<%= dealId %>"
  ),

  AircraftPurchasePriceUpdateInfo: _.template(
    "/Component/AircraftPurchasePrice/<%=Id%>/<%=user%>"
  ),
  EnginePurchasePriceUpdateInfo: _.template(
    "/Component/EnginePurchasePrice/<%=Id%>/<%=user%>"
  ),

  VfVector_GenericPageInfo: "/VfVector/GenericPageInfo",
  VfVector_GenericAcApuCombo: _.template(
    "/VfVector/GenericAcApuCombo/<%= AircraftId %>/<%= ApuId %>"
  ),
  VfVector_GenericAcEngCombo: _.template(
    "/VfVector/GenericAcEngCombo/<%= AircraftId %>/<%= EngineId %>"
  ),
  VfVector_GenericFileUpload: "/VfVector/UploadGenericValues",

  // VectorDeal_GetExpectedLoss: _.template(
  //   "/VectorDeal/GetDealExpectedLoss/<%= dealId %>"
  // ),
  // LiabilityModel_GetExpectedLoss: _.template(
  //   "/LiabilityModel/GetDealExpectedLoss/<%= dealId %>"
  // ),

  LiabilityModel_GetDealLiabilityModelOutputByDealId: _.template(
    "/LiabilityModel/GetDealLiabilityModelOutput"
  ),
  LiabilityModel_GetDealLiabilityModelOutputsByDealIds: _.template(
    "/LiabilityModel/GetDealsLiabilityModelOutputs"
  ),
  LiabilityModel_GetAllDealsLiabilityModelOutputs:
    "/LiabilityModel/GetAllDealsLiabilityModelOutputs",
  LiabilityModel_GetLiabilityArchiveSelectView:
    "/LiabilityModel/GetLiabilityArchiveSelectView",
  LiabilityModel_GetDealsPageView: "/LiabilityModel/GetLiabilityDealView",
  LiabilityModel_UploadLiabilityFile: "/LiabilityModel/UploadFile",
  LiabilityModel_ExportLiabilityFile: "/LiabilityModel/ExportLiablityModelFile",

  LiabilityModel_GetPerformanceMonitor: "/LiabilityModel/GetPerformanceMonitor",

  LiabilityModel_GetLiabilityModelExtras:
    "/LiabilityModel/GetLiabilityModelExtras",
  LiabilityModel_PostLiabilityModelExtras:
    "/LiabilityModel/PostLiabilityModelExtras",

  ExpectedLoss_GetExpectedLoss: _.template(
    "/ExpectedLoss/GetDealExpectedLoss/<%= dealId %>"
  ),
  ExpectedLoss_RunExpectedLossSimulationByDealId:
    "/ExpectedLoss/CalculateExpectedLoss",
  ExpectedLoss_ExportExpectedLoss: _.template(
    "/ExpectedLoss/ExportExpectedLossData/<%= dealId %>"
  ),
  ExpectedLoss_GetDealsPageView: "/ExpectedLoss/GetExpectedLossRateView",
  ExpectedLoss_GetExpectedLossChartView:
    "/ExpectedLoss/GetExpectedLossChartView",
};
