import { Box, Button } from "@material-ui/core";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Errors from "../Components/Common/Errors";
import RefreshIcon from '@material-ui/icons/Refresh';
import HomeIcon from '@material-ui/icons/Home';
import { Redirect } from "react-router-dom";

const styles = {
  root: {
    width: "100%",
    padding: 20,
    textAlign: "center",
  },
  refreshButton: {
    margin: 20,
    textAlign: "center",
  }
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      error: null, 
      errorInfo: null
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ 
      error: error,
      errorInfo: info,
    });
  }

  render() {
    const { classes } = this.props;

    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <Box className={classes.root} >
          <Errors msg={"Something went wrong. "} />
          <Box>
            {/* <Button
              className={classes.refreshButton}
              variant="outlined"
              color="primary"
              onClick={() =>
                { return (<Redirect to={{ pathname: "/" }}  />)}}
              >
              <HomeIcon />
              <span style={{ marginLeft: 8, marginRight: 8 }}>
                Home
              </span>
            </Button> */}
            <Button
              className={classes.refreshButton}
              variant="outlined"
              color="primary"
              onClick={() => window.location.reload()}>
              <RefreshIcon />
              <span style={{ marginLeft: 8, marginRight: 8 }}>
                Refresh Page
              </span>
            </Button>
          </Box>
          {/* <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details> */}
        </Box>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);