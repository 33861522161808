import _ from "lodash";
import React from "react";

import { IconButton, Tooltip } from "@material-ui/core";

import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import VfSelect from "../../../../Common/UtilComponents/VfSelect";
import ThreeDotButton from "../../../../Common/ThreeDotButton";
import { ReactComponent as ResetIcon } from "../../../../../assets/images/others/ResetIcon.svg";
import { ReactComponent as UpdateIcon } from "../../../../../assets/images/others/UpdateIcon.svg";
import { ReactComponent as ResetIconDisabled } from "../../../../../assets/images/others/ResetIconDisabled.svg";
import { ReactComponent as UpdateIconDisabled } from "../../../../../assets/images/others/UpdateIconDisabled.svg";
import { ReactComponent as CopaIcon } from "../../../../../assets/images/airlines/CopaIcon2.svg";
import { ReactComponent as EtihadIcon } from "../../../../../assets/images/airlines/EtihadIcon2.svg";
import { ReactComponent as SpiceJetIcon } from "../../../../../assets/images/airlines/SpiceJetIcon2.svg";
import moment from "moment";

function generateColumns(warning) {
  return [
    {
      label: "Serial Number",
      caption: null,
      field: "serialNumber",
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) => {
        if (!value) return constants.___;
        else if (value === "Total") return "Total";
        else {
          let style = { paddingLeft: indent };
          let mouseover = constants.CASH_FLOWS;
          let filt = warning.filter((x) => {
            return x.msn == value.serialNumber;
          });
          if (filt.length > 0) {
            filt = filt[0];
            mouseover =
              'Scenario "' +
              filt.scenarioName +
              '" is from ' +
              filt.oldScenarioDate +
              ". Newer version as of " +
              filt.newScenarioDate +
              " exists";
            style.backgroundColor = "#F9C0BD";
            //change selectedscenarioId here? (new/old in warning)
          }
          let baseDealInfo = `/deal/assetModel/${value.asset.id}?dealId=${value.deal.dealId}&dealName=${value.deal.dealName}`;
          let assetInfo = `&assetType=${value.asset.assetType}&serialNumber=${value.asset.serialNumber}`;
          let selectedScenarioInfo = `&selected=Cashflows&scenarioId=${value.selectedScenarioId}&scenarios=${value.scenarioIds}`;

          return utils.renderLink(
            utils.renderCellValue(
              value.serialNumber,
              style,
              constants.TEXT_CELL
            ),
            {
              to: {
                pathname: baseDealInfo + assetInfo + selectedScenarioInfo,
                // state: value.linkProps.state,
              },
              disabled: !value.linkProps.disabled,
            },
            mouseover,
            value.serialNumber
          );
        }
      },
    },
    {
      label: "Asset Model",
      caption: null,
      field: "assetModel",
      align: constants.LEFT,
      indent: 16,
      content: (values, indent) => {
        if (!values) return constants.___;
        return utils.renderCellValue(
          utils.mapComponentName(values.assetModel, values.engineModel),
          { paddingLeft: indent },
          constants.TEXT_CELL
        );
      },
    },
    {
      label: "Lessee",
      caption: null,
      field: "lessee",
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) => {
        // will be refactored later
        if (!value) return constants.___;
        value =
          value &&
          (value.includes("Copa") ? (
            <CopaIcon />
          ) : value.includes("Etihad") ? (
            <EtihadIcon />
          ) : value.includes("Spice") ? (
            <SpiceJetIcon />
          ) : (
            value
          ));
        return utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        );
      },
    },

    {
      label: "Lease Term",
      caption: null,
      field: "leaseTerm",
      align: constants.RIGHT,
      indent: 16,
      content: (items, indent) =>
        utils.renderMultiValueCellValue(
          items,
          indent,
          140,
          null,
          false,
          constants.RIGHT,
          constants.DATE_CELL
        ),
    },
    {
      label: "D.O.M",
      caption: null,
      field: "dateOfManufacture",
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "Monthly Rent",
      caption: null,
      field: "monthlyRent",
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      label: "Mx Reserve Bal.",
      caption: null,
      field: "mxReserveBalance",
      align: constants.RIGHT,
      indent: 16,
      content: (items, indent) =>
        utils.renderMultiValueCellValue(items, indent, 120, "$", true),
    },
    {
      label: "Half-Life Values",
      caption: null,
      field: "halfLifeValues",
      align: constants.RIGHT,
      indent: 16,
      content: (items, indent) =>
        utils.renderMultiValueCellValue(items, indent, 140, "$"),
    },
    {
      label: "Value to H.L.",
      caption: null,
      field: "valueAboveHalfLife",
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      label: "Value",
      caption: null,
      field: "assetValue",
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      label: "P.O. Value",
      caption: null,
      field: "poValue",
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL,
          "$"
        ),
    },
    {
      label: "Scenario",
      caption: null,
      field: "scenario",
      align: "center",
      indent: 0,
      content: (scenarios) => {
        if (!scenarios) return constants.___;

        return (
          <VfSelect
            id={scenarios.id}
            label={null}
            items={scenarios.scenarioSummaries}
            value={scenarios.value ? scenarios.value : ""}
            type='SCENARIO'
            onSelectChange={scenarios.handleSelectChange}
            minWidth={110}
            disableUnderline={true}
            fontSize={"0.875rem"}
          />
        );
      },
    },
  ];
}

function formatData(
  fleetSummary,
  handleSelectChange,
  deal,
  assetList,
  disabled
) {
  const selectedScenariosId = sessionStorage
    .getItem(deal.dealName)
    .split(",")
    .map((e) => parseInt(e));
  let data = fleetSummary.map((e, idx) => ({
    assetModel: {
      assetModel: e.assetModel,
      engineModel: e.assetModel !== e.engineModel ? e.engineModel : null,
    },
    serialNumber: {
      serialNumber: e.serialNumber,
      linkProps: {
        disabled,
        state: {
          deal,
          assets: deal.assets,
          assetId: _.find(assetList, (o) => o.serialNumber === e.serialNumber
          && o.subseries === e.assetModel)
            .id,
          fromPortfolio: true,
        },
      },
      asset: _.find(assetList, (o) => o.serialNumber === e.serialNumber 
      && o.subseries === e.assetModel
      ),
      scenarioIds: selectedScenariosId,
      selectedScenarioId: selectedScenariosId[idx],
      deal,
    },
    dateOfManufacture: e.dateOfManufacture,
    lessee: e.lessee,
    leaseTerm: [
      { label: "Start", value: e.leaseStartDate },
      { label: "End", value: e.leaseEndDate },
    ],
    monthlyRent: _.round(e.monthlyRent),
    mxReserveBalance: [
      { label: "C.R.", value: _.round(e.cashReserveBalance) },
      { label: "L.C.", value: _.round(e.mxLC) },
    ],
    halfLifeValues: [
      {
        label: "Base",
        value: _.round(e.value.halfLife_Base),
      },
      {
        label: "Market",
        value: _.round(e.value.halfLife_Market),
      },
    ],
    valueAboveHalfLife: _.round(e.value.valueAboveHalfLife),
    assetValue: _.round(e.value.assetValue),
    poValue: _.round(e.value.poValue),
    scenario: {
      id: idx,
      scenarioSummaries: e.scenarioSummaries.map((s) => ({
        id: s.id,
        label: s.name,
        value: s.name,
      })),
      value: _.find(
        e.scenarioSummaries,
        (s) => s.id === selectedScenariosId[idx]
      ).name,
      handleSelectChange: handleSelectChange,
    },
  }));

  return data;
}

function generateTotal(fleetSummary) {
  const halfLifeValues = fleetSummary.map((o) => o.value);
  return {
    assetModel: null,
    serialNumber: "Total",
    dateOfManufacture: null,
    lessee: constants.___,
    leaseTerm: null,
    monthlyRent: _.sumBy(fleetSummary, "monthlyRent"),
    mxReserveBalance: [
      { label: "C.R.", value: _.sumBy(fleetSummary, "cashReserveBalance") },
      { label: "L.C.", value: _.sumBy(fleetSummary, "mxLC") },
    ],
    halfLifeValues: [
      {
        label: "Base",
        value: _.round(_.sumBy(halfLifeValues, "halfLife_Base")),
      },
      {
        label: "Market",
        value: _.round(_.sumBy(halfLifeValues, "halfLife_Market")),
      },
    ],
    valueAboveHalfLife: _.sum(
      fleetSummary.map((o) =>
        o.value.valueAboveHalfLife !== constants.NaN
          ? o.value.valueAboveHalfLife
          : 0
      )
    ),
    assetValue: _.sum(
      fleetSummary.map((o) =>
        o.value.assetValue !== constants.NaN ? o.value.assetValue : 0
      )
    ),
    poValue: _.sum(
      fleetSummary.map((o) =>
        o.value.poValue !== constants.NaN ? o.value.poValue : 0
      )
    ),
    scenario: null,
  };
}
function generateWarning(fleetSummary) {
  var msns = [];
  fleetSummary.forEach((element) => {
    if (!element.scenarioSummaries.some((x) => x.id == element.scenario.id)) {
      var newguy = element.scenarioSummaries.filter((x) => {
        return x.name == element.scenario.name;
      })[0];
      msns.push({
        msn: element.serialNumber,
        scenarioName: element.scenario.name,
        oldScenarioDate: moment
          .utc(element.scenario.scenarioDate)
          .format("YYYY-MM-DD"),
        newScenarioDate: moment.utc(newguy.date).format("YYYY-MM-DD"),
        oldScenarioId: element.scenario.id,
        newScenarioId: newguy.id,
      });
    }
  });
  return msns;
}

export default function mapToViewModel_fleetSummary(
  fleetSummary,
  commonScenarios,
  deal,
  options,
  assetList
) {
  const warning = generateWarning(fleetSummary);
  const columns = generateColumns(warning);
  const isLinkDisabled = options.refreshDisabled;
  const data = formatData(
    fleetSummary,
    options.handleSingleScenarioChangeEvt,
    deal,
    assetList,
    isLinkDisabled
  );

  const total = generateTotal(fleetSummary);

  const selectedScenarios = _.chain(data)
    .map((o) => o.scenario.value)
    .uniq()
    .value();

  const today = new Date(Date.now());
  const asOfDate = new Date(today.setMonth(today.getMonth() - 2));
  const endOfMonth = new Date(
    asOfDate.getFullYear(),
    asOfDate.getMonth() + 1,
    0
  );

  return {
    label: constants.FLEET_SUMMARY,
    caption: `As of ${moment.utc(endOfMonth).format(constants.FULL_YEAR_DATE)}`,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: {
      pagination: false,
      reset: true,
      refresh: true,
      threeDotButton: true,
    },
    optionsItems: {
      // pagination: {
      //   page: 0,
      //   rowsPerPage: 10,
      //   tableName: constants.fleetInfo,
      //   handleChangePage: options.pagination.handleChangePage,
      //   handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
      // },
      threeDotButton: {
        items: [
          {
            id: 0,
            label: constants.CHANGE_COMMON_SCENARIO,
            handleClick: options.handleOpenCommonScenarioDialog,
            // selected:
            //   selectedScenarios.length !== 1 ? null : selectedScenarios[0],
            // subMenuItems: commonScenarios.map((e) => {
            //   return {
            //     action: constants.CHANGE_COMMON_SCENARIO,
            //     label: e,
            //     icon: null,
            //   };
            // }),
          },
          {
            id: 1,
            label: constants.DEFAULT_BASE,
            handleClick: options.handleGetDefaultOutput,
          },
        ],
        content: (options) => <ThreeDotButton options={options} />,
      },
      refresh: {
        disabled: options.refreshDisabled,
        handleClick: options.handleRefresh,
        content: (items) =>
          items.disabled ? (
            <IconButton
              aria-label='reset'
              disabled={items.disabled}
              onClick={items.handleClick}>
              <UpdateIconDisabled />
            </IconButton>
          ) : (
            <Tooltip title='Refresh' arrow>
              <span>
                <IconButton
                  aria-label='refresh'
                  disabled={items.disabled}
                  onClick={items.handleClick}>
                  <UpdateIcon />
                </IconButton>
              </span>
            </Tooltip>
          ),
      },
      reset: {
        disabled: options.resetDisabled,
        handleClick: options.handleReset,
        content: (items) =>
          items.disabled ? (
            <IconButton
              aria-label='reset'
              disabled={items.disabled}
              onClick={items.handleClick}>
              <ResetIconDisabled />
            </IconButton>
          ) : (
            <Tooltip title='Reset' arrow>
              <span>
                <IconButton
                  aria-label='reset'
                  disabled={items.disabled}
                  onClick={items.handleClick}>
                  <ResetIcon />
                </IconButton>
              </span>
            </Tooltip>
          ),
      },
    },
    span: [12, 12, 12],
    columns,
    data,
    total,
  };
}
