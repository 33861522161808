import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loadingPanel: {
    backgroundColor: "#F4F6F8",
    textAlign: "center",
    padding: "20px",
    display: 'flex',  
    justifyContent:'center', 
    alignItems:'center',
  },
}));

const LoadingPanel = () => {
  const classes = useStyles();

  return (
    <Box flexGrow={1} className={classes.loadingPanel}>
      <CircularProgress style={{ color: "#023059" }} />
    </Box>
  );
};

export default LoadingPanel;
