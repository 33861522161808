import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  IconButton,
} from "@material-ui/core";
import {
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Create,
  DeleteForever,
  Edit,
  FirstPage,
  LastPage,
  Visibility,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import MaterialTable from "material-table";
import TableNamePanel from "../../TableNamePanel";
import ThreeDotButton from "../../ThreeDotButton";
import InputFieldComponent from "../../Editing/EditingComponents/InputFieldComponent";
// import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel"
import * as constants from "../../../../constants";
import LeaseRentEditingPanel from "../../Editing/EditingPanels/LeaseRentEditingPanel";
import ShopVisitAssumptionEditingPanel from "../../Editing/EditingPanels/ShopVisitAssumptionEditingPanel";
import DetailPanel from "./DetailPanel";
import XLSX from "xlsx";
import ExportIconSvg from "../../SvgComponents/ExportIconSvg";
import ImportIconSvg from "../../SvgComponents/ImportIconSvg";
import MultiFunctionTable from "../MultiFunctionTable/MultiFunctionTable";

const styles = {
  tableNamePanel: {
    // marginBottom: 16,
    padding: "0 0 8px 0",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  table: {
    height: "100%",
    width: "100%",
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
    padding: "20px 16px",
  },
  headerStyle: {
    color: "#263238",
    textAlign: "center",
    padding: 0,
    height: 40,
    fontSize: 12,
    fontWeight: 600,
    borderTop: "unset",
    boxShadow: "0px 1px 0px 0px rgba(224, 224, 224, 1)",
  },
  rowStyle: {
    backgroundColor: "#FFFFFF",
    borderBottom: "unset",
  },
  datePicker: {
    width: 70,
    fontSize: 14,
    textAlign: "center",
    marginLeft: 20,
  },
  leaseInput: {
    minWidth: "95%",
    fontSize: 14,
    textAlign: "center",
  },
  input: {
    width: 100,
    fontSize: 14,
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  LSTableHead: {
    paddingLeft: 6,
    display: "flex",
    justifyContent: "space-between",
  },
  LSTableTitle: {
    fontWeight: 550,
    color: "#023059",
    fontSize: 14,
    // padding: 10,
  },
  LSfootNotes: {
    fontWeight: 550,
    color: "#969696",
    textAlign: "center",
    // marginTop: -15,
  },
  displayNone: {
    display: "none",
  },
  iconClosed: {
    border: "0.5mm solid #023059",
    color: "#023059",
    borderRadius: "50%",
    fontSize: 12,
    padding: "2px 4px",
    margin: "-3px 0 -6px 0",
  },
  iconOpen: {
    color: "#fff",
    backgroundColor: "#023059",
    borderRadius: "50%",
    fontSize: 12,
    padding: "4px 6px",
    margin: "-12px 0 0 0",
  },
};

// isEditingSection are two code pollution.
// They come from the component: Utilization.jsx and Lease.jsx.
const EditableTable = ({
  table,
  isEditingSection,
  tableNamePanelButtons = [],
  bulkEdit = false,
  deleteOnly = false,
  leaseSummary = false,
  editAll,
  maxHeight,
}) => {
  const {
    columns,
    data,
    sorting,
    // NOT USED ANYWHERE
    errorsOnSubmit,
    isDeletable,
    label,
    props,
    showOptions,
    style,
    threeDotBtnOptions,
    tableName,
    detailPanel,
  } = table;

  const renderInputFieldComponent = (
    data,
    fieldType,
    selectItems,
    errorOnSubmit,
    column
  ) => {
    let value = data.value;
    value = !_.isPlainObject(value)
      ? value
      : (value && value.override) || value.generic;
    const inputProps =
      fieldType === constants.DATE
        ? {
            style: styles.datePicker,
          }
        : {
            style: leaseSummary ? data.columnDef.cellStyle : styles.input,
            min: 0,
          };
    const keyboardButtonProps = {
      style: { padding: 0 },
    };

    // // special case for Redelivery Adjustments table
    let items = null;
    if (typeof selectItems === "function") {
      items = selectItems(data.rowData);
    } else {
      items = selectItems;
    }

    // console.log(data, fieldType, selectItems, errorOnSubmit, column);

    return (
      <InputFieldComponent
        value={value}
        type={fieldType}
        onChange={data.onChange}
        items={items}
        label={[" ` "]}
        // fullWidth={true}
        required={data.columnDef.required}
        inputProps={inputProps}
        keyboardButtonProps={keyboardButtonProps}
        errorOnSubmit={errorOnSubmit}
      />
    );
  };

  for (let i = 0; i < columns.length; i++) {
    if (columns[i].editable === constants.ALWAYS && !columns[i].editComponent) {
      columns[i].editComponent = (rowData) =>
        renderInputFieldComponent(
          rowData,
          columns[i].inputFieldType,
          columns[i].items,
          errorsOnSubmit[columns[i].field],
          columns[i]
        );
    }
  }

  const {
    sectionIdx,
    section,
    funcs: {
      handleValuesUpdate,
      handleRowAdd,
      handleRowDelete,
      validateData,
      handleValueChange,
    },
  } = props;

  const [isDisplayMode, setIsDisplayMode] = useState(true);
  const [openSVAssumptionEdit, setOpenSVAssumptionEdit] = useState(false);
  const [shopVisitRowData, setShopVisitRowData] = useState(null);
  const [editing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);

  const isShopvisit =
    tableName === "shopVisitAssumptions" ||
    tableName === "engineLLPShopVisitAssumptions"
      ? true
      : false;

  useEffect(() => {
    if (!_.isNil(isEditingSection)) setIsDisplayMode(isEditingSection);
    if (editAll) {
      setEditing(true);
      setIsDisplayMode(false);
    }
  }, [isEditingSection, editAll]);

  const options = {
    paging: false,
    search: false,
    sorting: sorting ? sorting : false,
    draggable: false,
    actionsColumnIndex: -1,
    headerStyle: styles.headerStyle,
    rowStyle: styles.rowStyle,
    maxBodyHeight: leaseSummary ? "100%" : maxHeight ? maxHeight : 300,
    showTitle: bulkEdit ? false : true,
    // detailPanelColumnAlignment: 'right',
  };

  const rowEditingOptions = (newData, oldData, handleFunc, funcType) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (funcType !== constants.DELETE_ROW) {
          if (
            !validateData(
              newData,
              columns,
              section,
              tableName,
              constants.ADD_ROW
            )
          )
            return reject();
        }
        resolve();
        if (funcType === constants.UPDATE_ROW) {
          if (oldData) {
            handleFunc(section, sectionIdx, tableName, newData);
          }
        } else if (funcType === constants.ADD_ROW) {
          handleFunc(section, sectionIdx, tableName, newData);
        } else if (funcType === constants.DELETE_ROW) {
          handleFunc(section, sectionIdx, tableName, oldData);
        }
      }, 600);
    });
  };

  const editOptions = {
    isDeletable: (rowData) => {
      return !isDeletable ? true : isDeletable(rowData);
    },
    onRowAdd: (newData) =>
      rowEditingOptions(newData, null, handleRowAdd, constants.ADD_ROW),
    onRowUpdate: (newData, oldData) =>
      rowEditingOptions(
        newData,
        oldData,
        handleValuesUpdate,
        constants.UPDATE_ROW
      ),
    onRowDelete: (oldData) =>
      rowEditingOptions(null, oldData, handleRowDelete, constants.DELETE_ROW),
  };

  const bulkEditOptions = {
    onBulkUpdate: (changes) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!_.isEmpty(changes)) {
            let checkAllChanges = Object.values(changes).map((data) =>
              validateData(
                data.newData,
                columns,
                section,
                tableName,
                constants.UPDATE_ROW
              )
            );
            let totalRows = data.length;
            let changeRows = Object.keys(changes).length;
            if (
              data[0].cyclesAfter === null &&
              data[0].cyclesBefore === null &&
              totalRows !== changeRows
            ) {
              handleModeChange(!isDisplayMode);
              return reject();
            }
            if (checkAllChanges.includes(0)) {
              handleModeChange(!isDisplayMode);
              return reject();
            } else {
              resolve();
              let newChanges = Object.values(changes).map(
                (data) => data.newData
              );
              handleValuesUpdate(section, sectionIdx, tableName, newChanges);
              handleModeChange(!isDisplayMode);
            }
          } else {
            handleModeChange(!isDisplayMode);
            return reject();
          }
        }, 600);
      }),
    onRowDelete: (oldData) =>
      rowEditingOptions(null, oldData, handleRowDelete, constants.DELETE_ROW),
  };

  const deleteOnlyOptions = {
    isDeletable: (rowData) => {
      return !isDeletable ? true : isDeletable(rowData);
    },
    onRowDelete: (oldData) =>
      rowEditingOptions(null, oldData, handleRowDelete, constants.DELETE_ROW),
  };

  const icons = {
    Add: <AddBox />,
    Clear: Clear,
    Check: Check,
    Delete: (props) =>
      props.disabled ? (
        <DeleteForever color='disabled' />
      ) : (
        <DeleteForever color='secondary' />
      ),
    Edit: Create,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    FirstPage: FirstPage,
    LastPage: LastPage,
    SortArrow: ArrowDropUpIcon,
    Export: SaveAltIcon,
    // DetailPanel: ChevronLeft
  };

  const handleModeChange = (isDisplayMode) => {
    setIsDisplayMode(isDisplayMode);
  };

  const handleAddClick = (props) => {
    props.actions[0].onClick();
  };

  const readFilePromise = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const exportFileToExcel = (data, sheetName, fileName) => {
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, fileName);
  };

  const readExcel = (file) => {
    const promise = readFilePromise(file);

    promise.then((data) => {
      if (!_.isEmpty(data)) {
        let returnMe = [];
        for (var i = 0; i < data.length; i++) {
          let addMe = {};
          if (typeof data[i]["From Date"] === "number") {
            addMe.from = moment(
              new Date(Date.UTC(0, 0, data[i]["From Date"]))
            ).format(constants.FULL_YEAR_DATE);
            addMe.to = moment(
              new Date(Date.UTC(0, 0, data[i]["To Date"]))
            ).format(constants.FULL_YEAR_DATE);
            addMe.paymentDate = moment(
              new Date(Date.UTC(0, 0, data[i]["Payment Date"]))
            ).format(constants.FULL_YEAR_DATE);
          } else {
            addMe.from = moment
              .utc(data[i]["From Date"])
              .format(constants.FULL_YEAR_DATE);
            addMe.to = moment
              .utc(data[i]["To Date"])
              .format(constants.FULL_YEAR_DATE);
            addMe.paymentDate = moment
              .utc(data[i]["Payment Date"])
              .format(constants.FULL_YEAR_DATE);
          }
          if (data[i]["PBH Rate"]) {
            addMe.amount = null;
            addMe.pbhRate = data[i]["PBH Rate"];
          } else {
            addMe.amount = data[i]["Rent"];
            addMe.pbhRate = null;
          }
          if (tableName === "extensionRent")
            addMe.selectExtension = parseInt(data[i]["Select Extension"]) - 1;
          returnMe.push(addMe);
        }
        handleValueChange("leasePanelUpdate")(
          section,
          sectionIdx,
          tableName,
          returnMe
        );
      }
    });
  };

  const exportFile = () => {
    // label === constants.LEASE_RENT || label === "Extension Rent"
    let data = {
      "From Date": null,
      "To Date": null,
      "Payment Date": null,
      Rent: null,
      "Payment Frequency": null,
      "PBH Rate": null,
    };

    let filename = `Lease-Rent-Template.xlsx`;

    if (label === "Extension Rent") {
      data["Select Extension"] = null;
      filename = "Lease-Extension-Rent-Template.xlsx";
    }

    exportFileToExcel([data], "sheet1", filename);
  };

  const readExcelRA = (file) => {
    const promise = readFilePromise(file);

    promise.then((data) => {
      if (!_.isEmpty(data)) {
        data.forEach((e) => {
          if (typeof e.dates === typeof 1)
            e.dates = moment
              .utc(new Date(Date.UTC(0, 0, e.dates - 1)))
              .format("YYYY-MM-DDTHH:mm:ss");
        });
        handleValueChange("Import")(section, sectionIdx, tableName, data);
      }
    });
  };

  const exportFileRA = () => {
    let filename = `ReserveBalanceTemplate.xlsx`;
    let data = [];

    //if data already exists just use it
    //get column headers (table.columns[].field)
    if (table.data.length >= 1) {
      //deep copy
      data = JSON.parse(JSON.stringify(table.data));
      data.forEach((e) => {
        delete e.id;
        delete e.total;
        delete e.tableData;
      });
    } else {
      let cols = {};
      table.columns.forEach((e) => {
        if (e.field !== "total") cols[e.field] = null;
      });
      data.push(cols);
    }

    exportFileToExcel(data, "sheet1", filename);
  };
  //jank function to import reserves from MRR table in lease
  const retrieveRA = () => {
    var { lease } = props;
    let reserveAccounts = lease.reserveAccounts;
    let newReserves = [];
    let accountcount = 1;

    //loop through lease.componentLeaseInfo
    // if maintenanceReserveRates . any where rateType = "Cash"
    lease.componentLeaseInfo.forEach((x) => {
      x.maintenanceReserveRates.forEach((y) => {
        if (
          y.rateType === "Cash" &&
          !newReserves.some((z) =>
            z.components.some((a) => a === x.componentName)
          )
        ) {
          // Create template {id, components}
          let compname = x.componentName;
          if (constants.LGS.some((x) => compname.includes(x)))
            compname = "LG " + compname;
          newReserves.push({ id: accountcount, components: [compname] });
          accountcount++;
        }
      });
    });
    // now somehow get to the update function with the new reserves
    if (!_.isEqual(lease.reserveAccounts, newReserves)) {
      lease.reserveAccounts = newReserves;
      handleValueChange("Retrieve")(
        section,
        sectionIdx,
        tableName,
        newReserves
      );
    }
  };

  const commonThreeDotBtnOptions = {
    edit: {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
      handleClick: () => handleModeChange(!isDisplayMode),
    },
    view: {
      id: 0,
      icon: Visibility,
      label: constants.VIEW,
    },
    add_row: {
      id: 1,
      icon: AddBox,
      label: constants.ADD_ROW,
    },
    export: (exportFunc) => ({
      id: 2,
      icon: ExportIconSvg,
      label: `Export Template`,
      handleClick: () => {
        exportFunc();
      },
    }),
    import: (importFunc) => ({
      id: 3,
      icon: ImportIconSvg,
      label: `Import Account`,
      handleClick: (file) => {
        importFunc(file);
      },
    }),
    retrieve: (retrieveFunc) => ({
      id: 4,
      icon: ImportIconSvg,
      label: "Retrieve From RR",
      handleClick: () => {
        retrieveFunc();
      },
    }),
  };

  const disableThreeDotWhenEditing = (props) => {
    const update = "update";
    setEditing(
      _.find(props.data, (o) => {
        return o.tableData.editing === update;
      })
    );
  };

  const customComponents = {
    Toolbar: (props) => {
      // When a user is editing a row, the three dot button will be disabled.
      disableThreeDotWhenEditing(props);

      const editOption = commonThreeDotBtnOptions.edit;

      const defaultOptions = [
        commonThreeDotBtnOptions.view,
        commonThreeDotBtnOptions.add_row,
      ];

      const ReserveAccountOptions = [
        commonThreeDotBtnOptions.view,
        commonThreeDotBtnOptions.add_row,
        commonThreeDotBtnOptions.export(exportFileRA),
        commonThreeDotBtnOptions.import(readExcelRA),
        commonThreeDotBtnOptions.retrieve(retrieveRA),
      ];

      const leaseRentOptions = [
        commonThreeDotBtnOptions.view,
        {
          id: 1,
          icon: AddBox,
          label: "Add Lease Rent",
          handleClick: () => setOpen(!open),
        },
        commonThreeDotBtnOptions.export(exportFile),
        commonThreeDotBtnOptions.import(readExcel),
      ];

      // For the options View, Edit and Add (row),
      // the handler functions are only accessible in tqs component.
      // For custom options, only labels are defined by the time they've reached here.
      // Thus, we need use the following logic to populate the handlers.
      const options =
        label === constants.LEASE_RENT || label === "Extension Rent"
          ? leaseRentOptions
          : label === constants.Cash_Reserve_Accounts
          ? ReserveAccountOptions
          : threeDotBtnOptions || defaultOptions;

      for (var i = 0; i < options.length; i++) {
        if (options[i].label === constants.VIEW) {
          options[i].handleClick = () => handleModeChange(!isDisplayMode);
        }
        if (
          options[i].label === constants.ADD ||
          options[i].label === constants.ADD_ROW
        ) {
          options[i].handleClick = () => handleAddClick(props);
        }
      }

      return (
        <Box style={styles.tableNamePanel}>
          {props.title && (
            <TableNamePanel
              label={props.title}
              buttons={
                !editing && showOptions
                  ? [
                      ...tableNamePanelButtons,
                      <ThreeDotButton
                        options={isDisplayMode ? [editOption] : options}
                      />,
                    ]
                  : null
              }
              caption={table.caption}
            />
          )}
        </Box>
      );
    },
  };

  if (isShopvisit && !isDisplayMode)
    customComponents["Actions"] = (props) => {
      const deleteControls = _.isFunction(
        props.actions[props.actions.length - 1]
      )
        ? props.actions[props.actions.length - 1](props.data)
        : false;
      return deleteControls ? (
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <IconButton
            onClick={(event) => {
              setShopVisitRowData(props.data);
              setOpenSVAssumptionEdit(true);
            }}>
            <Edit style={{ color: "black" }} />
          </IconButton>
          <IconButton
            disabled={deleteControls.disabled}
            onClick={(event) => {
              deleteControls.onClick(event, props.data);
            }}>
            <DeleteForever
              color={deleteControls.disabled ? "disabled" : "secondary"}
            />
          </IconButton>
        </span>
      ) : (
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <IconButton
            onClick={(event) => {
              props.actions[0].onClick(event, props.data);
            }}>
            <Check style={{ color: "black" }} />
          </IconButton>
          <IconButton
            disabled={deleteControls.disabled}
            onClick={(event) => {
              props.actions[1].onClick(event, props.data);
            }}>
            <Clear style={{ color: "black" }} />
          </IconButton>
        </span>
      );
    };

  const leaseSummaryComponents = {
    Toolbar: (props) => {
      // When a user is editing a row, the three dot button will be disabled.
      disableThreeDotWhenEditing(props);

      const editOption = commonThreeDotBtnOptions.edit;

      const defaultOptions = [
        commonThreeDotBtnOptions.view,
        commonThreeDotBtnOptions.add_row,
      ];

      const options = threeDotBtnOptions || defaultOptions;
      for (var i = 0; i < options.length; i++) {
        if (options[i].label === constants.VIEW) {
          options[i].handleClick = () => handleModeChange(!isDisplayMode);
        }
        if (
          options[i].label === constants.ADD ||
          options[i].label === constants.ADD_ROW
        ) {
          options[i].handleClick = () => handleAddClick(props);
        }
      }

      return (
        props.title && (
          <Box style={styles.LSTableHead}>
            <Box style={{ padding: "10px 0 0 10px" }}>
              <Typography variant='subtitle1' style={styles.LSTableTitle}>
                {props.title}
              </Typography>
              <Typography variant='caption' style={styles.LSfootNotes}>
                {table.caption}
              </Typography>
            </Box>
            {!editing && showOptions ? (
              <ThreeDotButton
                options={isDisplayMode ? [editOption] : options}
              />
            ) : null}
          </Box>
        )
      );
    },
  };

  const bulkEditComponents = {
    Action: (props) => {
      return null;
    },

    Toolbar: (props) => {
      // When a user is editing a row, the three dot button will be disabled.
      disableThreeDotWhenEditing(props);

      const editOption = [
        {
          id: 0,
          icon: props.actions[1].icon,
          label: props.actions[1].tooltip,
          handleClick: () => {
            handleModeChange(!isDisplayMode);
            props.actions[1].onClick();
          },
        },
      ];

      if (tableName === constants.engineLLPStatus) {
        editOption.push(
          threeDotBtnOptions[1],
          threeDotBtnOptions[2],
          threeDotBtnOptions[3]
        );
      }
      if (tableName === "Partout Events") {
        editOption.push(threeDotBtnOptions[1]);
      }

      const options = [];
      for (var i = 2; i < props.actions.length; i++) {
        options.push({
          id: i,
          icon: props.actions[i].icon,
          label:
            props.actions[i].tooltip === "Save all changes"
              ? "Save All"
              : props.actions[i].tooltip === "Discard all changes"
              ? "Discard All"
              : props.actions[i].tooltip,
          handleClick:
            i === 2
              ? () => {
                  handleModeChange(!isDisplayMode);
                  props.actions[2].onClick();
                }
              : () => {
                  handleModeChange(!isDisplayMode);
                  props.actions[3].onClick();
                },
        });
      }

      return (
        <Box style={styles.tableNamePanel}>
          {props.title && (
            <TableNamePanel
              label={props.title}
              buttons={
                !editing && showOptions
                  ? [
                      // <MTableToolbar {...props} />,
                      ...tableNamePanelButtons,
                      <ThreeDotButton
                        options={isDisplayMode ? editOption : options}
                      />,
                    ]
                  : null
              }
              caption={table.caption}
            />
          )}
        </Box>
      );
    },
  };

  if (tableName === constants.utilizationByDate) {
    styles.table.padding = "5px 16px";
  }

  const boxStyle = { width: "100%" };
  if (!isDisplayMode && tableName === "extensionRent") boxStyle.zIndex = 3;

  return (
    <Box style={boxStyle}>
      <MaterialTable
        style={
          style
            ? { ...styles.table, ...style }
            : leaseSummary
            ? { all: "unset" }
            : styles.table
        }
        title={label}
        columns={columns}
        data={data}
        options={options}
        editable={
          // deleteOnlyOptions
          bulkEdit
            ? bulkEditOptions
            : deleteOnly
            ? deleteOnlyOptions
            : isDisplayMode || isEditingSection
            ? null
            : editOptions
        }
        icons={icons}
        components={
          bulkEdit
            ? bulkEditComponents
            : leaseSummary
            ? leaseSummaryComponents
            : customComponents
        }
        localization={
          bulkEdit
            ? {
                header: {
                  actions: "",
                },
              }
            : {}
        }
        detailPanel={
          detailPanel && detailPanel.shouldRender
            ? [
                (rowData) => {
                  rowData.isDisplayMode = isDisplayMode;
                  let renderData = detailPanel.detailPanelData(rowData);
                  return {
                    disabled: !rowData.swap,
                    icon: () =>
                      rowData.swap ? (
                        <Box style={{ maxHeight: 20 }}>
                          <Box style={styles.iconClosed}>
                            {detailPanel.panelLetter}
                          </Box>
                          <KeyboardArrowDown color='primary' />
                        </Box>
                      ) : null,
                    openIcon: () => (
                      <Box style={{ maxHeight: 24, margin: "-24px 0 0 0" }}>
                        <KeyboardArrowUp color='primary' />
                        <Box style={styles.iconOpen}>
                          {detailPanel.panelLetter}
                        </Box>
                      </Box>
                    ),
                    tooltip: rowData.swap ? detailPanel.tooltip : null,
                    render: (rowData) =>
                      renderData ? (
                        <DetailPanel
                          id={rowData.isDisplayMode}
                          detailPanel={detailPanel}
                          renderData={renderData}
                          props={props}
                          tableName={tableName}
                          isDisplayMode={rowData.isDisplayMode}
                        />
                      ) : // <MultiFunctionTable
                      //   tableData={{ ...detailPanel, data: [renderData] }}
                      // />
                      null,
                  };
                },
              ]
            : []
        }
      />
      {label === constants.LEASE_RENT || label === "Extension Rent" ? (
        <Modal
          open={open}
          style={styles.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={open}>
            <LeaseRentEditingPanel
              props={props}
              section={section}
              sectionIdx={sectionIdx}
              tableName={tableName}
              onValuesUpdate={handleValueChange}
              tableData={table}
              onClose={() => setOpen(!open)}
            />
          </Fade>
        </Modal>
      ) : isShopvisit ? (
        <Modal
          open={openSVAssumptionEdit}
          style={styles.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={openSVAssumptionEdit}>
            <ShopVisitAssumptionEditingPanel
              props={props}
              section={section}
              sectionIdx={sectionIdx}
              tableName={tableName}
              onValuesUpdate={handleValueChange}
              data={shopVisitRowData}
              table={table}
              onClose={() => setOpenSVAssumptionEdit(!openSVAssumptionEdit)}
            />
          </Fade>
        </Modal>
      ) : null}
    </Box>
  );
};

export default EditableTable;
