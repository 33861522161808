import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import * as constants from "../../../../../../constants";
import VfChart from "../../../../../Common/UtilComponents/VfChart";
import _ from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import { useMsal } from "@azure/msal-react";
import moment from "moment";

const AppNoRowsOverlay = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50%",
      }}>
      <p severity='info'>No Appraisal Data</p>
    </div>
);
const formatRowData = (ad) => {
    let indexcount = 0;
    let data = [{
        id: indexcount,
        appraiser: ad.valuation.appraiser,
        appraisalDate: moment.utc(ad.valuation.valuationDate).format("MMM-DD-YYYY"),
        valueDate: moment.utc(ad.valuation.valuationDate).format("YYYY"),
        hlBase: _.round(ad.valuation.halfLife_Base,2),
        hlMarket: _.round(ad.valuation.halfLife_Market,2),
        flBase: ad.valuation.fullLife_Base,
        flMarket: ad.valuation.fullLife_Market
    }]
    indexcount++;
    ad.valuation.futureValuations.forEach(f => {
        if(moment.utc(ad.valuation.valuationDate).format("MMM-DD-YYYY") === moment.utc(f.date).format("MMM-DD-YYYY"))
            return;
        indexcount++;
        data.push(
        {
            id: indexcount,
            appraiser: ad.valuation.appraiser,
            appraisalDate: moment.utc(ad.valuation.valuationDate).format("MMM-DD-YYYY"),
            valueDate: moment.utc(f.date).format("YYYY"),
            hlBase: _.round(f.halfLifeBase_constant,2),
            hlMarket: _.round(f.halfLifeMarket_constant,2),
            flBase: _.round(f.fullLifeBase_constant,2),
            flMarket: _.round(f.fullLifeMarket_constant,2)
        })
    });
    let total = data;

    if(ad.additionalValuations != null && ad.additionalValuations.length > 0){
        ad.additionalValuations.forEach(v => {
            indexcount ++;
            let newdata = [{
                id: indexcount,
                appraiser: v.appraiser,
                appraisalDate: moment.utc(v.valuationDate).format("MMM-DD-YYYY"),
                valueDate: moment.utc(v.valuationDate).format("YYYY"),
                hlBase: _.round(v.halfLife_Base,2),
                hlMarket: _.round(v.halfLife_Market,2),
                flBase: v.fullLife_Base,
                flMarket: v.fullLife_Market
            }];
            v.futureValuations.forEach(f => {
                if(moment.utc(v.valuationDate).format("MMM-DD-YYYY") === moment.utc(f.date).format("MMM-DD-YYYY"))
                    return;
                indexcount++;
                newdata.push({
                    id: indexcount,
                    appraiser: v.appraiser,
                    appraisalDate: moment.utc(v.valuationDate).format("MMM-DD-YYYY"),
                    valueDate: moment.utc(f.date).format("YYYY"),
                    hlBase: _.round(f.halfLifeBase_constant,2),
                    hlMarket: _.round(f.halfLifeMarket_constant,2),
                    flBase: _.round(f.fullLifeBase_constant,2),
                    flMarket: _.round(f.fullLifeMarket_constant,2)
                });
            });
            total = total.concat(newdata);
        });//end foreach addition val
        
    }
    return total;
}
const formatChartData = (ad) => {
    //for x axis get min val date + max forcast horizon
    //each series: name = (appraiser + valdate +  ALL HL/FL BASE/MARKET)
    //             data = [values [min -> max]]
    let allseries = [];
    let allvals = [];
    let curval = ad.valuation;
    allvals.push(curval);
    if(ad.additionalValuations != null && ad.additionalValuations.length > 0){
        allvals = allvals.concat(ad.additionalValuations);
    }
    //sort em by valdate
    let lastyears = allvals.map(x=>x.futureValuations[x.futureValuations.length-1].year);
    let lastyear = lastyears.reduce((a,b) => {return a > b ? a : b})
    let orderedvals = allvals.sort((a,b)=>{
         return Date.parse(b.valuationDate) - Date.parse(a.valuationDate)
        }
    );
    let earlydate = new Date(Date.parse(orderedvals[0].valuationDate));
    let earliest = earlydate.getFullYear();
    let range=[];
    //add valuation dates including month
    
    let i=earliest;do{range.push(i++)}while(i<=lastyear);
    

    let first = true;
    orderedvals.forEach(v => {
        //HL Base
        let hlbseries = {name: v.appraiser+" | "+moment.utc(v.valuationDate).format("MM-YY")+" | HLB", visible: first, data:[]};
        //HL Market
        let hlmseries = {name: v.appraiser+" | "+moment.utc(v.valuationDate).format("MM-YY")+" | HLM",visible: first, data:[]};
        //FL Base
        let flbseries = {name: v.appraiser+" | "+moment.utc(v.valuationDate).format("MM-YY")+" | FLB", visible: false, data:[]};
        //FL Market
        let flmseries = {name: v.appraiser+" | "+moment.utc(v.valuationDate).format("MM-YY")+" | FLM", visible: false, data:[]};

        first = false;

        range.forEach(y =>{
            let f = v.futureValuations.find((fv) => fv.year == y);
            if(f){
                hlbseries.data.push(_.round(f.halfLifeBase_constant,2));
                hlmseries.data.push(_.round(f.halfLifeMarket_constant,2));
                flbseries.data.push(_.round(f.fullLifeBase_constant,2));
                flmseries.data.push(_.round(f.fullLifeMarket_constant,2));
            }
            else{
                hlbseries.data.push(null);
                hlmseries.data.push(null);
                flbseries.data.push(null);
                flmseries.data.push(null);
            }
        })
        
        allseries.push(hlbseries,hlmseries,flbseries,flmseries);
    });



    let options = {
    chart: {
        type: 'line'
    },
    credits: { enabled: false },
    title: {
        text: 'Appraisal Values Over Time'
    },
    subtitle: {
        text:"constant values"
    },
    xAxis: {
        categories: range
    },
    yAxis: {
        title: {
            text: 'Values ($M)'
        }
    },
    plotOptions: {
        line: {
            dataLabels: {
                enabled: true
            },
            enableMouseTracking: false
        }
    },
    series: allseries
    };


    return options;

}


const Appraisal = ({assetDetails }) => {
    const msalContext = useMsal();
    useEffect(() => {
        setFutureValues(formatRowData(assetDetails));
        setChartOptions(formatChartData(assetDetails));
     }, []);
     const [loading, setLoading] = useState(false);
     const [futureValues, setFutureValues] = useState([]);
     const [chartOptions, setChartOptions] = useState(null);
     const columns = [
        {
          field: "appraiser",
          headerName: "Appraiser",
          flex: 0.2,
          //width: 100,
          editable: false,
          hideable: false,
          // valueGetter: (params) => getFormattedDate(params.row.changedOn),
        },
        {
          field: "appraisalDate",
          headerName: "As Of",
          //width: 100,
          type: "date",
          flex: 0.2,
          editable: false,
          hideable: false,
        },
        {
          field: "valueDate",
          headerName: "Year",
          //width: 100,
          //type: "date",
          flex: 0.2,
          editable: false,
          hideable: false,
        },
        {
            field: "hlBase",
            //headerName: "Half-Life Base Value",
            headerName: "HL Base",
            //width: 300,
            flex: 0.2,
            editable: false,
            hideable: true,
        },
        {
            field: "hlMarket",
            //headerName: "Half-Life Market Value",
            headerName: "HL Market",
            //width: 300,
            flex: 0.2,
            editable: false,
            hideable: true,
        },
        {
            field: "flBase",
            //headerName: "Full-Life Base Value",
            headerName: "FL Base",
            //width: 300,
            flex: 0.2,
            editable: false,
            hideable: true,
        },
        {
            field: "flMarket",
            //headerName: "Full-Life Market Value",
            headerName: "FL Market",
            //width: 300,
            flex: 0.2,
            editable: false,
            hideable: true,
        },

     ];
     return (
       <Box
       >
        <Grid container spacing={2}>
        {/* Chart */}
            <Grid item sm={12}>
                <VfChart
                    options={chartOptions}
                />
            </Grid>
        {/* Data Table */}
            <Grid item sm={12}>
                {/* <Box> table </Box> */}
            <Box
                style={{
                    position: "relative",
                    borderRadius: 12,
                    backgroundColor: "#FFFFFF",
                    padding: 20,
                    height: "100%",
                    boxShadow:
                    "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)",
                }}      
            >
                <DataGrid
                    components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: AppNoRowsOverlay,
                    }}
                    style={{ height: "70vh" }}
                    rows={futureValues}
                    columns={columns}
                    loading={loading}
                    initialState={{
                    sorting: {
                        sortModel: [{ field: "appraisalDate", sort: "desc" }],
                    },
                    }}
                />
            </Box>
            </Grid>
        </Grid>
       </Box>
     );
};
   
   export default Appraisal;