import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";
import * as constants from "../../../../constants";

const updateNotes = async (Id, data, msalContext) => {
  let apiendpoint = apiRoutes.NotesScenario_PostScenarioNotes({ Id });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data,
    },
    msalContext
  );
};

export const scenarioNotes = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
  msalContext
) => {
  if (actionType === constants.UPDATE_ROW) {
    scenario.scenarioComment = newData;
  } else if (actionType === constants.DELETE_ROW) {
    scenario.scenarioComment = null;
  }

  updateNotes(scenario.id, { note: scenario.scenarioComment }, msalContext);

  return scenario;
};
