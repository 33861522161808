import _ from "lodash";
import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import Events from "../ShopVisitAssumptions/Events";
import EditableTable from "../../../../../../Common/Tables/EditableTable/EditableTable";
import {mapToViewModel_ScheduledShopVisits} from "../../..//../../mapToViewModels/common/scheduledShopVisitsViewModel";

const ShopVisitAssumptions = ({
  svAssumptions,
  selectedEvent,
  onEventChange,
  scenario,
  generateEditableProps,
  assetDetails,
}) => {
  let props = generateEditableProps(0,selectedEvent);
  let sectionName = props.section;
  props.isScenario = true;
  let ssv = []
  let componentName = selectedEvent;

  //find the ssv for the selected event
  let esns = assetDetails.engines.map((e) => e.serialNumber);
  let positions = assetDetails.engines.map((e) => e.position);
  if(esns.some(s => sectionName.includes(s))){
    //find the engine position of said engine
    let esn = esns.filter(e => sectionName.includes(e) )
    if(esn.length >= 1){
        esn = esn[0];
    }
    let ind = esns.indexOf(esn);
    let position = positions[ind];
    //EPR
    if(sectionName.includes("EPR -")){
        componentName = "EPR "+position;
    }
    //ELLP
    else if (sectionName.includes("ELLP -")){
        componentName = "LLPStack "+position;
    }
    //OTHER
    else{
        componentName = sectionName.slice(0,sectionName.indexOf(" -")) + " " +position;
    }
  }
  if(sectionName.includes("LG")){
    let lgs = assetDetails.landingGears;
    let lgnames = lgs.map((l) => l.position);
    let sub = sectionName.replace("LG - ", "");
    componentName =  lgnames[lgnames.findIndex(e=> e.includes(sub))];
  }
  if(scenario.scenarioShopvisits != null && scenario.scenarioShopvisits.length >= 1){
    let targets = scenario.scenarioShopvisits.filter(x=>x.component == componentName)
    if(targets.length > 0)
      ssv = targets[0].scheduledVisitOverrides;
  }
  
  // scenario.scenarioShopvisits != null && scenario.scenarioShopvisits.length >= 1 
  //   ? scenario.scenarioShopvisits.map((o)=>{ if(o.Component == selectedEvent) return o.scheduledVisitOverrides}) : [];
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Box style={{ display: "flex", justifyContent: "flex-start" }}>
            <Events
              events={svAssumptions}
              onEventChange={onEventChange}
              selected={selectedEvent}
            />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Paper style={{ borderRadius: 12, height: "100%" }}>
            <EditableTable
              table={
                _.find(svAssumptions, (o) => o.label === selectedEvent).value
              }
            />
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <EditableTable table={mapToViewModel_ScheduledShopVisits(ssv,props)} />
      </Grid>
      </Grid>
    </Box>
  );
};

export default ShopVisitAssumptions;
