import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import apiRoutes from "../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../utils/ApiRequest";
import * as mapToViewModels from "../mapToViewModels";

import MultiFunctionTable from "../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import LoadingPanel from "../../Common/LoadingPanel";
import { useMsal } from "@azure/msal-react";

const CashflowHistory = ({ deal, cashflowHistoryRenderKey }) => {
  const [tableData, setTableData] = useState([]);
  const msalContext = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiRequest(
        {
          url: apiRoutes.VectorDeal_GetPortfolioCashflowHistory({
            dealId: deal.dealId,
          }),
          method: "GET",
        },
        msalContext
      );

      if (result.status === response.OK) {
        let sortedData = _.sortBy(result.data, ["asOfDateUtc"]).reverse();
        setTableData(mapToViewModels.cashflowHistory(sortedData));
      }
    };
    fetchData();
  }, [deal.dealId, cashflowHistoryRenderKey]);

  return !_.isEmpty(tableData) ? (
    <Box flexGrow={1} style={{ margin: "20px 0 0 0" }}>
      <Grid container spacing={2}>
        <MultiFunctionTable tableData={tableData} />
      </Grid>
    </Box>
  ) : (
    <Box style={{ paddingTop: 20 }}>
      <LoadingPanel />
    </Box>
  );
};

export default CashflowHistory;
