import React from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SingleFieldEditingCard from "../EditingComponents/SingleFieldEditingCard";

export const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const SingleFieldFadeInEditingPanel = ({
  id,
  open,
  overlines,
  body,
  captions,
  onClose,
  required,
  tableName,
  type,
  props,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <SingleFieldEditingCard
          id={id}
          overlines={overlines}
          body={body}
          captions={captions}
          tableName={tableName}
          onClose={onClose}
          required={required}
          type={type}
          props={props}
        />
      </Fade>
    </Modal>
  );
};

export default SingleFieldFadeInEditingPanel;
