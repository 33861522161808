import { generateGenericComponent } from "./genericComponent";

export const generateAPU = (genericComponent, apuInfo) => {
  const { apuModel, serialNumber } = apuInfo;
  const returnMe = {
    manufacturer: null,
    model: null,
    serialNumber: null,
    genericId: 0,
    genericMxInfo: [],
    shopVisitHistory: [],
    maintenanceStatus: [],
    componentLeaseInfo: [],
    maintenanceEvents: [],
    utilizationHistory: [],
    componentType: genericComponent.componentType.type,
  };

  returnMe.genericId = genericComponent.id;
  returnMe.model = apuModel.model;
  returnMe.manufacturer = apuModel.manufacturer;
  returnMe.serialNumber = serialNumber;
  returnMe.genericMxInfo = generateGenericComponent(genericComponent);

  return returnMe;
};
