import * as constants from "../../../constants";
import { default as mapToViewModel_AirframeGeneric } from "./GenericAirframe/index";
import { default as mapToViewModel_EngineGeneric } from "./GenericEngine/index";
import { default as mapToViewModel_APUGeneric } from "./GenericAPU/index";
import { default as mapToViewModel_CombinationsGeneric } from "./GenericCombinations/index";

export function mapToViewModel(
  data,
  componentType,
  handleSelectChange,
  props,
  selectedValue,
) {
  switch (componentType) {
    case constants.Airframe:
      return mapToViewModel_AirframeGeneric(data, handleSelectChange, props, selectedValue);
    case constants.Engine:
      return mapToViewModel_EngineGeneric(data, handleSelectChange, props, selectedValue);
    case constants.apUs:
      return mapToViewModel_APUGeneric(data, handleSelectChange, props, selectedValue);
    case "Combinations":
      return mapToViewModel_CombinationsGeneric(data, props);
    default:
      break;
  }
}