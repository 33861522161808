import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import * as constants from "../../../../../../constants";
import Airframe from "./Airframe";
import APU from "./APU";
import Engines from "./Engines/Engines";
import LandingGear from "./LandingGear";
import AssetSummaries from "./AssetSummaries/AssetSummaries";
import Appraisal from "./Appraisal";
import Errors from "../../../../../Common/Errors";
import RichTextInputComponent from "../../../../../Common/Editing/EditingComponents/RichTextInputComponent";
import VfAlertDialogBox from "../../../../../Common/UtilComponents/VfAlertDialogBox";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ThreeDotButton from "../../../../../Common/ThreeDotButton";

const renderSection = (
  assetDetails,
  generateEditableProps,
  scenario,
  selected
) => {
  switch (selected.label.toUpperCase()) {
    case constants.Airframe.toUpperCase():
      return (
        <Airframe
          assetDetails={assetDetails}
          generateEditableProps={generateEditableProps}
          scenario={scenario}
        />
      );
    case constants.Engine.toUpperCase():
    case constants.Engines.toUpperCase():
      return (
        <Engines
          assetDetails={assetDetails}
          engines={assetDetails.engines}
          generateEditableProps={generateEditableProps}
          scenario={scenario}
          selectedEngineInfo={selected}
        />
      );
    case constants.LG.toUpperCase():
      return (
        <LandingGear
          landingGears={assetDetails.landingGears}
          generateEditableProps={generateEditableProps}
          scenario={scenario}
        />
      );
    case constants.APU.toUpperCase():
      return (
        <APU
          assetDetails={assetDetails}
          apus={assetDetails.apUs}
          generateEditableProps={generateEditableProps}
          scenario={scenario}
        />
      );
    case constants.APPRAISAL.toUpperCase():
      return (
        <Appraisal
        assetDetails={assetDetails}
        />
      );
    case "Summary".toUpperCase():
      return (
        <AssetSummaries
          assetDetails={assetDetails}
          generateEditableProps={generateEditableProps}
        />
      );
    default:
      return null;
  }
};

const Asset = ({
  assetDetails,
  errors,
  scenario,
  selected,
  generateEditableProps,
}) => {
  const [isAlertDialogOpen, setisAlertDialogOpen] = useState(false);
  const props = generateEditableProps(1, "assetNotes");

  let threeDotBtnOptions = [
    {
      id: 1,
      icon: DeleteForeverIcon,
      label: "Delete Note",
      handleClick: () => setisAlertDialogOpen(true),
    },
  ];

  return errors.assetError ? (
    <Box mt={4}>
      <Errors />
    </Box>
  ) : (
    <Box>
      <VfAlertDialogBox
        isOpen={isAlertDialogOpen}
        handleAlertDialogVisible={setisAlertDialogOpen}
        dialogTitle={"Confirm Delete Notes"}
        dialogBody={"Are you sure you would like to delete asset notes?"}
        onAccept={() => {
          props.funcs.handleRowDelete(
            props.section,
            props.sectionIdx,
            "assetNotes",
            null
          );
          setisAlertDialogOpen(false);
        }}
      />

      <Box
        style={{
          backgroundColor: "#E3F2FD",
          padding: 20,
          marginBottom: 20,
          borderRadius: 12,
          minHeight: 150,
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Box>
          <Typography
            variant='h4'
            style={{ color: "#023059", fontSize: 12, opacity: "70%" }}>
            {"ASSET NOTES"}
          </Typography>
          <RichTextInputComponent
            value={assetDetails.assetNotes}
            onChange={(value) =>
              props.funcs.handleValuesUpdate(
                props.section,
                props.sectionIdx,
                "assetNotes",
                value
              )
            }
            heightLimit={55}
          />
        </Box>
        <Box style={{ maxWidth: 20, marginRight: 20 }}>
          <ThreeDotButton options={threeDotBtnOptions} />
        </Box>
      </Box>

      {renderSection(assetDetails, generateEditableProps, scenario, selected)}
      <br />
    </Box>
  );
};

export default Asset;
