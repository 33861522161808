import moment from "moment";
import React from "react";
import { Box, Typography } from "@material-ui/core";

import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

import NumberFormat from "react-number-format";

import { mapToViewModel_ShopVisitHistory } from "./shopVisitHistoryViewModel";
import { mapToViewModel_EngineLLPShopVisitAssumptions } from "../enginePR/engineLLPsAssumptionsViewModel";
import { mapToViewModel_ShopVisitAssumptions } from "./shopVisitAssumptionsViewModel";
import { mapToViewModel_ScheduledShopVisits } from "./scheduledShopVisitsViewModel";

export const styles = {
  tableNamePanel: {
    margin: 10,
    marginBottom: 5,
    backgroundColor: "#f6f6f6",
  },
  cellStyle: {
    color: "#023059",
    textAlign: "center",
    fontSize: 11,
    height: 40,
    padding: 0,
    borderBottom: "unset",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  boldCellStyle: {
    fontWeight: "bold",
    fontSize: 12,
  },
};

export const renderCell =
  (rowData) =>
  (field, fieldType, swap = false, prefix) => {
    const override = !rowData[field] ? null : rowData[field].override;
    var getComment = null;

    if (field.includes("interval")) {
      getComment = rowData.intOverrideComment;
    } else if (field.includes("cost") || field.includes("Cost")) {
      getComment = rowData.costOverrideComment;
    } else if (field.includes("Down")) {
      getComment = rowData.downOverrideComment;
    }

    const styledOverride =
      override || override === 0 ? (
        field === "costEsc" || field === "escalationRate" ? (
          <NumberFormat
            thousandSeparator={true}
            displayType={"text"}
            value={(override * 100).toFixed(2)}
            allowLeadingZeros={true}
            prefix={null}
            suffix={"%"}
          />
        ) : fieldType === constants.NUMBER ? (
          utils.formatNumber(override, prefix)
        ) : (
          moment.utc(override).format(constants.DATE_FORMAT)
        )
      ) : null;

    const generic = !rowData[field] ? null : rowData[field].generic;

    const styledGeneric =
      generic || generic === 0 ? (
        field === "costEsc" || field === "escalationRate" ? (
          <NumberFormat
            thousandSeparator={true}
            displayType={"text"}
            value={(generic * 100).toFixed(2)}
            allowLeadingZeros={true}
            prefix={null}
            suffix={"%"}
          />
        ) : fieldType === constants.NUMBER ? (
          utils.formatNumber(generic, prefix)
        ) : (
          moment.utc(generic).format(constants.DATE_FORMAT)
        )
      ) : (
        constants.___
      );

    const render = () => {
      return (
        <Box>
          <Typography
            variant='caption'
            display='block'
            style={{
              color: "rgba(0,0,0,0.38)",
              fontSize: 10,
            }}>
            {override || override === 0
              ? override !== generic
                ? styledGeneric
                : null
              : null}
          </Typography>
          <Typography
            variant='subtitle1'
            style={{
              color: "rgba(0,0,0,0.87)",
              fontSize: 13,
            }}>
            {!override && !generic && generic !== 0
              ? constants.___
              : styledOverride || styledGeneric}
          </Typography>
        </Box>
      );
    };

    return swap ? <s>{render()}</s> : render();
  };

const editableTables = {
  shopVisitHistory: mapToViewModel_ShopVisitHistory,
  scheduledShopVisits: mapToViewModel_ScheduledShopVisits,
  shopVisitAssumptions: mapToViewModel_ShopVisitAssumptions,
  engineLLPShopVisitAssumptions: mapToViewModel_EngineLLPShopVisitAssumptions,
};

export default editableTables;
