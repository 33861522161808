import _ from "lodash";
import * as constants from "../../../constants";
import { apiRequest, response } from "../../../utils/ApiRequest";
import apiRoutes from "../../../utils/ApiRoutes";

const addACAPUCombo = async (AircraftId, ApuId, msalContext) => {
  let apiendpoint = apiRoutes.VfVector_GenericAcApuCombo;
  apiendpoint = apiendpoint({ AircraftId, ApuId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
    },
    msalContext
  );
};

const addACENGCombo = async (AircraftId, EngineId, msalContext) => {
  let apiendpoint = apiRoutes.VfVector_GenericAcEngCombo;
  apiendpoint = apiendpoint({ AircraftId, EngineId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
    },
    msalContext
  );
};

export const combinationUpdater = (
  tableName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  genericData,
  msalContext
) => {
  let aircraftData = genericData.airframes;
  let apuData = genericData.apUs;
  let enginesData = genericData.engines;
  const acapuCombos = [...genericData.acApuCombos];
  const acengCombos = [...genericData.acEngCombos];

  const genericacapuComboTemplate = {
    aircraftSubseries: null,
    apuModel: null,
    genericAircraftId: null,
    genericApuId: null,
  };

  const genericacengComboTemplate = {
    aircraftSubseries: null,
    engineSubseries: null,
    genericAircraftId: null,
    genericEngineId: null,
  };

  let getACgenericId = _.find(aircraftData, function (o) {
    return o.subseries === newData.aircraftSubseries;
  }).id;

  if (tableName === "Aircraft APU Combinations") {
    let getAPUgenericId = _.find(apuData, function (o) {
      return o.model === newData.apuModel;
    });
    if (getAPUgenericId === undefined) {
      getAPUgenericId = newData.genericApuId ? newData.genericApuId : null;
    } else getAPUgenericId = getAPUgenericId.id;

    genericacapuComboTemplate.aircraftSubseries = newData.aircraftSubseries;
    genericacapuComboTemplate.apuModel = newData.apuModel;
    genericacapuComboTemplate.genericAircraftId = getACgenericId;
    genericacapuComboTemplate.genericApuId = getAPUgenericId;

    if (actionType === constants.ADD_ROW) {
      acapuCombos.push(genericacapuComboTemplate);
      addACAPUCombo(getACgenericId, getAPUgenericId, msalContext);
    } else if (actionType === constants.UPDATE_ROW)
      acapuCombos[newData.idx] = genericacapuComboTemplate;
    else if (actionType === constants.DELETE_ROW)
      acapuCombos.splice(newData.idx, 1);
  } else if (tableName === "Aircraft Engine Combinations") {
    let getENGgenericId = _.find(enginesData, function (o) {
      return o.subseries === newData.engineSubseries;
    });
    if (getENGgenericId === undefined) {
      getENGgenericId = newData.genericEngineId
        ? newData.genericEngineId
        : null;
    } else getENGgenericId = getENGgenericId.id;

    genericacengComboTemplate.aircraftSubseries = newData.aircraftSubseries;
    genericacengComboTemplate.engineSubseries = newData.engineSubseries;
    genericacengComboTemplate.genericAircraftId = getACgenericId;
    genericacengComboTemplate.genericEngineId = getENGgenericId;

    if (actionType === constants.ADD_ROW) {
      acengCombos.push(genericacengComboTemplate);
      addACENGCombo(getACgenericId, getENGgenericId, msalContext);
    } else if (actionType === constants.UPDATE_ROW)
      acengCombos[newData.idx] = genericacengComboTemplate;
    else if (actionType === constants.DELETE_ROW)
      acengCombos.splice(newData.idx, 1);
  }

  genericData.acApuCombos = acapuCombos;
  genericData.acEngCombos = acengCombos;

  return genericData;
};
