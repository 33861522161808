import React, { useState} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useMsal } from "@azure/msal-react";
import XLSX from "xlsx";
import {apiRequest, response} from "../../../utils/ApiRequest";
import apiRoutes from "../../../utils/ApiRoutes";
//import { useSignalRHub } from "../../../../hooks/useSignalRHub";

const AppraisalUpload = ({
  handleSnackbarOpen
}) => {
  const [open, setOpen] = useState(false);
  const [File, setFile] = useState(null);
  const [type, setType] = useState("Aircraft");
  const msalContext = useMsal();
  //maybe signal R later

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setFile(null);
      setOpen(false);
    }
  };

  const handleSelectFile = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const handleSelectFileType = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = async () => {
    handleClose();
    handleSnackbarOpen(
      true,
      "info",
      "Validating and Parsing File",
      5000
    );
    if (File) {
        loadAppraisal(
            File,
            msalContext
        );
    }
  };
  const loadAppraisal = async (
    file,
    msalContext
  ) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
  
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        resolve(ws);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  
    promise.then(async (data) => {
      let appraisalData = new FormData();
      appraisalData.append("fileName", file.name);
      appraisalData.append("userName", msalContext.accounts[0].username);
      appraisalData.append("fileFormat", "Excel");
  
      if (
        data.A2 &&
        data.A2.l &&
        data.A2.l.Target.includes("cirium.com")
      ) {
        appraisalData.append("fileData", file);
        //state for engine or aircraft selection
        if(type === "Aircraft"){
          if(file.name.includes("engine")){
            handleSnackbarOpen(true, "Warning",`Please select the aircraft portfolio file, not engine.`,2000 );
            return;
          }
          await uploadAppraisalToDB(
            appraisalData,
            msalContext
          );
        }
        else if (type === "Engine"){
          if(file.name.includes("Aircraft")){
            handleSnackbarOpen(true, "Warning",`Please select the Engine portfolio file, not Aircraft.`,2000 );
            return;
          }
          await uploadEngineAppraisalToDB(
            appraisalData,
            msalContext
          );
        }
      } else {
        // handleSnackerBarOpen = (open, sev, msg, duration = 2000)
        handleSnackbarOpen(true, "Warning",`The file provided does not match any accepted format.
        Please download the appraisal template, fill out the values and then upload the completed template.`,2000 );
      }
    });
  };
  const uploadAppraisalToDB = async (
    appraisalData,
    msalContext
  ) => {
    let apiEndPoint = apiRoutes.Valuation_PortfolioAppraisalUpload();
    const resp = await apiRequest(
      {
        url: apiEndPoint,
        method: "POST",
        data: appraisalData,
      },
      msalContext
    );
  
    if (resp.status === response.OK) {
        var message="Appraisals Successfully Uploaded for all Assets";
        handleSnackbarOpen(true, "info", message, 50000);
    }
  };
  const uploadEngineAppraisalToDB = async (
    appraisalData,
    msalContext
  ) => {
    let apiEndPoint = apiRoutes.Valuation_PortfolioEngineAppraisalUpload();
    const resp = await apiRequest(
      {
        url: apiEndPoint,
        method: "POST",
        data: appraisalData,
      },
      msalContext
    );
  
    if (resp.status === response.OK) {
        var message="Appraisals Successfully Uploaded for Engine Assets";
        handleSnackbarOpen(true, "info", message, 50000);
    }
  };
  return (
    <>
      <Tooltip
        title='Upload Portfolio Appraisals'
        aria-label='Upload Portfolio Appraisals'>
        <Button
          style={{ maxHeight: 28, marginRight:8}}
          variant='outlined'
          color='primary'
          onClick={handleOpen}>
          <span style={{ margin: "0px 8px 0px 8px" }}>Upload</span>
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        disableEscapeKeyDown>
        <DialogTitle>Upload Portfolio Appraisals</DialogTitle>
        <DialogContent dividers={true}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}>
          <FormControl
            variant='standard'
            style={{ minWidth: 200, marginBottom: "1rem",marginRight:"1rem" }}>
            <InputLabel id='appr-type-label'>Portfolio Appraisal Type</InputLabel>
            <Select
              labelId='appr-type-label'
              id='appr-type'
              value={type}
              label='Portfolio Appraisal Type'
              onChange={handleSelectFileType}>
              <MenuItem value={"Aircraft"}>Aircraft</MenuItem>
              <MenuItem value={"Engine"}>Engine</MenuItem>
            </Select>
          </FormControl>
            <Button
              variant='outlined'
              component='label'
              size='small'
              style={{
                marginRight: "0.5rem",
                whiteSpace: "nowrap",
                minWidth: "100px",
              }}>
              Select a file
              <input type='file' hidden onChange={handleSelectFile} />
            </Button>
            {File ? (
              <Tooltip title={File.name}>
                <span
                  style={{
                    fontSize: "1rem",
                    fontStyle: "italic",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}>{`${File.name}`}</span>
              </Tooltip>
            ) : (
              <span
                style={{ fontSize: "1rem", fontStyle: "italic", opacity: 0.7 }}>
                no selection
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button type='button' variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type='button'
            variant='outlined'
            color='primary'
            disabled={!Boolean(File)}
            onClick={handleSubmit}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppraisalUpload;
