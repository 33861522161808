import { combinationUpdater } from "./combinationUpdater";
import { lgRunoutUpdater} from "./lgRunoutUpdater";
import { apuRunoutUpdater } from "./apuRunoutUpdater";

export function updateTable(
  tableName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  genericData,
  msalContext
) {
  // console.log(
  //   tableName,
  //   actionType,
  //   sectionName,
  //   sectionIdx,
  //   newData,
  //   genericData
  // );
  const params1 = [
    tableName,
    actionType,
    sectionName,
    sectionIdx,
    newData,
    genericData,
    msalContext,
  ];

  switch (sectionName) {
    case "Combinations":
      return combinationUpdater(...params1);
    case "Airframe":
      return lgRunoutUpdater(...params1);
    case "apUs":
      return apuRunoutUpdater(...params1);
    default:
      break;
  }
}
