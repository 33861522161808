import _ from "lodash";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import moment from "moment";

export default function mapToViewModel_purchasePrice(data, props, sponsors) {
  return {
    label: "Purchase Info",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    // caption: `DOM: ${moment.utc(data.manufactureDate).format(constants.DATE_FORMAT)}`,
    tableType: constants.DATA_TABLE,
    tableName: "Purchase Price",
    columns: [
      {
        label: ["OWNER"],
        field: "owner",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        items: sponsors.map((sp) => {
          return {
            label: sp.name,
            value: sp.name,
          };
        }),
        type: constants.AUTO_COMPLETE,
        inputFieldType: constants.AUTO_COMPLETE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // content: (value) =>
        //   utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["Purchase Amount"],
        field: "purchasePrice",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.NUMBER,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      },
      {
        label: ["Purchase Date"],
        field: "purchaseDate",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        initialEditValue: moment.utc().format(constants.ISOFormat),
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          value
            ? moment.utc(value).format(constants.DATE_FORMAT)
            : constants.___,
      },
      {
        label: ["Finance Date"],
        field: "financeDate",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        initialEditValue: moment.utc().format(constants.ISOFormat),
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          value
            ? moment.utc(value).format(constants.DATE_FORMAT)
            : constants.___,
      },
    ],
    data: data
      ? {
          owner: data.owner,
          purchasePrice: data.purchasePrice,
          purchaseDate: data.purchaseDate
            ? moment.utc(data.purchaseDate).format(constants.DATE_FORMAT)
            : null,
          financeDate: data.financeDate
          ? moment.utc(data.financeDate).format(constants.DATE_FORMAT)
          : null,
        }
      : {},
  };
}
