import _, { filter } from "lodash";
import React, { useEffect, useState } from "react";
import LoadingPanel from "../Common/LoadingPanel";
import ApiRoutes from "../../utils/ApiRoutes";
import { apiRequest, response } from "../../utils/ApiRequest";
import MainContent from "./Components/MainContent";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import * as constants from "../../constants";
import { Redirect } from "react-router-dom";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import { useMsal } from "@azure/msal-react";

const reorderAssets = (
  assets,
  displayType,
  selectedSortBy,
  sortOrderDesc,
  searchText,
  unassignedSelected
) => {
  let filterdAssets =
    displayType === `${constants.AIRCRAFT} ${constants.ENGINE} ${constants.OnWingEngine}`
      ? unassignedSelected
        ? assets.filter((o) => !o.inDeal)
        : assets
      : unassignedSelected
      ? assets.filter(
          (o) =>
            o.assetType.toUpperCase() === displayType.toUpperCase() && !o.inDeal
        )
      : assets.filter(
          (o) => o.assetType.toUpperCase() === displayType.toUpperCase()
        );

  let reorderdAssets = _.chain(filterdAssets)
    .filter((o) => {
      const { serialNumber, subseries, registration, operator, assetType } = o;
      const str = JSON.stringify({
        serialNumber,
        subseries,
        registration,
        operator,
        assetType,
      });
      return (
        str.toUpperCase().includes(':"' + searchText.toUpperCase()) ||
        str.toUpperCase().includes(":'" + searchText.toUpperCase()) ||
        str.toUpperCase().includes(":" + searchText.toUpperCase())
      );
    })
    .value();

  let sortedAssets = _.sortBy(reorderdAssets, [selectedSortBy]);
  if (sortOrderDesc)
    sortedAssets = _.sortBy(reorderdAssets, [selectedSortBy]).reverse();

  return sortedAssets;
};

const fetchLogos = async (operators, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.Blob_GetAirlineLogos(),
      method: "POST",
      data: operators,
      options: { headers: { "Content-Type": "text/json" } },
    },
    msalContext
  );
};

const fetchManufacturerLogos = async (manufacturers, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.Blob_GetManufacturerLogos(),
      method: "POST",
      data: manufacturers,
      options: { headers: { "Content-Type": "text/json" } },
    },
    msalContext
  );
};

const applyColumnFilters = (tc) =>{
  return tc;
}

const Assets = () => {
  const [assets, setAssets] = useState(null);
  const [onwing, setOnwing] = useState(null);
  const [displayType, setDisplayType] = useState(
    `${constants.AIRCRAFT} ${constants.ENGINE} ${constants.OnWingEngine}`
  );
  const [selectedSortBy, setSelectedSortBy] = useState("id");
  const [sortOrderDesc, setSortOrderDesc] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [unassignedSelected, setUnassignedSelected] = useState(false);
  const [ifAddAsset, setIfAddAsset] = useState(false);
  const [filterProfiles, setFilterProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [columnFilter, setColumnFilter] = useState(null);
  const [tableColumns, setTableColumns] = useState();
  const msalContext = useMsal();

  useEffect(() => {
    const fetchAssets = async () => {
      const assetList = await apiRequest(
        {
          url: ApiRoutes.VectorDeal_GetAssets,
          method: "GET",
        },
        msalContext
      );
      //get profiles 
      const filterProfiles = await apiRequest(
        {
          url: ApiRoutes.AssetPool_GetFilterProfiles({tablename:"Assets", username:msalContext.accounts[0].username }),
          method: "GET"
        },
        msalContext
      )

      if (assetList.status === response.OK) {
        const populateLogos = async () => {
          const operators = _.uniq(
            assetList.data.map((d) => d.operator)
          ).filter((d) => d !== null);
          const operatorLogos = await fetchLogos(operators, msalContext);
          if (operatorLogos.status === response.OK) {
            for (const [key, value] of Object.entries(operatorLogos.data)) {
              let locateAsset = assetList.data.filter(
                (d) => d.operator === key
              );
              for (let asset of locateAsset) {
                asset.airlineLogo = value;
              }
            }
          }

          const manufacturers = _.uniq(
            assetList.data.map((d) => d.manufacturer)
          ).filter((d) => d !== null);
          const manufacturerLogos = await fetchManufacturerLogos(
            manufacturers,
            msalContext
          );
          if (manufacturerLogos.status === response.OK) {
            for (const [key, value] of Object.entries(manufacturerLogos.data)) {
              let locateAsset = assetList.data.filter(
                (d) => d.manufacturer === key
              );
              for (let asset of locateAsset) {
                asset.manufacturerLogo = "data:image/png;base64," + value;
              }
            }
          }
          //onwing engines
          const onWingEngines = await apiRequest(
            {
              url: ApiRoutes.VectorDeal_GetOnWing,
              method: "GET",
            },
            msalContext
          );
          if (onWingEngines.status === response.OK){
            setOnwing(onWingEngines.data);
            if(assetList.data){
              let tempassets = assetList.data;
              let tempengs = [];
              onWingEngines.data.forEach(x=> 
                {x.assetType = constants.OnWingEngine
                 tempengs.push(x);
                });
              tempassets = tempassets.concat(tempengs);
              setAssets(tempassets);
              // console.log(assets);
              // console.log(onWingEngines.data);
            }
          }
          //setAssets(assetList.data);
        };
        populateLogos();
      }

      if(filterProfiles.status === response.OK){
        setFilterProfiles(filterProfiles.data);
        setSelectedProfile(filterProfiles.data[0]);
        setColumnFilter(filterProfiles.data[0].columns);
        let keys = Object.keys(filterProfiles.data[0].columns);
        let filtered = keys.filter(k => {
          return filterProfiles.data[0].columns[k];
        })
        setTableColumns(filtered);
      }
    };
    fetchAssets();
  }, []);

  const handleAssetSearch = ({ target: { value } }) => {
    setSearchText(value);
  };

  const handleColumnCheck = (tar) =>{
    let temp = []
    tableColumns.forEach(e => {
      temp.push(e);
    });
    let ind = temp.findIndex(x=>{return x === tar})
    if(ind != -1){
      temp.splice(ind,1);
    }
    else
    {
      temp.push(tar);
    }
    setTableColumns(temp);
  };
  const handleProfileSwitch = (tar) => {
      let ind = filterProfiles.findIndex(x=> x.name === tar);
      if(ind != -1){
        setSelectedProfile(filterProfiles[ind]);
        setColumnFilter(filterProfiles[ind]);
        let keys = Object.keys(filterProfiles[ind].columns);
        let filtered = keys.filter(k => {
          return filterProfiles[ind].columns[k];
        });
        setTableColumns(filtered);
      }
  };

  const generateBreadCrumbs = () => {
    return [
      {
        label: "Assets",
        type: constants.TEXT,
      },
    ];
  };

  return assets && !ifAddAsset ? (
    <ErrorBoundary style={{ overflow: "hidden", paddingTop: 10 }}>
      <VfBreadcrumbContainer
        breadcrumb={<VfBreadcrumbs breadcrumbs={generateBreadCrumbs()} />}
      />

      {/* <Box style={{ display: "flex", justifyContent: "space-between", margin: "48px 0 8px 0" }}>
        <SectionHeader label={"Assets"} />
      </Box> */}

      <MainContent
        assets={reorderAssets(
          assets,
          displayType,
          selectedSortBy,
          sortOrderDesc,
          searchText,
          unassignedSelected
        )}
        allassets={assets}
        unassignedSelected={unassignedSelected}
        onUnassignedAssetsOptionChange={() =>
          setUnassignedSelected(!unassignedSelected)
        }
        selectedSortBy={selectedSortBy}
        onAssetSearch={handleAssetSearch}
        onDisplayAssetTypeChange={(value) => setDisplayType(value)}
        onSortByChange={(value, desc) => {
          setSelectedSortBy(value);
          setSortOrderDesc(desc);
        }}
        onAssetAddClick={() => setIfAddAsset(true)}
        onColumnCheck={(val) => handleColumnCheck(val)}
        onProfileSwap={(val) => handleProfileSwitch(val)}
        filterProfiles={filterProfiles}
        selectedProfile={selectedProfile}
        cols={applyColumnFilters(tableColumns)}
      />
    </ErrorBoundary>
  ) : ifAddAsset ? (
    <Redirect
      to={{
        pathname: "/assetCreation",
        //ADD ENGINE LIST AS WELL
        state: { assets, onwing },
      }}
    />
  ) : (
    <LoadingPanel />
  );
};

export default Assets;
