import _ from "lodash";

import apiRoutes from "../../../../utils/ApiRoutes";
import { generateGenericComponent } from "./genericComponent";
import { apiRequest } from "./../../../../utils/ApiRequest";

const generateLLPs = (llps, buildStandards, costs) => {
  const returnMe = llps.map((llp) => {
    // if the llp is a group of fan blades of Annulus Fillers
    if ("ids" in llp) {
      return llp.ids.map((id, idx) => {
        const c = _.find(costs, (o) => o.genericEngineLLPModelId === id);
        const bs = _.find(
          buildStandards,
          (o) => o.genericEngineLLPModelId === id
        );
        return {
          genericID: id,
          module: llp.module,
          partName: llp.partNames[idx],
          partNumber: llp.partNumber,
          inCore: llp.isCore,
          isDefault: llp.isDefault,
          price: [
            {
              cycleLifeLimit: c.cycleLifeLimit,
              price: c.price,
              priceYear: c.priceYear,
              escalationRate: c.priceEscalation,
              asOfDate: c.asOfDate,
            },
          ],
          buildStandard: [
            {
              cutOff: bs.cutOff,
              replaceCycles: bs.replaceCycles,
              scrapSaleCutoff: bs.scrapSaleCutOff,
              asOfDate: bs.asOfDate,
            },
          ],
          status: [],
          override: [],
        };
      });
    } else {
      const c = _.find(costs, (o) => o.genericEngineLLPModelId === llp.id);
      const bs = _.find(
        buildStandards,
        (o) => o.genericEngineLLPModelId === llp.id
      );
      return {
        genericID: llp.id,
        module: llp.module,
        partName: llp.partName,
        partNumber: llp.partNumber,
        inCore: llp.isCore,
        isDefault: llp.isDefault,
        price: [
          {
            cycleLifeLimit: c.cycleLifeLimit,
            price: c.price,
            priceYear: c.priceYear,
            escalationRate: c.priceEscalation,
            asOfDate: c.asOfDate,
          },
        ],
        buildStandard: [
          {
            cutOff: bs.cutOff,
            replaceCycles: bs.replaceCycles,
            scrapSaleCutoff: bs.scrapSaleCutOff,
            asOfDate: bs.asOfDate,
          },
        ],
        status: [],
        override: [],
      };
    }
  });

  return _.flatten(returnMe);
};

const fetchLLPBuildStandardAndCost = async (ids, msalContext) => {
  const buildStandards = await apiRequest(
    {
      url: apiRoutes.GenericEngineLLPBuidStandard_GetGenericEngineLLPBuildStandard,
      params: { ids: ids.join(",") },
      method: "GET",
    },
    msalContext
  );

  const costs = await apiRequest(
    {
      url: apiRoutes.GenericEngineLLPPrice_GetGenericEngineLLPPrice,
      params: { ids: ids.join(",") },
      method: "GET",
    },
    msalContext
  );

  return [buildStandards.data, costs.data];
};

export const generateEngine = async (
  genericComponent,
  engineInfo,
  msalContext
) => {
  let { engineModel, serialNumber, EngineSupplementalEvents } = engineInfo;

  var suppEvents = [];
  if (EngineSupplementalEvents != null && EngineSupplementalEvents.length >= 1)
    suppEvents = EngineSupplementalEvents.map((ev) => JSON.parse(ev));

  let llpGenericComponentIds = [];
  let llps = [];
  for (let key in engineInfo) {
    if (key.includes("llps")) {
      const llpsOfModule = engineInfo[key].map((llp) => JSON.parse(llp));

      llps.push(...llpsOfModule);
      llpGenericComponentIds.push(
        ..._.flatten(
          llpsOfModule.map((llp) => {
            if (llp) return llp.id || llp.ids;
            else return null;
          })
        )
      );
    }
  }

  llps = _.compact(llps);
  llpGenericComponentIds = _.compact(llpGenericComponentIds);

  const [buildStandards, costs] = await fetchLLPBuildStandardAndCost(
    llpGenericComponentIds,
    msalContext
  );

  const returnMe = {
    operator: null,
    engineSeries: engineModel.engineSeries,
    engineSubSeries: engineModel.engineSubSeries,
    manufactureDate: engineModel.manufactureDate,
    serialNumber: serialNumber,
    position: `Engine Position ${engineModel.position}`,
    genericId: 0,
    specAdjusters: null,
    scenarioSummaries: null,
    supplementalEvents: suppEvents,
    llpStack: {
      llPs: generateLLPs(llps, buildStandards, costs),
    },
    genericMxInfo: [],
    shopVisitHistory: [],
    maintenanceStatus: [],
    maintenanceEvents: [],
    utilizationHistory: [],
    componentType: genericComponent.componentType.type,
    vfStackSeries: null,
  };
  returnMe.genericId = genericComponent.id;
  returnMe.genericMxInfo = generateGenericComponent(genericComponent);
  return returnMe;
};
