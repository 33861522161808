import _ from "lodash";
import React from "react";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { ReactComponent as CopaIcon } from "../../../../../assets/images/airlines/CopaIcon.svg";
import { ReactComponent as EtihadIcon } from "../../../../../assets/images/airlines/EtihadIcon2.svg";
import { ReactComponent as SpiceJetIcon } from "../../../../../assets/images/airlines/SpiceJetIcon2.svg";

function generateColumns(isHidden = false) {
  let columns = []
  if(!isHidden) {
    columns.push({
      label: "Serial Number",
      caption: null,
      field: "serialNumber",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      contentType: constants.BUTTON,
      content: (isButton, value, handleClick, indent) =>
        utils.renderEventName(isButton, value, handleClick, indent),
      clickableCellIcon: (value) => (
        <div
          style={{
            boxSizing: "border-box",
            height: 22,
            width: 22,
            border: "1px solid #1655A1",
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            display: "inline-flex",
            margin: "2px 0 0 6px",
          }}
        >
          <div
            style={{
              height: 22,
              width: 22,
              textAlign: "center",
              color: "#023059",
              fontSize: 10,
              lineHeight: 2,
            }}
          >{`+${value}`}</div>
        </div>
      ),
    })
  } else {
    columns.push({
      label: "Serial Number",
      caption: null,
      field: "serialNumber",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingLeft: indent }),
    })
  }

  let finalColumns = columns.concat([
    {
      label: "Lessee",
      caption: null,
      field: "lessee",
      filter: false,
      align: constants.LEFT,
      indent: isHidden ? 20 : 16,
      content: (value, indent) => {
        // will be refactored later
        value =
          value &&
          (value.includes("Copa") ? (
            <CopaIcon />
          ) : value.includes("Etihad") ? (
            <EtihadIcon />
          ) : value.includes("Spice") ? (
            <SpiceJetIcon />
          ) : (
            value
          ));
        return utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        );
      },
    },
    {
      label: "Status",
      caption: null,
      field: "leaseStatus",
      filter: false,
      align: constants.LEFT,
      indent: isHidden ? 8 : 16,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingLeft: indent }),
    },
    {
      label: "Mx Reserve Type",
      caption: null,
      field: "mxReserveType",
      filter: false,
      align: constants.LEFT,
      indent: isHidden ? 0 : 16,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingLeft: indent }),
    },
    {
      label: "Start Date",
      caption: null,
      field: "startDate",
      filter: false,
      align: constants.RIGHT,
      indent: isHidden ? 0 : 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "End Date",
      caption: null,
      field: "endDate",
      filter: false,
      align: constants.RIGHT,
      indent: isHidden ? 4 : 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "Monthly Rent",
      caption: "U.S. $",
      field: "monthlyRent",
      filter: false,
      align: constants.RIGHT,
      indent: isHidden ? 8 : 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: "EOL. Comp.",
      caption: "U.S. $",
      field: "eolComp",
      filter: false,
      align: constants.RIGHT,
      indent: isHidden ? 12 : 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: "RRR Cost",
      caption: "U.S. $",
      field: "rrrCost",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
  ])

  return finalColumns;
}

function generateFormattedData(leases) {
  return leases.map((lease) => {
    let res = null;

    for (var i = 0; i < lease.length; i++) {
      let element = {
        serialNumber: lease[i].serialNumber,
        lessee: lease[i].lessee,
        leaseStatus: lease[i].leaseStatus,
        startDate: lease[i].startDate,
        endDate: lease[i].endDate,
        monthlyRent: _.round(lease[i].monthlyRent ),
        eolComp: _.round(lease[i].eolComp ),
        mxReserveType: lease[i].mxReserveType,
        rrrCost: (typeof lease[i].rrrCost) !== undefined ?  _.round(lease[i].rrrCost ) : null,
        hidden: lease.length < 2 ? null : {
          label: null,
          caption: null,
          hideHeaders: true,
          tableType: constants.MULTIFUNCTION_TABLE,
          options: { filter: false, pagination: false },
          span: [12, 12, 12],
          clickableCellIconValue: null,
          columns: generateColumns(true),
          data: [],
        },
        style: { height: 48 },
      };

      if (i === 0) {
        res = element;
      } else {
        element.serialNumber = null;
        // res.hidden.push(element);
        res.hidden.clickableCellIconValue = i;
        res.hidden.data.push(element);
      }
    }

    return res;
  });
}

export default function mapToViewModel_leaseInfo(leases, options) {
  const data = generateFormattedData(leases);
  const columns = generateColumns();

  const tableData = {
    label: constants.LEASE_REPORT,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: false, pagination: false },
    optionsItems: {
      // pagination: {
      //   page: 0,
      //   rowsPerPage: 10,
      //   tableName: constants.leaseInfo,
      //   handleChangePage: options.pagination.handleChangePage,
      //   handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
      // },
    },

    columns: columns,
    data: data,
  };

  return tableData;
}
