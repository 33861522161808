import _ from "lodash";
import moment from "moment";
import { styles } from "../../common";
import * as utils from "../../../../../utils/utils";
import * as constants from "../../../../../constants";

export function mapToViewModel_checkHistory(airframeChecks, props) {
  const items = airframeChecks.map((o) => ({
    label: o.checkName,
    value: o.checkName,
  }));

  const data = _.flatten(
    airframeChecks.map((o) =>
      o.shopVisitHistory.map((e, idx) => ({
        id: idx,
        checkName: o.checkName,
        svStartDate: e.startDate,
        svEndDate: e.endDate,
        isOverhaul: e.isOverhaul,
        totalHours: e.sinceNew.flightHour !== undefined ? e.sinceNew.flightHour : null,
        totalCycles: e.sinceNew.flightCycle !== undefined ? e.sinceNew.flightCycle : null,
        activeHours: e.sinceNew.activeHour !== undefined ? e.sinceNew.activeHour : null,
        days: e.sinceNew.day !== undefined ? e.sinceNew.day : null,
        cost: e.cost,
      }))
    )
  );

  return {
    label: "Check History",
    caption: "Since New at SV",
    props,
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.shopVisitHistory,
    errorsOnSubmit: {},
    columns: [
      {
        title: "Check Name",
        field: "checkName",
        cellStyle: styles.cellStyle,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items,
        initialEditValue: items[0].value,
        required: true,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "SV Date",
        field: "svStartDate",
        cellStyle: styles.cellStyle,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.svStartDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Is Overhaul",
        field: "isOverhaul",
        initialEditValue: true,
        cellStyle: styles.cellStyle,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: false,
        items: [
          { label: constants.YES, value: true },
          { label: constants.NO, value: false },
        ],
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (value) => (value ? constants.YES : constants.NO),
      },
      {
        title: "Total Hours",
        field: "totalHours",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        hidden: props.section === constants.apUs,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.totalHours, null, constants.NUMERIC_CELL),
      },
      {
        title: "Total Cycles",
        field: "totalCycles",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        hidden: props.section === constants.apUs,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => 
          utils.renderCellValue(rowData.totalCycles, null, constants.NUMERIC_CELL),
      },
      {
        title: "Day",
        field: "days",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        hidden: props.section === constants.apUs,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.days, null, constants.NUMERIC_CELL),
      },
      {
        title: "SV Cost",
        field: "cost",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.cost, null, constants.NUMERIC_CELL, "$"),
      },
    ],
    data: _.sortBy(data, ["svStartDate"]),
  };
}
