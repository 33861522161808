import * as constants from "../../../../constants";

export const Updater = (
  fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  leaseSummary
) => {
  if (actionType === constants.UPDATE_ROW) {
    if (newData.rowIdx.includes("addRow")) {
      let rowIdx = parseInt(newData.rowIdx.replace("addRow", ""));
      const rowData = leaseSummary[sectionName].additionalRows[rowIdx];
      rowData.rowTitle = newData.rowTitle;
      rowData.primaryValue = newData.primaryValue;
      rowData.secondaryValue = newData.secondaryValue;
      rowData.footNotes = newData.footNote;
    } else {
      const rowData = leaseSummary[sectionName][fieldName];
      rowData.primaryValue = newData.primaryValue;
      rowData.secondaryValue = newData.secondaryValue;
      rowData.footNotes = newData.footNote;
    }
  } else if (actionType === constants.ADD_ROW) {
    const sectionAddRow = leaseSummary[sectionName].additionalRows;
    sectionAddRow.push({
      rowTitle: newData.rowTitle,
      primaryValue: newData.primaryValue,
      secondaryValue: newData.secondaryValue,
      footNotes: newData.footNote,
    });
  } else if (actionType === constants.DELETE_ROW) {
    if (newData.rowIdx.includes("addRow")) {
      let rowIdx = parseInt(newData.rowIdx.replace("addRow", ""));
      const rowDatas = leaseSummary[sectionName].additionalRows;
      rowDatas.splice(rowIdx, 1);
    } else {
      const rowData = leaseSummary[sectionName][fieldName];
      rowData.primaryValue = null;
      rowData.secondaryValue = null;
      rowData.footNotes = null;
    }
  }

  return leaseSummary;
};
