import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import apiRoutes from "../../../../utils/ApiRoutes";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import AssetInput from "../AssetInput";
import LoadingPanel from "../../../Common/LoadingPanel";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
import FleetDataDialog from "./FleetDataDialog";

const mapToViewModel = (data) => {
  return [
    {
      label: ["Airframe Checks"],
      field: "airframeChecks",
      editable: true,
      required: true,
      items: data.map((o) => ({
        label: o.checkName,
        value: `${o.checkName}|${o.genericComponentId}`,
      })),
      type: constants.CHECK_BOX,
      inputFieldType: constants.CHECK_BOX,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
  ];
};

const AFCheck = ({ ACId, props, airframe, update }) => {
  const [data, setData] = useState(null);
  const [openFD, setOpenFD] = useState(true);
  const msalContext = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const afChecks = await apiRequest(
        {
          url: apiRoutes.GenericAFCheck_GetGenericAFCheckByACId({ ACId }),
          method: "GET",
        },
        msalContext
      );

      if (afChecks.status === response.OK)
        setData(mapToViewModel(afChecks.data));
      // could add "setError(true)" and show error messages in the future
    };
    fetchData();
  }, [ACId]);

  return data ? (
    <div>
      <FleetDataDialog
        msn={airframe.serialNumber}
        manufacturer={airframe.airframeModel.manufacturer}
        openme={openFD}
        setopenme={setOpenFD}
        updatefcn={update}
      />
      <AssetInput columns={data} subTitle='AFCheck' props={props} />
    </div>
  ) : (
    <LoadingPanel />
  );
};

AFCheck.propTypes = {
  ACId: PropTypes.number.isRequired,
  props: PropTypes.object.isRequired,
};

export default AFCheck;
