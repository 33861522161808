import _ from "lodash";
import axios from "axios";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { apiRequest, response } from "../../utils/ApiRequest";
import * as utils from "../../utils/utils";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import apiRoutes from "../../utils/ApiRoutes";
import * as constants from "../../constants";
import * as stateUpdater from "./stateUpdate";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import MultiFieldsFadeInEditingPanel from "../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import LoadingPanel from "../Common/LoadingPanel";
import Errors from "../Common/Errors";
import VfAlertDialogBox from "../Common/UtilComponents/VfAlertDialogBox";
import { Box, Button, Tooltip } from "@material-ui/core";
import SideNavBar from "./Components/SideNavBar/SideNavBar";
import LeaseContent from "./Components/LeaseContent/LeaseContent";
import { generateLSTemplate } from "./leaseSummaryTemplate";
// import AddBoxIcon from '@material-ui/icons/AddBox';
// import PrintIcon from '@material-ui/icons/Print';
// import EditIcon from '@material-ui/icons/Edit';
import ReactToPrint from "react-to-print";
import SectionHeader from "../Common/Section/SectionHeader";
import { Prompt } from "react-router";
import { MsalContext } from "@azure/msal-react";
import VfPrint from "../Common/UtilComponents/VfPrint";

const styles = {
  root: {
    paddingTop: 50,
    paddingBottom: 10,
  },
};

class LeaseSummaryComponents extends Component {
  static contextType = MsalContext;

  assetSummaryTokenSource = null;
  leaseSummaryTokenSource = null;
  constructor(props) {
    super(props);
    this.leaseContentRef = React.createRef();
    this.state = {
      assetType: null,
      assetDetails: null,
      leaseSummary: null,
      selected: { label: "Aircraft Details", caption: null },
      editing: false,
      editAll: false,
      isAlertDialogOpen: false,
      openCopy: false,
      copyProps: null,
      uniqueSummaries: null,
    };
  }

  generateEditableProps = (idx, section) => ({
    sectionIdx: idx,
    section,
    funcs: {
      handleValuesUpdate: this.handleValueChange(constants.UPDATE_ROW),
      handleRowAdd: this.handleValueChange(constants.ADD_ROW),
      handleColumnAdd: this.handleValueChange(constants.ADD_COLUMN),
      handleRowDelete: this.handleValueChange(constants.DELETE_ROW),
      validateData: this.validateData,
      // allow for custom props in child components
      handleValueChange: this.handleValueChange,
    },
  });

  getAssetSummary = async (assetType, assetId) => {
    const user = this.context.accounts[0].name;
    let apiEndPoint =
      assetType.toLowerCase() === constants.AIRCRAFT.toLowerCase()
        ? apiRoutes.Aircraft_GetAircraftDTO
        : apiRoutes.Engine_GetEngineDTO;

    apiEndPoint = apiEndPoint({
      assetId,
      user,
    });

    this.assetSummaryTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.assetSummaryTokenSource.token,
      },
      this.context
    );
  };

  getLeaseSummary = async (id) => {
    let apiEndPoint = apiRoutes.LeaseSummary_GetDTO({
      id,
    });

    this.leaseSummaryTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.leaseSummaryTokenSource.token,
      },
      this.context
    );
  };

  getAllLeaseSummaries = async () => {
    this.leaseSummaryTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiRoutes.LeaseSummary_GetAllLeaseSummaries,
        method: "GET",
        cancelToken: this.leaseSummaryTokenSource.token,
      },
      this.context
    );
  };

  parseQueryString = (url) => {
    var objURL = {};

    let pathValues = url.pathname.split("/");
    objURL["pathValues"] = pathValues;

    let queryString = url.search;
    queryString.replace(
      new RegExp("([^?=&]+)(=([^&]*))?", "g"),
      function ($0, $1, $2, $3) {
        objURL[$1] = $3;
      }
    );
    return objURL;
  };

  componentDidMount = async () => {
    // const { assetType, id } = this.props
    const urlValues = utils.parseUrlObject(new URL(window.location.href));
    const pathValues = urlValues.pathValues;
    const assetId = pathValues[pathValues.length - 1];
    const assetType = urlValues["assetType"];
    let assetSummary = await this.getAssetSummary(assetType, assetId);
    if (assetSummary.status === response.OK) {
      const assetDetails = _.cloneDeep(assetSummary.data);
      if (assetType.toLowerCase() === constants.ENGINE.toLowerCase()) {
        assetDetails.engines = [assetSummary.data];
      }
      this.setState({
        assetDetails,
        assetType: assetType,
      });
    }
    let leaseSummary = await this.getLeaseSummary(assetId);
    if (leaseSummary.status === response.OK) {
      this.setState({
        leaseSummary: leaseSummary.data,
      });
    }
    // window.addEventListener('scroll', this.handleScroll);
  };

  componentDidUpdate = () => {
    if (this.state.editing) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  // componentWillUnmount = () => {
  //     window.removeEventListener('scroll', this.handleScroll);
  // };

  validateData = (newData, columns, section, tableName, actionType) => {
    let allValid = true;
    let errorsOnSubmit = {};
    return allValid;
  };

  // handleScroll = (event) => {
  //   const leaseSectionItems = [
  //     { label: "Aircraft Details", caption: null },
  //     { label: "Lease Details", caption: null },
  //     { label: "Maintenance Reserves / Lessor Contributions", caption: null },
  //     { label: "Lease Options", caption: null },
  //     { label: "Redelivery Conditions", caption: null },
  //     { label: "Engine Substitution", caption: null },
  //     { label: "Airworthiness Directives", caption: null },
  //     { label: "Insurance", caption: null },
  //     { label: "Tax and Indemnities", caption: null },
  //     { label: "Other/Legal", caption: null },
  //   ];
  //   const selectElement = document.getElementById("Lease Details")
  //   if (selectElement) {
  //     if (window.scrollY >= selectElement.getBoundingClientRect().top) {
  //     }
  //   }
  // };

  handleSelectedChange = (selected) => {
    this.setState({
      selected: selected,
    });
  };

  handleValueChange =
    (action) => (section, sectionIdx, fieldName, newData, columns) => {
      const leaseSummary = { ...this.state.leaseSummary };
      // console.log(section, sectionIdx, fieldName, newData, columns);
      stateUpdater.updateTable(
        fieldName,
        action,
        section,
        sectionIdx,
        newData,
        leaseSummary,
        columns
      );
      leaseSummary.published = false;
      this.setState({ leaseSummary, editing: true });
    };

  handleNewLeaseSummary = () => {
    const { assetType, assetDetails } = this.state;
    const userName = this.context.accounts[0].name;
    const leaseSummary = _.cloneDeep(
      generateLSTemplate(assetDetails, assetType, assetDetails.id, userName)
    );
    leaseSummary.newLeaseSummary = true;
    this.setState({
      leaseSummary: leaseSummary,
      editing: true,
    });
    this.handleAlertDialogVisible(false);
  };

  handleCopyLeaseSummary = async (section, sectionIdx, fieldName, newData) => {
    const { assetType, assetDetails } = this.state;
    const userName = this.context.accounts[0].name;
    const leaseSummary = _.cloneDeep(
      generateLSTemplate(assetDetails, assetType, assetDetails.id, userName)
    );
    let copiedleaseSummary = await this.getLeaseSummary(
      parseInt(newData.assetId)
    );
    if (copiedleaseSummary.status === response.OK) {
      let copiedleaseSummaryData = copiedleaseSummary.data;
      leaseSummary.leaseDetails = copiedleaseSummaryData.leaseDetails;
      leaseSummary.mxReserve_LessorContrib =
        copiedleaseSummaryData.mxReserve_LessorContrib;
      leaseSummary.leaseOptions = copiedleaseSummaryData.leaseOptions;
      leaseSummary.redeliveryConditions =
        copiedleaseSummaryData.redeliveryConditions;
      leaseSummary.engineSubstitution =
        copiedleaseSummaryData.engineSubstitution;
      leaseSummary.airworthinessDirectives =
        copiedleaseSummaryData.airworthinessDirectives;
      leaseSummary.insurance = copiedleaseSummaryData.insurance;
      leaseSummary.taxAndIndemnities = copiedleaseSummaryData.taxAndIndemnities;
      leaseSummary.other_Legal = copiedleaseSummaryData.other_Legal;

      this.setState({
        leaseSummary: leaseSummary,
        editing: true,
      });
    }
  };

  handleGetLeaseSummaries = async () => {
    let leaseSummaries = await this.getAllLeaseSummaries();
    if (leaseSummaries.status === response.OK) {
      let uniqueSummaries = _.unionBy(leaseSummaries.data, "assetId");
      let props = {
        section: "copylease",
        sectionIdx: 1,
        funcs: {
          handleValuesUpdate: (section, sectionIdx, fieldName, newData) => {
            this.handleCopyLeaseSummary(
              section,
              sectionIdx,
              fieldName,
              newData
            );
          },
          validateData: this.validateData,
        },
      };

      this.setState({
        copyProps: props,
        uniqueSummaries: uniqueSummaries,
        openCopy: true,
      });
    }
  };

  handleAlertDialogVisible = (open) => {
    this.setState({ isAlertDialogOpen: open });
  };

  handlePublished = () => {
    this.setState({ editing: false });
  };

  renderNewLeaseSummaryBody = () => {
    return this.state.leaseSummary ? (
      <div>
        <p>
          Are you sure you would like to <b>Override</b> the current lease
          summary?
        </p>
        <p>
          You may copy from an existing lease summary by clicking <b>COPY</b> or{" "}
          <b>AGREE</b> to create completely new lease summary
        </p>
        <p>
          Note: The current lease summary will not be replaced until you
          publish.
        </p>
      </div>
    ) : (
      <div>
        <p>
          You may copy from an existing lease summary by clicking <b>COPY</b> or{" "}
          <b>AGREE</b> to create completely new lease summary
        </p>
      </div>
    );
  };

  renderActionButtons = () => {
    const pageStyle = `
      @media all {
        .pagebreak {
          overflow: hidden; height: 0;
        }
      }

      @media print {
        @page { 
          size: landscape; 
          margin: 0mm;
        }

        #threedotbutton {
          visibility: hidden;
        }
        #leaseSummarySideBar {
          overflow: hidden; 
          height: 0;
        }
        #leaseSummaryTitle {
          overflow: hidden; 
          height: 0;
        }
        #leaseSummaryContent {
          width: 100%;
          margin-left: -200px;
        }
        .scrollMargin {
          padding: 0px;
          margin: 0 auto;
        }
      }
    `;
    return (
      <Box style={{ marginTop: 12 }}>
        {this.state.leaseSummary ? (
          <>
            <Tooltip title='Edit All' aria-label='edit all'>
              <Button
                style={{ maxHeight: 28, marginLeft: 16 }}
                variant='outlined'
                color='primary'
                onClick={() => this.setState({ editAll: true })}>
                <span style={{ margin: "0px 8px 0px 8px" }}>Edit All</span>
              </Button>
            </Tooltip>
            <Tooltip title='Print / to PDF' aria-label='print/pdf'>
              {/* <span>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      style={{ maxHeight: 28, marginLeft: 16 }}
                      variant='outlined'
                      color='primary'
                      disabled={
                        this.state.leaseSummary.published ? false : true
                      }>
                      <span style={{ margin: "0px 8px 0px 8px" }}>Print</span>
                    </Button>
                  )}
                  content={() => this.componentRef}
                  pageStyle={pageStyle}
                  documentTitle={`MSN ${this.state.assetDetails.msn} Lease Summary`}
                />
              </span> */}
              <VfPrint
                styleId={"leaseSummaryPrint"}
                margin={20}
                orientation={"landscape"}
                additionalCss={`html {
                  zoom: 80%;
                  margin: 0 auto;
                  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
                  color-adjust: exact !important; /*Firefox*/
                  width: 100%;
                }`}
              />
            </Tooltip>
          </>
        ) : (
          <></>
        )}
        <Tooltip title='New Lease Summary' aria-label='new lease summary'>
          <Button
            style={{ maxHeight: 28, marginLeft: 16 }}
            variant='outlined'
            color='primary'
            onClick={() => this.handleAlertDialogVisible(true)}>
            <span style={{ margin: "0px 8px 0px 8px" }}>New</span>
          </Button>
        </Tooltip>
      </Box>
    );
  };

  renderBreadCrumbs = () => {
    const { assetDetails, assetType } = this.state;
    let subseries =
      assetType.toLowerCase() === "aircraft"
        ? assetDetails.aircraftSubSeries
        : assetDetails.engineSubSeries;
    let serialNumber =
      assetType.toLowerCase() === "aircraft"
        ? assetDetails.msn
        : assetDetails.serialNumber;
    const leaseInfo = _.template(
      `Lease Summary - <%= subseries %> / <%= serialNumber %>`
    );
    return [
      {
        label: "Assets",
        type: constants.LINK,
        tooltip: "Back to Assets",
        props: { to: "/assets" },
      },
      {
        label: leaseInfo({ subseries, serialNumber }),
        type: constants.TEXT,
        tooltip: "Lease Summary",
      },
    ];
  };

  render() {
    const {
      assetDetails,
      leaseSummary,
      selected,
      editing,
      editAll,
      isAlertDialogOpen,
      copyProps,
      openCopy,
      uniqueSummaries,
      assetType,
    } = this.state;

    const leaseSectionItems = [
      { label: "Aircraft Details", caption: null },
      { label: "Lease Details", caption: null },
      { label: "Maintenance Reserves / Lessor Contributions", caption: null },
      { label: "Lease Options", caption: null },
      { label: "Redelivery Conditions", caption: null },
      { label: "Engine Substitution", caption: null },
      { label: "Air Worthiness Directives", caption: null },
      { label: "Insurance", caption: null },
      { label: "Tax and Indemnities", caption: null },
      { label: "Other/Legal", caption: null },
    ];

    const navBarSectionItems = [
      {
        label: "Lease Sections",
        listItems: leaseSectionItems,
      },
    ];

    return assetDetails ? (
      <ErrorBoundary>
        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={this.handleAlertDialogVisible}
          dialogTitle={"New Lease Summary"}
          dialogBody={this.renderNewLeaseSummaryBody()}
          customButton={{
            label: "Copy",
            handleClick: () => {
              this.handleGetLeaseSummaries();
              this.setState({ openCopy: true });
            },
          }}
          onAccept={this.handleNewLeaseSummary}
        />

        <VfBreadcrumbContainer
          breadcrumb={<VfBreadcrumbs breadcrumbs={this.renderBreadCrumbs()} />}
        />

        <Prompt
          when={editing}
          message='You have unsaved changes, are you sure you want to leave?
            "OK" to continue and discard changes.
            Or "Cancel" to stay on page.'
        />

        <Box
          spacing={10}
          ref={(el) => (this.componentRef = el)}
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 10fr",
            marginTop: 20,
          }}>
          <Box
            style={{ width: 220, paddingRight: 10 }}
            id='leaseSummarySideBar'>
            <SideNavBar
              assetDetails={assetDetails}
              navBarSectionItems={navBarSectionItems}
              selected={selected}
              onSelectedChange={this.handleSelectedChange}
            />
          </Box>
          <Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "28px 0 28px 0",
              }}
              id='leaseSummaryTitle'>
              <SectionHeader label={"Lease Summary"} />
              {this.renderActionButtons()}
            </Box>
            {leaseSummary ? (
              <Box style={{ maxWidth: "100%" }} id='leaseSummaryContent'>
                <LeaseContent
                  assetType={assetType}
                  leaseSummary={leaseSummary}
                  generateEditableProps={this.generateEditableProps}
                  editing={editing}
                  editAll={editAll}
                  handlePublished={this.handlePublished}
                />
              </Box>
            ) : (
              <Box style={{ paddingTop: 40 }}>
                <Errors
                  msg={
                    "There is currently no lease summary for this asset, please click on new lease summary button."
                  }
                />
              </Box>
            )}
          </Box>

          <Box style={{ height: 75 }}></Box>

          {uniqueSummaries ? (
            <MultiFieldsFadeInEditingPanel
              open={openCopy}
              columns={[
                {
                  label: ["Serial Number"],
                  field: "assetId",
                  editable: true,
                  required: true,
                  items: uniqueSummaries.map((o) => {
                    return {
                      label: `${o.assetType} ${o.serialNumber}`,
                      value: o.assetId,
                    };
                  }),
                  type: constants.AUTO_COMPLETE,
                  inputFieldType: constants.AUTO_COMPLETE,
                },
              ]}
              data={null}
              tableName={""}
              props={copyProps}
              onClose={() => this.setState({ openCopy: false })}
            />
          ) : (
            <></>
          )}
        </Box>
      </ErrorBoundary>
    ) : (
      <LoadingPanel />
    );
  }
}

export default withStyles(styles)(LeaseSummaryComponents);
