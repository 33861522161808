import _ from "lodash";
import moment from "moment";
import { styles } from "../../common";
import * as utils from "../../../../../utils/utils";
import * as constants from "../../../../../constants";

export function mapToViewModel_scheduledShopVisit(airframeChecks, props) {
  const items = airframeChecks.map((o) => ({
    label: o.checkName,
    value: o.checkName,
  }));

  return {
    label: "Scheduled Shop Visit",
    caption: null,
    props,
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.scheduledShopVisit,
    errorsOnSubmit: {},
    columns: [
      {
        title: "Event",
        field: "event",
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items: items,
        initialEditValue: items[0].value,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Start Date",
        field: "startDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.startDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Days Down",
        field: "daysDown",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.daysDown, null, constants.NUMERIC_CELL),
      },
      {
        title: "Cost",
        field: "cost",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.cost, null, constants.NUMERIC_CELL, "$"),
      },
      {
        title: "Payment Process (Days)",
        field: "paymentProcessDays",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.paymentProcessDays, null, constants.NUMERIC_CELL),
      },
      {
        title: "Is Overhaul",
        field: "isOverhaul",
        initialEditValue: true,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: false,
        items: [
          { label: constants.YES, value: true },
          { label: constants.NO, value: false },
        ],
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          rowData.isOverhaul ? constants.YES : constants.NO,
      },
      {
        title: "Triggered by Other Events",
        field: "isTriggeredByOtherEvents",
        initialEditValue: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        items: [
          { label: constants.NO, value: false },
          { label: constants.YES, value: true },
        ],
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          rowData.isTriggeredByOtherEvents ? constants.YES : constants.NO,
      },
    ],
    data: _.compact(
      _.flatten(
        airframeChecks.map((o) =>
          o.maintenanceEvents && o.maintenanceEvents.length
            ? o.maintenanceEvents.map((e, idx) => {
                  return {
                    id: idx,
                    event: o.checkName,
                    startDate: e.startDate,
                    isScheduled: true,
                    daysDown: moment.utc(e.endDate).diff(e.startDate, "days"),
                    cost: e.cost,
                    isOverhaul: e.isOverhaul,
                    isTriggeredByOtherEvents: e.isTriggeredByOtherEvents,
                    paymentProcessDays: e.paymentProcessDays,
                  };
              })
            : null
        )
      )
    ),
  };
}
