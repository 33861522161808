import moment from "moment";
import _ from "lodash";
import * as constants from "../../constants";

export const generateLSTemplate = (
  assetDetails,
  assetType,
  assetId,
  userName
) => {
  let engineType = null;
  let eSNs = null;

  if (!_.isEmpty(assetDetails.engines)) {
    engineType = assetDetails.engines[0].engineSubSeries;
    eSNs = assetDetails.engines.map((e) => e.serialNumber);
    eSNs = eSNs.join(" , ");
  }

  return {
    // id: -1,
    assetId: assetId,
    assetSerialNumber: assetType === "Engine" ? eSNs : assetDetails.msn,
    assetType: assetType,
    author: userName,
    leaseSummaryDate: new Date(),
    published: false,
    newLeaseSummary: false,
    lastUpdatedBy: userName,
    lastUpdatedDate: new Date(),
    aircraftDetails: {
      additionalRows: [],
      msn: {
        primaryValue: assetType === "Engine" ? null : assetDetails.msn,
        secondaryValue: null,
        footNotes: null,
      },
      model: {
        primaryValue:
          assetType === "Engine" ? null : assetDetails.aircraftSubSeries,
        secondaryValue: null,
        footNotes: null,
      },
      dom: {
        primaryValue:
          assetType === "Engine"
            ? null
            : moment
                .utc(assetDetails.manufactureDate)
                .format(constants.DATE_FORMAT),
        secondaryValue: null,
        footNotes: null,
      },
      engineType: {
        primaryValue:
          assetType === "Engine" ? assetDetails.engineSubSeries : engineType,
        secondaryValue: null,
        footNotes: null,
      },
      thrustRating: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      eSNs: {
        primaryValue: eSNs,
        secondaryValue: null,
        footNotes: null,
      },
      registration: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      countryOfRegistration: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      owningEntity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lessor: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      convertorLocation: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      conversionPhaseCompDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    leaseDetails: {
      additionalRows: [],
      lessee: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lesseeFullName: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      leaseGuarantor: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      sublessee: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      leaseExecutedDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      deliveryDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      leaseExpDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      securityDepAmnt: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      securityDepForm: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      basicRentAmnt: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      supRentType: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      rentType: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      floatingRateFormula: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      rentDueDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    mxReserve_LessorContrib: {
      additionalRows: [],
      mxReservePayable: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      letterofCreditinLieuofPayment: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      mxReserveRate: {
        footNotes: null,
        dataTable: [],
      },
      engineRestorationRateMatrix: {
        footNotes: null,
        dataTable: [],
      },
      rightsToMXReserves: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      mxReseverRateAdj: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      mxReserveBalance: {
        footNotes: null,
        dataTable: [],
      },
      mxReserveBalanceAsOfDate: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      mxRedeliveryAdj: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      mxEventDefinitions: [],
      mxEventNotice: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lessorContribApplicable: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lessorContribConditions: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lessorContribFirstMXEvent: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    leaseOptions: {
      additionalRows: [],
      purchaseOption: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      extensionOptions: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      terminationOption: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      otherOption: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      subleasing: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      permittedSublessees: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    redeliveryConditions: {
      additionalRows: [],
      redeliveryConditions: {
        footNotes: null,
        dataTable: [],
      },
      redeliveryLocation: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      supRentatRedelivery: {
        footNotes: null,
        dataTable: [],
      },
    },
    engineSubstitution: {
      additionalRows: [],
      engineSubAtAnyTime: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      engineSubEventLoss_CasualtyOccurrence: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      engineSubAtReturn: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lessorSub: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    airworthinessDirectives: {
      additionalRows: [],
      adCostSharing: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    insurance: {
      additionalRows: [],
      agreedValue: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      minLiabilityCoverage: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lesseDeductible_SelfInsuranceAmnt: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      damageNotificationThreshold: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      broker: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    taxAndIndemnities: {
      additionalRows: [],
      withholdingGrossUp: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      genTaxIndemnity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      taxIndemnityExclusions: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      taxIndemnity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      operatingIndemnity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      operatingIndemnityExclusions: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      indemnitees: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      otherIndemnities: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
    other_Legal: {
      additionalRows: [],
      governingLaw: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      netWorthRequirement: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      interestOnLatePayments: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lessorTransferRestrict: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      exclusionFromNetLeaseProv: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      waiverOfImmunity: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      crossDefault: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
      lessorPaymentObligations: {
        primaryValue: null,
        secondaryValue: null,
        footNotes: null,
      },
    },
  };
};
