import _ from "lodash";
import React, { useState, useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VfSnackerBar from "../../../Common/UtilComponents/VfSnackerBar";
import Asset from "./Asset";
import MultiFieldsFadeInEditingPanel from "../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import ApiRoutes from "../../../../utils/ApiRoutes";
import { mapToViewModel_assets } from "../../mapToViewModels/assetsViewModel";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
import { DealRelatedDataContext } from "../../../../context/DealRelatedDataContext";
import { mapToViewModel_pipelineAssets } from "../../mapToViewModels/pipelineAssetsViewModel";
import VfAlertDialogBox from "../../../Common/UtilComponents/VfAlertDialogBox";
import EditableTable from "../../../Common/Tables/EditableTable/EditableTable";


const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  addAssetBox: {
    height: 68,
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#DDE0E5",
    boxShadow: "unset",
    paddingTop: 15,
    paddingBottom: 15,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.background.hover}`,
    },
  },
  addAssetContent: { width: "100%", paddingRight: 7, paddingTop: 6 },
}));

const Assets = ({ user, deal, dealAssets, airlineLogos }) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const { assetPoolData } = useContext(DealRelatedDataContext);
  const [open, setOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(1500);
  const delay = 1500;
  const plAssets = deal.plAssets;


  const linkAssetPoolToDeal = async (dealId, assetPoolId) => {
    return apiRequest(
      {
        url: ApiRoutes.AssetPool_LinkAssetPoolToDeal({
          dealId,
          user: user,
          assetPoolId,
        }),
        method: "POST",
        headers: { "Content-Type": "text/json" },
      },
      msalContext
    );
  };

  // args: section (null), sectionIdx(null), tableName(null), values(assetId|assetType))
  const handleAssetPoolAdd = async (...args) => {
    try {
      const { dealId, dealName } = deal;
      //const [assetId, assetType, subseries] = args[3].asset.split("|");
      var assetPoolId = args[3].assetPoolId;
      const result = await linkAssetPoolToDeal(dealId, assetPoolId);
      //const result = await addAssetToDB(dealId, assetId, assetType);
      if (result.status === response.OK) {
        setMessage(`Successfully added to ${dealName}.`);
        setsnackOpen(true);
        setSeverity("info");
        setTimeout(() => {
          window.location.reload(true);
        }, delay);
      } else {
        setMessage(`Failed to add to ${dealName}.`);
        setsnackOpen(true);
        setSeverity("warning");
      }
    } catch (ex) {}
  };
  const handleGoToAssetPool = () => {
      let addon = `/assetpools?search=`+deal.dealName;
      window.open(addon);
  }
  const CallCreateAssets = async (dealId, assets) =>{
    return apiRequest(
      {
        url: ApiRoutes.VectorDeal_CreatePipelineAssets({ dealId}),
        method: "POST",
        data: assets,
        headers: { "Content-Type": "text/json" },
      },
      msalContext
    );

  }
  const handleCreateAssets = async () =>{
    //console.log(uhoh);
    const { universalDealId, dealName, plAssets } = deal;
    //setCreateOpen(false);
    setMessage(`Creating Asset for ${dealName}. This can take a few minutes...`);
    setsnackOpen(true);
    setSeverity("warning");
    setDuration(3000);
    var res = await CallCreateAssets(universalDealId, plAssets);
    if (res.status === response.OK) {
      setMessage(`Successfully Created Assets for ${dealName}.`);
      setsnackOpen(true);
      setSeverity("info");
      setTimeout(() => {
        window.location.reload(true);
      }, delay);
    } else {
      setMessage(`Failed to create Assets for ${dealName}.`);
      setsnackOpen(true);
      setSeverity("warning");
    }
    setCreateOpen(false);
    
  }

  const props = {
    funcs: { handleValuesUpdate: handleAssetPoolAdd, validateData: () => true },
  };
  // let availableAssets = _.differenceBy(allAssets, dealAssets, "id");
  let availableAssets =
    assetPoolData && mapToViewModel_assets(assetPoolData, dealAssets);
  let availablePlAssets = mapToViewModel_pipelineAssets(plAssets);
  availablePlAssets.props = props;
  //console.log(availablePlAssets);

  return (
    <Box className={classes.root}>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        duration={duration}
        message={message}
      />
      <Grid container spacing={2} alignItems='center'>
        
        {dealAssets.map((asset, index) => (
          <Grid key={index} item sm={6} lg={3} md={4}>
            <Asset
              user={user}
              deal={deal}
              asset={asset}
              dealAssets={dealAssets}
              airlineLogo={_.find(
                airlineLogos,
                (o) => o.operator === asset.operator
              )}
            />
          </Grid>
        ))}
        <Grid item sm={6} lg={3} md={4}>
          <Box className={classes.addAssetBox} onClick={() => setOpen(true)}>
            <Grid
              container
              className={classes.addAssetContent}
              spacing={1}
              justifyContent='center'>
              <Grid item>
                <AddCircleOutlineIcon color='primary' />
              </Grid>
              <Grid item>
                <Typography variant='subtitle1' color='primary'>
                  Select Asset Pool
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Grid>
        {deal.assetPoolId ? (
        <Grid item sm={6} lg={3} md={4}>
          <Box className={classes.addAssetBox} onClick={() => handleGoToAssetPool()} >
              <Grid
                container
                className={classes.addAssetContent}
                spacing={1}
                justifyContent='center'>
                <Grid item>
                  <ExitToAppIcon color='primary' />
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1' color='primary'>
                    Go To Asset Pool
                  </Typography>
                </Grid>
              </Grid>
            </Box>
        </Grid>
        ) : null}
      {plAssets.length >= 1 ? (
        <Grid item sm={6} lg={3} md={4}>
        <Box className={classes.addAssetBox} onClick={() => setCreateOpen(true)}>
          <Grid
            container
            className={classes.addAssetContent}
            spacing={1}
            justifyContent='center'>
            <Grid item>
              <AddCircleOutlineIcon color='primary' />
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' color='primary'>
                Create Assets From Pipeline Data
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      ) : null}
      </Grid>
      {assetPoolData ? (
        <MultiFieldsFadeInEditingPanel
          open={open}
          columns={availableAssets.columns}
          props={props}
          onClose={() => setOpen(false)}
        />
      ) : null}
      {availablePlAssets ? (//<></>
      <VfAlertDialogBox
      isOpen={createOpen}
      handleAlertDialogVisible={setCreateOpen}
      dialogTitle={"Create Assets From Deal Manager"}
      //dialogBody={"Asset Table Goes Here"}
      dialogBody={<EditableTable table={availablePlAssets}/>}
      //dialogBody={<DataTable tableData={availablePlAssets}/>}
      onAccept={handleCreateAssets}
      />
      ) : null }
    </Box>
  );
};

export default Assets;
