import _ from "lodash";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";

export function mapToViewModel_prepayments(loanSummary, props) {
  let columns = [
    {
      label: ["Mandatory Prepayments"],
      field: "mandatoryPrepayments",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Voluntary Prepayments"],
      field: "voluntaryPrepayments",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Prepayment Fees"],
      field: "prepaymentFees",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
  ];

  let data = {
    mandatoryPrepayments: loanSummary.prepayments.mandatoryPrepayments,
    voluntaryPrepayments: loanSummary.prepayments.voluntaryPrepayments,
    prepaymentFees: loanSummary.prepayments.prepaymentFees,
  };

  loanSummary.prepayments.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  });

  return {
    label: "Prepayments",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Prepayments",
    columns: columns,
    data: data,
  };
}
