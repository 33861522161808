import { Updater } from "./redeliveryLocation"
import { redeliveryConditionUpdater } from "./redeliveryCondition";
import { suppRentRedeliveryUpdater } from "./suppRentRedelivery";

export function RedeliveryConditionsUpdater(fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  leaseSummary) {

  switch (fieldName) {
    case "Redelivery Conditions":
      return redeliveryConditionUpdater(actionType, sectionName, sectionIdx, newData, leaseSummary);
    case "Supplemental Rent at Redelivery":
      return suppRentRedeliveryUpdater(actionType, sectionName, sectionIdx, newData, leaseSummary);
    default:
      return Updater(fieldName, actionType, sectionName, sectionIdx, newData, leaseSummary);
  }
}