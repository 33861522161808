// import * as constants from "../../../constants";
import { tableUpdater } from "./tableUpdater";
import { MXReserve_LessorContribUpdater } from "./MXReserve_LessorContrib/index";
import { RedeliveryConditionsUpdater } from "./RedeliveryConditions/index";

export function updateTable(
  fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  leaseSummary,
  columns
) {
  // console.log(
  //   fieldName,
  //   actionType,
  //   sectionName,
  //   sectionIdx,
  //   newData,
  //   leaseSummary,
  //   columns
  // );
  const params1 = [
    fieldName,
    actionType,
    sectionName,
    sectionIdx,
    newData,
    leaseSummary,
    columns,
  ];

  switch (sectionName) {
    case "mxReserve_LessorContrib":
      return MXReserve_LessorContribUpdater(...params1);
    case "redeliveryConditions":
      return RedeliveryConditionsUpdater(...params1);
    default:
      return tableUpdater(...params1);
  }
}
