import React from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    justifyContent: "space-between"
  },
  link: {
    textDecoration: "none",
    color: "#0E253F",
  },
  sponsor: {
    fontSize: 12,
    lineHeight: "16px",
  },
  dealName: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "0.25px",
  },
}));

const DealName = ({ deal }) => {
  const classes = useStyles();
  const { dealName, dealId, sponsor, facilityTrancheName, trancheSeniority } =
    deal;
  return (
    <Box className={classes.root}>
      <Link
        className={classes.link}
        onClick={(event) => event.stopPropagation()}
        to={{
          pathname: `/deal/portfolio/${dealId}`,
          state: { deal, dealId },
        }}>
        <Typography
          variant='overline'
          color='secondary'
          noWrap={true}
          className={classes.sponsor}>
          {sponsor} {facilityTrancheName ? `- ${facilityTrancheName}` : null}
        </Typography>
        <Tooltip title='Go to Portfolio' placement='bottom' arrow>
          <Typography
            variant='h1'
            color='primary'
            className={classes.dealName}
            noWrap={true}>
            {dealName}
          </Typography>
        </Tooltip>
      </Link>
    </Box>
  );
};

export default DealName;
