import { default as mapToViewModel_Apprasial } from "./apprasialViewModel";
import { default as mapToViewModel_ValuationAssumptions } from "./valuationAssumptionsViewModel";
import { default as mapToViewModel_PartOutValues } from "./partOutValuesViewModel";

export default function mapToViewModel_AssetValuation(
  report,
  appraisals,
  selectedAppraisal,
  compStatus,
  partOutValues,
  handleSelectChange,
  runDate,
  dtDispDates,
) {
  return {
    valuationAssumptions: mapToViewModel_ValuationAssumptions(
      selectedAppraisal,
      handleSelectChange,
      dtDispDates,
      appraisals,
    ),
    appraisal: mapToViewModel_Apprasial(
      report,
      appraisals,
      selectedAppraisal,
      compStatus,
      runDate,
    ),
    partOutValues: mapToViewModel_PartOutValues(
      partOutValues,
      runDate,
      selectedAppraisal,
    ),
  };
}
