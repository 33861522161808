import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import React from "react";
import { Box, Button, Grid, Tooltip } from "@material-ui/core";

import BorderLinearProgress from "../Components/Common/BroderLinearProgressBar";
import BookMark from "../Components/Common/SvgComponents/BookMark";
import InfoPopup from "../Components/Common/InfoPopup";

import { Link } from "react-router-dom";
import * as constants from "../constants";
import "./utils.Module.css";
import XLSX from "xlsx";

export function capitalizeFirstLetter([firstLetter, ...restOfWord]) {
  return firstLetter.toUpperCase() + restOfWord.join("");
}

export function formatNumber(value, prefix = null, suffix = null, roundDigits) {
  return value || value === 0 ? (
    <NumberFormat
      thousandSeparator={true}
      displayType={"text"}
      value={_.round(100 * _.round(value, roundDigits)) / 100}
      allowLeadingZeros={true}
      prefix={prefix}
      suffix={suffix}
    />
  ) : (
    constants.NA
  );
}

export function thousandSeparator(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function generateFilterData(columns, data) {
  let returnMe = {};

  for (var i = 0; i < columns.length; i++) {
    const column = columns[i];
    returnMe[column.field] = column.filter
      ? _.concat([constants.ALL], _.uniq(_.map(data, column.field).sort()))
      : null;
  }

  return returnMe;
}

export function ifValueExist(value, keepZero = false) {
  if (value !== 0) value = value || null;

  return keepZero
    ? value !== null && value !== constants.NaN
    : value && value !== constants.NaN;
}

export function mapComponentName(compName, caption) {
  let newCompName = "";
  let position = null;

  if (compName.includes("Engine")) {
    position = _.last(compName.split(" "));
    if (compName.includes("EngineLLP")) {
      newCompName = "EngineLLP";
    } else {
      newCompName = "EnginePR";
    }
  } else {
    switch (compName) {
      case constants.APU_OVERHAUL:
        newCompName = "APU OH";
        break;
      case "LG Left Main":
        newCompName = "LG LM";
        break;
      case "LG Right Main":
        newCompName = "LG RM";
        break;
      default:
        newCompName = compName;
        break;
    }
  }

  newCompName = (
    <Box>
      <Box>{newCompName}</Box>
      <Box className={"serialNumber"}>{caption ? caption : position}</Box>
    </Box>
  );

  return newCompName;
}

export function renderCellValue(
  value,
  style,
  type = constants.TEXT_CELL,
  prefix = null,
  roundDigits = 0,
  keepZero = true,
  dateFormat = null
) {
  const comp = ifValueExist(value, keepZero)
    ? type === constants.NUMERIC_CELL
      ? formatNumber(value, prefix, null, roundDigits)
      : type === constants.DATE_CELL
      ? moment.utc(value).format(dateFormat ?? constants.DATE_FORMAT)
      : value
    : constants.___;

  style = style ? style : {};
  if (value < 0) style["color"] = "#FF6C5C";
  return <Box style={style}>{comp}</Box>;
}

export function renderEventName(
  isButton,
  value,
  handleClick,
  indent,
  textAlign,
  styleObj = {}
) {
  if (!value || value.length === 0) return null;
  const eventName = Array.isArray(value)
    ? mapComponentName(value[0], value[1])
    : value;
  const style = {
    all: "unset",
    paddingLeft: indent,
    textAlign: textAlign ? textAlign : "left",
    fontSize: 13,
    color: "#64b5f6",
    height: 25,
    ...styleObj,
  };
  // debugger;
  return isButton ? (
    <Button
      style={style}
      className={"button"}
      onClick={handleClick}
      disableRipple={true}>
      {eventName}
    </Button>
  ) : (
    <Box style={{ paddingLeft: indent }}>{eventName}</Box>
  );
}

export function renderLink(label, props, tooltip, key) {
  const classes = props.disabled ? "disabledButton" : "button";

  return (
    <Tooltip
      disableHoverListener={props.disabled}
      placement={"top-start"}
      title={<h2>{tooltip}</h2>}
      key={key}
      arrow>
      <Box>
        <Link
          className={classes}
          onClick={(e) => {
            if (props.disabled) e.preventDefault();
          }}
          to={props.to}
          style={{
            color: "#023059",
            textDecoration: "underline",
            textUnderlineOffset: "4px",
          }}>
          {label}
        </Link>
      </Box>
    </Tooltip>
  );
}

export function getLifePctTextColor(value) {
  const GREEN = "#1f9f04";
  const YELLOW = "#e6cd53";
  const RED = "#ff6c5c";
  return value >= 60 ? GREEN : value <= 40 ? RED : YELLOW;
}

export function renderLifePctBar(value, indent) {
  if (value <= 0) {
    value = 0;
  }
  const lifePct = _.isObject(value) ? value[0] : value;
  const scrapCycle = _.isObject(value) ? value[1] : null;
  return lifePct >= 0 ? (
    <Box style={{ paddingRight: indent, textAlign: constants.RIGHT }}>
      <Box>
        <Box
          className='lifePctLabel'
          style={{ color: getLifePctTextColor(lifePct) }}>{`${lifePct} %`}</Box>

        <Box className='lifePctBar'>
          <BorderLinearProgress value={lifePct} />
        </Box>
      </Box>
      {scrapCycle ? (
        <Box>
          <Box
            style={{ marginTop: 4, color: "#64B5F6" }}
            className='lifePctLabel'>{`FC ${scrapCycle}`}</Box>
          <Box
            style={{
              display: "inline-block",
              width: 59,
            }}>
            <InfoPopup />
          </Box>
        </Box>
      ) : null}
    </Box>
  ) : (
    <Box style={{ paddingRight: indent, textAlign: constants.RIGHT }}>---</Box>
  );
}

export function renderMultiValueCellValue(
  items,
  indent,
  width = 100,
  prefix = null,
  keepZero = false,
  align = constants.RIGHT,
  type = constants.NUMERIC_CELL
) {
  if (!items) return constants.___;

  const valueCount = _.sum(
    items.map((item) => ifValueExist(item.value, keepZero))
  );

  const paddingRight = align === constants.RIGHT ? indent : 0;
  const paddingLeft = Math.abs(paddingRight - indent);
  const marginRight = align === constants.RIGHT ? 0 : "auto";
  const marginLeft = align === constants.LEFT ? 0 : "auto";

  // No data should be shown for multi-value cells for "Total" row.
  // items === []
  if (items.length === 0 || (items.length !== 0 && valueCount === 0)) {
    return <Box style={{ paddingRight, paddingLeft }}>---</Box>;
  }

  const gridSpacing =
    items.length === 4 ? 0 : items.length === 3 ? "1px 0" : "1px 0";

  return (
    <Box>
      <Grid container style={{ padding: 3 }}>
        {items.map((item) =>
          !ifValueExist(item.value, keepZero) ? null : (
            <Grid key={item.label} item sm={12} style={{ margin: gridSpacing }}>
              <Box
                style={{
                  marginRight,
                  marginLeft,
                  width: width,
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Box
                  className={"multiValuesCellValueLabel Caption"}
                  style={{ paddingLeft, fontSize: 11 }}>
                  {item.label}
                </Box>
                <Box
                  className={"multiValuesCellValue"}
                  style={{
                    paddingRight,
                    color: item.value < 0 ? "#FF6C5C" : "#263238",
                    fontSize: 11,
                  }}>
                  {type === constants.NUMERIC_CELL
                    ? formatNumber(item.value, prefix)
                    : type === constants.DATE_CELL
                    ? moment.utc(item.value).format(constants.DATE_FORMAT)
                    : item.value}
                </Box>
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
}

export function renderValueWithIcon(value, hidden) {
  return (
    <span>
      <div style={{ verticalAlign: "text-bottom", display: "inline-flex" }}>
        {hidden ? (
          <div style={{ width: "30px" }}></div>
        ) : (
          <BookMark value={""} height={20} />
        )}
      </div>
      <div
        style={{
          marginRight: 8,
          display: "inline-flex",
        }}>
        {value}
      </div>
    </span>
  );
}

export function validate(type, value, required) {
  let valid = false;
  if (type === constants.TEXT) {
    if (
      value.includes("\\") ||
      value.includes("/") ||
      value[0] === " " ||
      value[value.length - 1] === " "
    )
      return false;
  }

  switch (type) {
    case constants.DATE:
      valid = value && moment.utc(value).isValid();
      return required ? valid : !value || valid;
    case constants.PERCENTAGE:
      valid = value && value.toString().match(constants.NUMBER_REG) !== null;
      valid = valid && value <= 1 && value >= 0;
      return required ? valid : !value || valid;
    case constants.NUMERIC:
    case constants.NUMBER:
      valid =
        value &&
        value >= 0 &&
        value.toString().match(constants.NUMBER_REG) !== null;
      return required ? valid : !value || valid;
    case constants.NEG_NUMERIC:
    case constants.NEG_NUMBER:
      //console.log(value);
      if (value) {
        let neg_value = value.toString().replace("-", "");
        valid = neg_value && neg_value.match(constants.NUMBER_REG) !== null;
      }
      return required ? valid : !value || valid;
    case constants.CHECK_BOX:
      return required ? value.length !== 0 : true;
    case constants.SELECT:
      return true;
    case constants.AUTO_COMPLETE:
      return true;
    case constants.RADIO:
      return true;
    default:
      valid = value ? true : false;
      return required ? valid : true;
  }
}

export function parseUrlObject(url) {
  var objURL = {};

  let pathValues = url.pathname.split("/");
  objURL["pathValues"] = pathValues;

  let queryString = url.search;
  queryString.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    }
  );
  return objURL;
}

export function linearInterpolation(t0, t, t1, y_t0, y_t1, unit) {
  const dateDiff20 = moment(t1).diff(moment(t0), unit);
  const dateDiff10 = moment(t).diff(moment(t0), unit);
  const valueDiff20 = y_t1 - y_t0;
  const y_t = y_t0 + (valueDiff20 * dateDiff10) / dateDiff20;

  return _.round(y_t, 5);
}

export function XNPVcalculation(rate, PVlist, di, d1) {
  if (Array.isArray(PVlist)) {
    let PValues = [];
    let dateDiff = null;
    for (var i = 0; i < PVlist.length; i++) {
      dateDiff = moment.utc(di[i]).diff(moment.utc(d1), "day");
      PValues.push(PVlist[i] / Math.pow(1 + rate, dateDiff / 365));
    }
    return _.round(_.sum(PValues));
  } else {
    return "please provide array";
  }
}

// format excel columns for exporting files
export function formatColumn(worksheet, col, fmt) {
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  // note: range.s.r + 1 skips the header row
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = XLSX.utils.encode_cell({ r: row, c: col });
    if (worksheet[ref] && worksheet[ref].t === "n") {
      worksheet[ref].z = fmt;
    }
  }
}

export function fitToColumn(arrayOfArray) {
  // get maximum character of each column
  // only works on xlsx Files, will not work for csvs
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length + 6 : 10))
    ),
  }));
}

export function sessionStoreHelper(itemName, data, timeout = 4) {
  window.sessionStorage.setItem(itemName, JSON.stringify(data));
  let checkSessionTimeout = window.sessionStorage.getItem(
    "sessionExpireDatetime"
  );
  if (!checkSessionTimeout) {
    let currentTime = new Date();
    let expireTime = new Date(
      currentTime.setHours(currentTime.getHours() + timeout)
    );
    window.sessionStorage.setItem(
      "sessionExpireDatetime",
      expireTime.toUTCString()
    );
  }
}

export function sessionGetDataHelper(itemName) {
  let sessionTimeout = window.sessionStorage.getItem("sessionExpireDatetime");
  if (sessionTimeout) {
    if (new Date(sessionTimeout) < new Date()) {
      window.sessionStorage.clear();
      return null;
    } else {
      return JSON.parse(window.sessionStorage.getItem(itemName));
    }
  } else {
    return null;
  }
}

export function localStoreHelper(itemName, data) {
  window.localStorage.setItem(itemName, JSON.stringify(data));
}

export function localStoreGetDataHelper(itemName) {
  return JSON.parse(window.localStorage.getItem(itemName));
}
