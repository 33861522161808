import React, { useEffect } from "react";
import { Button } from "@material-ui/core";

function setPageSize(styleId, margin, orientation, additionalCss) {
  const style = document.createElement("style");
  style.innerHTML = `@media print {
  ${additionalCss}
  #multifunctionaltable,
  #multifunctionaltable * {
    break-inside: auto;
    width: 100%;
    table-layout: fixed;
  }
  #datatable {
    width: 100%;
    display: block;
  }
  #threedotbutton {
    visibility: hidden;
  }
  @page { 
    margin: ${margin}; 
    size: ${orientation};
    }
  } `;
  //   style.innerHTML = `@page { margin: ${margin}; size: ${orientation}}`;
  style.id = styleId;
  document.head.appendChild(style);
}

const VfPrint = ({
  styleId,
  margin = 0,
  orientation = "portrait",
  additionalCss,
}) => {
  // Set orientation of page being printed
  useEffect(() => {
    setPageSize(styleId, margin, orientation, additionalCss);
    return () => {
      const child = document.getElementById(styleId);
      child.parentNode.removeChild(child);
    };
  }, [orientation]);

  return (
    <Button
      style={{ maxHeight: 28, marginLeft: 16 }}
      onClick={() => window.print()}
      variant='outlined'
      color='primary'>
      <span style={{ margin: "0px 8px 0px 8px" }}>Print</span>
    </Button>
  );
};

export default VfPrint;
