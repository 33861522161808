import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";

const getLeaseTemplate = (leaseInfo) => {
  return {
    lessee: leaseInfo.lessee,
    // ?
    subLessee: leaseInfo.subLessee !== undefined ? leaseInfo.subLessee : null,
    leaseStartDate: leaseInfo.startDate,
    leaseEndDate: leaseInfo.endDate,
    securityDeposit_Cash:
      leaseInfo.securityDeposit_Cash !== undefined
        ? leaseInfo.securityDeposit_Cash
        : [],
    securityDeposit_LC:
      leaseInfo.securityDeposit_LC !== undefined
        ? leaseInfo.securityDeposit_LC
        : [],
    rrR_Cost: leaseInfo.rrrCost !== undefined ? leaseInfo.rrrCost : null,
    // securityDeposit_Cash: leaseInfo.securityDeposit_Cash,
    // securityDeposit_LC: leaseInfo.securityDeposit_LC,
    // rrR_Cost: leaseInfo.rrrCost,
    leaseStatus: leaseInfo.leaseStatus,
    leaseType: leaseInfo.leaseType,
    rentPayments: [],
    extensions: [],
    reserveAccounts: [],
    reserveAccountCaps: [],
    creditAccounts: [],
  };
};

export const getComponentLeaseInfoTemplate = (leaseInfo, llp = false) => {
  return {
    id: 0,
    redeliveryCondition: {
      maxUsed: {
        flightHour: "Infinity",
        flightCycle: "Infinity",
        activeHour: "Infinity",
        day: "Infinity",
      },
      minLeft: {
        flightHour: 0,
        flightCycle: 0,
        activeHour: 0,
        day: 0,
      },
      isWaived: false,
      compensationIfWaived: null,
    },
    deliveryCondition: {
      maxUsed: {
        flightHour: "Infinity",
        flightCycle: "Infinity",
        activeHour: "Infinity",
        day: "Infinity",
      },
      minLeft: {
        flightHour: 0,
        flightCycle: 0,
        activeHour: 0,
        day: 0,
      },
      isWaived: false,
      compensationIfWaived: null,
    },
    fundingPolicies: [
      {
        maintenanceNumber: 2,
        isSweepWhenDraw: false,
        cashReserveDrawableType:
          leaseInfo.template === "Generic" && llp
            ? "ApportionedAllocation"
            : "Balance",
        lessorExposureType:     
            leaseInfo.template === "Generic" && llp
              ? "DeliveryUsageWithDeliveryCost" : "None",
        lessorExposureLimit: "Infinity",
        llpReplaceCostType: "NetCost",
        fundingPriority: ["CashReserve", "CreditReserve", "LessorContribution"],
      },
      {
        maintenanceNumber: 1,
        isSweepWhenDraw: false,
        cashReserveDrawableType:
          leaseInfo.template === "Generic" && llp
            ? "ApportionedAllocation"
            : "Balance",
        lessorExposureType:
          leaseInfo.template === "Generic" && llp
            ? "DeliveryUsageWithDeliveryCost"
            : leaseInfo.template === "Generic" && !llp ? "DeliveryUsageWithDeliveryRate" : "None",
        lessorExposureLimit: "Infinity",
        llpReplaceCostType: "NetCost",
        fundingPriority: ["CashReserve", "CreditReserve", "LessorContribution"],
      },
    ],
    compPolicy: {
      usageType: llp ? "ToEvent" : "SinceEvent",
      redeliveryCompType: "None",
      maintenanceReserveBalanceReceiver: "Lessor",
      referenceRateDateType: "Redelivery",
      referenceRateType: "FromLease",
      redeliveryRateDateType: "Redelivery",
      redeliveryRateType: "FromLease",
      scaleFactor: 1,
      compCap: "Infinity",
      extraComp: 0,
    },
    maintenanceReserveRates: [],
  };
};

const expandIntervals = (values) => {
  let returnMe = [];
  const interval = values;
  const paymentFrequency =
    values.paymentFrequency.value === constants.Quarterly ? 3 : 1;

  let from = interval.fromDate.value;
  const to = interval.endDate.value;

  while (moment.utc(to).diff(from, "month") >= paymentFrequency) {
    let addMe = {};
    let end = moment.utc(from).add(paymentFrequency, "month").add(-1, "days");

    let paymentDate = null;
    if (interval.paymentDate.value === "inAdvance") {
      paymentDate = moment.utc(from);
    } else {
      paymentDate = end;
    }

    addMe.from = from;
    addMe.to = end.format(constants.ISOFormat);
    addMe.paymentDate = paymentDate.format(constants.ISOFormat);

    if (interval.pbh.value === "isPBH") {
      addMe.amount = null;
      addMe.pbhRate = interval.amount.value;
    } else {
      addMe.amount = interval.amount.value;
      addMe.pbhRate = null;
    }
    returnMe.push(addMe);
    from = moment
      .utc(from)
      .add(paymentFrequency, "month")
      .format(constants.ISOFormat);
  }

  if (
    (moment.utc(to).diff(from, "days") < 31 &&
      moment.utc(to).diff(from, "days") > 1) ||
    (moment.utc(to).diff(from, "month") < paymentFrequency &&
      moment.utc(to).diff(from, "month") > 0)
  ) {
    let paymentDate = null;
    let addMe = {};
    if (interval.paymentDate.value === "inAdvance") {
      paymentDate = from;
    } else {
      paymentDate = to;
    }

    addMe.from = from;
    addMe.to = to;
    addMe.paymentDate = paymentDate;
    if (interval.pbh.value === "isPBH") {
      addMe.amount = null;
      addMe.pbhRate = interval.amount.value;
    } else {
      addMe.amount = interval.amount.value;
      addMe.pbhRate = null;
    }

    returnMe.push(addMe);
  }
  return returnMe;
};

const mxReserveRatesTemplate = (
  component,
  componentName,
  leaseInfo,
  llp = false
) => {
  let genericMxInfo =
    componentName === constants.ENGINES && leaseInfo.engineRun === "matureRun"
      ? component.genericMxInfo[1]
      : component.genericMxInfo[0];

  let flightHour = null,
    flightCycle = null,
    activeHour = null,
    day = null;

  if (llp) {
    let llps = component.llpStack.llPs;
    flightCycle = _.chain(llps)
      .map((llp) => llp.price[0].price / llp.price[0].cycleLifeLimit)
      .sum()
      .round(2)
      .value();
  }

  if (genericMxInfo && !llp) {
    flightHour = genericMxInfo.interval.flightHour
      ? _.round(genericMxInfo.cost.cost / genericMxInfo.interval.flightHour, 2)
      : null;
    flightCycle = genericMxInfo.interval.flightCycle
      ? _.round(genericMxInfo.cost.cost / genericMxInfo.interval.flightCycle, 2)
      : null;
    activeHour = genericMxInfo.interval.activeHour
      ? _.round(genericMxInfo.cost.cost / genericMxInfo.interval.activeHour, 2)
      : null;
    day = genericMxInfo.interval.day
      ? _.round(genericMxInfo.cost.cost / genericMxInfo.interval.day, 2)
      : null;
  }

  return {
    rateDate:
      genericMxInfo && !llp
        ? genericMxInfo.cost.costDate
        : llp
        ? component.llpStack.llPs[0].price[0].asOfDate
        : null,
    rateType: "Cash",
    isCashReserve: false,
    rateInterval: {
      flightHour: flightHour ? flightHour : null,
      flightCycle:
        componentName !== constants.ENGINES || llp ? flightCycle : null,
      activeHour: componentName !== constants.ENGINES ? activeHour : null,
      day:
        componentName !== constants.ENGINES &&
        componentName !== constants.LANDING_GEARS
          ? day
          : null,
      monthly: null,
      escalationRate:
        genericMxInfo && !llp
          ? genericMxInfo.cost.costEscalation
          : llp
          ? component.llpStack.llPs[0].price[0].escalationRate
          : null,
    },
  };
};

const deleteLease = (assetDetails, scenario, components, leaseInfo) => {
  scenario.leases = [...scenario.leases];
  scenario.leases = _.filter(
    scenario.leases,
    (o) => o.leaseId !== leaseInfo.leaseId
  );
};

const updateLeaseInfo = (scenario, leaseInfo) => {
  scenario.leases = [...scenario.leases];
  const idx = _.findIndex(
    scenario.leases,
    (o) => o.leaseId === leaseInfo.leaseId
  );
  const lease = { ...scenario.leases[idx] };
  lease.lessee = leaseInfo.lessee;
  lease.subLessee = leaseInfo.subLessee;
  lease.leaseStartDate = leaseInfo.startDate;
  lease.leaseEndDate = leaseInfo.endDate;
  lease.securityDeposit_Cash = leaseInfo.securityDeposit_Cash;
  lease.securityDeposit_LC = leaseInfo.securityDeposit_LC;
  lease.rrR_Cost = leaseInfo.rrrCost;
  lease.leaseStatus = leaseInfo.leaseStatus;
  lease.leaseType = leaseInfo.leaseType;

  scenario.leases[idx] = lease;
};

const addLease = (assetDetails, scenario, components, leaseInfo) => {
  let newLease = getLeaseTemplate(leaseInfo);
  let componentLeaseInfo = [];
  let reserveAccCounter = 1;

  for (var i = 0; i < components.length; i++) {
    if (assetDetails[components[i]]) {
      const reserveAccountsGroups = [];
      for (var j = 0; j < assetDetails[components[i]].length; j++) {
        const component = assetDetails[components[i]][j];
        const compLeaseInfo = getComponentLeaseInfoTemplate(leaseInfo);

        const mxRRTemplate = mxReserveRatesTemplate(
          component,
          components[i],
          leaseInfo
        );

        switch (components[i]) {
          case constants.AIRFRAME_CHECKS:
            compLeaseInfo.componentName = component.checkName;
            if (leaseInfo.template === "Generic") {
              // add each airframe check as seperate reserve account
              newLease.reserveAccounts.push({
                id: reserveAccCounter,
                components: [component.checkName],
              });
              reserveAccCounter = reserveAccCounter + 1;
            }
            break;
          case constants.ENGINES:
            compLeaseInfo.componentName = component.position;
            const leaseInfoEngineLLP = getComponentLeaseInfoTemplate(
              leaseInfo,
              true
            );
            leaseInfoEngineLLP.componentName =
              "LLP Stack " + component.position;

            leaseInfoEngineLLP.maintenanceReserveRates =
              leaseInfo.template === "Generic"
                ? [
                    mxReserveRatesTemplate(
                      component,
                      components[i],
                      leaseInfo,
                      true
                    ),
                  ]
                : [];
            componentLeaseInfo.push(leaseInfoEngineLLP);

            if (leaseInfo.template === "Generic") {
              // add each engine and llp as seperate reserve account
              newLease.reserveAccounts.push({
                id: reserveAccCounter,
                components: [component.position],
              });
              reserveAccCounter = reserveAccCounter + 1;
              newLease.reserveAccounts.push({
                id: reserveAccCounter,
                components: ["LLP Stack " + component.position],
              });
              reserveAccCounter = reserveAccCounter + 1;
            }

            if (!_.isEmpty(component.supplementalEvents)) {
              const suppReserveAccGroup = [];
              component?.supplementalEvents?.forEach((ev) => {
                const leaseInfoEE = getComponentLeaseInfoTemplate(leaseInfo);

                leaseInfoEE.maintenanceReserveRates =
                  leaseInfo.template === "Generic"
                    ? [mxReserveRatesTemplate(ev, components[i], leaseInfo)]
                    : [];
                leaseInfoEE.componentName =
                  ev.eventName + " " + component.position;

                suppReserveAccGroup.push(
                  ev.eventName + " " + component.position
                );

                componentLeaseInfo.push(leaseInfoEE);
              });

              // add engine supplemental events to one account

              newLease.reserveAccounts.push({
                id: reserveAccCounter,
                components: suppReserveAccGroup,
              });
              reserveAccCounter = reserveAccCounter + 1;
            }
            break;
          case constants.LANDING_GEARS:
            compLeaseInfo.componentName = component.position;
            reserveAccountsGroups.push("LG " + component.position);
            break;
          default:
            compLeaseInfo.componentName = constants.APU.toUpperCase();
            reserveAccountsGroups.push(constants.APU.toUpperCase());
            break;
        }

        compLeaseInfo.maintenanceReserveRates =
          leaseInfo.template === "Generic" ? [mxRRTemplate] : [];

        componentLeaseInfo.push(compLeaseInfo);
      }
      // add reserve accounts for each component except for engine
      if (
        leaseInfo.template === "Generic" &&
        components[i] !== constants.ENGINES &&
        components[i] !== constants.AIRFRAME_CHECKS
      ) {
        newLease.reserveAccounts.push({
          id: reserveAccCounter,
          components: reserveAccountsGroups,
        });
        reserveAccCounter = reserveAccCounter + 1;
      }
    }
  }

  const leaseRenttemplate = {
    fromDate: { value: null },
    endDate: { value: null },
    paymentDate: {
      value: "inAdvance",
    },
    amount: { value: null },
    paymentFrequency: {
      value: constants.Monthly,
    },
    pbh: {
      value: "notPBH",
    },
  };

  if (leaseInfo.template === "Generic" && leaseInfo.rent) {
    leaseRenttemplate.fromDate.value = leaseInfo.startDate;
    leaseRenttemplate.endDate.value = leaseInfo.endDate;
    leaseRenttemplate.amount.value = leaseInfo.rent;
    leaseRenttemplate.paymentDate.value = leaseInfo.paymentDate;
    leaseRenttemplate.paymentFrequency.value = leaseInfo.paymentFrequency;
    leaseRenttemplate.pbh.value = leaseInfo.pbhRate;

    newLease.rentPayments = expandIntervals(leaseRenttemplate);
  }

  newLease.leaseId = new Date().valueOf();
  newLease.componentLeaseInfo = componentLeaseInfo;
  scenario.leases = [...scenario.leases];
  scenario.leases.push(newLease);
};

export const updateData = (assetDetails, actionType, leaseInfo, scenario) => {
  const components = [
    constants.AIRFRAME_CHECKS,
    constants.ENGINES,
    constants.apUs,
    constants.LANDING_GEARS,
  ];

  if (actionType === constants.ADD_ROW)
    addLease(assetDetails, scenario, components, leaseInfo);
  else if (actionType === constants.UPDATE_ROW)
    updateLeaseInfo(scenario, leaseInfo);
  else {
    deleteLease(assetDetails, scenario, components, leaseInfo);
  }
};
