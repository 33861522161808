import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Box, Grid, Button } from "@material-ui/core";
import { AddBox, Visibility } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DataTable from "../../../../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../../../../Common/Tables/EditableTable/EditableTable";
import SectionHeader from "../../../../../../Common/Section/SectionHeader";
import * as constants from "../../../../../../../constants";
import LoadingPanel from "../../../../../../Common/LoadingPanel";
import { leaseViewModel } from "../../../../../mapToViewModels";
import ReserveCaps from "./reserveCaps";

const formatData = (
  assetDetails,
  componentType,
  generateEditableProps,
  lease,
  leaseIndex,
  lessors,
  scenario,
  lessees
) => {
  let props = generateEditableProps(leaseIndex, componentType);

  let threeDotBtnOptions = [
    {
      id: 0,
      icon: Visibility,
      label: constants.VIEW,
    },
    {
      id: 1,
      icon: AddBox,
      label: constants.ADD_ROW,
    },
  ];

  return {
    data: leaseViewModel(
      assetDetails,
      props,
      lease,
      lessors,
      scenario,
      threeDotBtnOptions,
      lessees
    ),
    componentType,
  };
};

const Lease = ({
  assetDetails,
  generateEditableProps,
  leaseIndex,
  lease,
  scenario,
  handleValueChange,
  lesseeData,
  lessorData,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  });

  const { data } = formatData(
    assetDetails,
    constants.LEASES,
    generateEditableProps,
    lease,
    leaseIndex,
    lessorData,
    scenario,
    lesseeData
  );

  // Add a reserveAccountCaps Section
  const addCap = {
    label: `${constants.ADD} Cap`,
    handleClick: () =>
      handleValueChange(constants.ADD_ROW)(
        constants.SCENARIO,
        leaseIndex,
        "Account Caps",
        null
      ),
  };

  const renderTable = (
    data,
    span = [],
    setDisplayStlye = true,
    maxHeight = false
  ) => {
    const display = { display: "grid" };

    const table =
      data.tableType === constants.DATA_TABLE ? (
        <DataTable tableData={data} />
      ) : (
        <EditableTable table={data} maxHeight={maxHeight} />
      );
    return (
      <Grid
        style={setDisplayStlye ? display : {}}
        item
        sm={span[0]}
        md={span[0]}
        lg={span[1]}>
        {table}
      </Grid>
    );
  };

  return (
    <Box>
      {loading ? (
        <LoadingPanel />
      ) : (
        <Box>
          <Grid container spacing={2}>
            {renderTable(data.leaseSummary, [12, 12])}
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={6}>
              <Grid container spacing={2}>
                {renderTable(data.leaseExtension, [12, 12])}
                {renderTable(data.extensionRent, [12, 12], true, 250)}
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={6}>
              {renderTable(data.leaseRent, [12, 12], true, 500)}
            </Grid>
          </Grid>

          <SectionHeader label={"Lease Deposit"} fontSize={20} />

          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={6}>
              {renderTable(data.LeaseDepositCash, [12, 12], true, 500)}
            </Grid>
            <Grid item sm={12} md={12} lg={6}>
              {renderTable(data.LeaseDepositLC, [12, 12], true, 500)}
            </Grid>
          </Grid>

          <SectionHeader
            label={constants.MAINTEANCE_RESERVE}
            fontSize={20}
            button={
              <Button
                variant='outlined'
                color='primary'
                onClick={addCap.handleClick}>
                <AddIcon fontSize='default' />
                <span style={{ marginLeft: 8, marginRight: 8 }}>
                  {addCap.label}
                </span>
              </Button>
            }
          />
          <Grid container spacing={2}>
            {renderTable(data.maintenanceReserveRates, [12, 12])}
            {renderTable(data.cashReserveAccounts, [6, 6])}
            {renderTable(data.creditLineReserve, [6, 6])}
            <Grid item sm={12} style={{ display: "grid" }}>
              {data.cashReserveCap.map((reserveCaps, idx) => (
                <ReserveCaps
                  key={idx}
                  idx={idx}
                  reserveCaps={reserveCaps}
                  leaseIndex={leaseIndex}
                  handleValueChange={handleValueChange}
                />
              ))}
            </Grid>
          </Grid>

          <SectionHeader
            label={constants.MAINTENANCE_FUNDING_AND_ADJUSTMENT}
            fontSize={20}
          />
          <Grid container spacing={2}>
            {renderTable(data.maintenanceFunding, [12, 7])}
            <Grid item sm={12} md={12} lg={5}>
              {renderTable(data.llpReplacementCostClaimable, [12, 12], false)}
              <br></br>
              {renderTable(data.eolCompBounds, [12, 12])}
            </Grid>

            {renderTable(data.redeliveryAdjustments, [12, 12], false)}
          </Grid>

          <SectionHeader label={constants.STATUS_REQUIREMENTS} fontSize={20} />
          <Grid container spacing={2}>
            {renderTable(data.deliveryConditions, [12, 12])}
            {renderTable(data.redeliveryConditions, [12, 12])}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Lease;
