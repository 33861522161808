import React, { useState } from "react";
import { Box, Grid, Tabs, Tab } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

import MultiFunctionTable from "../../Common/Tables/MultiFunctionTable/MultiFunctionTable";

import ExpectedLossBarChart from "./ExpectedLossComponents/ExpectedLossBarChart";
import ExpectedLossScatterPlot from "./ExpectedLossComponents/ExpectedLossScatterPlot";

const ExpectedLossRiskSection = ({
  deal,
  expectLossTranchesData,
  expectedLossData,
  liabilityData,
}) => {
  return (
    <Box>
      <Box>
        <ExpectedLossBarChart
          expectLossTranchesData={expectLossTranchesData}
          expectedLossData={expectedLossData}
          liabilityData={liabilityData}
        />

        <ExpectedLossScatterPlot deal={deal} />

        <Box style={{ margin: 20 }}></Box>

        <MultiFunctionTable
          tableData={expectLossTranchesData[0].data.expectedLossTranches}
          containerMaxHeight={400}
          showPagination={false}
        />
      </Box>
    </Box>
  );
};

export default ExpectedLossRiskSection;
