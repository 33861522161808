import _ from "lodash";
import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";

const VfCheckBox = ({
  items,
  label,
  required,
  selected,
  valid,
  fontSize,
  onChange,
}) => {
  const [values, setValues] = useState(selected || []);
  const handleChange = (event) => {
    let newValues = [...values];
    if (values.includes(event.target.name))
      newValues = _.without(values, event.target.name);
    else newValues.push(event.target.name);
    setValues(newValues.sort());

    onChange(newValues.sort());
  };

  return (
    <Grid item lg={12}>
      <FormControl required error={false} component='fieldset'>
        {label && (
          <FormLabel
            style={{ fontSize: 13 }}
            component={"legend"}
            error={
              !valid || (required && values.length === 0 && (!selected || !selected.length))
            }>
            {label} {required ? "(Select at least one!)" : null}
          </FormLabel>
        )}
        {label && <Box mt={1} />}
        <FormGroup>
          <Grid container spacing={2}>
            {items.map((item, idx) => (
              <Grid key={idx} item lg={items.length === 1 ? 12 : 4}>
                <FormControlLabel
                  checked={_.includes(selected, item.value)}
                  control={<Checkbox style={{ color: "#023059" }} />}
                  onChange={handleChange}
                  name={item.value}
                  label={
                    <div
                      style={{
                        minWidth: 100,
                        fontSize: fontSize || 12,
                        fontWeight: 500,
                        color: "#023059",
                      }}>
                      {item.label}
                    </div>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

export default VfCheckBox;
