import _ from "lodash";
import React, { Component } from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import * as constants from "../constants";
import LoadingPanel from "../Components/Common/LoadingPanel";
import AssetComponents from "../Components/AssetModel/AssetComponents";
import VfBreadcrumbs from "./../Components/Common/UtilComponents/VfBreadcrumbs";
import ErrorBoundary from "./GeneralErrorPage";
import * as utils from "./../utils/utils";
import { apiRequest, response } from "./../utils/ApiRequest";
import { MsalContext } from "@azure/msal-react";
import apiRoutes from "./../utils/ApiRoutes";
import axios from "axios";

const styles = {
  main: {
    width: "100%",
    // maxWidth: "100%",
  },
};

class AssetModel extends Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      currAssetId: null,
      deal: null,
      assetPool: null,
      assets: null,
      scenarios: null,
    };
  }

  getDealData = async (dealId) => {
    let apiEndPoint = apiRoutes.VectorDeal_GetDealsByDealId({
      dealId,
    });
    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
      },
      this.context
    );
  };

  getPoolData = async (poolId) => {
    let apiEndPoint = apiRoutes.AssetPool_GetAssetPoolById({
      id: poolId,
    });
    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
      },
      this.context
    );
  };

  componentDidMount = async () => {
    const urlValues = utils.parseUrlObject(new URL(window.location.href));
    const pathValues = urlValues.pathValues;
    const assetId = parseInt(pathValues[pathValues.length - 1]);

    if (this.props.location.state) {
      const { deal, assetPool, assets } = this.props.location.state;
      this.setState({
        deal: deal,
        assetPool: assetPool,
        assets: assets,
        currAssetId: assetId,
        scenarios: urlValues.scenarios,
      });
    } else {
      let dealId = parseInt(urlValues.dealId);
      let poolId = parseInt(urlValues.poolId);

      if (dealId) {
        let dealData = await this.getDealData(dealId);
        if (dealData.status === response.OK) {
          this.setState({
            deal: dealData.data,
            // assetPool: { assetPoolId: 1, assetPoolName: "SpiceJet 737-F" },
            assets: dealData.data.assets,
            currAssetId: assetId,
            scenarios: urlValues.scenarios,
          });
        }
      } else {
        let poolData = await this.getPoolData(poolId);
        if (poolData.status === response.OK) {
          this.setState({
            assetPool: poolData.data,
            assets: poolData.data.assetPool_Assets,
            currAssetId: assetId,
            scenarios: urlValues.scenarios,
          });
        }
      }
    }
  };

  handleAssetChangeEvt = (id) => {
    const { deal, assetPool, assets } = this.state;
    const asset = _.find(assets, (o) =>
      deal ? o.id === id : o.assetId === id
    );
    let newUrl =
      id +
      `?` +
      (deal
        ? `dealId=${deal.dealId}&dealName=${deal.dealName}&`
        : `poolId=${assetPool.assetPoolId}&poolName=${assetPool.assetPoolName}`) +
      `&assetType=${asset.assetType}&serialNumber=${asset.serialNumber}`;
    window.history.pushState({}, null, newUrl);
    this.setState({ currAssetId: id });
  };

  renderBreadcrumbs = () => {
    var breadcrumbs = [];
    const { deal, assets, scenarios, currAssetId } = this.state;
    const urlScenarios = scenarios ? `?scenarios=${scenarios}` : "";
    const assetModel = _.find(assets, (o) =>
      deal ? o.id === currAssetId : o.assetId === currAssetId
    );

    let sortedAssets = _.sortBy(assets, ["subseries", "serialNumber"]);

    if (deal) {
      const { sponsor, dealName } = deal;
      const dealInfo = _.template(`Portfolio - <%= sponsor %>/<%= dealName %>`);

      breadcrumbs = [
        {
          label: "Deals",
          type: constants.LINK,
          tooltip: "Back to Deals",
          props: { to: "/deals" },
        },
        {
          label: dealInfo({ sponsor, dealName }),
          type: constants.LINK,
          tooltip: "Go to Portfolio",
          props: {
            to: {
              pathname: `/deal/portfolio/${deal.dealId}` + urlScenarios,
              state: { deal: deal, dealId: deal.dealId },
            },
            handleClick: null,
          },
        },
      ];
    } else {
      breadcrumbs = [
        {
          label: "Asset Pools",
          type: constants.LINK,
          tooltip: "Back to Asset Pools",
          props: { to: "/assetpools" },
        },
      ];
    }

    breadcrumbs.push({
      label: `${assetModel.subseries} (${assetModel.serialNumber})`,
      type: constants.DROPDOWN,
      items: _.chain(sortedAssets)
        .map((o) => ({
          id: deal ? o.id : o.assetId,
          label: `${o.subseries} (${o.serialNumber})`,
          props: {
            handleClick: this.handleAssetChangeEvt,
          },
        }))
        .value(),
    });

    return <VfBreadcrumbs breadcrumbs={breadcrumbs} />;
  };

  render() {
    const { classes, accountObj } = this.props;
    const { currAssetId, deal, assets } = this.state;
    let assetModel = null;
    if (assets)
      assetModel = _.find(assets, (o) =>
        deal ? o.id : o.assetId === currAssetId
      );

    return assetModel ? (
      <ErrorBoundary>
        <Box flexGrow={1}>
          <Box flexGrow={1} className={classes.main}>
            <AssetComponents
              //added react key prop to unmount the element with the old key and mount a new instance.
              //this handles the changing of assets in the breadcrumb removes state from previous asset.
              //will not save the state of the previous asset, so when going back to that asset, unpublished scenario will be lost.
              key={currAssetId}
              deal={deal}
              assetModel={assetModel}
              userName={accountObj.name}
              renderBreadcrumbs={this.renderBreadcrumbs}
            />
          </Box>
        </Box>
      </ErrorBoundary>
    ) : (
      <LoadingPanel />
    );
  }
}

AssetModel.propTypes = {
  accountObj: PropTypes.object.isRequired,
  // contains class names for styling components
  classes: PropTypes.object.isRequired,
  // this component should come from routing
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssetModel);
