import React from "react";
import { Paper, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VerticalTable from "../../../../Common/Tables/VerticalTable/VerticalTable";
import EditableTable from "../../../../Common/Tables/EditableTable/EditableTable";

const useStyles = makeStyles((theme) => ({
  rectangle13: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 12,
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  topSpace: {
    marginTop: 20,
  }
}));

const Collateral = ({
  data,
  editAll
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.rectangle13}>
      <VerticalTable tableData={data.verticalSection} customStyle={{}} editAll={editAll} />
      <Divider />
      <EditableTable table={data.aircraftsDetails} leaseSummary={true} editAll={editAll} />
    </Paper>
  );
};

export default Collateral;
