import { generateGenericComponent } from "./genericComponent";

export const generateAirframeCheck = async (
  componentName,
  genericComponent
) => {
  const returnMe = {
    checkName: null,
    genericId: 0,
    genericMxInfo: [],
    shopVisitHistory: [],
    maintenanceStatus: [],
    maintenanceEvents: [],
    utilizationHistory: [],
    componentType: genericComponent.componentType.type,
  };

  returnMe.genericId = genericComponent.id;
  returnMe.checkName = componentName;
  returnMe.componentType = genericComponent.componentType.type;

  returnMe.genericMxInfo = generateGenericComponent(genericComponent);

  return returnMe;
};
