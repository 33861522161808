import _ from "lodash";
import React from "react";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";
import * as leaseInfoTemplate from "../../../stateUpdate/lease/leaseList";

const Balance = "Balance";
const ApportionedAllocation = "ApportionedAllocation";
const DeliveryUsageWithDeliveryRate = "DeliveryUsageWithDeliveryRate";
const DeliveryUsageWithEventRate = "DeliveryUsageWithEventRate";
const DeliveryUsageWithDeliveryCost = "DeliveryUsageWithDeliveryCost";
const DeliveryUsageWithEventCost = "DeliveryUsageWithEventCost";
const Shortfall = "Shortfall";
const ApportionedAllocationShortfall = "ApportionedAllocationShortfall";
const NetCost = "NetCost";
const Betterment = "Betterment";

export function mapToViewModel_MxFundingAndLLPReplCostClaimable(
  components,
  props,
  compdata
) {
  const mxFunding = [];
  const llpReplCostTypes = [];
  let count = 0;
  let { section, sectionIdx, funcs } = props;

  Object.keys(components).map((key) => {
    components[key].map((mxEvent) => {
      if (
        mxEvent.componentLeaseInfo == null ||
        mxEvent.componentLeaseInfo == undefined
      ) {
        mxEvent.componentLeaseInfo =
          leaseInfoTemplate.getComponentLeaseInfoTemplate("Generic");
        mxEvent.componentLeaseInfo.componentName = mxEvent.checkName;
        funcs.handleValueChange("newcomp")(
          section,
          sectionIdx,
          "leaseComp",
          mxEvent.componentLeaseInfo
        );
      }

      const { checkName, componentLeaseInfo, componentName } = mxEvent;

      //   const mxStatus = mxEvent.mxStatus[0];
      // const mxNo = mxStatus.isFirstRun ? 1 : 2;
      const mxNo = 1;
      const fp = _.filter(componentLeaseInfo.fundingPolicies, function (e) {
        return e.maintenanceNumber === mxNo;
      })[0];
      if (checkName.includes("LLP")) {
        llpReplCostTypes.push({
          label: checkName,
          componentName,
          value: fp.llpReplaceCostType,
        });
      }

      mxFunding.push({
        id: count++,
        mxEventDisplay:
          checkName === constants.APU ? checkName.toUpperCase() : checkName,
        mxEvent: mxEvent.componentName ? mxEvent.componentName : checkName,
        cashReserveDrawableType: fp.cashReserveDrawableType,
        isSweepWhenDraw: fp.isSweepWhenDraw,
        lessorExposureType: fp.lessorExposureType,
        lessorExposureLimit:
          typeof fp.lessorExposureLimit === constants.STRING
            ? ""
            : fp.lessorExposureLimit,
        isSweepWhenDraw: fp.isSweepWhenDraw,
      });

      return null;
    });
    return null;
  });

  const mxEvents = _.flattenDeep(Object.values(props.allShopVisitEventNames));

  const cashReserveDrawableType = [
    { label: Balance, value: Balance },
    {
      label: ApportionedAllocation,
      value: ApportionedAllocation,
    },
  ];

  const lessorExposureType = [
    { label: constants.NONE, value: constants.NONE },
    {
      label: ApportionedAllocationShortfall,
      value: ApportionedAllocationShortfall,
    },
    {
      label: DeliveryUsageWithDeliveryRate,
      value: DeliveryUsageWithDeliveryRate,
    },
    {
      label: DeliveryUsageWithEventRate,
      value: DeliveryUsageWithEventRate,
    },
    {
      label: DeliveryUsageWithDeliveryCost,
      value: DeliveryUsageWithDeliveryCost,
    },
    {
      label: DeliveryUsageWithEventCost,
      value: DeliveryUsageWithEventCost,
    },
    {
      label: Shortfall,
      value: Shortfall,
    },
  ];

  const mxFundingTableData = {
    label: "Maintenance Funding",
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.maintenanceFunding,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns: [
      {
        title: "Mx Event",
        field: "mxEvent",
        initialEditValue: mxEvents[0].value,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        items: mxEvents,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // render: (rowData) =>
        //   rowData.mxEvent === constants.APU
        //     ? rowData.mxEvent.toUpperCase()
        //     : rowData.mxEvent,
        render: (rowData) => rowData.mxEventDisplay,
      },
      {
        title: "Cash Reserve Available",
        field: "cashReserveDrawableType",
        initialEditValue: cashReserveDrawableType[0].value,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        items: (rowData) => {
          if (rowData.mxEvent.includes("LLP")) return cashReserveDrawableType;
          else return cashReserveDrawableType.slice(0, -1);
        },
        //items: cashReserveDrawableType,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Reserve Sweep",
        field: "isSweepWhenDraw",
        initialEditValue: false,
        cellStyle: styles.cellStyle,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: false,
        items: [
          { label: constants.YES, value: true },
          { label: constants.NO, value: false },
        ],
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          rowData.isSweepWhenDraw ? constants.YES : constants.NO,
      },
      {
        title: "Lessor Contribution",
        field: "lessorExposureType",
        initialEditValue: lessorExposureType[0].value,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        items: lessorExposureType,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Lessor Contribution Cap",
        field: "lessorExposureLimit",
        required: false,
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.lessorExposureLimit,
            null,
            constants.NUMERIC,
            null,
            2
          ),
      },
    ],
    data: mxFunding,
  };

  const costClaimableOptions = [
    { label: NetCost, value: NetCost },
    { label: Betterment, value: Betterment },
    { label: constants.NONE, value: constants.NONE },
  ];

  const data = {};
  for (var i = 0; i < llpReplCostTypes.length; i++) {
    data[
      `${constants.llpReplacementCostClaimable}_${llpReplCostTypes[i].componentName}`
    ] = llpReplCostTypes[i].value;
  }

  const llpReplCostTypeTableData = {
    label: <span style={{ fontSize: 14 }}>LLP REPLACEMENT COST CLAIMABLE</span>,
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: constants.llpReplacementCostClaimable,
    props: props,
    errorsOnSubmit: {},
    columns: llpReplCostTypes.map((e) => ({
      label: [e.label],
      field: `${constants.llpReplacementCostClaimable}_${e.componentName}`,
      captions: null,
      span: [6, 6],
      editable: true,
      required: false,
      type: constants.TEXT,
      items: costClaimableOptions,
      initialEditValue: costClaimableOptions[0].value,
      showCellOptions: true,
      inputFieldType: constants.SELECT,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    })),
    data,
  };
  const EOLCompBoundsData = {
    label: <span style={{ fontSize: 14 }}>EOL Comp Bounds</span>,
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: "eolCompBounds",
    props: props,
    errorsOnSubmit: {},
    columns: [
      {
        label: ["CEILING"],
        field: "eoLcompCap",
        captions: null,
        span: [6, 6],
        editable: true,
        required: false,
        showCellOptions: true,
        type: constants.NUMERIC,
        initialEditValue: compdata.eoLcompCap,
        inputFieldType: constants.NUMERIC,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          value || value === 0
            ? utils.renderCellValue(value, null, constants.NUMERIC_CELL)
            : constants.___,
      },
      {
        label: ["FLOOR"],
        field: "eoLcompFloor",
        captions: null,
        span: [6, 6],
        editable: true,
        required: false,
        showCellOptions: true,
        type: constants.NUMERIC,
        initialEditValue: compdata.eoLcompFloor,
        inputFieldType: constants.NUMBER,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          value || value === 0
            ? utils.renderCellValue(value, null, constants.NUMERIC_CELL)
            : constants.___,
      },
    ],
    data: {
      eoLcompCap: compdata.eoLcompCap,
      eoLcompFloor: compdata.eoLcompFloor,
    },
  };

  return [mxFundingTableData, llpReplCostTypeTableData, EOLCompBoundsData];
}
