import _ from "lodash";
import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import EditableTable from "../../Common/Tables/EditableTable/EditableTable";
import * as constants from "../../../constants";
import { mapToViewModel } from "../mapToViewModels/index"

const formatData = (genericData, generateEditableProps, handleSelectChange, selectedModel) => {
  const componentType = "Combinations";
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModel(
      genericData,
      componentType,
      handleSelectChange,
      props,
      selectedModel,
    ),
    componentType,
  };
};

const GenericCombinations = ({ genericData, generateEditableProps }) => {
  const combinationData = formatData(genericData, generateEditableProps, null, null);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <EditableTable table={combinationData.data.ACAPUCombos} maxHeight={window.innerHeight - 300 } />
        </Grid>
        <Grid item sm={6}>
          <EditableTable table={combinationData.data.ACENGCombos} maxHeight={window.innerHeight - 300 } />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenericCombinations;
