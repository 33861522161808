import _ from "lodash";
import moment from "moment";
import React from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ApiRoutes from "../../../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
import VfButton from "../../../../../Common/UtilComponents/VfButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 111px 0 222px",
    height: 75,
    backgroundColor: "rgba(247,181,0,0.5)",
    boxShadow: "0 0.5px 2px 0 rgba(14,37,63,0.2)",
    marginBottom: 10,
  },
  outofdatebg: {
    padding: "0 111px 0 222px",
    height: 75,
    backgroundColor: "rgba(247,0,0,0.5)",
    boxShadow: "0 0.5px 2px 0 rgba(14,37,63,0.2)",
    marginBottom: 10,
  },
  discardButton: {
    height: 36,
  },
  buttonMargin: { marginTop: 20, marginBottom: 20 },
  submitButton: {
    height: 36,
    width: "100%",
    backgroundColor: "#1655A1",
    "&:hover": {
      backgroundColor: "#1655A1",
      opacity: 0.85,
    },
  },
  buttonLabel: {
    fontSize: 14,
    letterSpacing: "0.25px",
    lineHeight: "20px",
    textAlign: "center",
  },
  discardButtonLabel: { color: "#0D47A1" },
  submitButtonLabel: {
    color: "#F1F0F2",
  },
  bannerText: {
    margin: "25px 0",
    textAlign: "center",
    fontSize: 16,
    color: "#023059",
  },
}));

const publishScenariotoDB = async (scenarioData, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.Scenario_PostScenario,
      method: "POST",
      data: scenarioData,
      headers: { "Content-Type": "text/json" },
    },
    msalContext
  );
};

const handlePublishScenario = async (
  scenario,
  assetDetails,
  handleScenarioLoading,
  setsnackOpen,
  setSeverity,
  setMessage,
  userName,
  msalContext
) => {
  let engineOnlyAsset = false;

  if (
    assetDetails.engines.length === 1 &&
    _.isEmpty(assetDetails.airframeChecks)
  ) {
    engineOnlyAsset = true;
  }

  try {
    delete scenario.id;
    scenario.author = userName ? userName : scenario.author;
    scenario.scenarioDate = moment.utc().format("YYYY-MM-DD");
    const scenarioData = {
      aircraft: engineOnlyAsset ? null : assetDetails,
      engine: engineOnlyAsset ? assetDetails.engines[0] : null,
      scenario: scenario,
    };
    const result = await publishScenariotoDB(scenarioData, msalContext);
    if (result.status === response.OK) {
      setMessage("Successfully Published Scenario.");
      setsnackOpen(true);
      setSeverity("info");
      setTimeout(() => {
        handleScenarioLoading(false, "publish");
      }, 1500);
    } else {
      handleScenarioLoading(false);
      setMessage("Failed to Publish Scenario.");
      setsnackOpen(true);
      setSeverity("warning");
    }
  } catch (ex) {
    //console.log(ex)
    handleScenarioLoading(false);
    setMessage(`${ex}`);
    setsnackOpen(true);
    setSeverity("error");
  }
};

const Banner = ({
  bannerText,
  submitButtonLabel,
  scenario,
  assetDetails,
  handleScenarioLoading,
  setsnackOpen,
  setSeverity,
  setMessage,
  userName,
  setDismiss,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();

  if (bannerText.includes("Changes were made to the")) {
    return (
      <Grid container className={classes.root}>
        <Grid item md={8} lg={8}>
          <Box className={classes.bannerText}>
            <span>{bannerText}</span>
          </Box>
        </Grid>
        <Grid item md={2} lg={2}>
          <Box className={classes.buttonMargin} mr={2}>
            <VfButton
              label={submitButtonLabel}
              buttonStyle={classes.submitButton}
              textStyle={`${classes.buttonLabel} ${classes.submitButtonLabel}`}
              onButtonClick={() => {
                handleScenarioLoading(true);
                handlePublishScenario(
                  scenario,
                  assetDetails,
                  handleScenarioLoading,
                  setsnackOpen,
                  setSeverity,
                  setMessage,
                  userName,
                  msalContext
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item md={2} lg={2}>
          <Box className={classes.buttonMargin} ml={2}>
            <VfButton
              label={"Discard"}
              buttonStyle={classes.discardButton}
              textStyle={`${classes.buttonLabel} ${classes.discardButtonLabel}`}
              // onButtonClick={() => setDismiss}
              onButtonClick={() => window.location.reload(true)}
            />
          </Box>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.outofdatebg}>
        <Grid item md={8} lg={8}>
          <Box className={classes.bannerText}>
            <span>{bannerText}</span>
          </Box>
        </Grid>
        <Grid item md={2} lg={2}>
          <Box className={classes.buttonMargin} mr={2}>
            {/* <VfButton
              label={submitButtonLabel}
              buttonStyle={classes.submitButton}
              textStyle={`${classes.buttonLabel} ${classes.submitButtonLabel}`}
              onButtonClick={() => { handleScenarioLoading(true);
                handlePublishScenario(scenario, assetDetails, handleScenarioLoading, setsnackOpen, setSeverity, setMessage, userName)}}
            /> */}
          </Box>
        </Grid>
        <Grid item md={2} lg={2}>
          <Box className={classes.buttonMargin} ml={2}>
            <VfButton
              label={"Dismiss"}
              buttonStyle={classes.discardButton}
              textStyle={`${classes.buttonLabel} ${classes.discardButtonLabel}`}
              onButtonClick={() => window.location.reload(true)}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default Banner;
