import _ from "lodash";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export default function mapToViewModel_lgChecks(
  airframeData,
  props,
  selectedSubseries,
) {
  let selectedAirframeData = _.find(airframeData, { 'subseries': selectedSubseries });
  let data = selectedAirframeData.genericLGs.map( e => {
    return {
      position: e.position,
      intervalDay: e.interval.day,
      intervalFH: e.interval.flightHour,
      intervalFC: e.interval.flightCycle, 
      cost: e.cost.cost,
      costDate: e.cost.costDate,
      costEscRate: e.cost.escalation,
      downtime: e.interval.downtime,
    }
  })

  let columns = [
    {
      label: ["LG Position"],
      field: "position",
      caption: null,
      align: constants.LEFT,  
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, {paddingLeft: indent}, constants.TEXT_CELL),
    },
    {
      label: ["Interval Day"],
      field: "intervalDay",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Interval FH"],
      field: "intervalFH",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Interval FC"],
      field: "intervalFC",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Cost"],
      field: "cost",
      caption: ["U.S. $"],
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Cost Date"],
      field: "costDate",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.DATE_CELL),
    },
    {
      label: ["Cost Esc Rate"],
      field: "costEscRate",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) => {
        value = `${_.round(value * 100, 3).toFixed(2)}%`;
        return utils.renderCellValue(value, { paddingRight: indent }, constants.TEXT_CELL)
      },
    },
    {
      label: ["Downtime"],
      field: "downtime",
      caption: ["Days"],
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
  ];

  return {
    label: "Landing Gear Checks",
    props,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { pagination: false },
    optionsItems: {
      pagination: {
        page: 0,
        rowsPerPage: 10,
        tableName: null,
        handleChangePage: null,
        handleChangeRowsPerPage: null,
      },
    },
    span: [12, 12, 12],
    columns,
    data,
  };
}
