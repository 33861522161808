import React from "react";

const LandingGearSVG = ({
  width = 30,
  height = 30,
  viewBox = "0 0 30 30",
  color = "#023059",
  style = null,
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} style={{ ...style }}>
        <title>Landing Gear</title>
        <g 
            id="Volofin-Pages" 
            stroke="none" 
            stroke-width="1" 
            fill="none" 
            fill-rule="evenodd"
        >
            <g 
                id="Icon-/-Favorite-/-Outlined-Copy-2" 
                transform="translate(-545.000000, -393.000000)" 
            >
                <g transform="translate(545.000000, 393.000000)">
                    <g id="Group" transform="translate(4.000000, 3.000000)">
                        <path d="M14.3385643,4.4934688 C12.865955,4.4934688 11.6516982,5.72133527 11.6516982,7.21044993 L11.6516982,9.09143687 L10.9799817,9.09143687 L10.9799817,9.48330914 L10.4116062,9.48330914 L10.4116062,6.55732946 L9.79156016,6.55732946 L9.79156016,0 L8.86149112,0 L8.86149112,6.55732946 L8.16393934,6.55732946 L8.16393934,5.1988389 L6.92384729,5.1988389 C6.40714227,5.1988389 5.99377825,4.7808418 5.99377825,4.25834543 C5.99377825,3.73584906 6.40714227,3.31785196 6.92384729,3.31785196 L8.16393934,3.31785196 L8.16393934,0 L7.2338703,0 L7.2338703,2.37735849 L6.92384729,2.37735849 C6.58798902,2.37735849 6.27796601,2.45573295 6.0196135,2.61248186 L3.43608839,0 L1.73096182,0 L1.70512657,0.0261248186 L5.19288547,3.55297533 C5.08954446,3.78809869 5.03787396,4.02322206 5.03787396,4.28447025 C5.03787396,5.32946299 5.86460199,6.16545718 6.89801204,6.16545718 L7.20803505,6.16545718 L7.20803505,6.58345428 L6.61382428,6.58345428 L6.61382428,9.45718433 L6.04544875,9.45718433 L6.04544875,9.06531205 L5.37373222,9.06531205 L5.37373222,7.18432511 C5.37373222,5.69521045 4.15947542,4.46734398 2.68686611,4.46734398 L2.68686611,4.46734398 C1.2142568,4.46734398 0,5.69521045 0,7.18432511 L0,15.2830189 C0,16.7721335 1.2142568,18 2.68686611,18 C4.15947542,18 5.37373222,16.7721335 5.37373222,15.2830189 L5.37373222,13.4020319 L6.04544875,13.4020319 L6.04544875,13.0101597 L10.9541465,13.0101597 L10.9541465,13.4020319 L11.625863,13.4020319 L11.625863,15.2830189 C11.625863,16.7721335 12.8401198,18 14.3127291,18 C15.7853384,18 16.9995952,16.7721335 16.9995952,15.2830189 L16.9995952,7.18432511 C17.0254305,5.69521045 15.8111737,4.4934688 14.3385643,4.4934688 Z" 
                        id="Path" stroke="#355A79" stroke-width="0.7"></path>
                        <polygon id="Path" points="10 7 10 9 8 9 8 7"></polygon>
                        <polygon id="Path" stroke="#355A79" stroke-width="0.5" points="10.5 12 6.5 12 6.5 10 10.5 10"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default LandingGearSVG;