import * as constants from "../../../constants";
import headerMapper from "./headers/headers";
import { default as aircraftAssetSummaryViewModel } from "./assetSummary/assetSummaryViewModel_Aircraft";
import { default as engineAssetSummaryViewModel } from "./assetSummary/assetSummaryViewModel_Engine";
import { default as purchasePriceViewModel } from "./assetSummary/assetPurchasePriceViewModel";
import { default as airframeCheckViewModel } from "./airframeCheck/airframeCheckViewModel";
import { default as enginePRViewModel } from "./enginePR/enginePRViewModel";
import { default as landingGearViewModel } from "./landingGear/landingGearViewModel";
import { default as apuViewModel } from "./apu/apuViewModel";
import { default as leasesViewModel } from "./leases/leasesViewModel";
import { default as utilizationViewModel } from "./utiilization/utilizationViewModel";
import { default as scenariosViewModel } from "./scenarios/scenariosViewModel";
import { default as cashReserveAccounts } from "./common/cashReserveAccounts";
//import { default as fleetDataViewModel } from "./assetSummary/assetFleetDataViewModel";

export function mapToViewModelHeader(snapShot, componentType, idx) {
  switch (componentType) {
    case constants.GENERAL_INFO:
      return headerMapper.assetSummary(snapShot);
    case constants.AIRFRAME_CHECKS:
      return headerMapper.airframeCheck(snapShot);
    case constants.ENGINES:
      return headerMapper.engine(snapShot);
    case constants.LANDING_GEARS:
      return headerMapper.landingGear(snapShot);
    case constants.apUs:
      return headerMapper.apu(snapShot);
    case constants.LEASES:
      return headerMapper.lease(snapShot);
    case constants.EXPECTED_UTILIZATIONS:
      return headerMapper.utilization(snapShot, idx);
    default:
      break;
  }
}

export function mapToViewModel(
  data,
  componentType,
  props,
  scenario,
  additionalProps,
  airlines,
  additionalData,
  partOut,
  models
) {
  switch (componentType) {
    case constants.GENERAL_INFO:
      if (data.aircraftSeries)
        return aircraftAssetSummaryViewModel(data, props, scenario,additionalProps);
      else return engineAssetSummaryViewModel(data, props);
    case "Purchase Price":
      return purchasePriceViewModel(data, props, additionalData);
    // case "Fleet Data":{
    //   console.log(data,props,additionalData,additionalProps);
    //   return fleetDataViewModel(data,props,additionalData);
    // }
    case constants.AIRFRAME_CHECKS:
      return airframeCheckViewModel(data, props);
    case constants.ENGINES:
      return enginePRViewModel(
        data,
        props,
        additionalProps,
        airlines,
        additionalData,
        partOut
      );
    case constants.LANDING_GEARS:
      return landingGearViewModel(data, props);
    case constants.apUs:
      return apuViewModel(data, props,models);
    case constants.LEASES:
      return leasesViewModel(props, scenario, airlines, additionalProps);
    case constants.EXPECTED_UTILIZATIONS:
      return utilizationViewModel(data, props);
    case constants.SCENARIO_SUMMARIES:
      return scenariosViewModel(data, props, additionalProps);
    case constants.Cash_Reserve_Accounts:
      return cashReserveAccounts(data, props, additionalProps);
    default:
      break;
  }
}

export { default as simulateParameter } from "./simulateReport/simulationParameters";
export { default as conversionEvent } from "./simulateReport/conversionEvent";
export { default as runoutOverrides } from "./simulateReport/RunoutOverrides";
// export { default as partoutEvents } from "./leaseReport/partoutEventsViewModel";
export { default as aircraftAssetSummaryViewModel } from "./assetSummary/assetSummaryViewModel_Aircraft";
export { default as engineAssetSummaryViewModel } from "./assetSummary/assetSummaryViewModel_Engine";
export { default as airframeCheckViewModel } from "./airframeCheck/airframeCheckViewModel";
export { default as enginePRViewModel } from "./enginePR/enginePRViewModel";
export { default as landingGearViewModel } from "./landingGear/landingGearViewModel";
export { default as apuViewModel } from "./apu/apuViewModel";
export { default as leaseViewModel } from "./leases/lease/leaseViewModel";
export { default as utilizationViewModel } from "./utiilization/utilizationViewModel";

export { default as mapToComponentStatusViewModel } from "./simulateReport/assetStatus/componentStatusViewModel";
export { default as mapToAssetSummaryViewModel } from "./simulateReport/assetStatus/assetSummaryViewModel";
export { default as mapToApprasialViewModel } from "./simulateReport/assetValuation/apprasialViewModel";
export { default as mapToValuationAssumptionsViewModel } from "./simulateReport/assetValuation/valuationAssumptionsViewModel";
export { default as mapToPartOutValuesViewModel } from "./simulateReport/assetValuation/partOutValuesViewModel";
export { default as mapToViewModel_DispositionValues } from "./simulateReport/assetValuation/dispositionValuesViewModel";
export { default as mapToViewModel_EncumberedValues } from "./simulateReport/assetValuation/encumberedValuesViewModel";

export { default as assetStatusViewModel } from "./simulateReport/assetStatus/assetStatusViewModel";
export { default as assetValuationViewModel } from "./simulateReport/assetValuation/assetValuationViewModel";
export { default as leaseReportsViewModel } from "./leaseReport/leaseReportsViewModel";
export { default as mapToViewModel_SVAssumptions } from "./scenarios/scenario/svAssumptionsViewModel";
