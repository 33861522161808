import _, { add } from "lodash";
import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

export default function mapToViewModel_Apprasial(
  report,
  appraisals,
  selectedAppraisal,
  compStatus,
  runDate
) {
  //need report here
  //console.log(report);
  const appraisal = appraisals[selectedAppraisal.appraiser];

  let halfLife = null;
  let fullLife = null;
  // if (appraisal) {
  //   const futureValuations = appraisal.futureValuations;

  //   const evaluateDate1 = moment(runDate).toDate();
  //   const nextValuation = _.find(futureValuations, d => {
  //     return new Date(d.date) > evaluateDate1
  //   });
  //   const previousEvaluationDate = evaluateDate1.setFullYear(evaluateDate1.getFullYear() - 1);
  //   const currValuation = _.find(futureValuations, d => {
  //     return new Date(d.date) > previousEvaluationDate
  //   });

  //   const currValuationDate = new Date(currValuation.date);
  //   const nextValuationtDate = new Date(nextValuation.date);
  //   const evaluateDate = moment().subtract(1, "months").endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss")

  //   halfLife = _.round(
  //     (utils.linearInterpolation(
  //       currValuationDate,
  //       evaluateDate,
  //       nextValuationtDate,
  //       currValuation[`halfLife${selectedAppraisal.valueType}_inflated`],
  //       nextValuation[`halfLife${selectedAppraisal.valueType}_inflated`],
  //       "days"
  //     ) *
  //       Math.pow(10, 6))
  //   );

  //   fullLife = _.round(
  //     (utils.linearInterpolation(
  //       currValuationDate,
  //       evaluateDate,
  //       nextValuationtDate,
  //       currValuation[`fullLife${selectedAppraisal.valueType}_inflated`],
  //       nextValuation[`fullLife${selectedAppraisal.valueType}_inflated`],
  //       "days"
  //     ) *
  //       Math.pow(10, 6))
  //   );
  // }
  let newrundate = moment(runDate).endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS+00:00");
  // console.log(newrundate);
  // console.log(report.cashFlows);
  // console.log(report.cashFlows[newrundate]);
  // console.log(selectedAppraisal.valueType);
  if(selectedAppraisal.valueType == constants.MARKET){
    halfLife = report.cashFlows[newrundate].halfLifeValue_Market;
    fullLife = report.cashFlows[newrundate].fullLifeValue_Market;
  }
  if(selectedAppraisal.valueType == constants.BASE){
    halfLife = report.cashFlows[newrundate].halfLifeValue_Base;
    fullLife = report.cashFlows[newrundate].fullLifeValue_Base;
  }

  return {
    label: "Valuation",
    caption: `As of ${moment.utc(runDate).format(constants.DATE_FORMAT)}`,
    tableType: constants.DATA_TABLE,
    columns: [
      {
        label: ["HALF-LIFE"],
        field: "halfLife",
        captions: ["U.S. $"],
        span: [6, 6],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["FULL-LIFE"],
        field: "fullLife",
        captions: ["U.S. $"],
        span: [6, 6],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["LEASE RATE"],
        field: "leaseRate",
        captions: ["U.S. $"],
        span: [6, 6],
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["VALUES to H.L."],
        field: "valueAboveHalfLife",
        captions: ["U.S. $"],
        span: [6, 6],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(
            value,
            { paddingRight: 0 },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: ["ASSET VALUE"],
        field: "assetValue",
        captions: ["U.S. $"],
        span: [6, 6],
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
    ],
    data: {
      leaseRate:
        appraisal &&
        _.round(
          (appraisal[`monthlyLeaseRate_${selectedAppraisal.valueType}`] *
            Math.pow(10, 6)) 
        ),
      halfLife,
      fullLife,
      valueAboveHalfLife: _.round(
        _.sumBy(compStatus, "valueAboveHalfLife")
      ),
      assetValue:
        _.round(_.sumBy(compStatus, "valueAboveHalfLife")) + halfLife,
    },
  };
}
