import _ from "lodash";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

function generateColumns() {
  return [
    {
      label: "Lessee",
      caption: null,
      field: "lessee",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) => {
        // will be refactored later
        // value =
        //   value &&
        //   (value.includes("Copa") ? (
        //     <CopaIcon />
        //   ) : value.includes("Etihad") ? (
        //     <EtihadIcon />
        //   ) : value.includes("Spice") ? (
        //     <SpiceJetIcon />
        //   ) : (
        //     value
        //   ));
        return utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        );
      },
    },
    {
      label: "Status",
      caption: null,
      field: "leaseStatus",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingLeft: indent }),
    },
    {
      label: "Mx Reserve Type",
      caption: null,
      field: "mxReserveType",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingLeft: indent }),
    },
    {
      label: "Start Date",
      caption: null,
      field: "startDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "End Date",
      caption: null,
      field: "endDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "Monthly Rent",
      caption: "U.S. $",
      field: "monthlyRent",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: "EOL. Comp.",
      caption: "U.S. $",
      field: "eolComp",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: "RRR Cost",
      caption: "U.S. $",
      field: "rrrCost",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
  ];
}

export function mapToViewModel_leaseInfo(leases,simdate) {
  const columns = generateColumns();

  const data = leases.map((lease) => ({
    lessee: lease.lessee,
    subLessee: lease.subLessee,
    startDate: lease.startDate,
    leaseStatus: lease.leaseStatus,
    endDate: lease.endDate,
    monthlyRent: _.round(lease.monthlyRent ),
    eolComp: _.round(lease.eolComp ),
    mxReserveType: lease.mxReserveType,
    rrrCost: (typeof lease.rrrCost) !== undefined ?  _.round(lease.rrrCost ) : null,
    hidden: [],
    style: { height: 48 },
  }));

  const tableData = {
    label: constants.LEASE_REPORT,
    caption: "Simulation As Of: "+simdate,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: false, pagination: false },
    columns,
    data,
  };

  return tableData;
}
