import _ from "lodash";
import React from "react";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export default function mapToViewModel_performanceRestAssump(
  engineData,
  props,
  run,
  selectedSubseries,
) {
  let interval = null;
  let cost = null;
  let selectedEngineData = _.find(engineData, { 'subseries': selectedSubseries });

  if (run === "First Run") {
    interval = selectedEngineData.firstRunInterval
    cost = selectedEngineData.firstRunCost
  } else {
    interval = selectedEngineData.matureRunInterval
    cost = selectedEngineData.matureRunCost
  }

  return {
    label: "Performance Restoration Assumptions",
    caption: `${run}`,
    props,
    tableType: constants.DATA_TABLE,
    columns: [
      {
        label: ["Interval Flight Hour"],
        field: "intervalFH",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["Interval Flight Cycles"],
        field: "intervalFC",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["Downtime"],
        field: "downtime",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      },
      {
        label: ["Cost"],
        field: "cost",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL, "$"),
      },
      {
        label: ["Cost Date"],
        field: "costDate",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.DATE_CELL),
      },
      {
        label: ["Cost Escalation Rate"],
        field: "costEscRate",
        // captions: ["U.S. $"],
        span: [4, 4],
        indent: 0,
        content: (value) =>
          `${_.round(value * 100, 3).toFixed(2)}%`,
      },
    ],
    data: {
      intervalFH: interval.flightHour,
      intervalFC: interval.flightCycle, 
      downtime: interval.downtime,
      cost: cost.cost,
      costDate: cost.costDate,
      costEscRate: cost.escalation,
    },
  };
}
