import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { mapToViewModel } from "../../mapToViewModels/index";
import VerticalTable from "../../../Common/Tables/VerticalTable/VerticalTable";
import MultiFieldsFadeInEditingPanel from "../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel"
import VfSnackerBar from "../../../Common/UtilComponents/VfSnackerBar"
import { AddBox, Visibility, DeleteForever } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import SeniorLoanDetails from "./Components/SeniorLoanDetails";
import Collateral from "./Components/Collateral";
import Banner from "./Components/Banner";
import * as constants from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  scrollMargin: {
    paddingTop: "70px",
    marginTop: "-70px",
  } 
}));

const handleSetFootNoteData = (footnoteData, setFootNote, setOpen) => {
  setFootNote(footnoteData)
  setOpen(true)
}

const formatData = (componentType, generateEditableProps, loanSummary, setOpen, setFootNote) => {
  let props = generateEditableProps(1, componentType);

  const threeDotBtnOptions = (fieldName, footnoteData) => {
    return [
      {
        id: 0,
        icon: Visibility,
        label: constants.VIEW,
      },
      {
        id: 1,
        icon: AddBox,
        label: constants.ADD_ROW,
      },
      {
        id: 2,
        icon: AddBox,
        label: `Add FootNote`,
        handleClick: () => {
          handleSetFootNoteData(footnoteData, setFootNote, setOpen)
        }
      },
      {
        id: 3,
        icon: DeleteForever,
        label: `Delete FootNote`,
        handleClick: () => {
          props.funcs.handleRowDelete(
            props.section,
            "footNotes",
            fieldName,
            "footNotes",
          );
        }
      },
    ]
  };

  return {
    data: mapToViewModel(
      componentType,
      props,
      loanSummary,
      threeDotBtnOptions,
    ),
    componentType,
  };
};

const LoanContent = ({
  loanSummary,
  generateEditableProps,
  editing,
  editAll,
  handlePublished
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [footNote, setFootNote] = useState(null)
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  
  let seniorLoanDetailsData = formatData("seniorLoanDetails", generateEditableProps, loanSummary, setOpen, setFootNote)
  let collateralData = formatData("collateral", generateEditableProps, loanSummary, setOpen, setFootNote)
  let taxAndIndemnitiesData = formatData("taxAndIndemnities", generateEditableProps, loanSummary)
  let feesData = formatData("fees", generateEditableProps, loanSummary)
  let financialCovenantsData = formatData("financialCovenants", generateEditableProps, loanSummary)
  let prepaymentsData = formatData("prepayments", generateEditableProps, loanSummary)
  let information_ReportingCovenantsData = formatData("information_ReportingCovenants", generateEditableProps, loanSummary)
  let restrictiveCovenantsData = formatData("restrictiveCovenants", generateEditableProps, loanSummary)
  let eventsOfDefaultData = formatData("eventsOfDefault", generateEditableProps, loanSummary)
  let other_LegalData = formatData("other_Legal", generateEditableProps, loanSummary)

  return (
    <Box style={{ padding: "0px 0 40px 0" }}>
      <VfSnackerBar snackOpen={snackOpen} setsnackOpen={setsnackOpen} severity={severity} message={message} />     

      {editing && <Banner
        bannerText={"Changes were made to the Loan Summary."}
        submitButtonLabel={"Publish"} 
        loanSummary = {loanSummary}
        setsnackOpen={setsnackOpen}
        setSeverity={setSeverity}
        setMessage={setMessage}
        handlePublished={handlePublished}
        />}

      <div id="Senior Loan Details" className={classes.scrollMargin}>
        <SeniorLoanDetails data={seniorLoanDetailsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Collateral" className={classes.scrollMargin}>
        <Collateral data={collateralData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Tax and Indemnities" className={classes.scrollMargin}>
        <VerticalTable tableData={taxAndIndemnitiesData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Fees" className={classes.scrollMargin}>
        <VerticalTable tableData={feesData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Financial Covenants" className={classes.scrollMargin}>
        <VerticalTable tableData={financialCovenantsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Prepayments" className={classes.scrollMargin}>
        <VerticalTable tableData={prepaymentsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Information/Reporting Covenants" className={classes.scrollMargin}>
        <VerticalTable tableData={information_ReportingCovenantsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Restrictive Covenants" className={classes.scrollMargin}>
        <VerticalTable tableData={restrictiveCovenantsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Events of Default" className={classes.scrollMargin}>
        <VerticalTable tableData={eventsOfDefaultData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id='Other/Legal' className={classes.scrollMargin} >
        <VerticalTable tableData={other_LegalData.data} editAll={editAll} />
      </div>

      {/* <div id="Aircraft Details" className={classes.scrollMargin} >
        <VerticalTable tableData={aircraftDetailsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Loan Details" className={classes.scrollMargin}>
        <VerticalTable tableData={leaseDetailsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Maintenance Reserves / Lessor Contributions" className={classes.scrollMargin}>
        <MXReserve_LessorContrib data={mxReserve_LessorContribData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Loan Options" className={classes.scrollMargin}>
        <VerticalTable tableData={leaseOptionsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Redelivery Conditions" className={classes.scrollMargin}>
        <RedeliveryConditions data={redeliveryConditionsData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Engine Substitution" className={classes.scrollMargin}>
        <VerticalTable tableData={engineSubstitutionData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Airworthiness Directives" className={classes.scrollMargin}>
        <VerticalTable tableData={airworthinessDirectivesData.data} editAll={editAll} />
      </div>
      <br></br>
      <div id="Insurance" className={classes.scrollMargin}>
        <VerticalTable tableData={insuranceData.data} editAll={editAll} />
      </div> */}
      

      {footNote ? <MultiFieldsFadeInEditingPanel
        open={open}
        columns={[{
          label: ["Foot Notes"],
          field: "footNote",
          editable: true,
          multiline: true,
          numRows: 3,
          required: false,
          type: constants.STRING,
          inputFieldType: constants.TEXT_FIELD,
        }]}
        data={{
          footNote: footNote.footNotes,
        }}
        tableName={footNote.tableName}
        props={footNote.props}
        onClose={() => setOpen(false)}
        /> : <></>}
    </Box>
  );
};

export default LoanContent;
