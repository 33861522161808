import _ from "lodash";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export default function mapToViewModel_acapuCombos(sortACAPUCombos, props, styles, airframeItems, apuItems) {
  return {
    label: "Aircraft APU Combinations",
    caption: null,
    props,
    isDeletable: (rowData) => {
      return false;
    },
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: "Aircraft APU Combinations",
    errorsOnSubmit: {},
    columns: [
      {
        title: "Aircraft Subseries",
        field: "aircraftSubseries",
        cellStyle: styles.cellStyle,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items: airframeItems,
        initialEditValue: airframeItems[0].value,
        required: true,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "APU Model",
        field: "apuModel",
        cellStyle: styles.cellStyle,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items: apuItems,
        initialEditValue: apuItems[0].value,
        required: true,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
    ],
    data: sortACAPUCombos.map((e, idx) => ({
        idx,
        aircraftSubseries: e.aircraftSubseries,
        apuModel: e.apuModel,
        genericAircraftId: e.genericAircraftId,
        genericApuId: e.genericApuId,
      })
    ),
  };
}
