import _ from "lodash";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const getTemplate = () => {
  return {
    isFirstRun: false,
    sinceNew: {
      flightHour: null,
      flightCycle: null,
      day: null,
      activeHour: null,
    },
    sinceEvent: {
      flightHour: null,
      flightCycle: null,
      day: null,
      activeHour: null,
    },
    statusAsOf: null,
  };
};

const uploadStatus = async (compType, compId, newdata, msalContext) => {
  let apiendpoint = apiRoutes.PostMaintenanceStatus;
  let userName = msalContext.accounts[0].name;
  apiendpoint = apiendpoint({ compType, compId, userName });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newdata,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    newdata = resp.data;
  }
};

export const updateData = (actionType, assetDetails, newData, msalContext) => {
  assetDetails.engines = [...assetDetails.engines];
  const idx = _.findIndex(
    assetDetails.engines,
    (o) => o.serialNumber === newData.serialNumber
  );
  let engine = { ...assetDetails.engines[idx] };

  if (newData.eventName === "EnginePR") {
    let maintenanceStatus = [...engine.maintenanceStatus];
    let shopVisitHistory = _.sortBy(engine.shopVisitHistory, [
      "startDate",
    ]).slice(-1)[0];

    const targetIdx = newData.idx;
    if (actionType === constants.DELETE_ROW) {
      maintenanceStatus = maintenanceStatus.filter(
        (o, idx) => targetIdx !== idx
      );
    } else {
      const newMaintenanceStatus = getTemplate();

      if (shopVisitHistory && shopVisitHistory.isOverhaul) {
        let flightHours_sinceEvent =
          parseInt(newData.flightHours_sinceNew) -
          shopVisitHistory.sinceNew.flightHour;
        let flightCycles_sinceEvent =
          parseInt(newData.flightCycles_sinceNew) -
          shopVisitHistory.sinceNew.flightCycle;
        let days_sinceEvent =
          parseInt(newData.days_sinceNew) - shopVisitHistory.sinceNew.day;
        let activeHours_sinceEvent =
          parseInt(newData.activeHours_sinceNew) -
          shopVisitHistory.sinceNew.activeHour;

        newMaintenanceStatus.sinceEvent = {
          flightHour: newData.flightHours_sinceEvent
            ? parseInt(newData.flightHours_sinceEvent)
            : flightHours_sinceEvent,
          flightCycle: newData.flightCycles_sinceEvent
            ? parseInt(newData.flightCycles_sinceEvent)
            : flightCycles_sinceEvent,
          day: newData.days_sinceEvent
            ? parseInt(newData.days_sinceEvent)
            : days_sinceEvent,
          activeHour: newData.activeHours_sinceEvent
            ? newData.activeHours_sinceEvent
            : activeHours_sinceEvent,
        };

        newMaintenanceStatus.isFirstRun = false;
      } else {
        newMaintenanceStatus.sinceEvent = {
          flightHour:
            newData.flightHours_sinceEvent &&
            parseInt(newData.flightHours_sinceEvent),
          flightCycle:
            newData.flightCycles_sinceEvent &&
            parseInt(newData.flightCycles_sinceEvent),
          day: newData.days_sinceEvent && parseInt(newData.days_sinceEvent),
          activeHour: newData.activeHours_sinceEvent,
        };

        newMaintenanceStatus.isFirstRun = newData.isFirstRun === constants.YES;
      }

      newMaintenanceStatus.sinceNew = {
        flightHour:
          newData.flightHours_sinceNew &&
          parseInt(newData.flightHours_sinceNew),
        flightCycle:
          newData.flightCycles_sinceNew &&
          parseInt(newData.flightCycles_sinceNew),
        day: newData.days_sinceNew && parseInt(newData.days_sinceNew),
        activeHour: newData.activeHours_sinceNew,
      };

      newMaintenanceStatus.statusAsOf = newData.asOfDate;
      if (actionType === constants.ADD_ROW)
        maintenanceStatus.push(newMaintenanceStatus);
      else {
        maintenanceStatus[targetIdx] = { ...maintenanceStatus[targetIdx] };
        maintenanceStatus[targetIdx] = newMaintenanceStatus;
      }
    }

    uploadStatus(
      engine.componentType,
      engine.componentId,
      maintenanceStatus,
      msalContext
    );
    engine.maintenanceStatus = maintenanceStatus;
    assetDetails.engines[idx] = engine;
  } else {
    let eventIdx = engine.supplementalEvents.findIndex(
      (x) => x.eventName === newData.eventName
    );
    let event = engine.supplementalEvents[eventIdx];
    let maintenanceStatus = [
      ...engine.supplementalEvents[eventIdx].maintenanceStatus,
    ];
    const targetIdx = newData.idx;
    if (actionType === constants.DELETE_ROW) {
      maintenanceStatus = maintenanceStatus.filter(
        (o, idx) => targetIdx !== idx
      );
    } else {
      const newMaintenanceStatus = getTemplate();
      newMaintenanceStatus.sinceEvent = {
        flightHour:
          newData.flightHours_sinceEvent &&
          parseInt(newData.flightHours_sinceEvent),
        flightCycle:
          newData.flightCycles_sinceEvent &&
          parseInt(newData.flightCycles_sinceEvent),
        day:
          newData.months_sinceEvent &&
          parseInt(_.round(newData.months_sinceEvent * 30.44)),
        activeHour: newData.activeHours_sinceEvent,
      };
      newMaintenanceStatus.sinceNew = {
        flightHour:
          newData.flightHours_sinceNew &&
          parseInt(newData.flightHours_sinceNew),
        flightCycle:
          newData.flightCycles_sinceNew &&
          parseInt(newData.flightCycles_sinceNew),
        day:
          newData.months_sinceNew &&
          parseInt(_.round(newData.months_sinceNew * 30.44)),
        activeHour: newData.activeHours_sinceNew,
      };
      newMaintenanceStatus.isFirstRun = newData.isFirstRun === constants.YES;
      newMaintenanceStatus.statusAsOf = newData.asOfDate;
      if (actionType === constants.ADD_ROW)
        maintenanceStatus.push(newMaintenanceStatus);
      else {
        maintenanceStatus[targetIdx] = { ...maintenanceStatus[targetIdx] };
        maintenanceStatus[targetIdx] = newMaintenanceStatus;
      }
    }
    uploadStatus(event.componentType, event.componentId, maintenanceStatus,msalContext);
    event.maintenanceStatus = maintenanceStatus;
    assetDetails.engines[idx] = engine;
  }

  return assetDetails;
};
