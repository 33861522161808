import _ from "lodash";
import moment from "moment";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as constants from "../../../constants";
import Errors from "../../Common/Errors";
import NewsDateSvg from "../../Common/SvgComponents/NewsDateSvg";

const getNewsArticles = async () => {
  let response = await axios({
    baseURL:
      "https://volofinnews.azurewebsites.net/api/volofinNews?code=xLzg5ElF4Ow/klYgZwcOrFjKsVTLxp6C6KWaVd1rmkA9auGRrYDiIg==&&",
    method: "GET",
    params: {
      name: "volofin",
    },
  });

  return response;
};

const boldString = (str) => {
  var newStr = str.replaceAll("*", "");

  const strMapObj = {
    "Volofin Capital Management": "<b>Volofin Capital Management</b>",
    "volofin Capital Management": "<b>volofin Capital Management</b>",
    volofin: "<b>volofin</b>",
    Volofin: "<b>Volofin</b>",
  };

  return {
    __html: newStr.replace(
      /\b(?:Volofin Capital Management|volofin Capital Management|volofin|Volofin)\b/gi,
      (matched) => strMapObj[matched]
    ),
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    height: 250,
    overflow: "auto",
    margin: "8px 24px 24px 24px",
  },
}));

const VolofinNews = ({}) => {
  const classes = useStyles();
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    getNewsArticles()
      .then(function (response) {
        setNewsData(response.data.entries);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return _.isEmpty(newsData) ? (
    <Box>
      <Errors msg={"There are currently no new articles for this airline."} />
    </Box>
  ) : (
    <Box className={classes.root}>
      {newsData.map((news, idx) => (
        <Box
          key={idx}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "0 0 4px 0",
          }}>
          <Box>
            <NewsDateSvg
              Date={moment(news.date)
                .format(constants.DATE_FORMAT)
                .toUpperCase()}
            />
          </Box>
          <Box style={{ padding: "2px 0 0 16px", color: "#023059" }}>
            <div dangerouslySetInnerHTML={boldString(news.article)} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default VolofinNews;
