import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Box, Grid, Tooltip, Button } from "@material-ui/core";
import DataTable from "../../Common/Tables/DataTable/DataTable";
import MultiFunctionTable from "../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import * as constants from "../../../constants";
import { mapToViewModel } from "../mapToViewModels/index"

const formatData = (genericData, generateEditableProps, selectedSubseries, handleSelectChange) => {
  const componentType = constants.Airframe;
  let props = generateEditableProps(null, componentType);
  return {
    data: mapToViewModel(
      genericData,
      componentType,
      handleSelectChange,
      props,
      selectedSubseries
    ),
    componentType,
  };
};

const GenericAirframe = ({ genericData, generateEditableProps, urlValues }) => {
  const sortedAirframeData = _.sortBy(genericData.airframes, [function(o) { return o.subseries; }]);
  const [selectedSubseries, setSelectedSubseries] = useState(sortedAirframeData[0].subseries)
  
  useEffect(() => {
    if (urlValues.afSelect) setSelectedSubseries( decodeURI(urlValues.afSelect) )
  }, [urlValues.afSelect]);

  const handleSelectChange = (type, value) => {
    let newUrl = `?tab=0&afSelect=${value}`
    window.history.pushState({}, null, newUrl);
    setSelectedSubseries(value)
  }
  
  const airframeData = formatData(sortedAirframeData, generateEditableProps, selectedSubseries, handleSelectChange);


  return (
    <Box> 
      {/* <>
        <input
          type="file"
          accept=".xlsm, .xlsx"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) this.uploadAirframeFile(file);
          }}
          hidden
          id="upload-btn"
          />
        
        <Tooltip title="Upload Generic Airframe Info" aria-label="Upload Assumptions">
          <Button
            style={{ maxHeight: 28, marginLeft: 16 }}
            variant="outlined"
            color="primary"
          >
            <label htmlFor="upload-btn" style={{width: "100%", height: "100%"}}>
              <span style={{ margin: "0px 8px 0px 8px" }}>Upload</span>
            </label>
          </Button>
        </Tooltip>
      </> */}
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <DataTable tableData={airframeData.data.airframeDetails} />
        </Grid>
        <Grid item sm={12}>
          <MultiFunctionTable tableData={airframeData.data.airframeChecks} />
        </Grid>
        <Grid item sm={12}>
          <MultiFunctionTable tableData={airframeData.data.lgChecks} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenericAirframe;
