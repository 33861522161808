export const updateData = (scenario, sectionIdx, newData) => {
  scenario.leases = [...scenario.leases];
  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
    lessee: newData.lessee,
    subLessee: newData.subLessee,
    leaseStatus: newData.leaseStatus,
    leaseType: newData.leaseType,
    sponsor: newData.sponsor,
    // securityDeposit_Cash : newData.securityDeposit_Cash,
    // securityDeposit_LC : newData.securityDeposit_LC,
    leaseStartDate: newData.leaseStartDate,
    leaseEndDate: newData.leaseEndDate,
  };
  return scenario;
};
