import _ from "lodash";
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
} from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Assets from "../Assets/Assets";
import DealSummary from "./DealSummary/DealSummary";
import MultiFieldsFadeInEditingPanel from "../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import ThreeDotButton from "../../../Common/ThreeDotButton";
import VfAlertDialogBox from "../../../Common/UtilComponents/VfAlertDialogBox";
import VfSnackerBar from "../../../Common/UtilComponents/VfSnackerBar";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import ApiRoutes from "../../../../utils/ApiRoutes";
import { useMsal } from "@azure/msal-react";
import { StaticDataContext } from "../../../../context/StaticDataContext";
import { mapToViewModel_deal } from "../../../../Pages/Deals/mapToViewModels/dealViewModel";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export const useStyles = makeStyles({
  panelSummary: {
    maxHeight: 59,
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    "&:hover": {
      background: "rgba(22,85,161,0.05) !important",
    },
    minWidth: 1100,
  },
  panelDetails: {
    padding: "20px 24px 24px 16px",
    backgroundColor: "#F4F5F8",
  },

  modal: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const updateDealinDB = async (data, userName, msalContext) => {
  let dealid = data.id;

  return apiRequest(
    {
      url: ApiRoutes.VectorDeal_DeleteUpdateDeals({ dealid, user: userName }),
      method: "PUT",
      data: data,
      headers: { "Content-Type": "text/json" },
    },
    msalContext
  );
};

const deleteDealfromDB = async (dealid, userName, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.VectorDeal_DeleteUpdateDeals({ dealid, user: userName }),
      method: "DELETE",
      headers: { "Content-Type": "text/json" },
    },
    msalContext
  );
};
const deleteDealLink = async (dealid, userName, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.VectorDeal_RemovePipelineLink({ dealid, user: userName }),
      method: "POST",
      headers: { "Content-Type": "text/json" },
    },
    msalContext
  );
};

const Deal = ({
  dealId,
  deal,
  dealStages,
  airlineLogos,
  liabilityData,
  expectedLossRateData,
  userName,
  pipelineData
}) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const { sponsorsData } = useContext(StaticDataContext);
  const checkedExpanded = utils.sessionGetDataHelper(`deal_panel${dealId}`);
  const [open, setOpen] = useState(false);
  const [isAlertDialogOpen, setisAlertDialogOpen] = useState(false);
  const [isAlertDeleteOpen, setisAlertDeleteOpen] = useState(false);
  const [expanded, setExpanded] = useState(
    checkedExpanded ? checkedExpanded : false
  );
  const [poolName, setpoolName] = useState("None");
  const [showOption, setShowOption] = useState(checkedExpanded ? true : false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const delay = 1500;

  const handleDealDelete = async () => {
    try {
      const result = await deleteDealfromDB(dealId, userName, msalContext);
      if (result.status === response.OK) {
        setMessage("Successfully deleted Deal.");
        setsnackOpen(true);
        setSeverity("info");
        setTimeout(() => {
          window.location.reload(true);
        }, delay);
      } else {
        setMessage("Failed to delete Deal.");
        setsnackOpen(true);
        setSeverity("warning");
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleDealRemoveLink = async () =>{
    try {
      const result = await deleteDealLink(dealId, userName, msalContext);
      if (result.status === response.OK) {
        setMessage("Successfully Updated Deal.");
        setsnackOpen(true);
        setSeverity("info");
        setTimeout(() => {
          window.location.reload(true);
        }, delay);
      } else {
        setMessage("Failed to delete Deal Link.");
        setsnackOpen(true);
        setSeverity("warning");
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleDealUpdate = async (values) => {
    try {
      values["id"] = values["dealId"];
      const newValues = _.pick(values, [
        "id",
        "dealName",
        "operator",
        "sponsor",
        "stage",
        "facilityTrancheName",
        "trancheSeniority",
        "recourse",
        "globalId"
      ]);

      const result = await updateDealinDB(newValues, userName, msalContext);
      if (result.status === response.PUT_OK) {
        setMessage("Successfully Edited Deal.");
        setsnackOpen(true);
        setSeverity("info");
        setTimeout(() => {
          window.location.reload(true);
        }, delay);
      } else {
        setMessage("Failed to Edit Deal.");
        setsnackOpen(true);
        setSeverity("warning");
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    utils.sessionStoreHelper(`deal_${panel}`, isExpanded ? panel : false, 48);
    if (!showOption) {
      setShowOption(true);
    } else {
      setShowOption(false);
    }
  };

  const bgUnexpanded = "#FFFFFF";
  const bgExpanded = "rgba(22,85,161,0.05)";
  const props = { funcs: { handleDealDelete, handleDealUpdate } };
  const dealModel = mapToViewModel_deal(dealStages, deal, sponsorsData, pipelineData);
  const threeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
      handleClick: () => setOpen(true),
    },
    {
      id: 1,
      icon: DeleteOutline,
      label: constants.DELETE,
      handleClick: () => setisAlertDialogOpen(true),
    },
  ];
  if(deal.editableDeal == false)
  {
    threeDotBtnOptions.push({
      id: 2,
      icon: DeleteOutline,
      label: "Delete PL Link",
      handleClick: () => setisAlertDeleteOpen(true),
    })
  }

  return (
    <Box>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        message={message}
      />
      <VfAlertDialogBox
        isOpen={isAlertDialogOpen}
        handleAlertDialogVisible={setisAlertDialogOpen}
        dialogTitle={"Confirm Delete Deal"}
        dialogBody={"Are you sure you would like to delete this deal?"}
        onAccept={handleDealDelete}
      />
        <VfAlertDialogBox
        isOpen={isAlertDeleteOpen}
        handleAlertDialogVisible={setisAlertDeleteOpen}
        dialogTitle={"Confirm Remove Pipeline Link"}
        dialogBody={"Are you sure you would like to Remove this Deal's Pipeline Link?"}
        onAccept={handleDealRemoveLink}
      />

      <Box flexGrow={1} mb={1}>
        <Box style={{ maxWidth: "100%" }}>
          <ExpansionPanel
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel${dealId}`}
            onChange={handleChange(`panel${dealId}`)}
            elevation={0}>
            <ExpansionPanelSummary
              aria-controls={`panel${dealId}bh-content`}
              id={`panel${dealId}bh-header`}
              className={classes.panelSummary}
              style={{
                background:
                  expanded === `panel${dealId}` ? bgExpanded : bgUnexpanded,
              }}>
              <DealSummary
                deal={deal}
                dealId={dealId}
                airlineLogos={airlineLogos}
                liabilityData={liabilityData}
                expectedLossRateData={expectedLossRateData}
              />
              <Box style={{ zIndex: 200, width: 20 }}>
                <FormControlLabel
                  style={{ margin: "12px 0 0 0", width: 40 }}
                  aria-label='threedotbutton'
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    showOption ? (
                      <ThreeDotButton
                        fontSize={"small"}
                        // padding={20}
                        options={threeDotBtnOptions}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </Box>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
              classes={{ root: classes.panelDetailsRoot }}
              className={classes.panelDetails}>
              <Assets
                user={userName}
                deal={deal}
                dealAssets={deal.assets}
                // allAssets={allAssets}
                airlineLogos={airlineLogos}
                threeDotBtnOptions={threeDotBtnOptions}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>

        <MultiFieldsFadeInEditingPanel
          open={open}
          columns={dealModel.columns}
          data={dealModel.data}
          props={props}
          onClose={() => setOpen(false)}
          tableName={"Edit Deal"}
        />
      </Box>
    </Box>
  );
};

Deal.propTypes = {
  deal: PropTypes.object,
  dealId: PropTypes.number,
  dealStages: PropTypes.array,
};

export default Deal;
