import _ from "lodash";

const getReserveAccountBalance = (
  componentData,
  data,
  componentKey,
  LLP = false
) => {
  data.forEach((element) => {
    let component = null;
    let value = null;

    if (element.componentType === "LandingGear")
      component = `LG ${element[componentKey]}`;
    else if (LLP) component = `LLP Stack ${element[componentKey]}`;
    else component = element[componentKey];
    //engine?
    if (component.includes("Engine Position") && !LLP) {
      //check supplemental events

      if (element.supplementalEvents) {
        element.supplementalEvents.forEach((se) => {
          //console.log(se);
          let newcomponent = component + " SupplementalEvent " + se.eventName;
          if (se.reserveAccount || !_.isEmpty(se.reserveAccount)) {
            componentData.dates = se.reserveAccount.cashBalanceByDate.map(
              (data) => data.date
            );
            value = se.reserveAccount.cashBalanceByDate.map(
              (data) => data.balance
            );
            componentData[newcomponent] = {
              value: value,
              serialNumber: element.serialNumber,
            };
          } else {
            componentData[newcomponent] = {
              value: null,
              serialNumber: element.serialNumber,
            };
          }
        });
      }
    }

    if (element.reserveAccount || !_.isEmpty(element.reserveAccount)) {
      componentData.dates = element.reserveAccount.cashBalanceByDate.map(
        (data) => data.date
      );
      if (LLP)
        value = element.llpStack.reserveAccount.cashBalanceByDate.map(
          (data) => data.balance
        );
      else
        value = element.reserveAccount.cashBalanceByDate.map(
          (data) => data.balance
        );
      componentData[component] = {
        value: value,
        serialNumber: element.serialNumber,
      };
    } else {
      componentData[component] = {
        value: null,
        serialNumber: element.serialNumber,
      };
    }
  });
};

export default function getComponentCashReserveAccData(assetDetails) {
  var componentData = {};
  // let items = _.flattenDeep(Object.values(props.allShopVisitEventNames));

  if (assetDetails.airframeChecks) {
    getReserveAccountBalance(
      componentData,
      assetDetails.airframeChecks,
      "checkName"
    );
    getReserveAccountBalance(
      componentData,
      assetDetails.landingGears,
      "position"
    );
  }
  if (assetDetails.apUs) {
    getReserveAccountBalance(componentData, assetDetails.apUs, "componentType");
  }
  if (assetDetails.engines) {
    getReserveAccountBalance(componentData, assetDetails.engines, "position");
    getReserveAccountBalance(
      componentData,
      assetDetails.engines,
      "position",
      true
    );
  }

  return componentData;
}
