import moment from "moment";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const uploadMxEvent = async (compType, compId, newdata, msalContext) => {
  let apiendpoint = apiRoutes.PostMaintenanceEvents;
  apiendpoint = apiendpoint({ compType, compId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newdata,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    newdata = resp.data;
  }
};

export const updateData = (
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  msalContext
) => {
  tableName =
    tableName === constants.scheduledShopVisit
      ? constants.maintenanceEvents
      : tableName;
  assetDetails[sectionName] = [...assetDetails[sectionName]];
  assetDetails[sectionName][sectionIdx] = {
    ...assetDetails[sectionName][sectionIdx],
  };

  newData.endDate = moment
    .utc(newData.startDate)
    .add(parseInt(newData.daysDown), "days")
    .endOf("day")
    .toISOString();

  let table = [...assetDetails[sectionName][sectionIdx][tableName]];
  let comp = assetDetails[sectionName][sectionIdx];
  if (
    newData.event.includes("LLP") &&
    tableName === constants.maintenanceEvents
  ) {
    table = [...assetDetails[sectionName][sectionIdx].llpStack[tableName]];
  }
  //EPR
  else if ( (newData.event === "Engine Position 1" || newData.event === "Engine Position 2" || newData.event === "Engine Position 3"|| newData.event === "Engine Position 4" || newData.event.includes("EnginePR"))
      && tableName === constants.maintenanceEvents){
        table = [...assetDetails[sectionName][sectionIdx][tableName]];
  }
  // Supplemental Events
  else if (tableName === constants.maintenanceEvents && sectionName === "engines") {
    let ses = assetDetails[sectionName][sectionIdx].supplementalEvents;
    let removeme = "";
    if(newData.event.includes(" Engine Position "))
      removeme = newData.event.indexOf(" Engine Position ");
    else
      removeme = newData.event.length-3;
    let sub = newData.event.substring(0,removeme);
    let supp = ses.find( o =>
      o.eventName === sub
    );
    if(supp != null)
      table = supp[tableName];
  }

  if (actionType === constants.ADD_ROW) {
    table.push(newData);
  } else if (actionType === constants.UPDATE_ROW) {
    table[newData.id] = { ...table[newData.id] };
    table[newData.id] = newData;
  } else {
    table = table.filter((row, idx) => idx !== newData.id);
  }
  if (
    newData.event.includes("LLP") &&
    tableName === constants.maintenanceEvents
  ) {
    uploadMxEvent(
      "EngineLLP",
      assetDetails[sectionName][sectionIdx].llpStack.localId,
      table,
      msalContext
    );
    assetDetails[sectionName][sectionIdx].llpStack[tableName] = table;
  } else if ( (newData.event === "Engine Position 1" || newData.event === "Engine Position 2" || newData.event === "Engine Position 3"|| newData.event === "Engine Position 4" || newData.event.includes("EnginePR"))
      && tableName === constants.maintenanceEvents) {
    uploadMxEvent(
      assetDetails[sectionName][sectionIdx].componentType,
      assetDetails[sectionName][sectionIdx].componentId,
      table,
      msalContext
    );
    assetDetails[sectionName][sectionIdx][tableName] = table;
  }
  else if (tableName === constants.maintenanceEvents && sectionName === "engines") {
    let ses = assetDetails[sectionName][sectionIdx].supplementalEvents;
    let removeme = "";
    if(newData.event.includes(" Engine Position "))
      removeme = newData.event.indexOf(" Engine Position ");
    else
      removeme = newData.event.length-3;
    let sub = newData.event.substring(0,removeme);
    let supp = ses.find( o =>
      o.eventName === sub
    );    
    uploadMxEvent(
      "EngineSupp",
      supp.componentId,
      table,
      msalContext
    );
    supp[tableName] = table;
  }
  else{
    uploadMxEvent(
      assetDetails[sectionName][sectionIdx].componentType,
      assetDetails[sectionName][sectionIdx].componentId,
      table,
      msalContext
    );
    assetDetails[sectionName][sectionIdx][tableName] = table;
  }

  return assetDetails;
};
