import _ from "lodash";
import moment from "moment";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";
import { Visibility } from "@material-ui/icons";

export const styles = {
  cellStyle: {
    color: "#023059",
    textAlign: "center",
    fontSize: 11,
    height: 40,
    padding: 0,
    borderBottom: "unset",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
};

export default function mapToViewModel_strvaluecolrating(
  deal,
  strv_colr_data,
  props
) {
  let strv_colr_assets =
    deal.dealId in strv_colr_data ? strv_colr_data[deal.dealId] : null;
  let data = deal.assets.map((x, i) => {
    let strv_colr = _.find(
      strv_colr_assets,
      (y) => y.serialNumber === x.serialNumber
    );
    return {
      id: i,
      dealId: deal.dealId,
      assetId: x.id,
      operator: x.operator,
      registration: x.registration,
      serialNumber: x.serialNumber,
      subseries: x.subseries,
      collateralRating: strv_colr ? strv_colr.collateralRating : null,
      strategicValue: strv_colr ? strv_colr.strategicValue : null,
    };
  });

  return {
    label: deal.dealName,
    caption: null,
    props,
    showOptions: true,
    sorting: false,
    isDeletable: () => false,
    tableType: constants.EDITABLE_TABLE,
    threeDotBtnOptions: [
      {
        id: 0,
        icon: Visibility,
        label: constants.VIEW,
      },
    ],
    tableName: deal.dealName,
    style: { height: "100%" },
    errorsOnSubmit: {},
    columns: [
      {
        title: "Operator",
        field: "operator",
        type: constants.STRING,
        cellStyle: styles.cellStyle,
        editable: constants.NEVER,
        required: true,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      // {
      //   title: "Registration",
      //   field: "registration",
      //   type: constants.STRING,
      //   cellStyle: styles.cellStyle,
      //   editable: constants.NEVER,
      //   required: true,
      //   sorting: false,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      // },
      {
        title: "Serial Number",
        field: "serialNumber",
        type: constants.STRING,
        cellStyle: styles.cellStyle,
        editable: constants.NEVER,
        required: true,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Subseries",
        field: "subseries",
        type: constants.STRING,
        cellStyle: styles.cellStyle,
        editable: constants.NEVER,
        required: true,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Collateral Rating",
        field: "collateralRating",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        cellStyle: styles.cellStyle,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // render: (rowData) =>
        //   utils.renderCellValue(
        //     rowData.collateralRating,
        //     null,
        //     constants.NUMERIC_CELL
        //   ),
      },
      {
        title: "Strategic Value",
        field: "strategicValue",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        cellStyle: styles.cellStyle,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // render: (rowData) =>
        //   utils.renderCellValue(
        //     rowData.strategicValue,
        //     null,
        //     constants.NUMERIC_CELL
        //   ),
      },
    ],
    data: data,
  };
}
