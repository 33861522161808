import * as constants from "../../../constants";

export function mapToViewModelHeader(componentType) {
  switch (componentType) {
    case constants.FLEET_SUMMARY:
      return [
        {
          label: constants.FLEET_SUMMARY,
          caption: null,
          span: [12, 12, 12],
        },
      ];
    case constants.LEASE_SUMMARY:
      return [
        {
          label: constants.LEASE_SUMMARY,
          caption: null,
          span: [12, 12, 12],
        },
      ];
    default:
      break;
  }
}

export { default as mapToFleetInfoViewModel } from "./portfolioReport/fleetSummary/fleetInfoViewModel";
export { default as mapToLeaseInfoViewModel } from "./portfolioReport/leaseSummary/leaseInfoViewModel";

export { default as fleetSummaryViewModel } from "./portfolioReport/fleetSummary/fleetSummaryViewModel";
export { default as leaseSummaryViewModel } from "./portfolioReport/leaseSummary/leaseSummaryViewModel";
export { default as liabilityCashFlows } from "./liabilityCashflow";
export { default as liabilityLoans } from "./liabilityLoans";
export { default as editLiabilityLoans } from "./editLiabilityLoans";
export { default as expectedLossTranches } from "./expectedLossTranches";
export { default as cashflowHistory } from "./cashflowHistory";
