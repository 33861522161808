import React from "react";
import { Box } from "@material-ui/core";
import * as constants from "../../../../constants";
import Scenario from "./Components/Scenario/Scenario";
import Asset from "./Components/Asset/Asset";

const renderSection = (
  report,
  errors,
  assetDetails,
  generateEditableProps,
  scenario,
  selected,
  handleValueChange,
  handleScenarioChangeEvt,
  currentState,
  handleScenarioLoading,
  userName
) => {
  switch (selected.label.toUpperCase()) {
  
    case constants.Airframe.toUpperCase():
    case constants.Engine.toUpperCase():
    case constants.Engines.toUpperCase():
    case constants.LG.toUpperCase():
    case constants.APU.toUpperCase():
    case constants.APPRAISAL.toUpperCase():
    case "Summary".toUpperCase():
      return (
        <Asset
          assetDetails={assetDetails}
          selected={selected}
          scenario={scenario}
          generateEditableProps={generateEditableProps}
          errors={errors}
          currentState={currentState}
        />
      );
    default:
      return (
        <Scenario
          selected={selected}
          report={report}
          errors={errors}
          assetDetails={assetDetails}
          generateEditableProps={generateEditableProps}
          scenario={scenario}
          handleValueChange={handleValueChange}
          handleScenarioChangeEvt={handleScenarioChangeEvt}
          currentState={currentState}
          handleScenarioLoading={handleScenarioLoading}
          userName={userName}
        />
      );
  }
};

const MainContent = ({
  assetDetails,
  errors,
  report,
  selected,
  scenario,
  generateEditableProps,
  handleValueChange,
  handleScenarioChangeEvt,
  currentState,
  handleScenarioLoading,
  userName,
}) => {
  return (
    <Box>
      {renderSection(
        report,
        errors,
        assetDetails,
        generateEditableProps,
        scenario,
        selected,
        handleValueChange,
        handleScenarioChangeEvt,
        currentState,
        handleScenarioLoading,
        userName
      )}
      <br />
    </Box>
  );
};

export default MainContent;
