import _ from "lodash";
import * as utils from "../../../../../utils/utils";
import * as constants from "../../../../../constants";
import moment from "moment";

export function mapToViewModel_airframeDetails(data, props, additionalProps) {
  //console.log(additionalProps);
  let cols = [
    {
      label: ["MODEL"],
      field: "aircraftSubSeries",
      captions: [],
      span: [3, 3],
      editable: false,
      required: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["SERIAL NUMBER"],
      field: "serialNumber",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["MANUFACTURER"],
      field: "manufacturer",
      captions: [],
      span: [3, 3],
      editable: false,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Date of Manufacture"],
      field: "aircraftDOM",
      captions: [],
      span: [3, 3],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      initialEditValue: moment.utc().format(constants.ISOFormat),
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        moment.utc(value).format(constants.DATE_FORMAT),
    },
  ];
  // check if you can add any airframe checks
  if(additionalProps != null && additionalProps.AFCs != null){
    let existingafcs = data.airframeChecks.map(x=> {return x.genericID});
    let afclist = additionalProps.AFCs.filter(x=> !existingafcs.includes(x.id));
    if (afclist.length >= 1)
      cols.push(
        {
          label: ["Add Airframe Check"],
          field: "newafc",
          captions: [],
          span: [3, 3],
          editable: true,
          required: false,
          hidden: true,
          items: afclist.map((afc)=> {
            return {
              label: afc.checkName,
              value: afc.id
            }
          }),
          //value:  data.engineSpecificationAdjusterDescriptions,
          //initialEditValue: { label:"", value: "" },
          type: constants.AUTO_COMPLETE,
          inputFieldType: constants.AUTO_COMPLETE,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required)
        },
      )
  }
  // check if you can add any engines
  if(additionalProps != null && additionalProps.Engs != null){
    //console.log(data);
    let usedeng = data.engines.map(x=> {return x.id});
    let englist = additionalProps.Engs.filter(x=> !usedeng.includes(x.id));
    let usedpos = data.engines.map(x=> {return x.position[x.position.length-1]});
    if(englist.length >= 1 && data.engines.length < englist[0].numOfEngs){
      let numOfEngs = englist[0].numOfEngs;
      englist = englist.map(x=> {
        if(x.aircraftId != null){
          return {
            label:x.subseries+" - "+x.serialNumber+" ON MSN "+ x.aircraftMSN,
            value: x.id,
            disabled: true
          }
        }
        else{
          return {
            label: x.id+" - "+x.subseries+" - "+x.serialNumber,
            value: x.id,
            disabled: false
          }
        }
      }); //endmap 
      //console.log(englist);
      englist = englist.sort((a,b)=> { 
        if(a.disabled)
          return 1
        if(b.disabled)
          return -1
      });
      for(let i = 1; i<= numOfEngs; i++)
      {
        if(usedpos.indexOf(i.toString()) != -1){
          continue;
        }
        cols.push(
          {
            label: ["Add Engine Position "+i],
            field: "neweng"+i,
            captions: [],
            span: [3, 3],
            editable: true,
            required: false,
            hidden: true,
            items: englist.map((eng)=> {
              return {
                label: eng.label,
                value: eng.value,
                disabled: eng.disabled
              }
            }),
            //value:  data.engineSpecificationAdjusterDescriptions,
            //initialEditValue: { label:"", value: "" },
            type: constants.AUTO_COMPLETE,
            inputFieldType: constants.AUTO_COMPLETE,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required)
          },
        )
      }
    }
  }

  return {
    label: "Airframe Details",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    // caption: `DOM: ${moment.utc(data.manufactureDate).format(constants.DATE_FORMAT)}`,
    tableType: constants.DATA_TABLE,
    tableName: "Airframe Details",
    columns: cols,
    data: {
      aircraftSubSeries: data.aircraftSubSeries,
      serialNumber: data.msn,
      manufacturer: data.manufacturer,
      aircraftDOM: data.manufactureDate ? moment.utc(data.manufactureDate).format(constants.DATE_FORMAT) : null ,
      newafc: data.newafc
    },
  };
}
