import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";

export const mapToViewModel_dealSelect = (pipelineDealsData) => {
  const recourses = ["Non-Recourse", "Recourse"];
  const seniority = [1, 2, 3, 4, 5];
  const pipelineOnly = pipelineDealsData?.filter(function (d) {
    return d.application === "Pipeline"
  });
  return {
    columns: [
      {
        label: ["Pipeline Deal"],
        field: "globalId",
        editable: true,
        required: true,
        items: pipelineOnly.map((deal) => {
          return { label: deal.dealName, value: deal.id };
        }),
        // initialEditValue: sponsors,
        type: constants.AUTO_COMPLETE,
        inputFieldType: constants.AUTO_COMPLETE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Facility Tranche Name"],
        field: "facilityTrancheName",
        editable: true,
        required: true,
        type: constants.TEXT,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Tranche Seniority"],
        field: "trancheSeniority",
        editable: true,
        required: true,
        items: seniority.map((x) => {
          return { label: x, value: x };
        }),
        // initialEditValue: [{ label: 1, value: 1 }],
        type: constants.SELECT,
        inputFieldType: constants.SELECT,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["Recourse"],
        field: "recourse",
        editable: true,
        required: true,
        items: recourses.map((x) => {
          return { label: x, value: x };
        }),
        // initialEditValue: sponsors,
        type: constants.SELECT,
        inputFieldType: constants.SELECT,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
    ],
  };
};
