import _ from "lodash";
import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const getTemplate = () => {
  return {
    isFirstRun: false,
    sinceNew: {
      flightHour: null,
      flightCycle: null,
      day: null,
      activeHour: null,
    },
    sinceEvent: {
      flightHour: null,
      flightCycle: null,
      day: null,
      activeHour: null,
    },
    statusAsOf: null,
  };
};
const uploadStatus = async (compType, compId, newdata, msalContext) => {
  let apiendpoint = apiRoutes.PostMaintenanceStatus;
  let userName = msalContext.accounts[0].name;
  apiendpoint = apiendpoint({ compType, compId, userName });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newdata,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    newdata = resp.data;
  }
};

export const updateData = (actionType, assetDetails, newData, msalContext) => {
  assetDetails.landingGears = [...assetDetails.landingGears];
  const idx = _.findIndex(
    assetDetails.landingGears,
    (o) => o.position === newData.position
  );
  let landingGear = { ...assetDetails.landingGears[idx] };

  console.log(landingGear);

  let maintenanceStatus = [...landingGear.maintenanceStatus];
  let shopVisitHistory = _.sortBy(landingGear.shopVisitHistory, [
    "startDate",
  ]).slice(-1)[0];

  const targetIdx = newData.idx;
  if (actionType === constants.DELETE_ROW) {
    maintenanceStatus = maintenanceStatus.filter((o, idx) => targetIdx !== idx);
  } else {
    const newMaintenanceStatus = getTemplate();

    if (shopVisitHistory && shopVisitHistory.isOverhaul) {
      let flightHours_sinceEvent =
        parseInt(newData.flightHours_sinceNew) -
        shopVisitHistory.sinceNew.flightHour;
      let flightCycles_sinceEvent =
        parseInt(newData.flightCycles_sinceNew) -
        shopVisitHistory.sinceNew.flightCycle;
      let days_sinceEvent =
        parseInt(newData.days_sinceNew) - shopVisitHistory.sinceNew.day;
      let activeHours_sinceEvent =
        parseInt(newData.activeHours_sinceNew) -
        shopVisitHistory.sinceNew.activeHour;

      newMaintenanceStatus.sinceEvent = {
        flightHour: newData.flightHours_sinceEvent
          ? parseInt(newData.flightHours_sinceEvent)
          : flightHours_sinceEvent,
        flightCycle: newData.flightCycles_sinceEvent
          ? parseInt(newData.flightCycles_sinceEvent)
          : flightCycles_sinceEvent,
        day: newData.days_sinceEvent
          ? parseInt(newData.days_sinceEvent)
          : days_sinceEvent,
        activeHour: newData.activeHours_sinceEvent
          ? newData.activeHours_sinceEvent
          : activeHours_sinceEvent,
      };
    } else {
      newMaintenanceStatus.sinceEvent = {
        flightHour:
          newData.flightHours_sinceEvent &&
          parseInt(newData.flightHours_sinceEvent),
        flightCycle:
          newData.flightCycles_sinceEvent &&
          parseInt(newData.flightCycles_sinceEvent),
        day:
          newData.days_sinceEvent &&
          parseInt(_.round(newData.days_sinceEvent, 2)),
        activeHour: newData.activeHours_sinceEvent,
      };
    }

    newMaintenanceStatus.sinceNew = {
      flightHour:
        newData.flightHours_sinceNew && parseInt(newData.flightHours_sinceNew),
      flightCycle:
        newData.flightCycles_sinceNew &&
        parseInt(newData.flightCycles_sinceNew),
      day: newData.days_sinceNew && parseInt(_.round(newData.days_sinceNew)),
      activeHour: newData.activeHours_sinceNew,
    };
    // no need to change isfirst run
    // newMaintenanceStatus.isFirstRun = maintenanceStatus.length
    //   ? maintenanceStatus[targetIdx].isFirstRun
    //   : false;
    newMaintenanceStatus.statusAsOf = newData.asOfDate;
    if (actionType === constants.ADD_ROW)
      maintenanceStatus.push(newMaintenanceStatus);
    else {
      maintenanceStatus[targetIdx] = { ...maintenanceStatus[targetIdx] };
      maintenanceStatus[targetIdx] = newMaintenanceStatus;
    }
  }
  uploadStatus(
    landingGear.componentType,
    landingGear.componentId,
    maintenanceStatus,
    msalContext
  );
  landingGear.maintenanceStatus = maintenanceStatus;
  assetDetails.landingGears[idx] = landingGear;
  return assetDetails;
};
