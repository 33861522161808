import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";

const CreditReserve = "CreditReserve";
const LessorContribution = "LessorContribution";

export function mapToViewModel_maintenanceReserveRate(data, props) {
  const mxEvents = _.flattenDeep(Object.values(props.allShopVisitEventNames));

  const reserveTypes = [
    { label: constants.NONE, value: constants.NONE },
    { label: constants.CashReserve, value: "Cash" },
    { label: "Credit", value: constants.LC },
  ];

  let getCompsData = _.flattenDeep(
    Object.keys(data).map((key, idx) =>
      key === constants.SCENARIO
        ? []
        : data[key].map((mx, id) => {
            //console.log(mx);
            //console.log(mxEvents);
            const maintenanceReserve =
              mx.componentLeaseInfo.maintenanceReserveRates;
            const checkName = mx.checkName;

            return maintenanceReserve.map((mxRev, i) => {
              const origin = mxEvents.find(x=> { return x.label === checkName});
              return {
                id: i,
                mxEventDisplay:
                  checkName === constants.APU
                    ? checkName.toUpperCase()
                    : checkName,
                mxEvent: mx.componentName ? mx.componentName : checkName,
                eventOrigin: origin,
                reserveType: mxRev.rateType,
                rateEffectiveDate: mxRev.rateDate,
                flightHour: mxRev.rateInterval.flightHour,
                flightCycle: mxRev.rateInterval.flightCycle,
                activeHour: mxRev.rateInterval.activeHour,
                day: mxRev.rateInterval.day,
                monthly: mxRev.rateInterval.monthly,
                annualEscRate: mxRev.rateInterval.escalationRate,
              };
            });
          })
    )
  );

  return {
    label: "Maintenance Reserve Rate",
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.maintenanceReserveRates,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns: [
      {
        title: "Mx Event",
        field: "mxEvent",
        initialEditValue: mxEvents[0].value,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        items: mxEvents,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => rowData.mxEventDisplay,
      },
      {
        title: "Reserve Type",
        field: "reserveType",
        initialEditValue: reserveTypes[0].value,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        items: reserveTypes,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Flight Hour",
        field: "flightHour",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.flightHour,
            null,
            constants.NUMERIC,
            null,
            2
          ),
      },
      {
        title: "Flight Cycle",
        field: "flightCycle",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.flightCycle,
            null,
            constants.NUMERIC,
            null,
            2
          ),
      },
      {
        title: "Active Hour",
        field: "activeHour",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.activeHour,
            null,
            constants.NUMERIC,
            null,
            2
          ),
      },
      {
        title: "Days",
        field: "day",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        required: false,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.day, null, constants.NUMERIC, null, 2),
      },
      {
        title: "Monthly",
        field: "monthly",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        required: false,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.monthly,
            null,
            constants.NUMERIC,
            null,
            2
          ),
      },
      {
        title: "Rate Effective Date",
        field: "rateEffectiveDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.rateEffectiveDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Annual Esc Rate",
        field: "annualEscRate",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMERIC,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(constants.PERCENTAGE, value, required),
        render: (rowData) =>
          `${_.round(rowData.annualEscRate * 100, 3).toFixed(2)}%`,
      },
    ],
    data: getCompsData,
  };
}
