import React from "react";
import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export function mapToViewModel_cashFlows(cashFlows) {
  return {
    label: "Cash Flows",
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    span: [12, 12, 12],
    columns: [
      {
        label: "Payment Date",
        caption: null,
        field: "paymentDate",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.DATE_CELL
          ),
      },
      {
        label: "Rent",
        caption: "U.S. $",
        field: "rent",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Supp Rent",
        caption: "U.S. $",
        field: "mxReserveToLessor",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: (
          <span>
            Mx. Exp <br /> Lessor
          </span>
        ),
        caption: "U.S. $",
        field: "mxExpenseFromLessor",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: (
          <span>
            EOL Comp <br /> Net
          </span>
        ),
        caption: "U.S. $",
        field: "redeliveryComp",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "RRR Cost",
        caption: "U.S. $",
        field: "rrrCost",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Other CF",
        caption: "U.S. $",
        field: "otherCF",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Net Total CF",
        caption: "U.S. $",
        field: "netTotalCF",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Mx. Cash Reserve",
        caption: "U.S. $",
        field: "mxCashReserve",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Value to H.L.",
        caption: "U.S. $",
        field: "hlAdj",
        filter: false,
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: "Value",
        caption: "U.S. $",
        field: "value",
        filter: false,
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
      {
        label: (
          <span>
            EOL Comp <br /> Exp
          </span>
        ),
        caption: "U.S. $",
        field: "redeliveryCompExp",
        align: "right",
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL
          ),
      },
    ],
    data: _.compact(
      Object.keys(cashFlows).map((paymentDate) => {
        // if (moment.utc(paymentDate).diff(moment.utc(new Date()).subtract(1, 'months'), "days") < 0) {
        //   return null;
        // }
        return {
          paymentDate: paymentDate,
          rent: _.round(cashFlows[paymentDate].rent ),
          mxReserveToLessor: _.round(
            cashFlows[paymentDate].mxReserveToLessor 
          ),
          mxCashReserve: _.round(cashFlows[paymentDate].mxCashReserve ),
          mxExpenseFromLessor: _.round(
            cashFlows[paymentDate].mxExpenseFromLessor 
          ),
          redeliveryComp: _.round(cashFlows[paymentDate].redeliveryComp ),
          rrrCost: _.round(cashFlows[paymentDate].rrrCost ),
          otherCF: _.round(cashFlows[paymentDate].otherCF ),
          netTotalCF: _.round(cashFlows[paymentDate].netTotalCF ),
          hlAdj: _.round(cashFlows[paymentDate].valueAboveHalfLife ),
          value:  _.round(cashFlows[paymentDate].value ),
          redeliveryCompExp: _.round(cashFlows[paymentDate].redeliveryCompExp ),
          style: {
            height: 48,
          },
        };
      })
    ),
  };
}
