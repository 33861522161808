import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Box,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useMsal } from "@azure/msal-react";
import XLSX from "xlsx";
import _ from "lodash";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";
import { useSignalRHub } from "../../../../hooks/useSignalRHub";

const FileUpload = ({
  deal,
  handleSnackbarOpen,
  handleRefreshLiabilityData,
}) => {
  const [open, setOpen] = useState(false);
  const [lmFile, setLmFile] = useState(null);
  const [lmType, setLmType] = useState("Base");
  const [lmSubtype, setLmSubtype] = useState(null);
  const [error, setError] = useState(false);
  const msalContext = useMsal();
  const { hubConnection, hardReconnect } = useSignalRHub("lmHub"); // hubname arg is required and must match the hub name on api app

  // handling signalR messages
  useEffect(() => {
    if (hubConnection) {
      hubConnection.on("ReceiveMessage", (processStage, message) => {
        console.log(message);
        if (processStage === "warning") {
          handleSnackbarOpen(true, "warning", message, 50000);
        } else {
          handleSnackbarOpen(true, "info", message, 50000);
          // Checking if process is done & is base model upload to auto refresh data
          if (
            lmType === "Base" &&
            (processStage === "done" || processStage === "warning")
          ) {
            handleRefreshLiabilityData(deal.dealId);
          }
        }
      });
    }
  }, [
    hubConnection,
    handleSnackbarOpen,
    handleRefreshLiabilityData,
    deal,
    lmType,
  ]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setLmType("Base");
      setLmFile(null);
      setOpen(false);
    }
  };

  const handleSelectFile = (event) => {
    const selectedFile = event.target.files[0];
    setLmFile(selectedFile);
  };

  const handleSelectFileType = (event) => {
    setLmType(event.target.value);
  };

  const handleSelectSubType = (event) => {
    setLmSubtype(event.target.value);
  };

  const validateUploadFile = () =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(lmFile);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "binary" });

        const ws = wb.Sheets["Asset_Info"];
        const data = XLSX.utils.sheet_to_json(ws);

        let uploadSerial = _(data)
          .map((o) => o["SerialNumber"])
          .without(undefined, null, "")
          .sort()
          .value();

        let dealSerial = _(deal.assets)
          .map((o) => o.serialNumber)
          .sort()
          .value();

        // check that the serial numbers of assets in liabilitymodel match what's in the assetpool
        let checkSerialNum = _.isEqual(dealSerial, uploadSerial);

        // check the model type against selected one if not Other
        let checkModelType = true;
        if (lmType !== "Other") {
          let cell = _.find(wb.Workbook.Names, [
            "Name",
            "Model_Type",
          ]).Ref.split("$");

          const modelType =
            wb.Sheets["Summary"][cell[cell.length - 2] + cell[cell.length - 1]]
              .v;
          checkModelType = modelType === lmType ? true : false;
        }

        resolve(checkSerialNum && checkModelType);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const handleSubmit = async () => {
    console.log(lmFile, lmSubtype);
    if (lmFile && lmSubtype) {
      hardReconnect();
      handleClose();
      handleSnackbarOpen(
        true,
        "info",
        <Box
          style={{
            width: 280,
            display: "flex",
            justifyContent: "space-between",
            color: "white",
          }}>
          Validating & Uploading File
          <CircularProgress color='inherit' size={20} />
        </Box>,
        null
      );

      try {
        const isFileValid =
          lmSubtype === "Shortform" ? true : await validateUploadFile(lmFile);

        if (isFileValid) {
          const formData = new FormData();
          formData.append("file", lmFile);
          const resp = await apiRequest(
            {
              url: apiRoutes.LiabilityModel_UploadLiabilityFile,
              method: "POST",
              data: formData,
              params: {
                assetPoolId: deal.assetPoolId,
                dealId: deal.dealId,
                uploadedBy: msalContext.accounts[0].username,
                liabilityModelType: lmType,
                lmSubtype: lmSubtype,
              },
            },
            msalContext
          );

          if (resp.status === response.OK) {
            console.log("LM Upload: Response OK. Upload in progress");
          } else {
            handleSnackbarOpen(
              true,
              "error",
              "Something went wrong with your upload. Please refresh your browser and try again.",
              5000
            );
          }
        } else {
          handleSnackbarOpen(
            true,
            "error",
            "The asset serial numbers in your file do not match those serial numbers in this deal, Or the selected model type is wrong",
            3000
          );
        }
      } catch (error) {
        console.log(`LM Upload: ${error}`);
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Tooltip
        title='Upload Liability Model'
        aria-label='Upload Liability Model'>
        <Button
          style={{ maxHeight: 28, marginLeft: 16 }}
          variant='outlined'
          color='primary'
          onClick={handleOpen}>
          <span style={{ margin: "0px 8px 0px 8px" }}>Upload</span>
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        disableEscapeKeyDown>
        <DialogTitle>Upload Liability Model</DialogTitle>
        <DialogContent dividers={true}>
          <FormControl
            variant='standard'
            style={{ minWidth: 200, marginBottom: "2rem" }}>
            <InputLabel id='lm-type-label'>Liability Model Type</InputLabel>
            <Select
              labelId='lm-type-label'
              id='lm-type'
              value={lmType}
              label='Liability Model Type'
              onChange={handleSelectFileType}>
              <MenuItem value={"Base"}>Base</MenuItem>
              <MenuItem value={"ZeroSalePremium"}>Base - ZSP</MenuItem>
              <MenuItem value={"Closed"}>Closed</MenuItem>
              <MenuItem value={"ClosedZSP"}>Closed - ZSP</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant='standard'
            style={{ minWidth: 200, marginBottom: "2rem", marginLeft: "2rem" }}>
            <InputLabel id='lm-type-label'>Subtype</InputLabel>
            <Select
              labelId='lm-subtype-label'
              id='lm-subtype'
              value={lmSubtype}
              label='Subtype'
              onChange={handleSelectSubType}>
              <MenuItem value={"Default"}>Default</MenuItem>
              <MenuItem value={"LTM"}>LTM</MenuItem>
              <MenuItem value={"LTS"}>LTS</MenuItem>
              <MenuItem value={"Shortform"}>Shortform</MenuItem>
            </Select>
          </FormControl>
          {/* {error ? (
            <Box>
              Please select a model subtype and / or liability model file to
              proceed
            </Box>
          ) : (
            <></>
          )} */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}>
            <Button
              variant='outlined'
              component='label'
              size='small'
              style={{
                marginRight: "0.5rem",
                whiteSpace: "nowrap",
                minWidth: "100px",
              }}>
              Select a file
              <input type='file' hidden onChange={handleSelectFile} />
            </Button>
            {lmFile ? (
              <Tooltip title={lmFile.name}>
                <span
                  style={{
                    fontSize: "1rem",
                    fontStyle: "italic",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}>{`${lmFile.name}`}</span>
              </Tooltip>
            ) : (
              <span
                style={{ fontSize: "1rem", fontStyle: "italic", opacity: 0.7 }}>
                no selection
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button type='button' variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type='button'
            variant='outlined'
            color='primary'
            disabled={!Boolean(lmFile) | !Boolean(lmSubtype)}
            onClick={handleSubmit}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUpload;
