import React from "react";
import _ from "lodash";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import mapToViewModel_LLPReplacements from "./llpReplacementsViewModel";
import BookMark from "../../../Common/SvgComponents/BookMark";
import { Box } from "@material-ui/core";

export function mapToViewModel_maintenanceEvents(events) {
  events = events.filter(function (obj) {
    return obj.type !== "Conversion";
  });

  return {
    label: "Maintenance Events",
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    span: [12, 12, 12],
    columns: [
      {
        label: "Event",
        caption: null,
        field: "name",
        align: constants.LEFT,
        indent: 16,
        contentType: constants.BUTTON,
        clickableCellIcon: (value) => <BookMark value={value} />,
        content: (isButton, value, handleClick, indent) => (
          <Box
            style={{
              width: "100%",
            }}>
            {utils.renderEventName(isButton, value, handleClick, indent)}
          </Box>
        ),
      },
      {
        label: "Start Date",
        caption: null,
        field: "eventStartDate",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.DATE_CELL
          ),
      },
      {
        label: "End Date",
        caption: null,
        field: "eventEndDate",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.DATE_CELL
          ),
      },

      {
        label: "Cost",
        caption: "U.S. $",
        field: "mxCost",
        align: constants.RIGHT,
        indent: 16,
        content: (value, indent) =>
          utils.renderCellValue(
            value,
            { paddingRight: indent },
            constants.NUMERIC_CELL,
            "$"
          ),
      },
      {
        label: "Funded By",
        caption: "U.S. $",
        field: "fundedBy",
        align: constants.RIGHT,
        indent: 16,
        content: (items, indent) =>
          utils.renderMultiValueCellValue(items, indent, 120, "$"),
      },
      {
        label: "Since Last Event",
        caption: null,
        field: "sinceEvent",
        align: constants.RIGHT,
        indent: 16,
        content: (items, indent) =>
          utils.renderMultiValueCellValue(items, indent, 120),
      },
    ],

    data: events.map((event) => ({
      type: event.type,
      eventStartDate: event.eventStartDate,
      eventEndDate: event.eventEndDate,
      date: event.date,
      name: [event.name, event.componentSerialNumber],
      mxCost: _.round(event.mxCost),
      fundedBy: [
        { label: "C.R. ", value: _.round(event.fromCashReserve) },
        {
          label: "LSR. CTB.",
          value: _.round(event.fromCreditReserve),
        },
        {
          label: "L.C.",
          value: _.round(event.fromLessorContribution),
        },
        {
          label: "LSE. CTB.",
          value: _.round(event.fromLesseeContribution),
        },
      ],
      sinceEvent: event.name.includes("LLP")
        ? [
            { label: "FC C.", value: _.round(event.sinceEvent_FC_Core) },
            { label: "FC", value: _.round(event.sinceEvent_FC) },
          ]
        : [
            { label: "FH", value: _.round(event.sinceEvent_FH) },
            { label: "FC", value: _.round(event.sinceEvent_FC) },
            { label: "MO", value: _.round(event.sinceEvent_Day / 30.44) },
            {
              label: "APUH",
              value:
                event.name === constants.APU_OVERHAUL
                  ? _.round(event.sinceEvent_AH)
                  : null,
            },
          ],
      hidden: event.llpReplacements.length
        ? mapToViewModel_LLPReplacements(
            event.llpReplacements,
            constants.maintenanceEvents
          )
        : null,
    })),
  };
}
