import React from "react";
import _ from "lodash";
import { Box, Typography, Grid } from "@material-ui/core";
import * as constants from "../constants";
import * as utils from "../utils/utils";
import MultiFunctionTable from "../Components/Common/Tables/MultiFunctionTable/MultiFunctionTable";

import moment from "moment";
import LoadingPanel from "../Components/Common/LoadingPanel";
import VfAlertDialogBox from "../Components/Common/UtilComponents/VfAlertDialogBox";

import { apiRequest, response } from "../utils/ApiRequest";
import apiRoutes from "../utils/ApiRoutes";
import VfSnackerBar from "../Components/Common/UtilComponents/VfSnackerBar";
import { MsalContext } from "@azure/msal-react";

function generateColumns(props) {
  return [
    {
      label: "File Name",
      caption: null,
      field: "fileName",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(value, {
          paddingLeft: indent,
          width: 300,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }),
    },
    {
      label: "Status",
      caption: null,
      field: "status",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }),
    },
    {
      label: "Upload Date",
      caption: null,
      field: "uploadDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          null,
          null,
          0,
          true
        ),
    },
    {
      label: "Download Date",
      caption: null,
      field: "downloadDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          null,
          null,
          0,
          true
        ),
    },
    // {
    //   label: "Size",
    //   caption: null,
    //   field: "fileSize",
    //   filter: false,
    //   align: constants.RIGHT,
    //   indent: 16,
    //   content: (value, indent) =>
    //     utils.renderCellValue(value, { paddingRight: indent }),
    // },
    {
      label: "Format",
      caption: null,
      field: "fileFormat",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }),
    },
    {
      label: "Download",
      caption: null,
      field: "downloads",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      contentType: constants.BUTTON,
      content: (isButton, cell, _) =>
        utils.renderEventName(
          true,
          cell.label,
          () => props.handleDownloadButtonClick(cell.fileId, cell.fileName),
          16,
          "Right"
        ),
    },
    {
      label: "Delete",
      caption: null,
      field: "delete",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      contentType: constants.BUTTON,
      content: (isButton, cell, _) =>
        utils.renderEventName(
          true,
          cell.label,
          () => props.handleDeleteButtonClick(cell.fileId),
          16,
          "Right",
          { color: "red" }
        ),
    },
  ];
}

export function mapToViewModel_exportHistory(historyData, props) {
  const data = historyData.map((item) => ({
    id: item.id,
    fileName: item.filename,
    filePath: null,
    status: "Ready",
    downloadDate: item.downloadDate
      ? moment
          .utc(item.downloadDate)
          .local()
          .format(constants.LOCAL_DATETIME_FORMAT)
      : null,
    uploadDate: moment
      .utc(item.uploadDate)
      .local()
      .format(constants.LOCAL_DATETIME_FORMAT), // new Date(item.uploadDate).toLocaleString(),,
    fileSize: item.fileSize,
    fileFormat: item.fileFormat ?? "Excel",
    style: { height: 48 },
    downloads: { label: "Download", fileId: item.id, fileName: item.filename },
    delete: { label: "Delete", fileId: item.id, fileName: item.filename },
  }));
  const columns = generateColumns(props);

  const tableData = {
    label: constants.exportHistory,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: false, pagination: false },
    columns,
    data,
  };

  return tableData;
}

class ExportHistory extends React.Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.state = {
      histData: [],
      isLoading: true,
      isAlertDialogOpen: false,
      selectedFileId: null,
      snackOpen: false,
      severity: "info",
      message: "",
    };
    //this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }
  componentDidMount = async () => {
    let resp = await this.getExportHistory();
    if ((resp.status = response.OK)) {
      this.setState({ histData: resp.data, isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  getExportHistory = async () => {
    let username = this.context.accounts[0].username;
    return await apiRequest(
      {
        url: apiRoutes.VectorDeal_GetExportHistory,
        params: { username: username },
        method: "GET",
      },
      this.context
    );
  };

  handleDownloadButtonClick = async (fileId, fileName) => {
    let exportFileObj = {
      FileId: fileId,
      UserName: this.context.accounts[0].username,
    };

    const response = await apiRequest(
      {
        url: apiRoutes.VectorDeal_DownloadExportFile,
        method: "POST",
        responseType: "blob",
        data: exportFileObj,
      },
      this.context
    );

    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  handleDeleteButtonClick = async (fileId) => {
    this.setState({ isAlertDialogOpen: true, selectedFileId: fileId });
    return;
  };

  deleteFile = async () => {
    let exportFileObj = {
      FileId: this.state.selectedFileId,
      UserName: this.context.accounts[0].username,
    };

    const resp = await apiRequest(
      {
        url: apiRoutes.VectorDeal_DeleteExportFile,
        method: "POST",
        data: exportFileObj,
      },
      this.context
    );

    if (resp.status == response.OK) {
      this.handleSnackerBarOpen(true, "success", "File Deleted Successfully");
      let resp = await this.getExportHistory();
      if ((resp.status = response.OK)) {
        this.setState({
          histData: resp.data,
          isLoading: false,
          isAlertDialogOpen: false,
        });
      } else {
        this.setState({ isLoading: false, isAlertDialogOpen: false });
      }
    } else {
      this.handleSnackerBarOpen(true, "error", "Error Deleting File");
    }
  };

  handleAlertDialogVisible = (isOpen) => {
    this.setState({ isAlertDialogOpen: isOpen });
  };

  handleSnackerBarOpen = (open, severity, msg) => {
    this.setState({ snackOpen: open, severity: severity, message: msg });
  };

  render() {
    const {
      histData,
      isLoading,
      isAlertDialogOpen,
      snackOpen,
      severity,
      message,
    } = this.state;

    let leaseInfo = mapToViewModel_exportHistory(histData, this);
    return (
      <Box mx={1} my={4} flexGrow={1} style={{ minWidth: 1280 }}>
        <VfSnackerBar
          snackOpen={snackOpen}
          setsnackOpen={this.handleSnackerBarOpen}
          duration={2000}
          severity={severity}
          message={message}
        />
        <Grid container direction='row'>
          <Grid item sm={12} md={12}>
            <Typography variant='h5' color='primary'>
              Export History
            </Typography>
            <Box my={4}>
              {isLoading ? (
                <LoadingPanel />
              ) : (
                <MultiFunctionTable tableData={leaseInfo} />
              )}
            </Box>
          </Grid>
        </Grid>
        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={this.handleAlertDialogVisible}
          dialogTitle={"Delete File"}
          dialogBody={"Are you sure you want to Delete this file?"}
          onAccept={this.deleteFile}
        />
      </Box>
    );
  }
}

export default ExportHistory;
