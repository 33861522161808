import _ from "lodash";
import * as constants from "../../../../constants";

export const updateConversions = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario,
) => {
  const parameters = scenario.parameters
  if (actionType === constants.UPDATE_ROW) {
    newData ?
    parameters.conversion = {
        startDate: newData.startDate,
        endDate: newData.endDate,
        cost: newData.cost
    }
    :
    parameters.conversion = null;
    ;
  }

  return scenario;
};