import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import { styles } from "../index";

export function mapToViewModel_mxReserveBalance(leaseSummary, props, threeDotBtnOptions) {
  let data = [];
  const leaseData = leaseSummary.mxReserve_LessorContrib.mxReserveBalance
  if(leaseData.dataTable) {
    data = leaseData.dataTable.map((e, idx) => {
      return {
        id: idx,
        component: e.component,
        description: e.description,
        balance: e.balance,
        note: e.note,
      };
    })
  };

  const {
    section,
    funcs: { handleValuesUpdate, validateData },
  } = props

  const footNoteData = {
    footNotes: leaseData.footNotes,
    tableName:  "Maintenance Reserve Balance",
    props: { 
      section: section,
      sectionIdx: "footNotes",
      funcs: { handleValuesUpdate, validateData } 
    },
  };

  return {
    label: "Maintenance Reserve Balance",
    caption: leaseData.footNotes,
    props,
    threeDotBtnOptions: threeDotBtnOptions("Maintenance Reserve Balance", footNoteData),
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: "Maintenance Reserve Balance",
    errorsOnSubmit: {},
    columns: [
      {
        title: "Component",
        field: "component",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.component, null),
      },
      {
        title: "Description",
        field: "description",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.description, null),
      },
      {
        title: "Balance",
        field: "balance",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        hidden: props.section === constants.apUs,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.balance, null, constants.NUMERIC_CELL, "$"),
      },
      {
        title: "Note",
        field: "note",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        align: "left",
        cellStyle: { ...styles.cellStyle, textAlign: "left", width: 550 , minWidth: 550 },
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.note, null),
      },
    ],
    data: data,
  };
}
