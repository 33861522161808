import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import VerticalTableContainer from "./VerticalTableContainer";

const useStyles = makeStyles({
  rectangle13: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 12,
    // height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const VerticalTable = ({ tableData, customStyle, editAll }) => {
  const classes = useStyles();

  return tableData.data && tableData.data.length === 0 ? null : customStyle ? (
    <VerticalTableContainer tableData={tableData} editAll={editAll} />
  ) : (
    <Paper className={classes.rectangle13}>
      <VerticalTableContainer tableData={tableData} editAll={editAll} />
    </Paper>
  );
};

export default VerticalTable;
