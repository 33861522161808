import React from "react";
import _ from "lodash";
import moment from "moment";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as constants from "../../../../../constants";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import { useState } from "react";
import { useEffect } from "react";

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    // paddingRight: 20,
  },
  mainText: {
    color: "#023059",
    fontWeight: 400,
    fontSize: 16,
  },
  captionText: {
    color: "#023059",
    fontSize: 10,
    opacity: "70%",
  },
  gridItem: {
    width: 80,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const DealLiabilityPreview = ({
  liabilityData,
  expectedLossRateData,
  deal,
}) => {
  const classes = useStyles();
  const [match, setMatch] = useState(true);

  let collateral = null;
  let msn = deal.assets.map((x) => x.serialNumber);

  useEffect(() => {
    if (liabilityData) {
      if (liabilityData.modelSubtype === "Shortform") setMatch(true);
      else if (liabilityData.assets.sort().join(",") !== msn.sort().join(","))
        setMatch(false);
    }
  }, [liabilityData]);

  if (liabilityData) {
    collateral =
      liabilityData.assetValue +
      liabilityData.lcReserve_Gurantee +
      liabilityData.cashReserve;
  }

  return (
    <Box>
      {!match ? (
        <Grid container className={classes.root}>
          <Grid item xs={2} className={classes.gridItem}>
            <SyncProblemIcon />
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <Box style={{ paddingTop: 5 }}> Asset Mismatch</Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.root}>
          <Grid item xs={2} className={classes.gridItem}>
            <Box className={classes.mainText} align='center' variant='body2'>
              {liabilityData
                ? `${(liabilityData.ltvPct * 100).toFixed(0)}%`
                : constants.___}
            </Box>
            <Box className={classes.captionText}>LTV</Box>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <Box className={classes.mainText} align='center' variant='body2'>
              {liabilityData
                ? `$${_.round(
                    liabilityData.loanOutstanding / 1000000,
                    1
                  ).toFixed(1)}M`
                : constants.___}
            </Box>
            <Box className={classes.captionText}>Loan Balance</Box>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <Box className={classes.mainText} align='center' variant='body2'>
              {liabilityData
                ? `$${_.round(collateral / 1000000, 1).toFixed(1)}M`
                : constants.___}
            </Box>
            <Box className={classes.captionText}>Collateral</Box>
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            <Box className={classes.mainText} align='center' variant='body2'>
              {liabilityData
                ? `${moment
                    .utc(liabilityData.paymentDate)
                    .format(constants.DATE_FORMAT)}`
                : constants.___}
            </Box>
            <Box className={classes.captionText}>Balance Date</Box>
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            <Box className={classes.mainText} align='center' variant='body2'>
              {expectedLossRateData
                ? `${_.round(expectedLossRateData.lossRate, 1).toFixed(2)} Bps`
                : constants.___}
            </Box>
            <Box className={classes.captionText}>
              {expectedLossRateData
                ? "Exp Loss - " +
                  moment
                    .utc(expectedLossRateData.eL_RunDate)
                    .format(constants.DATE_FORMAT)
                : "Exp Loss"}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DealLiabilityPreview;
