import React, { useState, useEffect } from "react";

import apiRoutes from "../../../../utils/ApiRoutes";
import { apiRequest } from "../../../../utils/ApiRequest";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import AssetInput from "../AssetInput";
import LoadingPanel from "../../../Common/LoadingPanel";
import { useMsal } from "@azure/msal-react";

const mapToViewModel = ({ data }) => {
  return [
    {
      label: ["Airframe Model"],
      field: "airframeModel",
      editable: true,
      required: true,
      items: data.map((o) => ({
        label: o.aircraftSubSeries,
        value: o,
      })),
      type: constants.AUTO_COMPLETE,
      inputFieldType: constants.AUTO_COMPLETE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      label: ["Serial Number"],
      field: "serialNumber",
      editable: true,
      required: true,
      type: constants.TEXT,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
  ];
};

const Airframe = ({ props }) => {
  const [data, setData] = useState(null);
  const msalContext = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiRequest(
        {
          url: apiRoutes.GenericAC_GetList,
          method: "GET",
        },
        msalContext
      );
      setData(mapToViewModel(result));
    };
    fetchData();
  }, []);

  return data ? (
    <AssetInput columns={data} subTitle='Airframe' props={props} />
  ) : (
    <LoadingPanel />
  );
};

export default Airframe;
