import React from "react";
import _ from "lodash";
// import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";

export function mapToViewModel_CashReserveAccounts(
  scenario,
  props
) {
  let data = [];
  props.lease = scenario;

  let items = _.flattenDeep(Object.values(props.allShopVisitEventNames));
  let svnames =  _.flatten(items.map((y) => y.value));

  //console.log(svnames);

  const usedComponents = _.flatten(scenario.reserveAccounts.map((a) => a.components) );
  const available = items.filter(item => !usedComponents.includes(item.value) )

  //console.log(usedComponents);
  let removethese = [];

  let { section, sectionIdx, funcs } = props;

  scenario.reserveAccounts.map((a, idx) => {
    let unshown = a.components.filter(function(x){
      return svnames.indexOf(x) ==-1;
    })
    if(unshown.length > 0){
      removethese.push(a.id);
      return null;
    }
    data.push({
      id: a.id,
      idx: idx,
      accountName: `Account ${a.id}`,
      available: available,
      components: a.components
    });
    return null;
  });
  //console.log(removethese);
  if(removethese.length > 0){
      const newra = scenario.reserveAccounts.filter(d => removethese.indexOf(d.id) === -1);
      funcs.handleValueChange("removecomp")(
        section,
        sectionIdx,
        constants.cashReserveAccounts,
        newra
      );
  }


  return {
    label: "Cash Reserve Accounts",
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.cashReserveAccounts,
    props,
    showOptions: true,
    errorsOnSubmit: {},
    columns: [
      {
        title: "Account Name",
        field: "accountName",
        type: constants.STRING,
        cellStyle: styles.cellStyle,
        editable: constants.NEVER,
      },
      {
        title: "Components",
        field: "components",
        span: [6, 4],
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        items: available,
        type: constants.STRING,
        inputFieldType: constants.SELECT_CHECK_BOX,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => {
          let displayData = [];
          let components = _.compact(
            rowData.components.map((component) => {
              let target = _.find(
                items,
                (item) =>
                  item.checkName === component || item.value === component
              );
              
              return target && target.label;
            })
          );

          let af = [];
          let lastAFCheckIdx = components.length;
          let afCheckCount = 0;
          for (var j = components.length - 1; j >= 0; j--) {
            if (
              components[j].includes("Check") ||
              (components[j].includes("Year") &&
                lastAFCheckIdx === components.length)
            ) {
              af.push(components[j]);
              lastAFCheckIdx = j;
              afCheckCount++;
            } else if (
              components[j].includes("Check") ||
              components[j].includes("Year")
            ) {
              if (j < lastAFCheckIdx) {
                components[j] = components[j].split(" ")[0].trim();
                af.push(components[j]);
                afCheckCount++;
              }
            }
          }

          // if (afCheckCount > 1) af[0] += "s";
          displayData.push(af.reverse());

          let firstEPRIdx = -1;
          let firstELLPIdx = -1;
          let firstLGIdx = -1;

          let enginePR = [];
          let engineLLP = [];
          let lg = [];
          let apu = [];
          //console.log(components);

          for (var i = 0; i < components.length; i++) {
            if (components[i].includes("EPR") && firstEPRIdx === -1) {
              enginePR.push(components[i]);
              firstEPRIdx = i;
            } else if (components[i].includes("EPR")) {
              if (i > firstEPRIdx)
                components[i] = components[i].split("-")[1].trim();
              enginePR.push(components[i]);
            } else if (components[i].includes("ELLP") && firstELLPIdx === -1) {
              engineLLP.push(components[i]);
              firstELLPIdx = i;
            } else if (components[i].includes("ELLP")) {
              if (i > firstELLPIdx)
                components[i] = components[i].split("-")[1].trim();
              engineLLP.push(components[i]);
            } else if (components[i].includes("LG") && firstLGIdx === -1) {
              lg.push(components[i]);
              firstLGIdx = i;
            } else if (components[i].includes("LG")) {
              if (i > firstLGIdx)
                components[i] = components[i].split("-")[1].trim();
              lg.push(components[i]);
            } else if (
              components[i].toUpperCase() === constants.APU.toUpperCase()
            ) {
              apu.push(components[i].toUpperCase());
            } else if ( !af.includes(components[i]) ){
              displayData.push([components[i]])
            }
          }
          displayData.push(enginePR);
          displayData.push(engineLLP);
          displayData.push(lg);
          displayData.push(apu);

          return displayData.map((data, idx) => (
            <span key={idx}>
              {data.map((e, i) => (
                <span key={e}>
                  <span style={{ fontSize: data.length >= 3 ? 13 : 14 }}>
                    {e === constants.APU ? e.toUpperCase() : e}
                    {i !== data.length - 1 ? ", " : null}
                  </span>
                  {idx !== displayData.length - 1 && i === data.length - 1 ? (
                    <br />
                  ) : null}
                </span>
              ))}
            </span>
          ));
        },
      },
    ],
    data,
  };
}
