import React from "react";
import Root from "../../../Components/Hoc/Root";
import {
  Grid,
  Fade,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
  rootMenu: {
    width: 180,
    color: "#263238",
    zIndex: "auto"
  },
  subMenu: {
    width: 180,
    height: 300,
    overflowY: "auto",
    color: "#263238",
  },
  menuListPadding: {
    padding: 0,
  },
  selected: {
    cursor: "unset",
    color: "#BDBEC0",
    "&:hover": { backgroundColor: "unset" },
  },
}));

const VfMenuList = ({ open, options, onItemClick }, ref) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = React.useState(null);

  const handleClick = (e, index, id, action) => {
    if (action === constants.TOGGLE_MENU) {
      if (!menuOpen) setAnchorRef(e.currentTarget);
      else setAnchorRef(null);
      setMenuOpen(!menuOpen);
    } else {
      onItemClick(e, index, id, action);
    }
  };

  const checkLabels = ["Import", "Upload"];

  return (
    <MenuList autoFocusItem={open} id='menu-list-grow'>
      <Paper className={classes.rootMenu}>
        {options.map((option, index) => (
          <Root key={index}>
            {/* {option.label === "Import Status" || option.label === "Upload Appraisal" ||  option.label === "Import Lease Rent" || option.label === "Upload Secondary" || option.label === "Import Account" ?  */}
            {checkLabels.some((checkLabel) =>
              option.label.includes(checkLabel)
            ) ? (
              <MenuItem component='label'>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item>
                    <Typography variant='body2'>
                      {option.icon && (
                        <option.icon
                          color='action'
                          style={{
                            paddingTop: 5,
                            fill: "rgba(0, 0, 0, 0.54)",
                            width: 30,
                            height: 30,
                            margin: -4,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2'>{option.label}</Typography>
                  </Grid>
                </Grid>
                <input
                  type='file'
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) option.handleClick(file);
                  }}
                  hidden
                />
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(event) =>
                  handleClick(event, index, option.id, option.action)
                }>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item>
                    <Typography variant='body2'>
                      {option.icon && <option.icon color='action' />}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2'>{option.label}</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            )}
            {option.subMenuItems && (
              <Popper
                className={classes.subMenu}
                open={menuOpen}
                anchorEl={anchorRef}
                placement={"right-start"}
                transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps}>
                    <MenuList
                      classes={{ padding: classes.menuListPadding }}
                      autoFocusItem={menuOpen}
                      id='menu-list-grow'>
                      <Paper>
                        {option.subMenuItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            className={
                              option.selected === item.label
                                ? classes.selected
                                : null
                            }
                            onClick={(event) =>
                              handleClick(event, index, option.id, item.action)
                            }>
                            <Grid container spacing={1} alignItems='center'>
                              <Grid item>
                                <Typography variant='body2'>
                                  {item.icon && <item.icon color='action' />}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {item.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </Paper>
                    </MenuList>
                  </Fade>
                )}
              </Popper>
            )}
          </Root>
        ))}
      </Paper>
    </MenuList>
  );
};

const forwardedVfMenuList = React.forwardRef(VfMenuList);
export default forwardedVfMenuList;
