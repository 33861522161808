import React from "react";

import AircraftSvg from "../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../Common/SvgComponents/EngineSvg";
import LandingGearSVG from "../../Common/SvgComponents/LandingGearSVG";
import ApuSVG from "../../Common/SvgComponents/ApuSVG";

import * as constants from "../../../constants";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: { color: "rgba(0,0,0,0.87)", fontSize: 34, textAlign: "center" },
  subTitle: {
    color: "rgba(0,0,0,0.87)",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "center",
  },
  assetType: { width: 508, height: 250, margin: "auto" },
  assetTypeCard: {
    width: 250,
    height: 250,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    cursor: "pointer",
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    "&:hover": { backgroundColor: "#E5E8EB" },
  },
  assetTypeText: {
    fontSize: 12,
    color: "#000000",
    textAlign: "center",
  },
}));

const AssetTypeCard = ({ onAssetTypeSelect }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="subtitle1" className={classes.title}>
          Asset Creation
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="subtitle2" className={classes.subTitle}>
          Select Asset Type
        </Typography>
      </Box>
      <Box className={classes.assetType}>
        <Grid container spacing={2}>
          {[constants.ENGINE, constants.AIRCRAFT
          //,constants.APU, constants.Landing_Gears
          ].map((assetType, idx) => (
            <Grid key={idx} item lg={6}>
              <Box
                className={classes.assetTypeCard}
                onClick={() => onAssetTypeSelect(assetType)}
              >
                <Box
                  style={{
                    width: 100,
                    height: 100,
                    margin: "auto",
                    paddingTop: 75,
                  }}
                >
                  {assetType.toUpperCase() ===
                  constants.AIRCRAFT.toUpperCase() ? (
                    <AircraftSvg height={100} width={100} viewBox="0 6 24 21" />
                  ) : assetType.toUpperCase() === constants.ENGINE.toUpperCase() ? 
                  (
                    <EngineSvg height={100} width={100} viewBox="0 0 24 30" />
                  )// APU 
                  : assetType.toUpperCase() === constants.APU.toUpperCase() ? 
                  (
                    <ApuSVG height={100} width={100} viewBox="0 0 24 30" />
                  ) : //LG
                  ( <LandingGearSVG height={100} width={100} viewBox="0 0 24 30" /> )
                  }
                  <Typography
                    variant="caption"
                    display="block"
                    className={classes.assetTypeText}
                  >
                    {assetType.charAt(0).toUpperCase() + assetType.slice(1)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AssetTypeCard;
