import React from "react";
import "../../App.css";
import { Button, Grid, Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import bgLogin from "../../img/bg3.jpg";
import volofinLogo from "../../img/volofin_logo.png";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles({
  image: {
    width: "100%",
    height: "100%",
  },
  img: {
    margin: "50px auto",
    display: "block",
    maxWidth: "70%",
    maxHeight: "70%",
  },
});

const handleLogin = (instance) => {
  instance.loginRedirect();
};

const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant='contained'
      style={{ width: "100%" }}
      color='primary'
      size={"medium"}
      onClick={() => handleLogin(instance)}>
      Sign In
    </Button>
  );
};

const Login = () => {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();

  return !isAuthenticated ? (
    <Container maxWidth={"xl"} style={{ padding: 0 }}>
      <Grid container>
        <Grid
          item
          sm={12}
          md={9}
          style={{
            backgroundImage: `url(${bgLogin})`,
            backgroundSize: "cover",
            height: "100vh",
            backgroundPosition: "center",
          }}></Grid>
        <Grid item sm={12} md={3} style={{ backgroundColor: "#FCFAFA" }}>
          <Box display='flex' width={1} height={1} bgcolor='#eee'>
            <Box m='auto' px={1}>
              <Grid container>
                <Grid item xs={12} className={classes.image} m={12}>
                  <img
                    className={classes.img}
                    src={volofinLogo}
                    alt='Volofin Logo'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h4'
                    align='center'
                    className={classes.myCustomClass}>
                    Welcome to VfAssetModel
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ marginTop: "2rem" }}>
                    <SignInButton />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Redirect to='/' />
  );
};

export default Login;
