import React from "react";

const NewsDateSvg = ({
  Date
}) => {
  return (
    <svg width="78px" height="24px" viewBox="0 0 78 24" >
        <title>040DBCA6-52C4-40DE-9370-F5ED123441A6</title>
        <g id="Volofin-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="C.-Assets-C.1.5-status" transform="translate(-1051.000000, -299.000000)" fill="#2ABF43">
                <g id="Icon-/-Tag-/-Pipleline" transform="translate(1051.000000, 299.000000)">
                    <path d="M72.008986,0.5 L5.99101397,0.5 C4.47565371,0.5 3.10272147,1.11525863 2.10872225,2.10980372 C1.11503123,3.10404046 0.5,4.47737567 0.5,5.99430406 L0.5,18.0056959 C0.5,19.5230052 1.11568485,20.8964495 2.11105635,21.8906034 C3.10672526,22.8850543 4.48228006,23.5 6.00164591,23.5 L72.0060755,23.5 C73.5196536,23.5 74.893984,22.88383 75.889527,21.888224 C76.8835014,20.8941867 77.5,19.5216966 77.5,18.0056959 L77.5,5.99430406 C77.5,4.47689339 76.8854323,3.1033173 75.891744,2.10908324 C74.8980793,1.11487288 73.5253144,0.5 72.008986,0.5 Z" id="Rectangle-2-Copy-3" stroke="#2ABF43" fillOpacity="0.4" opacity="0.5"></path>
                    <text id="Pipeline" fontFamily="Roboto-Regular, Roboto" fontSize="13" fontWeight="normal" linespacing="12">
                        <tspan x="8" y="16">{Date}</tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default NewsDateSvg;