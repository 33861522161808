import _ from "lodash";
import moment from "moment";
import React from "react";
import { Grid, ExpansionPanelDetails, Typography } from "@material-ui/core";
import * as Constants from "../../../../constants";
import { makeStyles } from "@material-ui/core/styles";
import * as utils from "../../../../utils/utils";

const useStyles = makeStyles({
  mainTextColor: {
    color: "#023059",
  },
});

const EventDetails = ({ detailType, data = [] }) => {
  const classes = useStyles();

  const mxPayment = () => {
    let fundedBy = data.fromCashReserve + data.fromLessorContribution;
    return (
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          {/* <Grid item sm={6} >
            <Typography color="secondary" align="left" style={{fontSize: 12, fontFamily: "Roboto"}}>
              SERIAL NUMBER
            </Typography>
            <Typography className={`${classes.mainTextColor}`} align="left" style={{fontSize: 14, fontFamily: "Roboto"}}>
              {data.serialNumber}
            </Typography>
          </Grid> */}
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              COMP SERIAL NO
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {data.componentSerialNumber}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              EVENT DATE
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {moment.utc(data.date).format(Constants.DATE_FORMAT)}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              COMPONENT NAME
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {data.name}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              COST
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {data.mxCost < 0
                ? utils.formatNumber(data.mxCost * -1, "($", ")", 0)
                : utils.formatNumber(data.mxCost, "$", null, 0)}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              FUNDED BY LESSOR
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {fundedBy < 0
                ? utils.formatNumber(fundedBy * -1, "($", ")", 0)
                : utils.formatNumber(fundedBy, "$", null, 0)}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    );
  };

  const EOLComp = () => {
    let netcomp =
      data.lesseeToLessor + data.lessorToLessee + data.cashReserveToLessee;
    return (
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          {/* <Grid item sm={6} >
            <Typography color="secondary" align="left" style={{fontSize: 12, fontFamily: "Roboto"}}>
              SERIAL NUMBER
            </Typography>
            <Typography className={`${classes.mainTextColor}`} align="left" style={{fontSize: 14, fontFamily: "Roboto"}}>
              {data.serialNumber}
            </Typography>
          </Grid> */}
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              COMP SERIAL NO
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {data.componentSerialNumber}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              EVENT DATE
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {moment.utc(data.date).format(Constants.DATE_FORMAT)}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              COMPONENT NAME
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {data.name}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              NET COMP
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {netcomp < 0
                ? utils.formatNumber(netcomp * -1, "($", ")", 0)
                : utils.formatNumber(netcomp, "$", null, 0)}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              {"Life %"}
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {`${_.round(data.lifePct * 100, 2)}%`}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              {"HL Adj"}
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {data.hlAdj < 0
                ? utils.formatNumber(data.hlAdj * -1, "($", ")", 0)
                : utils.formatNumber(data.hlAdj, "$", null, 0)}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    );
  };

  const sale = () => {
    return (
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          {/* <Grid item sm={6} >
            <Typography color="secondary" align="left" style={{fontSize: 12, fontFamily: "Roboto"}}>
              SERIAL NUMBER
            </Typography>
            <Typography className={`${classes.mainTextColor}`} align="left" style={{fontSize: 14, fontFamily: "Roboto"}}>
              {data.serialNumber}
            </Typography>
          </Grid> */}
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              EVENT DATE
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {moment.utc(data.date).format(Constants.DATE_FORMAT)}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              SALE VALUE
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {!data.saleValue
                ? Constants.___
                : data.saleValue < 0
                ? utils.formatNumber(data.saleValue * -1, "($", ")", 0)
                : utils.formatNumber(data.saleValue, "$", null, 0)}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    );
  };

  const convEvent = () => {
    return (
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          {/* <Grid item sm={6} >
            <Typography color="secondary" align="left" style={{fontSize: 12, fontFamily: "Roboto"}}>
              SERIAL NUMBER
            </Typography>
            <Typography className={`${classes.mainTextColor}`} align="left" style={{fontSize: 14, fontFamily: "Roboto"}}>
              {data.serialNumber}
            </Typography>
          </Grid> */}
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              EVENT START DATE
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {moment.utc(data.date).format(Constants.DATE_FORMAT)}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              EVENT END DATE
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {moment.utc(data.endDate).format(Constants.DATE_FORMAT)}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              color='secondary'
              align='left'
              style={{ fontSize: 12, fontFamily: "Roboto" }}>
              Cost
            </Typography>
            <Typography
              className={`${classes.mainTextColor}`}
              align='left'
              style={{ fontSize: 14, fontFamily: "Roboto" }}>
              {!data.cost
                ? Constants.___
                : data.cost < 0
                ? utils.formatNumber(data.cost * -1, "($", ")", 0)
                : utils.formatNumber(data.cost, "$", null, 0)}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    );
  };

  return detailType === "mxPayment" ? (
    mxPayment()
  ) : detailType === "EOLComp" ? (
    EOLComp()
  ) : detailType === "sale" ? (
    sale()
  ) : detailType === "conv" ? (
    convEvent()
  ) : (
    <></>
  );
};

export default EventDetails;
