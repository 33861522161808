import _ from "lodash";
import React, { useState } from "react";
import {
  Box,
  IconButton,
  Button,
  Tooltip,
  Fade,
  Modal,
  Backdrop,
  Typography,
} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: { maxWidth: "100%", margin: "auto" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  threeDotButton: { display: "inline-flex", float: "right" },
});

const RenderComment = ({ rowData, llp = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const comments = {
    intervalComments: [],
    costComments: [],
    downtimeComments: [],
  };
  const llpComments = {
    cutOffComment: null,
    replaceCyclesComment: null,
  };
  var checkComment = false;

  if (llp) {
    for (const [colName, cmt] of Object.entries(rowData)) {
      if (colName.includes("Comment") && cmt) {
        llpComments[colName] = cmt;
        checkComment = true;
      }
    }
  } else {
    for (const [colName, cmt] of Object.entries(rowData)) {
      if (colName.includes("Comment")) {
        if (colName.toLowerCase().includes("interval") && cmt) {
          comments.intervalComments.push([colName, cmt]);
          checkComment = true;
        } else if (colName.toLowerCase().includes("cost") && cmt) {
          comments.costComments.push([colName, cmt]);
          checkComment = true;
        } else if (colName.toLowerCase().includes("down") && cmt) {
          comments.downtimeComments.push([colName, cmt]);
          checkComment = true;
        }
      }
    }
  }

  return checkComment ? (
    <Box key={`${rowData.component}_${rowData.id}`}>
      <Tooltip title='' placement='right'>
        <IconButton onClick={() => setOpen(true)}>
          <CommentIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        className={classes.modal}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}>
        <Fade in={open}>
          <Box
            style={{
              width: 800,
              margin: "0 auto",
              backgroundColor: "#fff",
              padding: 20,
              borderRadius: "5px",
            }}>
            <Box style={{ margin: 10 }}>
              {llpComments.cutOffComment ? (
                <Box>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: "rgba(0,0,0,0.87)",
                      fontSize: 16,
                    }}>
                    Cutoff Override Comments
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      fontSize: 14,
                      margin: 10,
                    }}>
                    {llpComments.cutOffComment}
                  </Typography>
                </Box>
              ) : null}
              {llpComments.replaceCyclesComment ? (
                <Box>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: "rgba(0,0,0,0.87)",
                      fontSize: 16,
                    }}>
                    Replace Cycles Override Comments
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      fontSize: 14,
                      margin: 10,
                    }}>
                    {llpComments.replaceCyclesComment}
                  </Typography>
                </Box>
              ) : null}
              {!_.isEmpty(comments.intervalComments) ? (
                <Box>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: "rgba(0,0,0,0.87)",
                      fontSize: 16,
                    }}>
                    Interval Override Comments
                  </Typography>
                  <Box
                    style={{
                      fontSize: 14,
                      margin: 10,
                    }}>
                    {comments.intervalComments.map((cmt, idx) => (
                      <Box key={idx}>
                        <b>{cmt[0].replace("Comment", "").toUpperCase()} : </b>{" "}
                        {cmt[1]}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
              {!_.isEmpty(comments.costComments) ? (
                <Box>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: "rgba(0,0,0,0.87)",
                      fontSize: 16,
                    }}>
                    Cost Override Comments
                  </Typography>
                  <Box
                    style={{
                      fontSize: 14,
                      margin: 10,
                    }}>
                    {comments.costComments.map((cmt, idx) => (
                      <Box key={idx}>
                        <b>{cmt[0].replace("Comment", "").toUpperCase()} : </b>{" "}
                        {cmt[1]}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
              {!_.isEmpty(comments.downtimeComments) ? (
                <Box>
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: "rgba(0,0,0,0.87)",
                      fontSize: 16,
                    }}>
                    Downtime Override Comments
                  </Typography>
                  <Box
                    style={{
                      fontSize: 14,
                      margin: 10,
                    }}>
                    {comments.downtimeComments.map((cmt, idx) => (
                      <Box key={idx}>
                        <b>{cmt[0].replace("Comment", "").toUpperCase()} : </b>{" "}
                        {cmt[1]}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Button
              style={{ float: "right", margin: "0 16px 10px 0" }}
              variant='outlined'
              onClick={() => setOpen(false)}>
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  ) : null;
};

export default RenderComment;
