import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import editableTables from "../common";

export default function mapToViewModel_AirframeCheck(data, props) {
  const maintenanceStatus = data.maintenanceStatus[0];

  return {
    statusSinceNew: {
      label: "Status Since New",
      caption: (data) => moment.utc(data.asOfDate).format("MMM-DD-YYYY"),
      tableName: constants.statusSinceNew,
      tableType: constants.EDITABLE_TABLE,
      props,
      errorsOnSubmit: {},
      showOptions: true,
      columns: [
        {
          label: ["FLIGHT HOURS"],
          field: "flightHours_sinceNew",
          captions: ["Since New"],
          span: [6, 4],
          editable: true,
          required: false,
          type: constants.NUMERIC,
          inputFieldType: constants.TEXT_FIELD,
          content: (value) =>
            utils.renderCellValue(value, null, constants.NUMERIC_CELL),
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          label: ["FLIGHT CYCLES"],
          field: "flightCycles_sinceNew",
          captions: ["Since New"],
          span: [6, 4],
          editable: true,
          required: false,
          type: constants.NUMERIC,
          inputFieldType: constants.TEXT_FIELD,
          content: (value) =>
            utils.renderCellValue(value, null, constants.NUMERIC_CELL),
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          label: ["FLIGHT DAYS"],
          field: "days_sinceNew",
          captions: ["Since New"],
          span: [6, 4],
          editable: true,
          required: false,
          type: constants.NUMERIC,
          inputFieldType: constants.TEXT_FIELD,
          content: (value) =>
            utils.renderCellValue(value, null, constants.NUMERIC_CELL),
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          label: [constants.AS_OF_DATE],
          field: "asOfDate",
          hidden: true,
          editable: true,
          required: true,
          type: constants.DATE,
          inputFieldType: constants.DATE,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
      ],
      data: {
        flightHours_sinceNew: maintenanceStatus.sinceNew.flightHour,
        flightCycles_sinceNew: maintenanceStatus.sinceNew.flightCycle,
        days_sinceNew: maintenanceStatus.sinceNew.day,
        asOfDate: maintenanceStatus.statusAsOf,
      },
    },
    checkStatus: {
      label: "Check Status",
      caption: (data) => moment.utc(data.asOfDate).format("MMM-DD-YYYY"),
      tableName: constants.checkStatus,
      tableType: constants.EDITABLE_TABLE,
      props,
      errorsOnSubmit: {},
      showOptions: true,
      columns: [
        {
          label: ["FLIGHT HOURS"],
          field: "flightHours_sinceEvent",
          captions: ["Since last check"],
          span: [6, 4],
          editable: true,
          required: false,
          type: constants.NUMERIC,
          inputFieldType: constants.TEXT_FIELD,
          content: (value) =>
            utils.renderCellValue(value, null, constants.NUMERIC_CELL),
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          label: ["FLIGHT CYCLES"],
          field: "flightCycles_sinceEvent",
          captions: ["Since last check"],
          span: [6, 4],
          editable: true,
          required: false,
          type: constants.NUMERIC,
          inputFieldType: constants.TEXT_FIELD,
          content: (value) =>
            utils.renderCellValue(value, null, constants.NUMERIC_CELL),
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          label: ["FLIGHT DAYS"],
          field: "days_sinceEvent",
          captions: ["Since last check"],
          span: [6, 4],
          editable: true,
          required: false,
          type: constants.NUMERIC,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.NUMERIC_CELL),
        },
        {
          label: [constants.AS_OF_DATE],
          field: "asOfDate",
          hidden: true,
          editable: true,
          required: true,
          type: constants.DATE,
          inputFieldType: constants.DATE,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(rowData.asOfDate, null, constants.DATE_CELL),
        },
      ],
      data: {
        flightHours_sinceEvent:
          maintenanceStatus.sinceEvent.flightHour &&
          _.round(maintenanceStatus.sinceEvent.flightHour),
        flightCycles_sinceEvent:
          maintenanceStatus.sinceEvent.flightCycle &&
          _.round(maintenanceStatus.sinceEvent.flightCycle),
        days_sinceEvent:
          maintenanceStatus.sinceEvent.day &&
          _.round(maintenanceStatus.sinceEvent.day),
        asOfDate: maintenanceStatus.statusAsOf || new Date(),
      },
    },
    shopVisitHistory: editableTables.shopVisitHistory(
      data.shopVisitHistory,
      props
    ),
    scheduledShopVisit: editableTables.scheduledShopVisit(
      data.maintenanceEvents,
      props
    ),
  };
}
