import _ from "lodash";
import React, { Component } from "react";
import {
  Box,
  Table,
  TableBody,
  IconButton,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";
import { Check, Clear, DeleteForever, Edit } from "@material-ui/icons";
import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel";

const styles = {
  headerStyle: {
    color: "#263238",
    textAlign: "center",
    padding: 0,
    height: 30,
    fontSize: 12,
    fontWeight: 600,
    borderTop: "unset",
  },
  iconButton: {
    height: 48,
    width: 48,
  },
};

// isEditingSection are two code pollution.
// They come from the component: Utilization.jsx and Lease.jsx.
class DetailPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detailPanel: null,
      renderData: null,
      props: null,
      tableName: null,
      editing: false,
      openPanel: false,
      deleting: false,
    };
  }

  componentDidMount = () => {
    const { detailPanel, renderData, props, tableName, isDisplayMode } =
      this.props;
    this.setState({
      detailPanel: detailPanel,
      renderData: renderData,
      props: props,
      tableName: tableName,
      editing: !isDisplayMode,
    });
  };

  componentWillReceiveProps(nextProps) {
    const { isDisplayMode } = nextProps;
    this.setState({
      editing: !isDisplayMode,
    });
  }

  handleDeleteRow = (props, tableName, renderData) => {
    props.funcs.handleRowDelete(
      props.section,
      props.sectionIdx,
      tableName,
      renderData
    );
  };

  render() {
    const {
      detailPanel,
      renderData,
      props,
      tableName,
      editing,
      openPanel,
      deleting,
    } = this.state;

    return (
      <Box style={{ width: "100%", padding: "10px 50px 0 50px" }}>
        {detailPanel && detailPanel.shouldRender ? (
          <>
            <Table size='small' aria-label='purchases'>
              <TableBody>
                <TableRow style={{ backgroundColor: "#E5E8EB" }}>
                  {detailPanel.columns.map((col, i) => (
                    <TableCell
                      variant='head'
                      key={i}
                      style={styles.headerStyle}>
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {detailPanel.columns.map((col, i) => (
                    <TableCell
                      key={i}
                      style={col.cellStyle}
                      width={
                        editing && !deleting
                          ? `${col.width}%`
                          : `${col.width + 2}%`
                      }>
                      {col.content(renderData[col.field])}
                    </TableCell>
                  ))}
                  {detailPanel.inPanelEditing && editing && !deleting ? (
                    <TableCell width='10%' style={{ padding: "0 16px" }}>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}>
                        <IconButton
                          style={styles.iconButton}
                          onClick={() => {
                            this.setState({ openPanel: true });
                          }}>
                          <Edit style={{ color: "black" }} />
                        </IconButton>
                        <IconButton
                          style={styles.iconButton}
                          onClick={() => {
                            this.setState({ deleting: true });
                          }}>
                          <DeleteForever
                            color='secondary'
                            style={{ marginRight: -10 }}
                          />
                        </IconButton>
                      </span>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  {deleting ? (
                    <TableCell width='10%'>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <IconButton
                          onClick={() => {
                            this.handleDeleteRow(props, tableName, renderData);
                          }}>
                          <Check />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            this.setState({ deleting: false });
                          }}>
                          <Clear style={{ marginRight: -10 }} />
                        </IconButton>
                      </span>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableBody>
            </Table>
            <MultiFieldsFadeInEditingPanel
              open={openPanel}
              columns={detailPanel.columns}
              data={renderData}
              tableName={tableName}
              props={props}
              onClose={() => this.setState({ openPanel: false })}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
    );
  }
}

export default DetailPanel;
