import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import InputFieldComponent from "../EditingComponents/InputFieldComponent";
import Root from "../../../Hoc/Root";
import * as constants from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  multiFields: {
    width: 1080,
    padding: 30,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  buttons: {
    padding: "30px 0px 0px 69px",
    textAlign: "right",
  },
  cancelText: {
    height: 16,
    width: 80,
    color: "rgba(0,0,0,0.38)",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "2px",
    lineHeight: "16px",
    textAlign: "left",
  },
  submitText: {
    height: 16,
    width: 80,
    color: "#0D47A1",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
  submitTextDisabled: {
    color: "rgba(0,0,0,0.38)",
  },
  submitButton: {
    width: 104,
    height: 36,
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: 4,
    backgroundColor: "rgba(98,2,238,0)",
  },
}));

const LeaseRentEditingPanel = (
  { section, sectionIdx, tableName, onClose, onValuesUpdate, tableData },
  ref
) => {
  const classes = useStyles();

  const template = {
    fromDate: { label: "From Date", value: null, type: constants.DATE },
    endDate: { label: "End Date", value: null, type: constants.DATE },
    paymentDate: {
      label: "Payment Date",
      value: "inAdvance",
      type: constants.SELECT,
      items: [
        { label: "In Advance", value: "inAdvance" },
        { label: "Rear", value: "rear" },
      ],
    },
    amount: { label: "Amount", value: null, type: constants.DOLLARNUMBER },
    paymentFrequency: {
      label: "Frequency",
      value: constants.Monthly,
      type: constants.SELECT,
      items: [
        { label: constants.Monthly, value: constants.Monthly },
        { label: constants.Quarterly, value: constants.Quarterly },
      ],
    },
    pbh: {
      label: "PBH",
      value: "notPBH",
      type: constants.SELECT,
      items: [
        { label: "Yes", value: "isPBH" },
        { label: "No", value: "notPBH" },
      ],
    },
  };

  if (tableName === "extensionRent") {
    template.selectExtension = {
      label: "Extension",
      value: 0,
      type: constants.SELECT,
      items: tableData.selectExtension,
    };
  }

  const [error, setError] = useState(null);
  const [values, setValues] = useState([{ ...template }]);

  const handleAdd = () => {
    let newValues = [...values];
    newValues.push({ ...template });
    setValues(newValues);
  };

  const handleChange = (key, idx, value) => {
    let newValues = [...values];
    newValues[idx] = { ...newValues[idx] };
    newValues[idx][key].value = value;
    setValues(newValues);
  };

  const handleRemove = () => {
    if (values.length === 1) return;
    let newValues = [...values];
    newValues.pop();
    setValues(newValues);
  };

  const validateDates = () => {
    let dates = values.map((value) => [
      value.fromDate.value,
      value.endDate.value,
      value.paymentFrequency.value,
    ]);

    for (var i = 0; i < dates.length; i++) {
      if (moment.utc(dates[i][0]).diff(moment.utc(dates[i][1]), "days") >= 0) {
        return false;
      }
    }

    return true;
  };

  const checkOverlap = () => {
    let dateIntervals = values.map((value) => [
      value.fromDate.value,
      value.endDate.value,
    ]);
    // dateIntervals = _.sortBy(dateIntervals, [(e) => e[0]]);
    dateIntervals = dateIntervals.sort(function compare(a, b) {
      var dateA = new Date(a[0]);
      var dateB = new Date(b[0]);
      return dateA - dateB;
    });

    for (var i = 1; i < dateIntervals.length; i++) {
      const interval1 = dateIntervals[i - 1];
      const interval2 = dateIntervals[i];

      if (moment.utc(interval2[0]).diff(moment.utc(interval1[1]), "days") < 0)
        return true;
    }

    return false;
  };

  const expandIntervals = () => {
    let returnMe = [];
    for (var i = 0; i < values.length; i++) {
      const interval = values[i];
      const paymentFrequency =
        values[i].paymentFrequency.value === constants.Quarterly ? 3 : 1;

      let from = interval.fromDate.value;
      const to = interval.endDate.value;

      while (moment.utc(to).diff(from, "month") >= paymentFrequency) {
        let addMe = {};
        let end = moment
          .utc(from)
          .add(paymentFrequency, "month")
          .add(-1, "days");

        let paymentDate = null;
        if (interval.paymentDate.value === "inAdvance") {
          paymentDate = moment.utc(from);
        } else {
          paymentDate = end;
        }

        addMe.from = from;
        addMe.to = end.format(constants.ISOFormat);
        addMe.paymentDate = paymentDate.format(constants.ISOFormat);

        if (interval.pbh.value === "isPBH") {
          addMe.amount = null;
          addMe.pbhRate = interval.amount.value;
        } else {
          addMe.amount = interval.amount.value;
          addMe.pbhRate = null;
        }
        if (tableName === "extensionRent")
          addMe.selectExtension = interval.selectExtension.value;
        returnMe.push(addMe);
        from = moment
          .utc(from)
          .add(paymentFrequency, "month")
          .format(constants.ISOFormat);
      }

      if (
        (moment.utc(to).diff(from, "days") < 31 &&
          moment.utc(to).diff(from, "days") > 1) ||
        (moment.utc(to).diff(from, "month") < paymentFrequency &&
          moment.utc(to).diff(from, "month") > 0)
      ) {
        let paymentDate = null;
        let addMe = {};
        if (interval.paymentDate.value === "inAdvance") {
          paymentDate = from;
        } else {
          paymentDate = to;
        }

        addMe.from = from;
        addMe.to = to;
        addMe.paymentDate = paymentDate;
        if (interval.pbh.value === "isPBH") {
          addMe.amount = null;
          addMe.pbhRate = interval.amount.value;
        } else {
          addMe.amount = interval.amount.value;
          addMe.pbhRate = null;
        }
        if (tableName === "extensionRent")
          addMe.selectExtension = interval.selectExtension.value;

        returnMe.push(addMe);
      }
    }
    return returnMe;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateDates()) {
      setError("Invalid data");
      return;
    }
    if (checkOverlap()) {
      setError("Overlapping intervals");
      return;
    }

    onValuesUpdate("leasePanelUpdate")(
      section,
      sectionIdx,
      tableName,
      expandIntervals()
    );
    setError(null);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.multiFields}>
        <Grid container alignItems={"flex-end"} spacing={2}>
          <Grid item md={12}>
            <Box style={{ color: "red" }}>{error && `* ${error}`}</Box>
          </Grid>
          {values.map((value, idx) => {
            return (
              <Grid
                item
                md={12}
                key={idx}
                style={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  item
                  sm={1}
                  style={{ margin: "auto 0", textAlign: "center" }}>
                  {idx + 1}
                </Box>
                {Object.keys(value).map((key) => (
                  <Box
                    key={`${key}${idx}`}
                    item
                    sm={2}
                    style={
                      value[key].type === constants.SELECT
                        ? { marginTop: -20, marginRight: 5 }
                        : { width: 150 }
                    }>
                    <InputFieldComponent
                      label={value[key].label}
                      value={value[key].value}
                      type={value[key].type}
                      onChange={(value) => handleChange(key, idx, value)}
                      items={value[key].items}
                      // required={true}
                      fullWidth={true}
                      // inputProps={inputProps}
                      // keyboardButtonProps={keyboardButtonProps}
                      // errorOnSubmit={errorOnSubmit}
                    />
                  </Box>
                ))}
              </Grid>
            );
          })}
        </Grid>
        <Grid container>
          <Grid item style={{ margin: "auto" }}>
            <IconButton children={<Add />} onClick={handleAdd} />
            <IconButton
              children={<Remove />}
              onClick={handleRemove}
              disabled={values.length === 1}
            />
          </Grid>
        </Grid>

        <Box className={classes.buttons}>
          <Button onClick={onClose}>
            <span className={classes.cancelText}>CANCEL</span>
          </Button>
          <Button
            className={classes.submitButton}
            variant='outlined'
            type='submit'>
            <span className={classes.submitText}>SAVE</span>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

const forwardEditableTable = React.forwardRef(LeaseRentEditingPanel);
export default forwardEditableTable;
