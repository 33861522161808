import _ from "lodash";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";

export function mapToViewModel_eventsOfDefault(loanSummary, props) {
  let columns = [
    {
      label: ["Brief Description (Clause reference)"],
      field: "briefDescription_ClauseReference",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
  ];

  let data = {
    briefDescription_ClauseReference: loanSummary.eventsOfDefault.briefDescription_ClauseReference,
  };

  loanSummary.eventsOfDefault.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  });

  return {
    label: "Events of Default",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Events of Default",
    columns: columns,
    data: data,
  };
}
