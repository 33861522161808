import React from "react";
import { Box, Grid } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

import * as Constants from "../../../../constants";
import DataTable from "../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../Common/Tables/EditableTable/EditableTable";
import MultiFunctionTable from "../../../Common/Tables/MultiFunctionTable/MultiFunctionTable";

// const useStyles = makeStyles((theme) => ({
//   sectionLabel: {
//     marginTop: 32,
//     marginBottom: 16,
//   },
//   subtitle2: {
//     color: "rgba(0,0,0,0.87)",
//     fontSize: 14,
//     fontWeight: 500,
//     letterSpacing: "0.1px",
//     lineHeight: "24px",
//   },
// }));

const FleetSummary = ({ data }) => {
  const renderTable = (
    data,
    span = [],
    setDisplayStlye = true,
    showPagination = false
  ) => {
    const display = { display: "grid" };

    let table = null;
    if (data.tableType === Constants.DATA_TABLE) {
      table = <DataTable tableData={data} />;
    } else if (data.tableType === Constants.EDITABLE_TABLE) {
      table = <EditableTable table={data} />;
    } else if (data.tableType === Constants.MULTIFUNCTION_TABLE) {
      table = (
        <MultiFunctionTable tableData={data} showPagination={showPagination} />
      );
    }
    return (
      <Grid
        style={setDisplayStlye ? display : null}
        item
        sm={span[0]}
        lg={span[1]}>
        {table}
      </Grid>
    );
  };

  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        {renderTable(data.fleetInfo, [12, 12], false, true)}
      </Grid>
    </Box>
  );
};

export default FleetSummary;
