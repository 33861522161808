import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  mainContentWidth: { primary: "95%" },
  palette: {
    // primary: { main: "#161616" }, //main: '#203764' },
    // primary: { main: "#1655a1" },
    primary: {
      main: "#355A79",
      background: {
        main: "#FFFFFF",
        hover: "rgba(22,85,161,0.05)",
        expanded: "rgba(22,85,161,0.05)",
      },
    },
    secondary: { main: "#7E95AA", background: { main: "rgba(0, 0, 0, 0.03)" } },
  },
  status: {
    danger: "orange",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      // 'Seravek',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTablePagination: {
      // Name of the rule
      spacer: {
        // Some CSS
        flex: "auto",
      },
    },
  },
});
