import _ from "lodash";
import * as constants from "../../../../constants";

export const updateData = (
  actionType,
  leaseIdx,
  newData,
  scenario,
  componentName
) => {
  const template = {
    cashReserveDrawableType: "Balance",
    isSweepWhenDraw: false,
    fundingPriority: ["CashReserve", "CreditReserve", "LessorContribution"],
    lessorExposureLimit: null,
    lessorExposureType: "None",
    llpReplaceCostType: "None",
    maintenanceNumber: 1,
  };
  const templatetwo = {
    cashReserveDrawableType: "Balance",
    isSweepWhenDraw: false,
    fundingPriority: ["CashReserve", "CreditReserve", "LessorContribution"],
    lessorExposureLimit: null,
    lessorExposureType: "None",
    llpReplaceCostType: "None",
    maintenanceNumber: 2,
  };
  const DeliveryUsageWithDeliveryCost = "DeliveryUsageWithDeliveryCost";
  const DeliveryUsageWithEventCost = "DeliveryUsageWithEventCost";
  const Shortfall = "Shortfall";
  const ApportionedAllocationShortfall = "ApportionedAllocationShortfall";

  const llpExposures = [
    ApportionedAllocationShortfall,
    Shortfall,
    DeliveryUsageWithDeliveryCost,
    DeliveryUsageWithEventCost,
    constants.NONE,
  ];

  scenario.leases = [...scenario.leases];
  scenario.leases[leaseIdx] = { ...scenario.leases[leaseIdx] };
  scenario.leases[leaseIdx].componentLeaseInfo = [
    ...scenario.leases[leaseIdx].componentLeaseInfo,
  ];

  const targetLeaseInfoIdx = _.findIndex(
    scenario.leases[leaseIdx].componentLeaseInfo,
    (o) => o.componentName === componentName
  );

  const targetLeaseInfo = {
    ...scenario.leases[leaseIdx].componentLeaseInfo[targetLeaseInfoIdx],
  };

  let fundingPolicies = [...targetLeaseInfo.fundingPolicies];

  let policyIndex = _.findIndex(
    fundingPolicies,
    (o) => o.maintenanceNumber === 1
  );
  let policyIndexTwo = _.findIndex(
    fundingPolicies,
    (o) => o.maintenanceNumber === 2
  );

  fundingPolicies[policyIndex] = { ...fundingPolicies[policyIndex] };

  if (actionType === constants.DELETE_ROW) {
    fundingPolicies[policyIndex] = template;
  } else {
    if (componentName.includes("LLP")) {
      if (!llpExposures.includes(newData.lessorExposureType)) {
        alert("cannot have llp exposure type of " + newData.lessorExposureType);
        return scenario;
      }
    }
    template.id = newData.id;
    template.cashReserveDrawableType = newData.cashReserveDrawableType;
    template.lessorExposureLimit =
      newData.lessorExposureLimit && parseInt(newData.lessorExposureLimit);
    template.lessorExposureType = newData.lessorExposureType;
    template.isSweepWhenDraw = newData.isSweepWhenDraw;
    fundingPolicies[policyIndex] = template;

    templatetwo.id = newData.id;
    templatetwo.cashReserveDrawableType = newData.cashReserveDrawableType;
    templatetwo.lessorExposureLimit =
      newData.lessorExposureLimit && parseInt(newData.lessorExposureLimit);
    templatetwo.lessorExposureType = componentName.includes("LLP") ? newData.lessorExposureType : "None";
    templatetwo.isSweepWhenDraw = newData.isSweepWhenDraw;
    fundingPolicies[policyIndexTwo] = templatetwo;
  }

  targetLeaseInfo.fundingPolicies = fundingPolicies;
  scenario.leases[leaseIdx].componentLeaseInfo[targetLeaseInfoIdx] =
    targetLeaseInfo;

  return scenario;
};
