import _ from "lodash";
import * as constants from "../../../../constants";

export function suppRentRedeliveryUpdater(actionType, sectionName, sectionIdx, newData, leaseSummary) {
  const section = leaseSummary[sectionName].supRentatRedelivery
  const rowData = section.dataTable
  
  if (sectionIdx === "footNotes") {
    newData = newData.footNote
  } else {
    newData = {
      id: newData.id,
      component: newData.component,
      rate: newData.rate,
    }
  };

  if (actionType === constants.ADD_ROW) {
    rowData.push(newData);
  } else if (actionType === constants.DELETE_ROW) {
    if (sectionIdx === "footNotes") {
      section.footNotes = null;
    } else {
      section.dataTable = rowData.filter((row, idx) => idx !== newData.id);
    }
  } else {
    if (sectionIdx === "footNotes") {
      section.footNotes = newData;
    } else {
      rowData[newData.id] = { ...rowData[newData.id] };
      rowData[newData.id] = newData;
    }
  };

  return leaseSummary
}
