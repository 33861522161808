import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import { styles } from "../index";

export function mapToViewModel_aircraftsDetails(loanSummary, props, threeDotBtnOptions) {
  let data = [];
  const loanData = loanSummary.collateral.aircraftsDetails
  if(loanData.dataTable) {
    data = loanData.dataTable.map((e, idx) => {
      return {
        id: idx,
        // asset: e.asset,
        aircraft: e.aircraft,
        msn: e.msn,
        regMark: e.regMark,
        yearOfManu: e.yearOfManu,
        engines: e.engines,
        eSNs: e.eSNs,
        mortgage: e.mortgage,
        idera: e.idera,
        deregPoA: e.deregPoA,
      };
    })
  };

  const {
    section,
    funcs: { handleValuesUpdate, validateData },
  } = props

  const footNoteData = {
    footNotes: loanData.footNotes,
    tableName:  "Asset Details",
    props: { 
      section: section,
      sectionIdx: "footNotes",
      funcs: { handleValuesUpdate, validateData } 
    },
  };

  return {
    label: "Asset Details",
    caption: loanData.footNotes,
    props,
    threeDotBtnOptions: threeDotBtnOptions("Asset Details", footNoteData),
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: "Asset Details",
    errorsOnSubmit: {},
    columns: [
      // {
      //   title: "Asset",
      //   field: "asset",
      //   type: constants.STRING,
      //   inputFieldType: constants.TEXT,
      //   editable: constants.ALWAYS,
      //   cellStyle: styles.cellStyle,
      //   required: false,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      //   render: (rowData) =>
      //     utils.renderCellValue(rowData.asset, null),
      // },
      {
        title: "Asset",
        field: "aircraft",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.aircraft, null),
      },
      {
        title: "Serial Number",
        field: "msn",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.msn, null),
      },
      {
        title: "Reg Mark",
        field: "regMark",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.regMark, null),
      },
      {
        title: "Year of Manufacture",
        field: "yearOfManu",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.yearOfManu, null),
      },
      {
        title: "Engines",
        field: "engines",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.engines, null),
      },
      {
        title: "ESNs",
        field: "eSNs",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.eSNs, null),
      },
      {
        title: "Mortgage",
        field: "mortgage",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.mortgage, null),
      },
      {
        title: "IDERA",
        field: "idera",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.idera, null),
      },
      {
        title: "Dereg PoA",
        field: "deregPoA",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.deregPoA, null),
      },
    ],
    data: data,
  };
}
