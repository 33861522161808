import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
} from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Assets from "../Assets/Assets";
import PoolSummary from "./PoolSummary/PoolSummary";
import MultiFieldsFadeInEditingPanel from "../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import ThreeDotButton from "../../../Common/ThreeDotButton";
import VfAlertDialogBox from "../../../Common/UtilComponents/VfAlertDialogBox";
import VfSnackerBar from "../../../Common/UtilComponents/VfSnackerBar";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import ApiRoutes from "../../../../utils/ApiRoutes";
import { useMsal } from "@azure/msal-react";
import { mapToViewModel_pool } from "../../mapToViewModels/poolViewModel";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export const useStyles = makeStyles({
  panelSummary: {
    maxHeight: 59,
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    "&:hover": {
      background: "rgba(22,85,161,0.05) !important",
    },
    minWidth: 1100,
  },
  panelDetails: {
    padding: "0px 24px 24px 16px",
    backgroundColor: "#F4F5F8",
  },

  modal: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const updatePoolinDB = async (data, userName, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.AssetPool_EditAssetPool({
        assetPoolId: data.assetPoolId,
        user: userName,
      }),
      method: "POST",
      params: { newname: data.assetPoolName },
      headers: { "Content-Type": "text/json" },
    },
    msalContext
  );
};

const deletePoolfromDB = async (assetPoolId, userName, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.AssetPool_DeleteAssetPool({
        user: userName,
        assetPoolId,
      }),
      method: "DELETE",
      headers: { "Content-Type": "text/json" },
    },
    msalContext
  );
};

const Pool = ({
  allAssets,
  assetPoolId,
  assetPool,
  airlineLogos,
  liabilityData,
  userName,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const checkedExpanded = utils.sessionGetDataHelper(
    `assetPool_panel${assetPoolId}`
  );
  const [open, setOpen] = useState(false);
  const [isAlertDialogOpen, setisAlertDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(
    checkedExpanded ? checkedExpanded : false
  );
  const [showOption, setShowOption] = useState(checkedExpanded ? true : false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const delay = 1500;

  const handleDownloadOutput = async () => {
    // let downloadObject = {
    //   _id: selectedScenario.id,
    //   input: scenario,
    //   output: report,
    // };

    const resp = await apiRequest(
      {
        url: ApiRoutes.AssetPool_Cashflows({
          assetPoolId: assetPoolId,
        }),
        method: "Get",
      },
      msalContext
    );
    if (resp.status === response.OK) {
      let downloadObject = resp.data;
      let filename = `${assetPool.assetPoolName}-output.json`;
      let contentType = "application/json;charset=utf-8;";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob(
          [
            decodeURIComponent(
              encodeURI(JSON.stringify(downloadObject, null, 4))
            ),
          ],
          { type: contentType }
        );
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var a = document.createElement("a");
        a.download = filename;
        a.href =
          "data:" +
          contentType +
          "," +
          encodeURIComponent(JSON.stringify(downloadObject, null, 4));
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      setMessage("Failed to retrieve Cashflows.");
      setsnackOpen(true);
      setSeverity("warning");
    }
  };

  const handlePoolDelete = async () => {
    try {
      const result = await deletePoolfromDB(assetPoolId, userName, msalContext);
      if (result.status === response.OK) {
        setMessage("Successfully deleted Pool.");
        setsnackOpen(true);
        setSeverity("info");
        setTimeout(() => {
          window.location.reload(true);
        }, delay);
      } else {
        setMessage("Failed to delete Pool.");
        setsnackOpen(true);
        setSeverity("warning");
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handlePoolUpdate = async (_, __, ___, values, ____) => {
    try {
      const result = await updatePoolinDB(values, userName, msalContext);
      if (result.status === response.OK) {
        setMessage("Successfully Edited Pool.");
        setsnackOpen(true);
        setSeverity("info");
        setTimeout(() => {
          window.location.reload(true);
        }, delay);
      } else {
        setMessage("Failed to Edit Pool.");
        setsnackOpen(true);
        setSeverity("warning");
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    utils.sessionStoreHelper(
      `assetPool_${panel}`,
      isExpanded ? panel : false,
      48
    );
    if (!showOption) {
      setShowOption(true);
    } else {
      setShowOption(false);
    }
  };

  const simulatePool = async () => {
    const resp = await apiRequest(
      {
        url: ApiRoutes.AssetPool_Simulate({
          assetPoolId: assetPoolId,
        }),
        method: "POST",
      },
      msalContext
    );
    return resp;
  };

  const handlePoolSimulate = async () => {
    try {
      const result = await simulatePool(assetPoolId, userName, msalContext);
      if (result.status === response.OK) {
        setMessage("Successfully Simulated Pool.");
        setsnackOpen(true);
        setSeverity("info");
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, delay);
      } else {
        setMessage("Failed to Simulate Pool.");
        setsnackOpen(true);
        setSeverity("warning");
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const bgUnexpanded = "#FFFFFF";
  const bgExpanded = "rgba(22,85,161,0.05)";
  const props = {
    funcs: {
      validateData: () => true,
      handleRowDelete: handlePoolDelete,
      handleValuesUpdate: handlePoolUpdate,
    },
  };
  const assetPoolModel = mapToViewModel_pool(assetPool);
  const threeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
      handleClick: () => setOpen(true),
    },
    {
      id: 1,
      icon: DeleteOutline,
      label: constants.DELETE,
      handleClick: () => setisAlertDialogOpen(true),
    },
    {
      id: 2,
      label: "Download Cashflows",
      handleClick: () => handleDownloadOutput(),
    },
    {
      id: 3,
      label: "Simulate All",
      handleClick: () => handlePoolSimulate(),
    },
  ];

  return (
    <Box>
      <VfSnackerBar
        snackOpen={snackOpen}
        setsnackOpen={setsnackOpen}
        severity={severity}
        message={message}
      />
      <VfAlertDialogBox
        isOpen={isAlertDialogOpen}
        handleAlertDialogVisible={setisAlertDialogOpen}
        dialogTitle={"Confirm Delete Pool"}
        dialogBody={"Are you sure you would like to delete this assetPool?"}
        onAccept={handlePoolDelete}
      />
      <Box flexGrow={1} mb={1}>
        <Box style={{ maxWidth: "100%" }}>
          <ExpansionPanel
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel${assetPoolId}`}
            onChange={handleChange(`panel${assetPoolId}`)}
            elevation={0}>
            <ExpansionPanelSummary
              aria-controls={`panel${assetPoolId}bh-content`}
              id={`panel${assetPoolId}bh-header`}
              className={classes.panelSummary}
              style={{
                background:
                  expanded === `panel${assetPoolId}`
                    ? bgExpanded
                    : bgUnexpanded,
              }}>
              <PoolSummary
                assetPool={assetPool}
                assetPoolId={assetPoolId}
                airlineLogos={airlineLogos}
                liabilityData={liabilityData}
              />
              <Box style={{ marginLeft:10, zIndex: 200, width: 20 }}>
                <FormControlLabel
                  style={{ margin: "12px 0 0 0", width: 40 }}
                  aria-label='threedotbutton'
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    showOption ? (
                      <ThreeDotButton
                        fontSize={"small"}
                        options={threeDotBtnOptions}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </Box>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
              classes={{ root: classes.panelDetailsRoot }}
              className={classes.panelDetails}>
              <Assets
                user={userName}
                assetPool={assetPool}
                assetPoolAssets={assetPool.assetPool_Assets}
                assetPoolPastAssets={assetPool.assetPool_PastAssets}
                assetPoolFutureAssets={assetPool.assetPool_FutureAssets}
                assetPoolArchivedAssets={assetPool.assetPool_ArchivedAssets}
                allAssets={allAssets}
                airlineLogos={airlineLogos}
                threeDotBtnOptions={threeDotBtnOptions}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>

        {/* <Box style={{ margin: "19px 0", right: -10 }}>
          {showOption ? (
            <ThreeDotButton
              fontSize={"small"}
              padding={0}
              options={threeDotBtnOptions}
            />
          ) : null}
        </Box> */}
        <MultiFieldsFadeInEditingPanel
          open={open}
          columns={assetPoolModel.columns}
          data={assetPoolModel.data}
          props={props}
          onClose={() => setOpen(false)}
          tableName={"Edit Pool"}
        />
      </Box>
    </Box>
  );
};

Pool.propTypes = {
  allAssets: PropTypes.array,
  assetPool: PropTypes.object,
  assetPoolId: PropTypes.number,
};

export default Pool;
