import React, { useState, useEffect } from "react";
import { TableRow, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MultiFunctionTable from "./MultiFunctionTable";
import MultiFunctionTableCell from "./MultiFunctionTableCell";
import * as constants from "../../../../constants";

import BookMark from "../../SvgComponents/BookMark";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      background: "rgba(22,85,161,0.05) !important",
    },
  },
}));

const MultiFunctionTableRow = ({ columnHeaders, row }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);

  useEffect(() => {
    return () => setOpen(false);
  }, [row]);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const renderTableRows = (row, key) => {
    return (
      <TableRow key={key} className={classes.root}>
        {columnHeaders.map((header, idx) =>
          header.hidden && header.hidden.length !== 0 ? null : (
            <MultiFunctionTableCell
              key={`${header.field}_${idx}`}
              align={header.align}
              color={row.style && row.style.color}
              bgColor={
                open
                  ? constants.HIDDEN_ROW_BG_COLOR
                  : row.style && row.style.bgColor
              }
              fontWeight={row.style && row.style.fontWeight}
              height={row.style && row.style.height}
              zIndex={row.style && row.style.zIndex}
              showSideBar={open && idx === 0}>
              {header.content ? (
                header.contentType === constants.BUTTON ? (
                  <div
                    style={
                      header.align === "right"
                        ? { paddingRight: header.indent }
                        : {
                            display: "flex",
                            justifyContent: "flex-start",
                            verticalAlign: "inherit",
                          }
                    }>
                    {header.content(
                      row.hidden && row.hidden.length !== 0,
                      row[header.field],
                      handleClick,
                      header.indent,
                      header.textAlign,
                      open
                    )}

                    {row.type && row.type.includes("EngineSwap") ? (
                      <span style={{ verticalAlign: "inherit" }}>
                        <BookMark value={"S"} color={"#1f9f04"} />
                      </span>
                    ) : null}

                    {row.hidden &&
                    row.hidden.length !== 0 &&
                    header.clickableCellIcon ? (
                      <span style={{ verticalAlign: "inherit" }}>
                        <span style={{ marginTop: -5 }}>
                          {header.clickableCellIcon(
                            row.hidden.data
                              ? row.hidden.clickableCellIconValue
                              : row.hidden.length
                          )}
                        </span>
                      </span>
                    ) : null}
                  </div>
                ) : (
                  header.content(row[header.field], header.indent)
                )
              ) : (
                row[header.field]
              )}
            </MultiFunctionTableCell>
          )
        )}
      </TableRow>
    );
  };

  return (
    <>
      {renderTableRows(row, 0)}
      <Fade in={open}>
        {open && row.hidden ? (
          !Array.isArray(row.hidden) ? (
            <TableRow>
              <MultiFunctionTableCell
                showSideBar={true}
                colSpan={columnHeaders.length}>
                <MultiFunctionTable
                  tableData={row.hidden}
                  // bgcolor="#D5E5F2"
                  containerMaxHeight={480}
                />
              </MultiFunctionTableCell>
            </TableRow>
          ) : row.hidden.length !== 0 ? (
            row.hidden.map((row, index) => renderTableRows(row, index))
          ) : (
            setOpen(false)
          )
        ) : (
          <TableRow />
        )}
      </Fade>
    </>
  );
};

export default MultiFunctionTableRow;
