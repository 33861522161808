import React, { useState } from "react";
import { Box, Grid, Tabs, Tab } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import FleetSummary from "./AssetsComponents/FleetSummary";
import LeaseSummary from "./AssetsComponents/LeaseSummary";
import CashFlowAssetValueChart from "./AssetsComponents/PTAssetValueChart";
import AssetCashFlowTimeSeriesChart from "./AssetsComponents/PTAssetCashFlowTimeSeries";
import moment from "moment";

const PortfolioAssetsSection = ({
  fleetSummary,
  leaseSummary,
  report,
  appraisalError,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  if(fleetSummary !== null && fleetSummary !== undefined)
      fleetSummary[0].data.fleetInfo.caption = "Simulation Date: " + moment.utc(report.simDate).format("MMM-DD-YYYY");

  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        {fleetSummary ? (
          <>
            <Grid item xs={12} sm={12} md={12}>
              {fleetSummary.map((item, idx) => (
                <FleetSummary key={idx} data={item.data} />
              ))}
            </Grid>
            {!appraisalError ? (
              <>
                {" "}
                <Grid item sm={12}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor='primary'
                    textColor='primary'
                    justify='end'>
                    <Tab key='assetValue' label='Value Over Time' />
                    <Tab key='assetCashflow' label='Cashflow Time Series' />
                  </Tabs>
                </Grid>
                {tabValue === 0 ? (
                  <Grid item xs={12}>
                    <CashFlowAssetValueChart report={report} />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <AssetCashFlowTimeSeriesChart report={report} />
                  </Grid>
                )}
              </>
            ) : (
              <> </>
            )}{" "}
          </>
        ) : (
          <> </>
        )}

        {leaseSummary ? (
          <Grid item xs={12} sm={12} md={12}>
            {leaseSummary.map((item, idx) => (
              <LeaseSummary key={idx} data={item.data} />
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};

export default PortfolioAssetsSection;
