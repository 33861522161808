import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import { styles } from "../index";

export function mapToViewModel_mxReserveRate(leaseSummary, props, threeDotBtnOptions) {
  let data = [];
  const leaseData = leaseSummary.mxReserve_LessorContrib.mxReserveRate
  
  if(leaseData.dataTable) {
    data = leaseData.dataTable.map((e, idx) => {
      return {
        id: idx,
        component: e.component,
        rate: e.rate,
        freq: e.freq,
        asOfDate: e.asOfDate,
        escalation: e.escalation,
        currentRate: e.currentRate,
        currentRateFreq: e.currentRateFreq,
        currentRateAsOfDate: e.currentRateAsOfDate,
      };
    })
  };

  const {
    section,
    funcs: { handleValuesUpdate, validateData },
  } = props

  const footNoteData = {
    footNotes: leaseData.footNotes,
    tableName:  "Maintenance Reserve Rates",
    props: { 
      section: section,
      sectionIdx: "footNotes",
      funcs: { handleValuesUpdate, validateData } 
    },
  };

  return {
    label: "Maintenance Reserve Rates",
    caption: leaseData.footNotes,
    props,
    threeDotBtnOptions: threeDotBtnOptions("Maintenance Reserve Rates", footNoteData),
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: "Maintenance Reserve Rates",
    errorsOnSubmit: {},
    columns: [
      {
        title: "Component",
        field: "component",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.component, null),
      },
      {
        title: "Rate",
        field: "rate",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        hidden: props.section === constants.apUs,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.rate, null, constants.NUMERIC_CELL, "$"),
      },
      {
        title: "Freq",
        field: "freq",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.freq, null),
      },
      {
        title: "As of Date",
        field: "asOfDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.asOfDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Escalation",
        field: "escalation",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.escalation, null),
      },
      {
        title: "Current Rate",
        field: "currentRate",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        hidden: props.section === constants.apUs,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.currentRate, null, constants.NUMERIC_CELL, "$"),
      },
      {
        title: "Current Rate Freq",
        field: "currentRateFreq",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.currentRateFreq, null),
      },
      {
        title: "Current Rate As of Date",
        field: "currentRateAsOfDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.currentRateAsOfDate).format(constants.DATE_FORMAT),
      },
    ],
    data: data,
  };
}
