import React from "react";
import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import { styles } from "../common";

export default function mapToViewModel_Utilization(data, props) {
  const m = {
    [constants.AIRFRAME_CHECKS]: "Airframe",
    // [constants.ENGINES]: "Engine",
    [constants.apUs]: "APU",
    [constants.LANDING_GEARS]: "Landing Gears",
  };
  data.utilizationByDate = _.sortBy(data.utilizationByDate,"startDate");

  let keys = Object.entries(props.allShopVisitEventNames);
  let items = _.flatten(
    keys.map((key) => {
      if (key[0] === constants.ENGINES) {
        let engineSNs = props.allShopVisitEventNames[key[0]].filter(engine => !engine.checkName.includes("LLP") )
        return engineSNs.map((sn) => ({
          label: sn.checkName,
          value: sn.checkName,
        }));
      } else {
        return {
          label: key[0] === constants.apUs ? m[key[0]].toUpperCase() : m[key[0]],
          value: m[key[0]],
        };
      }
    })
  );

  const components = {
    label: null,
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: constants.utilizationSelectedComponents,
    props,
    style: { boxShadow: "unset", backgroundColor: "#E5E8EB" },
    errorsOnSubmit: {},
    columns: [
      {
        label: ["Components"],
        field: "components",
        span: [12, 12],
        required: true,
        editable: true,
        items,
        showCellOptions: true,
        type: constants.CHECK_BOX,
        inputFieldType: constants.CHECK_BOX,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (values) => (
          <div>
            <br />
            {values.map((value) => (
              <div key={value} style={{ lineHeight: "normal" }}>
                {value === constants.APU ? value.toUpperCase() : value}
              </div>
            ))}
          </div>
        ),
      },
    ],
    data: {
      components: data.components.map((e) => {
        if (e.includes("Engine")) {
          let returnMe = _.find(
            props.allShopVisitEventNames.engines,
            (o) => o.checkName === e
          )?.value;
          if(returnMe == null)
            return e;
          returnMe = returnMe.replace("EPR", "Engine");
          return returnMe;
        } else return e;
      }),
    },
  };

  const utilization = {
    label: null,
    caption: null,
    tableName: constants.utilizationByDate,
    tableType: constants.EDITABLE_TABLE,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    style: { boxShadow: "unset", padding: "6px 16px" },
    columns: [
      {
        title: "From Date",
        field: "startDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.startDate).format(constants.DATE_FORMAT),
      },
      {
        title: "To Date",
        field: "endDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.endDate).format(constants.DATE_FORMAT),
      },
      {
        title: "Daily FH",
        field: "flightHour",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Daily FC",
        field: "flightCycle",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Daily APUH",
        field: "activeHour",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
    ],
    data: data.utilizationByDate.map((e, index) => {
      return {
        id: index,
        startDate: e.startDate,
        endDate: e.endDate,
        flightHour: _.round(e.utilization.flightHour, 2),
        flightCycle: _.round(e.utilization.flightCycle, 2),
        activeHour: _.round(e.utilization.activeHour, 2),
      };
    }),
  };

  return {
    components,
    utilization,
  };
}
