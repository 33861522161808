import React from "react";
import { Box, Grid } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import * as Constants from "../../../../constants";
import DataTable from "../../../Common/Tables/DataTable/DataTable";
import EditableTable from "../../../Common/Tables/EditableTable/EditableTable";
import MultiFunctionTable from "../../../Common/Tables/MultiFunctionTable/MultiFunctionTable";

const LeaseSummary = ({ data }) => {
  const renderTable = (
    data,
    span = [],
    setDisplayStlye = true,
    showPagination = false
  ) => {
    const display = { display: "grid" };

    let table = null;
    if (data.tableType === Constants.DATA_TABLE) {
      table = <DataTable tableData={data} />;
    } else if (data.tableType === Constants.EDITABLE_TABLE) {
      table = <EditableTable table={data} />;
    } else if (data.tableType === Constants.MULTIFUNCTION_TABLE) {
      table = (
        <MultiFunctionTable tableData={data} showPagination={showPagination} />
      );
    }

    return (
      <Grid
        style={setDisplayStlye ? display : null}
        item
        sm={span[0]}
        lg={span[1]}>
        {table}
      </Grid>
    );
  };

  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        {data.leaseInfo.data[0] ? (
          renderTable(data.leaseInfo, [12, 12], false, true)
        ) : (
          <> </>
        )}
        {_.isEmpty(data.conversionEvents.data) ? (
          <> </>
        ) : (
          renderTable(data.conversionEvents, [12, 12], false, false)
        )}
        {_.isEmpty(data.maintenanceEvents.data) ? (
          <> </>
        ) : (
          renderTable(data.maintenanceEvents, [12, 12], false, true)
        )}
        {_.isEmpty(data.redeliveryEvents.data) ? (
          <> </>
        ) : (
          renderTable(data.redeliveryEvents, [12, 12], false, true)
        )}
        {_.isEmpty(data.cashFlows.data) ? (
          <> </>
        ) : (
          renderTable(data.cashFlows, [12, 12], false, true)
        )}
      </Grid>
    </Box>
  );
};

export default LeaseSummary;
