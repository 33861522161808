import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";

function populateConditionsData(data, checkName, id, leaseID) {
  return {
    id: id,
    leaseID: leaseID,
    mxEvent: checkName === constants.APU ? checkName.toUpperCase() : checkName,
    maxUsedFH:
      typeof data.maxUsed.flightHour === constants.STRING
        ? null
        : data.maxUsed.flightHour,
    maxUsedFC:
      typeof data.maxUsed.flightCycle === constants.STRING
        ? null
        : data.maxUsed.flightCycle,
    maxUsedAH:
      typeof data.maxUsed.activeHour === constants.STRING
        ? null
        : data.maxUsed.activeHour,
    maxUsedDays:
      typeof data.maxUsed.day === constants.STRING ? null : data.maxUsed.day,
    minLeftFH: data.minLeft.flightHour,
    minLeftFC: data.minLeft.flightCycle,
    minLeftAH: data.minLeft.activeHour,
    minLeftDays: data.minLeft.day,
    isWaived: data.isWaived,
    compensationIfWaived: data.compensationIfWaived
  };
}

export function mapToViewModel_DeliveryAndRedeliveryConditions(
  components,
  props
) {
  const mxEvents = _.flattenDeep(Object.values(props.allShopVisitEventNames));
  const columns = [
    {
      title: "Mx Event",
      field: "mxEvent",
      initialEditValue: mxEvents[0].value,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.NEVER,
      required: true,
      cellStyle: styles.cellStyle,
      items: mxEvents,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
    },
    {
      title: "Max Used FH",
      field: "maxUsedFH",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.maxUsedFH, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Max Used FC",
      field: "maxUsedFC",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.maxUsedFC, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Max Used AH",
      field: "maxUsedAH",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.maxUsedAH, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Max Used Days",
      field: "maxUsedDays",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.maxUsedDays, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Min left FH",
      field: "minLeftFH",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.minLeftFH, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Min Left FC",
      field: "minLeftFC",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.minLeftFC, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Min left AH",
      field: "minLeftAH",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.minLeftAH, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Min Left Days",
      field: "minLeftDays",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.minLeftDays, null, constants.NUMERIC, null, 2),
    },
    {
      title: "Is Waived",
      field: "isWaived",
      initialEditValue: false,
      cellStyle: styles.cellStyle,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: false,
      items: [
        { label: constants.YES, value: true },
        { label: constants.NO, value: false },
      ],
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => (rowData.isWaived ? constants.YES : constants.NO),
    },
    {
      title: "Comp If Waived",
      field: "compensationIfWaived",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.NUMBER,
      editable: constants.ALWAYS,
      cellStyle: styles.cellStyle,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      render: (rowData) => utils.renderCellValue(rowData.compensationIfWaived, null, constants.NUMERIC, null, 2),
    },
  ];

  const deliveryConditions = [];
  const redeliveryConditions = [];

  let id = 0;
  Object.keys(components).map((key) => {
    components[key].map((mxEvent) => {
      const checkName = mxEvent.checkName;
      const dc = mxEvent.componentLeaseInfo.deliveryCondition;
      const rdc = mxEvent.componentLeaseInfo.redeliveryCondition;
      const leaseID = mxEvent.componentLeaseInfo.leaseID;

      deliveryConditions.push(
        populateConditionsData(dc, checkName, id, leaseID)
      );
      redeliveryConditions.push(
        populateConditionsData(rdc, checkName, id, leaseID)
      );

      id++;
      return null;
    });
    return null;
  });

  const deliveryCondtionTableData = {
    label: "Condition Requirements at Delivery",
    editModeOptions: [constants.VIEW],
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.deliveryConditions,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns: columns,
    data: deliveryConditions,
  };

  const redeliveryCondtionTableData = {
    label: "Condition Requirements at Re-delivery",
    editModeOptions: [constants.VIEW],
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.redeliveryConditions,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns: columns,
    data: redeliveryConditions,
  };

  return [deliveryCondtionTableData, redeliveryCondtionTableData];
}
