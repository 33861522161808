import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";

export default function mapToViewModel_PartOutValues(
  partOutValues,
  runDate,
  selectedAppraisal
) {
  const valueType = selectedAppraisal.partOutValueType.toLowerCase();
  let data = {
    total:
      partOutValues[runDate].afpoValues[valueType] +
      _.sumBy(partOutValues[runDate].enginePOValues, (o) => o[valueType]),
    airframe: partOutValues[runDate].afpoValues[valueType],
  };

  let columns = [
    {
      label: ["Total"],
      field: "total",
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
    },
  ];

  if (partOutValues[runDate].afpoValues[valueType]) {
    columns.push({
      label: ["Airframe"],
      field: "airframe",
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
    })
  }

  for (var i = 0; i < partOutValues[runDate].enginePOValues.length; i++) {
    const msn = partOutValues[runDate].enginePOValues[i].serialNumber;
    data[`engine${msn}`] = partOutValues[runDate].enginePOValues[i][valueType];
    columns.push({
      label: [`Engine ${msn}`],
      field: `engine${msn}`,
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
    });
  }
  if(partOutValues[runDate].lgpoValues != null){
      data.landinggear = partOutValues[runDate].lgpoValues[valueType];
      columns.push({
        label: [`Landing Gear`],
        field: `landinggear`,
        captions: ["U.S. $"],
        span: [6, 6],
        indent: 0,
        content: (value) =>
          utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
      });
  }
  if(partOutValues[runDate].apupoValues != null){
    data.apu = partOutValues[runDate].apupoValues[valueType];
    columns.push({
      label: [`APU`],
      field: `apu`,
      captions: ["U.S. $"],
      span: [6, 6],
      indent: 0,
      content: (value) =>
        utils.renderCellValue(value, { paddingRight: 0 }, constants.NUMERIC_CELL),
    });
}

  return {
    label: "Part-Out Values",
    caption: `As of ${runDate}`,
    tableType: constants.DATA_TABLE,
    columns,
    scrollable: true,
    data,
  };
}
