// import * as constants from "../../../constants";
import { SeniorLoanDetails } from "./SeniorLoanDetails/index";
import { Collateral } from "./Collateral/index";
import { mapToViewModel_taxAndIndemnities } from "./taxIndemnities";
import { mapToViewModel_other_Legal } from "./otherLegal";
import { mapToViewModel_fees } from "./fees";
import { mapToViewModel_financialCovenants } from "./financialCovenants";
import { mapToViewModel_prepayments } from "./prepayments";
import { mapToViewModel_information_ReportingCovenants } from "./information_ReportingCovenants";
import { mapToViewModel_restrictiveCovenants } from "./restrictiveCovenants";
import { mapToViewModel_eventsOfDefault } from "./eventsOfDefault";

// import { MXReserve_LessorContrib } from "./MXReserve_LessorContrib/index";
// import { mapToViewModel_loanOptions } from "./loanOptions";
// import { RedeliveryConditions } from "./RedeliveryConditions/index";
// import { mapToViewModel_engineSubstitution } from "./engineSubstitution";
// import { mapToViewModel_airworthinessDirectives } from "./airworthinessDirectives";
// import { mapToViewModel_insurance } from "./insurance";
// import { mapToViewModel_taxAndIndemnities } from "./taxIndemnities";

export const styles = {
  cellStyle: {
    color: "#023059",
    textAlign: "center",
    fontSize: 11,
    height: 40,
    padding: 0,
    borderBottom: "unset",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  boldCellStyle: {
    fontWeight: "bold",
    fontSize: 12,
  },
};

export function mapToViewModel(
  loanSection,
  props,
  loanSummary,
  threeDotBtnOptions,
) {
  switch (loanSection) {
    case "seniorLoanDetails":
      return SeniorLoanDetails(loanSummary, props, threeDotBtnOptions);
    case "collateral":
      return Collateral(loanSummary, props, threeDotBtnOptions);
    case "taxAndIndemnities":
      return mapToViewModel_taxAndIndemnities(loanSummary, props);
    case "fees":
      return mapToViewModel_fees(loanSummary, props);
    case "financialCovenants":
      return mapToViewModel_financialCovenants(loanSummary, props);
    case "prepayments":
      return mapToViewModel_prepayments(loanSummary, props);
    case "information_ReportingCovenants":
      return mapToViewModel_information_ReportingCovenants(loanSummary, props);
    case "restrictiveCovenants":
      return mapToViewModel_restrictiveCovenants(loanSummary, props);
    case "eventsOfDefault":
      return mapToViewModel_eventsOfDefault(loanSummary, props);
    case "other_Legal":
      return mapToViewModel_other_Legal(loanSummary, props);
    default:
      break;
  }
}