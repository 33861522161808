import React from "react";
import _ from "lodash";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import { Box } from "@material-ui/core";
import BookMark from "../../../Common/SvgComponents/BookMark";

function generateColumns(props, additionalProps) {
  return [
    {
      label: "Name",
      field: "name",
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    {
      label: "Date",
      field: "date",
      type: constants.DATE,
      inputFieldType: constants.DATE,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "Author",
      field: "author",
      type: constants.STRING,
      inputFieldType: constants.STRING,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    {
      label: "Details",
      caption: null,
      field: "details",
      content: (value, indent) => (
        <Box style={{ width: "100%", display: "flex" }}>
          {utils.renderEventName(
            true,
            "Details",
            // () =>
            //   (window.location.href = `${url}&selected=Maint & Util&scenarioId=${value}`),
            () =>
              props.funcs.handleScenarioChangeEvtFromTable(
                value.id,
                value.name
              ),
            null,
            "Right",
            { margin: "0 auto" }
          )}
        </Box>
      ),
    },
    {
      label: "Delete",
      caption: null,
      field: "delete",
      content: (value, indent) => (
        <Box style={{ width: "100%" }}>
          {utils.renderEventName(
            true,
            "Delete",
            () => additionalProps(value),
            null,
            "Right",
            { margin: "0 auto", color: "red" }
          )}
        </Box>
      ),
    },
  ];
}

function mapToViewModel_moreScenarioSummaries(
  scenarioSummaries,
  props,
  additionalProps
) {
  let columns = generateColumns(props, additionalProps);
  return {
    label: null,
    caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: false, pagination: false },
    optionsItems: {},
    tableName: constants.ScenarioSummaries,
    props,
    showOptions: false,
    clickableCellIconValue: scenarioSummaries.length,
    columns: columns,
    data: scenarioSummaries.map((scenario) => ({
      id: scenario.id,
      date: scenario.date,
      author: scenario.author,
      name: scenario.name,
      details: { id: scenario.id, name: scenario.name },
      delete: scenario.id,
    })),
  };
}

export default function mapToViewModel_ScenarioSummaries(
  scenarioSummaries,
  props,
  additionalProps
) {
  let groupedScenarios = _.groupBy(
    scenarioSummaries,
    (scenarios) => scenarios.name
  );

  let filteredScenarios = [];
  let remaining = {};
  for (const [key, value] of Object.entries(groupedScenarios)) {
    if (key === "Base") filteredScenarios.unshift(value[0]);
    else filteredScenarios.push(value[0]);
    remaining[key] = value.slice(1);
  }

  let columns = generateColumns(props, additionalProps);

  columns.unshift({
    label: "Show More",
    caption: null,
    field: "showMore",
    align: constants.CENTER,
    indent: 16,
    align: constants.LEFT,
    clickableCellIcon: (value) => <BookMark value={value} />,
    contentType: constants.BUTTON,
    content: (isButton, value, handleClick, indent, textAlign, isOpen) => (
      <Box style={{ width: "100%", margin: "4px 0 0 0" }}>
        {utils.renderEventName(
          isButton,
          isButton ? (isOpen ? "Less  -" : "More +") : constants.___,
          handleClick,
          indent
        )}
      </Box>
    ),
  });

  return {
    label: constants.ScenarioSummaries,
    // caption: null,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { filter: false, pagination: false },
    optionsItems: {},
    tableName: constants.ScenarioSummaries,
    props,
    showOptions: false,
    errorsOnSubmit: {},
    columns: columns,
    data: filteredScenarios.map((scenario) => ({
      id: scenario.id,
      showMore: scenario.id,
      date: scenario.date,
      author: scenario.author,
      name: scenario.name,
      details: { id: scenario.id, name: scenario.name },
      delete: scenario.id,
      hidden:
        !_.isEmpty(remaining) && !_.isEmpty(remaining[scenario.name])
          ? mapToViewModel_moreScenarioSummaries(
              remaining[scenario.name],
              props,
              additionalProps
            )
          : null,
    })),
  };
}
