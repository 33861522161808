import _ from "lodash";
import XLSX from "xlsx";
import React from "react";
import { Grid } from "@material-ui/core";
import DataTable from "../../../../../../Common/Tables/DataTable/DataTable";
import * as appraisalSummaryViewModel from "../../../../../mapToViewModels/common/appraisalSummary";
import { apiRequest, response } from "../../../../../../../utils/ApiRequest";
import apiRoutes from "../../../../../../../utils/ApiRoutes";
import UploadIconSvg from "../../../../../../Common/SvgComponents/UploadIconSvg";
import DownloadIconSvg from "../../../../../../Common/SvgComponents/DownloadIconSvg";
import ExportIconSvg from "../../../../../../Common/SvgComponents/ExportIconSvg";
import { DeleteForever } from "@material-ui/icons";

const downloadVFAppraisal = async (assetType, msalContext) => {
  const response = await apiRequest(
    {
      url:
        assetType === "SpecificAircraft"
          ? apiRoutes.Valuation_AircraftAppraisalTemplate
          : apiRoutes.Valuation_EngineAppraisalTemplate,
      method: "GET",
      responseType: "blob",
    },
    msalContext
  );

  const type = response.headers["content-type"];
  const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download =
    assetType === "SpecificAircraft"
      ? "vfAircraftAppraisalTemplate"
      : "vfEngineAppraisalTemplate";
  link.click();
};

const uploadAppraisalToDB = async (
  appraisalData,
  assetType,
  assetId,
  secondary = false,
  msalContext
) => {
  let apiEndPoint = apiRoutes.Valuation_AssetAppraisalUpload({
    assetType,
    assetId,
  });

  if (secondary)
    apiEndPoint = apiRoutes.Valuation_AssetSecondaryAppraisalUpload({
      assetType,
      assetId,
    });

  const resp = await apiRequest(
    {
      url: apiEndPoint,
      method: "POST",
      data: appraisalData,
    },
    msalContext
  );

  if (resp.status === response.OK) {
    setTimeout(() => {
      window.location.reload(true);
    }, 1200);
  }
};

const loadAppraisal = async (
  file,
  assetType,
  assetId,
  setsnackOpen,
  setSeverity,
  setMessage,
  secondary = false,
  msalContext
) => {
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      resolve(ws);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  promise.then(async (data) => {
    let appraisalData = new FormData();
    appraisalData.append("fileName", file.name);
    appraisalData.append("userName", msalContext.accounts[0].username);
    appraisalData.append("fileFormat", "Excel");

    if (
      data.A1 &&
      data.A13 &&
      data.A18 &&
      data.A1.v === "Valuation Details" &&
      data.A13.v === "Current Values" &&
      data.A18.v === "Future Values"
    ) {
      appraisalData.append("fileData", file);
      await uploadAppraisalToDB(
        appraisalData,
        assetType,
        assetId,
        secondary,
        msalContext
      );
    } else if (
      data.A2 &&
      data.A2.l &&
      data.A2.l.Target.includes("cirium.com")
    ) {
      const converterResp = await apiRequest(
        {
          method: "POST",
          url: `https://appraisalconverter.azurewebsites.net/api/AppraisalConverter?assetType=${assetType}`,
          data: file,
          responseType: "blob",
        },
        msalContext,
        true
      );

      if (converterResp.status === response.OK) {
        let convertedAppraisal = converterResp.data;
        let convertedFile = new File([convertedAppraisal], file.name);
        appraisalData.append("fileData", convertedFile);
        appraisalData.append("originFileData", file);

        await uploadAppraisalToDB(
          appraisalData,
          assetType,
          assetId,
          secondary,
          msalContext
        );
      } else if (converterResp.status === 204) {
        setMessage(
          `Appraisal for the wrong asset type was uploaded please upload ${assetType.replace(
            "Specific",
            ""
          )} appraisal only`
        );
        setsnackOpen(true);
        setSeverity("warning");
      }
    } else {
      setMessage(`The file provided does not match any accepted format.
       Please download the appraisal template, fill out the values and then upload the completed template.`);
      setsnackOpen(true);
      setSeverity("warning");
    }
  });
};

const deleteAppraisal = async (
  valuationId,
  setsnackOpen,
  setSeverity,
  setMessage,
  msalContext
) =>{
  
  let apiEndPoint = apiRoutes.Valuation_DeleteValuation({
    valuationId
  });
  const resp = await apiRequest(
    {
      url: apiEndPoint,
      method: "POST",
    },
    msalContext
  );

  if (resp.status === response.OK) {
    setMessage(
      `Appraisal `+valuationId+` Removed from Asset`
    );
    setsnackOpen(true);
    setSeverity("info");
    setTimeout(() => {
      window.location.reload(true);
    }, 1200);
  }
};

const exportCurrentAppraisal = async (
  valuationId,
  setsnackOpen,
  setSeverity,
  setMessage,
  msalContext
) => {
  let apiEndPoint = apiRoutes.Valuation_ExportCurrentAppraisal({
    valuationId,
  });

  const resp = await apiRequest(
    {
      url: apiEndPoint,
      method: "POST",
      params: { userName: msalContext.accounts[0].username },
    },
    msalContext
  );

  if (resp.status === response.OK) {
    setMessage(
      `The current appraisal was successfully exported, please head over to the export history page to download`
    );
    setsnackOpen(true);
    setSeverity("info");
  }
};
const exportCurrentAppraisalOrigin = async (
  valuationId,
  setsnackOpen,
  setSeverity,
  setMessage,
  msalContext
) => {
  let apiEndPoint = apiRoutes.Valuation_ExportCurrentAppraisalOrigin({
    valuationId,
  });

  const resp = await apiRequest(
    {
      url: apiEndPoint,
      method: "POST",
      params: { userName: msalContext.accounts[0].username },
    },
    msalContext
  );

  if (resp.status === response.OK) {
    setMessage(
      `The current appraisal was successfully exported, please head over to the export history page to download`
    );
    setsnackOpen(true);
    setSeverity("info");
  }
};


const Appraisals = ({
  assetDetails,
  generateEditableProps,
  setsnackOpen,
  setSeverity,
  setMessage,
  msalContext,
}) => {
  const assetType = !_.isEmpty(assetDetails.airframeChecks)
    ? "SpecificAircraft"
    : "SpecificEngine";
  const assetId = assetDetails.id;

  const appraisalOptionsTemplate = {
    upload: (secondary) => ({
      id: 0,
      icon: UploadIconSvg,
      label: `Upload Appraisal`,
      handleClick: (file) => {
        loadAppraisal(
          file,
          assetType,
          assetId,
          setsnackOpen,
          setSeverity,
          setMessage,
          secondary,
          msalContext
        );
      },
    }),
    download: {
      id: 1,
      icon: DownloadIconSvg,
      label: `Appraisal Tempt`,
      handleClick: () => {
        downloadVFAppraisal(assetType, msalContext);
      },
    },
  };

  const appraisalOptions = [
    appraisalOptionsTemplate.upload(false),
    appraisalOptionsTemplate.download,
  ];

  const appraisal2Options = [
    appraisalOptionsTemplate.upload(true),
    appraisalOptionsTemplate.download,
  ];

  const valuationDownloadable = assetDetails.valuation
    ? assetDetails.valuation.fileStorageId
    : null;

  const originValuationDL = assetDetails.valuation
  ? assetDetails.valuation.originFileStorageId
  : null;

  const valuation2Downloadable = assetDetails.secondaryValuation
  ? assetDetails.secondaryValuation.fileStorageId
  : null;

  if (valuationDownloadable && originValuationDL){
    appraisalOptions.push({
      id: 2,
      icon: ExportIconSvg,
      label: `Export (vf)`,
      handleClick: (file) => {
        exportCurrentAppraisal(
          assetDetails.valuation.id,
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext
        );
      },
    });
    appraisalOptions.push({
      id: 3,
      icon: ExportIconSvg,
      label: `Export (cirium)`,
      handleClick: (file) => {
        exportCurrentAppraisalOrigin(
          assetDetails.valuation.id,
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext
        );
      },
    });
    appraisalOptions.push({
      id: 4,
      icon: DeleteForever,
      label: `Remove Appraisal`,
      handleClick: () => {
        deleteAppraisal(       
          assetDetails.valuation.id, 
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext);
      }
    });
  }
  else if (valuationDownloadable){
    appraisalOptions.push({
      id: 2,
      icon: ExportIconSvg,
      label: `Export Appraisal`,
      handleClick: (file) => {
        exportCurrentAppraisal(
          assetDetails.valuation.id,
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext
        );
      },
    });
    appraisalOptions.push({
      id: 3,
      icon: DeleteForever,
      label: `Remove Appraisal`,
      handleClick: () => {
        deleteAppraisal(       
          assetDetails.valuation.id, 
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext);
      }
    });
  }
  else if (originValuationDL){
    appraisalOptions.push({
      id: 2,
      icon: ExportIconSvg,
      label: `Export (vfCopy)`,
      handleClick: (file) => {
        exportCurrentAppraisal(
          assetDetails.valuation.id,
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext
        );
      },
    });
    appraisalOptions.push({
      id: 3,
      icon: ExportIconSvg,
      label: `Export (Porfolio)`,
      handleClick: (file) => {
        exportCurrentAppraisalOrigin(
          assetDetails.valuation.id,
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext
        );
      },
    });
    appraisalOptions.push({
      id: 4,
      icon: DeleteForever,
      label: `Remove Appraisal`,
      handleClick: () => {
        deleteAppraisal(       
          assetDetails.valuation.id, 
          setsnackOpen,
          setSeverity,
          setMessage,
          msalContext);
      }
    });
  }
  if (valuation2Downloadable){
      appraisal2Options.push({
        id: 2,
        icon: ExportIconSvg,
        label: `Export Appraisal`,
        handleClick: (file) => {
          exportCurrentAppraisal(
            assetDetails.secondaryValuation.id,
            setsnackOpen,
            setSeverity,
            setMessage,
            msalContext
          );
        },
      });
      appraisal2Options.push(
      {
        id: 3,
        icon: DeleteForever,
        label: `Remove Appraisal`,
        handleClick: () => {
          deleteAppraisal(       
            assetDetails.secondaryValuation.id,
            setsnackOpen,
            setSeverity,
            setMessage,
            msalContext);
        },
      });
  } 



  return (
    <>
      <Grid item sm={12}>
        <DataTable
          tableData={appraisalSummaryViewModel.mapToViewModel_appraisalSummary(
            assetDetails,
            generateEditableProps(null, "Appraisal Summary"),
            appraisalOptions
          )}
        />
      </Grid>
      {assetType == "SpecificAircraft" ? 
      <Grid item sm={12}>
        <DataTable
          tableData={appraisalSummaryViewModel.mapToViewModel_appraisalSummary(
            assetDetails.secondaryValuation,
            generateEditableProps(null, "Secondary Appraisal"),
            appraisal2Options
          )}
        />
      </Grid>
      : <></>}
      
    </>
  );
};

export default Appraisals;
