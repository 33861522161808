import _, { constant } from "lodash";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import apiRoutes from "../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../utils/ApiRequest";
import { Box, LinearProgress } from "@material-ui/core";
import { transformData } from "./dataTransformers";
import { generateAssetDTOTemplate } from "./assetDTOTemplate";
import * as constants from "../../../constants";
import { MsalContext } from "@azure/msal-react";
import moment from "moment";

class TransitionComponent extends Component {
  static contextType = MsalContext;
  

  constructor(props) {
    super(props);
    this.state = {
      assetType: props.assetType,
      airframe: props.airframe,
      airframeChecks: props.airframeChecks,
      apu: props.apu,
      engines: props.engines,
      newAsset: null,
      lifePercent: props.lifePercent,
      statusDate: props.statusDate,
      createScenario: props.createScenario,
    };
  }

  componentDidMount = () => {
    const { engines } = this.state;
    this.setState({ engines: _.compact(engines) });
    this.constructAssetDTO();
  };
  componentDidUpdate = (prevState) => {
    if (this.state.newAsset && this.state.newAsset.id === 0) {
      this.fetchNewAsset(this.state.newAsset);
    }
  };

  getComponentModelIds = (
    airframe,
    airframeChecks,
    apu,
    engines,
    landingGears
  ) => {
    const genericComponentIds = {};
    if (!_.isEmpty(airframe)) {
      genericComponentIds[constants.Airframe] =
        airframe.airframeModel.genericComponentId;
    }

    if (!_.isEmpty(apu))
      genericComponentIds.apu = apu.apuModel.genericComponentId;

    if (airframeChecks && !_.isEmpty(airframeChecks)) {
      for (let afCheck of airframeChecks) {
        if (!(constants.AIRFRAME_CHECKS in genericComponentIds))
          genericComponentIds[constants.AIRFRAME_CHECKS] = {};
        const [checkName, genericComponentId] = afCheck.split("|");
        genericComponentIds[constants.AIRFRAME_CHECKS][checkName] =
          genericComponentId;
      }
    }

    for (let engine of engines) {
      if (!(constants.Engines in genericComponentIds))
        genericComponentIds[constants.Engines] = [];

      genericComponentIds[constants.Engines].push(engine);
    }

    if (!_.isEmpty(landingGears)) {
      for (let lg of landingGears) {
        genericComponentIds[lg.position] = [];
        genericComponentIds[lg.position].push(lg.genericComponentId);
      }
    }

    return genericComponentIds;
  };

  // fetchFleetRegistration = async (aircraftSubSeries, msn) => {
  //   const { data } = await apiRequest(
  //     {
  //       url: apiRoutes.FleetData_GetFleetRegistration({
  //         aircraftSubSeries,
  //         msn,
  //       }),
  //       method: "GET",
  //     },
  //     this.context
  //   );

  //   return data;
  // };

  fetchOHCostAndIntervals = async (
    component,
    genericComponentId,
    additionalInfo = null,
    engines = null
  ) => {
    if(engines != null)
    {
      if(engines.length > 0){
        //check if same engine type as previous engine
        let samengindex = engines.findIndex((x) => x.engineSubSeries === additionalInfo.engineModel.engineSubSeries);
        if(samengindex !== -1){
          var alreadyexists = Object.assign({},engines[samengindex]); 
          alreadyexists.serialNumber =  additionalInfo.serialNumber;
          alreadyexists.position = `Engine Position ${additionalInfo.engineModel.position}`;

          //check llps
          console.log(alreadyexists);
          return alreadyexists;
        }

      }

    }
    const { data } = await apiRequest(
      {
        url: apiRoutes.GenericComponent_GetGenericComponentById({
          genericComponentId,
        }),
        method: "GET",
      },
      this.context
    );

    const returnMe = await transformData(
      component,
      data,
      additionalInfo,
      this.context
    );

    return returnMe;
  };

  fetchNewAsset = async (dto) => {
    const { lifePercent, statusDate, createScenario,assetType } = this.state;
    const username = this.context.accounts[0].name;
    var lp = -1; var cs = false; var ao = moment().utc().format(constants.DATE_FORMAT);

    if (lifePercent !== 101 && lifePercent !== null) {
      lp = lifePercent; cs = createScenario; ao = encodeURIComponent(statusDate);
    }

    if (assetType === constants.AIRCRAFT) 
    {
      let resp = await apiRequest(
        {
          url: apiRoutes.Aircraft_PostLifeAircraftDTO({
            life: lp,
            asof: ao,
            scen: cs,
            user: username
          }),
          method: "POST",
          data: dto,
        },
        this.context
      );
      if (resp.status === response.OK) {
        dto = resp.data;
        this.setState({ newAsset: dto });
      }
    } 
    else if (assetType === constants.ENGINE)
    {
      let resp = await apiRequest(
        {
          url: apiRoutes.Engine_PostLifeEngineDTO({
            life: lp,
            asof: ao,
            scen: cs,
            user: username
          }),
          method: "POST",
          data: dto.engines[0],
        },
        this.context
      );
      if (resp.status === response.OK) {
        dto.engines = [resp.data];
        dto.id = resp.data.id;
        this.setState({ newAsset: dto });
      }
    }
    else if (assetType === constants.APU){
      let resp = await apiRequest(
        {
          url: apiRoutes.APU_PostLifeAPUDTO({
            life: lp,
            asof: ao,
            scen: cs,
            user: username
          }),
          method: "POST",
          data: dto.apUs[0],
        },
        this.context
      );
      if (resp.status === response.OK) {
        dto.apUs[0] = [resp.data];
        dto.id = resp.data.id;
        this.setState({ newAsset: dto });
      }
    }
  };

  singleAssetBreadCrumb = (subseries, serialNumber) => {
    const assetInfo = _.template(`<%= subseries %> (<%= serialNumber %>)`);

    return [
      {
        label: "Assets",
        type: constants.LINK,
        tooltip: "Back to Assets",
        props: { to: "/assets" },
      },
      {
        label: assetInfo({ subseries, serialNumber }),
        type: constants.TEXT,
      },
    ];
  };
  constructAssetDTO = async () => {
    const { airframe, airframeChecks, apu, engines } = this.state;

    let landingGear = null;
    let genericComponentIds = {};
    if (!_.isEmpty(airframe)) {
      try {
        landingGear = await apiRequest(
          {
            url: apiRoutes.GenericLandingGear_GetGenericLandingGearByACId({
              ACId: airframe.airframeModel.id,
            }),
            method: "GET",
          },
          this.context
        );

        if (landingGear.status === response.OK) landingGear = landingGear.data;
      } catch (ex) {}
    }

    genericComponentIds = this.getComponentModelIds(
      airframe,
      airframeChecks,
      apu,
      engines,
      landingGear || null
    );

    const assetDTOTemplate = generateAssetDTOTemplate();
    for (let key in genericComponentIds) {
      switch (key) {
        case constants.Airframe:
          const { airframeModel, serialNumber } = airframe;
          assetDTOTemplate.msn = serialNumber;
          assetDTOTemplate.FGID = airframe.FGID
          assetDTOTemplate.aircraftSeries = airframeModel.aircraftSeries;
          assetDTOTemplate.aircraftSubSeries = airframeModel.aircraftSubSeries;

           continue;
        case constants.APU:
          if (!_.isEmpty(apu)) {
            const id = genericComponentIds[constants.APU];
            assetDTOTemplate.apUs.push(
              await this.fetchOHCostAndIntervals(constants.APU, id, apu)
            );
          }
          continue;
        case constants.Engines:
          if (!_.isEmpty(...engines)) {
            for (let item of genericComponentIds[key]) {
              const engine = _.find(
                engines,
                (o) =>
                  o.serialNumber === item.serialNumber &&
                  o.engineModel.engineSubSeries ===
                    item.engineModel.engineSubSeries &&
                  o.engineModel.position === item.engineModel.position
              );

              assetDTOTemplate.engines.push(
                await this.fetchOHCostAndIntervals(
                  key,
                  item.engineModel.genericComponentId,
                  engine,
                  assetDTOTemplate.engines
                )
              );
            }
          }
          continue;
        case constants.AIRFRAME_CHECKS:
          const airframeChecks = genericComponentIds[key];
          for (let checkName in airframeChecks) {
            assetDTOTemplate.airframeChecks.push(
              await this.fetchOHCostAndIntervals(
                checkName,
                airframeChecks[checkName]
              )
            );
          }
          continue;
        default:
          for (let id of genericComponentIds[key]) {
            assetDTOTemplate.landingGears.push(
              await this.fetchOHCostAndIntervals(key, id)
            );
          }
      }
    }

    this.setState({ newAsset: assetDTOTemplate });
  };

  render() {
    const { airframe, engines,apu, newAsset,assetType } = this.state;

    let breadCrumbs = [];
    let serialNumber = null;
    // let subseries = null;
    let bcassetType = null;
    if (assetType === constants.AIRCRAFT) {
      breadCrumbs = this.singleAssetBreadCrumb(
        airframe.airframeModel.aircraftSubSeries,
        airframe.serialNumber
      );
      bcassetType = "Aircraft";
      serialNumber = airframe.serialNumber;
      // subseries = airframe.airframeModel.aircraftSubSeries;
    } else if (assetType === constant.ENGINE) {
      breadCrumbs = this.singleAssetBreadCrumb(
        engines[0].engineModel.engineSubSeries,
        engines[0].serialNumber
      );
      bcassetType = "Engine";
      serialNumber = engines[0].serialNumber;
      // subseries = engines[0].engineModel.engineSubSeries;
    }else if (assetType === constants.APU){
      breadCrumbs = this.singleAssetBreadCrumb(
        apu.apuModel.model,
        apu.serialNumber
      );
      bcassetType = "APU";
      serialNumber = apu.serialNumber;
    }

    return newAsset && newAsset.id !== 0 ? (
      <Redirect
        to={{
          pathname: `/assets/assetDetails/${newAsset.id}?assetType=${bcassetType}&serialNumber=${serialNumber}`,
          state: {
            newAsset,
            breadcrumbs: breadCrumbs,
          },
        }}
      />
    ) : (
      <Box>
        Constructing Asset....
        <LinearProgress />
      </Box>
    );
  }
}

export default TransitionComponent;
