import _ from "lodash";
import * as constants from "../../../../constants";

export const updateData = (
  actionType,
  leaseIdx,
  newData,
  scenario,
  componentName
) => {
  const template = {
    compCap: null,
    compFloor: null,
    extraComp: 0,
    maintenanceReserveBalanceReceiver: "Lessor",
    redeliveryCompType: "None",
    redeliveryRateDateType: "Redelivery",
    redeliveryRateType: "FromLease",
    referenceRateDateType: "Redelivery",
    referenceRateType: "FromLease",
    scaleFactor: 1,
    usageType: "SinceEvent",
    refPct: null,
  };

  scenario.leases = [...scenario.leases];
  scenario.leases[leaseIdx] = { ...scenario.leases[leaseIdx] };
  scenario.leases[leaseIdx].componentLeaseInfo = [
    ...scenario.leases[leaseIdx].componentLeaseInfo,
  ];
  const targetLeaseInfoIdx = _.findIndex(
    scenario.leases[leaseIdx].componentLeaseInfo,
    (o) => o.componentName === componentName
  );

  const targetLeaseInfo = {
    ...scenario.leases[leaseIdx].componentLeaseInfo[targetLeaseInfoIdx],
  };

  let compPolicy = {
    ...targetLeaseInfo.compPolicy,
  };

  if (actionType === constants.DELETE_ROW) {
    template.leaseID = newData.leaseID;
    compPolicy = template;
  } else {
    template.id = newData.id;
    template.leaseID = newData.leaseID;
    template.compCap = newData.compCap && parseFloat(newData.compCap);
    template.compFloor = newData.compFloor && parseFloat(newData.compFloor);
    template.extraComp = newData.extraComp && parseFloat(newData.extraComp);
    template.maintenanceReserveBalanceReceiver =
      newData.maintenanceReserveBalanceReceiver;
    template.redeliveryCompType = newData.financialCompType;
    template.redeliveryRateDateType = "Redelivery";
    template.redeliveryRateType = newData.unitRate;
    template.referenceRateDateType = "Redelivery";
    template.referenceRateType = newData.referenceRateAt;
    template.scaleFactor =
      newData.compScaleFactor && parseFloat(newData.compScaleFactor);
    template.usageType = newData.comparisionUsageType;
    template.refPct = newData.financialCompType.includes("StatusToRef")
      ? newData.refPct
      : null;
    compPolicy = template;
  }

  targetLeaseInfo.compPolicy = compPolicy;
  scenario.leases[leaseIdx].componentLeaseInfo[targetLeaseInfoIdx] =
    targetLeaseInfo;

  return scenario;
};
