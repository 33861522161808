import _ from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Paper, ListItem, ListItemText } from "@material-ui/core/";
import AssetInfo from "./AssetInfoNavBar";
import apiRoutes from "../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import { useMsal } from "@azure/msal-react";
import * as constants from "../../../../constants";
import AircraftSvg from "../../../Common/SvgComponents/AircraftSvg";
import EngineSvg from "../../../Common/SvgComponents/EngineSvg";
import SideNavBarListSection from "../../../Common/NavBar/SideNavBarListSection";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 80,
    height: 80,
    top: -20,
    right: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `#023059`,
  },
  root: {
    display: "flex",
    paddingTop: 24,
    position: "sticky",
    top: 50,
  },
  infoCard: {
    minWidth: 200,
    maxWidth: 240,
    maxHeight: 190,
    backgroundColor: "#FFFFFF",
    borderRadius: "152.73px 12px 12px 12px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    width: "100%",
    padding: "unset",
    minWidth: 200,
    maxWidth: 240,
    margin: "10px 0",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4,
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
  sectionLabel: {
    color: "#023059",
    fontWeight: 600,
    fontSize: 18,
  },
  section: {
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: "white",
    "&:hover": { backgroundColor: "#E5EAEE" },
  },
}));

const SideNavBar = ({
  assetDetails,
  selected,
  navBarSectionItems,
  onSelectedChange,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const [airlineLogo, setAirlineLogo] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const result = await apiRequest(
          {
            url: apiRoutes.Blob_GetAirlineLogo({
              operator: assetDetails.operator,
            }),
            method: "GET",
          },
          msalContext
        );
        if (result.status === response.OK)
          setAirlineLogo("data:image/png;base64," + result.data);
      } catch (ex) {}
    };

    fetchLogo();
  }, [assetDetails]);

  const handleSelectedChange = (value) => {
    onSelectedChange(value);
    const selectElement = document.getElementById(value.label);
    if (selectElement) {
      selectElement.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  const { aircraftSubSeries, engines, manufactureDate, msn, registration } =
    assetDetails;

  return (
    <Box mt={2} className={classes.root}>
      <Box className={classes.drawerContainer}>
        <Paper className={classes.infoCard}>
          <AssetInfo
            generalInfo={[
              {
                icon: airlineLogo ? (
                  <Avatar
                    alt={assetDetails.operator || constants.___}
                    className={classes.avatar}
                    src={airlineLogo}
                  />
                ) : aircraftSubSeries ? (
                  <AircraftSvg height={80} width={80} viewBox='0 6 24 21' />
                ) : (
                  <EngineSvg height={80} width={80} viewBox='0 0 24 24' />
                ),
                label: aircraftSubSeries
                  ? aircraftSubSeries
                  : !_.isEmpty(engines)
                  ? engines[0].engineSubSeries
                  : null,
                caption: aircraftSubSeries
                  ? !_.isEmpty(engines)
                    ? engines[0].engineSubSeries
                    : null
                  : engines[0].serialNumber,
                style: {
                  label: {
                    right: -20,
                    textAlign: "right",
                    color: "#023059",
                    fontSize: 20,
                    fontWeight: 500,
                  },
                  caption: {
                    color: "#023059",
                    fontSize: 14,
                    fontWeight: 450,
                    opacity: 0.9,
                  },
                },
              },
            ]}
            details={
              aircraftSubSeries
                ? [
                    {
                      label: "Year",
                      value: manufactureDate
                        ? manufactureDate.split("-")[0]
                        : constants.___,
                    },
                    { label: "Serial No.", value: msn },
                    {
                      label: "Registration",
                      value: registration || constants.___,
                    },
                  ]
                : null
            }
          />
        </Paper>
        <SideNavBarListSection
          navBarSectionItems={navBarSectionItems}
          selected={selected}
          onSelectedChange={handleSelectedChange}
        />
      </Box>
    </Box>
  );
};

export default SideNavBar;
