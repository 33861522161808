import _ from "lodash";
import * as constants from "../../../constants";
import { apiRequest, response } from "../../../utils/ApiRequest";
import apiRoutes from "../../../utils/ApiRoutes";
import { async } from "q";

const postLgRunout = async (id, value, msalContext) => {
  let apiendpoint = apiRoutes.GenericAC_postLgRunout;
  apiendpoint = apiendpoint({ id, value });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
    },
    msalContext
  );
  return resp;
};

export const lgRunoutUpdater = (
  tableName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  genericData,
  msalContext
) => {
    let airframeData = genericData.airframes;
    let selectedAirframeData = _.find(airframeData, { 'id': newData.genericId });
    //newData.genericId, newData.lgRunoutValue
    postLgRunout(newData.genericId, newData.lgRunoutValue,msalContext);
    selectedAirframeData.landingGear_Runout = newData.lgRunoutValue;
    return genericData;
    //find genericData where airframes.id = newData.genericId, swap values
}