import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputFieldComponent from "./InputFieldComponent";
import * as constants from "../../../../constants";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles({
  multiFields: {
    maxWidth: 400,
    maxHeight: 550,
    overflowX: "hidden",
    overflowY: "auto",
    padding: 30,
    margin: "auto",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
  },
  buttons: {
    padding: "30px 0px 0px 69px",
    textAlign: "right",
  },
  cancelText: {
    height: 16,
    width: 80,
    color: "rgba(0,0,0,0.38)",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "2px",
    lineHeight: "16px",
    textAlign: "left",
  },
  submitText: {
    height: 16,
    width: 80,
    color: "#0D47A1",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
  submitTextDisabled: {
    color: "rgba(0,0,0,0.38)",
  },
  submitButton: {
    width: 104,
    height: 36,
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: 4,
    backgroundColor: "rgba(98,2,238,0)",
  },
});

const MultiFieldsEditComponent = (
  {
    columns,
    data,
    errorsOnSubmit,
    onClose,
    props,
    tableName,
    submitButtonText,
    onCancel,
    styles,
    reverseAccounts,
  },
  ref
) => {
  const classes = useStyles();
  const {
    sectionIdx,
    section,
    // handleSelectChange:
    // for a special case, for assetCreate -> engine -> engine llps auto-population
    funcs: {
      validateData,
      handleValuesUpdate,
      handleSelectChange,
      handleRowDelete,
    },
  } = props;

  const [values, setValues] = useState(data);

  // for a special case, for assetCreate -> engine -> engine llps auto-population
  useEffect(() => {
    if (_.isArray(data) && values) {
      let newValues = { ...values };
      for (let e of data) {
        newValues = { ...newValues, ...e };
      }
      setValues({ ...newValues });
    }
  }, [data]);

  // This handleChange function only gets called for SELECT fields.
  // The reason for making this function can be found in
  // AssetCreation/Components/AssetComponent/Engine => props.funcs.handleSeleecChange
  // This is a special case where the llp data will be retrieved once an engine is selected.
  // However, this function can be further extended when needed.
  const handleChange = (field, value, inputFieldType) => {
    setValues({ ...values, [field]: value });
    if (
      handleSelectChange &&
      (inputFieldType === constants.SELECT ||
        inputFieldType === constants.AUTO_COMPLETE ||
        inputFieldType === constants.MULTI_AUTO)
    )
      handleSelectChange(value, field);
  };

  const handleSubmit = (e) => {
    if (tableName === "Edit Deal") {
      e.preventDefault();
      props.funcs.handleDealUpdate(values);
    } else {
      e.preventDefault();
      if (!validateData(values, columns)) {
        return;
      }
      handleValuesUpdate(section, sectionIdx, tableName, values, columns);
      onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.multiFields} style={styles}>
        <Grid container spacing={4}>
          {columns.map((c, i) =>
            c.editable ? (
              <Grid
                item
                key={i}
                sm={12}
                style={{ display: "flex", justifyContent: "space-between" }}>
                <Box width={"100%"}>
                  <InputFieldComponent
                    label={c.label}
                    items={c.items}
                    value={values && values[c.field]}
                    type={c.inputFieldType}
                    valueType={c.type}
                    fullWidth={true}
                    variant={"filled"}
                    datePickervariant={"filled"}
                    onChange={(value) =>
                      handleChange(c.field, value, c.inputFieldType)
                    }
                    required={c.required}
                    keyboardButtonProps={{
                      style: { padding: "12px 0 0 0" },
                    }}
                    errorOnSubmit={errorsOnSubmit && errorsOnSubmit[c.field]}
                    multiline={c.multiline}
                    rows={c.numRows}
                    initialEditValue={c.initialEditValue}
                  />
                </Box>
                {reverseAccounts ? (
                  <IconButton
                    onClick={() => {
                      handleRowDelete(
                        section,
                        sectionIdx,
                        tableName,
                        values[c.field]
                      );
                    }}
                    style={{ verticalAlign: "center", marginRight: -30 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Grid>
            ) : null
          )}
        </Grid>
        <Box className={classes.buttons}>
          {!onCancel ? (
            <Button onClick={onClose}>
              <span className={classes.cancelText}>CANCEL</span>
            </Button>
          ) : (
            <Button onClick={onCancel}>
              <span className={classes.cancelText}>CANCEL</span>
            </Button>
          )}
          <Button
            className={classes.submitButton}
            variant='outlined'
            type='submit'>
            <span className={classes.submitText}>
              {!submitButtonText ? "SAVE" : submitButtonText}
            </span>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

const forwardedMultiFieldsEditComponent = React.forwardRef(
  MultiFieldsEditComponent
);
export default forwardedMultiFieldsEditComponent;
