import _ from "lodash";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";

export function mapToViewModel_other_Legal(loanSummary, props) {
  let columns = [
    {
      label: ["Governing Law"],
      field: "governingLaw",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Jurisdiction"],
      field: "jurisdiction",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Exclusive Jurisdiction"],
      field: "exclusiveJurisdiction",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["volofin's counsel"],
      field: "volofinCounsel",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Borrower's counsel"],
      field: "borrowerCounsel",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Joint local counsel"],
      field: "jointLocalcounsel",
      captions: [],
      editable: true,
      required: false,
      multiRowColumn: true,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
  ];

  let data = {
    governingLaw: loanSummary.other_Legal.governingLaw,
    jurisdiction: loanSummary.other_Legal.jurisdiction,
    exclusiveJurisdiction: loanSummary.other_Legal.exclusiveJurisdiction,
    volofinCounsel: loanSummary.other_Legal.volofinCounsel,
    borrowerCounsel: loanSummary.other_Legal.borrowerCounsel,
    jointLocalcounsel: loanSummary.other_Legal.jointLocalcounsel,
  };

  loanSummary.other_Legal.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  });

  return {
    label: "Other / Legal",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Other / Legal",
    columns: columns,
    data: data,
  };
}
