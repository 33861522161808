import _ from "lodash";
import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import VfAlertDialogBox from "../../../../../../Common/UtilComponents/VfAlertDialogBox";
import MultiFunctionTable from "../../../../../../Common/Tables/MultiFunctionTable/MultiFunctionTable";
import MultiFieldsFadeInEditingPanel from "../../../../../../Common/Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
// import SectionHeader from "../../../../../../Common/Section/SectionHeader";
import Lease from "./Lease";
import * as AssetDetailViewModel from "../../../../../mapToViewModels";
import * as constants from "../../../../../../../constants";
import { StaticDataContext } from "../../../../../../../context/StaticDataContext";

const formatData = (
  assetDetails,
  componentType,
  generateEditableProps,
  scenario,
  setSelectedLeaseId,
  setAddNewLease,
  setIsAlertDialogOpen,
  setDeleteData,
  lessees
) => {
  let props = generateEditableProps(null, componentType);
  props.funcs.onClick = setSelectedLeaseId;

  return AssetDetailViewModel.mapToViewModel(
    assetDetails,
    componentType,
    props,
    scenario,
    {
      setAddNewLease: setAddNewLease,
      setIsAlertDialogOpen: setIsAlertDialogOpen,
      setDeleteData: setDeleteData,
    },
    lessees
  );
};

const Leases = ({
  assetDetails,
  generateEditableProps,
  scenario,
  handleValueChange,
  currentState,
}) => {
  const [selectedLeaseId, setSelectedLeaseId] = useState(-1);
  const [addNewLease, setAddNewLease] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const { lessorData, lesseeData } = useContext(StaticDataContext);

  const table = formatData(
    assetDetails,
    constants.LEASES,
    generateEditableProps,
    scenario,
    setSelectedLeaseId,
    setAddNewLease,
    setIsAlertDialogOpen,
    setDeleteData,
    lesseeData
  );

  const leaseIndex = _.findIndex(
    scenario.leases,
    (lease) => lease.leaseId === selectedLeaseId
  );

  useEffect(() => {
    if (currentState.leaseReset) {
      setSelectedLeaseId(-1);
      currentState.leaseReset = false;
    }
  });

  const {
    section,
    sectionIdx,
    funcs: { handleRowAdd, handleRowDelete, validateData },
  } = table.leaseList.props;

  const leaseTemplate = [
    { label: "None", value: "None" },
    { label: "Generic", value: "Generic" },
  ];

  const additionalColumns = [
    {
      label: ["Lease Template"],
      field: "template",
      type: constants.STRING,
      initialEditValue: leaseTemplate[0].value,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      required: true,
      hidden: true,
      items: leaseTemplate,
    },
    {
      label: ["Engine Run"],
      field: "engineRun",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      items: [
        { label: "First Run", value: "firstRun" },
        { label: "Mature Run", value: "matureRun" },
      ],
    },
    {
      label: ["Payment Date"],
      field: "paymentDate",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      items: [
        { label: "In Advance", value: "inAdvance" },
        { label: "Rear", value: "rear" },
      ],
    },
    {
      label: ["Rent"],
      field: "rent",
      required: false,
      type: constants.NUMERIC,
      inputFieldType: constants.TEXT_FIELD,
      editable: constants.ALWAYS,
    },
    {
      label: ["PBH"],
      field: "pbhRate",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.ALWAYS,
      items: [
        { label: constants.YES, value: "isPBH" },
        { label: constants.NO, value: "notPBH" },
      ],
    },
    {
      label: ["Payment Frequency"],
      field: "paymentFrequency",
      required: false,
      type: constants.STRING,
      inputFieldType: constants.SELECT,
      editable: constants.NEVER,
      items: [
        { label: constants.Monthly, value: constants.Monthly },
        { label: constants.Quarterly, value: constants.Quarterly },
      ],
    },
  ];
  const columns = [...table.leaseList.columns, ...additionalColumns];

  return (
    <Box>
      {deleteData ? (
        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={setIsAlertDialogOpen}
          dialogTitle={`Delete ${deleteData.lessee} Lease`}
          dialogBody={"Are you sure you want to Delete this Lease?"}
          onAccept={() => {
            handleRowDelete(
              section,
              sectionIdx,
              constants.leaseList,
              deleteData
            );
            setIsAlertDialogOpen(false);
          }}
        />
      ) : null}

      {selectedLeaseId === -1 ? (
        <Box>
          <Grid container spacing={4}>
            <Grid item sm={12}>
              <MultiFunctionTable tableData={table.leaseList} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Lease
          assetDetails={assetDetails}
          generateEditableProps={generateEditableProps}
          leaseIndex={leaseIndex}
          lease={scenario.leases[leaseIndex]}
          handleValueChange={handleValueChange}
          onButtonClick={() => setSelectedLeaseId(-1)}
          scenario={scenario}
          lesseeData={lesseeData}
          lessorData={lessorData}
        />
      )}

      <MultiFieldsFadeInEditingPanel
        open={addNewLease}
        columns={columns}
        data={{
          leaseStatus: "Contractual",
          leaseType: "NewLease",
          engineRun: "matureRun",
          template: "None",
          paymentDate: "inAdvance",
          pbhRate: "notPBH",
          paymentFrequency: constants.Monthly,
        }}
        props={{
          section: constants.LEASES,
          funcs: { handleValuesUpdate: handleRowAdd, validateData },
        }}
        onClose={() => setAddNewLease(false)}
        tableName={constants.leaseList}
      />
    </Box>
  );
};

export default Leases;
