import moment from "moment";
import * as constants from "../../../constants";

const parseDate = (dateStr) => {
  if (isNaN(dateStr)) {
    //Checked for numeric
    var dt = new Date(dateStr);
    if (isNaN(dt.getTime())) {
      //Checked for date
      return dateStr; //Return string if not date.
    } else {
      return dt; //Return date **Can do further operations here.
    }
  } else {
    return dateStr; //Return string as it is number
  }
};

export const tableUpdater = (
  fieldName,
  actionType,
  sectionName,
  sectionIdx,
  newData,
  leaseSummary,
  columns
) => {
  let primaryValue = newData.primaryValue;
  let columnType = columns.type || columns[1].type;
  if (
    columnType === constants.DATE &&
    parseDate(primaryValue) instanceof Date
  ) {
    primaryValue = moment.utc(primaryValue).format(constants.DATE_FORMAT);
  }

  if (actionType === constants.UPDATE_ROW) {
    if (newData.rowIdx.includes("addRow")) {
      let rowIdx = parseInt(newData.rowIdx.replace("addRow", ""));
      const rowData = leaseSummary[sectionName].additionalRows[rowIdx];
      rowData.rowTitle = newData.rowTitle;
      rowData.primaryValue = newData.primaryValue;
      rowData.secondaryValue = newData.secondaryValue;
      rowData.footNotes = newData.footNote;
    } else {
      const rowData = leaseSummary[sectionName][fieldName];
      rowData.primaryValue = primaryValue;
      rowData.secondaryValue = newData.secondaryValue;
      rowData.footNotes = newData.footNote;
    }
  } else if (actionType === constants.ADD_ROW) {
    const sectionAddRow = leaseSummary[sectionName].additionalRows;
    sectionAddRow.push({
      rowTitle: newData.rowTitle,
      primaryValue: newData.primaryValue,
      secondaryValue: newData.secondaryValue,
      footNotes: newData.footNote,
    });
  } else if (actionType === constants.DELETE_ROW) {
    if (newData.rowIdx.includes("addRow")) {
      let rowIdx = parseInt(newData.rowIdx.replace("addRow", ""));
      const rowDatas = leaseSummary[sectionName].additionalRows;
      rowDatas.splice(rowIdx, 1);
    } else {
      const rowData = leaseSummary[sectionName][fieldName];
      rowData.primaryValue = null;
      rowData.secondaryValue = null;
      rowData.footNotes = null;
    }
  }

  return leaseSummary;
};
