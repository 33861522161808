import React from "react";

const CalendarSvg = ({
  width = 30,
  height = 30,
  viewBox = "0 0 30 30",
  color = "#023059",
  style = null,
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} style={style}>
      <title>74DB217B-DE7D-41BB-9049-F198F9D7B98D</title>
      <defs>
        <path
          d="M16,2 L15,2 L15,0 L13,0 L13,2 L5,2 L5,0 L3,0 L3,2 L2,2 C0.89,2 0.01,2.9 0.01,4 L0,18 C0,19.1 0.89,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 Z M16,18 L2,18 L2,8 L16,8 L16,18 Z M14,11 L14,16 L9,16 L9,11 L14,11 Z M16,4 L16,6 L2,6 L2,4 L16,4 Z"
          id="path-1"
        ></path>
      </defs>
      <g
        id="Volofin-Pages"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Modules---breadcrumbs"
          transform="translate(-1163.000000, -1573.000000)"
        >
          <g
            id="Icon-/-Event-/-Outlined"
            transform="translate(1163.500000, 1573.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Mask" fillRule="nonzero"></g>
            <g id="↳-Icon-Color" mask="url(#mask-2)" fill={color}>
              <g transform="translate(-3.000000, -2.000000)" id="Rectangle">
                <rect x="0" y="0" width={width} height={height} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CalendarSvg;
