import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import { styles } from "../index";

export function mapToViewModel_aircraftsDetails(loanSummary, props, threeDotBtnOptions) {
  let data = [];
  const loanData = loanSummary.seniorLoanDetails.aircraftsDetails
  if(loanData.dataTable) {
    data = loanData.dataTable.map((e, idx) => {
      return {
        id: idx,
        asset: e.asset,
        advances: e.advances,
        utilisationDate: e.utilisationDate,
        finalRepaymentDate: e.finalRepaymentDate,
        rentCommenceDate: e.rentCommenceDate,
        finalMaturityDate: e.finalMaturityDate,
      };
    })
  };

  const {
    section,
    funcs: { handleValuesUpdate, validateData },
  } = props

  const footNoteData = {
    footNotes: loanData.footNotes,
    tableName:  "Asset Details",
    props: { 
      section: section,
      sectionIdx: "footNotes",
      funcs: { handleValuesUpdate, validateData } 
    },
  };

  return {
    label: "Asset Details",
    caption: loanData.footNotes,
    props,
    threeDotBtnOptions: threeDotBtnOptions("Asset Details", footNoteData),
    showOptions: true,
    tableType: constants.EDITABLE_TABLE,
    tableName: "Asset Details",
    errorsOnSubmit: {},
    columns: [
      {
        title: "Asset",
        field: "asset",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.asset, null),
      },
      {
        title: "Advances",
        field: "advances",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.advances, null),
      },
      {
        title: "Utilisation Date",
        field: "utilisationDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.utilisationDate, null, constants.DATE_CELL),
      },
      {
        title: "Final Repayment Date",
        field: "finalRepaymentDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.finalRepaymentDate, null, constants.DATE_CELL),
      },
      {
        title: "Rent Commencement Date",
        field: "rentCommenceDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.rentCommenceDate, null, constants.DATE_CELL),
      },
      {
        title: "Final Maturity Date",
        field: "finalMaturityDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.finalMaturityDate, null, constants.DATE_CELL),
      },
    ],
    data: data,
  };
}
