import * as constants from "../../../../constants";

export const updateReserveAccountsCaps = (
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario
) => {
  const template = { capId: null, reserveIds: [], capsByDate: [] };

  scenario.leases = [...scenario.leases];

  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };

  let reserveAccountCaps = [...scenario.leases[sectionIdx].reserveAccountCaps];
  if (actionType === constants.ADD_ROW) {
    if (reserveAccountCaps.length === 0) {
      template.capId = 1
    } else {
      template.capId = reserveAccountCaps[reserveAccountCaps.length - 1].capId + 1;
    }
    reserveAccountCaps.push(template);
  } else if (actionType === constants.DELETE_ROW) {
    reserveAccountCaps.splice(newData, 1)
  }

  scenario.leases[sectionIdx].reserveAccountCaps = reserveAccountCaps;

  return scenario;
};
