import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import { styles } from "../common";
import moment from "moment";

export default function mapToViewModel_ScenarioSummaries(
  scenario,
  props,
  assetDetails
) {
  const parameters = scenario.parameters;
  var MxAdjMethods =[
    {
      label: "Cost",
      value: "Cost"
    }
  ]
  if(scenario.engineId !== null){
    MxAdjMethods.push({
      label: "EngAppraisal",
      value: "EngAppraisal"
    });
  }

  const behaviourItems =[
    {
      label: "Standard",
      value: "Standard",
    },
    {
      label: "Half-Life",
      value: "HalfLife",
    },
    { 
      label: "Full-Life", 
      value: "FullLife" 
    },
  ];
  const prLifeDenoms = [
    { 
      label: "Generic",
      value: "Generic"
    },
    {
      label: "Actual",
      value: "Actual"
    }
  ];

  let appaiserItems = [];
  let dateItems = [];
  if(assetDetails?.valuation?.appraiser != null){
    appaiserItems.push({
      label: assetDetails?.valuation?.appraiser,
      value: assetDetails?.valuation?.appraiser
    });
    if(assetDetails?.valuation?.appraiser === parameters.appraiser){
      dateItems.push({
        label: moment.utc(assetDetails?.valuation?.valuationDate).format("MMM-DD-YY"),
        value: assetDetails?.valuation?.valuationDate
    })
    }
  }
  if(assetDetails?.additionalValuations != null && assetDetails?.additionalValuations?.length > 0){
    assetDetails.additionalValuations.forEach(a => {
      if(a.appraiser === parameters.appraiser){
        dateItems.push({
          label: moment.utc(a.valuationDate).format(constants.DATE_FORMAT),
          value: a.valuationDate
        });
      }
      if(appaiserItems.some(x=> x.label === a.appraiser))
        return;
      appaiserItems.push({
        label: a.appraiser,
        value: a.appraiser
      });
    });
  }
  if(appaiserItems.count === 0){
    appaiserItems.push(    {
      label: "Ascend",
      value: "Ascend",
    });
  }
  const valuationItems = [
    {
      label: "Mx Ad. H.L.",
      value: "MxAdjHL",
    },
    { label: "Part-Out", 
      value: "PartOut",
    },
    { label: "Highest", 
      value: "Highest",
    },
  ];

  const hlItems = [
    {
      label: "Base",
      value: "Base",
    },
    {
      label: "Market",
      value: "Market",
    },
  ];

  const partOutItems = [
    {
      label: "Base",
      value: "Base",
    },
    {
      label: "Down",
      value: "Down",
    },
    {
      label: "Market",
      value: "Market",
    }
  ];
  
  return {
    label: "Simulation Parameters",
    caption: `${scenario.name} (${moment.utc(scenario.date).format(constants.DATE_FORMAT)}) ${scenario.author}`,
    tableType: constants.DATA_TABLE,
    tableName: "Simulation Parameters",
    props,
    showOptions: true,
    errorsOnSubmit: {},
    columns: [
      {
        label: ["SIM DATE OVERRIDE"],
        field: "simDate",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        items: dateItems,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
      },
      {
        label: ["APPRAISER"],
        field: "appraiser",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: appaiserItems,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["APPRAISAL DATE"],
        field: "appraisalDate",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.DATE,
        inputFieldType: constants.SELECT,
        items: dateItems,
        //value:dateItems[0].value,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
      },
      {
        label: ["MODEL BEHAVIOR"],
        field: "modelBehavior",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: behaviourItems,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["PR Life Denominator"],
        field: "prLifeDenominator",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: prLifeDenoms,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["Valuation Method"],
        field: "valuationMethod",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: valuationItems,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["MxAdjMethod"],
        field: "mxAdjMethod",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: MxAdjMethods,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["Half-Life Value Type"],
        field: "hlValueType",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: hlItems,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["Part-Out Value Type"],
        field: "ptValueType",
        captions: [],
        span: [3, 3],
        editable: true,
        required: false,
        type: constants.STRING,
        inputFieldType: constants.SELECT,
        items: partOutItems,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.TEXT_CELL),
      },
      {
        label: ["AF HMV PPD"],
        field: "afCheck_HMV_PPD",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["AF Minor PPD"],
        field: "afCheck_Minor_PPD",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["APU PPD"],
        field: "apU_PPD",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["LG PPD"],
        field: "landingGear_PPD",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["EPR PPD"],
        field: "enginePR_PPD",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["ELLP PPD"],
        field: "engineLLP_PPD",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["AF HMV RemainingDay"],
        field: "afCheck_HMV_RemainingDay",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["AF Minor RemainingDay"],
        field: "afCheck_Minor_RemainingDay",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["APU RemainingDay"],
        field: "apU_RemainingDay",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["LG RemainingDay"],
        field: "landingGear_RemainingDay",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["EPR RemainingDay"],
        field: "enginePR_RemainingDay",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["ELLP RemainingDay"],
        field: "engineLLP_RemainingDay",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      
      {
        label: ["EPR MinLife OutOfShop"],
        field: "enginePRMinLifeOutOfShop",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, null, 5),
      },
      {
        label: ["LLP Price Factor"],
        field: "llpPriceFactor",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, null, 5),
      },
      {
        label: ["UTLZ Scale Factor"],
        field: "utilizationScaleFactor",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, null, 5),
      },
      {
        label: ["INT Scale Factor"],
        field: "eprIntervalScaleFactor",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, null, 5),
      },
      {
        label: ["Sale Gap"],
        field: "saleGap",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["EOL Comp Gap"],
        field: "eolCompGap",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["Valuation Floor"],
        field: "valuationFloor",
        span: [3, 3],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: true,
        required: false,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
    ],
    data: {
      simDate : parameters.simDate ? moment.utc(parameters.simDate).format(constants.DATE_FORMAT) : null,
      appraiser: parameters.appraiser ? parameters.appraiser : null ,
      appraisalDate: parameters.appraisalDate ? parameters.appraisalDate :null,
      // dateItems.length > 0 ? dateItems[0].value : null, 
      prLifeDenominator: parameters.prLifeDenominator ? parameters.prLifeDenominator : "Actual",
      modelBehavior: parameters.modelBehavior ? parameters.modelBehavior : null,
      valuationMethod: parameters.valuationMethod ? parameters.valuationMethod : null ,
      mxAdjMethod: parameters.mxAdjMethod ? parameters.mxAdjMethod : null,
      hlValueType: parameters.hlValueType ? parameters.hlValueType : null ,
      ptValueType: parameters.ptValueType ? parameters.ptValueType : null ,
      afCheck_HMV_PPD: parameters.afCheck_HMV_PPD,
      afCheck_HMV_RemainingDay: parameters.afCheck_HMV_RemainingDay,
      afCheck_Minor_PPD: parameters.afCheck_Minor_PPD,
      afCheck_Minor_RemainingDay: parameters.afCheck_Minor_RemainingDay,
      apU_PPD: parameters.apU_PPD,
      apU_RemainingDay: parameters.apU_RemainingDay,
      engineLLP_PPD: parameters.engineLLP_PPD,
      engineLLP_RemainingDay: parameters.engineLLP_RemainingDay,
      enginePRMinLifeOutOfShop: parameters.enginePRMinLifeOutOfShop,
      enginePR_PPD: parameters.enginePR_PPD,
      enginePR_RemainingDay: parameters.enginePR_RemainingDay,
      landingGear_PPD: parameters.landingGear_PPD,
      landingGear_RemainingDay: parameters.landingGear_RemainingDay,
      llpPriceFactor: parameters.llpPriceFactor,
      scenarioName: parameters.name,
      runDate: parameters.runDate,
      saleGap: parameters.saleGap,
      eolCompGap: parameters.eolCompGap,
      valuationFloor: parameters.valuationFloor,
      utilizationScaleFactor: parameters.utilizationScaleFactor,
      eprIntervalScaleFactor: parameters.eprIntervalScaleFactor ? parameters.eprIntervalScaleFactor : 1,
    },
  };
}
