import React from "react";
import _ from "lodash";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import mapToViewModel_LLPReplacements from "./llpReplacementsViewModel";
import BookMark from "./../../../../Common/SvgComponents/BookMark";
import TableFilter from "./../../../../Common/Tables/MultiFunctionTable/Components/TableFilter";
import { Box } from "@material-ui/core";

function generateColumns() {
  return [
    {
      label: "Serial Number",
      caption: null,
      field: "serialNumber",
      filter: true,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    // Engine LLP is expandable
    {
      label: "Component",
      caption: null,
      field: "component",
      filter: false,
      align: constants.LEFT,
      indent: 16,
      contentType: constants.BUTTON,
      clickableCellIcon: (value) => <BookMark value={value} />,
      content: (isButton, value, handleClick, indent) => (
        <Box
          style={{
            width: "100%",
          }}>
          {utils.renderEventName(isButton, value, handleClick, indent)}
        </Box>
      ),
    },
    {
      label: "Start Date",
      caption: null,
      field: "startDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "End Date",
      caption: null,
      field: "endDate",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "Component Type",
      caption: null,
      field: "type",
      filter: true,
      hidden: true,
      align: constants.LEFT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingLeft: indent },
          constants.TEXT_CELL
        ),
    },
    {
      label: "Payment Date",
      caption: null,
      field: "date",
      filter: true,
      align: constants.RIGHT,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.DATE_CELL
        ),
    },
    {
      label: "Cost",
      caption: "U.S. $",
      field: "cost",
      align: constants.RIGHT,
      filter: false,
      indent: 16,
      content: (value, indent) =>
        utils.renderCellValue(
          value,
          { paddingRight: indent },
          constants.NUMERIC_CELL
        ),
    },
    {
      label: "Funded By",
      caption: "U.S. $",
      field: "fundedBy",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (items, indent) =>
        utils.renderMultiValueCellValue(items, indent, 140),
    },
    {
      label: "Since Event",
      caption: null,
      field: "sinceEvent",
      filter: false,
      align: constants.RIGHT,
      indent: 16,
      content: (items, indent) =>
        utils.renderMultiValueCellValue(items, indent, 120),
    },
  ];
}

export function generateData(events, options) {
  let data = events.map((e) => ({
    type: e.type,
    serialNumber: e.serialNumber,
    component: [e.name, e.componentSerialNumber],
    startDate: e.eventStartDate,
    endDate: e.eventEndDate,
    type: e.type,
    date: e.date,
    cost: e.mxCost && _.round(e.mxCost),
    fundedBy: [
      {
        label: "Cash R.",
        value: e.fromCashReserve && _.round(e.fromCashReserve),
      },
      {
        label: "Credit R.",
        value: e.fromCreditReserve && _.round(e.fromCreditReserve),
      },
      {
        label: "Lessor C.",
        value: e.fromLessorContribution && _.round(e.fromLessorContribution),
      },
      {
        label: "Lessee C.",
        value: e.fromLesseeContribution && _.round(e.fromLesseeContribution),
      },
    ],
    sinceEvent: e.name.includes("LLP")
      ? [
          {
            label: "FC C.",
            value: e.sinceEvent_FC_Core && _.round(e.sinceEvent_FC_Core),
          },
          {
            label: "FC",
            value: e.sinceEvent_FC && _.round(e.sinceEvent_FC),
          },
        ]
      : e.name === constants.APU_OVERHAUL
      ? [
          {
            label: "APUH",
            value: e.sinceEvent_AH && _.round(e.sinceEvent_AH),
          },
        ]
      : [
          {
            label: "MO",
            value: e.sinceEvent_Day && _.round(e.sinceEvent_Day / 30.44),
          },
          { label: "FH", value: e.sinceEvent_FH && _.round(e.sinceEvent_FH) },
          { label: "FC", value: e.sinceEvent_FC && _.round(e.sinceEvent_FC) },
        ],
    hidden: e.llpReplacements.length
      ? mapToViewModel_LLPReplacements(e.llpReplacements, options)
      : null,
  }));

  data = _.sortBy(data, "date");

  const total = {
    serialNumber: "Total",
    component: null,
    startDate: null,
    endDate: null,
    type: null,
    date: null,
    cost: _.sumBy(events, "mxCost"),
    fundedBy: null,
    sinceEvent: null,
    hidden: null,
  };

  return [data, total];
}

export default function mapToViewModel_maintenanceEvents(events, options) {
  const columns = generateColumns();
  const [data, total] = generateData(events, options);

  const tableData = {
    label: constants.MAINTENANCE_EVENTS,
    caption: null,
    options: { filter: true, pagination: true },
    optionsItems: {
      filter: {
        tableName: constants.maintenanceEvents,
        filterData: utils.generateFilterData(columns, events),
        filterList: utils.generateFilterData(columns, events),
        handleFilterUpdate: options.filter.handleFilterUpdate,
        handleFilterReset: options.filter.handleFilterReset,

        content: (columns, items) => (
          <TableFilter
            columns={columns}
            tableName={items.tableName}
            filterData={items.filterData}
            filterList={items.filterList}
            onFilterUpdate={items.handleFilterUpdate}
            onFilterReset={items.handleFilterReset}
          />
        ),
      },
      pagination: {
        page: 0,
        rowsPerPage: 10,
        tableName: constants.maintenanceEvents,
        handleChangePage: options.pagination.handleChangePage,
        handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
      },
    },
    tableType: constants.MULTIFUNCTION_TABLE,
    columns: columns,
    data,
    total,
  };

  return tableData;
}
