import React from "react";
import {
  Box,
  Grow,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import VfButton from "./VfButton";
import ThreeDotButton from "../ThreeDotButton";
import * as constants from "../../../constants";
import { Edit, Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 10px 10px 10px",
    marginTop: 10,
  },
  header: {
    fontWeight: 500,
    fontSize: 12,
    color: "#0E253F",
  },
  content: {
    minHeight: 80,
  },
  marginRight: { marginRight: 15 },
  buttonGroup: { textAlign: "right", marginTop: 9 },
}));

const VfComments = ({
  value,
  editing,
  saving,
  onCommentsClose,
  onCommentStatusChange,
  onChangeMode,
}) => {
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    onCommentStatusChange(constants.SUBMIT);
  };

  const handleCommentClose = () => {
    onCommentsClose();
    if (editing) onCommentStatusChange(constants.CANCEL);
  };

  const threeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
      handleClick: () => onChangeMode(!editing),
    },
    {
      id: 1,
      icon: Close,
      label: constants.CLOSE,
      handleClick: handleCommentClose,
    },
  ];

  return (
    <Paper className={classes.root}>
      <Box>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant={"overline"} className={classes.header}>
              REPORT NOTES
            </Typography>
          </Grid>
          <Grid item>
            {!editing ? <ThreeDotButton options={threeDotBtnOptions} /> : null}
          </Grid>
        </Grid>
      </Box>

      {!editing ? (
        <Box className={classes.content}>
          <Typography variant={"subtitle1"} paragraph={true}>
            {value}
          </Typography>
        </Box>
      ) : (
        <Box>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              multiline
              id="outlined-multiline-static"
              rows={4}
              defaultValue={value}
              variant="standard"
              onBlur={(e) =>
                onCommentStatusChange(constants.UPDATE, e.target.value)
              }
            />

            <Grow in={editing} timeout={800}>
              <Box>
                <Box className={classes.buttonGroup}>
                  <span className={classes.marginRight}>
                    <VfButton
                      label="Submit"
                      type="submit"
                      disabled={saving}
                      onButtonClick={() => null}
                    />
                  </span>
                  <span>
                    <VfButton
                      label="Cancel"
                      type={"button"}
                      disabled={saving}
                      onButtonClick={() => {
                        onCommentStatusChange(constants.CANCEL);
                      }}
                    />
                  </span>
                </Box>
              </Box>
            </Grow>
          </form>
        </Box>
      )}
    </Paper>
  );
};

export default VfComments;
