import _ from "lodash";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";

export function mapToViewModel_leaseDetails(leaseSummary, props) {
  let columns = [
    {
      label: ["Lessee"],
      field: "lessee",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lessee Full Name"],
      field: "lesseeFullName",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lease Guarantor"],
      field: "leaseGuarantor",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Sublessee"],
      field: "sublessee",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Lease Executed Date"],
      field: "leaseExecutedDate",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: ["Delivery Date"],
      field: "deliveryDate",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: ["Lease Expiration Date"],
      field: "leaseExpDate",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
    {
      label: ["Security Deposit Amount"],
      field: "securityDepAmnt",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Security Deposit Form"],
      field: "securityDepForm",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Basic Rent Amount"],
      field: "basicRentAmnt",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Supplemental Rent Type"],
      field: "supRentType",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Rent Type"],
      field: "rentType",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Floating Rate Formula"],
      field: "floatingRateFormula",
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    },
    {
      label: ["Rent Due Date"],
      field: "rentDueDate",
      captions: [],
      editable: true,
      required: false,
      type: constants.DATE,
      inputFieldType: constants.DATE,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.DATE_CELL),
    },
  ];

  let data = {
    lessee: leaseSummary.leaseDetails.lessee,
    lesseeFullName: leaseSummary.leaseDetails.lesseeFullName,
    leaseGuarantor: leaseSummary.leaseDetails.leaseGuarantor,
    sublessee: leaseSummary.leaseDetails.sublessee,
    leaseExecutedDate: leaseSummary.leaseDetails.leaseExecutedDate,
    deliveryDate: leaseSummary.leaseDetails.deliveryDate,
    leaseExpDate: leaseSummary.leaseDetails.leaseExpDate,
    securityDepAmnt: leaseSummary.leaseDetails.securityDepAmnt,
    securityDepForm: leaseSummary.leaseDetails.securityDepForm,
    basicRentAmnt: leaseSummary.leaseDetails.basicRentAmnt,
    supRentType: leaseSummary.leaseDetails.supRentType,
    rentType: leaseSummary.leaseDetails.rentType,
    floatingRateFormula: leaseSummary.leaseDetails.floatingRateFormula,
    rentDueDate: leaseSummary.leaseDetails.rentDueDate,
  };

  leaseSummary.leaseDetails.additionalRows.map((addRow, idx) => {
    columns.push({
      label: [addRow.rowTitle],
      field: `addRow${idx}`,
      captions: [],
      editable: true,
      required: false,
      type: constants.STRING,
      inputFieldType: constants.TEXT_FIELD,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        utils.renderCellValue(value, null, constants.TEXT_CELL),
    })
    data[`addRow${idx}`] = addRow
  });

  return {
    label: "Lease Details",
    showOptions: true,
    props: props,
    errorsOnSubmit: {},
    tableType: "Vertical_Table",
    tableName: "Lease Details",
    columns: columns,
    data: data,
  };
}
