import React from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ApiRoutes from "../../../../../utils/ApiRoutes";
import { apiRequest, response } from "../../../../../utils/ApiRequest";
import VfButton from "../../../../Common/UtilComponents/VfButton";
// import moment from "moment";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 10,
    padding: "0 111px 0 222px",
    height: 75,
    backgroundColor: "rgb(202, 237, 237)",
    boxShadow: "0 0.5px 2px 0 rgba(14,37,63,0.2)",
    marginBottom: 10,
    zIndex: 100,
    maxWidth: 1250,
  },
  discardButton: {
    height: 36,
  },
  buttonMargin: { marginTop: 20, marginBottom: 20 },
  submitButton: {
    height: 36,
    width: "100%",
    backgroundColor: "#1655A1",
    "&:hover": {
      backgroundColor: "#1655A1",
      opacity: 0.85,
    },
  },
  buttonLabel: {
    fontSize: 14,
    letterSpacing: "0.25px",
    lineHeight: "20px",
    textAlign: "center",
  },
  discardButtonLabel: { color: "#0D47A1" },
  submitButtonLabel: {
    color: "#F1F0F2",
  },
  bannerText: {
    margin: "25px 0",
    textAlign: "center",
    fontSize: 16,
    color: "#023059",
  },
}));

const publishLoanSummarytoDB = async (loanSummary, msalContext) => {
  return apiRequest(
    {
      url: ApiRoutes.LoanSummary_PostDTO,
      method: "POST",
      data: loanSummary,
      params: {
        dealId: loanSummary.dealId,
      },
    },
    msalContext
  );
};

const handlePublishLoanSummary = async (
  loanSummary,
  setsnackOpen,
  setSeverity,
  setMessage,
  handlePublished,
  userName,
  msalContext
) => {
  try {
    loanSummary.lastUpdatedBy = userName;
    loanSummary.lastUpdatedDate = new Date();
    const result = await publishLoanSummarytoDB(loanSummary, msalContext);
    if (result.status === response.OK) {
      handlePublished();
      setMessage("Successfully Published Loan Summary.");
      setsnackOpen(true);
      setSeverity("info");
      // setTimeout(() => {
      //   window.location.reload(true);
      // }, 500);
    } else {
      // handleScenarioLoading(false);
      setMessage("Failed to Publish Loan Summary.");
      setsnackOpen(true);
      setSeverity("warning");
    }
  } catch (ex) {
    console.log(ex);
    // handleScenarioLoading(false);
    setMessage(`${ex}`);
    setsnackOpen(true);
    setSeverity("error");
  }
};

const Banner = ({
  bannerText,
  submitButtonLabel,
  loanSummary,
  setsnackOpen,
  setSeverity,
  setMessage,
  handlePublished,
}) => {
  const classes = useStyles();
  const msalContext = useMsal();
  const { accounts } = msalContext;
  const userName = accounts[0].name;

  return (
    <Grid container className={classes.root}>
      <Grid item md={8} lg={8}>
        <Box className={classes.bannerText}>
          <span>{bannerText}</span>
        </Box>
      </Grid>
      <Grid item md={2} lg={2}>
        <Box className={classes.buttonMargin} mr={2}>
          <VfButton
            label={submitButtonLabel}
            buttonStyle={classes.submitButton}
            textStyle={`${classes.buttonLabel} ${classes.submitButtonLabel}`}
            onButtonClick={() => {
              // handleScenarioLoading(true);
              handlePublishLoanSummary(
                loanSummary,
                setsnackOpen,
                setSeverity,
                setMessage,
                handlePublished,
                userName,
                msalContext
              );
            }}
          />
        </Box>
      </Grid>
      <Grid item md={2} lg={2}>
        <Box className={classes.buttonMargin} ml={2}>
          <VfButton
            label={"Discard"}
            buttonStyle={classes.discardButton}
            textStyle={`${classes.buttonLabel} ${classes.discardButtonLabel}`}
            onButtonClick={() => window.location.reload(true)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Banner;
