import React from "react";
import { ReactComponent as Expired } from "../../../../../assets/images/dealStages/Expired.svg";
import { ReactComponent as Closed } from "../../../../../assets/images/dealStages/Closed.svg";
import { ReactComponent as Lost } from "../../../../../assets/images/dealStages/Lost.svg";
import { ReactComponent as InfoOnly } from "../../../../../assets/images/dealStages/InfoOnly.svg";
import { ReactComponent as Pipeline } from "../../../../../assets/images/dealStages/Pipeline.svg";
import { ReactComponent as Managed } from "../../../../../assets/images/dealStages/Managed.svg";
import { ReactComponent as Archive } from "../../../../../assets/images/dealStages/Archive.svg";

const DealStatus = ({ stage }) => {
  const chips = {
    pipeline: <Pipeline style={{ verticalAlign: "middle" }} />,
    lost: <Lost style={{ verticalAlign: "middle" }} />,
    expired: <Expired style={{ verticalAlign: "middle" }} />,
    infoonly: <InfoOnly style={{ verticalAlign: "middle" }} />,
    closed: <Closed style={{ verticalAlign: "middle" }} />,
    managed: <Managed style={{ verticalAlign: "middle" }} />,
    archive: <Archive style={{ verticalAlign: "middle" }} />
  };
  return chips[stage.replaceAll(" ", "").toLowerCase()] ? (
    chips[stage.replaceAll(" ", "").toLowerCase()]
  ) : (
    <span>{stage}</span>
  );
};

export default DealStatus;
