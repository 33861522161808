import _ from "lodash";
import moment from "moment";

import React from "react";
import { Box } from "@material-ui/core";
import VfDropdown from "../../../Common/UtilComponents/VfDropdown";
import CalendarSvg from "../../../Common/SvgComponents/CalendarSvg";

import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

const chooseAccountDate = (data, selectedDate) => {
  return _.filter(data, function (o) {
    return moment(o.dates).isSame(selectedDate, "day");
  });
};

export default function mapToViewModel_CashReserveAccounts(
  cashReserveAccData,
  props,
  additionalProps
) {
  let componentData = cashReserveAccData.crComponentData;
  let alldata = [];
  let columns = [
    {
      label: ["Dates"],
      field: "dates",
      hiddenData: true,
      span: [2, 2],
      captions: [],
      type: constants.DATE,
      inputFieldType: constants.DATE,
      editable: constants.ALWAYS,
      required: true,
      validate: (inputType, value, required) =>
        utils.validate(inputType, value, required),
      content: (value) =>
        value ? moment.utc(value).format(constants.DATE_FORMAT) : constants.___,
    },
  ];

  if (componentData.dates) {
    let numOfColumns = componentData.dates.length;
    for (var i = 0; i < numOfColumns; i++) {
      let dataObj = {};
      let totalValues = 0;
      for (const [key, value] of Object.entries(componentData)) {
        if (key === "dates") {
          dataObj.dates = value[i];
        } else {
          dataObj[key] = String(value.value[i]);
          totalValues += parseFloat(value.value[i]);
          let label = key;

          if (key.includes("LLP")) label = "ELLP-" + value.serialNumber;
          else if (key.includes("SupplementalEvent"))
            label = label.slice(36) + "- " + value.serialNumber;
          else if (key.includes("Engine")) label = "EPR-" + value.serialNumber;
          else if (key.includes("LG")) label = key.substring(3);

          if (i === 0)
            columns.push({
              label: [label],
              field: key,
              span: [2, 2],
              captions: ["U.S. $"],
              type: constants.NUMERIC,
              inputFieldType: constants.TEXT_FIELD,
              editable: constants.ALWAYS,
              required: true,
              validate: (inputType, value, required) =>
                utils.validate(inputType, value, required),
              content: (value) =>
                utils.renderCellValue(
                  value,
                  null,
                  constants.NUMERIC_CELL,
                  "$",
                  2
                ),
            });
        }
      }
      dataObj.id = i;
      dataObj.cashReservetotal = totalValues;
      alldata.push(dataObj);
    }
  } else {
    let dataObj = { dates: null };
    for (const [key, value] of Object.entries(componentData)) {
      dataObj[key] = null;
      let label = key;
      if (key.includes("LLP")) label = "ELLP-" + value.serialNumber;
      else if (key.includes("SupplementalEvent"))
        label = label.slice(36) + "- " + value.serialNumber;
      else if (key.includes("Engine")) label = "EPR-" + value.serialNumber;
      else if (key.includes("LG")) label = key.substring(3);

      columns.push({
        label: [label],
        field: key,
        span: [2, 2],
        captions: ["U.S. $"],
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        editable: constants.ALWAYS,
        required: true,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$", 2),
      });
    }
    alldata.push(dataObj);
  }

  columns.push({
    label: ["Total"],
    blockStyle: { margin: "0 0 0 0" },
    labelStyle: { fontSize: 16, fontWeight: "bold" },
    captionStyle: { fontSize: 10, margin: "4px 20px 0 4px" },
    field: "cashReservetotal",
    span: [12, 12],
    captions: ["U.S. $"],
    type: constants.NUMERIC,
    inputFieldType: constants.TEXT_FIELD,
    editable: false,
    required: false,
    validate: (inputType, value, required) =>
      utils.validate(inputType, value, required),
    content: (value) =>
      utils.renderCellValue(
        value,
        { fontSize: 16, fontWeight: "bold" },
        constants.NUMERIC_CELL,
        "$",
        2
      ),
  });

  let selectedDate = cashReserveAccData.selectedCRAccDate;

  let getData = chooseAccountDate(alldata, selectedDate);

  let data = !_.isEmpty(getData) ? getData[0] : {};

  return {
    label: constants.Cash_Reserve_Accounts,
    caption: (
      <Box
        style={{
          margin: "auto 0",
          width: 250,
          display: "flex",
          justifyContent: "flex-end",
          float: "right",
        }}>
        <span
          style={{ margin: "2px 20px 0 0", fontSize: 14, fontWeight: "bold" }}>
          Acc Date
        </span>
        <VfDropdown
          itemProps={{
            icon: (
              <CalendarSvg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                style={{ verticalAlign: "middle" }}
              />
            ),
          }}
          selectedItem={
            !_.isEmpty(selectedDate)
              ? moment.utc(selectedDate).format(constants.DATE_FORMAT)
              : null
          }
          items={additionalProps.cashReserveAccDatesOptions}
        />
      </Box>
    ),
    tableType: constants.DATA_TABLE,
    tableName: constants.Cash_Reserve_Accounts,
    tableThreeDotBtnOptions: additionalProps.tableThreeDotBtnOptions,
    props,
    keepEditProps: true,
    style: { width: "100%", height: "100%", overflowX: "scroll" },
    showOptions: true,
    errorsOnSubmit: {},
    columns: columns,
    data: data,
    alldata: alldata,
  };
}
