import React from "react";
import "./App.css";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import AppTheme from "./theme";
import { makeStyles } from "@material-ui/core/styles";
import { MsalProvider } from "@azure/msal-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Box } from "@material-ui/core";
import AppRoutes from "./ClientApp/Router/AppRoutes";
import { DealRelatedDataContextProvider } from "./ClientApp/context/DealRelatedDataContext";
import { StaticDataContextProvider } from "./ClientApp/context/StaticDataContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F4F6F8",
  },
  container: {
    maxWidth: 1500,
    minWidth: 1024,
    width: "95%",
    margin: "auto",
  },
}));

// {pca} is PublicClientApplication prop instantiated in index.js
const App = ({ pca }) => {
  const classes = useStyles();

  return (
    <MsalProvider instance={pca}>
      <MuiThemeProvider theme={AppTheme}>
        <DealRelatedDataContextProvider>
          <StaticDataContextProvider>
            <div className={classes.root}>
              <CssBaseline />
              <Box className={classes.container}>
                <AppRoutes />
              </Box>
            </div>
          </StaticDataContextProvider>
        </DealRelatedDataContextProvider>
      </MuiThemeProvider>
    </MsalProvider>
  );
};

export default App;
