import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../../utils/utils";
import * as constants from "../../../../../constants";

export function mapToViewModel_statuSinceNew(data, props) {
  return {
    label: "Status Since New",
    caption: (data) => moment.utc(data.asOfDate).format("MMM-DD-YYYY"),
    tableName: constants.statusSinceNew,
    tableType: constants.DATA_TABLE,
    props: props,
    errorsOnSubmit: {},
    showOptions: true,
    columns: [
      {
        label: ["TOTAL FLIGHT HOURS"],
        field: "flightHours",
        captions: ["Total Hours Since New"],
        span: [4, 4],
        editable: true,
        required: false,
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          value
            ? utils.renderCellValue(value, null, constants.NUMERIC_CELL)
            : constants.___,
      },
      {
        label: ["TOTAL FLIGHT CYCLES"],
        field: "flightCycles",
        captions: ["Total Cycles Since New"],
        span: [4, 4],
        editable: true,
        required: false,
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        validate: (inputType, value, required) =>
          value ? utils.validate(inputType, value, required) : constants.___,
        content: (value) =>
          utils.renderCellValue(value, null, constants.NUMERIC_CELL),
      },
      {
        label: ["TOTAL Months"],
        field: "months",
        captions: ["Total Months Since New"],
        span: [4, 4],
        editable: true,
        required: false,
        type: constants.NUMERIC,
        inputFieldType: constants.TEXT_FIELD,
        content: (value) =>
          value
            ? utils.renderCellValue(value, null, constants.NUMERIC_CELL)
            : constants.___,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        label: ["As of Date"],
        field: "statusAsOf",
        hidden: true,
        editable: true,
        required: true,
        type: constants.DATE,
        inputFieldType: constants.DATE,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (value) =>
          utils.renderCellValue(value, null, constants.DATE_CELL),
      },
    ],
    data: !_.isEmpty(data.airframeChecks[0].maintenanceStatus)
      ? {
          flightHours:
            data.airframeChecks[0].maintenanceStatus[0].sinceNew.flightHour,
          flightCycles:
            data.airframeChecks[0].maintenanceStatus[0].sinceNew.flightCycle,
          months: _.round(
            data.airframeChecks[0].maintenanceStatus[0].sinceNew.day / 30.44
          ),
          activeHours:
            data.airframeChecks[0].maintenanceStatus[0].sinceNew.activeHour,
          statusAsOf: data.airframeChecks[0].maintenanceStatus[0].statusAsOf,
        }
      : {
          flightHours: null,
          flightCycles: null,
          months: null,
          activeHours: null,
        },
  };
}
