import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import editableTables, { styles } from "../common";

export default function mapToViewModel_APU(data, props, models) {
  return {
    apuInfo: {
      label: "APU Details",
      // style: { paddingTop: 30 },
      caption: null,
      tableType: constants.DATA_TABLE,
      tableName: "APU Details",
      props: props,
      errorsOnSubmit: {},
      showOptions: true,
      columns: [
        {
          label: ["MODEL"],
          field: "apuModel",
          captions: [],
          span: [4, 4],
          editable: true,
          required: false,
          items: models
            ? models.map((m) => {
                return {
                  label: m.model,
                  value: m.model,
                };
              })
            : [],
          initialEditValue: { label: data.model, value: data.model },
          type: constants.AUTO_COMPLETE,
          inputFieldType: constants.AUTO_COMPLETE,
          // type: constants.STRING,
          // inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
        {
          label: ["SERIAL NUMBER"],
          field: "serialNumber",
          captions: [],
          span: [4, 4],
          editable: true,
          required: false,
          type: constants.STRING,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
        {
          label: ["MANUFACTURER"],
          field: "manufacturer",
          captions: [],
          span: [4, 4],
          editable: false,
          required: false,
          type: constants.STRING,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
      ],
      data: {
        apuModel: data.model,
        serialNumber: data.serialNumber,
        manufacturer: data.manufacturer,
      },
    },
    apuStatus: {
      label: "Maintenance Status",
      caption:
        "\"Since Event\" values will be auto-populated if there is overhaul shop visit history",
      props,
      sorting: true,
      tableType: constants.EDITABLE_TABLE,
      showOptions: true,
      tableName: constants.apuStatus,
      // isDeletable: (rowData) => {
      //   return false;
      // },
      errorsOnSubmit: {},
      columns: [
        {
          title: "As Of Date",
          field: "asOfDate",
          type: constants.DATE,
          inputFieldType: constants.DATE,
          editable: constants.ALWAYS,
          initialEditValue: moment.utc().format(constants.ISOFormat),
          required: true,
          defaultSort: "desc",
          sorting: true,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(rowData.asOfDate, null, constants.DATE_CELL),
        },
        {
          title: "Model",
          field: "model",
          cellStyle: styles.cellStyle,
          type: constants.STRING,
          inputFieldType: constants.TEXT,
          editable: constants.NEVER,
          initialEditValue: data.model,
          required: true,
          sorting: false,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          title: "APUH Since New",
          field: "activeHours_sinceNew",
          type: constants.NUMERIC,
          inputFieldType: constants.NUMBER,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.activeHours_sinceNew,
              null,
              constants.NUMERIC_CELL
            ),
        },

        {
          title: "APUH Since Event",
          field: "activeHours_sinceEvent",
          type: constants.NUMERIC,
          inputFieldType: constants.STATUSMXCH,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.activeHours_sinceEvent,
              null,
              constants.NUMERIC_CELL
            ),
        },
      ],
      data: data.maintenanceStatus.map((o, idx) => ({
        idx,
        model: data.model,
        activeHours_sinceNew:
          o.sinceNew.activeHour && _.round(o.sinceNew.activeHour),
        activeHours_sinceEvent:
          o.sinceEvent.activeHour && _.round(o.sinceEvent.activeHour),
        isFirstRun: o.isFirstRun ? constants.YES : constants.NO,
        asOfDate: o.statusAsOf || new Date(),
      })),
    },
    shopVisitHistory: editableTables.shopVisitHistory(
      data.shopVisitHistory,
      props
    ),
    scheduledShopVisit: editableTables.scheduledShopVisits(
      data.maintenanceEvents,
      props
    ),
  };
}
