/* */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Box,
  Divider,
} from "@material-ui/core";
import VerticalTableCell from "./VerticalTableCell";
import MultiFieldsFadeInEditingPanel from "../../Editing/EditingPanels/MultiFieldsFadeInEditingPanel";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles({
  root: {
    verticalAlign: "top",
  },
  rowHeading: {
    fontWeight: 600,
    color: "#023059",
    fontSize: 12,
  },
  editingButtons: {
    maxWidth: 20,
    display: "flex",
    justifyContent: "center",
  },
});

const VerticalTableBody = ({ tableData, editing, newRowColumns }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [multiRowColumn, setMultiRowColumn] = useState(null);

  const columns = tableData.columns;
  const data = tableData.data;
  let props = tableData.props;

  const {
    section,
    funcs: { handleRowAdd, validateData },
  } = props;
  const handleValuesUpdate = handleRowAdd;

  const newRowColumnData = {
    rowTitle: multiRowColumn ? multiRowColumn : null,
    primaryValue: null,
    secondaryValue: null,
    footNote: null,
  };

  return (
    <Table
      stickyHeader
      size='small'
      aria-label='vertical column table'
      className={classes.table}>
      <TableBody>
        {columns &&
          columns.length !== 0 &&
          columns.map((c, idx) => (
            <TableRow key={idx}>
              {c.multiRowColumn ? (
                <>
                  <TableCell
                    variant='head'
                    width='20%'
                    className={classes.root}>
                    <Typography
                      variant='subtitle1'
                      className={classes.rowHeading}>
                      {c.label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box
                      style={{
                        marginTop: -7,
                        marginLeft: -16,
                        marginRight: -16,
                      }}>
                      <Table>
                        <TableBody>
                          {data[c.field].map((d, i) => (
                            <TableRow key={i}>
                              <VerticalTableCell
                                data={d}
                                editing={editing}
                                column={c}
                                rowIdx={i}
                                props={props}
                              />
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    {editing ? (
                      <Box
                        style={{
                          minWidth: "100%",
                          display: "inline-block",
                          marginLeft: -16,
                          marginRight: -16,
                        }}>
                        <Divider />
                        <IconButton
                          onClick={() => {
                            setMultiRowColumn(c.label);
                            setOpen(true);
                          }}>
                          <AddBoxIcon />
                          <Typography
                            variant='body1'
                            style={{ paddingLeft: 20 }}>
                            {"Add Row  "}
                          </Typography>
                        </IconButton>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    variant='head'
                    width='20%'
                    className={classes.root}>
                    <Typography
                      variant='subtitle1'
                      className={classes.rowHeading}>
                      {c.label}
                    </Typography>
                  </TableCell>
                  <VerticalTableCell
                    data={data[c.field]}
                    editing={editing}
                    column={c}
                    props={props}
                  />
                </>
              )}
            </TableRow>
          ))}
      </TableBody>
      <MultiFieldsFadeInEditingPanel
        open={open}
        columns={newRowColumns}
        data={newRowColumnData}
        tableName={tableData.tableName}
        // // errorsOnSubmit={tableData.errorsOnSubmit}
        props={{
          section: section,
          sectionIdx: multiRowColumn,
          funcs: { handleValuesUpdate, validateData },
        }}
        onClose={() => setOpen(false)}
      />
    </Table>
  );
};

export default VerticalTableBody;
