import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingPanel from "../../../Components/Common/LoadingPanel";
import Deals from "./../../../Components/Deals/Deals";

const useStyles = makeStyles({
  root: { maxWidth: "100%", margin: "auto" },
});

const DealList = ({
  currPage,
  dealStages,
  dealsData,
  rowsPerPage,
  isloading,
  handleChangePage,
  handleChangeRowsPerPage,
  liabilityData,
  expectedLossRateData,
  airlineLogos,
  totalRows,
  userName,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {!isloading && dealsData ? (
        <Deals
          currPage={currPage}
          rowsPerPage={rowsPerPage}
          dealsData={dealsData}
          dealStages={dealStages}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          liabilityData={liabilityData}
          expectedLossRateData={expectedLossRateData}
          airlineLogos={airlineLogos}
          totalRows={totalRows}
          userName={userName}
        />
      ) : (
        <LoadingPanel />
      )}
    </Box>
  );
};

export default DealList;
