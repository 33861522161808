import _ from "lodash";
import * as constants from "../../../constants";

export const mapToViewModel_assets = (assetPoolData, dealAssets) => {
  const items = assetPoolData.map((pool) => ({
    label: `${pool.assetPoolName}`,
    value: pool.assetPoolId,
  }));

  if (!_.isEmpty(dealAssets))
    items.unshift({
      label: `Removed Attached Asset Pool`,
      value: -1,
    });

  return {
    columns: [
      {
        label: ["Asset Pool"],
        field: "assetPoolId",
        editable: true,
        required: true,
        items,
        initialEditValue: items[0].value,
        type: constants.AUTO_COMPLETE,
        inputFieldType: constants.AUTO_COMPLETE,
      },
    ],
  };
};
