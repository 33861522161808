// import _ from "lodash";
import React from "react";
import { InputAdornment } from "@material-ui/core";
import * as constants from "../../../../../constants";
import VfSelect from "../../../../Common/UtilComponents/VfSelect";
import VfTextInput from "../../../../Common/UtilComponents/VfTextInput";

export default function mapToViewModel_ValuationAssumptions(
  selectedAppraisal,
  handleSelectChange,
  dtBeforeDisp,
  appraisals
) {
  const appraisal = appraisals[selectedAppraisal.appraiser];
  let columns = [];

  if (appraisal) {
    columns = [
      {
        label: [constants.APPRAISER],
        field: "appraiser",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            {
              label: constants.ASCEND,
              value: constants.ASCEND,
            }
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.appraiser}
              type={constants.APPRAISER}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: [constants.VALUE_TYPE],
        field: "valueType",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            {
              label: constants.BASE,
              value: constants.BASE,
            },
            {
              label: constants.MARKET,
              value: constants.MARKET,
            },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.valueType}
              type={constants.VALUE_TYPE}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["Part-Out Value Type"],
        field: "partOutValueType",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            {
              label: constants.BASE,
              value: constants.BASE,
            },
            {
              label: constants.DOWN,
              value: constants.DOWN,
            },
            {
              label: constants.MARKET,
              value: constants.MARKET,
            },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.partOutValueType}
              type={constants.PART_OUT_VALUE_TYPE}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["DOWNTIME BEFORE DISP"],
        field: "downtimeBeforeDisp",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = dtBeforeDisp;
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.downtimeBeforeDisp}
              type={"DOWNTIME BEFORE DISP"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["DISP VALUE METHOD"],
        field: "dispValueMethod",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            { label: "Mx. Adj. H.L", value: "Mx. Adj. H.L" },
            { label: "Part-Out Value", value: "Part-Out Value" },
            { label: "Override", value: "override" },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.dispValueMethod}
              type={"DISP VALUE METHOD"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["DISP VALUE HAIRCUT"],
        field: "dispValueHairCut",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            { label: "0.0%", value: 0.0 },
            { label: "2.0%", value: 0.02 },
            { label: "4.0%", value: 0.04 },
            { label: "6.0%", value: 0.06 },
            { label: "8.0%", value: 0.08 },
            { label: "10.0%", value: 0.1 },
            { label: "12.0%", value: 0.12 },
            { label: "15.0%", value: 0.15 },
            { label: "20.0%", value: 0.2 },
            { label: "25.0%", value: 0.25 },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.dispValueHairCut}
              type={"DISP VALUE HAIRCUT"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["Lease CF Dis. Rate"],
        field: "LSdiscountRate",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            { label: "0.0%", value: 0.0 },
            { label: "2.0%", value: 0.02 },
            { label: "4.0%", value: 0.04 },
            { label: "6.0%", value: 0.06 },
            { label: "8.0%", value: 0.08 },
            { label: "10.0%", value: 0.1 },
            { label: "12.0%", value: 0.12 },
            { label: "15.0%", value: 0.15 },
            { label: "20.0%", value: 0.2 },
            { label: "25.0%", value: 0.25 },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.LSdiscountRate}
              type={"Lease CF Discount Rate"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["Disp Dis. Rate"],
        field: "DispdiscountRate",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            { label: "0.0%", value: 0.0 },
            { label: "2.0%", value: 0.02 },
            { label: "4.0%", value: 0.04 },
            { label: "6.0%", value: 0.06 },
            { label: "8.0%", value: 0.08 },
            { label: "10.0%", value: 0.1 },
            { label: "12.0%", value: 0.12 },
            { label: "15.0%", value: 0.15 },
            { label: "20.0%", value: 0.2 },
            { label: "25.0%", value: 0.25 },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.DispdiscountRate}
              type={"Disp Discount Rate"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["DISP VALUE OVERRIDE"],
        field: "dispValueOverride",
        captions: null,
        span: [6, 6],
        content: (_) => {
          return (
            <VfTextInput
              items={true}
              fontSize={14}
              placeholder={
                typeof selectedAppraisal.dispValueOverride === "number"
                  ? selectedAppraisal.dispValueOverride
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : selectedAppraisal.dispValueOverride
              }
              type={"DISP VALUE OVERRIDE"}
              inputType={"number"}
              onChange={(type, value) => handleSelectChange(type, value)}
              fullWidth={130}
              minWidth={130}
              padding={"0 0 20px 0"}
              startAdornment={
                <InputAdornment position='start'>$</InputAdornment>
              }
              // disableUnderline={true}
            />
          );
        },
      },
      {
        label: ["Disposition Assumption"],
        field: "dispositionAssumption",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            { label: "Contractual", value: "Contractual" },
            { label: "Assumed", value: "Assumed" },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.dispositionAssumption}
              type={"Disposition Assumption"}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
    ];
  } else {
    columns = [
      {
        label: [constants.APPRAISER],
        field: "appraiser",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            {
              label: constants.ASCEND,
              value: constants.ASCEND,
            },
            { label: "OTHERS", value: "Others" },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.appraiser}
              type={constants.APPRAISER}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
      {
        label: ["Part-Out Value Type"],
        field: "partOutValueType",
        captions: null,
        span: [6, 6],
        content: (_) => {
          const items = [
            {
              label: constants.BASE,
              value: constants.BASE,
            },
            {
              label: constants.DOWN,
              value: constants.DOWN,
            },
            {
              label: constants.MARKET,
              value: constants.MARKET,
            },
          ];
          return (
            <VfSelect
              items={items}
              fontSize={14}
              value={selectedAppraisal.partOutValueType}
              type={constants.PART_OUT_VALUE_TYPE}
              onSelectChange={(type, value) => handleSelectChange(type, value)}
              minWidth={130}
              padding={"0 0 20px 0"}
            />
          );
        },
      },
    ];
  }

  return {
    label: "Valuation Assumptions",
    caption: null,
    tableType: constants.DATA_TABLE,
    columns: columns,
    style: {
      // backgroundColor: "unset",
      borderRadius: "unset",
      boxShadow: "unset",
    },
    data: {
      appraiser: selectedAppraisal.appraiser,
      valueType: selectedAppraisal.valueType,
      partOutValueType: selectedAppraisal.partOutValueType,
      LSdiscountRate: selectedAppraisal.LSdiscountRate,
      DispdiscountRate: selectedAppraisal.DispdiscountRate,
      downtimeBeforeDisp: selectedAppraisal.downtimeBeforeDisp,
      dispValueMethod: selectedAppraisal.dispValueMethod,
      dispValueHairCut: selectedAppraisal.dispValueHairCut,
      dispValueOverride: selectedAppraisal.dispValueOverride,
      dispositionAssumption: selectedAppraisal.dispositionAssumption,
    },
  };
}
