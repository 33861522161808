import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  GridList,
  GridListTile,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Popover,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as constants from "../../../../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 400,
    backgroundColor: theme.palette.background.primary,
    padding: "24px 24px 36px 24px",
  },
  header: {
    flex: "0 0 auto",
    marginBottom: "16px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    display: "inline-block",
    marginLeft: 7,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  reset: {
    alignSelf: "left",
  },
  resetButton: {
    marginLeft: "16px",
    fontSize: "12px",
    cursor: "pointer",
  },
  checkboxListTitle: {
    marginLeft: "7px",
    marginBottom: "8px",
    fontSize: "14px",
    color: theme.palette.text.secondary,
    textAlign: "left",
    fontWeight: 500,
  },
  checkboxFormGroup: {
    marginTop: "8px",
  },
  checkboxFormControl: {
    margin: "0px",
  },
  checkboxFormControlLabel: {
    fontSize: "15px",
    marginLeft: "8px",
    color: theme.palette.text.primary,
  },
  checkboxIcon: {
    width: "32px",
    height: "32px",
  },
  checkbox: {
    "&:checked": {
      color: theme.palette.primary,
    },
  },
}));

const TableFilter = ({
  columns,
  tableName,
  filterData,
  filterList,
  onFilterUpdate,
  onFilterReset,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMultiselectChange = (index, value, column) => {
    onFilterUpdate(index, value, column, tableName);
  };

  const formatIfDate = (data) => {
    return data.match(constants.RAW_DATE_REG) ||
      data.match(constants.RAW_DATE_REG2)
      ? moment.utc(data).format(constants.DATE_FORMAT)
      : data;
  };

  const renderMultipleSelect = (column, index) => {
    const allSelected = _.findIndex(filterList[column.field], function (n) {
      return n === constants.ALL;
    });

    return (
      <GridListTile
        key={index}
        cols={1}
        classes={{ tile: classes.gridListTile }}
      >
        <TextField
          multiline
          fullWidth
          select
          label={column.label}
          SelectProps={{
            multiple: true,
            fullWidth: true,
            value: filterList[column.field] || [],
            renderValue: (selected) =>
              allSelected !== -1 ? (
                constants.ALL
              ) : (
                <List dense={true}>
                  {selected.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText>{formatIfDate(item)}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              ),
            name: column.field,
            onChange: (event) =>
              handleMultiselectChange(index, event.target.value, column.field),
            input: <Input name={column.field} id={column.field} />,
          }}
        >
          {filterData[column.field].map((filterValue, filterIndex) => {
            return (
              <MenuItem value={filterValue} key={filterIndex + 1}>
                <Checkbox
                  checked={
                    filterList[column.field].indexOf(filterValue) >= 0
                      ? true
                      : false
                  }
                  value={filterValue !== null ? filterValue.toString() : ""}
                  className={classes.checkboxIcon}
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checked,
                  }}
                />
                <ListItemText primary={formatIfDate(filterValue)} />
              </MenuItem>
            );
          })}
        </TextField>
      </GridListTile>
    );
  };

  const filterGridColumns =
    columns.filter((column) => column.filter).length === 1 ? 1 : 2;
  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Filter list" arrow>
        <IconButton aria-label="filter list" onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Box className={classes.reset}>
              <Typography variant="body2" className={classes.title}>
                FILTER
              </Typography>
              <Button
                className={classes.resetButton}
                color="primary"
                aira-label="RESET"
                onClick={() => onFilterReset(tableName)}
              >
                RESET
              </Button>
            </Box>
          </Box>
          <GridList cellHeight="auto" cols={filterGridColumns} spacing={34}>
            {columns.map((column, index) => {
              if (column.filter) {
                return renderMultipleSelect(column, index);
              }
              return null;
            })}
          </GridList>
        </Box>
      </Popover>
    </>
  );
};

export default TableFilter;
