import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import { styles } from "../common";
import moment from "moment";
import { Edit, Visibility, AddBox, DeleteForever } from "@material-ui/icons";

export default function mapToViewModel_ConversionEvent(
  scenario,
  props
) {
  const parameters = scenario.parameters
  const tableThreeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
    },
    {
      id: 1,
      icon: DeleteForever,
      label: `Delete`,
      handleClick: () => {
        props.funcs.handleValuesUpdate(
          "Conversion Events",
          0,
          "Conversion Events",
          null)
      },
    },
  ];
  return {
    label: "Conversion Event",
    caption:"",
    tableType: constants.DATA_TABLE,
    tableName: "Conversion Events",
    props,
    showOptions: true,
    errorsOnSubmit: {},
    tableThreeDotBtnOptions: tableThreeDotBtnOptions,
    columns: [
        {
            label: ["Start Date"],
            field: "startDate",
            captions: [],
            span: [3, 3],
            type: constants.DATE,
            inputFieldType: constants.DATE,
            editable: constants.ALWAYS,
            required: false,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            render: (rowData) =>
              moment.utc(rowData["startDate"]).format(constants.DATE_FORMAT),
          },
          {
            label: ["End Date"],
            field: "endDate",
            captions: [],
            span: [3, 3],
            type: constants.DATE,
            inputFieldType: constants.DATE,
            editable: constants.ALWAYS,
            required: false,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            render: (rowData) =>
              moment.utc(rowData["endDate"]).format(constants.DATE_FORMAT),
          },
          {
            label: ["Cost"],
            field: "cost",
            captions: [],
            span: [3, 3],
            type: constants.NUMERIC,
            inputFieldType: constants.TEXT_FIELD,
            editable: true,
            required: false,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
              utils.renderCellValue(value, null, constants.NUMERIC_CELL),
          },
    ], 
    data: 
    parameters.conversion == null ? {
      startDate: null,
      endDate: null,
      cost: null
    }:
    {
      startDate: moment.utc(parameters.conversion.startDate).format(constants.DATE_FORMAT),
      endDate: moment.utc(parameters.conversion.endDate).format(constants.DATE_FORMAT),
      cost: parameters.conversion.cost
    },
  };
}