import React, { useState } from "react";
import { Box, Collapse, Typography } from "@material-ui/core";
import { ExpandMore, NavigateNext } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import VfComments from "./../../Common/UtilComponents/VfComments";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sectionHeaderLabel: {
    color: "#023059",
    fontWeight: 400,
    letterSpacing: "0.25px",
    lineHeight: "26px",
  },
  icon: {
    fontSize: 12,
    marginLeft: 2,
  },
  notesButton: {
    color: "#FD9A24",
    fontSize: 12,
    marginLeft: "30px",
    verticalAlign: "text-top",
    "&:hover": {
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  verticalAlignIcon: {
    verticalAlign: "middle",
  },
  verticalAlignLabel: {
    verticalAlign: "initial",
  },
}));

const SectionHeader = ({
  button,
  icon,
  label,
  comments,
  onCommentClose,
  onCommentStatusChange,
  onChangeMode,
  fontSize = 28,
}) => {
  const classes = useStyles();
  const [notesOpen, setNotesOpen] = useState(false);

  const handleCommentsClose = () => {
    onCommentClose();
    setNotesOpen((prevState) => !prevState);
  };

  return (
    <Box mt={2} mb={2}>
      <Box className={classes.sectionHeader}>
        <Box>
          <Typography
            variant="subtitle1"
            display="inline"
            className={classes.sectionHeaderLabel}
            style={{ fontSize }}
          >
            {icon && (
              <span className={classes.verticalAlignIcon}>{icon} &nbsp;</span>
            )}
            <span className={classes.verticalAlignLabel}>{label}</span>
          </Typography>
          {comments && (
            <Typography
              variant="subtitle1"
              className={classes.notesButton}
              display="inline"
              onClick={handleCommentsClose}
            >
              Notes
              {notesOpen ? (
                <ExpandMore className={classes.icon} />
              ) : (
                <NavigateNext className={classes.icon} />
              )}
            </Typography>
          )}
        </Box>
        <Box>{button}</Box>
      </Box>
      {comments && (
        <Collapse in={notesOpen}>
          <VfComments
            value={comments.curr}
            editing={comments.editing}
            saving={comments.saving}
            onCommentsClose={handleCommentsClose}
            onCommentStatusChange={(action, value) =>
              onCommentStatusChange(action, value)
            }
            onChangeMode={(editing) => onChangeMode(editing)}
          />
        </Collapse>
      )}
    </Box>
  );
};

SectionHeader.prototype = {
  comments: PropTypes.object,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  onCommentClose: PropTypes.func,
  onCommentStatusChange: PropTypes.func,
  onChangeMode: PropTypes.func,
};

export default SectionHeader;
