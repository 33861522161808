import React from "react";
import { Typography, Button } from "@material-ui/core";

const VfButton = ({
  label,
  buttonStyle,
  textStyle,
  onButtonClick,
  disabled = false,
  type = "button",
}) => {
  const handleClick = () => {
    onButtonClick();
  };

  return (
    <Button
      type={type}
      className={buttonStyle}
      disabled={disabled}
      variant="outlined"
      onClick={handleClick}
    >
      <Typography className={textStyle} variant="subtitle1">
        {label}
      </Typography>
    </Button>
  );
};

export default VfButton;
