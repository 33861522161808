import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: `${theme.spacing(1)}px 0`,
    backgroundColor: "#FFFFFF",
  },
  inputLabel: {
    letterSpacing: "1px",
    paddingTop: 10,
    paddingLeft: 17,
  },
  select: {
    color: "#263238",
    // Delete later
    marginTop: 2,
    padding: "6px 0 7px 0 !important",
    "&:focus": { backgroundColor: "transparent" },
  },
  selectFullWidth: {
    color: "#263238",
    padding: "27px 12px 10px !important",
    "&:focus": { backgroundColor: "transparent" },
  },
  item: {
    minHeight: 40,
    color: "#263238",
    borderRadius: 2,
    margin: "0px 5px",
    transition: "all 150ms linear",
    "&:hover": {
      backgroundColor: "#546E7A",
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0  rgba(0,0,0,0.2), 0 7px 10px -5px rgba(0,0,0,0.2)",
    },
  },
  selectedItem: {
    // fontWeight: 500,
    fontSize: 14,
    backgroundColor: "#F4F6F8 !important",
    color: "#263238",
    "&:hover": { color: "#263238", boxShadow: "None" },
  },
}));

const VfSelect = ({
  items,
  value,
  type,
  onSelectChange,
  id,
  fullWidth,
  variant = "standard",
  label = null,
  minWidth = 1,
  maxHeight = "100%",
  fontSize = null,
  padding = null,
  innerpadding = null,
  disabled,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    const { value, name } = event.target;
    onSelectChange(type, value, name);
  };
  return (
    <FormControl
      className={classes.formControl}
      style={{
        minWidth,
        width: fullWidth ? "100%" : "unset",
        padding: padding,
      }}>
      {label ? (
        <InputLabel id={label[0]} className={classes.inputLabel} shrink={true}>
          {label}
        </InputLabel>
      ) : null}
      {items && (
        <Select
          labelId={label && label[0]}
          name={`${id === null ? label : id}`}
          value={value}
          onChange={handleChange}
          style={{
            fontSize,
            padding: innerpadding,
            maxHeight: maxHeight,
          }}
          variant={variant}
          classes={{
            select: fullWidth ? classes.selectFullWidth : classes.select,
          }}
          disabled={disabled}>
          <MenuItem
            classes={{ root: classes.item, selected: classes.selectedItem }}
            disabled>
            CHOOSE {type ? type.toUpperCase() : type}
          </MenuItem>
          {items.map((item, idx) => (
            <MenuItem
              key={idx}
              selected={idx === 0}
              value={item.value}
              classes={{ root: classes.item, selected: classes.selectedItem }}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default VfSelect;
