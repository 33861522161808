import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import { styles } from "../common";
import { Edit, DeleteForever } from "@material-ui/icons";

export default function mapToViewModel_RunoutOverrides(
  scenario,
  props
) {
  const parameters = scenario.parameters
  const tableThreeDotBtnOptions = [
    {
      id: 0,
      icon: Edit,
      label: constants.EDIT,
    },
    {
      id: 1,
      icon: DeleteForever,
      label: `Delete`,
      handleClick: () => {
        props.funcs.handleValuesUpdate(
          "Runout Overrides",
          0,
          "Runout Overrides",
          null)
      },
    },
  ];
  return {
    label: "Runout Floor Overrides",
    caption:"",
    tableType: constants.DATA_TABLE,
    tableName: "Runout Overrides",
    props,
    showOptions: true,
    errorsOnSubmit: {},
    tableThreeDotBtnOptions: tableThreeDotBtnOptions,
    columns: [
        {
            label: ["Airframe Value"],
            field: "airframeValue",
            captions: [],
            span: [3, 3],
            type: constants.NUMERIC,
            inputFieldType: constants.TEXT_FIELD,
            editable: true,
            required: false,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
            utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),    
          },
          {
            label: ["Combined Landing Gear"],
            field: "combinedLandingGearValue",
            captions: [],
            span: [3, 3],
            type: constants.NUMERIC,
            inputFieldType: constants.TEXT_FIELD,
            editable: true,
            required: false,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
              utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
          },
          {
            label: ["APU Value"],
            field: "apuValue",
            captions: [],
            span: [3, 3],
            type: constants.NUMERIC,
            inputFieldType: constants.TEXT_FIELD,
            editable: true,
            required: false,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
                utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
          },
          {
            label: ["Single Engine Value"],
            field: "singleEngineValue",
            captions: [],
            span: [3, 3],
            type: constants.NUMERIC,
            inputFieldType: constants.TEXT_FIELD,
            editable: true,
            required: false,
            cellStyle: styles.cellStyle,
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
                utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
          },
    ], 
    data: 
    parameters.runoutFloorValues == null ? {
      airframeValue:null,
      combinedLandingGearValue: null,
      apuValue: null,
      singleEngineValue: null
    }:
    {
        airframeValue:parameters.runoutFloorValues.airframeValue,
        combinedLandingGearValue: parameters.runoutFloorValues.combinedLandingGearValue,
        apuValue: parameters.runoutFloorValues.apuValue,
        singleEngineValue: parameters.runoutFloorValues.singleEngineValue
    },
  };
}