import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";
import editableTables, { styles } from "../common";

export default function mapToViewModel_LandingGears(data, props) {
  return {
    LGInfo: {
      label: "Landing Gear Details",
      // style: { paddingTop: 30 },
      caption: null,
      tableType: constants.DATA_TABLE,
      tableName: "Landing Gear Details",
      props: props,
      errorsOnSubmit: {},
      showOptions: true,
      columns: [
        {
          label: ["PART NUMBER"],
          field: "partNumber",
          captions: [],
          span: [4, 4],
          editable: false,
          required: false,
          type: constants.NUMBER,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
        {
          label: ["SERIAL NUMBER"],
          field: "serialNumber",
          captions: [],
          span: [4, 4],
          editable: true,
          required: false,
          type: constants.STRING,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
        {
          label: ["POSITION"],
          field: "position",
          captions: [],
          span: [4, 4],
          editable: false,
          required: false,
          type: constants.STRING,
          inputFieldType: constants.TEXT_FIELD,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          content: (value) =>
            utils.renderCellValue(value, null, constants.TEXT_CELL),
        },
      ],
      data: {
        partNumber: data.partNumber,
        serialNumber: data.serialNumber,
        position: data.position,
      },
    },
    landingGearStatus: {
      label: "Maintenance Status",
      caption:
      "\"Since Event\" values will be auto-populated if there is overhaul shop visit history",
      props,
      sorting: true,
      showOptions: true,
      tableType: constants.EDITABLE_TABLE,
      tableName: constants.landingGearStatus,
      // isDeletable: (rowData) => {
      //   return false;
      // },
      errorsOnSubmit: {},
      columns: [
        {
          title: "As Of Date",
          field: "asOfDate",
          type: constants.DATE,
          inputFieldType: constants.DATE,
          editable: constants.ALWAYS,
          initialEditValue: moment.utc().format(constants.ISOFormat),
          required: true,
          defaultSort: "desc",
          sorting: true,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(rowData.asOfDate, null, constants.DATE_CELL),
        },
        {
          title: "POSITION",
          field: "position",
          cellStyle: styles.cellStyle,
          type: constants.STRING,
          inputFieldType: constants.TEXT,
          editable: constants.NEVER,
          initialEditValue: data.position,
          required: true,
          sorting: false,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
        },
        {
          title: "Cycles Since New",
          field: "flightCycles_sinceNew",
          type: constants.NUMERIC,
          inputFieldType: constants.NUMBER,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.flightCycles_sinceNew,
              null,
              constants.NUMERIC_CELL
            ),
        },
        {
          title: "Days Since New",
          field: "days_sinceNew",
          type: constants.NUMERIC,
          inputFieldType: constants.NUMBER,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.days_sinceNew,
              null,
              constants.NUMERIC_CELL
            ),
        },
        {
          title: "Cycles Since Event",
          field: "flightCycles_sinceEvent",
          type: constants.NUMERIC,
          inputFieldType: constants.STATUSMXCH,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.flightCycles_sinceEvent,
              null,
              constants.NUMERIC_CELL
            ),
        },
        {
          title: "Days Since Event",
          field: "days_sinceEvent",
          type: constants.NUMERIC,
          inputFieldType: constants.STATUSMXCH,
          editable: constants.ALWAYS,
          required: false,
          sorting: false,
          cellStyle: styles.cellStyle,
          validate: (inputType, value, required) =>
            utils.validate(inputType, value, required),
          render: (rowData) =>
            utils.renderCellValue(
              rowData.days_sinceEvent,
              null,
              constants.NUMERIC_CELL
            ),
        },
      ],
      data: data.maintenanceStatus.map((o, idx) => ({
        idx,
        position: data.position,
        isFirstRun: o.isFirstRun ? constants.YES : constants.NO,
        asOfDate: o.statusAsOf || new Date(),
        flightCycles_sinceNew:
          o.sinceNew.flightCycle && _.round(o.sinceNew.flightCycle),
        days_sinceNew: o.sinceNew.day && _.round(o.sinceNew.day, 2),
        flightCycles_sinceEvent:
          o.sinceEvent.flightCycle && _.round(o.sinceEvent.flightCycle),
        days_sinceEvent: o.sinceEvent.day && _.round(o.sinceEvent.day, 2),
      })),
    },
    shopVisitHistory: editableTables.shopVisitHistory(
      data.shopVisitHistory,
      props
    ),
    scheduledShopVisit: editableTables.scheduledShopVisits(
      data.maintenanceEvents,
      props
    ),
  };
}
