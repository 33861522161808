import _ from "lodash";
import moment from "moment";
import * as utils from "../../../utils/utils";
import * as constants from "../../../constants";
import { Visibility } from "@material-ui/icons";

export const styles = {
  cellStyle: {
    color: "#023059",
    textAlign: "center",
    fontSize: 11,
    height: 40,
    padding: 0,
    borderBottom: "unset",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
};

export default function mapToViewModel_liabilityExtras(
  liabData,
  deal,
  props,
  assetList
) {
  let data = liabData.liabilityModelData.loanDetails.map((x, i) => {
    return {
      id: i,
      dealId: deal.dealId,
      assetId: _.isNil(
        _.find(assetList, (o) => o.serialNumber === x.assetSerialNumber)
      )
        ? null
        : _.find(assetList, (o) => o.serialNumber === x.assetSerialNumber).id,
      serialNumber: x.assetSerialNumber,
      collateralRating: x.assetRating,
      strategicValue: x.strategicValue,
    };
  });

  return {
    label: "Edit Loans",
    caption: null,
    props,
    showOptions: true,
    sorting: false,
    isDeletable: () => false,
    tableType: constants.EDITABLE_TABLE,
    threeDotBtnOptions: [
      {
        id: 0,
        icon: Visibility,
        label: constants.VIEW,
      },
    ],
    tableName: "Edit Loans",
    style: { all: "unset" },
    errorsOnSubmit: {},
    columns: [
      {
        title: "Serial Number",
        field: "serialNumber",
        type: constants.STRING,
        cellStyle: styles.cellStyle,
        editable: constants.NEVER,
        required: true,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Collateral Rating",
        field: "collateralRating",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        cellStyle: styles.cellStyle,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // render: (rowData) =>
        //   utils.renderCellValue(
        //     rowData.collateralRating,
        //     null,
        //     constants.NUMERIC_CELL
        //   ),
      },
      {
        title: "Strategic Value",
        field: "strategicValue",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        cellStyle: styles.cellStyle,
        editable: constants.ALWAYS,
        required: false,
        sorting: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // render: (rowData) =>
        //   utils.renderCellValue(
        //     rowData.strategicValue,
        //     null,
        //     constants.NUMERIC_CELL
        //   ),
      },
    ],
    data: data,
  };
}
