import React from "react";
import { Route } from "react-router-dom";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { accounts, inProgress } = useMsal();

  return (
    <Route
      {...rest}
      render={(props) => (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          {inProgress === 'none' && (
            <Component accountObj={accounts[0]} {...props} />
          )}
          {/* TODO: Consider showing component that lets user know the app is authenticating if msal is inProgress */}
        </MsalAuthenticationTemplate>
      )}
    />
  );
};

export default PrivateRoute;
