import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const updateAsset = async (assetId, data, msalContext) => {
  let compType = "LandingGear";
  let apiendpoint = apiRoutes.PostAircraftUpdateInfo;
  apiendpoint = apiendpoint({ compType, assetId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: data,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    data = resp.data;
  }
};

export const updateData = (assetDetails, sectionIdx, newData, msalContext) => {
  // assetDetails.landingGears[sectionIdx].partNumber = newData.partNumber
  assetDetails.landingGears[sectionIdx].serialNumber = newData.serialNumber;
  // assetDetails.landingGears[sectionIdx].position = newData.position

  let postData = {
    serialNumber: newData.serialNumber,
  };

  updateAsset(
    assetDetails.landingGears[sectionIdx].localId,
    postData,
    msalContext
  );
  return assetDetails;
};
