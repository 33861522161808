// import _ from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

export const updateData = (
  tableName,
  actionType,
  assetDetails,
  sectionIdx,
  newData,
  scenario
) => {
  let startdate =  moment.utc().toISOString().split(".")[0];
  let enddate =  moment.utc().toISOString().split(".")[0];
  var exists = false;
  //change default date to lease start and end if they exist
  scenario.leases.every(l =>
  {
    exists = false;
    //if utilization already exists with lease dates look at next lease
    scenario.expectedUtilizations[sectionIdx].utilizationByDate.forEach(e => {
      let ed = moment.utc(e.endDate).format(constants.DATE_FORMAT);
      let ld = moment.utc(l.leaseEndDate).format(constants.DATE_FORMAT);
      if( 
        //ed.isSame(ld)
        ed == ld
      ){
        exists = true;
      }
    });
    if(!exists){
      startdate = l.leaseStartDate
      enddate = l.leaseEndDate
      return false;
    }
    return true;
  });

  const template = {
    startDate: startdate,
    endDate: enddate,
    utilization: {
      flightHour: null,
      flightCycle: null,
      day: null,
      activeHour: null,
    },
  };

  scenario.expectedUtilizations = [...scenario.expectedUtilizations];
  scenario.expectedUtilizations[sectionIdx] = {
    ...scenario.expectedUtilizations[sectionIdx],
  };

  if (tableName === constants.utilizationByDate) {
    let utilizationByDate = [
      ...scenario.expectedUtilizations[sectionIdx].utilizationByDate,
    ];
    if (actionType === constants.DELETE_ROW) {
      utilizationByDate = utilizationByDate.filter(
        (e, idx) => idx !== newData.id
      );
    } else {
      if (actionType === constants.UPDATE_ROW) {
        template.startDate = moment.utc(newData.startDate);
        template.endDate = moment.utc(newData.endDate);
        template.utilization.flightHour =
          newData.flightHour && parseFloat(newData.flightHour);
        template.utilization.flightCycle =
          newData.flightCycle && parseFloat(newData.flightCycle);
        template.utilization.day = newData.day && parseInt(newData.day);
        template.utilization.activeHour =
          newData.activeHour && parseFloat(newData.activeHour);
        utilizationByDate[newData.id] = template;
      } else {
        utilizationByDate.push(template);
      }
    }

    scenario.expectedUtilizations[
      sectionIdx
    ].utilizationByDate = utilizationByDate;
  } else {
    let components = [...scenario.expectedUtilizations[sectionIdx].components];
    components = newData.components.map((c) => c);
    scenario.expectedUtilizations[sectionIdx].components = components;
  }

  return scenario;
};
