import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";

export function mapToViewModel_CreditLineReserve(scenario, props) {
  const data = scenario.creditAccounts;
  return {
    label: "Credit Line Reserve",
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.creditLineReserve,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns: [
      {
        title: "From Date",
        field: "fromDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.fromDate).format(constants.DATE_FORMAT),
      },
      // {
      //   title: "To Date",
      //   field: "toDate",
      //   type: constants.DATE,
      //   inputFieldType: constants.DATE,
      //   editable: constants.ALWAYS,
      //   required: true,
      //   cellStyle: styles.cellStyle,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      //   render: (rowData) =>
      //     moment.utc(rowData.toDate).format(constants.DATE_FORMAT),
      // },
      {
        title: "Credit Available",
        field: "amount",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(
            rowData.amount,
            null,
            constants.NUMERIC_CELL,
            "$",
            2
          ),
      },
    ],
    data: data.map((d, idx) => ({
      id: idx,
      fromDate: d.fromDate,
      // toDate: d.toDate,
      amount: d.amount,
    })),
  };
}
