import _ from "lodash";
import axios from "axios";
import React, { Component } from "react";
import { Box, Grid } from "@material-ui/core";
import { apiRequest, response } from "../../utils/ApiRequest";
import apiRoutes from "../../utils/ApiRoutes";
import SectionHeader from "../Common/Section/SectionHeader";
import ErrorBoundary from "../../Pages/GeneralErrorPage";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import VFKPI from "./Components/vfKPI";
import AssetPreview from "./Components/assetPreview";
import RecentActivity from "./Components/recentActivity";
// import AssetMap from "./Components/assetMap";
import LoadingPanel from "../Common/LoadingPanel";
import VolofinNews from "./Components/VolofinNews";
import { MsalContext } from "@azure/msal-react";

class Homepage extends Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.state = {
      allAssetsData: null,
      recentActivity: null,
      liabilityData: null,
      selectedStageData: null,
    };
  }

  getHomepageData = async () => {
    this.tokenSourceDeals = axios.CancelToken.source();
    return apiRequest(
      {
        url: apiRoutes.Homepage_GetMostRecentActivity,
        method: "GET",
        options: { headers: { "Content-Type": "text/json" } },
        cancelToken: this.tokenSourceDeals.token,
      },
      this.context
    );
  };

  componentDidMount = async () => {
    let allData = await this.getHomepageData();
    if (allData && allData.status === response.OK) {
      const getClosedDealAssets = _.filter(
        allData.data.homePageAssets,
        (asset) => {
          return asset.stage === "Closed";
        }
      );
      const closedDealIds = _.uniq(
        getClosedDealAssets.map((asset) => asset.dealId)
      );
      const liabilityData = _.filter(
        allData.data.homePageLiabilityModels,
        (liab) => {
          if (liab) return closedDealIds.includes(liab.dealId);
        }
      );

      this.setState({
        allAssetsData: allData.data.homePageAssets,
        recentActivity: allData.data.homePageRecentChanges,
        selectedStageData: getClosedDealAssets,
        liabilityData: liabilityData,
      });
    }
  };

  render() {
    const { recentActivity, liabilityData, selectedStageData } = this.state;

    return (
      <ErrorBoundary>
        <VfBreadcrumbContainer
          breadcrumb={<VfBreadcrumbs breadcrumbs={[]} />}
        />

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "48px 0px 16px 0px",
          }}>
          <SectionHeader label={"Dashboard"} />
        </Box>

        {liabilityData ? (
          <Box>
            <VFKPI liabilityData={liabilityData} />
          </Box>
        ) : (
          <LoadingPanel />
        )}

        <Grid container spacing={2} style={{ minWidth: 1250, marginTop: 20 }}>
          <Grid item xs={6}>
            {selectedStageData ? (
              <AssetPreview
                selectedStageData={selectedStageData}
                liabilityData={liabilityData}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6}>
            {recentActivity ? (
              <RecentActivity recentActivity={recentActivity} />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        {liabilityData ? (
          <Box style={{ margin: "24px 0 0px 0px" }}>
            <Box
              style={{
                height: 350,
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: 6,
                boxShadow:
                  "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
              }}>
              <Box
                style={{
                  padding: "24px 0px 8px 32px",
                  textAlign: "left",
                  color: "#9B9B9B",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}>
                News
              </Box>
              <VolofinNews />
            </Box>
          </Box>
        ) : (
          <></>
        )}

        {/* <Box style={{ minWidth: 1250, marginTop: 20, paddingBottom: 20 }}>
          <AssetMap />
        </Box> */}

        <Box style={{ height: 24 }}></Box>
      </ErrorBoundary>
    );
  }
}

export default Homepage;
