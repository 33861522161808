import _ from "lodash";
import React, { useContext } from "react";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";
import { Box } from "@material-ui/core";
import ThreeDotButton from "../../Common/ThreeDotButton";
import moment from "moment";
import { StaticDataContext } from "../../../context/StaticDataContext";

function generateTotal(loanData, loanCF) {
  let getFirst = Object.values(loanCF).map((loan) => loan[0]);

  return {
    serialNumber: "Total",
    leaseMaturity: null,
    monthlyRent: _.sumBy(loanData, "monthlyRent"),
    cashReserve:
      _.sumBy(getFirst, "securityDepositCash") + _.sumBy(getFirst, "mrBalance"),
    lcReserve_Guarantee:
      _.sumBy(getFirst, "securityDepositLC") +
      _.sumBy(getFirst, "mxReserveLC") +
      _.sumBy(getFirst, "guarantee"),
    mxAdjustedValue: _.sumBy(loanData, "mxAdjFromHL"),
    unencumbered: _.sumBy(loanData, "unencumbered"),
    encumbered: _.sumBy(loanData, "encumbered"),
    loanBalance: _.sumBy(loanData, "loanBalance"),
    collateralRating: null,
    strategicValue: null,
    interestRateType: null,
    margin: null,
    referenceRate: null,
    wal: null,
    vfShare: null,
  };
}

export default function mapToViewModel_liabilityLoans(
  liabilityData,
  deal,
  options,
  assetList
) {
  //console.log(assetList, deal);
  const disabled = options.refreshDisabled;

  let loanCF = _.groupBy(liabilityData.liabilityModelData.loanCashflows, "msn");

  let loanData = liabilityData.liabilityModelData.loanDetails;
  let assetData = liabilityData.liabilityModelData.assetInfo;

  const total = generateTotal(loanData, loanCF);
  let data = !_.isEmpty(loanData)
    ? loanData.map((x, idx) => {
        // console.log(
        //   _.find(
        //     assetList,
        //     (o) =>
        //       o.serialNumber === x.assetSerialNumber &&
        //       o.subseries === x.aircraftModel
        //   )
        //);
        return {
          serialNumber: _.isNil(
            _.find(
              assetList,
              (o) =>
                o.serialNumber === x.assetSerialNumber &&
                o.subseries === x.aircraftModel
            )
          )
            ? {
                serialNumber: x.assetSerialNumber,
                linkProps: null,
              }
            : {
                serialNumber: x.assetSerialNumber,
                linkProps: {
                  disabled,
                  state: {
                    deal,
                    assets: deal.assets,
                    assetId: _.find(
                      assetList,
                      (o) =>
                        o.serialNumber === x.assetSerialNumber &&
                        o.subseries === x.aircraftModel
                    ).id,
                    fromPortfolio: true,
                  },
                },
                asset: _.find(
                  assetList,
                  (o) =>
                    o.serialNumber === x.assetSerialNumber &&
                    o.subseries === x.aircraftModel
                ),
                deal,
              },
          leaseMaturity: x.maturity,
          monthlyRent: _.find(assetData, (o) => o.msn === x.assetSerialNumber)
            .monthlyRent,
          // cashReserve:
          //   x.security_Deposit_Cash_Col +
          //   loanCF[x.assetSerialNumber][0].mrBalance,
          // lcReserve_Guarantee:
          //   x.security_Deposit_LC_Col + x.mxREserve_LC_Col + x.guarantee_Col,
          cashReserve:
            loanCF[x.assetSerialNumber][0].securityDepositCash +
            loanCF[x.assetSerialNumber][0].mrBalance,
          lcReserve_Guarantee:
            loanCF[x.assetSerialNumber][0].securityDepositLC +
            loanCF[x.assetSerialNumber][0].mxReserveLC +
            loanCF[x.assetSerialNumber][0].guarantee,
          mxAdjustedValue: x.mxAdjFromHL,
          unencumbered: x.unencumbered,
          encumbered: x.encumbered,
          loanBalance: x.loanBalance,
          collateralRating: x.assetRating,
          strategicValue: x.strategicValue,
          interestRateType: x.irType,
          margin: x.irType === "Fixed" ? x.fixedRate : x.margin,
          referenceRate: x.referenceRate,
          wal: x.wal,
          vfShare: x.vfShare,
        };
      })
    : deal.assets.map((x) => {
        return {
          serialNumber: {
            serialNumber: x.serialNumber,
            linkProps: {
              disabled,
              state: {
                deal,
                assets: deal.assets,
                assetId: _.find(
                  deal.assets,
                  (o) => o.serialNumber === x.serialNumber
                ).id,
                fromPortfolio: true,
              },
            },
            asset: _.find(
              deal.assets,
              (o) => o.serialNumber === x.serialNumber
            ),
            deal,
          },
          leaseMaturity: null,
          monthlyRent: null,
          cashReserve: null,
          lcReserve_Guarantee: null,
          mxAdjustedValue: null,
          unencumbered: null,
          encumbered: null,
          loanBalance: null,
          collateralRating: null,
          strategicValue: null,
          interestRateType: null,
          margin: null,
          referenceRate: null,
          wal: null,
          vfShare: null,
        };
      });

  return {
    liabilityTranches: {
      label: "Loans",
      caption: `As of ${moment
        .utc(loanData[0].determinationDate)
        .format(constants.FULL_YEAR_DATE)}`,
      tableType: constants.MULTIFUNCTION_TABLE,
      options: { pagination: false, threeDotButton: true },
      optionsItems: {
        // pagination: {
        //   page: 0,
        //   rowsPerPage: 10,
        //   tableName: "facilityLoans",
        //   handleChangePage: options.pagination.handleChangePage,
        //   handleChangeRowsPerPage: options.pagination.handleChangeRowsPerPage,
        // },
        threeDotButton: {
          items: [
            {
              id: 0,
              label: constants.EDIT,
              handleClick: () => options.handleOpenLoansTableEditing(true),
            },
          ],
          content: (options) => <ThreeDotButton options={options} />,
        },
      },
      span: [12, 12, 12],
      columns: [
        {
          label: "Serial Number",
          caption: null,
          field: "serialNumber",
          align: constants.LEFT,
          indent: 16,
          content: (value, indent) => {
            if (!value) return constants.___;
            else if (value === "Total") return "Total";
            else if (value.linkProps === null)
              return utils.renderCellValue(
                value.serialNumber,
                { paddingLeft: indent },
                constants.TEXT_CELL
              );
            else {
              let baseDealInfo = `/deal/assetModel/${value.asset.id}?dealId=${value.deal.dealId}&dealName=${value.deal.dealName}`;
              let assetInfo = `&assetType=${value.asset.assetType}&serialNumber=${value.asset.serialNumber}`;
              let selectedScenarioInfo = `&selected=Cashflows`;

              return utils.renderLink(
                utils.renderCellValue(
                  value.serialNumber,
                  { paddingLeft: indent },
                  constants.TEXT_CELL
                ),
                {
                  to: {
                    pathname: baseDealInfo + assetInfo + selectedScenarioInfo,
                  },
                  disabled: !value.linkProps.disabled,
                },
                constants.CASH_FLOWS,
                value.serialNumber
              );
            }
          },
        },
        {
          label: "Lease Maturity",
          caption: null,
          field: "leaseMaturity",
          align: "left",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingLeft: indent },
              constants.DATE_CELL
            ),
        },
        {
          label: "Monthly Rent",
          caption: "U.S. $",
          field: "monthlyRent",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Cash Reserve",
          caption: "U.S. $",
          field: "cashReserve",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "LC Reserve / Guarantee",
          caption: "U.S. $",
          field: "lcReserve_Guarantee",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Unencumbered Value",
          caption: "U.S. $",
          field: "unencumbered",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        // {
        //   label: "Collateral Value",
        //   caption: "U.S. $",
        //   field: "collateral",
        //   align: "right",
        //   indent: 16,
        //   content: (value, indent) =>
        //     utils.renderCellValue(
        //       value,
        //       { paddingRight: indent },
        //       constants.NUMERIC_CELL
        //     ),
        // },
        {
          label: "Encumbered Value",
          caption: "U.S. $",
          field: "encumbered",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Loan Balance",
          caption: "U.S. $",
          field: "loanBalance",
          align: "right",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(
              value,
              { paddingRight: indent },
              constants.NUMERIC_CELL
            ),
        },
        {
          label: "Collateral Rating",
          caption: null,
          field: "collateralRating",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value ? value.toFixed(2) : constants.___}
            </Box>
          ),
        },
        {
          label: "Strategic Value",
          caption: null,
          field: "strategicValue",
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value ? value.toFixed(2) : constants.___}
            </Box>
          ),
        },
        {
          label: "Interest Rate Type",
          caption: null,
          field: "interestRateType",
          align: "left",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(value, { paddingLeft: indent }),
        },
        {
          label: "Margin / Fixed Rate",
          caption: "%",
          field: "margin",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value ? _.round(value * 100, 3).toFixed(2) : constants.___}
            </Box>
          ),
        },
        {
          label: "Reference Rate",
          caption: null,
          field: "referenceRate",
          align: "left",
          indent: 16,
          content: (value, indent) =>
            utils.renderCellValue(value, { paddingLeft: indent }),
        },
        {
          label: "WAL",
          caption: null,
          field: "wal",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value ? value.toFixed(2) : constants.___}
            </Box>
          ),
        },
        {
          label: "vfShare",
          caption: "%",
          field: "vfShare",
          filter: false,
          align: "right",
          indent: 16,
          content: (value, indent) => (
            <Box style={{ paddingRight: indent }}>
              {value ? _.round(value * 100, 3).toFixed(2) : constants.___}
            </Box>
          ),
        },
      ],
      data: data,
      total,
    },
  };
}
