import _, { constant } from "lodash";
import moment from "moment";
import * as constants from "../../../../constants";
import * as utils from "../../../../utils/utils";

export default function mapToViewModel_llpDetails(
  engineData,
  props,
  selectedSubseries,
) {
  let selectedEngineData = _.find(engineData, { 'subseries': selectedSubseries });
  let data = selectedEngineData.stack.llPs.map( e => {
    return {
      isCore: e.isCore,
      isDefault: e.isDefault,
      module: e.module,
      partName: e.partName,
      //partNumber: e.partNumber,
      price: e.price.price,
      priceEscalation: e.price.priceEscalation,
      priceYear: e.price.priceYear,
      cycleLifeLimit: e.buildStandard.cycleLifeLimit,
      replaceBuildStandard: e.buildStandard.replaceBuildStandard, 
      replaceCutOff: e.buildStandard.replaceCutOff, 
      scrapSaleCutoff: e.buildStandard.scrapSaleCutoff,
    }
  })

  let columns = [
    {
      label: ["Module"],
      field: "module",
      align: constants.LEFT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingLeft: indent }, constants.TEXT_CELL),
    },
    {
      label: ["Part Name"],
      field: "partName",
      align: constants.LEFT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingLeft: indent }, constants.TEXT_CELL),
    },
    // {
    //   label: ["Part Number"],
    //   field: "partNumber",
    //   align: constants.LEFT,
    //   // span: [4, 4],
    //   indent: 10,
    //   content: (value, indent) =>
    //     utils.renderCellValue(value, { paddingLeft: indent }, constants.TEXT_CELL),
    // },
    {
      label: ["Is Core"],
      field: "isCore",
      caption: null,
      align: constants.LEFT,  
      // span: [4, 4],
      indent: 10,
      content: (value, indent) => {
        value = value ? "True" : "False";
        return utils.renderCellValue(value, { paddingLeft: indent }, constants.TEXT_CELL)
      },
    },
    {
      label: ["Is Default"],
      field: "isDefault",
      align: constants.LEFT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) => {
        value = value ? "True" : "False";
        return utils.renderCellValue(value, { paddingLeft: indent }, constants.TEXT_CELL)
      },
    },
    {
      label: ["Cycle Life Limit"],
      field: "cycleLifeLimit",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Replace Build Standard"],
      field: "replaceBuildStandard",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Replace Cutoff"],
      field: "replaceCutOff",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Scrap Sale Cutoff"],
      field: "scrapSaleCutoff",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Price"],
      field: "price",
      caption: ["U.S. $"],
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
    {
      label: ["Price Esc"],
      field: "priceEscalation",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) => {
        value = `${_.round(value * 100, 3).toFixed(2)}%`;
        return utils.renderCellValue(value, { paddingRight: indent }, constants.TEXT_CELL)
      },
    },
    {
      label: ["Price Year"],
      field: "priceYear",
      align: constants.RIGHT,
      // span: [4, 4],
      indent: 10,
      content: (value, indent) =>
        utils.renderCellValue(value, { paddingRight: indent }, constants.NUMERIC_CELL),
    },
  ];

  return {
    label: "LLP Stack Details",
    caption: `As of Date: ${moment.utc(selectedEngineData.stack.llPs[0].price.asOfDate).format(constants.DATE_FORMAT)}`,
    props,
    tableType: constants.MULTIFUNCTION_TABLE,
    options: { pagination: false },
    optionsItems: {
      pagination: {
        page: 0,
        rowsPerPage: 10,
        tableName: null,
        handleChangePage: null,
        handleChangeRowsPerPage: null,
      },
    },
    span: [12, 12, 12],
    columns,
    data,
  };
}
