import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MsalContext } from "@azure/msal-react";
import appConfig from "../../../appConfig";

import * as constants from "../../constants";
import * as utils from "../../utils/utils";
import * as mapToViewModels from "./mapToViewModels";
import { apiRequest, response } from "../../utils/ApiRequest";
import apiRoutes from "../../utils/ApiRoutes";

import axios from "axios";
import moment from "moment";
import _, { delay } from "lodash";
import XLSX from "xlsx";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Tab,
  Tabs,
  Tooltip,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import VfSnackerBar from "../Common/UtilComponents/VfSnackerBar";
import VfAlertDialogBox from "../Common/UtilComponents/VfAlertDialogBox";
import VfBreadcrumbs from "../Common/UtilComponents/VfBreadcrumbs";
import VfBreadcrumbContainer from "../Common/UtilComponents/VfBreadcrumbContainer";
import VfPrint from "../Common/UtilComponents/VfPrint";
import SectionHeader from "../Common/Section/SectionHeader";
import LoadingPanel from "../Common/LoadingPanel";
import Errors from "../Common/Errors";
import ErrorBoundary from "../../Pages/GeneralErrorPage";

import PortfolioLiabilitySection from "./Components/LiabilitySection";
import PortfolioAssetsSection from "./Components/AssetsSection";
import ExpectedLossRiskSection from "./Components/ExpectLossRiskSection";
import PaymentPerformancekSection from "./Components/PaymentPerformance";
import CashflowHistory from "./Components/CashflowHistory";
import FileUpload from "./Components/LiabilityComponents/FileUpload";
import LiabilityModelSelect from "./Components/LiabilityComponents/LiabilityModelSelect";
import VfCheckBox from "../Common/UtilComponents/VfCheckBox";

const styles = {
  root: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  topElement: {
    // position: "absolute",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  portFolioBody: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  refreshButton: {
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

class PortfolioReport extends Component {
  static contextType = MsalContext;

  selectedPortfolioTokenSource = null;
  defaultPortfolioTokenSource = null;

  constructor(props) {
    super(props);
    this.portfolioContentRef = React.createRef();
    this.UploadRef = React.createRef();

    this.state = {
      originalScenarioList: [],
      selectedCommonScenario: "",
      commonScenarios: [],
      fleetSummary: null,
      leaseSummary: null,
      assetList: [],
      error: null,
      appraisalError: false,
      report: null,
      noSimAssets: false,
      unAbletoLoad: false,
      snackOpen: false,
      severity: "info",
      message: "",
      duration: 2000,
      isAlertDialogOpen: false,
      switchDialogRender: null,
      exportFileName: "",
      selectedExportScenarios: null,
      selectedExportDealName: null,
      selectedExportFile: "portfolio",
      selectedExportFormat: ".xlsx",
      selectedUploadFile: "liability",
      shareAllChecked: false,
      includeMR: false,
      includeCompRDAExposure: false,
      selectedSheets: [
        "Scenario",
        "Aggregate_Data",
        "Component_Status",
        "Mx_Events",
        "Rda_Events",
        "Engines",
        "LLP_Events",
        "Lease_Report",
        "Deal_Info",
        "Asset_Info",
        "AC_Info",
        "AC_CV",
        "AC_FV",
        "ENG_Info",
        "ENG_CV",
        "ENG_FV",
        "Simulation_Params",
      ],
      sheetNames: [],
      tabValue: 0,
      cashflowHistoryRenderKey: 0,
      dataRefrshRenderKey: 0,
      liabilityData: null,
      liabilityError: false,
      liabilityCashflow: null,
      liabilityTranches: null,
      expectedLossTranches: null,
      expectedLossData: null,
      performanceData: null,
      deal: null,
      editLoans: false,
      missingSims: [],
      LinkedDeals: [],
      pipelineDealId: null,
      selectedModelType: "Base",
    };
  }

  formatData = (data, componentType, mapToViewModel) => {
    const { selectedCommonScenario } = this.state;
    const { deal, assetList } = this.state;

    const props = {
      selectedCommonScenario,
      handleSingleScenarioChangeEvt: this.handleSingleScenarioChangeEvt,
      // handleCommonScenarioChangeEvt: this.handleCommonScenarioChangeEvt,
      handleOpenCommonScenarioDialog: this.handleOpenCommonScenarioDialog,
      handleOpenLoansTableEditing: this.handleOpenLoansTableEditing,
      refreshDisabled: true,
      handleRefresh: this.handleRefresh,
      resetDisabled: true,
      handleGetDefaultOutput: this.handleGetDefaultOutput,
      handleReset: this.handleReset,
      filter: {
        handleFilterUpdate: this.handleFilterUpdate,
        handleFilterReset: this.handleFilterReset,
      },
      pagination: {
        handleChangePage: this.handlePaginationChange,
        handleChangeRowsPerPage: this.handlePaginationChange,
      },
    };

    return data.map((item) => {
      return {
        header: mapToViewModels.mapToViewModelHeader(componentType),
        data: mapToViewModel(item, deal, props, assetList),
        componentType: componentType,
      };
    });
  };

  getPortfolioDataByScenarios = async (scenarios) => {
    const apiEndPoint = apiRoutes.VectorDeal_GetSimulatePortfolio;
    this.defaultPortfolioTokenSource = axios.CancelToken.source();
    return apiRequest(
      {
        url: apiEndPoint,
        method: "POST",
        params: {
          asofdate: encodeURIComponent(
            `${moment
              .utc(new Date())
              .subtract(1, "months")
              .endOf("month")
              .format("MM-DD-YYYY")}`
          ),
        },
        data: scenarios,
        cancelToken: this.defaultPortfolioTokenSource.token,
      },
      this.context
    );
  };

  getDefaultPortfolioData = async (dealId) => {
    const apiEndPoint = apiRoutes.VectorDeal_GetPortfolioReportByDealId({
      dealid: dealId,
      asOfDate: encodeURIComponent(
        `${moment
          .utc(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("MM-DD-YYYY")}`
      ),
    });

    this.selectedPortfolioTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };

  getDealLiabilityModelOutput = async (dealId, modelType = null, asOfDate) => {
    const apiEndPoint =
      apiRoutes.LiabilityModel_GetDealLiabilityModelOutputByDealId();
    this.selectedPortfolioTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        params: {
          dealId: dealId,
          liabilityModelType: modelType,
          asOfDate: asOfDate,
        },
        method: "GET",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };

  getAssetsList = async () => {
    return apiRequest(
      {
        url: apiRoutes.VectorDeal_GetAssets,
        method: "GET",
      },
      this.context
    );
  };

  getPerformanceMonitorData = async (dealId) => {
    const apiEndPoint = apiRoutes.LiabilityModel_GetPerformanceMonitor;
    this.selectedPortfolioTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        params: { dealId: dealId },
        method: "GET",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };

  getExpectedLossData = async (dealId, modelType = null, asOfDate) => {
    const apiEndPoint = apiRoutes.ExpectedLoss_GetExpectedLoss({
      dealId: dealId,
    });
    this.selectedPortfolioTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        params: {
          liabilityModelType: modelType,
          liabAsOfDate: asOfDate,
        },
        method: "GET",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };

  getDealScenarioSummaries = async (assets) => {
    const apiEndPoint = apiRoutes.VectorDeal_GetScenarioSummaries;
    this.selectedPortfolioTokenSource = axios.CancelToken.source();

    return apiRequest(
      {
        url: apiEndPoint,
        data: assets,
        method: "POST",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };

  getDealsData = async (dealId) => {
    this.loanSummaryTokenSource = axios.CancelToken.source();
    let apiEndPoint = apiRoutes.VectorDeal_GetDealsByDealId({
      dealId,
    });
    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.loanSummaryTokenSource.token,
      },
      this.context
    );
  };

  updateStateIfStatusOk = async ({ data }) => {
    const { deal } = this.state;
    const { dealName } = deal;
    const urlValues = utils.parseUrlObject(new URL(window.location.href));
    const pathValues = urlValues.pathValues;
    const dealId = pathValues[pathValues.length - 1];
    const originalScenarioList = data.fleetInfo.map((o) => o.scenario.name);
    const checkAppraisalData = data.fleetInfo.map((d) => d.appraisal);

    let lds = await this.getLinkedDeals();
    if (lds.status === response.OK) {
      let plid = lds.data.filter((d) => d.assetModelId == dealId);
      if (plid.length > 0) {
        this.setState({ pipelineDealId: plid[0].id });
      }
    }

    let commonScenarios = [];
    if (deal) {
      try {
        let assets = deal.assets.map((ast) => {
          return {
            id: ast.id,
            assetType: ast.assetType,
          };
        });

        let getScenarioSummaries = null;
        getScenarioSummaries = await this.getDealScenarioSummaries(assets);
        if (getScenarioSummaries.status === response.OK) {
          data.fleetInfo.forEach((element) => {
            let scenarioSummaries = _.find(
              getScenarioSummaries.data.assetToScenarios,
              (o) => o.serialNumber === element.serialNumber
            );
            element.scenarioSummaries = scenarioSummaries.simulatedSummaries;
          });
          commonScenarios = getScenarioSummaries.data.commonScenarios;
        }
      } catch (ex) {
        console.log(ex);
        if (axios.isCancel(ex)) return;
      }
    }

    sessionStorage.setItem(
      dealName,
      data.fleetInfo.map(
        (o) => _.find(o.scenarioSummaries, (s) => s.name === o.scenario.name).id
      )
    );

    const fleetSummary = this.formatData(
      [data],
      constants.FLEET_SUMMARY,
      mapToViewModels.fleetSummaryViewModel
    );

    const leaseSummary = this.formatData(
      [data],
      constants.LEASE_SUMMARY,
      mapToViewModels.leaseSummaryViewModel
    );

    if (deal && fleetSummary) {
      let dealSNs = _(deal.assets)
        .map((o) => o.serialNumber)
        .sort()
        .value();
      let simulatedSNs = fleetSummary[0].data.fleetInfo.data.map(
        (o) => o.serialNumber.serialNumber
      );
      let diff = dealSNs.filter((x) => simulatedSNs.indexOf(x) === -1);
      if (diff.length >= 1) this.setState({ missingSims: diff });
    }

    this.setState({
      originalScenarioList,
      fleetSummary,
      leaseSummary: leaseSummary[0] ? leaseSummary : null,
      report: data,
      noSimAssets: false,
      appraisalError: checkAppraisalData.includes(null) ? true : false,
      commonScenarios: commonScenarios,
    });
  };

  handleRefreshLiabilityData = async (dealId, item) => {
    const { dataRefrshRenderKey, selectedModelType, liabilityError } =
      this.state;
    let newRenderKey = dataRefrshRenderKey + 1;
    try {
      let liabResp = null;
      if (item) {
        liabResp = await this.getDealLiabilityModelOutput(
          dealId,
          item.modelType,
          item.asOfDate
        );
      } else {
        liabResp = await this.getDealLiabilityModelOutput(dealId);
      }

      if (liabResp.data) {
        const liabilityCashflow = this.formatData(
          [liabResp.data],
          "liability",
          mapToViewModels.liabilityCashFlows
        );

        const liabilityTranches = this.formatData(
          [liabResp.data],
          "liability",
          mapToViewModels.liabilityLoans
        );

        this.setState({
          liabilityData: liabResp.data,
          liabilityError: false,
          liabilityCashflow: liabilityCashflow,
          liabilityTranches: liabilityTranches,
          selectedModelType: item ? item.label : selectedModelType,
          dataRefrshRenderKey: newRenderKey,
        });

        if (
          liabResp.data.liabilityModelData &&
          liabResp.data.liabilityModelData.assetInfo[0]?.scenarioName
        ) {
          let liabAssetInfo = [];
          liabResp.data.liabilityModelData.assetInfo.forEach((asset) => {
            liabAssetInfo.push({
              MSN: asset.msn,
              AssetType: asset.assetType,
              ScenarioDate: asset.scenarioDate,
              ScenarioName: asset.scenarioName,
            });
          });
          let scenids = await this.getScenarioIds(liabAssetInfo);
          if (
            scenids.status === response.OK &&
            !_.isEmpty(scenids.data) &&
            !scenids.data.includes(0)
          ) {
            //console.log(scenids.data);
            let resp = await this.getPortfolioDataByScenarios(scenids.data);
            if (resp.status === response.OK) this.updateStateIfStatusOk(resp);
          }
        }
      } else this.setState({ liabilityError: true });
    } catch (ex) {
      console.log(ex);
      if (axios.isCancel(ex)) return;
      this.setState({
        liabilityData: null,
        liabilityCashflow: null,
        liabilityTranches: null,
        liabilityError: true,
        selectedModelType: item ? item.label : selectedModelType,
        dataRefrshRenderKey: newRenderKey,
      });
    }

    if (liabilityError === false) {
      newRenderKey = dataRefrshRenderKey + 1;
      try {
        let expectedLossResp = null;
        if (item) {
          expectedLossResp = await this.getExpectedLossData(
            dealId,
            item.modelType,
            item.asOfDate
          );
        } else {
          setTimeout(() => {
            console.log(
              "wait for expected loss to finish calculating in the background"
            );
          }, 10000);

          expectedLossResp = await this.getExpectedLossData(dealId);
        }

        if (expectedLossResp.status === response.OK) {
          const expectedLossTranchData = this.formatData(
            [expectedLossResp.data],
            "expectedLoss",
            mapToViewModels.expectedLossTranches
          );

          this.setState({
            expectedLossTranches: expectedLossTranchData,
            expectedLossData: expectedLossResp.data,
            dataRefrshRenderKey: newRenderKey,
          });
        } else {
          this.setState({
            expectedLossTranches: null,
            expectedLossData: null,
            dataRefrshRenderKey: newRenderKey,
          });
        }
      } catch (ex) {
        this.setState({
          expectedLossTranches: null,
          expectedLossData: null,
          dataRefrshRenderKey: newRenderKey,
        });
      }
    }
  };

  getLinkedDeals = async () => {
    let apiEndPoint = apiRoutes.VectorDeal_LinkedDeals;
    this.selectedPortfolioTokenSource = axios.CancelToken.source();
    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };
  getScenarioIds = async (info) => {
    let apiEndPoint = apiRoutes.VectorDeal_GetScenarioIds;
    return apiRequest(
      {
        url: apiEndPoint,
        data: info,
        method: "POST",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };

  componentDidMount = async () => {
    const { deal } = this.props;
    const urlValues = utils.parseUrlObject(new URL(window.location.href));
    const pathValues = urlValues.pathValues;
    const dealId = pathValues[pathValues.length - 1];
    const scenarios = urlValues.scenarios;

    try {
      let assetResp = await this.getAssetsList();

      if (assetResp.status === response.OK)
        this.setState({
          assetList: assetResp.data,
        });
    } catch (ex) {}

    if (deal === undefined) {
      let dealData = await this.getDealsData(dealId);
      if (dealData.status === response.OK) {
        this.setState({ deal: dealData.data });
      }
    } else {
      this.setState({ deal: deal });
    }
    let liabResp = null;
    try {
      liabResp = await this.getDealLiabilityModelOutput(dealId);

      if (liabResp.data) {
        const liabilityCashflow = this.formatData(
          [liabResp.data],
          "liability",
          mapToViewModels.liabilityCashFlows
        );

        const liabilityTranches = this.formatData(
          [liabResp.data],
          "liability",
          mapToViewModels.liabilityLoans
        );

        this.setState({
          liabilityData: liabResp.data,
          liabilityCashflow: liabilityCashflow,
          liabilityTranches: liabilityTranches,
        });
      } else this.setState({ liabilityError: true });
    } catch (ex) {
      console.log(ex);
      if (axios.isCancel(ex)) return;
      this.setState({ liabilityError: true });
    }
    // wait for liabilitymode response then populate scenarios
    //console.log(liabResp);
    try {
      let resp = null;
      if (scenarios)
        resp = await this.getPortfolioDataByScenarios(
          scenarios.split(",").map(Number)
        );
      else if (
        liabResp &&
        liabResp.data &&
        liabResp.data.liabilityModelData &&
        liabResp.data.liabilityModelData.assetInfo[0]?.scenarioName
      ) {
        let liabAssetInfo = [];
        liabResp.data.liabilityModelData.assetInfo.forEach((asset) => {
          liabAssetInfo.push({
            MSN: asset.msn,
            AssetType: asset.assetType,
            ScenarioDate: asset.scenarioDate,
            ScenarioName: asset.scenarioName,
          });
        });
        let scenids = await this.getScenarioIds(liabAssetInfo);
        if (
          scenids.status === response.OK &&
          !_.isEmpty(scenids.data) &&
          !scenids.data.includes(0)
        ) {
          //console.log(scenids.data);
          resp = await this.getPortfolioDataByScenarios(scenids.data);
          if (resp.status !== response.OK)
            resp = await this.getDefaultPortfolioData(dealId);
        } else resp = await this.getDefaultPortfolioData(dealId);
      } else resp = await this.getDefaultPortfolioData(dealId);

      this.updateStateIfStatusOk(resp);
    } catch (ex) {
      console.log(ex);
      if (axios.isCancel(ex)) {
        console.log(ex);
        return;
      }
      if (ex.response && ex.response.status === 400) {
        this.setState({ errors: "errors", noSimAssets: true });
      } else if (ex.response && ex.response.status >= 404) {
        this.setState({ errors: "errors", unAbletoLoad: true });
      } else if (!ex.response) {
        this.setState({ errors: "errors", unAbletoLoad: true });
      }
    }

    try {
      let expectedLossResp = await this.getExpectedLossData(dealId);

      if (expectedLossResp.status === response.OK) {
        const expectedLossTranchData = this.formatData(
          [expectedLossResp.data],
          "expectedLoss",
          mapToViewModels.expectedLossTranches
        );

        this.setState({
          expectedLossTranches: expectedLossTranchData,
          expectedLossData: expectedLossResp.data,
        });
      }
    } catch (ex) {}

    // try {
    //   let perfResp = await this.getPerformanceMonitorData(dealId);

    //   if (perfResp.status === response.OK)
    //     this.setState({
    //       performanceData: perfResp.data,
    //     });
    // } catch (ex) {}
  };

  componentWillUnmount() {
    const msg = "Operation Canceled due to component unmount";
    if (this.defaultPortfolioTokenSource)
      this.defaultPortfolioTokenSource.cancel(msg);
    if (this.selectedPortfolioTokenSource)
      this.selectedPortfolioTokenSource.cancel(msg);
  }

  updateOptionsItemsStateHelper(obj, options, values) {
    let optionsItems = { ...obj.optionsItems };

    for (var option of options) {
      switch (option) {
        case constants.REFRESH:
          let refresh = { ...optionsItems.refresh };
          refresh.disabled = values.disabled;
          optionsItems.refresh = refresh;
          break;
        case constants.RESET:
          let reset = { ...optionsItems.reset };
          reset.disabled = values.disabled;
          optionsItems.reset = reset;
          break;
        case constants.PAGINATION:
          let pagination = { ...optionsItems.pagination };
          pagination.page = values.page;
          pagination.rowsPerPage = values.rowsPerPage || pagination.rowsPerPage;
          optionsItems.pagination = pagination;
          break;
        case constants.FILTER:
          break;
        default:
          break;
      }
    }

    obj.optionsItems = optionsItems;
  }

  updateFilterList(action, tableName, props = null) {
    let leaseSummary = [...this.state.leaseSummary];

    leaseSummary[0] = { ...leaseSummary[0] };
    let data = { ...leaseSummary[0].data };
    let table = { ...data[tableName] };
    this.updateOptionsItemsStateHelper(table, [constants.PAGINATION], {
      page: 0,
      rowsPerPage: null,
    });
    let optionsItems = { ...table.optionsItems };
    let filter = { ...optionsItems.filter };
    let filterList = { ...filter.filterList };
    let filterData = filter.filterData;

    switch (action) {
      case constants.UPDATE:
        let { column, value } = props;
        const allSelected = _.findIndex(value, function (n) {
          return n === constants.ALL;
        });

        if (allSelected !== -1 && value.length >= filterList[column].length) {
          value = filterData[column];
        } else if (allSelected !== -1) {
          value = _.without(value, constants.ALL);
        } else if (
          allSelected === -1 &&
          filterList[column].length >= filterData[column].length
        ) {
          value = [];
        } else if (
          allSelected === -1 &&
          value.length === filterData[column].length - 1
        ) {
          value = filterData[column];
        }

        filterList[column] = value;
        break;
      case constants.RESET:
        filterList = _.cloneDeep(filterData);
        break;
      default:
        break;
    }

    filter.filterList = filterList;
    optionsItems.filter = filter;
    table.optionsItems = optionsItems;
    data[tableName] = table;
    leaseSummary[0].data = data;

    this.setState({ leaseSummary });
  }

  handleFilterUpdate = (_, value, column, tableName) => {
    this.updateFilterList(constants.UPDATE, tableName, { value, column });
  };

  handleFilterReset = (tableName) => {
    this.updateFilterList(constants.RESET, tableName);
  };

  handlePaginationChange =
    (tableName, newPage) =>
    (rowsPerPage = null) => {
      let obj =
        tableName === constants.fleetInfo
          ? this.state.fleetSummary
          : tableName === "liabilityCashflows"
          ? this.state.liabilityCashflow
          : tableName === "liabilityTranches"
          ? this.state.liabilityTranches
          : this.state.leaseSummary;

      obj[0] = { ...obj[0] };
      let data = { ...obj[0].data };
      let table = { ...data[tableName] };

      this.updateOptionsItemsStateHelper(table, [constants.PAGINATION], {
        page: newPage,
        rowsPerPage: rowsPerPage,
      });

      data[tableName] = table;
      obj[0].data = data;

      if (tableName === constants.fleetInfo)
        this.setState({ fleetSummary: obj });
      else if (tableName === "liabilityCashflows")
        this.setState({ liabilityCashflow: obj });
      else this.setState({ leaseSummary: obj });
    };

  handleOpenLoansTableEditing = (shouldOpen) => {
    this.setState({
      editLoans: shouldOpen,
    });
  };

  updateFleetInfoDataScenario(fleetInfoData, value) {
    let scenario = { ...fleetInfoData.scenario };

    scenario.value = value;
    fleetInfoData.scenario = scenario;
  }

  updateSimulateReportLinkHelper(fleetInfoData, enabled) {
    let assetModel = { ...fleetInfoData.assetModel };
    let linkProps = { ...assetModel.linkProps };
    linkProps.disabled = enabled;

    let state = { ...linkProps.state };
    state.currScenario = _.find(
      state.scenarioList,
      (o) => o.name === fleetInfoData.scenario.value
    );

    linkProps.state = state;
    assetModel.linkProps = linkProps;
    fleetInfoData.assetModel = assetModel;
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  handleScenarioChangeHelper(action, props) {
    let fleetSummary = [...this.state.fleetSummary];
    fleetSummary[0] = { ...fleetSummary[0] };
    let data = { ...fleetSummary[0].data };
    let fleetInfo = { ...data.fleetInfo };

    this.updateOptionsItemsStateHelper(
      fleetInfo,
      [constants.REFRESH, constants.RESET],
      { disabled: props.buttonDisabled }
    );

    let fleetInfoData = [...fleetInfo.data];

    switch (action) {
      case constants.COMMON_SCENARIO_UPDATE:
        for (let i = 0; i < fleetInfoData.length; i++) {
          fleetInfoData[i] = { ...fleetInfoData[i] };
          this.updateFleetInfoDataScenario(
            fleetInfoData[i],
            props.selectedCommonScenario
          );
          this.updateSimulateReportLinkHelper(
            fleetInfoData[i],
            props.linkEnabled
          );
        }
        break;
      case constants.SINGEL_SCENARIO_UPDATE:
        fleetInfoData[props.id] = { ...fleetInfoData[props.id] };
        this.updateFleetInfoDataScenario(
          fleetInfoData[props.id],
          props.targetScenario
        );
        this.updateSimulateReportLinkHelper(
          fleetInfoData[props.id],
          props.linkEnabled
        );
        break;
      case constants.RESET:
        for (let i = 0; i < fleetInfoData.length; i++) {
          const scenario = this.state.originalScenarioList[i];
          fleetInfoData[i] = { ...fleetInfoData[i] };
          this.updateFleetInfoDataScenario(fleetInfoData[i], scenario);
          this.updateSimulateReportLinkHelper(
            fleetInfoData[i],
            props.linkEnabled
          );
        }
        break;
      default:
        break;
    }

    fleetInfo.data = fleetInfoData;

    data.fleetInfo = fleetInfo;
    fleetSummary[0].data = data;

    this.setState({
      selectedCommonScenario: props.selectedCommonScenario,
      fleetSummary,
    });
  }

  handleOpenCommonScenarioDialog = () => {
    this.handleAlertDialogVisible(
      true,
      null,
      null,
      constants.COMMON_SCENARIO_UPDATE
    );
  };

  handleCommonScenarioChangeEvt = () => {
    const { selectedCommonScenario } = this.state;
    this.handleAlertDialogVisible(false);
    this.handleScenarioChangeHelper(constants.COMMON_SCENARIO_UPDATE, {
      selectedCommonScenario,
      buttonDisabled: false,
      linkEnabled: false,
    });
  };

  handleSingleScenarioChangeEvt = (_, targetScenario, id) => {
    this.handleScenarioChangeHelper(constants.SINGEL_SCENARIO_UPDATE, {
      selectedCommonScenario: null,
      id,
      targetScenario,
      buttonDisabled: false,
      linkEnabled: false,
    });
  };

  handleReset = () => {
    this.handleScenarioChangeHelper(constants.RESET, {
      selectedCommonScenario: null,
      buttonDisabled: true,
      linkEnabled: true,
    });
  };

  handleRenderCommonScenarioSelection = () => {
    const { selectedCommonScenario, commonScenarios } = this.state;

    const selectionChange = (event) => {
      this.setState({
        selectedCommonScenario: event.target.value,
      });
    };

    return (
      <Grid container spacing={2} style={{ minWidth: 80, padding: 10 }}>
        <Grid item sm={12} style={{ minWidth: 80 }}>
          <FormControl style={{ minWidth: 80, width: "100%" }}>
            <InputLabel id='select-export-file'>Scenario</InputLabel>
            <Select
              labelId='select-export-file'
              id='demo-simple-select'
              value={selectedCommonScenario}
              onChange={selectionChange}
              style={{ minWidth: 80, width: "100%" }}>
              {commonScenarios.map((s, i) => (
                <MenuItem key={i} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };
  handleGetDefaultOutput = async () => {
    let id = this.state.deal.dealId;
    const { dealName } = this.state.deal;
    sessionStorage.setItem(dealName, "");
    try {
      this.setState({ missingSims: [] });
      this.handleSnackerBarOpen(true, "info", "Simulating Portfolio Base Case");
      const resp = await this.getDefaultPortfolioData(id);
      this.updateStateIfStatusOk(resp);
      this.handleSnackerBarOpen(
        true,
        "success",
        "Page Updated with Default Scenarios"
      );
      let newUrl = id;
      window.history.pushState({}, null, newUrl);
    } catch (ex) {
      if (axios.isCancel(ex)) return;
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        this.setState({ errors: "error", noSimAssets: true });
      } else if (ex.response && ex.response.status === 404) {
        this.setState({ errors: "errors", unAbletoLoad: true });
      }
    }
  };

  handleRefresh = async () => {
    const { dealName } = this.state.deal;
    const scenarios = this.state.fleetSummary[0].data.fleetInfo.data.map(
      (o) => o.scenario
    );

    sessionStorage.setItem(
      dealName,
      scenarios.map(
        (scenario) =>
          _.find(scenario.scenarioSummaries, (s) => s.value === scenario.value)
            .id
      )
    );

    // Reset for rendering loading component
    this.setState({
      originalScenarioList: [],
      fleetSummary: null,
      leaseSummary: null,
      error: null,
    });

    const sendMe = scenarios.map(
      (scenario) =>
        _.find(scenario.scenarioSummaries, (s) => s.value === scenario.value).id
    );

    try {
      const resp = await this.getPortfolioDataByScenarios(sendMe);
      this.updateStateIfStatusOk(resp);
      let newUrl = this.state.deal.dealId + `?scenarios=${sendMe}`;
      window.history.pushState({}, null, newUrl);
    } catch (ex) {
      if (axios.isCancel(ex)) return;
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        this.setState({ errors: "error", noSimAssets: true });
      } else if (ex.response && ex.response.status === 404) {
        this.setState({ errors: "errors", unAbletoLoad: true });
      }
    }
  };

  renderBreadCrumbs() {
    const { deal } = this.state;
    const { sponsor, dealName } = deal;
    const dealInfo = _.template(`Portfolio - <%= sponsor %>/<%= dealName %>`);
    return [
      {
        label: "Deals",
        type: constants.LINK,
        tooltip: "Back to Deals",
        props: { to: "/deals", handleClick: null },
      },
      {
        label: dealInfo({ sponsor, dealName }),
        type: constants.TEXT,
        tooltip: "Portfolio",
      },
    ];
  }

  handleExportButtonClick = async () => {
    const { deal, sheetNames, selectedSheets } = this.state;
    let acsheets = ["AC_CV", "AC_FV"];
    let engsheets = ["ENG_CV", "ENG_FV"];

    //if no engine assets or aircraft assets, add related sheetnames to excludeSheets
    if (!deal.assets.some((x) => x.assetType === "aircraft"))
      this.setState({
        selectedSheets: selectedSheets.filter((item) => {
          return acsheets.indexOf(item) === -1;
        }),
      });
    if (!deal.assets.some((x) => x.assetType === "engine"))
      this.setState({
        selectedSheets: selectedSheets.filter((item) => {
          return engsheets.indexOf(item) === -1;
        }),
      });

    const savedScenarios = sessionStorage.getItem(deal.dealName);
    let scenarios = null;
    if (savedScenarios) {
      scenarios = savedScenarios.split(",").map((e) => parseInt(e));
      this.setState({ exportFileName: "Portfolio " + deal.dealName });
    } else {
      this.setState({
        exportFileName: "Portfolio " + deal.dealName,
        selectedExportFile: "liability",
      });
    }
    if (sheetNames.length === 0) {
      let sheetsData = await this.getSheetnames();
      if (sheetsData.status === response.OK) {
        this.setState({ sheetNames: sheetsData.data });
      } else {
        this.setState({
          sheetNames: [
            "Scenario",
            "Aggregate_Data",
            "Mx_Events",
            "Rda_Events",
            "Engines",
            "LLP_Events",
            "Lease_Report",
            "Deal_Info",
            "Asset_Info",
            "AC_Info",
            "AC_CV",
            "AC_FV",
            "ENG_Info",
            "ENG_CV",
            "ENG_FV",
            "Simulation_Params",
            "MR",
          ],
        });
      }
    }

    this.handleAlertDialogVisible(
      true,
      scenarios,
      deal.dealName,
      "export_file"
    );
  };

  renderExportButton = () => {
    return (
      <Tooltip title='Export Portfolio Report' aria-label='Export Portfolio'>
        <Button
          style={{ maxHeight: 28, marginLeft: 16 }}
          variant='outlined'
          color='primary'
          onClick={() => this.handleExportButtonClick()}>
          <span style={{ margin: "0px 8px 0px 8px" }}>Export</span>
        </Button>
      </Tooltip>
    );
  };

  renderUploadButton = () => {
    return (
      <Tooltip title='Upload Selector' aria-label='Upload Selector'>
        <Button
          style={{ maxHeight: 28, marginLeft: 16 }}
          variant='outlined'
          onClick={() =>
            this.handleAlertDialogVisible(true, null, null, "upload_file")
          }
          color='primary'>
          <span style={{ margin: "0px 8px 0px 8px" }}>Upload</span>
        </Button>
      </Tooltip>
    );
  };

  renderExpectedLossButton = () => {
    const { deal } = this.state;

    return (
      <Tooltip
        title='Run Expected Loss Simulation'
        aria-label='Run Expected Loss Simulation'>
        <Button
          style={{ maxHeight: 28, marginLeft: 16 }}
          variant='outlined'
          onClick={() => this.runExpectedLossSimulation(deal.dealId)}
          color='primary'>
          <span style={{ margin: "0px 8px 0px 8px" }}>EL Sim</span>
        </Button>
      </Tooltip>
    );
  };

  handleSnackerBarOpen = (open, severity, msg, duration = 2000) => {
    this.setState({
      snackOpen: open,
      severity: severity,
      message: msg,
      duration: duration,
    });
  };

  handleAlertDialogVisible = (
    open,
    selectedExportScenarios,
    selectedExportDealName,
    switchDialogRender
  ) => {
    this.setState({
      isAlertDialogOpen: open,
      selectedExportScenarios: selectedExportScenarios,
      selectedExportDealName: selectedExportDealName,
      switchDialogRender: switchDialogRender,
    });
  };

  handleExportFileNameChange = () => (event) => {
    this.setState({ exportFileName: event.target.value });
  };

  runExpectedLossSimulation = async (dealId) => {
    const { dataRefrshRenderKey, liabilityData } = this.state;
    let lmexNullCheck = false;

    liabilityData.liabilityModelData.loanDetails.forEach((e) => {
      if ((e.assetRating === null) | (e.strategicValue === null)) {
        lmexNullCheck = true;
      }
    });

    if (lmexNullCheck) {
      this.handleSnackerBarOpen(
        true,
        "warning",
        "Expected Loss did not run, please fill in missing collateral ratings and/or strategic values for assets in Deal"
      );
    } else {
      let newRenderKey = dataRefrshRenderKey + 1;

      this.loanSummaryTokenSource = axios.CancelToken.source();

      const resp = await apiRequest(
        {
          url: apiRoutes.ExpectedLoss_RunExpectedLossSimulationByDealId,
          method: "GET",
          params: {
            dealId: dealId,
            liabilityModelType: liabilityData.modelType,
            asOfDate: liabilityData.asOfDate,
          },
          cancelToken: this.loanSummaryTokenSource.token,
        },
        this.context
      );

      if (resp.status === response.OK) {
        this.handleSnackerBarOpen(
          true,
          "success",
          "Expected Loss Simulation Completed"
        );

        const expectedLossTranchData = this.formatData(
          [resp.data],
          "expectedLoss",
          mapToViewModels.expectedLossTranches
        );

        this.setState({
          expectedLossTranches: expectedLossTranchData,
          expectedLossData: resp.data,
          dataRefrshRenderKey: newRenderKey,
        });
      } else {
        this.handleSnackerBarOpen(
          true,
          "error",
          "Expected Loss Simulation Failed"
        );
      }
    }
  };

  exportFile = async () => {
    const {
      deal,
      selectedExportFile,
      selectedExportFormat,
      shareAllChecked,
      selectedSheets,
      includeCompRDAExposure,
      includeMR,
      cashflowHistoryRenderKey,
    } = this.state;

    this.handleSnackerBarOpen(
      true,
      "info",
      <Box
        style={{
          width: 280,
          display: "flex",
          justifyContent: "space-between",
        }}>
        Processing
        <CircularProgress color='inherit' size={20} />
      </Box>,
      null
    );
    let newselected = selectedSheets;
    if (includeCompRDAExposure) newselected.push("Component_RedelivCompExp");

    let exportFileObj = {
      userName: this.context.accounts[0].username,
      fileName: this.state.exportFileName + selectedExportFormat,
      fileFormat: selectedExportFormat === ".json" ? "JSON" : "Excel",
      cashFlowModel: {
        scenarioIds: this.state.selectedExportScenarios,
        asofdate: encodeURIComponent(
          `${moment.utc(new Date()).endOf("month").format("MM-DD-YYYY")}`
        ),
      },
      sheets: newselected,
      dealId: deal.dealId,
    };

    const resp =
      selectedExportFile === "portfolio"
        ? await apiRequest(
            {
              url: apiRoutes.VectorDeal_ExportPortfolio({
                dealId: deal.dealId,
              }),
              method: "POST",
              data: exportFileObj,
              params: {
                isJsonExport: selectedExportFormat === ".json" ? true : false,
                isShareAll: shareAllChecked,
                includeMR: includeMR,
              },
            },
            this.context
          )
        : selectedExportFile === "expectedLoss"
        ? await apiRequest(
            {
              url: apiRoutes.ExpectedLoss_ExportExpectedLoss({
                dealId: deal.dealId,
              }),
              method: "POST",
              params: {
                isJsonExport: selectedExportFormat === ".json" ? true : false,
                userName: this.context.accounts[0].username,
              },
            },
            this.context
          )
        : await apiRequest(
            {
              url: apiRoutes.LiabilityModel_ExportLiabilityFile,
              method: "POST",
              params: {
                dealId: deal.dealId,
                userName: this.context.accounts[0].username,
                lm_modeltype: this.state.liabilityData.modelType,
                lm_asofdate: this.state.liabilityData.asOfDate,
                isJsonExport: selectedExportFormat === ".json" ? true : false,
              },
            },
            this.context
          );

    if (resp.status === response.OK) {
      this.handleAlertDialogVisible(false);

      this.handleSnackerBarOpen(
        true,
        "success",
        `${selectedExportFile.toUpperCase()} Report Generated Successfully`
      );

      if (
        selectedExportFile === "portfolio" &&
        selectedExportFormat === ".json" &&
        shareAllChecked
      ) {
        var newKey = cashflowHistoryRenderKey + 1;
        this.setState({
          cashflowHistoryRenderKey: newKey,
        });
      }
    } else {
      this.handleSnackerBarOpen(
        true,
        "error",
        `Error Generating ${selectedExportFile.toUpperCase()} Report`
      );
    }
  };

  handleUpdateLiabilityData = (liabData) => {
    const liabilityTranches = this.formatData(
      [liabData],
      "liability",
      mapToViewModels.liabilityLoans
    );

    this.setState({
      liabilityData: liabData,
      liabilityTranches: liabilityTranches,
    });
  };

  uploadLiabilityFile = async (file) => {
    const { deal } = this.state;
    let liabilityData = new FormData();

    liabilityData.append("fileName", file.name);
    liabilityData.append("userName", this.context.accounts[0].username);
    liabilityData.append("fileFormat", "Excel");
    liabilityData.append("fileData", file);

    this.handleSnackerBarOpen(
      true,
      "info",
      <Box
        style={{
          width: 280,
          display: "flex",
          justifyContent: "space-between",
          color: "white",
        }}>
        Processing
        <CircularProgress color='inherit' size={20} />
      </Box>,
      null
    );

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "binary" });
        const ws = wb.Sheets["Asset_Info"];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then(async (data) => {
      // check the serial numbers in trunch table against the assets in deal
      let dealSerial = _(deal.assets)
        .map((o) => o.serialNumber)
        .sort()
        .value();
      let uploadSerial = _(data)
        .map((o) => o["SerialNumber"])
        .without(undefined, null, "")
        .sort()
        .value();

      let checkSerialNum = _.isEqual(dealSerial, uploadSerial);

      if (checkSerialNum) {
        const resp = await apiRequest(
          {
            url: apiRoutes.LiabilityModel_UploadLiabilityFile,
            method: "POST",
            data: liabilityData,
            params: {
              dealId: deal.dealId,
              dealName: deal.dealName,
              assetPoolId: deal.assetPoolId,
            },
          },
          this.context
        );
        if (resp.status === response.OK) {
          this.handleSnackerBarOpen(
            true,
            "success",
            "Liability Model File Uploaded To Server For Further Processing"
          );
          this.handleAlertDialogVisible(false);
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        } else {
          this.handleSnackerBarOpen(
            true,
            "error",
            "Error Uploading Liability Model File"
          );
        }
      } else {
        this.handleSnackerBarOpen(
          true,
          "error",
          "The serial numbers in the liability model do not match those in the deal"
        );
      }
    });
  };
  getSheetnames = async () => {
    this.loanSummaryTokenSource = axios.CancelToken.source();
    let apiEndPoint = apiRoutes.Export_GetSheetNames;
    return apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
        cancelToken: this.selectedPortfolioTokenSource.token,
      },
      this.context
    );
  };

  renderAlertDialogExportBody = (exportFileName) => {
    const {
      selectedExportFile,
      selectedExportFormat,
      shareAllChecked,
      includeMR,
      includeCompRDAExposure,
      selectedSheets,
      sheetNames,
      liabilityError,
      expectedLossData,
    } = this.state;

    var checkboxitems = sheetNames.map((o) => {
      return {
        label: o,
        value: o,
      };
    });

    const selectionChange = (event) => {
      var targetFile = event.target.value;
      if (targetFile === "liability" || targetFile === "expectedLoss")
        this.setState({
          selectedExportFile: targetFile,
          selectedExportFormat: ".xlsx",
        });
      else
        this.setState({
          selectedExportFile: targetFile,
        });
    };

    const formatSelectionChange = (event) => {
      this.setState({
        selectedExportFormat: event.target.value,
        shareAllChecked: false,
      });
    };

    const handleCheckBoxChange = (event) => {
      this.setState({
        shareAllChecked: event.target.checked,
      });
    };
    const handleMRCheckBoxChange = (event) => {
      this.setState({
        includeMR: event.target.checked,
      });
    };
    const handleExpCheckBoxChange = (event) => {
      this.setState({
        includeCompRDAExposure: event.target.checked,
      });
    };

    const handleSheetChange = (event) => {
      this.setState({ selectedSheets: event });
    };

    return (
      <Grid container spacing={2} style={{ minWidth: 80, padding: 10 }}>
        <Grid item sm={12} style={{ minWidth: 80 }}>
          <FormControl style={{ minWidth: 80, width: "100%" }}>
            <InputLabel id='select-export-file'>File To Export</InputLabel>
            <Select
              labelId='select-export-file'
              id='select-file'
              value={selectedExportFile}
              onChange={selectionChange}
              style={{ minWidth: 80, width: "100%" }}>
              <MenuItem value={"portfolio"}>Cashflow Report</MenuItem>
              {liabilityError ? (
                <div></div>
              ) : (
                <MenuItem value={"liability"}>Liability Report</MenuItem>
              )}
              {_.isEmpty(expectedLossData) ? (
                <div></div>
              ) : (
                <MenuItem value={"expectedLoss"}>Expected Loss</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={12} style={{ minWidth: 80 }}>
          <FormControl style={{ minWidth: 80, width: "100%" }}>
            <Select
              labelId='select-export-format'
              id='select-format'
              value={selectedExportFormat}
              onChange={formatSelectionChange}
              style={{ minWidth: 80, width: "100%" }}>
              <MenuItem value={".xlsx"}>EXCEL</MenuItem>
              {selectedExportFile === "portfolio" ? (
                <MenuItem value={".json"}>JSON</MenuItem>
              ) : (
                <div></div>
              )}
            </Select>
          </FormControl>
        </Grid>
        {selectedExportFile === "portfolio" &&
        selectedExportFormat === ".json" ? (
          <div>
            <Grid item sm={12} style={{ minWidth: 80 }}>
              <FormControlLabel
                label='Share to all users'
                style={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={shareAllChecked}
                    onChange={handleCheckBoxChange}
                  />
                }
              />
            </Grid>
            <Grid item sm={12} style={{ minWidth: 80 }}>
              <FormControlLabel
                label='Include MR'
                style={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={includeMR}
                    onChange={handleMRCheckBoxChange}
                  />
                }
              />
              <FormControlLabel
                label='Include RDA exp By Comp'
                style={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={includeCompRDAExposure}
                    onChange={handleExpCheckBoxChange}
                  />
                }
              />
            </Grid>
          </div>
        ) : selectedExportFile === "portfolio" ? (
          <VfCheckBox
            label={"Select Sheets to Include"}
            items={checkboxitems}
            required={false}
            selected={selectedSheets}
            onChange={handleSheetChange}
          />
        ) : null}

        {selectedExportFile === "portfolio" ? (
          <Grid item sm={12}>
            <FormControl style={{ width: "100%" }}>
              <TextField
                id='filled-basic'
                label='Export File Name'
                variant='filled'
                defaultValue={exportFileName}
                onBlur={this.handleExportFileNameChange()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      {selectedExportFormat}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      fleetSummary,
      leaseSummary,
      report,
      noSimAssets,
      unAbletoLoad,
      snackOpen,
      severity,
      message,
      duration,
      isAlertDialogOpen,
      exportFileName,
      tabValue,
      liabilityData,
      liabilityError,
      appraisalError,
      deal,
      liabilityCashflow,
      liabilityTranches,
      expectedLossTranches,
      expectedLossData,
      performanceData,
      switchDialogRender,
      selectedUploadFile,
      cashflowHistoryRenderKey,
      dataRefrshRenderKey,
      editLoans,
      missingSims,
      pipelineDealId,
      selectedModelType,
      assetList,
    } = this.state;
    //console.log(appConfig);
    let dmurl = appConfig.REACT_APP_DEALMANAGER_URL + "pipeline/deals/";
    let liabFileName = null;
    if (liabilityData) {
      let texts = liabilityData.fileLocation.split("/");
      liabFileName = texts[texts.length - 1];
    }

    // console.log(this.state);

    return fleetSummary || liabilityData ? (
      <ErrorBoundary>
        <VfSnackerBar
          snackOpen={snackOpen}
          setsnackOpen={this.handleSnackerBarOpen}
          duration={duration}
          severity={severity}
          message={message}
        />

        <input
          type='file'
          accept='.xlsm, .xlsx'
          hidden
          id='upload-btn'
          ref={(ref) => (this.UploadRef = ref)}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              selectedUploadFile === "liability"
                ? this.uploadLiabilityFile(file)
                : this.uploadPaymentPerf(file);
            }
          }}
          style={{ display: "none" }}
        />

        <VfAlertDialogBox
          isOpen={isAlertDialogOpen}
          handleAlertDialogVisible={this.handleAlertDialogVisible}
          dialogTitle={
            switchDialogRender === constants.COMMON_SCENARIO_UPDATE
              ? "Select Common Scenarios"
              : switchDialogRender === "export_file"
              ? "Export Reports"
              : "Upload Files"
          }
          dialogBody={
            switchDialogRender === constants.COMMON_SCENARIO_UPDATE
              ? this.handleRenderCommonScenarioSelection()
              : switchDialogRender === "export_file"
              ? this.renderAlertDialogExportBody(exportFileName)
              : null
            // : this.renderAlertDialogUploadBody()
          }
          onAccept={
            switchDialogRender === constants.COMMON_SCENARIO_UPDATE
              ? this.handleCommonScenarioChangeEvt
              : switchDialogRender === "export_file"
              ? this.exportFile
              : () => this.UploadRef.click()
          }
        />

        <VfBreadcrumbContainer
          breadcrumb={<VfBreadcrumbs breadcrumbs={this.renderBreadCrumbs()} />}
          button={
            pipelineDealId != null ? (
              <a href={dmurl + pipelineDealId} target='_blank'>
                {"Open in DealManager >"}
              </a>
            ) : (
              <></>
              // <p>No Dealmanager Link</p>
            )
          }
        />

        <Box
          mt={1}
          mb={1.5}
          style={{ marginTop: 48 }}
          key={dataRefrshRenderKey}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box style={{ display: "flex", justifyContent: "flex-start" }}>
              <SectionHeader
                label={
                  deal.facilityTrancheName
                    ? `Debt Facility - ${deal.facilityTrancheName}`
                    : "Debt Facility"
                }
              />
              {liabilityData ? (
                <>
                  <Tooltip
                    title={
                      <div>
                        Upload On:{" "}
                        {moment
                          .utc(liabilityData.asOfDate)
                          .format(constants.DATE_FORMAT)}
                        <br /> By: {liabilityData.uploadedBy}
                        <br /> File Name: {liabFileName}
                      </div>
                    }
                    placement='right'>
                    <IconButton style={{ margin: "6px 0 6px 20px" }}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <LiabilityModelSelect
                    selectedModelType={selectedModelType}
                    deal={deal}
                    handleSelectLiabilityModel={this.handleRefreshLiabilityData}
                  />
                </>
              ) : null}
            </Box>

            <Box style={{ marginTop: 16 }}>
              <span>
                <Link
                  to={{
                    pathname: `/deal/${deal.dealName}/portfolio/loanSummary/${deal.dealId}`,
                  }}>
                  <Tooltip title='Go to Loan Summary' placement='bottom' arrow>
                    <Button
                      style={{ maxHeight: 28 }}
                      variant='outlined'
                      color='primary'>
                      <span style={{ margin: "0px 8px 0px 8px" }}>
                        Loan Summary
                      </span>
                    </Button>
                  </Tooltip>
                </Link>
              </span>
              {this.renderExportButton()}
              <FileUpload
                deal={deal}
                handleSnackbarOpen={this.handleSnackerBarOpen}
                handleRefreshLiabilityData={this.handleRefreshLiabilityData}
              />
              {liabilityError ? null : this.renderExpectedLossButton()}
              <VfPrint
                styleId={"portfolioPrint"}
                margin={20}
                orientation={"landscape"}
                additionalCss={`html {
                  zoom: 65%;
                  margin: 0 auto;
                  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
                  color-adjust: exact !important; /*Firefox*/
                  width: 100%;
                }`}
              />
            </Box>
          </Box>

          <Box className={classes.portFolioBody}>
            {missingSims.length >= 1 ? (
              <Box style={{ width: "100%" }}>
                <Box style={{ marginBottom: 20, width: "100%" }}>
                  <Errors
                    msg={
                      "The following MSNs are missing base case simulations: " +
                      missingSims.toString()
                    }
                  />
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <Grid container spacing={2}>
              {liabilityError && !liabilityData ? (
                <Box style={{ width: "100%" }}>
                  <Box style={{ marginBottom: 20, width: "100%" }}>
                    <Errors
                      msg={
                        "There is currently no Liability Model Attached to this Deal."
                      }
                    />
                  </Box>
                  <Grid item sm={12}>
                    <Tabs
                      value={tabValue}
                      onChange={this.handleTabChange}
                      indicatorColor='primary'
                      textColor='primary'
                      justify='end'>
                      <Tab
                        key='assets'
                        label='Assets'
                        disabled={fleetSummary ? false : true}
                      />
                      <Tab key='history' label='Exports' />
                    </Tabs>
                  </Grid>
                  {tabValue === 0 ? (
                    <Grid item xs={12}>
                      <PortfolioAssetsSection
                        fleetSummary={fleetSummary}
                        leaseSummary={leaseSummary}
                        report={report}
                        appraisalError={appraisalError}
                      />
                    </Grid>
                  ) : tabValue === 1 ? (
                    <Grid item xs={12}>
                      <CashflowHistory
                        deal={deal}
                        cashflowHistoryRenderKey={cashflowHistoryRenderKey}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <>
                  <Grid item sm={12}>
                    <Tabs
                      value={tabValue}
                      onChange={this.handleTabChange}
                      indicatorColor='primary'
                      textColor='primary'
                      justify='end'>
                      <Tab key='liability' label='Liability' />
                      <Tab
                        key='assets'
                        label='Assets'
                        disabled={fleetSummary ? false : true}
                      />
                      <Tab
                        key='risks'
                        label='risks'
                        disabled={expectedLossTranches ? false : true}
                      />
                      {/* <Tab
                        key='performance'
                        label='performance'
                        disabled={performanceData ? false : true}
                      /> */}
                      <Tab key='history' label='Exports' />
                    </Tabs>
                  </Grid>
                  {tabValue === 0 ? (
                    <Grid item xs={12}>
                      <PortfolioLiabilitySection
                        deal={deal}
                        liabilityData={liabilityData}
                        report={report}
                        liabilityTranches={liabilityTranches}
                        liabilityCashflow={liabilityCashflow}
                        editLoans={editLoans}
                        handleOpenLoansTableEditing={
                          this.handleOpenLoansTableEditing
                        }
                        handleUpdateLiabilityData={
                          this.handleUpdateLiabilityData
                        }
                        assetList={assetList}
                      />
                    </Grid>
                  ) : tabValue === 1 ? (
                    <Grid item xs={12}>
                      <PortfolioAssetsSection
                        fleetSummary={fleetSummary}
                        leaseSummary={leaseSummary}
                        report={report}
                        appraisalError={appraisalError}
                      />
                    </Grid>
                  ) : expectedLossTranches && tabValue === 2 ? (
                    <Grid item xs={12}>
                      <ExpectedLossRiskSection
                        deal={deal}
                        expectLossTranchesData={expectedLossTranches}
                        expectedLossData={expectedLossData}
                        liabilityData={liabilityData}
                      />
                    </Grid>
                  ) : performanceData && tabValue === 3 ? (
                    <Grid item xs={12}>
                      {/* <PaymentPerformancekSection
                        liabilityData={liabilityData}
                        performanceData={performanceData}
                      /> */}
                    </Grid>
                  ) : tabValue === 3 ? (
                    <Grid item xs={12}>
                      <CashflowHistory
                        deal={deal}
                        cashflowHistoryRenderKey={cashflowHistoryRenderKey}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </ErrorBoundary>
    ) : unAbletoLoad | noSimAssets ? (
      <Box style={{ paddingTop: 20 }}>
        <ErrorBoundary>
          <VfSnackerBar
            snackOpen={snackOpen}
            setsnackOpen={this.handleSnackerBarOpen}
            duration={duration}
            severity={severity}
            message={message}
          />

          <input
            type='file'
            accept='.xlsm, .xlsx'
            hidden
            id='upload-btn'
            ref={(ref) => (this.UploadRef = ref)}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                selectedUploadFile === "liability"
                  ? this.uploadLiabilityFile(file)
                  : this.uploadPaymentPerf(file);
              }
            }}
            style={{ display: "none" }}
          />

          <VfAlertDialogBox
            isOpen={isAlertDialogOpen}
            handleAlertDialogVisible={this.handleAlertDialogVisible}
            dialogTitle={
              switchDialogRender === constants.COMMON_SCENARIO_UPDATE
                ? "Select Common Scenarios"
                : switchDialogRender === "export_file"
                ? "Export Reports"
                : "Upload Files"
            }
            dialogBody={
              switchDialogRender === constants.COMMON_SCENARIO_UPDATE
                ? this.handleRenderCommonScenarioSelection()
                : switchDialogRender === "export_file"
                ? this.renderAlertDialogExportBody(exportFileName)
                : null
              // : this.renderAlertDialogUploadBody()
            }
            onAccept={
              switchDialogRender === constants.COMMON_SCENARIO_UPDATE
                ? this.handleCommonScenarioChangeEvt
                : switchDialogRender === "export_file"
                ? this.exportFile
                : () => this.UploadRef.click()
            }
          />
        </ErrorBoundary>

        {noSimAssets ? (
          <Errors
            msg={
              "An asset in this portfolio's asset pool contains an unsimulated base case scenario."
            }
          />
        ) : (
          <Errors
            msg={
              "There is no liability or simulated asset data for this deal, the page cannot load."
            }
          />
        )}

        <Box className={classes.refreshButton}>
          <FileUpload
            deal={this.state.deal}
            handleSnackbarOpen={this.handleSnackerBarOpen}
            handleRefreshLiabilityData={this.handleRefreshLiabilityData}
          />
        </Box>
      </Box>
    ) : (
      <Box style={{ paddingTop: 20 }}>
        <LoadingPanel />
      </Box>
    );
  }
}

export default withStyles(styles)(PortfolioReport);
