import moment from "moment";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";

export const mapToViewModel_assetPoolAsset = (asset) => {
    let data = {
      asset: asset,
      enterDate: asset.enterDate,
      exitDate: asset.exitDate,
      comment: asset.comment,
    };
    return {
      data : data,
      columns: [
        {
            label: ["Enter Date"],
            field: "enterDate",
            captions: [],
            span: [3, 3],
            editable: true,
            required: false,
            type: constants.DATE,
            inputFieldType: constants.DATE,
            value:moment.utc(asset.enterDate).format(constants.ISOFormat),
            initialEditValue: moment.utc(asset.enterDate).format(constants.ISOFormat),
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
              value
                ? moment.utc(value).format("MM-DD-YYYY")
                : constants.___,
          },
          {
            label: ["Exit Date"],
            field: "exitDate",
            captions: [],
            span: [3, 3],
            editable: true,
            required: false,
            type: constants.DATE,
            inputFieldType: constants.DATE,
            initialEditValue: moment.utc(asset.exitDate).format(constants.ISOFormat),
            validate: (inputType, value, required) =>
              utils.validate(inputType, value, required),
            content: (value) =>
              value
                ? moment.utc(value).format(constants.DATE_FORMAT)
                : constants.___,
          },
          {
            label: ["Comment"],
            field: "comment",
            editable: true,
            span: [6, 6],
            required: false,
            initialEditValue: asset.comment,
            type: constants.TEXT_FIELD,
            inputFieldType: constants.TEXT_FIELD,
          }
      ],
    };
  };
  