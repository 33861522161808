export const updateData = (scenario, sectionIdx, newData, actionType) => {
  scenario.leases = [...scenario.leases];
  scenario.leases[sectionIdx] = {
    ...scenario.leases[sectionIdx],
  };

  if (actionType === "leasePanelUpdate") {
    scenario.leases[sectionIdx].rentPayments = newData;
  } else {
    const rentPayment = {
      amount: parseInt(newData.rent),
      frequency: newData.paymentFrequency,
      from: newData.fromDate,
      paymentDate: newData.paymentDate,
      to: newData.toDate,
    }

    if(scenario.leases[sectionIdx].rentPayments[newData.id].pbhRate || newData.pbhRate === "Yes") {
      rentPayment.amount = null;
      rentPayment.pbhRate = parseInt(newData.rent);
    } else {
      rentPayment.pbhRate = null
    }

    scenario.leases[sectionIdx].rentPayments[newData.id] = rentPayment;
  }
  
  return scenario;
};
