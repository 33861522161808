import * as constants from "../../../../constants";
import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const uploadHistory = async (compType, compId, newdata, msalContext) => {
  let apiendpoint = apiRoutes.PostShopVisit;
  apiendpoint = apiendpoint({ compType, compId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newdata,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    newdata = resp.data;
  }
};

export const updateData = (
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  msalContext
) => {
  assetDetails[sectionName] = [...assetDetails[sectionName]];
  assetDetails[sectionName][sectionIdx] = {
    ...assetDetails[sectionName][sectionIdx],
  };

  newData = {
    id: newData.id,
    startDate: newData.svStartDate,
    endDate: newData.svEndDate,
    isOverhaul: newData.isOverhaul,
    sinceNew: {
      flightHour: newData.totalHours,
      flightCycle: newData.totalCycles,
      activeHour: newData.activeHours,
      day: newData.days,
    },
    cost: newData.cost,
  };

  let table = [...assetDetails[sectionName][sectionIdx][tableName]];

  if (actionType === constants.ADD_ROW) {
    table.push(newData);
  } else if (actionType === constants.UPDATE_ROW) {
    table[newData.id] = { ...table[newData.id] };
    table[newData.id] = newData;
  } else {
    table = table.filter((row, idx) => idx !== newData.id);
  }

  uploadHistory(
    assetDetails[sectionName][sectionIdx].componentType,
    assetDetails[sectionName][sectionIdx].componentId,
    table,
    msalContext
  );
  assetDetails[sectionName][sectionIdx][tableName] = table;
  return assetDetails;
};
