import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import * as constants from "../../../constants";
import * as d3 from "d3";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

const styles = {
  root: {
    margin: 2,
    padding: 16,
    height: 160,
    minWidth: 260,
    maxWidth: 300,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
    textAlign: "left",
  },
  assetsKPI: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainTextColor: {
    color: "#023059",
    fontWeight: 600,
    fontSize: 50,
    // marginTop: -5,
    // marginBottom: -5,
  },
  smallTitle: {
    fontSize: 16,
    color: "#023059",
    fontWeight: 550,
  },
  otherText: {
    color: "#023059",
    fontWeight: 600,
    fontSize: 30,
  },
  barItem:{
    marginTop: -4,
  },
}

class AssetsKPI extends Component {
  constructor(props){
    super(props)
    this.myInput = React.createRef()
    this.state = {
      narrowBody: [],
      wideBody: [],
      regionalJet: [],
      aircraft: [],
      engine: [],
      passenger: [],
      freighter: [],
      typeTotal: null,
      usageTotal: null,
      sizeTotal: null,
    }
  }
  
  componentDidMount = () => {
    const { assets } = this.props
    var [narrowBody, wideBody, regionalJet, aircraft, engine, passenger, freighter] = [[], [], [], [], [], [], []]
    assets.forEach(asset => {
      let modelClass = asset.modelClass
      if (modelClass === "NB") narrowBody.push(asset);
      else if (modelClass === "WB") wideBody.push(asset);
      else if (modelClass === "RJ") regionalJet.push(asset);

      if(asset.assetType === "Aircraft") aircraft.push(asset);
      else if(asset.assetType === "Engine") engine.push(asset);

      if(asset.usage === "Passenger") passenger.push(asset);
      else if (asset.usage === "Freighter") freighter.push(asset)
    });

    const typeTotal = aircraft.length + engine.length
    const usageTotal = passenger.length + freighter.length
    const sizeTotal = wideBody.length + narrowBody.length + regionalJet.length

    this.setState({
      narrowBody: narrowBody,
      wideBody: wideBody,
      regionalJet: regionalJet,
      aircraft: aircraft,
      engine: engine,
      passenger: passenger,
      freighter: freighter,
      typeTotal: typeTotal,
      usageTotal: usageTotal,
      sizeTotal: sizeTotal,
    })

    // asset type bars
    this.assetRectBar(0, aircraft.length/typeTotal)
    this.assetRectBar(1, engine.length/typeTotal)

    // asset usage bars
    this.assetRectBar(2, passenger.length/usageTotal)
    this.assetRectBar(3, freighter.length/usageTotal)

    // asset size bars
    this.assetRectBar(4, wideBody.length/sizeTotal)
    this.assetRectBar(5, narrowBody.length/sizeTotal)
    this.assetRectBar(6, regionalJet.length/sizeTotal)
  }

  setAreaChartGradient = (idx, svg, fillpct) => {
    const defs = svg.append('defs');
    const bgGradient = defs
      .append('linearGradient')
      .attr('id', `bg-fill-partial${idx}`)
      .attr('x1', 0)
      .attr('x2', 1)
      .attr('y1', 0)
      .attr('y2', 0)
    bgGradient
      .append('stop')
      .attr('stop-color', `#023059` )
      .attr('offset', '0%');
    bgGradient
      .append('stop')
      .attr('stop-color', `#023059` )
      .attr('offset', fillpct );
    bgGradient  
      .append('stop')
      .attr('stop-color', `#d0cfd1` )
      .attr('offset', fillpct );
  }

  assetRectBar = (idx, pct) => {
    var width = 200
    var height = 12
    
    var parent = d3.select(`#progbar${idx}`);

    var svg = parent.append('svg')
      .attr('width', width)
      .attr('height', height)
    
    this.setAreaChartGradient(idx, svg, `${_.round((pct % 1)*100, 2)}%`)

    svg.append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)  
      .attr("height", height)
      .attr("border-radius", 5)
      .attr("fill", `url(#bg-fill-partial${idx})`)
  }

  // makeHalfPieChart = (svg, data) => {
  //   var width = 150,
  //   height = 80,
  //   radius = Math.min(width, 2 * height) / 2,
  //   angleRange = 0.5 * Math.PI;

  //   var color = d3.scaleOrdinal(['rgba(2, 48, 89, 1)','rgba(2, 48, 89, 0.7)','rgba(2, 48, 89, 0.4)','rgba(2, 48, 89, 0.1)']);
      
  //   svg.attr("width", width)
  //     .attr("height", height)
      
  //   var translation = (x, y) => `translate(${x}, ${y})`
  //   var g = svg.append("g")
  //     .attr("transform", translation(width / 2, height));

  //   // Generate the pie
  //   var pie = d3.pie()
  //     .value( d => d)
  //     .sort(null)
  //     .startAngle( angleRange  * -1)
  //   	.endAngle( angleRange );

  //   // Generate the arcs
  //   var arc = d3.arc()
  //     .innerRadius(0)
  //     .outerRadius(radius);
    
  //   var arcs = g.selectAll("arc")
  //     .data(pie(data))
  //     .enter()
  //     .append("g")
  //     .attr("class", "arc")
    
  //   arcs.append("path")
  //     .attr("fill", function(d, i) {
  //       return color(i);
  //     })
  //     .attr("d", arc);
  // }

  render(){
    const { classes, assets } = this.props;
    const { narrowBody, wideBody, regionalJet, aircraft, engine, passenger, freighter, typeTotal, sizeTotal, usageTotal } = this.state;
    let realassets = assets.filter((x)=> x.assetType !== constants.OnWingEngine);
    const numOfAssets = realassets.length;

    const assetWithValue = realassets.filter(a => a.value !== null)
    let totalval = _.sumBy(assetWithValue, a => a.value );
    let billions = 0;
    if(totalval > 999999999){
       billions = _.round(totalval/ Math.pow(10, 9), 3 ).toFixed(2);
    }

    const totalAssetValue = billions == 0 ? _.round(totalval / Math.pow(10, 6), 1 ).toFixed(2) : billions;
    const AsOfDate = moment().format(constants.DATE_FORMAT)

    return (
      <Grid container spacing={2} style={{ minWidth: 1250, marginBottom: 10 }}>
        <Grid item sm={12} className={classes.assetsKPI} >
          <Box className={classes.root} > 
            <Typography className={classes.smallTitle} align="left" variant="subtitle2">
              {"Assets"}
            </Typography>
            <Typography color="textSecondary" align="left" variant="caption">
              {"QTY"}
            </Typography>
            <Typography className={classes.mainTextColor} align="left" variant="h3">
              {numOfAssets}
            </Typography>
            <Typography color="textSecondary" align="left" variant="caption">
              As of {AsOfDate}
            </Typography>
          </Box>
          <Box className={classes.root} > 
            <Typography className={classes.smallTitle} align="left" variant="subtitle2">
              {"Assets Value"}
            </Typography>
            <Typography color="textSecondary" align="left" variant="caption">
              {"USD"}
            </Typography>
            <Typography className={classes.mainTextColor} align="left" variant="h3">
              {totalAssetValue}<span className={classes.mainTextColor} style={{ fontSize: 24 }}>{billions == 0 ? "M" : "B"}</span>
            </Typography>
            <Typography color="textSecondary" align="left" variant="caption">
              For <span style={{ fontWeight: 'bold', fontSize: 12, color: "#023059" }}>{` ${assetWithValue.length}`}</span> {` of ${numOfAssets} Assets`} 
            </Typography>
          </Box>
          
          <Box className={classes.root} >
            <Typography className={classes.smallTitle} align="left" variant="subtitle2">
              {"Type"}
            </Typography>
            <Box className={classes.assetsKPI}>
              <Typography align="left" color="textSecondary" variant="caption">
                {"Aircraft"}
              </Typography>
              <Typography align="right" color="textSecondary" variant="caption">
                {`${_.round((aircraft.length / typeTotal) * 100)}%`}
              </Typography>
            </Box>
            <Box className={classes.assetsKPI} style={{ marginTop: -5 }} >
              <Typography className={classes.smallTitle}>
                {aircraft.length}
              </Typography>
              <Box id="progbar0"></Box>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`}>
              <Typography align="left" color="textSecondary" variant="caption">
                {"Engine"}
              </Typography>
              <Typography align="right" color="textSecondary" variant="caption">
                {`${_.round((engine.length / typeTotal) * 100)}%`}
              </Typography>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`} >
              <Typography className={classes.smallTitle}>
                {engine.length}
              </Typography>
              <Box id="progbar1"></Box>
            </Box>
          </Box>
          <Box className={classes.root} >
            <Typography className={classes.smallTitle} align="left" variant="subtitle2">
              {"Usage"}
            </Typography>
            <Box className={classes.assetsKPI}>
              <Typography align="left" color="textSecondary" variant="caption">
                {"Passenger"}
              </Typography>
              <Typography align="right" color="textSecondary" variant="caption">
                {`${_.round((passenger.length / usageTotal) * 100)}%`}
              </Typography>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`} >
              <Typography className={classes.smallTitle}>
                {passenger.length}
              </Typography>
              <Box id="progbar2"></Box>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`}>
              <Typography align="left" color="textSecondary" variant="caption">
                {"Freighter"}
              </Typography>
              <Typography align="right" color="textSecondary" variant="caption">
                {`${_.round((freighter.length / usageTotal) * 100)}%`}
              </Typography>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`}>
              <Typography className={classes.smallTitle}>
                {freighter.length}
              </Typography>
              <Box id="progbar3"></Box>
            </Box>
          </Box>
          <Box className={classes.root} >
            <Typography className={classes.smallTitle} align="left" variant="subtitle2">
              {"Size"}
            </Typography>
            <Box className={classes.assetsKPI}>
              <Typography align="left" color="textSecondary" variant="caption">
                {"Wide"}
              </Typography>
              <Typography align="right" color="textSecondary" variant="caption">
                {`${_.round((wideBody.length / sizeTotal) * 100)}%`}
              </Typography>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`} >
              <Typography className={classes.smallTitle}>
                {wideBody.length}
              </Typography>
              <Box id="progbar4"></Box>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`}>
              <Typography align="left" color="textSecondary" variant="caption">
                {"Narrow"}
              </Typography>
              <Typography align="right" color="textSecondary" variant="caption">
                {`${_.round((narrowBody.length / sizeTotal) * 100)}%`}
              </Typography>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`} >
              <Typography className={classes.smallTitle}>
                {narrowBody.length}
              </Typography>
              <Box id="progbar5"></Box>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`}>
              <Typography align="left" color="textSecondary" variant="caption">
                {"Regional"}
              </Typography>
              <Typography align="right" color="textSecondary" variant="caption">
                {`${_.round((regionalJet.length / sizeTotal) * 100)}%`}
              </Typography>
            </Box>
            <Box className={`${classes.assetsKPI} ${classes.barItem}`} >
              <Typography className={classes.smallTitle}>
                {regionalJet.length}
              </Typography>
              <Box id="progbar6"></Box>
            </Box>
          </Box>
        </Grid>
      </Grid>      
    );
  }
};

export default withStyles(styles)(AssetsKPI);