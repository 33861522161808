import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
import { styles } from "../../common";

export function mapToViewModel_LeaseExtension(leaseInfo, props) {
  const leaseStatus = [
    { label: constants.Contractual, value: constants.Contractual },
    { label: constants.UnderNegotiation, value: constants.UnderNegotiation },
    { label: constants.Assumption, value: constants.Assumption },
  ];

  let data = leaseInfo.extensions;

  return {
    label: "Lease Extension",
    caption: null,
    tableType: constants.EDITABLE_TABLE,
    tableName: constants.leaseExtension,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    columns: [
      {
        title: "Ext",
        field: "id",
        type: constants.STRING,
        // initialEditValue: leaseStatus[0].value,
        // inputFieldType: constants.SELECT,
        editable: constants.NEVER,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => rowData.id + 1,
      },
      {
        title: "Lease Status",
        field: "leaseStatus",
        type: constants.STRING,
        initialEditValue: leaseStatus[0].value,
        inputFieldType: constants.SELECT,
        editable: constants.ALWAYS,
        required: true,
        items: leaseStatus,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
      },
      {
        title: "Start Date",
        field: "extensionStartDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        initialEditValue: moment.utc(leaseInfo.leaseEndDate).add(1, "day"),
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.extensionStartDate).format(constants.DATE_FORMAT),
      },
      {
        title: "End Date",
        field: "extensionEndDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // && moment.utc(value).diff(moment.utc(rowData.extensionStartDate), "days") > 0,
        render: (rowData) =>
          moment.utc(rowData.extensionEndDate).format(constants.DATE_FORMAT),
      },
      // {
      //   title: "Rent Payments",
      //   field: "rentPayments",
      //   type: constants.NUMERIC,
      //   inputFieldType: constants.NUMBER,
      //   editable: constants.ALWAYS,
      //   required: true,
      //   cellStyle: styles.cellStyle,
      //   validate: (inputType, value, required) =>
      //     utils.validate(inputType, value, required),
      //   content: (value) =>
      //     utils.renderCellValue(value, null, constants.NUMERIC_CELL, "$"),
      // },
    ],
    data: data.map((d, idx) => ({
      id: idx,
      leaseStatus: d.leaseStatus,
      extensionStartDate: d.extensionStartDate,
      extensionEndDate: d.extensionEndDate,
      rentPayments: d.rentPayments,
    })),
  };
}
