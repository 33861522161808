import React, { useState } from "react";
import {
  Box,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import * as utils from "../../../utils/utils";

export const useStyles = makeStyles((theme) => ({
  chips: {
    borderRadius: 5,
    margin: "0px 0px 0px 8px",
    padding: "0px 4px 0px 4px",
    fontWeight: 450,
  },
}));

const DealStatus = (stage) => {
  const classes = useStyles();
  const chips = {
    all: (
      <span
        style={{
          border: "1px solid #023059",
          background: "#023059",
          color: "#F4F6F8",
        }}
        className={classes.chips}>
        {stage.count ? stage.count : 0}
      </span>
    ),
    pipeline: (
      <span
        style={{
          border: "1px solid #2ABF43",
          background: "rgba(42,191,67,0.4)",
          color: "#2ABF43",
        }}
        className={classes.chips}>
        {stage.count ? stage.count : 0}
      </span>
    ),
    closed: (
      <span
        style={{
          border: "1px solid #39B2F3",
          background: "rgba(57,178,243,0.4)",
          color: "#39B2F3",
        }}
        className={classes.chips}>
        {stage.count ? stage.count : 0}
      </span>
    ),
    managed: (
      <span
        style={{
          border: "1px solid #0052FA",
          background: "rgba(0,82,250,0.4)",
          color: "#0052FA",
        }}
        className={classes.chips}>
        {stage.count ? stage.count : 0}
      </span>
    ),
    expired: (
      <span
        style={{
          border: "1px solid #FA6400",
          background: "rgba(250,100,0,0.4)",
          color: "#FA6400",
        }}
        className={classes.chips}>
        {stage.count ? stage.count : 0}
      </span>
    ),
    lost: (
      <span
        style={{
          border: "1px solid #E02020",
          background: "rgba(224,32,32,0.4)",
          color: "#E02020",
        }}
        className={classes.chips}>
        {stage.count ? stage.count : 0}
      </span>
    ),
    infoonly: (
      <span
        style={{
          border: "1px solid #0E253F",
          background: "rgb(207, 159, 255, 0.4)",
          color: "#7F00FF",
        }}
        className={classes.chips}>
        {stage.count ? stage.count : 0}
      </span>
    ),
  };

  return chips[stage.value.replaceAll(" ", "").toLowerCase()];
};

const DealStageFilter = ({ dealStages, handleChange, checkedDealStage }) => {
  const multiSelect = utils.sessionGetDataHelper("dealstage-multi-select");

  const [selectionType, setSelectionType] = useState(
    multiSelect ? multiSelect : false
  );

  const handleDealStageSelect = (value) => {
    if (selectionType) {
      handleChange(value);
    } else {
      handleChange(value, true);
    }
  };

  return (
    <Box style={{ margin: "0px 8px 0px 8px" }}>
      <FormControl style={{ margin: "-4px 8px 0px 8px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={selectionType}
              onChange={() => {
                utils.sessionStoreHelper(
                  "dealstage-multi-select",
                  !selectionType
                );
                setSelectionType(!selectionType);
              }}
              color='primary'
            />
          }
          label='Multi-Stage'
        />
      </FormControl>

      {dealStages.map((stage, idx) => (
        <Button
          key={idx}
          label={stage.label}
          // variant="default"
          style={{
            margin: "2px 8px 0px 8px",
            maxHeight: 28,
            background:
              checkedDealStage.includes(stage.value) ||
              (_.isEmpty(checkedDealStage) && stage.value === "All")
                ? "#E0E0E0"
                : "#F4F6F8",
          }}
          // avatar={<Avatar>M</Avatar>}
          color={
            checkedDealStage.includes(stage.value) ||
            (_.isEmpty(checkedDealStage) && stage.value === "All")
              ? "primary"
              : "secondary"
          }
          onClick={() => handleDealStageSelect(stage.value)}>
          <span style={{ margin: "0px 4px 0px 4px" }}>{stage.label}</span>{" "}
          <span>{DealStatus(stage)}</span>
        </Button>
      ))}
    </Box>
  );
};

export default DealStageFilter;
