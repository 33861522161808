import AssetModel from "../Pages/AssetModel";
import Deals from "../Pages/Deals/DealsPage";
import Home from "../Pages/Home";
import AssetPools from "../Pages/AssetPool";
import Portfolio from "../Pages/Portfolio";
import Simulations from "../Pages/Simulations";
import SessionData from "../Pages/SessionData";
import AuthPage from "../Pages/AuthPage";
import AssetCreation from "./../Components/AssetCreation/AssetCreation";
import AssetComponents from "../Components/AssetModel/AssetComponents";
import Assets from "./../Components/AssetList/Assets";
import ExportHistory from "../Pages/ExportHistory";
import LeaseSummary from "../Pages/LeaseSummary";
import LoanSummary from "../Pages/LoanSummary";
import GenericAssumptions from "../Pages/GenericAssumptions";
import Valuations from "../Pages/Valuations";


const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: Home,
    isProtected: true,
  },
  {
    path: ["/assets/assetDetails/:assetId"],
    exact: true,
    component: AssetComponents,
    isProtected: true,
  },
  {
    path: ["/assets"],
    exact: true,
    component: Assets,
    isProtected: true,
  },
  {
    path: ["/assetpools"],
    exact: true,
    component: AssetPools,
    isProtected: true,
  },
  {
    path: ["/deals"],
    exact: true,
    component: Deals,
    isProtected: true,
  },
  {
    path: ["/deal/portfolio/:dealId"],
    exact: true,
    component: Portfolio,
    isProtected: true,
  },
  {
    path: ["/genericAssumptions"],
    exact: true,
    component: GenericAssumptions,
    isProtected: true,
  },
  {
    path: ["/valuations"],
    exact: true,
    component: Valuations,
    isProtected: true,
  },
  {
    path: "/simulations",
    component: Simulations,
    isProtected: true,
  },
  {
    path: "/sessionData",
    component: SessionData,
    isProtected: true,
  },
  {
    path: "/deal/assetModel/:assetId",
    component: AssetModel,
    isProtected: true,
  },
  {
    path: "/assetPool/assetModel/:assetId",
    component: AssetModel,
    isProtected: true,
  },
  {
    path: "/auth",
    component: AuthPage,
    isProtected: false,
    exact: true,
  },
  {
    path: "/assetCreation",
    component: AssetCreation,
    isProtected: false,
    exact: true,
  },
  {
    path: "/exportHistory",
    component: ExportHistory,
    isProtected: true,
    exact: true,
  },
  {
    path: ["/assets/leaseSummary/:assetId"],
    exact: true,
    component: LeaseSummary,
    isProtected: true,
  },
  {
    path: ["/deal/:dealName/portfolio/loanSummary/:dealId"],
    exact: true,
    component: LoanSummary,
    isProtected: true,
  },
];

export default routes;
