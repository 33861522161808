import _ from "lodash";
import moment from "moment";
import * as utils from "../../../../utils/utils";
import * as constants from "../../../../constants";
import { styles } from "../index";

export function mapToViewModel_engineRestRateMatrix(leaseSummary, props, threeDotBtnOptions) {
  let data = [];
  const leaseData = leaseSummary.mxReserve_LessorContrib.engineRestorationRateMatrix
  if(leaseData.dataTable) {
    data = leaseData.dataTable.map((e, idx) => {
      return {
        id: idx,
        fHfC: e.fHfC,
        thrust: e.thrust,
        rate: e.rate,
      };
    })
  }

  const {
    section,
    funcs: { handleValuesUpdate, validateData },
  } = props

  const footNoteData = {
    footNotes: leaseData.footNotes,
    tableName:  "Engine Restoration Rate Matrix",
    props: { 
      section: section,
      sectionIdx: "footNotes",
      funcs: { handleValuesUpdate, validateData } 
    },
  };

  return {
    label: "Engine Restoration Rate Matrix",
    caption: leaseData.footNotes,
    props,
    showOptions: true,
    threeDotBtnOptions: threeDotBtnOptions("Engine Restoration Rate Matrix", footNoteData),
    tableType: constants.EDITABLE_TABLE,
    tableName: "Engine Restoration Rate Matrix",
    errorsOnSubmit: {},
    columns: [
      {
        title: "FH:FC",
        field: "fHfC",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        // render: (rowData) =>
        //   utils.renderCellValue(rowData.fHfC1, null, constants.TEXT_CELL),
      },
      {
        title: "Thrust / Derate",
        field: "thrust",
        type: constants.STRING,
        inputFieldType: constants.TEXT,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.thrust, null),
      },
      {
        title: "Rate",
        field: "rate",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        cellStyle: styles.cellStyle,
        required: false,
        hidden: props.section === constants.apUs,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          utils.renderCellValue(rowData.rate, null, constants.NUMERIC_CELL, "$"),
      },
    ],
    data: data,
  };
}
