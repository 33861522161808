import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Switch,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  Slide,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import SectionHeader from "../../Common/Section/SectionHeader";
import VfCheckBox from "../../Common/UtilComponents/VfCheckBox";
import VfRadioGroup from "../../Common/UtilComponents/VfRadioGroup";
import * as constants from "../../../constants";
import * as utils from "../../../utils/utils";
import AppraisalUpload from "./AppraisalUpload";
import XLSX from 'xlsx';
import moment from "moment";
import ColumnFilter from "../../Common/Tables/FilteringTable/ColumnFilter";
import VfSnackerBar from "../../Common/UtilComponents/VfSnackerBar";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: "flex",
    justifyContent: "space-between"
  },
  button: {
    maxHeight: 28,
  }
}));

// const exportFile = (assets) => {
//   const filename = `vf-Assets-List-${moment(new Date()).format(constants.DATE_FORMAT)}.xlsx`
//   let data = []

//   assets.forEach(ast => {
//     data.push({
//       "Serial Number": ast.serialNumber,
//       "Asset Type": ast.assetType,
//       "Operator": ast.operator,
//       "Model": ast.subseries,
//       "Vintage": ast.manufactureDate ? new Date(ast.manufactureDate).getFullYear() : null,
//       "Manufacturer": ast.manufacturer,
//       "Registration": ast.registration,
//       "Model Class": ast.modelClass,
//       "Value": ast.value,
//       "Deal Name": ast.inDeal ? ast.dealInfo[0].dealName : null,
//       "Deal Sponsor": ast.inDeal ? ast.dealInfo[0].sponsor : null,
//     })
//   });
  
//   /* convert state to workbook */
//   const wb = XLSX.utils.book_new();
//   const ws = XLSX.utils.json_to_sheet(data);

//   // format currency columns
//   const currency = '$#,##0.00'
//   utils.formatColumn(ws, 8, currency)

//   // files format must be xlsx, will not work for csvs
//   ws['!cols'] = utils.fitToColumn(data.map( Object.values ) );

//   XLSX.utils.book_append_sheet(wb, ws, "Assets");
//   /* generate XLSX file and send to client */
//   XLSX.writeFile(wb, filename)
// };


const TopFilters = ({
  assets,
  selectedSortBy,
  unassignedSelected,
  onAssetSearch,
  onDisplayAssetTypeChange,
  onSortByChange,
  onUnassignedAssetsOptionChange,
  onAssetAddClick,
  setUpdateRenderKey,
  updateRenderKey,
  columns,
  onSwitchChange,
  onProfileSwap,
  profiles,
  selectedProfile
}) => {
  const classes = useStyles();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity,setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(2000);

  const handleSnackerBarOpen = (open, sev, msg, duration = 2000) => {
    setMessage(msg);
    setSeverity(sev);
    setsnackOpen(open);
    setDuration(duration);
  };
  //console.log(assets);


  const filters = [
    {
      // icon: <FilterListIcon />,
      label: "Filter",
      handleClick: () => {
        setShowFilterOptions(!showFilterOptions);
      },
    },
    // {
    //   // icon: <FilterListIcon />,
    //   label: "Export",
    //   handleClick: () => {
    //     exportFile(assets);
    //   },
    // },
    // {
    //   // icon: <AddIcon />,
    //   label: "Upload Appraisals",
    //   handleClick: () => {
    //     onAssetAddClick()
    //   },
    // },
    // {
    //   // icon: <AddIcon />,
    //   label: "Add Asset",
    //   handleClick: () => {
    //     onAssetAddClick()
    //   },
    // },
  ];

  const checkBoxItems = [{ label: "Unassigned Assets", value: "unassigned" }];

  const sortItems = [
    { label: "Operator", value: "operator" },
    { label: "Asset Model", value: "subseries" },
    { label: "Serial Number", value: "serialNumber" },
    { label: "Vintage", value: "manufactureDate" },
  ];

  const filterItems = [
    { label: "Engine Only", value: constants.ENGINE },
    { label: "Aircraft Only", value: constants.AIRCRAFT },
    { label: "OnWing Engines", value: constants.OnWingEngine},
    {
      label: "All",
      value: `${constants.AIRCRAFT} ${constants.ENGINE} ${constants.OnWingEngine}`,
    },
  ];
  const filterSelected = `${constants.AIRCRAFT} ${constants.ENGINE}`;

  return (
    <Box style={{ maxWidth: "100%", margin: "auto" }}>
        <VfSnackerBar
          snackOpen={snackOpen}
          setsnackOpen={handleSnackerBarOpen}
          severity={severity}
          message={message}
          duration={duration}
        />
      <Box mt={1} mb={1.5}>
        <Box style={{ display: "flex", justifyContent: "space-between", margin: "48px 0 8px 0" }}>
          {/* <SectionHeader label={"Assets"} /> */}
          {/* justifyContent: "flex-end" */}
          <Box alignItems="center" style={{ display: "flex" }}>
            <Box>
              <TextField
                id="input-with-icon-textfield-searchAsset"
                style={{ marginRight: 16 }}
                label={
                  <Typography variant="button" color="primary" style={{ fontSize: 14, maxHeight: 28 }}>
                    SEARCH
                  </Typography>
                }
                InputLabelProps={{ style: { top: -8 } }}
                inputProps={{ style: { height: 8 } }}
                variant="outlined"
                size="small"
                onChange={onAssetSearch}
              />
            </Box>

            <Box>
              <Grid container spacing={1}>
                {filters.map((filter, idx) => (
                  <Grid key={idx} item>
                    <Button
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      onClick={filter.handleClick}
                    >
                      <span style={{ margin: "0px 8px 0px 8px" }}>
                        {filter.label}
                      </span>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box>
              <Tooltip title="Reset all filters and sorting" aria-label="Reset">
                <Button
                  onClick={() => {
                    onSortByChange('id', false)
                    onDisplayAssetTypeChange(`${constants.AIRCRAFT} ${constants.ENGINE} ${constants.OnWingEngine}`)
                    if (unassignedSelected) onUnassignedAssetsOptionChange(false)
                    if (showFilterOptions) setShowFilterOptions(false)
                    let newKey = updateRenderKey + 1;
                    setUpdateRenderKey(newKey)
                  }}
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: 8,marginRight:8 }}
                  className={classes.button} >
                  Reset
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box alignItems="center" style={{ display: "flex" }}>
            <Box>
                <AppraisalUpload
                  handleSnackbarOpen={handleSnackerBarOpen}
                />
            </Box>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {onAssetAddClick()}}
            >
              <span style={{ margin: "0px 8px 0px 8px" }}>
                {"Add Asset"}
              </span>
            </Button>
          </Box>
        </Box>
      </Box>

      <Slide direction="right" in={showFilterOptions} mountOnEnter unmountOnExit>
        <Box mb={1.5}>
          <Grid container>
            <Grid item>
              <VfCheckBox
                items={checkBoxItems}
                onChange={onUnassignedAssetsOptionChange}
                fontSize={14}
                selected={unassignedSelected ? [checkBoxItems[0].value] : null}
              />
            </Grid>
            <Grid item>
              <VfRadioGroup
                items={filterItems}
                selected={selectedSortBy}
                onChange={onDisplayAssetTypeChange}
              />
            </Grid>
            <Grid item>
              <ColumnFilter
                columns={columns}
                profiles={profiles}
                selectedProfile={selectedProfile}
                onProfileSwap={onProfileSwap}
                onSwitchChange={onSwitchChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </Box>
  );
};

export default TopFilters;
