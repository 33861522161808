import React from "react";

const OnWingEngineSvg = ({
  width = 30,
  height = 30,
  viewBox = "0 0 30 30",
  color = "#023059",
  style = null,
}) => {
  return (
    <svg width={width} height={height} style={{...style}} 
    //viewBox={viewBox}
    viewBox="3 10 32 30"
    >
      <path
        fill="#355A79"
        d="M-.003-7.623a7.624 7.624 0 100 15.247 7.624 7.624 0 000-15.247zM5.314-3.72L1.539-1.54l2.18-3.775c.62.434 1.16.974 1.595 1.595zM.562-6.468a6.494 6.494 0 012.18.584L.562-2.106v-4.362zm-4.286 1.152l.787 1.364.977-.565-.787-1.367a6.494 6.494 0 012.18-.584v4.362l-.74-1.282-.977.565.74 1.282-3.776-2.18c.435-.62.975-1.16 1.596-1.595zm-2.163 2.572l3.778 2.18h-4.363a6.494 6.494 0 01.585-2.18zm.565 6.466l2.778-1.6-.565-.992-2.778 1.604A6.494 6.494 0 01-6.472.565h4.574c.09.296.248.567.46.79L-3.723 5.31A6.494 6.494 0 01-5.32 3.722h-.002zm4.754 2.747a6.494 6.494 0 01-2.18-.584l2.18-3.778v4.362zM-.85.001A.847.847 0 11.844 0 .847.847 0 01-.85 0zM.562 6.469V2.107l2.18 3.778a6.494 6.494 0 01-2.18.584zm3.156-1.152l-2.18-3.775 2.977 1.72.564-.98L2.103.565h4.363a6.494 6.494 0 01-2.748 4.752zM6.466-.564H2.103l3.778-2.18c.32.687.519 1.425.585 2.18z"
        transform="matrix(.68407 0 0 .9221 18.544 29.349) translate(0 1.41)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        fill="#355A79"
        d="M4.179-8.161L2.945-11.86h-5.896l-1.234 3.699a10.447 10.447 0 108.364 0zm-6.317-2.57h4.27l.689 2.087a10.393 10.393 0 00-5.647 0l.688-2.087zm2.135 21.46a9.318 9.318 0 110-18.636 9.318 9.318 0 010 18.635z"
        transform="matrix(.68407 0 0 .9221 18.544 29.349)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <g>
        <path
          fill="#656565"
          fillOpacity="0"
          stroke="#355A79"
          strokeWidth="1.5"
          d="M44.75 0c0 6.213-5.236 11.25-11.696 11.25h-66.108c-6.46 0-11.696-5.037-11.696-11.25h0c0-6.213 5.236-11.25 11.696-11.25h66.107C39.514-11.25 44.75-6.213 44.75 0h0z"
          transform="matrix(.3352 0 0 -.38728 18.544 14.641)"
          vectorEffect="non-scaling-stroke"
        ></path>
      </g>
    </svg>
  );
};

export default OnWingEngineSvg;
