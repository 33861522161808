import _ from "lodash";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import ScenarioDropdown from "./ScenarioDropdown";
import ScenarioSimulation from "./ScenarioSimulation";
import ScenarioThreeDotOptions from "./ScenarioThreeDotOptions";
import VfSnackerBar from "../../../../../../Common/UtilComponents/VfSnackerBar";
import { useMsal } from "@azure/msal-react";

import VfPrint from "../../../../../../Common/UtilComponents/VfPrint";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  icon: {
    float: "right",
    fontSize: 16,
    verticalAlign: "text-top",
  },
  divider: {
    width: "100%",
    margin: "0 0 8px 0",
  },
  buttonHeight: {
    maxHeight: 28,
  },
  runButton: {
    marginLeft: 8,
  },
  buttonSpace: {
    marginLeft: 16,
  },
  scenarioButton: {
    minWidth: 350,
    maxHeight: 28,
    backgroundColor: "#023059",
    color: "#F6F6F6",
    font: "Roboto Regular",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#023059",
    },
    textTransform: "none",
  },
  menuItemRoot: {
    width: "100%",
    maxHeight: 30,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  menuItemButton: {
    font: "Roboto Regular",
    fontSize: 14,
    width: "100%",
  },
  arrowSpan: {
    paddingLeft: 80,
  },
  customBadge: {
    backgroundColor: "#E02020",
    color: "white",
  },
}));

const css_printing = `html {
  margin: 0 auto;
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
  width: 100%;
}
body * {
  visibility: hidden;
  display: block;
}
#asset_info,
#asset_info * {
  visibility: visible;
}
#asset_info {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.section-to-print,
.section-to-print * {
  visibility: visible;
}
.section-to-print {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 200px;
}`;

const ScenarioOptions = ({
  assetDetails,
  scenario,
  handleScenarioChangeEvt,
  currentState,
  handleScenarioLoading,
  report,
  errors,
}) => {
  const classes = useStyles();
  const [openCreateScenario, setOpenCreateScenario] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const [snackOpen, setsnackOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(1500);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const msalContext = useMsal();
  const { accounts } = msalContext;
  const loading = currentState.scenarioLoading;

  const handleSnackerBarOpen = (open, severity, msg, dura = 1500) => {
    setsnackOpen(open);
    setSeverity(severity);
    setMessage(msg);
    setDuration(dura);
  };

  const selected = currentState.selected;

  return (
    <Box>
      <Box style={{ margin: "16px 0 0 0", zIndex: 20 }}>
        <VfSnackerBar
          snackOpen={snackOpen}
          setsnackOpen={setsnackOpen}
          severity={severity}
          message={message}
          duration={duration}
        />

        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          {loading ? null : (
            <ScenarioDropdown
              classes={classes}
              openCreateScenario={openCreateScenario}
              setOpenCreateScenario={setOpenCreateScenario}
              setSelectedScenario={setSelectedScenario}
              assetDetails={assetDetails}
              scenario={scenario}
              handleScenarioChangeEvt={handleScenarioChangeEvt}
              currentState={currentState}
              setShowErrorIcon={setShowErrorIcon}
            />
          )}
          {loading || currentState.editing ? null : (
            <ScenarioSimulation
              classes={classes}
              setOpenCreateScenario={setOpenCreateScenario}
              assetDetails={assetDetails}
              scenario={scenario}
              selectedScenario={selectedScenario}
              handleScenarioLoading={handleScenarioLoading}
              errors={errors}
              showErrorIcon={showErrorIcon}
              setShowErrorIcon={setShowErrorIcon}
              handleSnackerBarOpen={handleSnackerBarOpen}
              msalContext={msalContext}
            />
          )}

          {selected.label === "Valuation" ? (
            <VfPrint
              styleId={"assetValuationPrint"}
              margin={10}
              additionalCss={
                css_printing +
                `html {
                  zoom: 65%;
                }
                .valuation_grid1 {width:33%;  float:left;}
                .valuation_grid2 {width:33%; float:left;}
                .valuation_grid3 {width:33%; float:left;}`
              }
            />
          ) : selected.label === "Cashflows" ? (
            <VfPrint
              styleId={"assetCashflowsPrint"}
              margin={10}
              additionalCss={
                css_printing +
                `html {
                  zoom: 60%;
                }`
              }
            />
          ) : null}

          <ScenarioThreeDotOptions
            selectedScenario={selectedScenario}
            scenario={scenario}
            report={report}
            accounts={accounts}
            msalContext={msalContext}
            handleSnackerBarOpen={handleSnackerBarOpen}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ScenarioOptions;
