import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import EditableTable from "../../../../../Common/Tables/EditableTable/EditableTable";
// import SectionHeader from "../../../../../Common/Section/SectionHeader";
// import { ReactComponent as APUIcon5x } from "../../../../../../assets/images/APU/APUIcon5x.svg";
import * as constants from "../../../../../../constants";
import * as AssetDetailViewModel from "../../../../mapToViewModels";
import { mapToViewModelHeader } from "../../../../mapToViewModels";
import DataTable from "../../../../../Common/Tables/DataTable/DataTable";
import { apiRequest, response } from "../../../../../../utils/ApiRequest";
import apiRoutes from "../../../../../../utils/ApiRoutes";
import { useMsal } from "@azure/msal-react";

const formatData = (data, componentType, generateEditableProps, scenario, models) => {
  return data.map((item, idx) => {
    let props = generateEditableProps(idx, componentType);
    return {
      header: mapToViewModelHeader(item, componentType),
      data: AssetDetailViewModel.mapToViewModel(
        item,
        componentType,
        props,
        scenario,
        null,
        null,
        null,
        null,
        models
      ),
      componentType,
    };
  });
};

const APU = ({assetDetails, apus, generateEditableProps, scenario }) => {
 const msalContext = useMsal();
 const [models, setModels] = useState(null);
 useEffect(() => {
  const getApus = async () => {
    let apiEndPoint = apiRoutes.GenericAPU_GetACAPUs;
    let genericId = assetDetails.genericID;
    apiEndPoint = apiEndPoint({
      ACId : genericId
    });
    let apus = await apiRequest(
      {
        url: apiEndPoint,
        method: "GET",
      },
      msalContext
    );
    if (apus.status === response.OK) setModels(apus.data);
  };
  getApus();

  }, []);
  const data = formatData(
    apus,
    constants.apUs,
    generateEditableProps,
    scenario,
    models
  );

  return (
    <Box>
      {data.map((o, idx) => (
        <Box key={idx}>
          {/* <SectionHeader label={o.header[0].label} />
          icon={<APUIcon5x />} */}
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <DataTable tableData={o.data.apuInfo} />
            </Grid>
            <Grid item sm={12}>
              <EditableTable table={o.data.apuStatus} />
            </Grid>
            <Grid item sm={12}>
              <EditableTable table={o.data.shopVisitHistory} />
            </Grid>
            <Grid item sm={12}>
              <EditableTable table={o.data.scheduledShopVisit} />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default APU;
