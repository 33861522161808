import { apiRequest, response } from "../../../../utils/ApiRequest";
import apiRoutes from "../../../../utils/ApiRoutes";

const updateAsset = async (assetId, data, msalContext) => {
  let user = msalContext.accounts[0].name;
  let compType = "Aircraft";
  let apiendpoint = apiRoutes.PostAircraftUpdateInfo;
  apiendpoint = apiendpoint({user, compType, assetId });
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: data,
    },
    msalContext
  );
  return resp;
};
const updateEngines = async (assetId, newengs, msalContext) => {
  let user = msalContext.accounts[0].name;
  let apiendpoint = apiRoutes.Engine_AddToWing;
  apiendpoint = apiendpoint({ AcId : assetId, user});
  let resp = await apiRequest(
    {
      url: apiendpoint,
      method: "POST",
      data: newengs,
    },
    msalContext
  );
  if (resp.status === response.OK) {
    window.location.reload();
  }
  return resp;
};

export const updateData = (assetDetails, newData, msalContext) => {
  //console.log(assetDetails);
  let dif = false;
  let resp = null;
  //airframe detail changes
  if(newData.serialNumber != assetDetails.msn){
    assetDetails.msn = newData.serialNumber;
    dif = true;
  }
  if(assetDetails.manufactureDate != newData.aircraftDOM){
    assetDetails.manufactureDate = newData.aircraftDOM;
    dif = true;
  }
  if(newData.newafc){
    dif = true;
  }
  if(dif){
    let postData = {
      subseries: newData.aircraftSubSeries,
      serialNumber: newData.serialNumber,
      DOM: newData.aircraftDOM,
      newafc: newData.newafc,
    };
    resp = updateAsset(assetDetails.id, postData, msalContext);
  }
  //engine detail changes
  if(newData?.neweng1 ||newData?.neweng2 || newData?.neweng3 ||newData?.neweng4){
    let newengs = [];
    if(newData?.neweng1)
      newengs.push({Id: newData?.neweng1,Position: 1});
    if(newData?.neweng2)
      newengs.push({Id: newData?.neweng2, Position: 2});
    if(newData?.neweng3)
      newengs.push({Id: newData?.neweng3, Position: 3});
    if(newData?.neweng4)
      newengs.push({Id: newData?.neweng4,Position: 4});

    resp = updateEngines(assetDetails.id, newengs, msalContext);
  }
  if (resp.status === response.OK) {
    window.location.reload();
  }

  return assetDetails;

};
