import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { TableCell } from "@material-ui/core";

const useStyles = makeStyles({
  tableCell: {
    padding: 0,
    maxWidth: 135,
    minWidth: 90,
    lineHeight: "normal",
    color: "#263238",
  },
});

const MultiFunctionTableCell = ({
  colSpan,
  children,
  sticky = {},
  showSideBar = false,
  fontWeight = 500,
  align = "center",
  color = "#263238",
  bgColor = "inherit",
  height = 46,
  fontSize = 13,
  zIndex = 1,
}) => {
  const classes = useStyles();
  const borderLeft = showSideBar ? "5px solid #50708A" : "unset";
  return (
    <TableCell
      align={align}
      style={{
        background: bgColor,
        fontSize,
        color,
        fontWeight,
        height,
        borderLeft,
        zIndex,
        ...sticky,
      }}
      className={classes.tableCell}
      colSpan={colSpan ? colSpan : 1}
    >
      {children}
    </TableCell>
  );
};

export default MultiFunctionTableCell;
