import * as constants from "../../../constants";
import { updateAirframeInfo } from "./airframe";
import { updateLandingGearInfo } from "./landingGears";
import { updateEngineInfo } from "./engine";
import { updateAPUInfo } from "./apu";
import { updateUtilizationInfo } from "./utilization";
import { updateLeaseInfo } from "./lease";
import { updateSection as addOrDeleteSection } from "./updateSection";
import { cashReserveAccounts } from "./common/cashReserveAccounts";
import { updateAssetDetail } from "./assetDetails";
import { updateParameters } from "./common/simulationParameters";
import { scenarioSummary } from "./common/scenarioSummary";
import { scenarioNotes } from "./common/scenarioNotes";
import { assetNotes } from "./common/assetNotes";
import { updateConversions } from "./common/conversionEvents";
import { updateRunoutOverrides } from "./common/runoutOverrides";
import { updatePartoutEvents } from "./common/partoutEvents";
import { updatePurchasePrice } from "./assetDetails/purchasePrice";
import { updateScenarioScheduledShopvisits } from "./updateSection/scenarioScheduledShopvisits";

export function updateTable(
  tableName,
  actionType,
  assetDetails,
  sectionName,
  sectionIdx,
  newData,
  scenario,
  msalContext
) {
  const params1 = [
    tableName,
    actionType,
    assetDetails,
    sectionName,
    sectionIdx,
    newData,
    scenario,
    msalContext,
  ];

  const params2 = [
    tableName,
    actionType,
    assetDetails,
    sectionIdx,
    newData,
    scenario,
    msalContext,
  ];
  if (tableName.includes("Details")) updateAssetDetail(...params2);
  else if (tableName === "Conversion Events") updateConversions(...params2);
  else if (tableName === "Runout Overrides") updateRunoutOverrides(...params2); 
  else if (tableName === "PartoutEventDate" || tableName === "Partout Events" || tableName === "partoutEventDate") updatePartoutEvents(...params2);
  else if (tableName === "Simulation Parameters") updateParameters(...params2);
  else if (tableName === constants.ScenarioSummaries)
    scenarioSummary(...params2);
  else if (tableName === "eolCompBounds") addOrDeleteSection(...params1);
  else if (tableName === "Purchase Price") updatePurchasePrice(...params2);
  else if (tableName === constants.scenarioScheduledShopVisit) updateScenarioScheduledShopvisits(...params1) ;

  switch (sectionName) {
    case constants.AIRFRAME_CHECKS:
    case constants.GENERAL_INFO:
      return updateAirframeInfo(...params1);
    case constants.LANDING_GEARS:
      return updateLandingGearInfo(...params1);
    case constants.ENGINES:
      return updateEngineInfo(...params1);
    case constants.apUs:
      return updateAPUInfo(...params1);
    // for editing the values in a utilization section
    case constants.EXPECTED_UTILIZATIONS:
      return updateUtilizationInfo(...params2);
    case constants.LEASES:
      return updateLeaseInfo(...params2);
    // for adding, deleting a utilization section
    case constants.SCENARIO:
      return addOrDeleteSection(...params1);
    // for Cash Reserve Account update on asset level
    case constants.Cash_Reserve_Accounts:
      return cashReserveAccounts(
        actionType,
        assetDetails,
        newData,
        msalContext
      );
    case "scenarioNotes":
      return scenarioNotes(...params2);
    case "assetNotes":
      return assetNotes(...params2);
    default:
      return;
  }
}
