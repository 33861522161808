import _ from "lodash";
import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import * as constants from "../../../../../constants";
import * as utils from "../../../../../utils/utils";
// import { AddBox, DeleteOutline } from "@material-ui/icons";

export function mapToViewModel_CashReserveCap(reserveCap, props, leaseInfo) {
  const items = leaseInfo.reserveAccounts.map((e) => ({
    label: `Account ${e.id}`,
    value: `${reserveCap.capId} - ${e.id}`,
    components: e.components
  }));

  const selectedAccounts = reserveCap.reserveIds.map((e) => _.find(items, (o) => 
    {
      let displayValue = o.value.split("-")[1].trim()
      return parseInt(displayValue) === e
    }))

  const accounts = {
    label: null,
    caption: null,
    tableType: constants.DATA_TABLE,
    tableName: "Accounts",
    props,
    style: { boxShadow: "unset", backgroundColor: "#E5E8EB" },
    errorsOnSubmit: {},
    columns: [
      {
        label: ["Accounts"],
        field: "accounts",
        span: [6, 6],
        required: true,
        editable: true,
        items,
        showCellOptions: true,
        type: constants.CHECK_BOX,
        inputFieldType: constants.CHECK_BOX,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (values) => (
          <Grid container spacing={2}>
            {values.map((value) => (
              <Grid item sm={6} md={6} lg={6} key={value} style={{ lineHeight: "normal" }}>
                {value}
              </Grid>
            ))}
          </Grid>
        ),
      },
      {
        label: ["Components"],
        field: "components",
        span: [6, 6],
        required: false,
        editable: false,
        showCellOptions: false,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        content: (values) => (
          <Grid container spacing={1}>
            {values.map((value) => (
              <Grid item sm={6} md={6} lg={6} key={value} style={{ lineHeight: "normal", fontWeight: 400, fontSize:12 }}>
                {value}
              </Grid>
            ))}
          </Grid>
        ),
      }
    ],
    data: {
      accounts: selectedAccounts.map((e) => e.label),
      components: _.flattenDeep(selectedAccounts.map((e) => e.components)) ,
    },
  };

  const reserveAccountCaps = {
    label: null,
    caption: null,
    tableName: constants.cashReserveCap,
    tableType: constants.EDITABLE_TABLE,
    errorsOnSubmit: {},
    props,
    showOptions: true,
    style: { boxShadow: "unset", padding: "6px 16px" },
    columns: [
      {
        title: "From Date",
        field: "startDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        // cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.startDate).format(constants.DATE_FORMAT),
      },
      {
        title: "To Date",
        field: "endDate",
        type: constants.DATE,
        inputFieldType: constants.DATE,
        editable: constants.ALWAYS,
        required: true,
        // cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) =>
          moment.utc(rowData.endDate).format(constants.DATE_FORMAT),
      },
      {
        title: "R.A. Caps",
        field: "RAcaps",
        type: constants.NUMERIC,
        inputFieldType: constants.NUMBER,
        editable: constants.ALWAYS,
        required: true,
        // cellStyle: styles.cellStyle,
        validate: (inputType, value, required) =>
          utils.validate(inputType, value, required),
        render: (rowData) => utils.renderCellValue(rowData.RAcaps, null, constants.NUMERIC, null, 2),
      },
    ],
    data: reserveCap.capsByDate.map((e, index) => {
      return {
        capId: reserveCap.capId,
        id: index,
        startDate: e.startDate,
        endDate: e.endDate,
        RAcaps: e.cap,
      };
    }),
  };

  return {
    accounts,
    reserveAccountCaps,
  }
}
